import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../../shared/model/SelectList.model';

import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { FeeService } from '../../shared/services/Fee.service';


@Component({
  selector: 'app-concession-category',
  templateUrl: './concession-category.component.html',
  styleUrls: ['./concession-category.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class ConcessionCategoryComponent implements OnInit {

  concession: Array<{id:number,desc:string,rem:string}>;

  @ViewChild('f') formRef: NgForm;
  constructor(
    private toastr: ToastrService,
    private feeService:FeeService
  ) { 
    this.concession = new Array<{id:number,desc:string,rem:string}>();
  }

  ngOnInit(): void {
    this.loadConcession();
  }

  OnSubmit(){
  }

  loadConcession() {
    this.concession = []; 
    this.feeService.getConcCategory().subscribe((res: { conc_id: number, conc_des: string,rem:string }[]) => {
      res?.forEach(element => {
        this.concession.push({ id: element.conc_id, desc: element.conc_des,rem:element.rem });
      });
    }, err => {
      console.log(err);
    });
  }

  addConcession() {
    if(this.formRef.valid){
      this.feeService.addConcCategory(this.formRef.value.conc_id, this.formRef.value.conc_desc,this.formRef.value.conc_rem).subscribe((res:{warning:string,conc_id:number,conc_des:string,rem:string}) => {
        if(!res){
          this.toastr.error("Unnown Error");
        }
        else if(res.warning){
          this.toastr.warning(res.warning);
        }
        else{
          this.concession.push({id:res.conc_id,desc:res.conc_des,rem:res.rem});
          this.toastr.success("Add Successfully!");        }
      }, err => {
        this.toastr.error("Some Error Occured during Adding Fee Description!");
      });
    }
    else{
      this.toastr.warning("Some Fields are Missing!");
    }
  }

  deleteConcession(id,index){
      this.feeService.deleteConcCategory(id).subscribe((res:{msg:string, warning:string}) => {
        if(!res){
          this.toastr.error("Unnown Error");
        }
        else if(res.msg){
          this.toastr.success(res.msg);
          this.concession.splice(index,1);
        }
        else{
          this.toastr.warning(res.warning);
        }
      }, err => {
        this.toastr.error("Some Error Occured during Adding Fee Description!");
      });
  }



  ClearForm(fn: NgForm) {
    fn.resetForm();
  }

  ClearIDBase() {
    this.formRef?.controls['conc_desc'].reset();
    this.formRef?.controls['conc_rem'].reset();
  }

  ClearDescBase() {
    this.formRef?.controls['conc_rem'].reset();
  }

}
