import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class ImportExportService {

    constructor(private http: HttpClient) {
    }

    getQuery(p) {
        return this.http.get(`${baseUrl}/api/importExport/getQuery/${p}`);
      }
      
    gettbnm() {
          return this.http.get(`${baseUrl}/api/importExport/get_tb_nm`);
        }
      
    getData(params) {
          return this.http.post(`${baseUrl}/api/importExport/getData`, params);
        }
      
    getColName(tn) {
          return this.http.get(`${baseUrl}/api/importExport/getColName/${tn}`);
        }
   
}