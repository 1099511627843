<div [@SlideInFromLeft] class="backdrop" style="overflow:hidden; overflow-y: scroll">
  <div class="row">
    <div class="col-lg-10 col-md-12 offset-sm-3 ml-sm-auto mr-sm-auto" style="left: 3%;">
      <div class="alert-box">
        <form #f="ngForm">
          <div class="container-fluid mb-2 card" style="margin-bottom: 5px;">
            <div class="card-header  d-flex justify-content-between" style="margin-bottom: -20px">
              Details
              <button class="btn btn-xs btn-outline-danger" (click)="OnClose()">x</button>
            </div>

            <div class="row card-body" style="margin-bottom: -30px">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">#N</th>
                    <th scope="col">Subject Code</th>
                    <th scope="col">Name</th>
                    <th scope="col">Grading</th>
                    <th scope="col">Cr. Hr.</th>
                    <th scope="col">Grading</th>
                    <th scope="col">Season</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let s of subjects">
                    <td>{{s.pr_ord}}</td>
                    <td>{{s.sub_code}}</td>
                    <td>{{s.sub_nm}}</td>
                    <td>{{(s.ab_rel===1 ? "Absolute": "Relative")}}</td>
                    <td>{{s.cr_h}}</td>
                    <td>{{s.gp_per==='G' ? "GP": "%age"}}</td>
                    <td>{{s.reg_sumr}}</td>
                  </tr>
                </tbody>
              </table>
              <button *ngIf="selectedDept.status===0" class="btn btn-sm btn-outline-primary mt-2" [disabled]="subjects.length < 1 "
                (click)="OfferSubject()" style="width: 100px; height: 40px; margin-left: 220px; text-align: center;"
                type="button">Offer</button>
              <button *ngIf="selectedDept.status===0" class="btn btn-outline-danger btn-md mt-2" [disabled]="subjects.length < 1 "
                (click)="rejectSubject()" style="width: 100px; height: 40px; margin-left: 30px; text-align: center;"
                type="button">Reject</button>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
