import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class RoomService {

    constructor(private http: HttpClient) {
    }

    getRooms(blk_id: string, day: string, s_time: string, e_time: string, duration: string, status: number) {
        return this.http.get(`${baseUrl}/api/room/getrooms/${blk_id}/${day}/${s_time}/${e_time}/${duration}/${status}`);
    }
    getRoomsByBlock(blk_id:string){
        return this.http.get(`${baseUrl}/api/room/getRoomsByBlock/${blk_id}`);
    }

   
}