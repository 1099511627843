import { Component, OnInit, ViewChild } from '@angular/core';
import { FeeService } from '../shared/services/Fee.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { NgForm } from '@angular/forms';

declare let $: any;

@Component({
  selector: 'app-faidnamesearch',
  templateUrl: './faidnamesearch.component.html',
  styleUrls: ['./faidnamesearch.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class FaidnamesearchComponent implements OnInit {
  data: Array<{ f_no, year, rolno, nm, f_nm, Major, remarks, c_code, se_id }>;
  stdDet: boolean = false;
  stddata: boolean = false;
  rolno: any;
  c_code: any;
  se_id: any;
  t_no: any;
  loader = false;
  @ViewChild("f") f: NgForm;
  details =
    `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>On this screen, you can view the details of any student. If you want to search by name only, just enter the name and press the search button. Similarly, you can do the same for the roll number. Additionally, you can also view details by entering both the roll number and name. The basic details will first be shown in the table.
   To view more details, click on the detail button on the student row in the table. </h6>
  <h6 class="text-right">اس اسکرین پر آپ کسی بھی طالب علم کی تفصیلات دیکھ سکتے ہیں۔ اگر آپ صرف نام سے تلاش کرنا چاہتے ہیں تو صرف نام درج کریں اور سرچ کا بٹن دبائیں. اسی طرح رول نمبر کے لئے بھی۔ اس کے علاوہ آپ رول نمبر اور نام دونوں درج کرکے بھی تفصیلات دیکھ سکتے ہیں۔ پہلے ٹیبل میں آپ کو بنیادی تفصیل نظر آئے گی۔ مزید تفصیلات دیکھنے 
  کے لیے آپ ٹیبل میں طالب علم کی لائن  پر لگے  بٹن پر کلک کریں۔

۔
</h6>
  `  ;
  constructor(
    private FeeService: FeeService,
  ) {
    this.data = new Array<{ f_no; year; rolno; nm; f_nm; Major; remarks, c_code, se_id }>();
  }

  ngOnInit(): void { };

  getData() {
    this.data = [];
    this.loader = true;
    const name = (document.getElementById('nm') as HTMLInputElement).value.trim() || '0';
    const rollNumber = (document.getElementById('rolno') as HTMLInputElement).value.trim() || '0';
    this.FeeService.faidnamesearch(name, rollNumber).subscribe(
      (res: any) => {
        this.data = res;
        this.loader = false;
      },
      (error: any) => {
        console.error('Error fetching data:', error);
        this.loader = false;
      }
    );
  }

  onClear() {
    this.data = [];
    this.f.reset();
    this.loader = false;
  }

  getDetail(rolno_in, c_code_in) {
    this.stdDet = true;
    this.rolno = rolno_in;
    this.c_code = c_code_in;
  }

  getDetails(rolno_in, c_code_in, se_id_in, t_no_in) {
    this.stddata = true;
    this.rolno = rolno_in;
    this.c_code = c_code_in;
    this.se_id = se_id_in;
    this.t_no = t_no_in;
  }

  CloseStd() {
    this.stdDet = false;
  }

  Filter(e: any, index: string) {
    var value;
    var store;
    var rows;

   
    value = $("#myInput" + index).val().toUpperCase();

   
    rows = $("#myTable #myBody").find("tr").hide();

   
    if (index == '1') { 
        rows.filter(function () {
            store = $(this).children(':eq(0)').text().toUpperCase().indexOf(value) !== -1;
            return store;
        }).show();
    } else if (index == '2') { 
        rows.filter(function () {
            store = $(this).children(':eq(1)').text().toUpperCase().indexOf(value) !== -1;
            return store;
        }).show();
    } else if (index == '3') { 
        rows.filter(function () {
            store = $(this).children(':eq(2)').text().toUpperCase().indexOf(value) !== -1;
            return store;
        }).show();
    } else if (index == '4') { 
        rows.filter(function () {
            store = $(this).children(':eq(3)').text().toUpperCase().indexOf(value) !== -1;
            return store;
        }).show();
    } else if (index == '5') { 
        rows.filter(function () {
            store = $(this).children(':eq(4)').text().toUpperCase().indexOf(value) !== -1;
            return store;
        }).show();
    } else if (index == '6') { 
        rows.filter(function () {
            store = $(this).children(':eq(5)').text().toUpperCase().indexOf(value) !== -1;
            return store;
        }).show();
    }

    return e.keyCode !== 13; 
}


  CategoryFilter(e: any) {
    var value;
    var value2;
    var value3;
    var value4;
    var value5;
    var value6;
    var value7;
    var assign;
    var store;
    var rows;
    $("#myInput1,#myInput2,#myInput3,#myInput4,#myInput5,#myInput6,#myInput7").on("keyup", function () {
      value = $("#myInput1").val();
      value2 = $("#myInput2").val();
      value3 = $("#myInput3").val();
      value4 = $("#myInput4").val();
      value5 = $("#myInput5").val();
      value6 = $("#myInput6").val();
      value7 = $("#myInput7").val();
      assign = $("#massign").val();
      rows = $("#myTable #myBody").find("tr").hide();
      rows.filter(function () {
        store = $(this).children(':eq(0)').text().indexOf(value) !== -1
          && $(this).children(':eq(1)').text().indexOf(value2) !== -1
          && $(this).children(':eq(2)').text().indexOf(value3) !== -1
          && $(this).children(':eq(3)').text().indexOf(value4) !== -1
          && $(this).children(':eq(4)').text().indexOf(value5) !== -1
          && $(this).children(':eq(5)').text().indexOf(value6) !== -1
          && $(this).children(':eq(6)').text().indexOf(value7) !== -1;
        return store;
      }).show();
    })
    return e.keyCode != 13;
  }
}