import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SlideInFromLeft } from '../../transitions';
import { DepartmentService } from './../shared/services/Department.service';
import { TeacherService } from './../shared/services/Teacher.service';
import { SelectList } from '../shared/model/SelectList.model';
import { NgForm } from '@angular/forms';
import { TimetableService } from '../insert-time-table/Timetable.service';
import { ToastrService } from 'ngx-toastr';
import { TimetableModel } from '../view-timetable/model/Timetable.model';

@Component({
  selector: 'app-add-teacher',
  templateUrl: './add-teacher.component.html',
  styleUrls: ['./add-teacher.component.css'],
   animations: [
    SlideInFromLeft()
  ]
})
export class AddTeacherComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  // @Output() c_data:EventEmitter<Map<number, TimetableModel>> = new EventEmitter();
  @Output() c_data  = new EventEmitter<any>();
  // @Output() countChanged: EventEmitter<Map<>> =   new EventEmitter()
  @Input() Form_data:any;
  @ViewChild('f') formRef: NgForm;
  departments: SelectList[];
  teachers: Map<number, SelectList>;
  param:any;
  tableEntries: Map<number, TimetableModel>;


  constructor(
    private teacherService:TeacherService,
    private departmentService:DepartmentService,
    private timetableService: TimetableService,
    private toastr: ToastrService) 
    { 
      this.teachers = new Map<number, SelectList>();
      this.departments = new Array<SelectList>();
      this.tableEntries = new Map<number, TimetableModel>();
      
    }

    
  ngOnInit(): void {
    this.loadDepartment();
  }

  loadDepartment(){
    this.departmentService.getDepartments({admin:true}).subscribe((res:{D_ID:number,D_NM:string}[]) => {
      res?.forEach(entry=>{
        this.departments.push({id:entry.D_ID,nm:entry.D_NM});
      });
    });

  }

  OnClose() {
    this.close.emit();
  }

  OnDeptChange() {
    this.loadTeachers();
  }

  loadTeachers(){
    this.teachers.clear();
    this.teacherService.getTeachers(this.formRef.value.d_id).subscribe((res:{FM_ID: number, FM_NM: string}[]) => {
     res?.forEach(entry=>{
       this.teachers.set(entry.FM_ID, {id: entry.FM_ID, nm: entry.FM_NM});
     })
    });
  }

  onTeacherChange(teacherId:any){
   this.param =this.Form_data;
    this.param.fm_id=teacherId;

  }

  
  OnSubmit() {
    if (!this.formRef.valid)
      return;
  this.timetableService.insertTimetable(this.param).subscribe((r: {
    sub_code: string, tm_id: number, d_id: number, d_nm: string, c_nm: string, se: number, t_no: number, section: string, subject: string, fm_id: string, teacher: string, day: string, start_time: string, end_time: string, blk_id: string, blk_nm: string, rm_id: string, rm_nm: string, status: number
    
  }) => {
    if (!r) {
      this.toastr.warning("Failed To Insert");
    } else {
      this.toastr.success("Inserted successfully");
      this.
    tableEntries.set(r.tm_id, new TimetableModel(r.sub_code, 0, r.tm_id.toString(), r.d_id, r.d_nm, r.c_nm, r.se, r.t_no, r.section, r.subject, r.fm_id, r.teacher, r.day, r.start_time, r.end_time, r.blk_id, r.blk_nm, r.rm_id, r.rm_nm, r.status));

    this.c_data.emit(this.tableEntries);

    }
  },
    err => {
      console.log(err.message);
      this.toastr.error(err.message);
    });

  }
}
