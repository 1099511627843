<div [@SlideInFromLeft]>
  <div class="card shadow">
    <div class="card-header shadow-sm">
      <div>Sub Offer Requests</div>
    </div>
    <div class="card-body mb-3" style="min-height: 70vh">
      <div class="list-group shadow">


        <div class="d-flex justify-content-between mb-1 w-100" *ngFor="let s of requestedSubs; let i=index;"
          (click)="viewDetails(s, i)">
          <div *ngIf="s.status===1" class="p-1 bg-success"></div>
          <div *ngIf="s.status===-1 || s.status===0" class="p-1 bg-danger"></div>
          <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h6 class="mb-1">{{s.maj_nm}}</h6>
              <small *ngIf="s.status===1">Approved</small>
              <small *ngIf="s.status===0">Requested</small>
              <small *ngIf="s.status===-1">Rejected</small>
            </div>
            <div class="d-flex w-100 justify-content-around" style="align-items: center;">
              <p>{{s.c_nm}}</p>
              <p>{{s.se}}</p>
              <p>Semester: {{s.t_no}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
  <app-requested-sub-detail [selectedDpet]='selectedDept' *ngIf="showDetails" (close)="OnClose($event)">
  </app-requested-sub-detail>
