import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../../shared/model/SelectList.model';
import { ClassService } from '../../shared/services/Class.service';
import { DegreeprintService } from '../../shared/services/degreeprint.service';
import { MajorService } from '../../shared/services/Major.service';
import { jsPDF } from "jspdf";
import { arialblack, font, oldeng } from 'src/font';
import "jspdf-autotable";
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';

export interface IStudentDetail {
  address: string
  F_name: string,
  name: string,
  ROLNO: string,
  rn: number,
  year: number
}

@Component({
  selector: 'app-singlestdaddressforletter',
  templateUrl: './singlestdaddressforletter.component.html',
  styleUrls: ['./singlestdaddressforletter.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class SinglestdaddressforletterComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;
  class: Array<SelectList>;
  selected_Class_name: string;
  majors: Array<SelectList>;
  category: string = "";
  StudentDetails: Array<IStudentDetail>;
  selected_st_rn: number;
  selected_st_year: number;
  ending_array: Array<IStudentDetail>;
  final_array: Array<IStudentDetail>;
  selected_end_rn: any;
  selected_end_year: any;
  selected_major_name: string;

  constructor(
    private degree: DegreeprintService,
    private lmsSharedService: LMSharedDataService) {
    this.class = [];
    this.majors = [];
    this.StudentDetails = [];
    this.ending_array = [];
    this.final_array = [];
  }

  ngOnInit(): void {
    this.updateClasses();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.class = classes;
        console.log(this.class)
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateMajors() {
    this.majors.length = 0;
    this.lmsSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  OnClassChange() {
    for (let i = 0; i < this.class.length; i++) {
      if (this.class[i].id == this.formRef.value.c_code) {
        this.selected_Class_name = this.class[i].nm;
      }
    }
    this.updateMajors();
  }

  getSelectedMajorName() {
    for (let i = 0; i < this.majors.length; i++) {
      if (this.majors[i].id == this.formRef.value.maj_id) {
        this.selected_major_name = this.majors[i].nm;
      }
    }
  }

  OnCategoryChange() {
    this.getSelectedMajorName();
    this.StudentDetails = [];
    this.ending_array = [];
    if (this.formRef.value.category === "Urgent") {
      this.category = "U";
    }
    else if (this.formRef.value.category === "Regular") {
      this.category = "R";

    }
    else if (this.formRef.value.category === "Duplicate") {
      this.category = "D"
    }
    this.degree.getStudentAddressByS(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.notifDate,
      this.formRef.value.notifDate1, this.category).subscribe((data: any) => {

        for (let i = 0; i < data.length; i++) {
          this.StudentDetails.push({ address: data[i].ADD1, F_name: data[i].F_NM, name: data[i].NM, ROLNO: data[i].ROLNO, rn: data[i].rn, year: data[i].year });
        }
      })
  }

  OnStartRnChange(val: HTMLSelectElement) {
    this.ending_array = [];
    let j = val.value.toString();
    this.StudentDetails.forEach(el => {
      if (el.ROLNO == j) {
        this.selected_st_rn = el.rn;
        this.selected_st_year = el.year
      }
    })

    if (val.value) {
      this.degree.getStudentDetailsByEndRolNo(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.notifDate,
        this.formRef.value.notifDate1, this.category, this.selected_st_rn, this.selected_st_year).subscribe((data: any) => {
          for (let i = 0; i < data.length; i++) {
            this.ending_array.push({ address: data[i].ADD1, F_name: data[i].F_NM, name: data[i].NM, ROLNO: data[i].ROLNO, rn: data[i].rn, year: data[i].year });
          }
        })
    }
    else {
      alert("Please click on Roll no ")
    }
  }

  onClearForm(fo: NgForm) {
    this.ending_array = [];
    this.final_array = [];
    fo.resetForm();
  }

  OnEndRnChange(fval: HTMLSelectElement, val: HTMLSelectElement) {
    this.final_array = [];

    let j = val.value.toString();
    let k = fval.value.toString();
    this.StudentDetails.forEach(el => {
      if (el.ROLNO == k) {
        this.selected_st_rn = el.rn;
        this.selected_st_year = el.year
      }
    });

    this.ending_array.forEach(el => {
      if (el.ROLNO == j) {
        this.selected_end_rn = el.rn;
        this.selected_end_year = el.year;
      }
    });

    this.degree.getStudentAddressBetweenSt_end_rnByS(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.notifDate,
      this.formRef.value.notifDate1, this.category, this.selected_st_rn, this.selected_end_rn, this.selected_st_year, this.selected_end_year).subscribe((data: any) => {
        for (let i = 0; i < data.length; i++) {
          this.final_array.push({ address: data[i].ADD1, F_name: data[i].F_NM, name: data[i].NM, ROLNO: data[i].ROLNO, rn: data[i].rn, year: data[i].year });
        }
      })
  }

  OnPdfCreate() {
    const doc = new jsPDF("p", "mm", [215.9, 329.4]);
    let pageHeight = 300;
    let y = 250;
    let y_axis = 40;
    let x_rn = 36;
    let y_rn = 49;
    let y_name = 60;
    let y_fname = 71;
    let y_address = 82;
    let page;

    function createCard(item) {
      if ((y_axis || x_rn || y_rn || y_name || y_fname || y_address) >= pageHeight){
        doc.addPage();
      y = 250;
      y_axis = 40;
      x_rn = 36;
      y_rn = 49;
      y_name = 60;
      y_fname = 71;
      y_address = 82;
      }
    }

      // page=doc.getNumberOfPages();
    doc.addFileToVFS('OLDENGL.ttf', oldeng);
    doc.addFont('OLDENGL.ttf', 'OLDENGL', 'bold');
    doc.addFileToVFS('FontsFree-Net-ariblk-normal.ttf', arialblack);
    doc.addFont('FontsFree-Net-ariblk-normal.ttf', 'Arial', 'black');
    doc.addFileToVFS('Arialn-normal.ttf', font);
    doc.addFont('Arialn-normal.ttf', 'Arial', 'normal');
    doc.setFontSize(26);
    doc.setFont('OLDENGL', "bold");
    doc.text("GC University Lahore", 102, 13, { align: 'center' }); //titile
    doc.setFontSize(13);
    doc.setFont("Arial"); //class
    doc.text(`Class: ${this.selected_Class_name}`, 102, 22, { align: 'center' });
    doc.setFontSize(13);
    doc.setFont("Arial"); //Major
    doc.text(`Group: ${this.selected_major_name}`, 102, 30, { align: 'center' });

    for (let i = 0; i < this.final_array.length; i++) {
      doc.setFontSize(13);
      doc.rect(31, y_axis, 150, 55);    // x- axis, y-axis,  width, height
      doc.text(`Roll No    :`, x_rn, y_rn);
      doc.setFontSize(12);
      const textWidth = doc.getTextWidth(this.final_array[i].ROLNO);
      doc.setDrawColor(0, 0, 0, 1);
      doc.line(x_rn + 25, y_rn + 1, x_rn + 25 + textWidth, y_rn + 1)
      doc.text(`${this.final_array[i].ROLNO}`, x_rn + 25, y_rn);

      //Name
      doc.setFontSize(13);
      doc.text(`Name       :`, x_rn, y_name);
      doc.setFontSize(12);
      const textWidth2 = doc.getTextWidth(this.final_array[i].name);
      doc.setDrawColor(0, 0, 0, 1);
      doc.line(x_rn + 25, y_name + 1, x_rn + 25 + textWidth2, y_name + 1)
      doc.text(`${this.final_array[i].name}`, x_rn + 25, y_name);

      //FATHER NAME
      doc.setFontSize(13);
      doc.text(`F.Name   :`, x_rn, y_fname);
      doc.setFontSize(12);
      const textWidth3 = doc.getTextWidth(this.final_array[i].F_name);
      doc.setDrawColor(0, 0, 0, 1);
      doc.line(x_rn + 25, y_fname + 1, x_rn + 25 + textWidth3, y_fname + 1)
      doc.text(`${this.final_array[i].F_name}`, x_rn + 25, y_fname);

      //Address
      doc.setFontSize(13);
      doc.text(`Address   :`, 36, y_address);
      doc.setFontSize(12);
      let str = doc.splitTextToSize(this.final_array[i].address, 100)
      const textWidth4 = doc.getTextWidth(str[0]);
      let textWidth5;

      doc.setDrawColor(0, 0, 0, 1);
      doc.line(x_rn + 25, y_address + 1, x_rn + 25 + textWidth4, y_address + 1)
      doc.text(`${str[0]}`, x_rn + 25, y_address);
      if (str.length > 1) {
        textWidth5 = doc.getTextWidth(str[1]);
        doc.line(x_rn + 25, y_address + 7 + 1, x_rn + 25 + textWidth5, y_address + 7 + 1)
        doc.text(`${str[1]}`, x_rn + 25, y_address + 7);
      }

      // for (var k = 1; k <= page; k++) {
      //   doc.setPage(k);
      //   doc.text('Page ' + k + " of " + page, 102, pageHeight-20, {align: 'center'});
      // }

      y_axis = y_axis + 70;
      y_rn = y_axis + 10;
      y_name = y_axis + 20;
      y_fname = y_axis + 30;
      y_address = y_axis + 40;

      createCard(this.final_array[i])
    }
    // doc.save("Addresses.pdf");
    window.open(URL.createObjectURL(doc.output('blob')), 'Student_Address_For_letter');
  }
}