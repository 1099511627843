<form (ngSubmit)="OnSubmit()" #f="ngForm">
    <div [@SlideInFromLeft] class="container-fluid ">
      <div class="card shadow">
        <div class="card-header"  style="padding-top: 10px;">
          <div class=" col-lg-6 col-md-6 form-group">
            ReAdmission/ Rejoin/ Semester Unfreeze</div>
             <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
            <app-details-button [details]="details"></app-details-button>
        </div>
      </div>
        <div class="row card-body"> 
  
          <div class="col-lg-4 col-md-6 form-group">
            <label for="C_Code">Class</label>
            <select #cource (change)="OnClassChange(cource)" class="form-control " name="c_code" id="C_Code" ngModel>
              <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
            </select>
          </div>
  
          <div class="col-lg-4 col-md-6 form-group">
            <label for="class" class="text-left">Year</label>
            <input class="form-control" name="year_in" id="year_endTime" #input ngModel required>
          </div>
  
          <div class="col-lg-4 col-md-6 form-group">
            <label for="Session">Existing Session</label>
            <select class="form-control " #session (change)="OnSessionChange(session)" name="se_id" id="Session" ngModel>
              <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
            </select>
          </div>

        <div class="col-lg-4 col-md-6  form-group">
          <label for="cat">Category</label>
          <select #cat class="form-control " name="cat" ngModel required>
            <option value="RF">Re-Admission Forthcoming</option>
            <option value="RC">Re-Admission Current</option>
            <option value="RE">Rejoin</option>
          </select>
        </div>


        <div *ngIf="cat.value!=='RC'" class="col-lg-4 col-md-6 form-group">
          <label for="Session">New Session</label>
          <select class="form-control " #session (change)="OnSessionChange(session)" name="new_se_id" ngModel>
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>


        <div class="col-lg-4 col-md-6  form-group">
          <label for="class" class="text-left">Roll No</label>
          <input class="form-control" name="rn" id="roll_endtime" (change)='OnRollNoChange(f)' #input ngModel required>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="semester">Semester</label>
          <select #semester class="form-control" (change)='OnSemesterChange()' name="t_no" id="Semester" required
            ngModel>
            <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]" value="{{i}}">{{i}}</option>
          </select>
        </div>
      </div>

      <div class="row card-body">
          <button (click)="upadteStudentName()" class="btn  btn-outline-primary" [disabled]="!f.valid">Search Student</button>

          <button (click)="AddRecord()" class="btn btn-outline-success" [disabled]="!f.valid">Add Record</button>

          <button class="btn btn-outline-warning" type="button" (click)="ClearForm(f)">Clear Form</button>
      </div>

      <div *ngIf="Std?.rolno" class=" card-header mt-5">
        Student Record
      </div>
      <div *ngIf="Std?.rolno" class="card-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Class</th>
              <th scope="col">Major</th>
              <th scope="col">Session</th>
              <th scope="col">Roll No</th>
              <th scope="col">Name</th>
              <th scope="col">RN</th>
              <th scope="col">Father Name</th>
              <th scope="col">Year</th>
              <th scope="col">Remarks</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{Std.c_nm}}</td>
              <td>{{Std.maj_nm}}</td>
              <td>{{Std.session}}</td>
              <td>{{Std.rolno}}</td>
              <td>{{Std.nm}}</td>
              <td>{{Std.rn}}</td>
              <td>{{Std.f_nm}}</td>
              <td>{{Std.year}}</td>
              <td>{{Std.remarks}}</td>
              <td><button class="button btn btn-outline-danger fa fa-trash" (click)="deleteStudent(Std.rolno)"></button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>