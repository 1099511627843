<div id="main-container" class="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar" [@FadeIn]
  (click)="OnMainComponentClick($event)">

  <div class="app-header header-shadow">
    <div class="app-header__logo">
      <a>
        <div class="logo-src">
        </div>
      </a>
      <div class="header__pane ml-auto">
        <div>
          <button id="navBarHamBtn-lg" type="button" class="hamburger close-sidebar-btn hamburger--elastic"
            (click)="OnnavBarHamBtn_lgClicked(); OnnavBarHamBtn_lgClickedLocal()" data-class="closed-sidebar">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button id="navBarHamBtn" type="button" class="hamburger hamburger--elastic mobile-toggle-nav"
          (click)="OnnavBarHamBtnClicked()">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
      <div class="collapse" id="contentId">
        <div class="card" style="margin-top: 250px;margin-right: -40px;">
          <div class="card-header" style="color:white;min-width: 200px;background-color:rgba(0,105,217,0.63);">
            {{this.teacherName}}
          </div>
          <div class="card-body">
            <!-- <div>
              <button class="btn btn-link">Settings</button>
            </div> -->
            <div>
              <button class="btn btn-link" (click)="onClickNotification()">Notification</button>
            </div>
            <div>
              <button class="btn btn-link" (click)="OnChangePasswordClicked()">Change Password</button>
            </div>
            <div>
              <button class="btn btn-link" (click)="onLogout()">Logout</button>
            </div>
          </div>
        </div>
      </div>
      <span>
        <button id="app-header-mobile-menu" type="button"
          class="fixed btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav" data-toggle="collapse"
          data-target="#contentId" aria-expanded="false" aria-controls="contentId" (click)="OnAppHeaderMobileMenu()">
          <span class="btn-icon-wrapper">
            <i class="fa fa-ellipsis-v fa-w-6"></i>
          </span>
        </button>
      </span>
    </div>
    <div id="content_mobile" class="app-header__content hider">
      <div class="row" style="justify-content:space-between">
        <div class="app-header-left">
          <div id="heading">
            <span>GCU Management System</span>
            <h6 style="display: inline; font-size: 10px; color: darkgray; margin-left: 5px;">V03.05.11</h6>
          </div>
        </div>

      </div>
      <div class="app-header-right">


        <div class="header-btn-lg pr-0">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">

              <div class="widget-content-left">

                <div id="profile-btn" class="btn-group">
                  <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="p-0 btn">
                    <img width="42" class="rounded-circle" src="../../assets/images/avatars/1.png" alt="">
                    <i class="fa fa-angle-down ml-2 opacity-8"></i>
                  </a>
                  <ng-container *ngTemplateOutlet="id"></ng-container>
                  <ng-template #id>
                    <div tabindex="-1" role="menu" aria-hidden="true" id="menu-header-desktop"
                      class="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right">
                      <div class="dropdown-menu-header">
                        <div class="dropdown-menu-header-inner bg-info">
                          <div class="menu-header-image opacity-2"
                            style="background-image: url('../../assets/images/dropdown-header/city3.jpg');"></div>
                          <div class="menu-header-content text-left">
                            <div class="widget-content p-0">
                              <div class="widget-content-wrapper">
                                <div class="widget-content-left mr-3">
                                  <img width="42" class="rounded-circle" src="../../assets/images/avatars/1.png" alt="">
                                </div>
                                <div class="widget-content-left">
                                  <div class="widget-heading">{{this.teacherName}}</div>
                                </div>
                                <div class="widget-content-right mr-2">
                                  <button class="btn btn-link text-white" (click)="onLogout()">
                                    Logout
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="scroll-area-xs" style="height: 150px;">
                        <div class="scrollbar-container ps">
                          <ul class="nav flex-column">
                            <li class="nav-item-header nav-item">Activity</li>
                            <li class="nav-item">
                              <a href="javascript:void(0);" class="nav-link"
                                (click)="onClickNotification()">Notification
                              </a>
                            </li>
                            <li class="nav-item">
                              <a href="javascript:void(0);" class="nav-link" (click)="OnChangePasswordClicked()">Change
                                Password
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
              <div class="widget-content-left  ml-3 header-user-info">
                <div class="widget-heading">
                  {{this.teacherName}}
                </div>
                <div class="widget-subheading">
                  {{this.FM_ID}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="app-main">
    <div class="app-sidebar sidebar-shadow">
      <div class="app-header__logo">
        <div class="logo-src"></div>
        <div class="header__pane ml-auto">
          <div>
            <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="app-header__mobile-menu">
        <div>
          <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
      <div class="app-header__menu">
        <span>
          <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
            <span class="btn-icon-wrapper">
              <i class="fa fa-ellipsis-v fa-w-6"></i>
            </span>
          </button>
        </span>
      </div>
      <div class="scrollbar-sidebar scroll-hide pb-5" id="side-bar">
        <div class="app-sidebar__inner scroll-hide">
          <ul class="vertical-nav-menu">
            <!-- <li class="app-sidebar__heading">Service Request</li>
            <marquee *ngIf="navActionLocal == false" style="font-weight:bold;color:red">If you have any Problem. Drop your query on SRM</marquee>
            <li>
              <a (click)="onClickSRMS()">
                <i class="metismenu-icon fa fa-envelope"></i>
                SRM
              </a>
            </li> -->

            <li class="app-sidebar__heading">Menu</li>
            <li>
              <a (click)="onHomeClicked()">  
                <i class="metismenu-icon">
                <img src="../../../assets/home.svg" class="collapse-arrow" style="width: 2rem; margin-left: 1px; margin-top: -3px"
                alt=""></i>
              Home</a>
              <a *ngIf="isAdmin || isHod || isDashboard" routerLink="dashboard"> 
                <i class="metismenu-icon">
                <img src="../../../assets/dashboard-new.svg" class="collapse-arrow" style="width: 1.9rem; margin-top: -3px"
                alt=""></i>Dashboard</a>
            </li>
            <li>
              <a (click)="OnTeacherInfoClicked()">
                <i class="metismenu-icon">
                  <img src="../../../assets/profile.svg" class="collapse-arrow" style="width: 2rem; margin-left: 1px; margin-top: -3px"
                  alt=""></i>
                Profile
              </a>
            </li>
            <li *ngIf="(isAdmin || canReadmission)" class="app-sidebar__heading">Set-up Tables</li>

            <li class="list-item">
              <a *ngIf="(isAdmin || canReadmission)" (click)="navigate('ClassMajorRollno')">
                <i class="metismenu-icon">
                  <img src="../../../assets/class.svg" class="collapse-arrow" style="width: 2.3rem" alt="">
                </i>
                Add/Update Class-Major
              </a>
            </li>
            <li *ngIf="(isTeacher || canAccessStudentProfile)" class="app-sidebar__heading">Student
              Actions</li>
            <li tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              (click)="OnShowMenuListItem('menu-list-1')">
              <a *ngIf="(isTeacher)">
                <i class="metismenu-icon">
                  <img src="../../../assets/course-up.svg" class="collapse-arrow ml-1" style="width: 2.2rem"
                  alt="">
                </i>
                Course Upload
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse" (click)="$event.stopPropagation(); $event.preventDefault()">

                <li class="list-item">
                  <a *ngIf="(isTeacher )" (click)="OncourseUploadClicked()">
                    Course Material
                  </a>
                </li>

                <li class="list-item">
                  <a *ngIf="(isTeacher )" (click)="onCourseOutlineClicked()">
                    Course Outline
                  </a>
                </li>
              </ul>
            </li>

            <li (click)="OnAttendanceClicked('li-attendance')" class="list-item" id="li-attendance">
              <a *ngIf="(isTeacher)">
                <i class="metismenu-icon">
                  <img src="../../../assets/attendance.svg" class="collapse-arrow ml-1" style="width: 2rem"
                  alt="">
                </i>
                Attendance
              </a>
            </li>

            <li class="list-item">
              <a *ngIf="superAdmin" (click)="OnOverallAttendanceClick()" class="list-item-link">
                <i class="metismenu-icon">
                  <img src="../../../assets/att-rep.svg" class="collapse-arrow" style="width: 2.1rem"
                  alt="">
                </i>
                Overall Attendance Report
              </a>
            </li>

            <li (click)="OnViewStudentProfileClicked()" class="list-item">
              <a *ngIf="(canAccessStudentProfile)">
                <i class="metismenu-icon">
                  <img src="../../../assets/attendance-profile.svg" class="collapse-arrow" style="width: 1.8rem"
                  alt="">
                </i>
                Attendance Profile
              </a>
            </li>

            <li class="list-item">
              <a *ngIf="(isTeacher)" (click)="OnAssignmentClicked()">
                <i class="metismenu-icon">
                  <img src="../../../assets/assessment.svg" class="collapse-arrow" style="width: 2.1rem"
                  alt="">
                </i>
                Assessment
              </a>
            </li>

            <!-- <li *ngIf="(isTeacher && canManageAwardList)"> -->
            <li class="list-item" id="li-award-list">
              <a *ngIf="(isTeacher)" (click)="navigateToManageAwardlist('li-award-list')" class="list-item-link">
                <!-- <i class="metismenu-icon fa fa-award"></i> -->
                 <i class="metismenu-icon" >
                  <img src="../../assets/awardlist.png" class="collapse-arrow" alt="" style="width: 2.1rem">
                 </i>
                Awardlist
              </a>
            </li>
            <li *ngIf="(isTeacher)" class="app-sidebar__heading">Student Queries</li>
            <li>
              <a *ngIf="(isTeacher)" (click)="OnStudentQueriesClicked()">
                <i class="metismenu-icon">
                  <img src="../../../assets/queries.svg" class="collapse-arrow" style="width: 1.9rem"
                  alt="">
                </i>
                Student Queries
              </a>
            </li>
            <!-- 
              TEACHER SIDE
            -->
            <!--  isTeacher  superAdmin -->
            <!-- <li *ngIf="isTeacher" class="app-sidebar__heading">Subject Feedback Report</li>
            <li *ngIf="isTeacher">
              <a (click)="onTeacherSideEvaluationReport()">
                <i class="metismenu-icon fas fa-receipt" aria-hidden="true">
                </i>FeedBack Report
              </a>
            </li> -->

            <!-- 
              FACTULTY FEEDBACK EVALUATION
             -->
            <!-- (superAdmin || isQEC) -->
            <!-- <li *ngIf="(isQEC)" class="app-sidebar__heading">Faculty Feedback Evaluation</li>
            <li *ngIf="isQEC">
              <a (click)="onEvaluationForms()">
                <i class="metismenu-icon fa fa-wpforms" aria-hidden="true">
                </i>Evaluation Forms
              </a>
            </li> -->

            <!-- <li *ngIf="isQEC">
              <a (click)="onEvaluationCompileResponse()">
                <i class="metismenu-icon fas fa-receipt" aria-hidden="true">
                </i>Compile Responses
              </a>
            </li> -->

            <!-- <li *ngIf="isQEC">
              <a (click)="onEvaluationFormReports()">
                <i class="metismenu-icon fas fa-receipt" aria-hidden="true">
                </i>Reports
              </a>
            </li> -->

            <!-- <li *ngIf="isQEC">
              <a (click)="onEvaluationResponseClick()">
                -- <i class="metismenu-icon fa fa-square-info"></i>
                <i class="metismenu-icon fa fa-info"></i>
                Responses Detail
              </a>
            </li> -->


            <!-- <li> <a (click)="onTeacherAvailClicked()"> <i></i> Teacher Available</a> </li> -->


            <li *ngIf="(isAdmin || isExamCell || canEnroll || canEnrolleRepeater
              || canManageTimetable || canAddSubject ||canReadmission||canRecoverUser)" class="app-sidebar__heading">
              Admin
            </li>

            <li tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              (click)="OnShowMenuListItem('menu-list-1')">
              <a *ngIf="(canManageTimetable)">
                <i class="metismenu-icon">
                  <img src="../../../assets/timetable.svg" class="collapse-arrow " style="width: 2.3rem"
                  alt="">
                </i>
                Manage Timetable
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse" (click)="$event.stopPropagation(); $event.preventDefault()">

                <li>
                  <a *ngIf="(canManageTimetable)" (click)="OnInsertTimeTable()">
                    <i class="metismenu-icon fa fa-question">
                    </i>Insert Time Table
                  </a>
                </li>

                <li>
                  <a *ngIf="(canManageTimetable)" (click)="OnViewTimeTable()">
                    <i class="metismenu-icon fa fa-calendar-o">
                    </i>View Time Table
                  </a>
                </li>
              </ul>
            </li>

            <li tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              (click)="OnShowMenuListItem('menu-list-1')">
              <a *ngIf="(isExamCell || canAddSubject || canManageTimetable)">
                <i class="metismenu-icon">
                  <img src="../../../assets/subject.svg" class="collapse-arrow" style="width: 2.3rem"
                  alt="">
                </i>
                Subjects
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse" (click)="$event.stopPropagation(); $event.preventDefault()">

                <li>
                  <a *ngIf="(isExamCell)" (click)="OnAddSub()">
                    <i class="metismenu-icon fa fa-question">
                    </i>Add Subject
                  </a>
                </li>

                <li>
                  <a *ngIf="( canAddSubject || isExamCell || canManageTimetable)" (click)="OnSubDisplay()">
                    <i class="metismenu-icon fa fa-question">
                    </i>Subjects Display
                  </a>
                </li>

                <li>
                  <a *ngIf="(canManageTimetable||isExamCell)" (click)="OnSubOffer()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Subject Offer
                  </a>
                </li>
                <li>
                  <a *ngIf="(isExamCell)" (click)="OnSubOfferShift()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Auto Subject Offer
                  </a>
                </li>

                <li (click)="OnSubjectOfferReportClick()">
                  <a *ngIf="superAdmin">
                    Offered Subjects Report
                  </a>
                </li>
              </ul>
            </li>


            <li tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              (click)="OnShowMenuListItem('menu-list-1')">
              <a
                *ngIf="(canAllocateSection || isAdmin || canEnroll || canEnrolleRepeater || canReadmission || canIDC || isExamCell)">
                <i class="metismenu-icon">
                  <img src="../../../assets/enrollment.svg" class="collapse-arrow" style="width: 2.2rem"
                  alt="">
                </i>
                Enrollment
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse" (click)="$event.stopPropagation(); $event.preventDefault()">
                <li (click)="OnEnrocomClick()">
                  <a *ngIf=" (canEnroll)">
                    <i class="metismenu-icon fa fa-pie-chart"></i>
                    Enrollment</a>
                </li>
                <li *ngIf="(isAdmin || isExamCell || canEnrolleRepeater)">
                  <a (click)="navigate('ClassEnrollmentSetNo')">
                    Class Detail for Enrollment
                  </a>
                </li>
                <li>
                  <a *ngIf="(canAllocateSection)" (click)=" onSectionAllocate()">
                    <i class="metismenu-icon fa fa-question">
                    </i>Allocate Section
                  </a>
                </li>


                <li>
                  <a *ngIf="(isAdmin || canIDC)" (click)=" onIDCSectionAllocate()">
                    <i class="metismenu-icon fa fa-question">
                    </i>IDC Section Allocation
                  </a>
                </li>


                <li>
                  <a *ngIf="(isAdmin || canEnrolleRepeater || isExamCell)" (click)="OnSubjectEnrollment()">
                    <i class="metismenu-icon fa fa-question">
                    </i>Subject Repeater Enrollment
                  </a>
                </li>

                <li>
                  <a *ngIf="(isAdmin)" (click)="OnCheckEnrollmentClicked()">
                    Check Enrollment Status
                  </a>
                </li>

                <li>
                  <a *ngIf="(isAdmin || canReadmission)" (click)=" OnReAdmission()">
                    <i class="metismenu-icon fa fa-question">
                    </i>Re-Admission
                  </a>
                </li>
                <li *ngIf="superAdmin" (click)="OnEnrepoClick()">
                  <a>
                    <i class="metismenu-icon fa fa-pie-chart"></i>Reports</a>
                </li>
              </ul>
            </li>


            <li tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              (click)="OnShowMenuListItem('menu-list-1')">
              <a *ngIf="(isAdmin  || canAllocateSection || canManageTimetable || isExamCell||canRecoverUser)">
                <!-- <i class="metismenu-icon fa fa-cogs"></i> -->
                Utility
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
                 <i class="metismenu-icon">
                  <img src="../../assets/utility.png" class="collapse-arrow" style="width: 2.1rem" alt="">
                 </i>
              </a>

              <ul class="mm-collapse" (click)="$event.stopPropagation(); $event.preventDefault()">

                <li>
                  <a *ngIf="(isAdmin)" (click)="navigate('register')">
                    <i class="metismenu-icon fa fa-calendar-o">
                    </i>Settings
                  </a>
                </li>

                <li>
                  <a *ngIf="(isAdmin)" (click)="OnClassShift()">
                    <i class="metismenu-icon fa fa-calendar-o">
                    </i>Class Shifting
                  </a>
                </li>

                <li>
                  <a *ngIf="(isAdmin || canEnrolleRepeater)" (click)="onuserdetail()">
                    <i class="metismenu-icon fa fa-calendar-o">
                    </i>User Detail
                  </a>
                </li>

                <li (click)="onRecoverUserClicked()">
                  <a *ngIf="(canAllocateSection)||(stdDetail) || canEnrolleRepeater">
                    <i class=" metismenu-icon fa fa-undo"></i>
                    Recover User
                  </a>
                </li>
                <li>
                  <a *ngIf="(isAdmin||canManageTimetable)" (click)=" OnCheckTeacherAttendance()">
                    <i class="metismenu-icon fa fa-question">
                    </i>Faculty Report
                  </a>
                </li>

                <li>
                  <a *ngIf="(isAdmin || canEnrolleRepeater)" (click)=" onAdmissionForm()">
                    <i class="metismenu-icon fa fa-question">
                    </i>Admission Form
                  </a>
                </li>
                <li>
                  <a *ngIf="(canManageTimetable||isExamCell)" (click)="navigateToLocking()">
                    <i class="metismenu-icon fa fa-calendar-o">
                    </i>Manage Locking
                  </a>
                </li>
                <li>
                  <a *ngIf="(isExamCell)" (click)="navigate('calenderManagement')">
                    <i class="metismenu-icon fas fa-address-book">
                    </i>Calender Management
                  </a>
                </li>

                <li (click)="onSubmissionCheck()">
                  <a *ngIf="(isAdmin||canManageTimetable)">
                    Result Submission Check
                  </a>
                </li>

                <li>
                  <a *ngIf="(superAdmin)" (click)="onDBdetail()">
                    <i class="metismenu-icon fa fa-calendar-o">
                    </i>DB Schema Detail
                  </a>
                </li>

                <!------------------------------------- ADD SUBJECT COMBINATION--------------------------------- -->
                <!-- <li>
                  <a *ngIf="(canEnrolleRepeater||canReadmission)" (click)="navigate('addSubjectCombo')">
                    <i class="metismenu-icon fas fa-address-book">
                    </i>Add Subject Combinations
                  </a>
                </li> -->
              </ul>
            </li>
            <li tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              (click)="OnShowMenuListItem('menu-list-1')">
              <a
                *ngIf="(canAllocateSection || isAdmin || canEnroll||canReadmission || canEnrolleRepeater||canManageTimetable||stdDetail||isExamCell)">
                <i class="metismenu-icon">
                  <img src="../../../assets/student-action.svg" class="collapse-arrow" style="width: 1.9rem"
                  alt="">
                </i>
                Student Action
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse" (click)="$event.stopPropagation(); $event.preventDefault()">
                <li (click)="onEligibilityClicked()">
                  <a *ngIf="(isAdmin  || canAllocateSection || canManageTimetable || isExamCell)">
                    <i class="metismenu-icon fa fa-book "></i>
                    Eligibility
                  </a>
                </li>
                <li>
                  <a *ngIf="(isAdmin||canReadmission)" (click)="OnStudentLeaves()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Duty Leaves
                  </a>
                </li>
                <li>
                  <a *ngIf="(stdDetail ||canManageTimetable || isExamCell || isAdmin)" (click)="onCheckStudentDetail()">
                    <i class="metismenu-icon fas fa-address-book">
                    </i>Student Detail
                  </a>
                </li>
                <!-- <li>
                  <a *ngIf="(stdDetail ||canManageTimetable || isExamCell || isAdmin)"
                    (click)="onCheckStudentAdmissionDetail()">
                    <i class="metismenu-icon fas fa-address-book">
                    </i>Student Admission Detail
                  </a>
                </li> -->
                <li>
                  <a *ngIf="((canManageTimetable)||(stdDetail)||canEnrolleRepeater)"
                    (click)="navigate('StdTimetableList')">
                    <i class="metismenu-icon fa fa-calendar-o">
                    </i>Student Timetable
                  </a>
                </li>
                <li>
                  <a *ngIf="(canManageTimetable || isExamCell)" (click)="OnDepartmentReports()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Student List
                  </a>
                </li>
                <li>
                  <a *ngIf="(isExamCell)" (click)="onReElig()">
                     <i class="metismenu-icon fa fa-book ">
                    </i>Recalculate Eligibility
                  </a>
                </li>
                <li>
                  <a *ngIf="(stdDetail)" (click)="OnAdmChangeRecord()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Change Record
                  </a>
                </li>
                <li>
                <li>
                  <a *ngIf="(isAdmin || canReadmission || canEnrolleRepeater)" (click)="navigate('FreezeSemester')">
                    <i class="metismenu-icon fa fa-book">
                    </i>Struck off
                  </a>
                </li>
                <li>
                  <a *ngIf="(isExamCell)" (click)="onlockstd()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Lock Student
                  </a>
                </li>
                <li>
                  <a *ngIf="(superAdmin)" (click)="OnDetailedStudentClick()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Detailed Student
                  </a>
                </li>
              </ul>
            </li>
            <li *ngIf="isAdmin || isExamCell || canEnrolleRepeater || canReadmission" class="app-sidebar__heading">
              Reports</li>

            <!-- <li tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              *ngIf="isAdmin || isExamCell || canEnrolleRepeater || canReadmission"
              (click)="OnShowMenuListItem('menu-list-1')">

              <a>
                <i class="metismenu-icon fa  fa-book"></i>
                General Reports
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse">
                <li>
                  <a (click)="OnGenderSt()">Gender Wise Strength</a>
                </li>
              </ul>
            </li> -->
            <li tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              (click)="OnShowMenuListItem('menu-list-1')"
              *ngIf="isAdmin || isExamCell || canEnrolleRepeater || canReadmission">

              <a>
                <i class="metismenu-icon">
                  <img src="../../../assets/merit.svg" class="collapse-arrow" style="width: 2.1rem"
                  alt="">
                </i>
                Merit List Reports
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse"  (click)="$event.stopPropagation(); $event.preventDefault()">
                <li>
                  <a (click)="OnMeritListInfo()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Merit List Report
                  </a>
                </li>
                <li>
                  <a (click)="OnBoardWise()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Board Wise Strength
                  </a>
                </li>
              </ul>
            </li>
            <li tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              *ngIf="isAdmin || isExamCell || canEnrolleRepeater || canReadmission"
              (click)="OnShowMenuListItem('menu-list-1')">

              <a>
                <i class="metismenu-icon">
                  <img src="../../../assets/adm-std.svg" class="collapse-arrow ml-1" style="width: 2rem"
                  alt="">
                </i>
                Admitted Students Report
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse"  (click)="$event.stopPropagation(); $event.preventDefault()">
                <li>
                  <a (click)="OnStudentGeneralInfo()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Student General Information
                  </a>
                </li>
                <li>
                  <a (click)="OnOverall()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Dump Report
                  </a>
                </li>
                <li>
                  <a (click)="OnSetWiseClassStrenght()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Set Wise Class Strength
                  </a>
                </li>
                <li>
                  <a (click)="onFreezeReport()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Struck-off Reports
                  </a>
                </li>
                <li *ngIf="(isAdmin)">
                  <a (click)="navigate('ClassEnrollmentSetNo')">
                    <i class="metismenu-icon fa fa-book"></i> Students Set Combination Detail
                  </a>
                </li>
              </ul>
            </li>
            <li tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              *ngIf="isAdmin || isExamCell || canEnrolleRepeater || canReadmission"
              (click)="OnShowMenuListItem('menu-list-1')">
              <a>
                <i class="metismenu-icon">
                  <img src="../../../assets/en-rep.svg" class="collapse-arrow ml-1" style="width: 2rem"
                  alt="">
                </i>
                Enrollment Reports
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse"  (click)="$event.stopPropagation(); $event.preventDefault()">
                <li>
                  <a (click)="OnEnrollmentStatusReport()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Enrollment Status
                  </a>
                </li>
                <li>
                  <a (click)="OnSubjectWiseEnrollmentCount()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Subject Wise Enrollment Count
                  </a>
                </li>
                <li>
                  <a (click)="OnSubjectWiseStrength()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Subject Wise Strength
                  </a>
                </li>
              </ul>
            </li>

            <li *ngIf="( isExamCell  || isAdmin || canEnrolleRepeater)" class="app-sidebar__heading">Examination
            </li>

            <li tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              (click)="OnShowMenuListItem('menu-list-1')">

              <a *ngIf="( isExamCell || isAdmin || canEnrolleRepeater)">
                <i class="metismenu-icon">
                  <img src="../../../assets/compile-res.svg" class="collapse-arrow" style="width: 2rem; margin-left: 1px;"
                  alt=""></i>
                Compile Results
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse" (click)="$event.stopPropagation(); $event.preventDefault()">

                <li (click)="onQueryCheck($event)">
                  <a *ngIf="( isExamCell || isAdmin)">
                    Insert Repeater Awardlist
                  </a>
                </li>

                <li>
                  <a *ngIf="(isExamCell)" (click)="navigate('singleStdAwardlist')">
                    <i class="metismenu-icon fa fa-book">
                    </i>Single Std Attendance/Awardlist
                  </a>
                </li>

                <li>
                  <a *ngIf="(isExamCell)" (click)="OnAwardListClicked()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Award List
                  </a>
                </li>

                <!-- not used
                  <li>
                  <a *ngIf="(isExamCell||isAdmin )" (click)="GenerateSingleStdAwardList()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Shift Single Student AwardList
                  </a>
                </li> -->

                <li>
                  <a *ngIf="(isExamCell || canEnrolleRepeater)" (click)="navigate('subjectReplacement')">
                    <i class="metismenu-icon fas fa-address-book">
                    </i>Exam Result Correction
                  </a>
                </li>

                <li>
                  <a *ngIf="(isExamCell)" (click)="navigate('resultTransfer')">
                    <i class="metismenu-icon fas fa-address-book">
                    </i>Result Transfer
                  </a>
                </li>

                <li>
                  <a *ngIf="(isExamCell)" (click)=" OnExamResult()">
                    <i class="metismenu-icon fa fa-result">
                    </i>Compile Exam Result
                  </a>
                </li>

                <li>
                  <a *ngIf="(isExamCell)" (click)="navigate('ResultAlterStd')">
                    <i class="metismenu-icon fa fa-calendar"></i>
                    Alter Student Result
                  </a>
                </li>
                <li>
                  <a *ngIf="(isExamCell)" (click)="onResultCompile()">
                    <i class="metismenu-icon fa fa-calendar"></i>
                    Exam Summary
                  </a>
                </li>

              </ul>
            </li>
            <li *ngIf="(isExamCell  || isAdmin)" tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              (click)="OnShowMenuListItem('menu-list-1')">
              <a>
                <i class="metismenu-icon">
                  <img src="../../../assets/exam-rep.svg" class="collapse-arrow ml-1" style="width: 2rem; "
                  alt="">
                </i>
                Reports
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse" (click)="$event.stopPropagation(); $event.preventDefault()">
                <!-- <li (click)="ExaminationReports()" *ngIf="(isAdmin || isExamCell)">
                  <a>
                    <i class="metismenu-icon fa fa-book "></i>
                    Examination Reports
                  </a>
                </li> -->
                <li>
                  <a (click)="OnProgressReportClick()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Progress Report
                  </a>
                </li>

                <li>
                  <a (click)="OnTransciptClicked()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Transcript
                  </a>
                </li>

              </ul>
            </li>

            <li *ngIf="(isExamCell  || isAdmin)">
              <a (click)="onLetterGrade()">
                <i class="metismenu-icon">
                  <img src="../../../assets/grades.svg" class="collapse-arrow mt-1" style="width: 2.1rem; margin-left: 5px"
                  alt="">
                </i>
                Letter Grade
              </a>
            </li>


            <!-- degreee screens-->
            <li *ngIf="(isExamCell  || isAdmin)" tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              (click)="OnShowMenuListItem('menu-list-1')">
              <a>
                <i class="metismenu-icon">
                  <img src="../../../assets/degree.svg" class="collapse-arrow" style="width: 2.4rem; margin-left: -4px"
                  alt="">
                </i>
                Degree
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse" (click)="$event.stopPropagation(); $event.preventDefault()">
                <li (click)="StatusOfTranscriptReportComponent()" *ngIf="(isAdmin || isExamCell)">
                  <a>
                    <i class="metismenu-icon fa fa-book "></i>
                    Status Of Transcript Report
                  </a>
                </li>
                <li (click)="SignatureForDegreeHolder()" *ngIf="(isAdmin || isExamCell)">
                  <a>
                    <i class="metismenu-icon fa fa-book "></i>
                    Signature For Degree Holders
                  </a>
                </li>
                <li (click)="EligbleCandidateForDegree()" *ngIf="(isAdmin || isExamCell)">
                  <a>
                    <i class="metismenu-icon fa fa-book "></i>
                    Eligble Candidate For Degree
                  </a>
                </li>
                <li (click)="degreeholderforgovernerhouse()" *ngIf="(isAdmin || isExamCell)">
                  <a>
                    <i class="metismenu-icon fa fa-book "></i>
                    Deg Holders For Governor House
                  </a>
                </li>
                <li (click)="singlestdaddressforletters()" *ngIf="(isAdmin || isExamCell)">
                  <a>
                    <i class="metismenu-icon fa fa-book "></i>
                    Student Addresses For Letters
                  </a>
                </li>

                <li>
                  <a (click)="OnDegreeRecieveInfoClicked()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Degree Receive Info
                  </a>
                </li>
                <li>
                  <a (click)="OnDegreePrintClicked()">
                    <i class="metismenu-icon fa fa-book">
                    </i>Degree Print Report
                  </a>
                </li>


              </ul>
            </li>
            <li (click)="navigate('inter')" *ngIf="(isExamCell  || isAdmin)" tabindex="0" id="menu-list-1">
              <a>
                <i class="metismenu-icon">
                  <img src="../../../assets/inter-new.svg" class="collapse-arrow" style="width: 2.1rem"
                  alt="">
                </i>
                Intermediate
                <!-- <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i> -->
              </a>
            </li>

            <li (click)="navigate('inter_seating')" *ngIf="(isAdmin || canManageTimetable || isExamCell)" tabindex="0" id="menu-list-1">
              <a>
                <i class="metismenu-icon">
                  <img src="../../../assets/seating.svg" class="collapse-arrow" style="width: 1.9rem"
                  alt="">
                </i>
                Seating Plan
              </a>
            </li>

            <!-- <ul class="mm-collapse"> -->

            <!-- <li (click)="navigate('inter')" *ngIf="(isAdmin || isExamCell)">
               <a>
                 <i class="metismenu-icon fa fa-book "></i>
                 Inter Awardlist
               </a>
             </li> -->

            <!-- <li (click)="navigate('inter_seating')" *ngIf="(isAdmin || isExamCell)">
              <a>
                <i class="metismenu-icon fa fa-book "></i>
                Inter Seating
              </a>
            </li> -->

            <!-- </ul> -->

            <li
              *ngIf="(isAdmin||((canEnrolleRepeater||canFeeStructure || isFinancialAidOffice || isEndownmentFund || canReadmission) &&!(isExamCell)))"
              class="app-sidebar__heading">Fee Structure
            </li>

            <li tabindex="0" autoCloseItem id="menu-list-1" class="menu-list"
              *ngIf="(isAdmin||((canEnrolleRepeater||canFeeStructure || isEndownmentFund || canReadmission) &&!(isExamCell)))"
              (click)="OnShowMenuListItem('menu-list-1')">
              <a>
                <i class="metismenu-icon">
                  <img src="../../../assets/dollar.svg" class="collapse-arrow" style="width: 2.2rem" alt="">
                </i>
                Fee Generation
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse" (click)="$event.stopPropagation(); $event.preventDefault()">

                <li *ngIf="(isAdmin || canFeeStructure)">
                  <a (click)="onChallanDataInsertion()">
                    Challan Data Insertion
                  </a>
                </li>

                <li *ngIf="(isAdmin || canFeeStructure || isEndownmentFund)">
                  <a (click)="onChallanGenerate()">
                    Generate & Print Challans
                  </a>
                </li>

                <li *ngIf="(isAdmin || canFeeStructure || isEndownmentFund)">
                  <a (click)="onPrintSingleChallan()">
                    Print Single Challan
                  </a>
                </li>

                <li>
                  <a *ngIf="(isAdmin || canFeeStructure || isEndownmentFund)" (click)="navigate('CheckPaidChalans')">
                    <i class="metismenu-icon fa fa-calendar"></i>
                    Check Paid & Unpaid Challans
                  </a>
                </li>

                <li>
                  <a *ngIf="(isAdmin || canFeeStructure || isEndownmentFund)"
                    (click)="navigate('StudentChallanActions')">
                    <i class="metismenu-icon fa fa-calendar"></i>
                    Student Challan Actions
                  </a>
                </li>

                <li *ngIf="(isAdmin || canFeeStructure || isEndownmentFund)">
                  <a (click)="CheckStdData()">
                    Check Student Data
                  </a>
                </li>

                <li *ngIf="(isAdmin || canFeeStructure || isEndownmentFund)">
                  <a (click)="onManualChallanF()">
                    Manual Challan
                  </a>
                </li>

                <li *ngIf="(isAdmin || canFeeStructure || isEndownmentFund)">
                  <a (click)="onDuesAdjustment()">
                    Dues Adjustment
                  </a>
                </li>
                <li *ngIf="(isAdmin || canFeeStructure || isEndownmentFund)">
                  <a (click)="navigate('OutstandingDuesEntry')">
                    Outstanding Dues Entry
                  </a>
                </li>
                <li *ngIf="(isAdmin || canFeeStructure || isEndownmentFund)">
                  <a (click)="onFeeRefund()">
                    Fee/Fund Refund
                  </a>
                </li>

                <li *ngIf="(isAdmin || canReadmission || canEnrolleRepeater)">
                  <a (click)="onAssigningRollNo()">
                    Assign Roll No
                  </a>
                </li>


                <!-- Still Needs to Get Worked On -->
                <!-- <li>
                  <a *ngIf="( isAdmin || canFeeStructure || isEndownmentFund)" (click)="onRepeat()">
                    Repeaters/ Subject Improvers
                  </a>
                </li> -->
                <!-- <li *ngIf="(isAdmin || canFeeStructure || isEndownmentFund)">
                  <a (click)="onSubjectFee()">
                    Subject Fee
                  </a>
                </li> -->

              </ul>
            </li>

            <li *ngIf="(isAdmin||((canFeeStructure || isFinancialAidOffice || isEndownmentFund) &&!(isExamCell)))"
              tabindex="0" autoCloseItem id="menu-list-1" class="menu-list" (click)="OnShowMenuListItem('menu-list-1')">
              <a>
                <i class="metismenu-icon">
                  <img src="../../../assets/schp.svg" class="collapse-arrow" style="width: 2.5rem" alt="">
                </i>
                Scholarships
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse" (click)="$event.stopPropagation(); $event.preventDefault()">
                <!-- <li *ngIf="(isAdmin || canFeeStructure || isFinancialAidOffice || isEndownmentFund)">
                  <a (click)="onSchpInfo()">
                    Scholarship Information
                  </a>
                </li>
                <li *ngIf="(isAdmin || canFeeStructure || isFinancialAidOffice || isEndownmentFund)">
                  <a (click)="onSchpAppForm()">
                    Student Information Form
                  </a>
                </li>  -->
                <li *ngIf="(isAdmin || canFeeStructure || isFinancialAidOffice || isEndownmentFund)">
                  <a (click)="onSchpAward()">
                    Merit List Scholarship Form
                  </a>
                </li>

                <li *ngIf="(isAdmin || canFeeStructure || isFinancialAidOffice || isEndownmentFund)">
                  <a (click)="navigate('ExternalSchp')">
                    External Scholarship
                  </a>
                </li>

                <!-- <li *ngIf="(isAdmin)">
                  <a (click)="onSchpAwardList()">
                    Scholarship Awarded List
                  </a>
                </li> -->

                <!-- <li *ngIf="(isAdmin || canFeeStructure || isFinancialAidOffice || isEndownmentFund)">
                  <a (click)="navigate('Sfaoletter')">
                    <i class="metismenu-icon fa fa-book "></i>
                    Letter
                  </a>
                </li> -->

              </ul>
            </li>

            <li *ngIf="(isAdmin||((canFeeStructure|| isEndownmentFund) &&!(isExamCell)))" tabindex="0" autoCloseItem
              id="menu-list-1" class="menu-list" (click)="OnShowMenuListItem('menu-list-1')">
              <a>
                <!-- <i class="metismenu-icon fas fa-asterisk"></i> -->
                 
                <i class="metismenu-icon">
                  <img src="../../assets/prerequisits.png" class="collapse-arrow" style="width: 2.1rem" alt="">

                </i>
                Prerequisites
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse" (click)="$event.stopPropagation(); $event.preventDefault()">
                <li *ngIf="(isAdmin || canFeeStructure || isEndownmentFund)">
                  <a (click)="onFeeUtilClicked()">
                    <i class="metismenu-icon fa fa-box-open"></i>
                    Fee Prerequisites
                  </a>
                </li>
              </ul>
            </li>

            <li
              *ngIf="(isAdmin||((canEnrolleRepeater||canFeeStructure || isFinancialAidOffice || isEndownmentFund || canReadmission) &&!(isExamCell)))"
              tabindex="0" autoCloseItem id="menu-list-1" class="menu-list" (click)="OnShowMenuListItem('menu-list-1')">
              <a>
                <i class="metismenu-icon">
                  <img src="../../../assets/feerep.svg" class="collapse-arrow" style="width: 2rem" alt="">
                </i>
                Reports
                <i class="metismenu-state-icon pe-7s-angle-down fa fa-caret-down"></i>
              </a>
              <ul class="mm-collapse" (click)="$event.stopPropagation(); $event.preventDefault()">

                <!-- <li *ngIf="(isAdmin || canFeeStructure  || isEndownmentFund || canReadmission || canEnrolleRepeater)">
                  <a (click)="navigate('StrengthRep')">
                    Strength Wise Reports
                  </a>
                </li> -->

                <li *ngIf="(isAdmin || canFeeStructure  || isEndownmentFund)">
                  <a (click)="onFeeReport()">
                    Dues Submission Report
                  </a>
                </li>

                <li *ngIf="(isAdmin || canFeeStructure  || isEndownmentFund || canReadmission || canEnrolleRepeater)">
                  <a (click)="onCollectionReport()">
                    Dues Collection Report
                  </a>
                </li>

                <li *ngIf="(isAdmin || canFeeStructure  || isEndownmentFund)">
                  <a (click)="navigate('incomecash')">
                    Income Cash Report
                  </a>
                </li>
                <li *ngIf="(isAdmin || canFeeStructure || isEndownmentFund)">
                  <a (click)="onFeeRefundRep()">
                    Fee/Fund Refund Reports
                  </a>
                </li>
                <li *ngIf="(isAdmin || canFeeStructure  || isEndownmentFund)">
                  <a (click)="navigate('generalRep')">
                    General Reports
                  </a>
                </li>

                <li *ngIf="(isAdmin || canFeeStructure || isFinancialAidOffice || isEndownmentFund)">
                  <a (click)="navigate('SfaoEftRep')">
                    SFAO/EFT Reports
                  </a>
                </li>
                <li *ngIf="(isAdmin)">
                  <a (click)="navigate('faidname')">
                    Search Student
                  </a>
                </li>
              </ul>
            </li>
            <!-- <li style="text-align: center; font-weight: bold; color:gray" class="mt-4" *ngIf="navActionLocal == false">DEVELOPED BY DIT</li>
             -->
            <li>
          </ul>
        </div>
      </div>
    </div>
    <div class="app-main__outer">
      <div class="app-main__inner">
        <!-- <div class="alert alert-warning alert-dismissible fade show" role="alert">
          <strong style="font-family: sans-serif; font-weight: bold;">Dear  Users,  Please  Calculate  Student  Eligibility  for  Semester  1  at  your  earliest.<br> For  further  assistance <span id="downloadfile" (click)="onDownload()">
            Download
          </span>  User  Manual  of  the  Eligibility  Process
          </strong>.
        </div> -->


        <!-- 
          <div class="alert alert-warning alert-dismissible">
          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
          <strong>Dear All!</strong> Result is being Locked for Semester 4,6,8. For Unlocking contact to Examination.
          <strong>Dear All!</strong> Result Submission Deadline for Semester 4,6,8 is extend to 3rd July 2022 Sunday 10:00 PM sharp.
        </div>
         -->

        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-change-password *ngIf="showChangePassword" (close)="onCloseChangePasswordForm()"></app-change-password>
</div>