import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { RequestedSubjectDepartmentModel } from './../models/RequestedSubjectDepartment.model';
import { SubjectRequestService } from './../../shared/services/SubjectRequest.service';
import { SubjectOfferService } from './../../shared/services/SubjectOffer.service';

export class sub{
  constructor(public ab_rel: number,public c_ele: string, public cr_h: number, public gp_per: string, public pr_ord: number,public reg_sumr:string, public sub_code: string, public sub_nm: string){

  }
}



@Component({
  selector: 'app-requested-sub-detail',
  templateUrl: './requested-sub-detail.component.html',
  styleUrls: ['./requested-sub-detail.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class RequestedSubDetailComponent implements OnInit {
  @Output() close = new EventEmitter<number>();
  
  @Input('selectedDpet') selectedDept: RequestedSubjectDepartmentModel;

  subjects: Array<sub>;
  constructor(private subRequestService: SubjectRequestService,
    private subOfferService :SubjectOfferService,
    private toastr: ToastrService) {
    this.subjects= new Array<sub>();
  }

  ngOnInit(): void {
    if(this.selectedDept){
      this.subjects=[];
      this.subRequestService.getRequestedSubjectDetails(this.selectedDept.c_code, this.selectedDept.maj_id, this.selectedDept.se_id, this.selectedDept.t_no)
        .subscribe((res) => {
          for (const i in res) {
            this.subjects.push(new sub(res[i].ab_rel, res[i].c_ele, res[i].cr_h, res[i].gp_per, res[i].pr_ord, res[i].reg_sumr, res[i].sub_code, res[i].sub_nm ));
          }
        });
    } 
  }


  OnClose(){
    this.close.emit(0);
  }


  rejectSubject() {
      this.subRequestService.rejectSubject( this.selectedDept.c_code, this.selectedDept.maj_id, this.selectedDept.se_id, this.selectedDept.t_no).subscribe(res => {
        if (res) {
          this.close.emit(-1);
          this.toastr.success("Rejected Successfully");
        }
        else {
          this.toastr.warning("Try Again");
        }
      });
  }



  OfferSubject() {
    this.subOfferService.offerSubject(this.selectedDept.c_code, this.selectedDept.maj_id, this.selectedDept.se_id, this.selectedDept.t_no).subscribe(res => {
      if (res) {
        this.toastr.success("Offered Successfully");
        this.close.emit(1);
      }
      else {
        this.toastr.warning("Already Offered");
      }
    }, err => {
      this.toastr.error("Error Occured");
    });
  }

}
