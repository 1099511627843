<form (ngSubmit)="OnSubmit()" #f="ngForm">
    <div [@SlideInFromLeft]>
      <div class="container-fluid card shadow">
        <div class="card-header">
          Concession Category
        </div>
  
        <div class="row card-body" style="padding-bottom: 0px;">    
          
          <div class="col-lg-4 col-md-6 form-group">
            <label for="concId">Concession ID</label>
            <input type="number" class="form-control" name="conc_id" min="1" ngModel required (change)="ClearIDBase()">
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="conceDescription">Description</label>
            <input type="string" class="form-control" name="conc_desc" min="1" ngModel required (change)="ClearDescBase()">
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="concRemarks">Remarks</label>
            <input type="string" class="form-control" name="conc_rem" min="1" ngModel required>
          </div>
        </div>
  
        <div class="card-header">
          Actions
        </div>
        <div class="row card-body">
          <div class="">
            <button  (click)="addConcession()" class="btn btn-outline-primary" [disabled]="!f.valid">
              Add
            </button>
         
  
            <button class="btn ml-1 btn-outline-primary" type="button" (click)="ClearForm(f)">Clear Form</button>
  
          </div>
  
  
        </div>
        <div *ngIf="concession?.length>0" class=" card-header mt-3">
            Concession Categories 
          </div>
          <div *ngIf="concession?.length>0" class="card-body">
            <table class="table table-bordered table-striped">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Concession ID</th>
                  <th scope="col">Concession Description</th>
                  <th scope="col">Concession Remarks</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let c of concession; let j =index;">
                  <td>{{c.id}}</td>
                  <td>{{c.desc}}</td>
                  <td>{{c.rem}}</td>
                  <td><button class="btn btn-danger" (click)="deleteConcession(c.id,j)" style="font-size: 12px;">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
  </form>
  