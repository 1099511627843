<form #f="ngForm">
  <div [@SlideInFromLeft]>
    <div class="container-fluid card shadow">
      <div class="card-header">
        Single Student Awardlist
      </div>

      <div class="row card-body px-3" style="padding-bottom: 0px;">

        <div class="col-lg-4 col-md-6 form-group">
          <label for="c_code">Class</label>
          <select #c_code class="form-control " name="c_code" id="c_code" (change)="onClassChange(c_code)" ngModel required>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="se_id">Session</label>
          <select #cource class="form-control " name="se_id" id="se_id" ngModel required>
            <option *ngFor="let c of sessions" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="t_no">Semester</label>
          <input type="number" class="form-control" name="t_no" id="t_no" min="0" max="8" ngModel required>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="year" class="text-left">Year</label>
          <input class="form-control" name="year" id="year" ngModel required>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="t_no">Roll Number</label>
          <input type="number" class="form-control" name="rn" id="rn" min="1" ngModel required>
        </div>
      </div>

      <div class="row card-body px-3 py-4">
        <!-- <div class="col-lg-4 col-md-6 form-group"> -->
        <button (click)="getResult()" class="btn btn-outline-primary ml-3" type="button" [disabled]="!f.valid">
          Get Awardlist
        </button>
        <!-- </div>
        <div class="col-lg-4 col-md-6 form-group"> -->
        <button (click)="getAttendance()" class="btn btn-outline-primary ml-1" type="button" [disabled]="!f.valid">
          Get Attendance
        </button>
        <!-- </div>
        <div class="col-lg-4 col-md-6 form-group"> -->
        <button class="btn btn-warning ml-1" type="button" (click)="reset()">Clear</button>
        <!-- </div> -->
      </div>

      <div class="card-header">
        Student
      </div>
      <div class="row card-body px-3">
        <div class=" col-lg-6 col-sm-12 input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Roll Number</span>
          </div>
          <input type="text" class="form-control" value="{{this.std?.ROLNO}}" aria-label="Username"
            aria-describedby="basic-addon1" readonly>
        </div>
        <div class="col-lg-6 col-sm-12 input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon5">Session</span>
          </div>
          <input type="text" class="form-control" value="{{this.std?.SESSION_NM}}" aria-label="Username"
            aria-describedby="basic-addon5" readonly>
        </div>
        <div class=" col-lg-6 col-sm-12 input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon2">Name</span>
          </div>
          <input type="text" class="form-control" value="{{this.std?.NM}}" aria-label="Username"
            aria-describedby="basic-addon2" readonly>
        </div>
        <div class=" col-lg-6 col-sm-12 input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">Father Name</span>
          </div>
          <input type="text" class="form-control" value="{{this.std?.F_NM}}" aria-label="Username"
            aria-describedby="basic-addon3" readonly>
        </div>

        <div class="col-lg-12 input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon4">Major</span>
          </div>
          <input type="text" class="form-control" value="{{this.std?.MAJOR_NM}}" aria-label="Username"
            aria-describedby="basic-addon4" readonly>
        </div>

      </div>
      <div class="card-header d-flex justify-content-between" *ngIf="std">
        Details
      <!-- AWARDLIST DOWNLOAD BUTTON -->
      <div class="row card-body">
        <button class="btn btn-sm btn-outline-danger" *ngIf="result?.length" (click)="genratePdf()">
          <i class="fa fa-file-pdf-o" for=""></i>
          Download Pdf</button>
        <!-- ATTENDANCE DOWNLOAD BUTTON -->
        <button class="btn btn-sm btn-outline-danger" *ngIf="attendance?.length" (click)="attendancePdf()">
          <i class="fa fa-file-pdf-o" for=""></i>
          Download Pdf</button>
      </div>
    </div>
      <!-- ATTENDANCE DETAIL -->
      <div class="row card-body" *ngIf="attendance?.length">
        <table class="table table-bordered table-striped">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Sr.No.</th>
              <th scope="col">Sub Code</th>
              <th scope="col">Subject Title</th>
              <th scope="col">Section</th>
              <th scope="col">Lecture Delivered</th>
              <th scope="col">Lecture Attended</th>
              <th scope="col">Lecture Absented</th>
              <th scope="col">Leaves</th>
              <th scope="col">Percentage(%) Inlcuding Relaxation</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of attendance; let i =index;" [ngClass]="{ 'text-danger': (r.PERCENTAGE<80)}">
              <td>{{i+1}}</td>
              <td>{{r.SUB_CODE}}</td>
              <td>{{r.SUB_NM}}</td>
              <td>{{r.SECTION}}</td>
              <td>{{r.TOTAL}}</td>
              <td>{{r.PRESENTS}}</td>
              <td>{{r.ABSENTS}}</td>
              <td>{{r.LEAVES}}</td>
              <td> {{r.PERCENTAGE}} </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- AWARD LIST DETAILS -->
      <div class="row card-body" *ngIf="result?.length && std">
        <table class="table table-bordered table-striped">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Sr #</th>
              <th scope="col">Sub Code</th>
              <th scope="col">Subject Title</th>
              <th scope="col">Section</th>
              <th scope="col">Sessional</th>
              <th scope="col">Final Term</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of result; let i =index;">
              <td>{{i+1}}</td>
              <td>{{r.SUB_CODE}}</td>
              <td>{{r.SUB_NM}}</td>
              <td>{{r.SECTION}}</td>
              <td>{{r.SESSIONAL}}</td>
              <td>{{r.FINAL_TERM}}</td>
              <td>{{r.FINAL_TERM + r.SESSIONAL}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>