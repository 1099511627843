import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class CourseMaterialService {

    constructor(private http: HttpClient) {
    }

    getCourseMaterial(param){
        return this.http.post(`${baseUrl}/api/courseMaterial/getCourseMaterialList`, param);
    }

    insertCourseMaterial(param){
        return this.http.post(`${baseUrl}/api/courseMaterial/insertCourseMaterial`, param);
    }
  

}