import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../shared/model/SelectList.model';
import { EnrollmentService } from '../shared/services/Enrollment.service';
import { StudentService } from '../shared/services/Student.service';
import { ExamSummaryService } from '../shared/services/ExamSummary.service';
import { ToastrService } from 'ngx-toastr';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';

@Component({
  selector: 'app-result-transfer',
  templateUrl: './result-transfer.component.html',
  styleUrls: ['./result-transfer.component.css'],
  animations: [SlideInFromLeft()]
})

export class ResultTransferComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;
  valid: boolean = false;
  name: string;
  rolno: Array<string>;
  classes: Array<SelectList>;
  stdtSubData: Array<{ sub_code, grd, sub_nm, se_id, subjectValue, enrollValue, gradeValue, excemptedValue,shiftedValue,changed }>;
  stdtSubDataReplica: Array<{ sub_code, grd, sub_nm, se_id, subjectValue, enrollValue, gradeValue, excemptedValue }>;
  shifted: number;
  constructor(
    private toastr: ToastrService,
    private enrollmentService: EnrollmentService,
    private examService: ExamSummaryService,
    private studentService: StudentService,
    private lmsSharedService: LMSharedDataService) {
    this.classes = [];
    this.stdtSubData = new Array<{ sub_code, grd, sub_nm, se_id, subjectValue, enrollValue, gradeValue, excemptedValue,shiftedValue,changed }>();
    this.stdtSubDataReplica = new Array<{ sub_code, grd, sub_nm, se_id, subjectValue, enrollValue, gradeValue, excemptedValue }>();
  }

  ngOnInit(): void {
    this.updateClasses();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
    });
  }

  getRolno() {
    this.rolno = [];
    this.enrollmentService.notEnroll(this.formRef.value).subscribe((res: { rolno: string }[]) => {
      res?.forEach(entry => {
        this.rolno.push(entry.rolno);
      });
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });
  }

  getStdtName() {
    this.name = '';
    this.studentService.getStudentNameByRolNo(this.formRef.value).subscribe((res: { name: string }) => {
      if (res[0]?.name) {
        this.name = res[0].name;
      }
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });
  }

  getstdtSubData() {
    this.valid = true;
    this.stdtSubData = [];
    this.stdtSubDataReplica=[]
    this.shifted=0;
    this.studentService.getEnrolledSubjects(this.formRef.value).subscribe((res: { sub_code: string, sub_nm: string, grd: string, se_id: number, subject: number, enroll: number, grade: number, execmpted: number,shifted:number }[]) => {
      res?.forEach(entry => {
        this.stdtSubData.push({ sub_code: entry.sub_code, sub_nm: entry.sub_nm, grd: entry.grd, se_id: entry.se_id, 
          subjectValue: entry.subject, enrollValue: entry.enroll, gradeValue: entry.grade, 
          excemptedValue: entry.execmpted,shiftedValue:entry.shifted,changed:0 });
        this.stdtSubDataReplica.push({ sub_code: entry.sub_code, sub_nm: entry.sub_nm, grd: entry.grd, se_id: entry.se_id, 
          subjectValue: entry.subject, enrollValue: entry.enroll, gradeValue: entry.grade, excemptedValue: entry.execmpted });
      
        this.shifted=entry.shifted;
      
      });
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });
  }

  RadioBox(e, obj, index, ctg) {
    if (ctg == 1) {
      if (!this.stdtSubData[index].subjectValue == false) {
        this.stdtSubData[index].subjectValue = 0;
      }
      else {
        this.stdtSubData[index].subjectValue = 1;
      }
    }
    else if (ctg == 2) {
      if (!this.stdtSubData[index].enrollValue == false) {
        this.stdtSubData[index].enrollValue = 0;
      }
      else {
        this.stdtSubData[index].enrollValue = 1;
      }
    }
    else if (ctg == 3) {
      if (!this.stdtSubData[index].gradeValue == false) {
        this.stdtSubData[index].gradeValue = 0;
      }
      else {
        this.stdtSubData[index].gradeValue = 1;
      }
    }
    else if (ctg == 4) {
      if (!this.stdtSubData[index].excemptedValue == false) {
        this.stdtSubData[index].excemptedValue = 0;
      }
      else {
        this.stdtSubData[index].excemptedValue = 1;
      }
    }
  }

  updateStdtData(obj,index) {
    this.stdtSubDataReplica[index].enrollValue=obj.enrollValue;
    this.stdtSubDataReplica[index].gradeValue=obj.gradeValue;
    this.stdtSubDataReplica[index].excemptedValue=obj.excemptedValue;
    this.examService.resultTransfer({ c_code: this.formRef.value.c_code, tno: this.formRef.value.tno, se_id: obj.se_id, 
      newSession: this.formRef.value.newSession, sub_code: obj.sub_code, year_in: this.formRef.value.year_in, 
      rolno: this.formRef.value.rolno, enroll: obj.enrollValue, subject: obj.subjectValue, excempted: obj.excemptedValue, 
      grade: obj.gradeValue }).subscribe(res => {
      for (let i = 0; i < 4; i++) {
        if (!res[i]) {
        }
        else {
          if (res[i][0]?.msg) {
            this.toastr.success(res[i][0].msg)
          }
          else if (res[i][0]?.warn) {
            this.toastr.error(res[i][0].warn)
          }
        }
      }
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });
  }

  ShiftAll()
  { 
    let i=0;
    this.stdtSubData.forEach(obj=>{
      this.stdtSubDataReplica[i].enrollValue=obj.enrollValue;
      this.stdtSubDataReplica[i].gradeValue=obj.gradeValue;
      this.stdtSubDataReplica[i].excemptedValue=obj.excemptedValue;
    this.examService.resultTransfer({ c_code: this.formRef.value.c_code, tno: this.formRef.value.tno, se_id: obj.se_id, 
      newSession: this.formRef.value.newSession, sub_code: obj.sub_code, year_in: this.formRef.value.year_in, 
      rolno: this.formRef.value.rolno, enroll: obj.enrollValue, subject: obj.subjectValue, excempted: obj.excemptedValue, 
      grade: obj.gradeValue }).subscribe(res => {
      for (let i = 0; i < 4; i++) {
        if (!res[i]) {
        }
        else {
          if (res[i][0]?.msg) {
            this.toastr.success(res[i][0].msg)
          }
          else if (res[i][0]?.warn) {
            this.toastr.error(res[i][0].warn)
          }
        }
      }
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });
    i++
  })
  }
}
