import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SelectList } from '../shared/model/SelectList.model';
import { DepartmentService } from '../shared/services/Department.service';
import { TeacherService } from '../shared/services/Teacher.service';
import { ToastrService } from 'ngx-toastr';
import { TimetableService } from '../insert-time-table/Timetable.service';
import { NgForm } from '@angular/forms';
import { GroupService } from '../shared/services/Groups.service';
import { TimetableModel } from './Timetable.model';
@Component({
  selector: 'app-teacher-available',
  templateUrl: './teacher-available.component.html',
  styleUrls: ['./teacher-available.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class TeacherAvailableComponent implements OnInit {
    isAdmin: boolean = false;
    group;
    dept: number;
    departments: Array<SelectList>;
    teachers: Array<SelectList>;
    groups: Array<SelectList>;
    timetableEntries: Map<number, TimetableModel>;
    temp: TimetableModel[];
    array;
    @ViewChild('f') formRef: NgForm;
    @Output() close = new EventEmitter<void>();
  
    editView: boolean = false;
    hideInfo:boolean=false;
    constructor(private authenticationService: AuthenticationService,
      private deptService: DepartmentService, private teacherService: TeacherService,
      private timetableService: TimetableService,
      private toaster: ToastrService, private groupService: GroupService) {
      this.isAdmin = this.authenticationService.isAdmin();
      this.group = this.authenticationService.getGroup();
      this.dept = 0;
      this.departments = new Array<SelectList>();
      this.teachers = new Array<SelectList>();
      this.groups = new Array<SelectList>();
      this.timetableEntries = new Map<number, TimetableModel>();
      this.temp = [];
    }
    ngOnInit(): void {
      // if (this.isAdmin) {
        this.loadDepartments();
        this.loadGroups();
      // }
    }
    OnClose(){
      this.close.emit();
    }
    loadGroups() {
      this.groupService.getGroups({ grp: this.group, role: this.isAdmin }).subscribe((res: { GRP: number, G_NM: string }[]) => {
        res?.forEach(element => {
          this.groups.push({ id: element.GRP, nm: element.G_NM });
        });
      }, err => {
        console.log(err);
      });
    }
    updateTimetable() {
      this.temp = [];
      this.timetableService.getTimetableView(this.formRef.value.t_no, this.formRef.value.section, this.formRef.value.fm_id).subscribe(res => {
        this.timetableEntries.clear();
        if(res==undefined || res=='' || res==null){
          this.toaster.info('No Time Table Available.');
      this.hideInfo=false;

          return;
        }
        if (!res) {
          this.toaster.error("Some Error Occured");
      this.hideInfo=false;

          return;
        }
        this.hideInfo=true;
        for (const i in res) {
          this.timetableEntries.set(res[i].tm_id, new TimetableModel(res[i].sub_code, res[i].grp, res[i].tm_id, res[i].d_id, res[i].d_nm, res[i].c_nm, res[i].se, res[i].t_no, res[i].section, res[i].subject, res[i].fm_id, res[i].teacher, res[i].day, res[i].start_time, res[i].end_time, res[i].blk_id, res[i].blk_nm, res[i].rm_id, res[i].rm_nm, res[i].status));
        }
        this.array = Array.from(this.timetableEntries, ([name, value]) => ({ name, value }));
        this.temp = Array.from(this.timetableEntries.values());
        let x = [];
        x = [...x, ...this.temp.filter(a => a.day == "Monday")];
        x = [...x, ...this.temp.filter(a => a.day == "Tuesday")];
        x = [...x, ...this.temp.filter(a => a.day == "Wednesday")];
        x = [...x, ...this.temp.filter(a => a.day == "Thursday")];
        x = [...x, ...this.temp.filter(a => a.day == "Friday")];
        this.array = x;
      }, err => {
        console.log(err);
      });
    }
    
    loadDepartments() {
      this.deptService.getDepartments({ admin: true, filter: true }).subscribe((res: { D_ID: number, D_NM: string }[]) => {
        res?.forEach(entry => {
          this.departments.push({ id: entry.D_ID, nm: entry.D_NM });
        });
      });
    }
    loadTeachers(val: number) {
      this.teachers = [];
      this.teacherService.getTeachers(val).subscribe((res: { FM_ID: number, FM_NM }[]) => {
        if (res.length == 0) {
          this.toaster.error('No Any Teachers');
          return;
        }
        res?.forEach(element => {
          this.teachers.push({ id: element.FM_ID, nm: element.FM_NM });
        });
      });
    }
    OnDeptChange(dpt: HTMLSelectElement) {
      this.loadTeachers(parseInt(dpt.value))
    }
  
    FetchTimeTable() {
      if (!(this.formRef.valid)) {
        this.toaster.warning('Fields Must Fill..');
        return;
      }
      else if (!this.formRef.value.fm_id) {
        this.toaster.warning('Teacher Must Enter.');
        return;
      }
      else {
        if (this.formRef.value.section && (this.formRef.value.t_no == null || this.formRef.value.t_no == '')) {
          this.toaster.warning('Semester Must Enter.');
          this.temp = [];
          return;
        }
        else {
          this.updateTimetable();
        }
      }
    }
  }