import { Component, OnInit,ViewChild  } from '@angular/core';
import { NgForm } from "@angular/forms";
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from "../shared/model/SelectList.model";
import {SubCombinationsService  } from './../shared/services/sub-combinations.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../auth/_services'
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';

@Component({
  selector: 'app-add-subject-combinations',
  templateUrl: './add-subject-combinations.component.html',
  styleUrls: ['./add-subject-combinations.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class AddSubjectCombinationsComponent implements OnInit {

  classes: Array<SelectList>;
  sub_comb:Array<{SET_NO:number, SUB_COMB:string}>;
  majors: Array<SelectList>;
  isAdmin: boolean;
 
  @ViewChild("f") formRef: NgForm;

  constructor(   
     private toastr: ToastrService,
     private subComb: SubCombinationsService,   
     private authenticationService: AuthenticationService,
     private lmsSharedService: LMSharedDataService,
     private feeSharedService: FeeSharedDataService)
   {
    this.isAdmin = authenticationService.isAdmin();
      this.classes = [];
      this.majors = [];
      this.sub_comb = [];
    }

  ngOnInit(): void {
    this.isAdmin = this.authenticationService.isAdmin();
    this.updateClasses();
  }

  // Load All Classes
  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting lms classes", error);
      });
  }

  // After Selecting Class get its relative major
  OnClassChange(c: HTMLSelectElement) {
    this.updateMajors();
  }

  // Get all relative majors 
  updateMajors() {
    this.majors.length = 0;
    this.feeSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  // get already exists combination relative to class or majors
  OnMajorChange(major) {
    this.loadSubCombs();
  }

  // load all majors
  loadSubCombs(){
    this.sub_comb = [];
    this.subComb.getSubjectCombinations(this.formRef.value.maj_id, this.formRef.value.c_code).subscribe((res:{SET_NO:number, SUB_COMB:string}[])=>{
      if(res?.length>0){
        this.sub_comb = res;
      }
    },err =>{
      this.toastr.error('Some Error Occured.');
    })
  }


// After submit store data and check whether it exists or not 
  OnSubmit() {
    this.subComb.insertSubjectCombinations(this.formRef.value).subscribe((res: any) => {
        if (!res)
          this.toastr.warning("Already exists against this Set No");

        else {
          this.toastr.success("add successfully");
         this. loadSubCombs();
        }
      }, err => {
        this.toastr.error("Failed to Offer Subject");
      });
  }
  OnSubmitFee() {
    this.subComb.insertSubjectCombinationsFee(this.formRef.value).subscribe((res: any) => {
        if (!res)
          this.toastr.warning("Already exists against this Set No");

        else {
          this.toastr.success("add successfully");
         this. loadSubCombs();
        }
      }, err => {
        this.toastr.error("Failed to Offer Subject");
      });
  }

// Delete Subject Combinations against c_code, g_code,set_no
  deleteSubComb(c_code:any,g_code:any,set_no:any){
    
   this.subComb.deleteSubjectCombination(c_code,g_code,set_no).subscribe((res:any) =>{
    if (res)
    this.toastr.success("Successfully Deleted");
    this. loadSubCombs();
    }, err => {
    this.toastr.error("Error while deleteing");
    console.log(err)
   });
   
   }

  // Clear the form
  Clear(f){
    this.classes = [];
    this.majors = [];
    this.sub_comb = [];
    f.resetForm();
  }

  Filter()
    {
     filter()
    }
}
