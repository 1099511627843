<form #f="ngForm">
    <div class="card-header"  style="padding-top: 10px;">
    <div class="col-lg-6 col-md-6 form-group">
        Faculty and Student Detail</div>
         <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
        <app-details-button [details]="details"></app-details-button>
    </div>
    </div>
    <div class=" container-fluid" [@SlideInFromLeft]>
        <div class="row card shadow">
            <div class="row card-body pb-0">
                <button type="button" class="btn mt-2 btn-outline-info form-group" (click)="getuserdata()">Faculty
                    Detail</button>
                <button type="button" class="btn mt-2 btn-outline-info form-group" (click)="getyearandbuttton()">Student
                    Detail</button>
                <button type="button" class="btn mt-2 btn-outline-warning form-group" (click)="clearstduser()">Clear</button>
        
                <button type="button" class="btn btn-outline-danger" *ngIf="studentdetail.length > 0" (click)="downloadstudentpdf()"><i class="fa fa-file-pdf-o " for=""></i> Download Student
                    Pdf</button>
                    <br>
                <button type="button" class="btn btn-outline-danger" *ngIf="userdetail.length > 0" (click)="downloadfacultypdf()"><i class="fa fa-file-pdf-o" for=""></i> Download Faculty
                    Pdf</button>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12 form-group" *ngIf="showyear">
                <label for="year">Year</label>
                <select #yearSelect class="form-control" name="year" id="year" ngModel (change)="getstudentdetail(yearSelect.value)" >
                    <option *ngFor="let item of year" [value]="item.year" >{{ item.year }}</option>
                </select>
            </div>
            <loader  *ngIf="loader" class="mt-5"></loader> 

            <div class="row card-body pb-0">

            <!-- <div class="col-lg-4 col-md-6 form-group px-0" *ngIf="userdetail.length > 0 || studentdetail.length>0">  
                <input class="form-control" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
            </div> -->
            <table class="table table-bordered table-striped mt-4" *ngIf="userdetail.length > 0" id="table" >
                <thead class="thead-dark">
                    <tr>
                        <th class=" p-2 text-center">
                            <span>User ID</span>
                            <input (keyup)='searchFilter()' #user_idvar style="width: 100%;" type="text"> 

                           
                        </th>
                        <th class="p-2 text-center">
                            <span>Name</span> 
                            <input (keyup)='searchFilter()' #namevar style="width: 100%;" type="text"> 

                               
                        </th>
                        <th class="p-2 text-center">
                            <span>Designation</span>
                            <input (keyup)='searchFilter()' #designationvar style="width: 100%;" type="text">     

                           
                        </th>
                        <th class="p-2 text-center">
                            <span>Contact No</span>
                            <input (keyup)='searchFilter()' #contact_novar style="width: 100%;" type="text">     

                           
                        </th>
                        <th class=" p-2 text-center ">
                            <span>Email</span>
                            <input (keyup)='searchFilter()' #emailvar style="width: 100%;" type="text">   

                              <br>
                        </th>
                        <th class="p-2 text-center">
                            <span>Last_Login</span>
                            <input (keyup)='searchFilter()' #last_loginvar style="width: 100%;"type="text">     

                           
                         </th>
                        <th class="p-2" colspan="3">
                            <span>Action</span>
                            <input  #last_loginvar style="width: 100%;"type="text">     

                            </th>
                        <!-- <th class="p-2">
                            <input  #last_loginvar style="width: 100%;"type="text">     

                            Action</th>
                        <th class="p-2">
                            <input  #last_loginvar style="width: 100%;"type="text">     

                            Action</th> -->

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of tempuserdetail; let i=index">
                        <td class="p-2">{{user.user_id}}</td>
                        <td>{{user.name}}</td>
                        <td>{{user.designation}}</td>
                        <td>{{user.contact}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.last_login}}</td>
                           <td  class="p-1">
                            <button  class="btn btn-warning ml-2  mt-2 " [disabled]='user.loc==13' (click)="onlocuser(user,i)" style="font-size: 12px;">Lock</button> 
                            </td>
                            <td class="p-1" ><button  class="btn btn-success ml-2 mt-2 " [disabled]='user.loc!==13' (click)="onunlocuser(user,i)"style="font-size: 12px;">Unlock</button> 
                            </td>
                             <td class="p-2">
                            <button class="btn  btn-danger ml-2 mt-2" (click)="ondeleteuser(user)" style="font-size: 12px;">Delete</button>
                            </td>
                    </tr>
                </tbody>

            </table>
            <!-- </div> -->

            <!-- </form>

<form #f="ngForm"> -->
            <!-- <div class="row card-body form-group"> -->
                <table class="table table-bordered table-striped form-group" *ngIf="studentdetail.length > 0" id="table">
                    <thead class="thead-dark">
                        <tr>
                            <th class="p-2">
                                <span>Year</span>
                                <input (keyup)='searchFilter2()' #yearvar style="width: 100%;" type="text"> 

                                </th>
                            <th class="p-2">
                                <span>Rolno</span>
                                <input (keyup)='searchFilter2()' #user_idvarr style="width: 100%;" type="text"> 

                               </th>
                            <th class="p-2">
                                <span>Class</span>
                                <input (keyup)='searchFilter2()' #classvar style="width: 100%;" type="text"> 

                                </th>
                            <th class="p-2">
                                <span>Name</span>
                                <input (keyup)='searchFilter2()' #namevar style="width: 100%;" type="text"> 

                                </th>
                            <th class="p-2">
                                <span>Guardian</span>
                                <input (keyup)='searchFilter2()' #guardianvar style="width: 100%;" type="text"> 

                                </th>
                            <th class="p-2">
                                <span>Contact</span>
                                <input (keyup)='searchFilter2()' #contactvar style="width: 100%;" type="text"> 

                                </th>
                            <th class="p-2" colspan="3">
                                <span>Action</span>
                                <input  #classvar style="width: 100%; " type="text" > 

                                
                            </th>
                            <!-- <th class="p-2">
                                <input  #classvar style="width: 100%;" type="text"> 

                                Action</th>
                            <th class="p-2">
                                <input  #classvar style="width: 100%;" type="text"> 

                                Action</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let std of tempstudentdetail ; let i=index"  [ngClass]="{'disabled-row': std.loc === 11}" id="values">
                            <td class="p-1" >{{std.year}}</td>
                            <td >{{std.user_id}}</td>
                            <td >{{std.class}}</td>
                            <td >{{std.name}}</td>
                            <td >{{std.guardian}}</td>
                            <td >{{std.contact}}</td>
                            <!-- <td style="font-size: 10px; width: 2px;">{{std.email}}</td> -->
                            <td ><button  class="btn  btn-primary  ml-2  mt-2 " [disabled]='std.loc==13 || std.loc==11' (click)="onlocstudent(std,i)" style="font-size: 12px;">Lock</button> 
                            </td>
                            <td ><button  class="btn  btn-success  ml-2  mt-2 " [disabled]='std.loc!==13 || std.loc==11 ' (click)="onunlocstudent(std,i)" style="font-size: 12px;">Unlock</button> 
                            </td>
                            <td class="p-1"><button class="btn  btn-danger  ml-2  mt-2 "
                                    (click)="ondeletestudent(std,i)"  [disabled]='std.loc==11' style="font-size: 12px;">Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- </div> -->
        </div>
    </div>
</form>