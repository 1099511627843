import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthComponent} from './auth/auth.component';
import {AuthGuard} from './auth/_helpers';
import { StudentGuard } from './auth/_helpers/Student.guard';

const routes: Routes = [
  {path: 'auth', component: AuthComponent},
  {path: 'main', canLoad: [AuthGuard], loadChildren: () => import(`./main/main.module`).then(m => m.MainModule)},
  {path: 'student',canLoad:[StudentGuard],canActivate:[StudentGuard], loadChildren: () => import(`./student/student.module`).then(m => m.StudentModule)},
  {path: '', redirectTo: 'auth', pathMatch: 'full'},
  {path: '**', redirectTo: 'auth'}

];

@NgModule({
  imports: [
  RouterModule.forRoot(routes, { enableTracing: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
