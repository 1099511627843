import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FeeService } from '../shared/services/Fee.service';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import { ToWords } from 'to-words';
import { ChalanService } from '../shared/services/chalan.service';
import { SelectList } from '../shared/model/SelectList.model';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from "src/app/transitions";
import { DatePipe } from '@angular/common';
import { EnrollmentService } from '../shared/services/Enrollment.service';
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';

declare let $: any
export interface fee {
  fee_id: number,
  fee_nm: string
}

interface refund {
  rolno: string;
  fee_id: number;
  fee_desc: string;
  amount: number;
}

export interface feeAccIns {
  c_code: number;
  fee_acc: string;
  bnk_nm: string;
  acc_no: number;
  start_dt: string;
  end_dt: string;
  act: string;
  ctgry: string;
  rem: string;
}

@Component({
  selector: 'app-manual-challan-fah',
  templateUrl: './manual-challan-fah.component.html',
  styleUrls: ['./manual-challan-fah.component.css'],
  animations: [SlideInFromLeft()],
})

export class ManualChallanFahComponent implements OnInit {
  [x: string]: any;
  @ViewChild("f") formRef: NgForm;
  ChalanCompInfo: Array<{
    bankMCAName: string, mca: string, bankNM: string,
    major: string, class: string, rolno: string, dueDate: string, f_nm: string, m_list: string, sr_no: string,
    base: string, f_no: string, sem: string, year: string, nm: string, paid: string, ch_no: string, sub_inst: string, des: string, acc_no: string
  }>;
  challanInfo: Map<string, any>;
  totalA: Map<string, any>;
  totalB: Map<string, any>;
  subTotalA: number;
  subTotalB: number;
  usr: any;
  letter: string;
  StudentInfo: Array<{ ch_no: number, dueDate: string, num: number, sem: number, nm: string, f_nm: string, sub_inst: number, paid: number, rn: any }>;
  StudentAmount: Array<{ fee_id: string, acc: string, debit: string, des: string }>
  loader: any;
  flag: boolean = true;
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<any>
  challanNo: number
  counter: number;
  totalAmnt: number
  pendingDues: any[] = [];
  pendingSubDues: any[] = [];

  constructor(
    private toastr: ToastrService,
    private feeService: FeeService,
    private chalanService: ChalanService,
    private enrollmentService: EnrollmentService,
    private datepipe: DatePipe,
    private feeSharedService: FeeSharedDataService
  ) {
    this.classes = [];
    this.sessions = []
    this.majors = [];
    this.StudentInfo = new Array<{ ch_no: number, dueDate: string, num: number, sem: number, nm: string, f_nm: string, sub_inst: number, paid: number, rn: any }>();
    this.StudentAmount = new Array<{ fee_id: string, acc: string, debit: string, des: string }>();
    this.classes = new Array<SelectList>();
    this.checking1 = new Array<any>();
    this.rolnos = new Array<SelectList>();
    this.outDues = new Array<refund>();
    this.checking2 = new Array<string>();
    this.bank = new Array<SelectList>();
    this.feeAcc = new Array<fee>();
    this.fineAcc = new Array<SelectList>();
    this.feeAc = new Array<feeAccIns>();
    this.majors = new Array<SelectList>();
    this.groups = new Array<SelectList>();
    this.datet = new Date();
    this.feeDesc = new Array<SelectList>();
    this.feeDesc1 = new Array<SelectList>();
    this.feeDesc2 = new Array<SelectList>();
    this.feeDesc3 = new Array<SelectList>();
    this.feeDef = new Array<{ inst: number, fee_id: number, fee_nm: string, amnt: number }>();
    this.totalAmnt = 0;
  }

  ngOnInit(): void {
    this.updateClasses();
    this.updateInstNO();
    this.updateSessions();
    this.updateFeeAcc()
    this.FreshChallan()
    this.counter = 1;
  }

  updateClasses() {
    this.feeSharedService.getFeeClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateSessions() {
    this.sessions = []
    this.feeSharedService.getFeeSessions()
      .then(sessions => {
        this.sessions = sessions;
      })
      .catch(error => {
        console.error("Error getting fee session", error);
      });
  }

  updateInstNO() {
    this.instNo = []
    this.feeSharedService.getFeeInst()
      .then(inst => {
        this.instNo = inst;
      })
      .catch(error => {
        console.error("Error getting inst", error);
      });
  }

  updateMajors() {
    this.majors.length = 0;
    this.feeSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  loadOutDues() {
    this.outDues = [];
    this.StudentAmount = [];
    this.StudentInfo = []
    this.feeService.onGetOutDues(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.year_in, this.formRef.value.rolno || -1, null, -1)
      .subscribe((res: { rolno: string, fee_id: number, fee_desc: string, amount: number }[]) => {
        if (!res) {
          this.toastr.error("Unknown Error");
        }
        else if (res) {
          res?.forEach(entry => {
            this.outDues.push({ rolno: entry.rolno, fee_id: entry.fee_id, fee_desc: entry.fee_desc, amount: entry.amount });
          });
        }
      }
        , err => {
          console.log(err);
          this.toastr.error("Some Error Occured!");
        }
      );
  }

  OnYearChange() {
    this.loadRolno();
  }

  loadRolno() {
    this.rolnos = [];
    this.enrollmentService.notEnrollFeeServer(this.formRef.value).subscribe((res: { rn: number, rolno: string }[]) => {
      res?.forEach(entry => {
        this.rolnos.push({ id: entry.rn, nm: entry.rolno });
      });
    });
  }

  Handle(e: any) {
    return e.keyCode != 13;
  }

  updateFeeAcc() {
    this.feeDesc = []
    this.feeDesLength = 0;
    this.feeDesLength1 = 0;
    this.feeSharedService.getFeeAcc()
      .then(feeAcc => {
        this.feeDesc = feeAcc;
        this.feeDesLength = Math.round((this.feeDesc.length / 3));
        this.feeDesLength1 = (parseInt(this.feeDesLength) * 2);

        for (let i = 0; i < parseInt(this.feeDesLength); i++) {
          this.feeDesc1.push(this.feeDesc[i]);
        }
        for (let i = parseInt(this.feeDesLength); i < parseInt(this.feeDesLength1); i++) {
          this.feeDesc2.push(this.feeDesc[i]);
        }
        for (let i = parseInt(this.feeDesLength1); i < parseInt(this.feeDesc.length); i++) {
          this.feeDesc3.push(this.feeDesc[i]);
        }
      })
      .catch(error => {
        console.error("Error getting fee Accs", error);
      });
    // this.getFeeDefiniton();
  }

  getChallan() {
    this.rol = []
    const { c_code, maj_id, year_in, rolno } = this.formRef.value;
    this.StudentInfo = [];
    this.StudentAmount = [];
    this.rol = rolno.split('-');

    this.feeService.getChFormByRn(c_code, maj_id, null, year_in, this.rol[0], null).subscribe(res => {
      if (!res) {
        this.toastr.error("Unknown Error!");
      }
      else if (res) {

        this.showBorder = true;
        res[1]?.forEach(entry => {
          this.StudentInfo.push(entry);
        })
        res[2]?.forEach(entry => {
          this.StudentAmount.push(entry);
        })
      }
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });
  }

  getCreateChallan() {
    this.counter = 1;
    this.ch_no = null
    this.totalAmnt = 0
    this.rol = [];
    this.StudentInfo = [];
    this.StudentAmount = [];
    const { c_code, maj_id, year_in, rolno, inst_no, exDate } = this.formRef.value;
    this.rol = rolno.split('-');

    this.feeService.getManualChallanData(c_code, maj_id, year_in, this.rol[0]).subscribe(res => {
      if (!res) {
        this.toastr.error("Unknown Error!");
      }
      else if (res) {
        this.showBorder = true;
        this.getPendingDues();
        this.StudentInfo.push({ ch_no: null, dueDate: this.curdate(), num: res[0].rolno, sem: inst_no, 
          nm: res[0].nm, f_nm: res[0].f_nm, sub_inst: 0, paid: 0, rn: this.rol[0] });
      }
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });
  }

  DueDate(date: any) {
    const { exDate } = this.formRef.value;
    this.StudentInfo.forEach(element => {
      element.dueDate = date;
    })
  }

  curdate(): any {
    return moment(new Date()).format('YYYY-MM-DD');
  }

  InsertIntoStudentAmount(fee_id, fee_amnt) {
    this.totalAmnt = 0
    if (fee_amnt == '')
      fee_amnt = 0;

    const { feedesc, amnt, des_in } = this.formRef.value;
    this.bool = true;
    this.feeDesc.forEach(element => {
      if (element.id == fee_id)
        this.feeDescName = element.nm;
    })
    this.StudentAmount.forEach(element => {
      if (element.fee_id == fee_id) {
        element.debit = fee_amnt;
        // element.des = des_in;
        this.bool = false;
      }
    })
    if (this.bool == true){
      if(fee_amnt > 0)
        this.StudentAmount.push({ fee_id: fee_id, acc: this.feeDescName, debit: fee_amnt, des: des_in })
    }
    this.StudentAmount.forEach((el) => {
      this.totalAmnt = this.totalAmnt + parseInt(el.debit);
    })
    // }
    // else
    //   this.toastr.warning("Some Fields are missing")
  }

  UpdateEntry() {
    const { inst_no } = this.formRef.value;

    this.StudentInfo[0].sem = inst_no;
  }

  getPendingDues(){
    this.pendingDues = [];
    this.pendingSubDues = [];
    const{rolno, year_in, c_code, maj_id} = this.formRef.value;

    this.feeService.getUnpaidChallansForManualChallan(rolno, year_in, c_code, maj_id).subscribe(
      (data:any)=>{
        if (!data) {
          this.toastr.error("Unknown Error!");
        }
        else {
          this.pendingDues = data[0];
          this.pendingSubDues = data[1];
        }
      }, err => {
        console.log(err);
        this.toastr.error("Unknown Error!");
      });
  }

  InsertDataIntoDues() {
    const { rolno } = this.formRef.value;
    this.count = 0;
    this.rol = rolno.split('-');

    if (this.formRef.valid || this.StudentAmount.length != 0) {

      if (this.counter == 1) {
        this.feeService.getUnassignedChallan().subscribe(res2 => {
          this.challanNo = res2[0].ch_no;
          this.insertOrUpdateChallan();
          setTimeout(()=>{
            this.CreatePdfDem();
          }, 2000);
        })
      }
      else {
        this.insertOrUpdateChallan();
        setTimeout(()=>{
          this.CreatePdfDem();
        }, 2000);
      }
      this.counter++;
    } else
      this.toastr.warning("Some Fields are missing");
  }

  insertOrUpdateChallan() {
      const { c_code, year_in, inst_no, maj_id, des_in } = this.formRef.value;
      this.StudentInfo[0].ch_no = this.challanNo;
      this.StudentAmount.forEach(element => {
        this.feeService.InsertManualEntryinDues(this.challanNo, c_code, inst_no, maj_id, year_in, element.fee_id,
          element.debit, this.StudentInfo[0].dueDate, des_in, this.StudentInfo[0].rn).subscribe((res: any) => {
            if (res[0].inserted == 1) {
              this.count++
            }
            else if (res[0].inserted == 2) {
              this.toastr.success("Challan Updated Successfully")
            }
            if (this.count == this.StudentAmount.length)
              this.toastr.success("Challan Inserted Successfully")
          })
      })
  }

  FreshChallan() {
    // this.challanInfo = new Map([["Challan No", " "], ["Due Date", " "], ["Form/Roll No", " "], ["Semester", " "], ["Name", " "], ["S/D of", " "]]);
    this.totalA = new Map([["OVERSEAS", 0], ["ADMISSION FEE", 0], ["REGISTRATION FEE", 0], ["TUITION (FORIEGN)", 0], ["TUITION FEE", 0], ["EXAMINATION", 0]
      , ["UNIVERSITY EXAM FEE", 0], ["THESIS FEE", 0], ["ID CARD", 0], ["OTHER MISCELLANEOUS (REG)", 0], ["LAB CHARGES", 0]
      , ["MISCELLANEOUS", 0], ["DEVELOPMENT FUND", 0], ["SPORTS FEE", 0], ["VERIFICATION FEE", 0], ["OTHER MISCELLANEOUS (SSP)", 0]]);
    this.totalB = new Map([["ROOM RENT", 0], ["HOSTEL SECURITY", 0], ["LIBRARY SECURITY", 0], ["WITHHOLDING TAX", 0], ["STUDENT FUNDS", 0], ["BOARD FUND", 0]]);
  }

  UpdateTotal(AFee: any) {
    this.subTotalA = 0;
    this.subTotalB = 0;
    this.totalA.clear();
    this.totalB.clear();
    this.FreshChallan();
    for (let i = 0; i < AFee.length; i++) {
      this.totalA.forEach((values, element) => {
        if (element == AFee[i].acc) {
          
          this.totalA.set(AFee[i].acc, parseInt(AFee[i].debit));
          this.subTotalA += parseInt(AFee[i].debit);
        }
        //We can also use mapName.has("keyValue")
      })
      this.totalB.forEach((values, element) => {
        if (element == AFee[i].acc) {
          this.totalB.set(AFee[i].acc, parseInt(AFee[i].debit));
          this.subTotalB += parseInt(AFee[i].debit);
        }
      })
      if (!this.totalA.has(AFee[i].acc) && !this.totalB.has(AFee[i].acc)) {
        this.totalB.set(AFee[i].acc, parseInt(AFee[i].debit))
        this.subTotalB += parseInt(AFee[i].debit);
      }
    }
  }

  InsertAndPrintChallan() {
    if (this.formRef.valid) {
      this.InsertDataIntoDues();
    }
    else
      this.toastr.warning("Some Fields are Missing");
  }

  CreatePdfDem() {
    this.rol = [];

    const { c_code, maj_id, inst_no, year_in, rolno } = this.formRef.value;
    this.rol = rolno.split('-');
    setTimeout(() => {
      this.toastr.success("Generating Challan")
    }, 200)
    this.ChalanCompInfo = [];

    const towords = new ToWords();
    var body = [];
    this.chalanService.T_CheckPaidUnpaidShowChallan(null, null, null, null, this.StudentInfo[0].ch_no, null).subscribe((data: any) => {

      this.ChalanCompInfo.push({
        bankMCAName: data[0][0].bank_snm, mca: data[0][0].mcs, bankNM: data[0][0].bank_nm,
        major: data[3][0].maj, class: data[3][0].class, rolno: data[1][0].num, dueDate: data[1][0].dueDate, f_nm: data[1][0].f_nm, m_list: data[1][0].m_list,
        sr_no: data[1][0].sr_no, base: data[1][0].base, f_no: data[1][0].f_no, sem: data[1][0].sem, year: data[1][0].year, nm: data[1][0].nm, paid: data[1][0].paid,
        ch_no: data[1][0].ch_no, sub_inst: data[1][0].sub_inst, des: data[1][0].des, acc_no: data[2][0].acc_no
      });

      this.UpdateTotal(data[2]);

      const doc = new jsPDF('l', 'mm', 'A4'); //[297, 210]
      {
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();

        doc.setDrawColor(192, 192, 192);
        doc.line(102, 0, 102, 220);
        doc.line(192, 0, 192, 220);
        doc.setDrawColor(0, 0, 0);

        let h = 20;
        //FIRST PAGE
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("GC University Lahore", 1 / 6 * width + 10, h - 2, { align: 'center' });
        doc.setFontSize(8);
        doc.text(this.ChalanCompInfo[0].class.toUpperCase(), 1 / 6 * width + 10, h + 3, { align: 'center' });
        doc.text("MAJOR: " + this.ChalanCompInfo[0].major.toUpperCase(), 1 / 6 * width + 10, h + 8, { align: 'center' });
        // const ins = document.getElementById('inst')[0].value;


        //For Stamp on Challan
        if (parseInt(this.ChalanCompInfo[0].paid) == 1) {
          doc.saveGraphicsState();
          doc.setFontSize(30);
          doc.setGState(doc.GState({ opacity: 0.5 }));
          doc.text("Challan Already Paid", 23, 160, null, 45)
          // doc.text("Paid On: ", 35, 160, null,45)
          doc.text("Challan Already Paid", 113, 160, null, 45)
          // doc.text("Paid On: ", 125, 160, null,45)
          doc.text("Challan Already Paid", 203, 160, null, 45)
          // doc.text("Paid On: ", 215, 160, null,45)
          doc.restoreGraphicsState();
        }

        if (parseInt(this.ChalanCompInfo[0].sub_inst) == 1) {
          doc.saveGraphicsState();
          doc.setFontSize(20);
          doc.setGState(doc.GState({ opacity: 0.5 }));
          doc.text("Challan has Sub-Installments", 25, 150, null, 45)
          doc.text("Challan has Sub-Installments", 115, 150, null, 45)
          doc.text("Challan has Sub-Installments", 205, 150, null, 45)
          doc.restoreGraphicsState();
        }


        // SECOND PAGE
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("GC University Lahore", 1 / 2 * width, h - 2, { align: 'center' });
        doc.setFontSize(8);
        doc.text(this.ChalanCompInfo[0].class.toUpperCase(), 1 / 2 * width, h + 3, { align: 'center' });
        doc.text("MAJOR: " + this.ChalanCompInfo[0].major.toUpperCase(), 1 / 2 * width, h + 8, { align: 'center' });

        // // THIRD PAGE
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("GC University Lahore", 5 / 6 * width - 10, h - 2, { align: 'center' });
        doc.setFontSize(8);
        doc.text(this.ChalanCompInfo[0].class.toUpperCase(), 5 / 6 * width - 10, h + 3, { align: 'center' });
        doc.text("MAJOR: " + this.ChalanCompInfo[0].major.toUpperCase(), 5 / 6 * width - 10, h + 8, { align: 'center' });

        if (parseInt(this.ChalanCompInfo[0].sem) == 1) {
          // "this.admBase.find(e => e.id == this.formRef.value.b_id)?.nm;"
          let nm = "BASE"
          doc.text("BASE: " + this.ChalanCompInfo[0].base, 1 / 6 * width + 10, h + 12, { align: 'center' });
          doc.text("BASE: " + this.ChalanCompInfo[0].base, 1 / 2 * width, h + 12, { align: 'center' });
          doc.text("BASE: " + this.ChalanCompInfo[0].base, 5 / 6 * width - 10, h + 12, { align: 'center' });
          h = 24;
        }

        // FIRST PAGE
        doc.setFontSize(8);
        doc.setFont("Helvetica", "bold");
        doc.text("Bank Copy", 1 / 6 * width + 10, h + 11, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('' + this.ChalanCompInfo[0].bankNM.toUpperCase(), 1 / 6 * width + 10, h + 15, { align: 'center' });
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(7);
        doc.text("Acceptable at All " + (this.ChalanCompInfo[0].bankMCAName.toUpperCase()) + " Branches (within Pakistan)", 1 / 6 * width + 10, h + 19.5, { align: 'center' });
        doc.text((this.ChalanCompInfo[0].bankNM) + " MCA # : " + this.ChalanCompInfo[0].mca, 1 / 6 * width + 10, h + 23.5, { align: 'center' });
        doc.setFont("Helvetica", "normal")
        doc.text('_______________________________________________________', 1 / 6 * width + 10, h + 25, { align: 'center' });


        // // SECOND PAGE
        doc.setFontSize(8);
        doc.setFont("Helvetica", "bold");
        doc.text("University Copy", 1 / 2 * width, h + 11, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('' + this.ChalanCompInfo[0].bankNM.toUpperCase(), 1 / 2 * width, h + 15, { align: 'center' });
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(7);
        doc.text("Acceptable at All " + (this.ChalanCompInfo[0].bankMCAName.toUpperCase()) + " Branches (within Pakistan)", 1 / 2 * width, h + 19.5, { align: 'center' });
        doc.text((this.ChalanCompInfo[0].bankNM.toUpperCase()) + " MCA # : " + this.ChalanCompInfo[0].mca, 1 / 2 * width, h + 23.5, { align: 'center' });
        doc.setFont("Helvetica", "normal")
        doc.text('_______________________________________________________', 1 / 2 * width, h + 25, { align: 'center' });


        // // THIRD PAGE
        doc.setFontSize(8);
        doc.setFont("Helvetica", "bold");
        doc.text("Student Copy", 5 / 6 * width - 10, h + 11, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('' + this.ChalanCompInfo[0].bankNM.toUpperCase(), 5 / 6 * width - 10, h + 15, { align: 'center' });
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(7);
        doc.text("Acceptable at All " + (this.ChalanCompInfo[0].bankMCAName.toUpperCase()) + " Branches (within Pakistan)", 5 / 6 * width - 10, h + 19.5, { align: 'center' });
        doc.text((this.ChalanCompInfo[0].bankNM.toUpperCase()) + " MCA # : " + this.ChalanCompInfo[0].mca, 5 / 6 * width - 10, h + 23.5, { align: 'center' });
        doc.setFont("Helvetica", "normal")
        doc.text('________________________________________________________', 5 / 6 * width - 10, h + 25, { align: 'center' });



        doc.setFont("Helvetica", "bold");
        doc.setFontSize(7);

        let b = 50,
          d = ['Challan #/PV #', 'Due Date', 'Roll #/Buyer Code', 'University Dues for', 'Name', 'S/D Of', 'Sr.#'];
        if (parseInt(this.ChalanCompInfo[0].sem) == 1) {
          b = 55;
        }

        doc.setFontSize(7);
        let sr = 0;
        let count = 0;
        for (let index = 0; index < d.length; index++) {
          // for (const itr of Object.entries(std.info)) {
          if (index == 1) {
            if (parseInt(this.ChalanCompInfo[0].sem) == 1) {

              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.datepipe.transform(this.ChalanCompInfo[0].dueDate, 'dd-MM-yyyy'), 1 / 6 * width + 21, b - 7);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 1 / 6 * width + 10, b - 6, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.datepipe.transform(this.ChalanCompInfo[0].dueDate, 'dd-MM-yyyy'), 1 / 2 * width + 11, b - 7);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 1 / 2 * width, b - 6, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.datepipe.transform(this.ChalanCompInfo[0].dueDate, 'dd-MM-yyyy'), 5 / 6 * width + 1, b - 7);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 5 / 6 * width - 10, b - 6, { align: 'center' });
              // e += a - 10;
            }
            else {
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.datepipe.transform(this.ChalanCompInfo[0].dueDate, 'dd-MM-yyyy'), 1 / 6 * width + 21, b - 6);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 1 / 6 * width + 10, b - 5, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.datepipe.transform(this.ChalanCompInfo[0].dueDate, 'dd-MM-yyyy'), 1 / 2 * width + 11, b - 6);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 1 / 2 * width, b - 5, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.datepipe.transform(this.ChalanCompInfo[0].dueDate, 'dd-MM-yyyy'), 5 / 6 * width + 1, b - 6);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 5 / 6 * width - 10, b - 5, { align: 'center' });
              // e += a - 10;
            }

          }
          else if (index == 2) {
            if (parseInt(this.ChalanCompInfo[0].sem) != 1) {
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.ChalanCompInfo[0].rolno, 20, b - 1);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 59, b, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.ChalanCompInfo[0].rolno, 2 / 6 * width + 10, b - 1);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 2 / 6 * width + 49, b, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.ChalanCompInfo[0].rolno, 2 / 3 * width, b - 1);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 2 / 3 * width + 39, b, { align: 'center' });
              b = b + 4;
            }
            else {
              if (parseInt(this.ChalanCompInfo[0].sem) == 1) { /**/
                doc.setFont("Helvetica", "bold")
                doc.text("Form #: " + this.ChalanCompInfo[0].f_no, 20, b - 2);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 59, b - 1, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text("Form #: " + this.ChalanCompInfo[0].f_no, 2 / 6 * width + 10, b - 2);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 6 * width + 49, b - 1, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text("Form #: " + this.ChalanCompInfo[0].f_no, 2 / 3 * width, b - 2);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 3 * width + 39, b - 1, { align: 'center' });
                doc.setFont("Helvetica", "bold")


                doc.text("Merit List: " + this.ChalanCompInfo[0].m_list, 20, b + 3);
                doc.text("Merit List: " + this.ChalanCompInfo[0].m_list, 2 / 6 * width + 10, b + 3);
                doc.text("Merit List: " + this.ChalanCompInfo[0].m_list, 2 / 3 * width, b + 3);


                doc.text("Session: " + this.ChalanCompInfo[0].year, 1 / 6 * width + 30, b + 3);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 59, b + 4, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text("Session: " + this.ChalanCompInfo[0].year, 1 / 2 * width + 20, b + 3);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 4, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text("Session: " + this.ChalanCompInfo[0].year, 5 / 6 * width + 10, b + 3);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 4, { align: 'center' });
                doc.setFont("Helvetica", "bold")

                sr = b;
                b = b + 8;
              }
            }
          }
          else if (index == 3) {
            if (parseInt(this.ChalanCompInfo[0].sem) > 0 && parseInt(this.ChalanCompInfo[0].sem) != -1) {
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ":                     Semester #" + parseInt(this.ChalanCompInfo[0].sem), 20, b);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 59, b + 1, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ":                     Semester #" + parseInt(this.ChalanCompInfo[0].sem), 2 / 6 * width + 10, b);
              doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 1, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ":                     Semester #" + parseInt(this.ChalanCompInfo[0].sem), 2 / 3 * width, b);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 1, { align: 'center' });
              b = b + 4;
            }
            if (parseInt(this.ChalanCompInfo[0].sem) == 0 || parseInt(this.ChalanCompInfo[0].sem) == -1) {
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ":                   " + this.ChalanCompInfo[0].des, 20, b);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 59, b + 1, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ":                   " + this.ChalanCompInfo[0].des, 2 / 6 * width + 10, b);
              doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 1, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ":                   " + this.ChalanCompInfo[0].des, 2 / 3 * width, b);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 1, { align: 'center' });
              b = b + 4;
            }
          }
          else if (index < 6) {

            if (index == 0) {
              if (parseInt(this.ChalanCompInfo[0].sem) == 1) {
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].ch_no, 20, b - 2);
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].ch_no, 2 / 6 * width + 10, b - 2);
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].ch_no, 2 / 3 * width, b - 2);
              }
              else {
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].ch_no, 20, b - 1);
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].ch_no, 2 / 6 * width + 10, b - 1);
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].ch_no, 2 / 3 * width, b - 1);
              }
              count++;
            }

            else {
              if (count == 1) {

                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].nm, 20, b + 1);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 59, b + 2, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].nm, 2 / 6 * width + 10, b + 1);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 2, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].nm, 2 / 3 * width, b + 1);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 2, { align: 'center' });
                count++;
              }
              else {
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].f_nm, 20, b + 1);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 59, b + 2, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].f_nm, 2 / 6 * width + 10, b + 1);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 2, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].f_nm, 2 / 3 * width, b + 1);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 2, { align: 'center' });
              }
            }
            b = b + 5;
            // b += a - 10;
          }

          if (index == 6 && parseInt(this.ChalanCompInfo[0].sem) == 1) {
            doc.setFont("Helvetica", "bold")
            doc.text(d[index] + ": " + this.ChalanCompInfo[0].sr_no, 58, sr + 3);
            doc.text(d[index] + ": " + this.ChalanCompInfo[0].sr_no, 2 / 6 * width + 48, sr + 3);
            doc.text(d[index] + ": " + this.ChalanCompInfo[0].sr_no, 2 / 3 * width + 38, sr + 3);
          }
          // index++;

        }

        // FIRST PAGE
        doc.setFontSize(7);
        doc.setFont("Helvetica", "bold");
        doc.text('Head Of Account', 24, b);
        doc.setFontSize(8);
        doc.text('A-FEE', 24, b + 4.5);
        // doc.text('__', 14, b+23);
        doc.setFontSize(7);
        doc.setFont("Helvetica", "normal");
        doc.text('_______________________________________________________', 59, b + 5, { align: 'center' });
        doc.setFont("Helvetica", "bold");
        doc.setFontSize(6);
        doc.text('A/C No to be credited', 1 / 6 * width + 15, b, { align: 'center' })
        doc.setFontSize(7);
        doc.text('Amount', 87, b);
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 59, b + 1, { align: 'center' });
        doc.setFont('Helvetica', 'normal');


        // // SECOND PAGE
        doc.setFontSize(7);
        doc.setFont("Helvetica", "bold");
        doc.text('Head Of Account', 2 / 6 * width + 14, b);
        doc.setFontSize(8);
        doc.text('A-FEE', 2 / 6 * width + 14, b + 4.5);
        doc.text('________________________________________________', 2 / 6 * width + 49, b + 5, { align: 'center' });
        doc.setFontSize(6);
        // doc.text('__', 14, b+23);
        doc.text('A/C No.to be credited', 1 / 2 * width + 5, b, { align: 'center' })
        doc.setFontSize(7);
        doc.text('Amount', 2 / 6 * width + 77, b);
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 1, { align: 'center' });
        doc.setFont('Helvetica', 'normal');

        // // THIRD PAGE
        doc.setFontSize(7);
        doc.setFont("Helvetica", "bold");
        doc.text('Head Of Account', 2 / 3 * width + 4, b);
        doc.setFontSize(8);
        doc.text('A-FEE', 2 / 3 * width + 4, b + 4.5);
        doc.setFontSize(7);
        doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 5, { align: 'center' });

        // doc.text('__', 14, b+23);
        doc.setFontSize(6);
        doc.text('A/C No.to be credited', 5 / 6 * width - 5, b, { align: 'center' })
        doc.setFontSize(7);
        doc.text('Amount', 2 / 3 * width + 67, b);
        doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 1, { align: 'center' });
        doc.setFont('Helvetica', 'normal');


        doc.setFontSize(6);
        let index = 1, m = b + 7;
        for (const itr of this.totalA) {
          if (this.totalA.get("OVERSEAS") == 0 && itr[0] == "OVERSEAS")
            continue

          if (this.totalA.get("TUITION (FORIEGN)") == 0 && itr[0] == "TUITION (FORIEGN)")
            continue

          if (this.totalA.get("TUITION (FORIEGN)") != 0 && itr[0] == "TUITION FEE")
            continue

          doc.text(index + ".", 20, m + 1);
          doc.text(index + ".", 2 / 6 * width + 10, m + 1);
          doc.text(index + ".", 2 / 3 * width, m + 1);

          doc.text(itr[0] + '', 24, m + 1);
          doc.text(itr[0] + '', 2 / 6 * width - 1 + 15, m + 1);
          doc.text(itr[0] + '', 2 / 3 * width - 1 + 5, m + 1);

          doc.text(JSON.stringify(itr[1]), 93, m + 1, { align: 'center' });
          doc.text('________________________________________________________________', 59, m + 2, { align: 'center' });
          doc.text(JSON.stringify(itr[1]), 2 / 6 * width + 83, m + 1, { align: 'center' });
          doc.text('________________________________________________________________', 2 / 6 * width + 49, m + 2, { align: 'center' });
          doc.text(JSON.stringify(itr[1]), 2 / 3 * width + 73, m + 1, { align: 'center' });
          doc.text('________________________________________________________________', 2 / 3 * width + 39, m + 2, { align: 'center' });
          index++;
          m += 4;
        }

        doc.setFontSize(7);

        doc.setFont('Helvetica', 'bold');
        doc.text('Total A', 24, m + 1);
        doc.text('Total A', 2 / 6 * width + 14, m + 1);
        doc.text('Total A', 2 / 3 * width + 4, m + 1);
        doc.text(JSON.stringify(this.subTotalA), 93, m + 1, { align: 'center' });
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 59, m + 2, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.text(JSON.stringify(this.subTotalA), 2 / 6 * width + 83, m + 1, { align: 'center' });
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 2 / 6 * width + 49, m + 2, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.text(JSON.stringify(this.subTotalA), 2 / 3 * width + 73, m + 1, { align: 'center' });
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 2 / 3 * width + 39, m + 2, { align: 'center' });

        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(8);
        doc.text('B-FEE', 24, m + 5.5);
        doc.setFont('Helvetica', 'normal');
        doc.text('________________________________________________', 59, m + 6, { align: 'center' });
        // doc.text('____', 10, m + 3);

        doc.setFont('Helvetica', 'bold');
        doc.text('B-FEE', 2 / 6 * width + 14, m + 5.5);
        doc.setFont('Helvetica', 'normal');
        doc.text('________________________________________________', 2 / 6 * width + 49, m + 6, { align: 'center' });
        // doc.text('____', 130, m + 2);

        doc.setFont('Helvetica', 'bold');
        doc.text('B-FEE', 2 / 3 * width + 4, m + 5.5);
        doc.setFont('Helvetica', 'normal');
        doc.text('________________________________________________', 2 / 3 * width + 39, m + 6, { align: 'center' });

        // doc.text('____', 250, m + 2);


        doc.setFont('Helvetica', 'normal');
        doc.setFontSize(6);

        index = 1;
        let n = m + 6;
        for (const itr of this.totalB) {
          doc.text(index + ".", 21, n + 3);
          doc.text(index + ".", 2 / 6 * width + 11, n + 3);
          doc.text(index + ".", 2 / 3 * width + 1, n + 3);

          doc.text(itr[0] + '', 24, n + 3);
          doc.text(itr[0] + '', 2 / 6 * width + 14, n + 3);
          doc.text(itr[0] + '', 2 / 3 * width + 4, n + 3);

          // if(parseInt(this.ChalanCompInfo[0].sem)==0||parseInt(this.ChalanCompInfo[0].sem)==9)
          // doc.text(JSON.stringify(this.ChalanCompInfo[0].acc_no), 50, n + 3, { align: 'center' });
          doc.text(JSON.stringify(itr[1]), 93, n + 3, { align: 'center' });
          doc.text('________________________________________________________________', 59, n + 4, { align: 'center' });
          // if(parseInt(this.ChalanCompInfo[0].sem)==0||parseInt(this.ChalanCompInfo[0].sem)==9)
          // doc.text(JSON.stringify(this.ChalanCompInfo[0].acc_no),  2 / 6 * width + 33, n + 3, { align: 'center' });
          doc.text(JSON.stringify(itr[1]), 2 / 6 * width + 83, n + 3, { align: 'center' });
          doc.text('________________________________________________________________', 2 / 6 * width + 49, n + 4, { align: 'center' });
          // if(parseInt(this.ChalanCompInfo[0].sem)==0||parseInt(this.ChalanCompInfo[0].sem)==9)
          // doc.text(JSON.stringify(this.ChalanCompInfo[0].acc_no),  2 / 3 * width + 23, n + 3, { align: 'center' });
          doc.text(JSON.stringify(itr[1]), 2 / 3 * width + 73, n + 3, { align: 'center' });
          doc.text('________________________________________________________________', 2 / 3 * width + 39, n + 4, { align: 'center' });

          index++;
          n += 4;
        }

        doc.setFontSize(7);

        doc.setFont('Helvetica', 'bold');
        doc.text('Total B', 24, n + 3.5);
        doc.text('Total B', 2 / 6 * width + 14, n + 3.5);
        doc.text('Total B', 2 / 3 * width + 4, n + 3.5);

        doc.text(JSON.stringify(this.subTotalB), 93, n + 3.5, { align: 'center' });
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 59, n + 4.5, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.text(JSON.stringify(this.subTotalB), 2 / 6 * width + 83, n + 3.5, { align: 'center' });
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 2 / 6 * width + 49, n + 4.5, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.text(JSON.stringify(this.subTotalB), 2 / 3 * width + 73, n + 3.5, { align: 'center' });
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 2 / 3 * width + 39, n + 4.5, { align: 'center' });


        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(8)
        doc.text('Grand Total', 24, n + 8);
        doc.text('Grand Total', 2 / 6 * width + 14, n + 8);
        doc.text('Grand Total', 2 / 3 * width + 4, n + 8);

        doc.text(JSON.stringify(this.subTotalA + this.subTotalB), 89, n + 8);
        doc.setFont("Helvetica", "normal")
        doc.text('________________________________________________', 59, n + 9, { align: 'center' });
        doc.setFont("Helvetica", "bold")
        doc.text(JSON.stringify(this.subTotalA + this.subTotalB), 2 / 6 * width + 79, n + 8);
        doc.setFont("Helvetica", "normal")
        doc.text('________________________________________________', 2 / 6 * width + 49, n + 9, { align: 'center' });
        doc.setFont("Helvetica", "bold")
        doc.text(JSON.stringify(this.subTotalA + this.subTotalB), 2 / 3 * width + 69, n + 8);
        doc.setFont("Helvetica", "normal")
        doc.text('________________________________________________', 2 / 3 * width + 39, n + 9, { align: 'center' });

        doc.setFont("Helvetica", "bold")
        doc.text('Rupees: ', 24, n + 12.5);//1
        doc.setFontSize(6)
        doc.text('(' + towords.convert(this.subTotalA + this.subTotalB) + ' Only)', 36, n + 12.5);
        doc.setFontSize(8)
        doc.setFont("Helvetica", "normal")
        doc.text('________________________________________________', 59, n + 13, { align: 'center' });
        doc.setFont("Helvetica", "bold")
        doc.text('Rupees: ', 2 / 6 * width + 14, n + 12.5);//1
        doc.setFontSize(6)
        doc.text('(' + towords.convert(this.subTotalA + this.subTotalB) + ' Only)', 2 / 6 * width + 26, n + 12.5);//1
        doc.setFontSize(8)
        doc.text('________________________________________________', 2 / 6 * width + 49, n + 13, { align: 'center' });
        doc.setFont("Helvetica", "bold")
        doc.setFontSize(8)
        doc.text('Rupees: ', 2 / 3 * width + 4, n + 12.5);//1
        doc.setFontSize(6)
        doc.text('(' + towords.convert(this.subTotalA + this.subTotalB) + ' Only)', 2 / 3 * width + 16, n + 12.5);//1
        doc.setFont("Helvetica", "normal")
        doc.setFontSize(8)
        doc.text('________________________________________________', 2 / 3 * width + 39, n + 13, { align: 'center' });
        doc.setFont("Helvetica", "normal")
        doc.setFontSize(8)

        doc.text('For ' + this.ChalanCompInfo[0].bankNM + ' Manager', 50, n + 17);
        doc.text('For ' + this.ChalanCompInfo[0].bankNM + ' Manager', 2 / 6 * width + 40, n + 17);
        doc.text('For ' + this.ChalanCompInfo[0].bankNM + ' Manager', 2 / 3 * width + 30, n + 17);

        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(7)
        doc.setFillColor(192, 192, 192);
        doc.rect(20, n + 20, 80, 12, 'FD');
        doc.rect(2 / 6 * width + 10, n + 20, 1 / 3 * width - 20, 12, 'FD');
        doc.rect(2 / 3 * width, n + 20, 2 / 6 * width - 20, 12, 'FD');

        doc.text("Issued By: Treasurer's Office", 23, n + 24);
        doc.text("This is Computerized Challan and No need to Signature", 23, n + 27);
        doc.text("Fee / dues submitted through Mobile App will not be acceptable.", 23, n + 30);
        doc.text("Issued By: Treasurer's Office", 2 / 6 * width + 13, n + 24);
        doc.text("This is Computerized Challan and No need to Signature", 2 / 6 * width + 13, n + 27);
        doc.text("Fee / dues submitted through Mobile App will not be acceptable.", 2 / 6 * width + 13, n + 30);
        doc.text("Issued By: Treasurer's Office", 2 / 3 * width + 3, n + 24);
        doc.text("This is Computerized Challan and No need to Signature", 2 / 3 * width + 3, n + 27);
        doc.text("Fee / dues submitted through Mobile App will not be acceptable.", 2 / 3 * width + 3, n + 30);
      }
      window.open(URL.createObjectURL(doc.output('blob')), '_blank');
    })
  }

  ClearClassBase() {
    this.formRef?.controls['maj_id'].reset();
    this.formRef?.controls['inst_no'].reset();
    this.formRef?.controls['year_in'].reset();
    this.formRef?.controls['rolno'].reset();
  }

  ClearMajorBase() {
    this.formRef?.controls['inst_no'].reset();
    this.formRef?.controls['year_in'].reset();
    this.formRef?.controls['rolno'].reset();
  }

  ClearInstBase() {
    this.formRef?.controls['year_in'].reset();
    this.formRef?.controls['rolno'].reset();
  }
}
