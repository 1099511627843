import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from '../shared/services/Class.service';
import { MajorService } from '../shared/services/Major.service';
import { SessionService } from '../shared/services/Sessions.service';
import { reportsFeeService } from '../shared/services/reportsFee.service';
import { FeeService } from '../shared/services/Fee.service';

@Component({
  selector: 'app-admitted-set',
  templateUrl: './admitted-set.component.html',
  styleUrls: ['./admitted-set.component.css']
})
export class AdmittedSetComponent implements OnInit {
  classDT: Array<SelectList>;
  isAdmin: boolean = false;
  base: string;
  @ViewChild("f") formRef: NgForm;
  majors: any;
  baseName = '';
  admBase: Array<SelectList>;

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private classService: ClassService,
    private sessionService: SessionService,
    private feeService: FeeService,
    private datePipe: DatePipe,
    private reportsFee: reportsFeeService,
    private majorService: MajorService
  ){}
  loader = false;
  ngOnInit(): void {
    this.classDT = [];
    this.classService
      .getAllClassesFeeServer()
      .subscribe((res: { c_code: number; Des: string }[]) => {
        res?.forEach((entry) => {
          this.classDT.push({
            id: entry.c_code, nm: entry.Des
          });
        });
      });
  }
  updateAdmissionBasis() {
    this.admBase = [];
    this.feeService.getAdmissionBasis().subscribe((res: { b_id: number, b_nm: string }[]) => {
      res?.forEach(entry => {
        this.admBase.push({ id: entry.b_id, nm: entry.b_nm });
      });
    });
  }
  

  OnMajorChange(m: HTMLSelectElement) {
    this.updateAdmissionBasis();
  }
  
  OnSubmit() {}

 

  
  onClear() {
    this.formRef.reset();
    
  }
  onBaseChange(b: HTMLSelectElement) {
    this.baseName = this.admBase[b.selectedIndex].nm;
   
  }

  ClearClassBase() {
    // this.formRef?.controls['year'].reset();
  }

  OnClassChange(c: HTMLSelectElement) {
    this.getMajors();
    
  }
 
  getMajors() {
    this.majors = [];
    this.majorService
      .getMajor({ c_code: this.formRef.value.c_code })
      .subscribe((res: { MAJ_ID: number; MAJ_NM: string }[]) => {
        res?.forEach((entry) => {
          this.majors.push({ id: entry.MAJ_ID, nm: entry.MAJ_NM });
        });
      });
  }


}
