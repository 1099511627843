<form (ngSubmit)="OnSubmit()" #f="ngForm">
  <div class=" container-fluid" [@SlideInFromLeft]>
    <div class="row card shadow px-2">
      <div class="card-header"  style="padding-top: 10px;">
        <div class=" col-lg-6 col-md-6 form-group">
          Student General Information Report</div>
           <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
          <app-details-button [details]="details"></app-details-button>
      </div>
    </div>
      <div class="row card-body pb-0  col-sm-12 px-4">
        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="C_Code">Class</label>
          <select #c_code class="form-control " name="c_code" id="C_Code"
            ngModel (change)="ClearClassBase()"
            (change)="OnClassChange(c_code)" ngModel required>

            <option *ngFor="let c of classDT" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="major">Major
          </label>
          <span class="login-box" style="margin-top: -47px;float:right">
            <form>
              <a style="font-size:9px;font-weight:bold">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                IF MAJOR WISE REQUIRED
              </a>
            </form>
          </span>

          <select class="form-control" #maj_id class="form-control "
            name="maj_id" id="maj_id" ngModel >
            <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
          </select>

        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="Session">Session</label>
          <select class="form-control " #session
            (change)="OnSessionChange(session)" name="se_id" id="Session"
            ngModel required>
            <option *ngFor="let s of sessions"
              value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>
      </div>

      <div class="row card-body px-4 ml-1">
        <button class="btn btn-primary" (click)="getData(session)" style="margin-left: -3px" [disabled]="!f.valid">
          Get Report
        </button>
        <button *ngIf="this.allData.length>0"
          class="btn ml-2 btn-danger" (click)="onPdfDownload(c_code)">
          Download PDF
        </button>

        <button *ngIf="this.allData.length>0"
          class="btn btn-success ml-2"
          (click)="DownloadExcel(c_code,maj_id)">
          <i class="fa fa-file-excel-o" for></i>
          Download Report Excel
        </button>
        <button class="btn ml-2 btn-warning" (click)="onClear()">
          Clear
        </button>
      </div>
      <loader *ngIf="loader"></loader>
      <div class="px-4" *ngIf="allData.length>0">
       <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
          <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
      </div>
      <table class="table table-bordered table-striped mt-4" id="tableID" id="table" style="font-size: 10px">
        <thead class="thead-dark">
          <th>
            Sr #
          </th>
          <th style="width: 10vw">
            Roll #
          </th>
          <th>
            Form #
          </th>
          <th>
            Name
          </th>
          <th>
            Father Name
          </th>
          <th>
            Gender
          </th>
          <th>
            Address
          </th>
          <th>
            Phone
          </th>
          <th>
            Guardian's Phone #
          </th>
          <!-- <th> Major is already selected above
            Major
          </th> --> 
        </thead>
        <tbody>
          <tr *ngFor="let i of this.allData; let j=index" id="values">
            <td>
              {{j+1}}
            </td>
            <td>
              {{i.rn}}
            </td>
            <td>
              {{i.f_no}}
            </td>
            <td>
              {{i.name}}
            </td>
            <td>
              {{i.f_name}}
            </td>
            <td>
              {{i.gender}}
            </td>
            <td>
              {{i.address}}
            </td>
            <td>
              {{i.phone}}
            </td>
            <td>
              {{i.g_phone}}
            </td>
            <!-- <td>
              {{i.group}}
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
    </div>
  </div>
</form>