<div [@SlideInFromLeft] class="container-fluid ">
  <form #f="ngForm">
    <div class="card shadow">

      <div class="card-header">
        <div>Struck OFF</div>
      </div>
      <div class="row card-body">
        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="YEAR" class="text-left">Year</label>
          <input class="form-control" name="YEAR" id="YEAR" #YEAR (change)="onYearChange()" ngModel required>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="C_CODE">Class</label>
          <select #C_CODE (change)="OnClassChange(C_CODE)" class="form-control " name="C_CODE" id="C_CODE" ngModel>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="RN" class="text-left">Roll No.</label>
          <input class="form-control" name="RN" id="RN" (change)="OnRollNumberChange()" #RN ngModel required>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="name" class="text-left">Name</label>
          <input class="form-control" name="name" id="name" value="{{this.StudentName}}" #name readonly>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="SE_ID">Session</label>
          <select class="form-control " #SE_ID (change)="OnSessionChange(SE_ID)" name="SE_ID" id="SE_ID" ngModel>
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="DATE" class="text-left">Expulsion Date</label>
          <input type="DATE" class="form-control" name="DATE" id="DATE" #DATE ngModel required>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="NR_ID">Remarks</label>
          <select #NR_ID class="form-control " name="NR_ID" id="NR_ID" ngModel>
            <option *ngFor="let c of Remarks" value="{{c.NR_ID}}">{{c.NR_DES}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="T_NO">Semester</label>
          <select #T_NO class="form-control" (change)='OnSemesterChange()' name="T_NO" id="T_NO" required ngModel>
            <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]" value="{{i}}">{{i}}</option>
          </select>
        </div>
      </div>

      <div class="card-body">
        <div class="row card-body" style="margin-top: -5rem;">
          <button class="btn btn-outline-primary" style="margin-left: -17px;" type="button" (click)="AddRecord()" [disabled]="!f.valid">Add Record</button>
          <button class="btn btn-outline-primary ml-1" type="button" (click)="FetchData()" [disabled]="!f.valid">Fetch Record</button>
          <button class="btn btn-outline-danger ml-1" type="button" (click)="ClearForm(f)">Clear</button>
        </div>
      </div>

      <div *ngIf="StudentRecord?.length" class="card-header" style="margin-top:-2rem;">
        Student Record
      </div>
      <div *ngIf="StudentRecord?.length" class="card-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Sr.#</th>
              <th scope="col">Roll Number</th>
              <th scope="col">Name</th>
              <th scope="col">Remarks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of StudentRecord; let i =index;">
              <td>{{i+1}}</td>
              <td>{{r.ROLNO}}</td>
              <td>{{r.NM}}</td>
              <td>{{r.NR_DES}}</td>
              <td *ngIf="!(r.LOC===1)"><button class="btn btn-outline-danger" (click)="DeleteRecord(r.YEAR,r.C_CODE,r.SE_ID,r.RN )">Delete</button>
              </td>
              <td *ngIf='(i===index)&&(r.LOC===1)&&(this.edit)' class="input-group input-group-sm">
                <label>New Remarks
                  <select #NR_ID_NEW class="form-control " name="NR_ID_NEW" id="NR_ID_NEW" ngModel>
                    <option *ngFor="let c of Remarks" value="{{c.NR_ID}}">{{c.NR_DES}}</option>
                  </select>
                </label>
                <button class="btn btn-outline-success mt-3 ml-1" style="resize:none; max-height: 2.5rem;"
                  (click)="Update(i,NR_ID_NEW.value)">
                  Update
                </button>
              </td>
              <td *ngIf="(r.LOC===1)">
                <button class="btn btn-outline-warning" (click)="toggleRecord(i)">
                  <i class="fas fa-edit"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
</div>