import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class DepartmentService {

    constructor(private http: HttpClient) {
    }

    getDepartments(params) {
        return this.http.post(`${baseUrl}/api/department/getDepartment`, params);
    }

    getCurrentYears(){
        return this.http.post(`${baseUrl}/api/department/S_CurrentYear`, {});
    }
    
    getDepartmentAgainstClass(params) {
        return this.http.post(`${baseUrl}/api/department/getDepartmentAgainstClass`, params);
    }
    EV_F_getDptByGroup(params){
        return this.http.post(`${baseUrl}/api/department/EV_F_getDptByGroup`,params);
    }

}
