<div [@SlideInFromLeft] class="tabber">
    <div class="container-fluid card shadow px-0">
        <div class="card-header px-4">
            <div class="over">
                <div class="loader">

                </div>
                <!-- Loading... -->
            </div>
            <div>Status Of Transcript Report</div>
        </div>
        <form #f="ngForm" class="px-4">
            <div class="row card-body space-remover px-0">

                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="c_code">Class</label>
                    <select #c_code class="form-control" ngModel name="c_code" id="c_code" required>
                        <option *ngFor="let i of classes" value="{{i.id}}">{{i.nm}} </option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                    <label for="st_year">Start Year</label>
                    <input #st_year class="form-control" ngModel name="st_year" id="st_year" required>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                    <label for="end_year">End Year</label>
                    <input #st_year class="form-control" ngModel name="end_year" id="end_year" required>
                </div>


                <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                    <label for="notifDate">Start Date Notification</label>
                    <input type="date" #notifDate class="form-control" ngModel name="notifDate" id="notifDate" required
                        ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                    <label for="notifDate1">End Date Notification</label>
                    <input type="date" #notifDate class="form-control" ngModel name="notifDate1" id="notifDate1"
                        required ngModel>
                </div>
                <div></div>
                <div class="test ml-3 d-flex flex-column" style="margin-top:28px">
                    <!-- [disabled]="!f.valid" -->
                    <button class="btn btn-outline-primary" type="button" (click)="DataLoad()"
                        [disabled]="!f.valid">Display Data
                    </button>
                </div>
                <div class="test ml-3 d-flex flex-column" style="margin-top:28px">
                    <!-- [disabled]="!f.valid" -->
                    <button class="btn btn-outline-danger" type="button" (click)="OnReport()" [disabled]="!f.valid">
                        <i class="fa fa-file-pdf-o" for=""></i>
                        Download Pdf
                    </button>
                </div>
                <div class="test ml-3">
                    <!-- <button [disabled]="!f.valid" class="btn btn-outline-primary btn-sm" type="submit">Generate Trans.
                    </button> -->
                    <button class="btn btn-outline-success ml-1" [disabled]="!f.valid" type="button"
                        style="margin-top:28px;" (click)="DownloadExcel()">
                        <i class="fa fa-file-excel-o" for=""></i>
                        Download Excel
                    </button>
                </div>
                <div class="test ml-3">
                    <!-- <button [disabled]="!f.valid" class="btn btn-outline-primary btn-sm" type="submit">Generate Trans.
                    </button> -->
                    <button class="btn btn-warning ml-1" type="button" style="margin-top:28px;"
                        (click)="OnClear(f)">Clear Form
                    </button>
                </div>
            </div>
        </form>
        <div class="px-4">
            <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1" *ngIf="Details.length > 0">  
                <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
            </div>
            <table class="table table-bordered table-striped mt-4" *ngIf="isOpen && Details.length>0" id="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Sr #</th>
                        <th scope="col">Trans No</th>
                        <th scope="col">Roll No</th>
                        <th scope="col">Name</th>
                        <th scope="col">Father Name</th>
                        <th scope=" col ">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let detail of Details let i=index" id="values">
                        <td>{{i+1}}</td>
                        <td>{{detail.trans_no}}</td>
                        <td>{{detail.rollno}}</td>
                        <td>{{detail.Name}}</td>
                        <td> {{detail.F_name }} </td>
                        <td>{{detail.notifDate }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>