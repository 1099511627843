import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { EnrollmentService } from '../shared/services/Enrollment.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { ClassService } from './../shared/services/Class.service';
import { SelectList } from './../shared/model/SelectList.model';
import { SessionService } from './../shared/services/Sessions.service';
import { runInNewContext } from 'vm';


@Component({
  selector: 'app-repeater-improver',
  templateUrl: './repeater-improver.component.html',
  styleUrls: ['./repeater-improver.component.css']
})
export class RepeaterImproverComponent implements OnInit {
  classes: Array<SelectList>;
  sessions: Array<SelectList>;
  rolno: Array<SelectList>;

  @ViewChild('f') formRef: NgForm;


  constructor(
    private toastr: ToastrService,
    private classService: ClassService,
    private sessionService: SessionService,
    private enrollmentService: EnrollmentService

  ) {
    this.classes = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.rolno = new Array<SelectList>();
  }

  ngOnInit(): void {
    this.classService.getClasses({}).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
    });
  }


  updateSessions() {
    this.sessions = [];
    // this.subjects = [];

    if (this.formRef.value.c_code === "") return;

    this.sessionService.getSessions(this.formRef.value.c_code).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(entry => {
        this.sessions.push({ id: entry.SE_ID, nm: entry.SE_NM });
      });
    });
  }

  OnSubmit() {
    if (!this.formRef.valid)
      return;
  }


  OnClassChange(c: HTMLSelectElement) {
    this.updateSessions();
  }

  getRolno() {
    this.rolno = [];
    this.enrollmentService.notEnroll(this.formRef.value).subscribe((res: { rn: number, rolno: string }[]) => {
      res?.forEach(entry => {
        this.rolno.push({ id: entry.rn, nm: entry.rolno });
      })
    });
  }
}
