<div class="row p-2" [@SlideInFromLeft]>
    <div class="col-sm-12">
      <div class="btn-group" role="group" aria-label="Basic example">
        <button style="margin-left:-8px" type="button" class="btn btn-outline-info" routerLinkActive="active"
          routerLink="singleSubjectEnroll">Single Subject Enrollment</button>
          <button type="button" class="btn btn-outline-info" routerLinkActive="active"
          routerLink="singleEnroll">Single Student Enrollment</button>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
  