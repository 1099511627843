import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'app-view-requested-subjects',
  templateUrl: './view-requested-subjects.component.html',
  styleUrls: ['./view-requested-subjects.component.css']
})
export class ViewRequestedSubjectsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      $('.backdrop').css('opacity', '1');
    }, 50);
  }

  

  viewDetails(d_id, c_code, maj_id, se_id, t_no)
  {

  }

}
