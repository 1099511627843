<form #f="ngForm">
    <div class=" container-fluid" [@SlideInFromLeft]>
        <div class="row card shadow">
            <div class="card-header">
                <div>Group Base Wise Collection Report</div>
            </div>
            <div class="row card-body pb-0 px-4">
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="c_code">Class</label>
                    <select #c_code class="form-control " name="c_code" id="c_code" ngModel (change)="ClearClassBase()">
                        <option *ngFor="let c of allClasses" value="{{c.id}}">{{c.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="year">Year</label>
                    <select class="form-control" id="year" aria-label="Year" ngModel
                      required name="year">
                      <option *ngFor="let s of sessions" value="{{s}}">{{s}}</option>
                    </select>
                  </div>
                  <div class="col-lg-4 col-md-6 form-group">
                      <label for="m_list">Merit List</label>
                      <input type="number" class="form-control" id="m_list" aria-label="m_list" min="0" ngModel
                        required name="m_list">
                    </div>
            </div>

            <div class="row card-body">
                <button class="btn btn-outline-danger" (click)="onData(c_code)" [disabled]="!f.valid">
                    <i class="fa fa-file-pdf-o" for=""></i>
                    Download Group-Base Report
                </button>
                <button class="btn btn-outline-danger" (click)="onMeritWiseData(c_code)" [disabled]="!f.valid">
                    <i class="fa fa-file-pdf-o" for=""></i>
                    Download Group-Base-Merit Report
                </button>
                <button class="btn ml-3 btn-warning" (click)="onClear()">
                    Clear
                </button>
            </div>
            <ng-container *ngIf="loader then ifBlock;"></ng-container>
            <ng-template #ifBlock>
                <div style="display:flex;justify-content:center; align-items: center;"><span class="loader"></span>
                </div>
            </ng-template>
        </div>
    </div>
</form>
