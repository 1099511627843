import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SlideInFromLeft } from 'src/app/transitions';
import { tableCollection } from './exim.model';
import { ToastrService } from 'ngx-toastr';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { ImportExportService } from '../shared/services/ImportExport.service';


@Component({
  selector: 'app-exim',
  templateUrl: './exim.component.html',
  styleUrls: ['./exim.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class EximComponent implements OnInit {

  @ViewChild('f') formRef: NgForm;
  @ViewChild('inty') inputRef: ElementRef;
  public leng: number;
  public arr = new Array<tableCollection>();
  public varr = new Array<any>();
  public resul= new Array<any>();
  public col = new Array<string>();
  private headers = [];
  private body = [];
  dtHolidays: any;
  public content: Array<string[]>;


  constructor(private http: HttpClient,
     private toastr: ToastrService,
     private importExportService:ImportExportService
     ) {
    this.arr = new Array<tableCollection>();
    this.varr = new Array<string>();
    this.col = new Array<string>();

    this.content = [];
    this.headers = [];
    this.body = [];
    this.leng = 0;
  }
  downloadCSV() {
    if(!this.formRef.value.tn || !this.formRef.value.dt || !this.formRef.value.t_to){
      this.toastr.warning("All field must be fill");
      return;
    }
    this.importExportService.getColName(this.formRef.value).subscribe(res => {
      this.headers = [];
      for (const i in res) {
        this.headers.push(res[i].Field);
      }
    });

    this.importExportService.getData(this.formRef.value).subscribe((res: any[]) => {
      this.body = [];
      res.forEach(entry => {
        this.body.push(Object.values(entry));
      })

      var array: any[];
      array = this.headers;
      new AngularCsv(this.body, this.formRef.value.tn, { headers: (array) });
    }
      , err => {
        console.log(err);
        this.toastr.error("Unknon error");
      }
    );
    var array: any[];
    array = this.col;
    new AngularCsv(this.content, this.formRef.value.tn, { headers: (array) });
  }

  ngOnInit(): void {
    this.importExportService.gettbnm().subscribe(res => {
      for (const i in res) {
        this.arr.push(new tableCollection(res[i].Tables_in_lms));
      }
    });
  }

  test(form){
  }

  OnSubmit() {
    
    document.getElementById('hid').className="show";
    if(!this.formRef.value.tn || !this.formRef.value.dt || !this.formRef.value.t_to){
      this.toastr.warning("All field must be fill");
      return;
    }
    this.importExportService.getColName(this.formRef.value).subscribe(res => {
      this.col = [];
      for (const i in res) {
        this.col.push(res[i].Field);
      }
    });

    this.importExportService.getData(this.formRef.value).subscribe((res: any[]) => {
      this.content = [];
      res.forEach(entry => {
        this.content.push(Object.values(entry));
      })
    }
      , err => {
        console.log(err);
        this.toastr.error("Unknon error");
      }
    );
  }

OnGetQuery(){
  this.importExportService.getQuery(this.inputRef.nativeElement.value).subscribe((res: any[]) => {
    this.toastr.success("Query executed");
      this.resul = [];
      res.forEach(entry => {
        this.resul.push(Object.values(entry));
      })
  }, err => {
    console.log(err);
    this.toastr.error("Query not executed");
  }
  );
}

onExportForm()
{
  document.getElementById('import').className="hide";
  document.getElementById('export').className="show";
}

onImportForm()
{
  document.getElementById('export').className="hide";
  document.getElementById('import').className="show";
}

onButtonClick(){
  document.getElementById('hid').className="show";
  this.col=[];
  this.content=[];
}

onTabCol(form){
  this.importExportService.getColName(form.value.tn).subscribe(res => {
    this.col = [];
    for (const i in res) {
      this.col.push(res[i].Field);
    }
  });
}
}