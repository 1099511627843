import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
import { param } from 'jquery';


@Injectable({
  providedIn: 'root'
})
export class IntermediateService {

  constructor(private http: HttpClient) { }

    // Shoaib Abbas Code 
  getMajors(){
    return this.http.post(`${baseUrl}/api/intermediate/intermajor`,{});
  }

  getSubject(st_year: any, c_code: any, t_no: any){
    return this.http.post(`${baseUrl}/api/intermediate/intersubj`,{st_year,c_code,t_no});
  }

  getInterResult(sub_code:any,type:any,sub_type:any,st_year: any, t_no: any, section: any){
    return this.http.post(`${baseUrl}/api/intermediate/inter_rslt`,{sub_code,type,sub_type,st_year, t_no, section});
  }

  getInterSections(sub_code:any,type:any,sub_type:any,st_year: any, t_no: any){
    return this.http.post(`${baseUrl}/api/intermediate/inter_section`,{sub_code,type,sub_type,st_year, t_no});
  }

  updateGrade(st_year :any, rn :any, sub_type :any, sub_code :any, t_no :any, obt_mrks :any, attnd :any, fine: any){
    return this.http.post(`${baseUrl}/api/intermediate/inter_update`, {st_year, rn, sub_type, sub_code, t_no, obt_mrks, attnd, fine})
  }


  createAssessment(st_year :any, sub_type :any, sub_code :any, t_no :any, tot_mrks :any, date: any, rol_no:any, mode_in: any){
    return this.http.post(`${baseUrl}/api/intermediate/inter_create_ass`, {st_year, sub_type, sub_code, t_no, tot_mrks, date, rol_no, mode_in})
  }

  displayAssignments( sub_code :any,st_year :any, t_no :any){
    return this.http.post(`${baseUrl}/api/intermediate/display_created`, {sub_code, st_year, t_no})
  }

  deleteAssignments(param :any){
    return this.http.post(`${baseUrl}/api/intermediate/delete_assessment`, {param})
  }

  UpdateAssignments(param :any, updated_tot_marks: any){
    return this.http.post(`${baseUrl}/api/intermediate/update_assessment`, {param, updated_tot_marks})
  }

  // Reporting

  getInterAbsentReport(sub_code:any,type:any,sub_type:any,st_year: any, t_no: any){
    return this.http.post(`${baseUrl}/api/intermediate/inter_absenties_rpot`,{sub_code,type,sub_type,st_year, t_no});
  }

  getInterPresntObtainNulll(sub_code:any,type:any,sub_type:any,st_year: any, t_no: any){
    return this.http.post(`${baseUrl}/api/intermediate/inter_P_obt_null`,{sub_code,type,sub_type,st_year, t_no});
  }

  getInterFine(type:any,sub_type:any,st_year: any, t_no: any, rpt_type: any){
    return this.http.post(`${baseUrl}/api/intermediate/inter_fine`,{type,sub_type,st_year, t_no, rpt_type});
  }

  // getInterDisiplineFine(type:any,sub_type:any,st_year: any, t_no: any){
  //   return this.http.post(`${baseUrl}/api/intermediate/S_inter_DisiplinaryFine`,{type,sub_type,st_year, t_no});
  // }

  getInterInternalGrades(maj_id: any, type:any,sub_type:any,st_year: any){
    return this.http.post(`${baseUrl}/api/intermediate/S_inter_internal_grades`,{maj_id, type, sub_type, st_year});
  }

  getInterTM_AwarsList(type:any,sub_type:any,st_year: any, t_no: any, maj_id: any){
    return this.http.post(`${baseUrl}/api/intermediate/inter_TM_AwardList`,{type, sub_type, st_year,t_no, maj_id});
  }

  getStdtAddress(type:any,sub_type:any,st_year: any, t_no: any, maj_id: any){
    return this.http.post(`${baseUrl}/api/intermediate/S_inter_stdtAddress`,{type, sub_type, st_year,t_no, maj_id});
  }

  getFMname(){
    return this.http.post(`${baseUrl}/api/intermediate/S_inter_getFMname`,{});
  }

  getS_inter_enrollYear(){
    return this.http.post(`${baseUrl}/api/intermediate/S_inter_enrollYear`,{});
  }

  getS_interCharCertificateDetail(st_year: any, maj_id: any){
    return this.http.post(`${baseUrl}/api/intermediate/S_inter_getDetails`,{st_year, maj_id});
  }

  // Board Roll Number Screen
  getS_inter_getStdtBoardRollNum(param){
    // console.log(param);
    return this.http.post(`${baseUrl}/api/intermediate/S_inter_getStdtBoardRollNum`,{param});
  }


  // Detailed Report Subject and Section Wise Screen
  S_inter_SubjSecRpt(param){
    // console.log(param);
    return this.http.post(`${baseUrl}/api/intermediate/S_inter_SubjSecRpt`,{param});
  }


  inter_UpdateStdtBoardRollNum(param){
    console.log(param);
    return this.http.post(`${baseUrl}/api/intermediate/S_inter_upadteStdtBoardRollNum`,{param});
  }


  // Shoaib Abbas Code Above


  // Rana Shoaib Nawaz's Code Below


  getRol(st_year :any, reg_no:any){
    return this.http.post(`${baseUrl}/api/intermediate/getstudent`, {st_year,reg_no});
   }

   getName(reg_no :any , st_year :any, t_no:any){
    return this.http.post(`${baseUrl}/api/intermediate/getstudentname`, {reg_no,st_year,t_no});
   }

   getDetail(st_year :any,Roll_No :any,t_no :any,id :any){
    return this.http.post(`${baseUrl}/api/intermediate/getstudentsubject`, {st_year,Roll_No,t_no,id});
   }

    getStudentMarks(st_year :any,maj_id :any,t_no :any,sub_ex_id :any, rn:any){
    return this.http.post(`${baseUrl}/api/intermediate/getstudentmarksdetails`, {st_year,maj_id,t_no,sub_ex_id,rn});
   }

   getStudentTotalMarks(st_year :any,maj_id :any,t_no :any,sub_ex_id :any, rn:any){
    return this.http.post(`${baseUrl}/api/intermediate/getstudenttotalmarks`, {st_year,maj_id,t_no,sub_ex_id,rn});
   }

   Update(st_year :any,Roll_No :any, id :any,t_no :any, tot_mrks : any, obt_prctical:any, obt_thoery:any, sub_code:any){
    return this.http.post(`${baseUrl}/api/intermediate/Update`, {st_year, Roll_No, id,t_no, tot_mrks, obt_prctical, obt_thoery, sub_code} );
   }

   UpdateStudentDetail(st_year :any,Roll_No :any, Board_No :any, Reg_No : any, BRD_RN_2 :any){
    return this.http.post(`${baseUrl}/api/intermediate/UpdateStudentDetail`, {st_year, Roll_No, Board_No, Reg_No, BRD_RN_2} );
   }

   getmarks(st_year :any,Roll_No :any, t_no :any, sub_ex_id : any){
    return this.http.post(`${baseUrl}/api/intermediate/getmarks`, {st_year, Roll_No, t_no, sub_ex_id} );
   }

   createpdf2(st_year :any,t_no :any, sub_ex_id : any,maj_id: any){
    return this.http.post(`${baseUrl}/api/intermediate/interpdf2`, {st_year, t_no, sub_ex_id,maj_id} );
   }

   getGroup(st_year :any, sub_ex_id : any){
    return this.http.post(`${baseUrl}/api/intermediate/getInterGroup`, {st_year,sub_ex_id} );
   }

   createpdf3(st_year :any, sub_ex_id : any,maj_id: any){
    return this.http.post(`${baseUrl}/api/intermediate/interpdf3`, {st_year, sub_ex_id,maj_id} );
   }

   sessions(){
    return this.http.post(`${baseUrl}/api/intermediate/getsessions`, {} );
   }

   getCGPA(){
    return this.http.get(`${baseUrl}/api/intermediate/getCGPA`, {} );;
  }

  getCurrentTimeTable(){
    return this.http.get(`${baseUrl}/api/intermediate/getcurrenttimetable`, {} );;
  }

  getInterDetail(st_year :any, t_no :any){
    return this.http.post(`${baseUrl}/api/intermediate/Get_Inter_Details`, {st_year, t_no});
   }

   R_getMajors(){
    return this.http.post(`${baseUrl}/api/intermediate/getmajors`, {} );
   }

   getInterBoardDetail(st_year :any, t_no :any, maj_id :any){
    return this.http.post(`${baseUrl}/api/intermediate/Get_Inter_Board_Details`, {st_year, t_no,maj_id});
   }

   getTestGroup(st_year :any, sub_ex_id : any){
    return this.http.post(`${baseUrl}/api/intermediate/getInterTestGroup`, {st_year,sub_ex_id} );
   }

   getInterRollNoList(st_year :any, maj_id:any, t_no:any, sub_ex_id : any){
    return this.http.post(`${baseUrl}/api/intermediate/get_inter_rolno_list`, {st_year,maj_id,t_no,sub_ex_id} );
   }

   getInterBoardSignature(st_year :any, t_no :any, maj_id :any){
    return this.http.post(`${baseUrl}/api/intermediate/Get_Inter_Board_Signature`, {st_year, t_no,maj_id});
   }
  
}
