<form #fn="ngForm">
  <div [@SlideInFromLeft] class="container-fluid card shadow" style="min-height: 80vh;">
    <div class="card-header"  style="padding-top: 10px;">
    <div class=" col-lg-6 col-md-6 form-group">
      Recover User
    </div>
    <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
      <app-details-button [details]="details"></app-details-button>
  </div>
</div>

    <div class="card-body row"  style="padding-top: 10px;">
        <div class=" col-lg-6 input-group my-2">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">Username</span>
          </div>
          <input #username type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon1"
            name="in" id="in" ngModel required>
        </div>

        <div class=" search-btn mt-2">
          <button [disabled]="!fn.valid" type="button" class="btn btn-primary " (click)="getUser()">
            Search
          </button>
        </div>

    </div>

    <div class="card-header">
      Details
    </div>

    <div class="card-body">
      <div class=" col-lg-6 col-sm-12 input-group mt-2 mb-4 ml-0 px-0">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">Name</span>
        </div>
        <input type="text" class="form-control" name="name" id="name" value={{name}} aria-label="Username"
          aria-describedby="basic-addon1" readonly>
      </div>

      <div class=" col-lg-6 col-sm-12 input-group mt-4 mb-2 ml-0 px-0">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">CNIC</span>
        </div>
        <input type="text" class="form-control" maxlength="15" type="text" name="nic" id="nic" value={{nic}}
          aria-label="Username" aria-describedby="basic-addon1" ngModel>
      </div>
    </div>

    <div class="card-header">
      Actions
    </div>

    <div class="card-body">
      <div class="row col-lg-6">
        <button class="btn btn-primary" [disabled]="!fn.valid || !name || !this.usnmRef.controls['nic']?.dirty"
          (click)="updatenic()">Update
          CNIC</button>
        <button class="btn btn-warning ml-2" [disabled]="!fn.valid || !nic" type="button"
          (click)="resetPw()">Reset
          Password</button>
      </div>
    </div>

  </div>
</form>
