import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { NgForm } from '@angular/forms';
import { UtilService } from 'src/app/Util.service';
import { ToastrService } from 'ngx-toastr';
import { SelectList } from './../shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';
@Component({
  selector: 'app-insert-subject',
  templateUrl: './insert-subject.component.html',
  styleUrls: ['./insert-subject.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class InsertSubjectComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;

  departments: Array<SelectList>;
  classes: SelectList[];
  subNature:Array<SelectList>;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  you can add the subject of any department in any class with different subject nature.And it will be visible to every major in that class </h6>
  <h6 class="text-right">آپ کسی بھی ڈیپارٹمنٹ کے سبجیکٹ کو کسی بھی کلاس میں مختلف سبجیکٹ نیچر کے ساتھ شامل کر سکتے ہیں۔ اور یہ اس کلاس کے ہر میجر کو نظر آئے گا۔
</h6>
  `  ;

  constructor(
    private utilService: UtilService,
    private toastr: ToastrService,
    private classService: ClassService,
    private lmsSharedService: LMSharedDataService
  ) { 
    this.departments = new Array<SelectList>();
    this.classes = [];
    this.subNature = [];
  }

  ngOnInit(): void {
    this.updateAdminDept();
    this.loadSubjectNature();
  }

  updateAdminDept() {
    this.lmsSharedService.getAdminDept()
      .then(adminDept => {
        this.departments = adminDept;
      })
      .catch(error => {
        console.error("Error getting lms admin Departments", error);
      });
  }

  loadSubjectNature(){
    this.utilService.getSubjectNature().subscribe((res:{NAT_ID, DES_NM}[]) =>{
      if(res?.length>0){
        res.forEach(e =>{
          this.subNature.push({id:e.NAT_ID, nm:e.DES_NM})
        }) 
      }
    }, err =>{
      console.log(err);
      this.toastr.error("Some Error Occred");
    })
  }

  OnSubmit(f :NgForm)
  {
    this.utilService.insertSubject(f.value).subscribe(res=>{
      if(res){
     
        this.toastr.success("Added successfully");
      }
    }, err=>{
      console.log(err);
      this.toastr.error("error occred");
    })
  }


  OnDeptChange(d: HTMLSelectElement) {
    this.updateClasses();
    this.dptsubjects = [];
  }

  OnClassChange(c: HTMLSelectElement) {

    this.getdepartmentalsubjects()
  }

  dptsubjects:any
  getdepartmentalsubjects(){
    this.dptsubjects = [];
    const {d_id,c_code}=this.formRef.value;
    this.classService.getdepartmentalsubjects(d_id,c_code).subscribe(res => {
      this.dptsubjects = res;
          },
      err => {
        console.log(err);
      });

  }

  updateClasses() {
    this.classes = [];
    this.classService.getClasses({ d_id: this.formRef.value.d_id}).subscribe((res:{C_CODE:number, C_NM:string}[]) => {
      res?.forEach(entry=>{
        this.classes.push({id: entry.C_CODE, nm: entry.C_NM});
      });
    },
      err => {
        console.log(err);
      });
  }
  Clear(f){
    f.resetForm();
    this.classes=[];    
  }  

  Filter()
  {
    filter()
  }
}
