<form (ngSubmit)="OnSubmit()" #f="ngForm">
  <div class=" container-fluid" [@SlideInFromLeft]>
    <div class="row card shadow">
      <div class="card-header" style="padding-top: 10px;">
        <div class="col-lg-4 col-md-6 form-group">
          Subject Fee
        </div>
        <!-- <div class="div1">
          <button class="btn1 fa" (click)="myFunction()" [ngClass]="flag ? 'fa-exclamation-circle' : 'fa-close'"
            [ngStyle]="{'color': flag? 'blue' : 'tomato'}"></button>
          <div class="popuptext" style="font-size: 10px; display: none;" id="myPopup">
            <h6>I am Here, look at me!</h6>
            <p style="margin-right: 4px; margin-left: 4px;">I am your stereo, it beats for you so listen close. There is
              an old man sitting next to me. MAking love to his tonic and gin</p>
            <p style="margin-right: 4px; margin-left: 4px;">Hey son can you please play me a memory, I am not sure how
              it goes, but it's sad and it's sweet and i knew it complete when i wore a younger man's clothes</p>
          </div>
        </div> -->
      </div>

      <div class="row card-body pb-0">
      <!--   <div class="col-lg-4 col-md-6 form-group">
          <label for="Day">Group</label>
          <input *ngIf="!isAdmin" type="text" class="form-control"
            value='{{ ((this.groups.length>0) ? groups[0].nm:" ")}}' name="grp" id="grp" readonly>
          <select *ngIf="isAdmin" #grp class="form-control" name="grp" id="grp" (change)="OnGrpChange(grp)" ngModel
            required>
            <option *ngFor="let grp of groups" value="{{grp.id}}">{{grp.nm}}</option>
          </select>
        </div> 
        </div> -->


      <div class="col-lg-4 col-md-6 form-group">
        <label for="C_Code">Class</label>
        <select #cource (change)="OnClassChange(cource)" class="form-control " name="c_code" id="C_Code" ngModel>
          <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
        </select>
      </div>
      <div class="col-lg-4 col-md-6 form-group">
        <label for="Major">Major</label>
        <select class="form-control" #major (change)="OnMajorChange(major)" name="maj_id" id="Major" ngModel>
          <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
        </select>
      </div>

      <div class="col-lg-4 col-md-6 form-group">
        <label for="Session">Session</label>
        <input type="number" class="form-control" name="year" min="1" (change)="updateSubjects()" ngModel required>
      </div>

      <div class="col-lg-4 col-md-6 form-group">
        <label for="Subject">Subject</label>
        <select #subject class="form-control" name="sub_code" id="Subject" ngModel>
          <option *ngFor="let sub of subjects" value="{{sub.sub_code}}">{{sub.sub_code}} {{sub.sub_nm}}</option>

        </select>
      </div>


      <div class="col-lg-4 col-md-6 form-group">
        <label for="amount">Amount</label>
        <input type="number" class="form-control" name="amnt" min="1" ngModel required>
      </div>
    </div>

    <div class="card-header">
      Actions
    </div>

    <div class="row card-body">
      <button class="btn btn-primary  btn-outline-primary" (click)="addSubjectFee()">
        Add
      </button>
      <button class="btn btn-warning ml-1 btn-outline-primary" (click)="onClear(f)">
        Clear
      </button>
    </div>

    <table class="table table-bordered table-striped mt-4" id="tableID">
      <thead class="thead-dark">
        <th>
          Subject
        </th>
        <th>
          Amount
        </th>
        <th>
          Actions
        </th>
      </thead>
      <tr *ngFor="let i of this.subFee, let k=index">
        <td>
          {{i.sub_nm}}
        </td>
        <!-- <td>
        {{i.f_no}}
      </td> -->
        <td>
          {{i.amount}}
        </td>
        <td>
          <button class="btn btn-danger" style="font-size: 12px;" (click)="deleteSubjectFee(i.sub_code,k)">
            Delete
          </button>
        </td>
      </tr>
    </table>
  </div>
  </div>
</form>
