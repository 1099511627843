import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { UtilService } from 'src/app/Util.service';
import { SubjectModel } from './Subject.model';
import { DepartmentService } from './../shared/services/Department.service';
import { SelectList } from './../shared/model/SelectList.model';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SubjectOfferService } from './../shared/services/SubjectOffer.service'
import { jsPDF } from "jspdf";
import autoTable, { UserOptions } from "jspdf-autotable";
import "jspdf-autotable";
import { DatePipe } from '@angular/common';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';

@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class SubjectsComponent implements OnInit {
  departments: SelectList[];
  classes: SelectList[];
  subjects: SubjectModel[];
  @ViewChild('f') formRef: NgForm;
  @ViewChild('input') inRef: HTMLInputElement;
  isExamCell: boolean = false;
  loader: boolean =false;
  edit: boolean = false;
  index: number;
  class:string = '';
  deptt:string = '';
  usr=null;
  subjectMap: Map<string, SubjectModel[]>;
  subNature:Array<SelectList>;
  update : {sub_nm:string,sub_code:string, cr_h:number, nat_id:number};
  modal: boolean = false;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  this screen is used to search any subject.You can also check and update the name,nature,credit hour of any subject of any department in  class </h6>
  <h6 class="text-right">یہ اسکرین کسی بھی سبجیکٹ کو تلاش کرنے کے لئے استعمال ہوتی ہے۔ آپ کسی بھی کلاس کے کسی بھی
ڈیپارٹمنٹ کے سبجیکٹ کا نام، نیچر، اور کریڈٹ آور بھی چیک اور اپڈیٹ کر سکتے ہیں۔۔
</h6>
  `  ;

  constructor(
    private utilService: UtilService,
    private toastr: ToastrService,
    private departmentService: DepartmentService,
    private subjectOfferService: SubjectOfferService,
    private authenticationService: AuthenticationService,
    private datePipe: DatePipe,
    private lmsSharedService: LMSharedDataService
  ) {
    this.departments = [];
    this.classes = [];
    this.isExamCell = this.authenticationService.isExamCell();
    this.usr=this.authenticationService.getUser();
    this.subjects = new Array<SubjectModel>();
    this.subjectMap = new Map<string, SubjectModel[]>();
    this.subNature = [];
    this.update = {sub_nm:'',sub_code:'', cr_h:0, nat_id:0};
  }

  ngOnInit(): void {
    this.updateClasses();
    this.loadSubjectNature();
  }

  loadSubjectNature(){
    this.utilService.getSubjectNature().subscribe((res:{NAT_ID, DES_NM}[]) =>{
      if(res?.length>0){
        res.forEach(e =>{
          this.subNature.push({id:e.NAT_ID, nm:e.DES_NM})
        }) 
      }
    }, err =>{
      console.log(err);
      this.toastr.error("Some Error Occred");
    })
  }

  getNatureID(nat_id:number):string{
    return this.subNature.find(i => i.id===nat_id)?.nm;
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting lms classes", error);
      });
  }
  
  OnClassChange(c: HTMLSelectElement) {
    this.class = this.classes[c.selectedIndex].nm;
    this.formRef.controls['d_id'].reset();
    this.subjects=[];
    this.subjectMap.clear();
    this.updateDepartment();
  }

  updateDepartment() {
    this.departments = [];
    this.departmentService.getDepartmentAgainstClass({ c_code: this.formRef.value.c_code }).subscribe((res: { D_ID: number, D_NM: string }[]) => {
      res?.forEach(entry => {
        this.departments.push({ id: entry.D_ID, nm: entry.D_NM });
      });
    });
  }

  OnDeptChange(d: HTMLSelectElement) {
    this.subjects=[];
    this.subjectMap.clear();
    this.deptt = this.departments[d.selectedIndex]?.nm;
  }
  GetSubjects() {
    this.getSubjects();
  }

  getSubjects() {
    this.loader = true;
    this.utilService.getSubjects(this.formRef.value.d_id || 0,this.formRef.value.c_code || 0).subscribe(res => {
      this.updateSubjectsList(res);
      this.MapFilling(res);
      this.loader = false;
    });
  }
  MapFilling(res) {
    this.subjectMap.clear();
    res.forEach(e => {
      if (this.subjectMap.has(e.D_NM)) {
        this.subjectMap.get(e.D_NM).push(e);
      }
      else {
        this.subjectMap.set(e.D_NM, new Array<SubjectModel>(e));
      }
    });
  }
  searchBySubcode(fn: NgForm) {
    this.utilService.searchSubjectByCode(fn.value.in).subscribe(res => {
      this.updateSubjectsList(res);
    });
  }

  searchByName(fn: NgForm) {
    this.utilService.searchSubjectByName(fn.value.in).subscribe(res => {
      this.updateSubjectsList(res);
    })
  }
  updateSubjectsList(res) {
    this.subjects = [];
    for (const i in res) {
      this.subjects.push(new SubjectModel(res[i].ID, res[i].SUB_CODE, res[i].SUB_NM, res[i].S_NM, res[i].D_NM, res[i].C_NM,res[i].CR_H, res[i].NAT_ID));
    }
  }
  DownloadExcel() {
    let body = [];
    this.subjects.forEach((entry,i) => {
      body.push({ sr: (i+1), sub_code: entry.SUB_CODE, sub_nm: entry.SUB_NM });
      i++;
    });
    new AngularCsv(body, this.class + "-" + this.deptt, { headers: ['Sr.', 'Subject Code', 'Subject Name'] });
  }
  DownloadPDF() {
    if (this.subjectMap?.size == 0) {
      return;
    }
    let body = [];
    let header = [];
    let arr: SubjectModel[];
    let page;
    let nm=this.usr?.NM;
    let className = this.class;
    this.toastr.info("Downloading TimeTable");
    const doc = new jsPDF("p", "mm", [297, 210]);
    let exportDate = this.datePipe.transform(new Date(), "MMM d, y, h:mm:ss a");

    let y = 20;
    // Created by Zain. 24 march, 2022
    for (let d_nm of this.subjectMap.keys()) {
      header = [
        [
          { content: "DEPARTMENT: " + d_nm?.toUpperCase(), colSpan: 3, styles: { halign: 'left', fillColor: [255, 255, 255], textColor: [0, 0, 0] } }
        ],
        ['Sr. No.', "Sub Code", "Subject Name"]
      ]
      arr = [];
      arr = this.subjectMap.get(d_nm);
      arr.forEach((entry, i) => {
        body.push([(i + 1),
        entry?.SUB_CODE,
        entry?.SUB_NM
        ]);
      })
      autoTable(doc, {
        theme: "grid",
        margin: { bottom: 15, top: 20, left: 10, right: 10 },
        styles: {
          cellPadding: 1,
          fontSize:11,
        },
        head: header,
        body: body,
        didDrawPage: function (d) {
          doc.setFontSize(14);
          doc.setFont("Arial", "bold");
          doc.text("GC UNIVERSITY LAHORE", 100, 10, { align: 'center' });
          doc.text(`CLASS: ${className}`, 100, 17, { align: 'center' });
          y = d.cursor.y;
          page = doc.getNumberOfPages();
        },
      });
      header=[];
      body = [];
    }
    doc.setFontSize(11);
    doc.setFont("Arial", "normal");
    for (let i = 1; i <= page; i++) {
      doc.text(`PRINTED BY: ${nm}`, 10, 286);
      doc.text("Note:- Errors and Omissions are Excepted", 10, 290);
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 110, 290, { align: 'center' });
      doc.text(`${exportDate}`, 159, 286);
      doc.text("Directorate of Information Technology", 140, 290);
    }
    let a = this.toastr;
    setTimeout(function () {
      a.success("Downloaded");
    }, 1000);
    this.toastr.success("PDF Download Successfully");
    doc.setProperties({
      title: `Subjects-${this.class}-(${this.deptt})`,
    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }
  toggleRecord(i:number, obj) {
    // this.edit = !this.edit;
    this.index = i;
    this.update.cr_h = obj?.cr_h;
    this.update.nat_id = obj?.nat_id;
    this.update.sub_code = obj?.SUB_CODE;
    this.update.sub_nm = obj?.SUB_NM;
  }

  Update(i:number, sub, event) {
    event.stopPropagation();
    this.subjectOfferService.UpdateSubName({ ID: i, SUB_NM: this.update.sub_nm, SUB_CODE:this.update?.sub_code, cr_h:this.update.cr_h, nat_id:this.update.nat_id, c_code:this.formRef.value.c_code, d_id:this.formRef.value.d_id}).subscribe((res:{warning:string, msg:string}) => {
      if(res==null || res==undefined){
        this.toastr.error('Some Error Occured.');
        return;
      }
      if (res?.msg=='Updated') {
        this.toastr.success("Updated Successfully");
        this.edit = false;
        this.modal = false;
        this.getSubjects();
      } 
      if(res?.warning!=null) {
        this.toastr.warning(res?.warning);
      }

    },
      err => {
        console.log(err);
      });
  }
  ClearBtn() {
    this.formRef.resetForm();
    this.departments = [];
    this.subjects = [];
    this.subjectMap.clear();
    this.loader = false;
  }

  Filter()
  {
    filter();
  }

  openModal(){
    this.edit = !this.edit
    this.modal = !this.modal; 
  }
}