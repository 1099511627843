import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SelectList } from '../shared/model/SelectList.model';
import { SessionService } from '../shared/services/Sessions.service';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';

interface Sessions {
  se_id: number,
  se_st_dt: Date,
  se_e_dt: Date,
  session_year: string,
  gp_per: string,
  att_per: string,
  p_criteria: number
}
@Component({
  selector: 'app-session-new',
  templateUrl: './session-new.component.html',
  styleUrls: ['./session-new.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class SessionNewComponent implements OnInit {
  classes: Array<SelectList>;
  @ViewChild('f') formRef: NgForm;
  newSe_id: number;
  sessions: Array<Sessions>;
  cls;
  st_dt_s;
  end_dt_e;
  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private sessService: SessionService,
    private lmsSharedService: LMSharedDataService
  ) {
    this.sessions = new Array<Sessions>();
    this.classes = new Array<SelectList>();
  }

  ngOnInit(): void {
    this.st_dt_s = moment(new Date()).format('YYYY-MM-DD');
    this.end_dt_e = moment(new Date()).format('YYYY-MM-DD');
    this.updateClasses();
    this.onEndYearChange();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting lms classes", error);
      });
  }

  onEndYearChange() {
    this.sessService.getNewSe_id().subscribe((res: { newSe_id: number }[]) => {
      this.newSe_id = (1 + res[0].newSe_id);
    },
      (err) => {
        this.toastr.error("Already Exist");
      });
    this.getSessions(this.formRef?.value.C_Code);
  }

  insertSession() {
    if (!this.formRef.valid) {
      this.toastr.warning("Please Enter All Fields");
      return;
    }

    this.sessService.insertSession(this.formRef.value).subscribe((res) => {
      this.toastr.success("Successfully Added");
    },
      (err) => {
        this.toastr.error("Already Exist");
      });

    this.sessService.insertSessionFeeServer(this.formRef.value).subscribe((res) => {
    })
    this.onEndYearChange();
  }

  OnSubmit() {
    if (!this.formRef.valid)
      return;
  }
  getSessions(c: any) {
    this.sessions = new Array<Sessions>(
    );
    this.sessService.getSessionsAll(c).subscribe((res) => {
      if (res) {
        res[0]?.forEach(element => {
          this.sessions.push(element);
        });
      }
    });
  }
  deleteSession(index: any, se_id: any) {
    this.sessService.deleteSessionID(se_id).subscribe((res: { msg: string, warning: string }) => {
      if (res) {
        this.toastr.success(res.msg);
        this.getSessions(this.formRef.value.C_Code);
      }
      else
        this.toastr.warning("You can't delete this Session!");
    }, err => {
      this.toastr.error("Unknown error");
    });

    this.sessService.deleteSessionIDFeeServer(se_id).subscribe((res: { msg: string, warning: string }) => {
      if (res) {
        this.toastr.success(res.msg);
      }
    });
  }
}