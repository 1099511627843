import { Component,  Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SlideInFromLeft } from 'src/app/transitions';

@Component({
  selector: 'app-auto-lock-time',
  templateUrl: './auto-lock-time.component.html',
  styleUrls: ['./auto-lock-time.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class AutoLockTimeComponent implements OnInit{

  @Output() date = new EventEmitter<string>()
  @Output() close = new EventEmitter<number>();

  dateForm:UntypedFormGroup;
  constructor(
    private FormBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.dateForm = this.FormBuilder.group({
    date: ['', Validators.required]
    })
  }
  onClose() {
    this.close.emit(-1);
  }
  onAssign() {
    this.date.emit(this.dateForm.value);
  }
}
