<form (ngSubmit)="OnSubmit()" #f="ngForm">
  <div class=" container-fluid" [@SlideInFromLeft]>
      <div class="row card shadow">
          <div class="card-header">Admitted students</div>
          <div class="row card-body pb-0  col-sm-12 ">
              <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                  <label for="C_Code">Class</label>
                  <select #c_code class="form-control " name="c_code" id="C_Code"
                      ngModel (change)="ClearClassBase()"   (change)="OnClassChange(c_code)">
                      
                      <option *ngFor="let c of classDT" value="{{c.id}}">{{c.nm}}</option>
                  </select>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                <label for="major">Major
                </label>
                
                <select class="form-control" #major (change)="OnMajorChange(major)" name="maj_id" id="major">
                  <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
                </select>
               </div>
               <div class="col-lg-4 col-md-6 form-group">
                <label for="year">Year</label>
                <input type="number" class="form-control" name="year" min="2000"  >
              </div>
              <div class="col-lg-4 col-md-6 form-group">
                <label for="base">Base</label>
                <select class="form-control" #base (change)="onBaseChange(base)" name="b_id" id="base" ngModel>
                  <!-- <option value="0">All</option> -->
                  <option *ngFor="let a of admBase" value="{{a.id}}">{{a.nm}}</option>
                </select>
              </div>

          </div>
      </div>
  </div>
</form>