<div class="card" [@SlideInFromLeft]>
  <div class="card-header">Level Wise Strength Report</div>
  <div class="card-header">
    This report will calculate the number of students in each class and provide grand totals according to study level.     
  </div>  

  <div class="card-body">
    <ul>
      <li>Part 1: Students who are in their 1st or 2nd Semester</li>
      <li>Part 2: Students who are in their 3rd or 4th Semester</li>
      <li>Part 3: Students who are in their 5th or 6th Semester</li>
      <li>Part 4: Students who are in their 7th or 8th Semester</li>
    </ul>  
  </div>

  <div class="row card-body">
    <div class="col-sm-12 col-lg-12 p-2">
      <form #f="ngForm">
        <div class="row">

          <div class="col-lg-4 col-md-6 form-group">
            <label for="lvl_id">Study Level</label>
            <select (change)="OnLevelChange()" id="lvl_id" name="lvl_id" class="form-control" ngModel>
              <option *ngFor="let each of studylevel" value="{{each.LVLID}}">{{each.DES}}</option>
            </select>
          </div>


          <div class="col-lg-4 col-md-6 form-group">
            <label for="year_in">Year</label>
            <select (change)="OnYearChange()" class="form-control " name="year_in" id="year_in" required
              ngModel>
              <option *ngFor="let each of years" value="{{each.year}}">{{each.year}}</option>
            </select>
          </div>
        </div>

        <div class="row mt-3">
          <button  class="btn btn-primary ml-3  mt-2 " (click)="onViewStrengthClick()" type="button">View Strength
          </button>                   
          <button *ngIf='this.sl.length>0' class="btn btn-danger ml-2  mt-2 " (click)="OnCreatePDF()" type="button"><i class="fa fa-file-pdf-o" for=""></i> Download PDF
          </button>  
        </div>
        <ng-container *ngIf="loader then ifBlock"></ng-container>
        <ng-template #ifBlock>
            <div style="display:flex;justify-content:center; align-items: center;"><span
                    class="loader"></span></div>
        </ng-template>
      </form>
    </div>
          
    <div *ngIf='this.sl.length>0' class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0" style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Strength</i></div>
        <div *ngFor="let each of sl; let f = index" class="card-body">
          <div  class="card-header"><i class="fa fa-line-chart">{{each[1].des}}</i></div>
          <div class="col-lg-12 col-md-6 form-group px-0 mt-1 pt-1">  
            <input class="form-control col-lg-4" type="text" name="Individual Search" placeholder="Search" id="myInput{{f+1}}" (keydown)="Filter(f+1)">
         </div>
          <table  class="table table-bordered table-striped m-0 p-0" id="table{{f+1}}">            
            <thead class="thead-dark">
              <tr>
                <th scope="col">Sr No</th>                
                <th scope="col">Major</th>
                <th scope="col">Male P1</th>
                <th scope="col">Female P1</th>
                <th scope="col">Total P1</th>
                <th scope="col">Male P2</th>
                <th scope="col">Female P2</th>
                <th scope="col">Total P2</th>
                <th scope="col">Male P3</th>
                <th scope="col">Female P3</th>
                <th scope="col">Total P3</th>
                <th scope="col">Male P4</th>
                <th scope="col">Female P4</th>
                <th scope="col">Total P4</th>
                <th scope="col">Grand Total</th>                
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of each[0]; let i =index;" id="values{{f+1}}">
                <td>{{i+1}}</td>                  
                <td>{{element.majname}}</td>
                <td>{{element.malepart1}}</td>
                <td>{{element.femalepart1}}</td>
                <td>{{element.totalpart1}}</td>
                <td>{{element.malepart2}}</td>
                <td>{{element.femalepart2}}</td>
                <td>{{element.totalpart2}}</td>
                <td>{{element.malepart3}}</td>
                <td>{{element.femalepart3}}</td>
                <td>{{element.totalpart3}}</td>
                <td>{{element.malepart4}}</td>
                <td>{{element.femalepart4}}</td>
                <td>{{element.totalpart4}}</td>
                <td>{{element.total}}</td>                
              </tr>

              <tr>
                <td></td>
                <td>{{each[1].des}} CLASS TOTAL</td>                  
                <td>{{each[1].malepart1}}</td>
                <td>{{each[1].femalepart1}}</td>
                <td>{{each[1].totalpart1}}</td>
                <td>{{each[1].malepart2}}</td>
                <td>{{each[1].femalepart2}}</td>
                <td>{{each[1].totalpart2}}</td>
                <td>{{each[1].malepart3}}</td>
                <td>{{each[1].femalepart3}}</td>
                <td>{{each[1].totalpart3}}</td>
                <td>{{each[1].malepart4}}</td>
                <td>{{each[1].femalepart4}}</td>
                <td>{{each[1].totalpart4}}</td>                
                <td>{{each[1].classtotal}}</td>                              
              </tr>
            </tbody>
          </table>
        </div>

      </div>


      <div *ngIf="showgrandtotal" class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Grand Total</i></div>
        <div class="card-body">          
          <table  class="table table-bordered table-striped m-0 p-0">            
            <thead class="thead-dark">
              <tr>
                <th scope="col"></th>
                <th scope="col">Male P1</th>
                <th scope="col">Female P1</th>
                <th scope="col">Total P1</th>
                <th scope="col">Male P2</th>
                <th scope="col">Female P2</th>
                <th scope="col">Total P2</th>
                <th scope="col">Male P3</th>
                <th scope="col">Female P3</th>
                <th scope="col">Total P3</th>
                <th scope="col">Male P4</th>
                <th scope="col">Female P4</th>
                <th scope="col">Total P4</th>
                <th scope="col">Grand Total</th>                
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Grand Total</td>                                  
                <td>{{overalltotals.gmalepart1}}</td>
                <td>{{overalltotals.gfemalepart1}}</td>
                <td>{{overalltotals.gtotalpart1}}</td>
                <td>{{overalltotals.gmalepart2}}</td>
                <td>{{overalltotals.gfemalepart2}}</td>
                <td>{{overalltotals.gtotalpart2}}</td>
                <td>{{overalltotals.gmalepart3}}</td>
                <td>{{overalltotals.gfemalepart3}}</td>
                <td>{{overalltotals.gtotalpart3}}</td>
                <td>{{overalltotals.gmalepart4}}</td>
                <td>{{overalltotals.gfemalepart4}}</td>
                <td>{{overalltotals.gtotalpart4}}</td>
                <td>{{overalltotals.gtotal}}</td>                
              </tr>              
            </tbody>
          </table>
        </div>   
      </div>
      <br>
    </div>
  </div>
</div>