import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../shared/model/SelectList.model';
import { ToastrService } from 'ngx-toastr';
import { AlterService } from './alter.service';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';
export interface IInfo {
  SE_ST_YEAR: string;
  t_no: string;
}
export interface IResult {
  SUB_CODE: string;
  GRD: string;
  GP_PER: string;
  sub_nm: string;
  SE_ID: String;
  session: string;
}
@Component({
  selector: 'app-alter-student-result',
  templateUrl: './alter-student-result.component.html',
  styleUrls: ['./alter-student-result.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class AlterStudentResultComponent implements OnInit {
  [x: string]: any;
  @ViewChild('f') formRef: NgForm
  classes: Array<SelectList>;
  info: IInfo;
  result: IResult;
  flag:boolean=true;

  constructor(
    private alter: AlterService, 
    private toastr: ToastrService,
    private lmsSharedService: LMSharedDataService) {
    this.classes = [];
    this.count = 0;
  }

  ngOnInit(): void {
    this.updateClasses();
  }
  myFunction() {
    this.flag = !this.flag;
    var popUp;
    popUp = document.getElementById("myPopup");


    if (popUp.style.display === "none") {
      popUp.style.display = "block";
    } else {
      popUp.style.display = "none";
    }
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  func() {
    this.result = null;
    this.valid = false;
    const { c_code, t_no, SE_ST_YEAR, rn } = this.formRef.value;
    this.year = SE_ST_YEAR;
    this.t_no = t_no;
    this.rn = rn;
    this.alter.GetName(c_code, SE_ST_YEAR, rn).subscribe((data: any) => {
      if (data.length != 0) {
        this.toastr.success("Data Found")
        this.valid = true;
        this.name = data[0].NM;
        this.maj_id = data[0].MAJ_ID;
        this.c_code = c_code;
        this.alter.GetStdInfo(c_code, this.maj_id, SE_ST_YEAR, t_no, rn).subscribe((data: { result: IResult }) => {
          this.result = data.result;
        })
      }
      else {
        this.toastr.error("Data Not Found");
      }
    })
  }

  Clicked(SUB_CODE, sub_nm, SE_ID) {

    if (confirm("Are you sure you want to change REMARKS to RL for " + sub_nm + " having sub_code " + SUB_CODE)) {
      this.alter.UpdateRem(this.year, this.c_code, this.maj_id, this.rn, this.t_no, SUB_CODE, SE_ID).subscribe((data: any) => {
        if (data == true)
          this.toastr.success("REMARKS Updated to RL for " + SUB_CODE);
        else
          this.toastr.error("REMARKS not UPDATED")
      })
    }
    else
      this.toastr.error("REMARKS not UPDATED")
  }

  Filter()
  {
    filter()
  }
}
