import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from './_services';
import { FadeSlideInDown } from '../transitions';
import { ToastrService,ActiveToast } from 'ngx-toastr';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
  animations: [
    FadeSlideInDown()
  ]
})
export class AuthComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  showResetForm = false;

  isButtonDisabled: boolean = false;
  countdown:number=20;
  public toastrMessage: string = '';
  private countdownTimer: any;
  private toastrRef: any;
  buttoncolor:string='rgba(95, 149, 255, 0.42)';


  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService) {

    if (this.authenticationService.isStudent()) {
      this.router.navigate(['/student']);
    }
    if (this.authenticationService.loggedIn()) {
      this.router.navigate(['/main']);
    }
  }

  ngOnInit() { 
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    
  }

  get f() {
    return this.loginForm.controls;
  }

  private clearCountdownTimer() {
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer);
    }
  }

  onSubmit() {
    this.submitted = true;

    this.isButtonDisabled = true;
    setTimeout(() => {
      this.isButtonDisabled = false;
    }, 20000)
   

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .subscribe(
        data => {
          this.loading = false;
          if (data === null || data === undefined) {
            this.countdown=20;
            this.toastr.error('Invalid Credentials', '', { timeOut: 2000, closeButton: true });
            this.toastrRef = this.toastr.warning(`Wait for Re-login ${this.countdown} seconds`,'', {
              timeOut: 20000, 
            });
            this.countdownTimer = setInterval(() => {
              if(this.countdown>0){
                this.buttoncolor= 'rgb(255, 0, 0)';
               }
              this.countdown--;
              this.toastrRef.toastRef.componentInstance.message = `Wait for Re-login ${this.countdown} seconds`;
            
              if (this.countdown === 0) {
                this.clearCountdownTimer();
                this.buttoncolor='rgba(95, 149, 255, 0.42)';
              }
            }, 1000);
            return;
          }

          else if(!data[0].warning && data){
            this.toastr.success('Logged In Successfully', '', { timeOut: 2000, closeButton: true })
          }
          else if(data[0].warning){
            this.toastr.warning(data[0].warning);
          }


          let unm: string = this.f.username.value;
          if (unm.includes('-')) {
            // this.toastr.success('Logged In Successfully', '', { timeOut: 2000, closeButton: true });
            this.router.navigate(['/student']);
            return;
          }
          else {
            // this.toastr.success('Logged In Successfully', '', { timeOut: 2000, closeButton: true });
            this.router.navigate(['/main']);
          }
        },
        error => {
          console.log(error);
          this.toastr.error(error);
          this.loading = false;
        });
  }
  onCloseResetForm() {
    this.showResetForm = false;
  }

  onShowResetForm() {
    this.showResetForm = true;
  }
}
