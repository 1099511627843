import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlSegment,
    Route,
    CanLoad
} from '@angular/router';

import { AuthenticationService } from '../_services';

@Injectable({ providedIn: 'root' })
export class StudentGuard implements CanActivate, CanLoad {
    constructor(private authenticationService: AuthenticationService,
        private router: Router) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.authenticationService.isStudent()){
            this.router.navigate(['/auth']);
        }
        return this.authenticationService.isStudent();
    }
    canLoad(route: Route, segments: UrlSegment[]):boolean {
        if (!this.authenticationService.isStudent()) {
            this.router.navigate(['/auth']);
        }
        return this.authenticationService.isStudent();
    }
}
