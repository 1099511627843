import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AddteacherComponent } from './../register/addteacher/addteacher.component';
import { SlideInFromLeft } from '../../transitions';
import { UtilService } from 'src/app/Util.service';
import { NgForm } from '@angular/forms';
import { TeacherService } from './../shared/services/Teacher.service';
import { SelectList } from '../shared/model/SelectList.model';
import { DepartmentService } from './../shared/services/Department.service';

@Component({
  selector: 'app-manage-teacher',
  templateUrl: './manage-teacher.component.html',
  styleUrls: ['./manage-teacher.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class ManageTeacherComponent implements OnInit {
  
  departments: SelectList[];
  teachers: Map<number, SelectList>;

  @Output() teacher = new EventEmitter<SelectList>();
  @Output() close = new EventEmitter<void>();
  
  @ViewChild('f') formRef: NgForm;

  constructor(private teacherService:TeacherService,
    private departmentService:DepartmentService
    ) {
    this.teachers = new Map<number, SelectList>();
    this.departments = new Array<SelectList>();
  }
  ngOnInit(): void {
    this.departmentService.getDepartments({admin:true}).subscribe((res:{D_ID:number,D_NM:string}[]) => {
      res?.forEach(entry=>{
        this.departments.push({id:entry.D_ID,nm:entry.D_NM});
      });
    });

  }

  OnChoose(){
    if (this.formRef.valid) {
      this.teacher.emit(this.teachers.get(parseInt(this.formRef.value.fm_id)));
      this.close.emit();
    }
  }

  OnClose() {
    this.close.emit();
  }


  OnNewTeacherAdded(event){
    this.teacher.emit({id: event.id, nm: event.nm});
    this.close.emit();
  }


  OnDeptChange() {
    this.loadTeachers();
  }


  loadTeachers(){
    this.teachers.clear();
    this.teacherService.getTeachers(this.formRef.value.d_id).subscribe((res:{FM_ID: number, FM_NM: string}[]) => {
     res?.forEach(entry=>{
       this.teachers.set(entry.FM_ID, {id: entry.FM_ID, nm: entry.FM_NM});
     })
    });
  }

}