<form #f="ngForm">
    <div [@SlideInFromLeft]>
      <div class="container-fluid card shadow">
        <div class="card-header"  style="padding-top: 10px;">
          <div class=" col-lg-6 col-md-6 form-group">
            Single Student Enrollment</div>
             <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
            <app-details-button [details]="details"></app-details-button>
        </div>
      </div> 
        <div class="row card-body" style="padding-bottom: 0px;">
    
          <div class="col-lg-4 col-md-6 form-group">
            <label for="C_Code">Class</label>
            <select #cource (change)="OnClassChange(cource)" class="form-control " name="c_code" id="C_Code" ngModel required>
              <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
            </select>
          </div>
          <div class="col-lg-4 col-md-6 form-group">
            <label for="Major">Major</label>
            <select class="form-control" #major name="maj_id" id="Major" ngModel required>
              <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
            </select>
          </div>
  
          <div class="col-lg-4 col-md-6 form-group">
            <label for="class" class="text-left">Year</label>
            <input class="form-control" name="year_in" id="year_endTime" #input ngModel required>
          </div>
  
  
          <div class="col-lg-4 col-md-6 form-group">
            <label for="t_no">Semester</label>
            <input type="number" class="form-control" #Rolno (change)="Get_Rolno()" name="t_no" id="t_no" min="0" max="8" ngModel required>
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="t_no">Roll Number</label>
            <select class="form-control"  name="rolno" id="rolno" (change)="onRollNumberChange()" ngModel required>
              <option *ngFor="let r of rolno" value="{{r}}">{{r}}</option>
            </select>
          </div>
  
        </div>

        <ng-container *ngIf="loader then ifBlock;"></ng-container>
        <ng-template #ifBlock>
            <div style="display:flex;justify-content:center; align-items: center;"><span
                    class="loader"></span></div>
        </ng-template>
  
        <div class="card-header">
          Actions
        </div>
        <div class="row card-body">
          
            <button (click)="OnSingleEnroll()" class="btn btn-outline-primary" type="button" [disabled]="!f.valid">
              New Enrollment
            </button>

            <button (click)="OnCheckEnroll()" class="btn btn-outline-info ml-1" type="button" [disabled]="!f.valid">
              Check Enrollment
            </button>

            <button (click)="OnDeleteEnroll()" class="btn btn-outline-danger ml-1" type="button" [disabled]="!f.valid">
              Delete Enrollment
            </button>
  
            <button class="btn btn-sm btn-outline-primary ml-1" type="button" (click)="ClearForm(f)">Clear Form</button>     

        </div>

  

        <div *ngIf="this.subjects?.length>0" class="card-header">
          Subject Enrollment Detail
        </div>


          <div *ngIf="this.subjects?.length>0" class="row card-body ">
            <table class="mb-0 table table-hover table-bordered">
                <thead class="table-active">
                    <th>Sr.No</th>
                    <th>Subject Name</th>
                    <th>Subject Code</th>
                    <th>Section</th>
                </thead>
                <tbody>
                    <tr *ngFor="let s of subjects; let i=index;">
                        <td>{{i+1}}</td>
                        <td>{{s.sub_nm}} </td>
                        <td> {{s.sub_code}} </td>
                        <td> {{s.section||null}} </td>
                    </tr>
                </tbody>
            </table>



        </div>
      </div>
    </div>
  </form>  