import { Component, OnInit, ViewChild, asNativeElements } from '@angular/core';
import { TeacherService } from '../shared/services/Teacher.service';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
import autoTable from 'jspdf-autotable';
import { NgForm } from '@angular/forms';
import { filter } from 'src/app/shared/functions/tableSearch';

declare let $:any;
@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class UserDetailComponent implements OnInit {
  
userdetail:any[]=[];
studentdetail:any[]=[];
year:any[]=[];
showyear:boolean=false;
@ViewChild('f') formRef: NgForm;
details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  this screen is used to lock,unlock and delete the lms account of faculty and students </h6>
  <h6 class="text-right">یہ اسکرین فیکلٹی اور طلباء کے ایل ایم ایس اکاؤنٹ کو لاک، ان لاک اور ڈیلیٹ کرنے کے لئے استعمال ہوتی ہے۔
</h6>
  `  ;

  constructor(private teacherservice :TeacherService,
    private toastr: ToastrService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.loader=false;
    this.getuserdata();
    this.getadmyear();
  }
  loader=false;

  getyearandbuttton(){
    this.showyear=true;
    this.studentdetail=[];
    this.userdetail=[];
    this.tempuserdetail=[];
    this.tempstudentdetail=[];
    

  }
  getadmyear(){
    // this.year=[];
    this.teacherservice.getadmyear().subscribe((res:any)=>
    {
      this.year=res;
    })
    }
  
  getuserdata(){
    this.showyear=false;
    this.studentdetail=[];
    this.userdetail=[];
    this.loader=true;
    this.toastr.success("Wait for a while");
    this.teacherservice.getuserdetail().subscribe((res:any)=>
    { 
      this.loader=false;
       this.userdetail=res;
       this.tempuserdetail=res;
       if(this.userdetail.length<=0){
        this.toastr.warning("No any data");
       }
      else{
       this.toastr.success("All Faculty Data");
      }
    })
  }
  getstudentdetail(selectedYear: number){
    this.formRef.value.year=selectedYear;
    
    this.studentdetail=[];
    this.userdetail=[];
    this.loader=true;
    this.toastr.success("Wait for a while ");
    // console.log(this.formRef.value.year);
    this.teacherservice.getstudentdetail(this.formRef.value).subscribe((res:any)=>
    { 
     
      this.loader=false;
       this.studentdetail=res;
       this.tempstudentdetail=res;
      //  console.log(this.studentdetail);
      //  console.log(this.tempstudentdetail);
       if(this.studentdetail.length<=0){
        this.toastr.warning("No any student");
       }
      else{
       this.toastr.success("All Student Data");
      }
    })
  }

  clearstduser(){
    this.studentdetail=[];
    this.userdetail=[];
    this.tempuserdetail=[];
    this.tempstudentdetail=[];
  }

  ondeleteuser(obj:any){
  if(confirm ("Are you sure you wanted to delete it ??? Note :It will be deleted permanantly from LMS ")){
    this.teacherservice.deleteuser(obj).subscribe (res=>{
      this.userdetail = this.userdetail.filter(u => u.user_id !== obj.user_id);
      this.tempuserdetail=this.tempuserdetail.filter(u =>u.user_id!==obj.user_id);
      this.toastr.success("User has been deleted from fm and fm_login successfully");
    })
  }
  }

  ondeletestudent(obj:any,index:number){
    this.teacherservice.deletestudent(obj).subscribe (res=>{
      // this.studentdetail = this.studentdetail.filter(s => s.user_id !== obj.user_id);
      this.toastr.success("student login  has been deleted from student_login and loc updated in adm_stdt successfully");
      this.studentdetail[index].loc=res[0].loc;
      this.tempstudentdetail[index].loc=res[0].loc;
    })
  }

  onlocuser(obj:any,index: number){
    this.teacherservice.userloc(obj).subscribe(res=>{
      this.toastr.success("user has been locked in fm ");
      this.userdetail[index].loc=res[0].loc;
      this.tempuserdetail[index].loc=res[0].loc;
    })
  }

  onunlocuser(obj:any,index:number){
this.teacherservice.userunloc(obj).subscribe(res=>{
  this.toastr.success("user has been unlocked from fm");
  this.userdetail[index].loc=res[0].loc;
  this.tempuserdetail[index].loc=res[0].loc;
})
 }

 onlocstudent(obj:any,index: number){
  this.teacherservice.studentloc(obj).subscribe(res=>{
    this.toastr.success("student has been locked in adm_stdt ");
    this.studentdetail[index].loc=res[0].loc;
    this.tempstudentdetail[index].loc=res[0].loc;
  })
}

onunlocstudent(obj:any,index:number){
  this.teacherservice.studentunloc(obj).subscribe(res=>{
    this.toastr.success("student has been unlocked from adm_stdt");
    this.studentdetail[index].loc=res[0].loc;
    this.tempstudentdetail[index].loc=res[0].loc;

  })
  }

  downloadfacultypdf(){
    
    const body = [];
    const header = [];
    let k = 0;

    for(let i=0;i<this.userdetail.length;i++){
      k++;
     
      body.push([
        k,
        this.userdetail[i].user_id,
        this.userdetail[i].name.toUpperCase(),
        this.userdetail[i].designation.toUpperCase(),
        this.userdetail[i].contact,
         this.userdetail[i].email
      ]);
    }

    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y,h:mm a')
    var image = new Image();
    var page;
    image.src='../../../assets/images/logo3.png';


    header.push(['Sr #','User_Id','Name','Designation','Contact #','Email']);
    const doc = new jsPDF();


    autoTable(doc, {
      margin: { top: 32, bottom: 10 },
      styles: { fontSize: 9,cellPadding:1},theme: 'grid',
      head: header,
      body: body,
      didDrawPage: function () {
        doc.addImage(image, 177, 2, 25, 25);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 172, 30);
        doc.setFontSize(20);
        doc.setFont('Arial');
        doc.text("GC UNIVERSITY LAHORE", 100, 20, { align: 'center' });
        doc.setFontSize(13);
        doc.text("FACULTY LOGIN DETAILS" ,100,25, { align: 'center' });
        doc.setFontSize(10);
        page = doc.getNumberOfPages();
        doc.text("Note:- Errors and Omissions Excepted", 142, 292);
        doc.text("Directorate of Information Technology", 13, 292);
      }
    });

    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 90, 292);
    }
    doc.setProperties({
      title: `Faculty List`,
      
    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');

  }



  downloadstudentpdf(){
    
    const body = [];
    const header = [];
    let k = 0;

    for(let i=0;i<this.studentdetail.length;i++){
      k++;
     
      body.push([
        k,
        this.studentdetail[i].year,
        this.studentdetail[i].user_id,
        this.studentdetail[i].class.toUpperCase(),
        this.studentdetail[i].name.toUpperCase(),
        this.studentdetail[i].guardian.toUpperCase(),
        this.studentdetail[i].contact
      ]);
    }

    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y,h:mm a')
    var image = new Image();
    var page;
    image.src='../../../assets/images/logo3.png';


    header.push(['Sr','Year','User_Id','Class','Name','Guardian','Contact #']);
    const doc = new jsPDF();


    autoTable(doc, {
      margin: { top: 32, bottom: 10 },
      styles: { fontSize: 9,cellPadding:1},theme: 'grid',
      head: header,
      body: body,
      didDrawPage: function () {
        doc.addImage(image, 177, 2, 25, 25);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 172, 30);
        doc.setFontSize(20);
        doc.setFont('Arial');
        doc.text("GC UNIVERSITY LAHORE", 100, 20, { align: 'center' });
        doc.setFontSize(13);
        doc.text("STUDENT LOGIN DETAILS" ,100,25, { align: 'center' });
        doc.setFontSize(10);
        page = doc.getNumberOfPages();
        doc.text("Note:- Errors and Omissions Excepted", 142, 292);
        doc.text("Directorate of Information Technology", 13, 292);
      }
    });

    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 90, 292);
    }
    doc.setProperties({
      title: `Student List`,
      
    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');

  }

// for searching
tempuserdetail = [...this.userdetail];
@ViewChild('user_idvar') user_idvar1: any = '';
@ViewChild('namevar') namevar1: any = '';
@ViewChild('designationvar') designationvar1: any = '';
@ViewChild('contact_novar') contact_novar1: any = '';
@ViewChild('emailvar') emailvar1: any = '';
@ViewChild('last_loginvar') last_loginvar1: any = '';

tempstudentdetail=[...this.studentdetail];
@ViewChild('yearvar') yearvar2: any = '';
@ViewChild('user_idvarr') user_idvar2: any = '';
@ViewChild('classvar') classvar2: any = '';
@ViewChild('namevar') namevar2: any = '';
@ViewChild('guardianvar') guardianvar2: any = '';
@ViewChild('contactvar') contactvar2: any = '';

searchFilter() {
  this.tempuserdetail = [];
  let filterobject: any = {
    user_id: this.user_idvar1.nativeElement.value,
    name: this.namevar1.nativeElement.value,
    designation: this.designationvar1.nativeElement.value,
    contact: this.contact_novar1.nativeElement.value,
    email: this.emailvar1.nativeElement.value,
    last_login: this.last_loginvar1.nativeElement.value,
  };
  // console.log(filterobject);
  for (let i = 0; i < this.userdetail.length; i++) {
    let isStringMatch = true;
    for (let key in filterobject) {
      if (filterobject[key].trim() !== '') {
        if (key == 'user_id') {
          // Check if the property is not null before using indexOf
          isStringMatch =
            this.userdetail[i][key] &&
            this.userdetail[i][key].toString().indexOf(filterobject[key]) > -1;
        } else {
          isStringMatch =
            this.userdetail[i][key] &&
            this.userdetail[i][key]
              .toUpperCase()
              .indexOf(filterobject[key].toUpperCase()) > -1;
        }
        if (!isStringMatch) {
          break;
        }
      }
    }
    if (isStringMatch) {
      this.tempuserdetail.push(this.userdetail[i]);
    }
  }
}

searchFilter2() {
  this.tempstudentdetail = [];
  let filterobject: any = {
    year: this.yearvar2.nativeElement.value,
    user_id: this.user_idvar2.nativeElement.value,
    class: this.classvar2.nativeElement.value,
    name: this.namevar2.nativeElement.value,
    guardian: this.guardianvar2.nativeElement.value,
    contact: this.contactvar2.nativeElement.value,
  };
  // console.log(filterobject);
  for (let i = 0; i < this.studentdetail.length; i++) {
    let isStringMatch = true;
    for (let key in filterobject) {
      if (filterobject[key].trim() !== '') {
        if (key == 'user_id') {
          // Check if the property is not null before using indexOf
          isStringMatch =
            this.studentdetail[i][key] &&
            this.studentdetail[i][key].toString().indexOf(filterobject[key]) > -1;
        } else {
          isStringMatch =
            this.studentdetail[i][key] &&
            this.studentdetail[i][key]
              .toUpperCase()
              .indexOf(filterobject[key].toUpperCase()) > -1;
        }
        if (!isStringMatch) {
          break;
        }
      }
    }
    if (isStringMatch) {
      this.tempstudentdetail.push(this.studentdetail[i]);
    }
  }
}

/*
searchFilter() {
  this.tempuserdetail = [];
  let filterobject: any = {
    user_id: this.user_idvar1.nativeElement.value,
    name: this.namevar1.nativeElement.value,
    designation: this.designationvar1.nativeElement.value,
    contact: this.contact_novar1.nativeElement.value,
    email: this.emailvar1.nativeElement.value,
    last_login: this.last_loginvar1.nativeElement.value,
  };
  for (let i = 0; i < this.userdetail.length; i++) {
    let isStringMatch = true;
    for (let key in filterobject) {
      if (key == 'user_id') {
        // Check if the property is not null before using indexOf
        isStringMatch =
          this.userdetail[i][key] &&
          this.userdetail[i][key].toString().indexOf(filterobject[key]) > -1;
      } else {
        isStringMatch =
          this.userdetail[i][key] &&
          this.userdetail[i][key]
            .toUpperCase()
            .indexOf(filterobject[key].toUpperCase()) > -1;
      }
      if (!isStringMatch) {
        break;
      }
    }
    if (isStringMatch) {
      this.tempuserdetail.push(this.userdetail[i]);
    }
    
  }
  
}*/



  Filter()
  {
    filter()
  }
}
