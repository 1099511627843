import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { AttendanceService } from '../attendance/attendance-services/attendance.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { Team, TimetableService } from '../shared/services/Timetable.service';
import { jsPDF } from "jspdf";
import autoTable, { UserOptions } from 'jspdf-autotable'
import 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { filter } from 'src/app/shared/functions/tableSearch';
@Component({
  selector: 'app-view-student-profile',
  templateUrl: './view-student-profile.component.html',
  styleUrls: ['./view-student-profile.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class ViewStudentProfileComponent implements OnInit {
  @ViewChild('c') selectClass: ElementRef;
  @ViewChild('course') selectCourse: ElementRef;
  students;
  loading = false;
  classes: { c_code: string, c_nm: string }[];
  classes2: { c_code: string, c_nm: string }[];
  teams: Team[];
  teams2: Team[];
  btnHide: boolean;
  tm: Team;
  results;
  private session:any;

  constructor(private timetableService: TimetableService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private attendanceSerivce: AttendanceService,
    private datePipe: DatePipe) {
      
   // this.classes = new Array<{ c_code: string, c_nm }>();
    this.classes2 = new Array<{ c_code: string, c_nm }>();
    this.teams = new Array<Team>();
    this.teams2 = new Array<Team>();
  }

  ngOnInit(): void {
    this.students = [];
    this.classes= this.timetableService.getWorkingClasses();

    if (this.classes.length > 0) {
      this.loadTeams(parseInt(this.classes[0].c_code));
    }
  }


  loadTeams(c_code: number) {
    this.teams =this.timetableService.getWorkingTeams(c_code);
    
    if (this.teams?.length) {
      this.tm = this.teams[0];
    } else {
      this.tm = null;
    }
  }

  OnClassChange(c: HTMLSelectElement) {
    this.students = [];
    this.loadTeams(parseInt(c.value));
  }

  OnCourseChange(index: HTMLSelectElement) {
    this.tm = this.teams[parseInt(index.value)];
    this.students = [];
  }

  OnSubmit() {
    this.btnHide = true;
    if (!this.tm) return;
    this.loading = true;
    this.students = [];
    this.attendanceSerivce.getCompleteSession({ se_id: this.tm.SE_ID }).subscribe((res) => {
      this.session = res[0].sess;
    });
    this.attendanceSerivce.getAttendancePercentage(this.tm).subscribe(
      (student: any[]) => {
        student?.forEach(element => {
          this.students.push(element);
        });
        this.loading = false;
        return;
      },
      err => {
        this.toastr.error("Unknown Error");
        this.loading = false;
      }
    );
  }
   
  Filter()
  {
    filter();
  }

  OnCreatePDF() {
    const body = [];
    const header = [];
    let k = 0;
    for (let i = 0; i < this.students.length; i++) {
      k++;
      body.push([k,
        this.students[i].ROLNO.toUpperCase(),
        this.students[i].NM.toUpperCase(),
        this.students[i].DELIVERED,
        this.students[i].PRESENT,
        this.students[i].ABSENT,
        this.students[i].LEAV,
        (((this.students[i].PRESENT + this.students[i].LEAV) / this.students[i].DELIVERED) * 100 || 0).toFixed(0) + '%',
        ((((this.students[i].PRESENT + this.students[i].LEAV) / this.students[i].DELIVERED) * 100)>=80)?"Eligible":"Ineligible",

      ]);
    }
    let exportDate = this.datePipe.transform(new Date(), 'MMM d,y, h:mm a');
    let image = new Image();
    let page;
    let cls = this.tm.C_NM;
    let sub_nm = this.tm.SUB_NM;
    let sub = this.tm.SUB_CODE;
    let to = this.tm.T_NO;
    let sec = this.tm.SECTION;
    let TeacherName = this.authenticationService.getUser().NM;
    let sess = this.session;
    image.src = '../../../assets/images/logo3.png';
    header.push(['Sr.', 'Roll No.', 'Name', 'Delivered', 'Present', 'Absent', 'Leaves', 'Percentage','Status']);
    const doc = new jsPDF();
    autoTable(doc, {
      margin: { top: 43, bottom: 20 },
      styles: { fontSize: 9},
      head: header,
      body: body,
      didDrawPage: function () {
        doc.addImage(image, 177, 2, 25, 25);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 172, 30);
        doc.setFontSize(25);
        doc.setFont('Arial');
        doc.text("GC UNIVERSITY LAHORE", 100, 10, { align: 'center' });
        doc.setFontSize(17);
        doc.text("CLASS: " + cls?.toUpperCase(), 100, 20, { align: 'center' });
        doc.text("SESSION: " + sess?.toUpperCase() + ",   " + "TERM: " + to + ",    " + "SEC: " + sec, 100, 30, { align: 'center' });
        doc.text("SUBJECT: " + sub_nm?.toUpperCase() + " (" + sub?.toUpperCase() + ")", 100, 40, { align: 'center' });
        doc.setFontSize(10);
        page = doc.getNumberOfPages();
        doc.text("Note:- Errors and Omissions Excepted", 145, 288);
        doc.text("Directorate of Information Technology", 8, 288);
        doc.text("GENERATED BY: " + TeacherName?.toUpperCase(), 8, 283);
      }
    });
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 90, 288);
    }
    doc.save("Attendance " + sub);
  }
}
