import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';

@Injectable({
    providedIn: 'root'
})

export class SessionService {

    constructor(private http: HttpClient) {
    }

    getNewSe_id() {
        return this.http.get(`${baseUrl}/api/session/getNewSe_id`);
    }
    getAllSessions() {
        return this.http.get(`${baseUrl}/api/session/getAllSessions`);
    }
    insertSession(params) {
        return this.http.post(`${baseUrl}/api/session/insertSession`, params);
    }
    insertSessionFeeServer(params) {
        return this.http.post(`${baseUrl}/api/session/insertSessionFeeServer`, params);
    }
    getSessions(c_code: string) {
        return this.http.get(`${baseUrl}/api/session/getsessions/${c_code}`);
    }
    getSessionsAll(c_code: string) {
        return this.http.get(`${baseUrl}/api/session/getsessionsAll/${c_code}`);
    }
    getSessionsAndDeptt(c_code: number, t_no: number, se_id: number, sub_code: string, section: string) {
        return this.http.get(`${baseUrl}/api/session/getSessionsAndDeptt/${c_code}/${t_no}/${se_id}/${sub_code}/${section}`);
    }
    deleteSessionID(se_id:number){
        return this.http.get(`${baseUrl}/api/session/deleteSessionID/${se_id}`);
    }
    deleteSessionIDFeeServer(se_id:number)
    {
        return this.http.get(`${baseUrl}/api/session/deleteSessionIDFeeServer/${se_id}`);   
    }

    getSessionForStYear(params) {
        return this.http.post(`${baseUrl}/api/session/getSessionForStYear`,params);
    }
    
    EXAM_getSessionID(c_code:number,se_st_year:number){
        return this.http.get(`${baseUrl}/api/session/EXAM_getSessionID/${c_code}/${se_st_year}`);
    }


    //
    getSessionsfromTm(cls) {
        return this.http.get(`${baseUrl}/api/session/getSessionsFromTm/${cls}`);
      }

      get_Sessions() {
        return this.http.post(`${baseUrl}/api/session/Get_Sessions_For_Dashboard`, {});
      }
    
}