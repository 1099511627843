import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from '../shared/services/Class.service';
import { MajorService } from '../shared/services/Major.service';
import { EnrollmentService } from '../shared/services/Enrollment.service';
import { SubjectOfferService } from '../shared/services/SubjectOffer.service';

@Component({
  selector: 'app-single-subject-enrollment',
  templateUrl: './single-subject-enrollment.component.html',
  styleUrls: ['./single-subject-enrollment.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class SingleSubjectEnrollmentComponent implements OnInit {
  departments: Array<SelectList>;
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  subjects: Array<{ sub_code: string, sub_nm: string }>;
  rolno:Array<string>;
  @ViewChild('f') formRef: NgForm;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>You can enroll the studnets in a single subject of any major of 
  different year and semester in a class and you can 
delete the enrollment of students in a single subject as well</h6>
  <h6 class="text-right">  
آپ کسی بھی میجر کے مختلف سال اور سمسٹر کے طلباء کو ایک کلاس میں ایک سنگل سبجیکٹ میں انرول
 کر سکتے ہیں اور آپ سبجیکٹ میں طلباء کی انرولمنٹ کو ڈیلیٹ بھی کر سکتے ہیں
</h6>
  `
  ;

  constructor(
    private toastr: ToastrService,
    private majorService:MajorService,
    private classService:ClassService,
    private enrollmentService:EnrollmentService,
    private subOfferService: SubjectOfferService,
  ) { 
    this.classes = [];
    this.majors = [];
    this.sessions = [];
  }

  loader=false;
  
  ngOnInit(): void {   
    this.subinsetoffer=[]
    this.studentcountinset=[]
    this.loader=false;
    this.classService.getClasses({}).subscribe((res:{C_CODE: number, C_NM: string}[]) => {
      res?.forEach(entry=>{
        this.classes.push({id: entry.C_CODE, nm: entry.C_NM});
      });
    });
  }
  OnYearChange(){
    this.subjects=[]
    this.subinsetoffer=[]
    this.studentcountinset=[]        
    this.formRef?.controls['t_no'].reset();
    this.formRef?.controls['sub_code'].reset();
  }
  OnMajorChange(){
    this.subjects=[]
    this.subinsetoffer=[]
    this.studentcountinset=[]
    this.subinsetoffer=[]
    this.studentcountinset=[]
    this.missingset=[]
    this.subofferset=[]
    this.formRef?.controls['year_in'].reset();
    this.formRef?.controls['t_no'].reset();
    this.formRef?.controls['sub_code'].reset();
  }

  updateMajors() {
    this.majors = [];
    this.sessions = [];
    this.subjects= [];
    this.majorService.getMajor({c_code: this.formRef.value.c_code}).subscribe((res:{MAJ_ID:number,MAJ_NM:string}[]) => {
      res?.forEach(entry=>{
        this.majors.push({id: entry.MAJ_ID, nm: entry.MAJ_NM});
      });
    });
  }

  OnSingleEnroll(){
    this.toastr.clear()
    if(this.formRef.valid)
    {
      this.loader=true;
      // if (this.missingset.length!=0)
      // {
      //   this.toastr.warning("Not Enrolled")
      //   return
      // }
      
      this.toastr.info("Enrolling Please Wait...",null,{timeOut:120000})

      this.enrollmentService.enrollSingleSubject(this.formRef.value).subscribe((res:{msg:string}) => {
        this.loader=false;
        this.toastr.clear();
        if(!res){
          this.toastr.warning('Already Enrolled');
        }
        else{          
          this.toastr.success(res[0][0].msg,null,{timeOut:10000});
        }
      },err=>{
        this.loader=false;
        console.log(err);
      });


    }
    else{
      this.toastr.warning("Some Fields are Missing!");
    }
  }
  SAO_OnDeleteSingleSubjectEnroll(){
    this.loader=true;
    this.toastr.clear()
    this.toastr.info("Deleting Please Wait...",null,{timeOut:120000})
    if(this.formRef.valid){
      this.enrollmentService.SAO_OnDeleteSingleSubjectEnroll(this.formRef.value).subscribe((res:{affectedRows:number}) => {
        this.loader=false;
        this.toastr.clear();
        if(res?.affectedRows==0){
          this.toastr.error('Enrollment is not Found.');
          return;
        }
        else{
          this.toastr.success(`Deleted ${res?.affectedRows} Students Successfully.`);
        }
      },err=>{
        this.loader=false;
        console.log(err);
      });
    }
    else{
      this.toastr.warning("Some Fields are Missing!");
    }
  }

  updateSubjects() {
    this.formRef?.controls['sub_code'].reset();
    this.subinsetoffer=[]
    this.studentcountinset=[]
    this.subjects = [];
    if (this.formRef.value.c_code === "" || this.formRef.value.year === "" ||
    this.formRef.value.maj_id === "" || this.formRef.value.t_no === "") return;

    this.subOfferService.getSubjectOfferForSingleSubject('-1', this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.year_in, this.formRef.value.t_no).subscribe((res: { SUB_CODE: string, SUB_TITLE_PRINT: string }[]) => {
      res?.forEach(entry => {
        this.subjects.push({ sub_code: entry.SUB_CODE, sub_nm: entry.SUB_TITLE_PRINT});
      });
    });
  }
 
  ClearForm(fn: NgForm) {
    this.subjects=[]
    this.subinsetoffer=[]
    this.studentcountinset=[]
    this.subinsetoffer=[]
    this.studentcountinset=[]
    this.majors=[];
    this.rolno=[];
    fn.resetForm();
  }

  
  OnClassChange(c: HTMLSelectElement) {
    this.formRef?.controls['maj_id'].reset();
    this.formRef?.controls['year_in'].reset();
    this.formRef?.controls['t_no'].reset();
    this.formRef?.controls['sub_code'].reset();
    this.subinsetoffer=[]
    this.studentcountinset=[]
    this.updateMajors();
  }
  totalstudents:number
  subinsetoffer:[]
  studentcountinset:[]  
  subofferset:any
  missingset:any    
  OnSubjectChange()
  {
    this.subinsetoffer=[]
    this.studentcountinset=[]
    this.subinsetoffer=[]
    this.studentcountinset=[]
    this.missingset=[]
    this.subofferset=[]
    var studentset=[]
    
    
    this.toastr.clear()
    const {c_code,maj_id,year_in,t_no,sub_code}=this.formRef.value;    
    this.enrollmentService.checksubjectsetinfo(c_code,maj_id, year_in, t_no,sub_code).subscribe((res) => {      
        this.totalstudents=res[4][0].total; //totalsudents
        this.subinsetoffer=res[1]  // sub offered against which sets
        this.studentcountinset=res[3] //students in each set from adm
        res[0].forEach(element => {
          studentset.push(element.setinadm)        
        });
        
        res[1].forEach(element => {
          this.subofferset.push(element.setinsuboffer)          
        });      
        if (res[2].length!=0)      
        {        
          this.missingset=res[2]
          
          this.toastr.clear()
          this.toastr.info(`The students have set ${studentset}. ${sub_code} is offered against set ${this.subofferset}.`,null,{timeOut:10000})
          /*removed from above toastr*/ 
          /* Offer subjects against set ${res[2][0].missing} then enroll. */
          
          
          
          /*        res[2].forEach(element => {                    
            this.toastr.warning(`Please Offer Subjects Against Set # ${element.missing} before Enrollment`,null,{timeOut: 10000}); 
          });        
          */
        }
        else
        {
          this.toastr.clear()
          this.toastr.info(`The students have set ${studentset}. ${sub_code} is offered against set ${this.subofferset}.`,null,{timeOut: 10000}); 
        }
      
      });    
  }
}
