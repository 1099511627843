import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { SlideInFromLeft } from "src/app/transitions";
import { AssignRollNoService } from "../shared/services/AssignRollNo.service";
import { SelectList } from "../shared/model/SelectList.model";
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { FeeSharedDataService } from "src/app/shared/FeeSharedService";
import { FeeService } from "../shared/services/Fee.service";
import { Subscription } from "rxjs";

declare let $: any

export interface IMajor {
  MAJ_ID: number;
  C_CODE: number;
  TITLE: string;
  D_ID: number;
  GEN_MAJ: string;
  D_NM: string;
}

export interface IMeritListStudents {
  sr_no: string;
  m_list: string;
  b_id: string;
  f_no: string;
  Nm: string;
  f_nm: string;
  c_code: string;
  paid: number;
  status: number;
  b_nm: string;
  rn: string;
  rolno: string;
  reg_no: string,
  email: string,
  ph1: string,
  add1: string,
  maj_chng_needed: number,
  LVLID: number,
  checked: number
}

export interface IPersonalInfo {
  year: string;
  c_code: string;
  f_no: string;
  g_code: string;
}

@Component({
  selector: "app-assign-roll-merit",
  templateUrl: "./assign-roll-merit.component.html",
  styleUrls: ["./assign-roll-merit.component.css"],
  animations: [SlideInFromLeft()],
})

export class AssignRollMeritComponent implements OnInit {
  [x: string]: any;
  subscription = new Subscription();
  @ViewChild("f") formRef: NgForm;
  classes: Array<SelectList>;
  major: Array<IMajor>;
  Imajor: IMajor;
  majors: Array<SelectList>;
  meritListStd: IMeritListStudents;
  meritListStdArr: Array<IMeritListStudents>;
  meritListCount: Array<number>;
  rolnoArr: Array<{ f_no: string, rn: string, rollno: string, reg_no: string, m_list: string }>
  existedRn: Array<string>
  assignedRn: Array<number>
  formnoString: string;
  formNoArr: Array<number>
  preAssigned: Array<{ f_no: string, rn: string, rolno: string, status: number, reg_no: string }>
  TempStoreRn: Array<{ rn: number, rolno: string, f_no: number }>
  personalInfo: Array<any>;
  preAssignedRnForms: string;
  unAssignedForms: Array<{ m_list: string, count: number }>
  loader: boolean;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>This screen is used to assign roll number and registration number to students.
  And it also shows the status of student class change and student major change</h6>
  <h6 class="text-right">یہ اسکرین طلباء کو رول نمبر اور رجسٹریشن نمبر مختص کرنے کے لیے استعمال ہوتی ہے۔ 
  اس میں طالب علم کی کلاس اور اس کے اصل میجر میں تبدیلی کی وضع دکھائی جاتی ہے۔
</h6>
  `
  ;

  constructor(
    private assign: AssignRollNoService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private feeSharedService: FeeSharedDataService,
    private feeService: FeeService
  ) {
    this.classes = [];
    this.major = [];
    this.meritListStdArr = [];
    this.meritListCount = [];
    this.rolnoArr = [];
    this.formNoArr = [];
    this.personalInfo = [];
    this.existedRn = [];
    this.assignedRn = []
    this.majors = [];
    this.preAssigned = [];
    this.load = true;
    this.unAssignedForms = [];
    this.TempStoreRn = []
    this.loader = false;
  }

  ngOnInit(): void {
    this.updateClasses();
    this.updateSessions();
    this.m_list = 1;
  }

  updateClasses() {
    this.feeSharedService.getFeeClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateMajors() {
    this.majors.length = 0;
    this.feeSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  updateSessions() {
    this.sessions = []
    this.feeSharedService.getFeeSessions()
      .then(sessions => {
        this.sessions = sessions;
      })
      .catch(error => {
        console.error("Error getting fee session", error);
      });
  }

  initialize() {
    this.meritListStdArr = [];
    this.meritListCount = [];
    this.rolnoArr = [];
    this.existedRn = [];
    this.formNoArr = [];
    this.assignedRn = [];
    this.preAssigned = [];
    this.preAssignedRnForms = ''
    this.personalInfo = [];
    this.loader = false;
    this.load = true;
    this.countUnAssigned = 0;
    this.unAssignedForms = [];
    this.TempStoreRn = []
    this.m_list = 1;
  }

  ShowResult() {
    this.initialize();
    this.loader = true;
    const { c_code, maj_id, year } = this.formRef.value;
    this.c_code_in = c_code;
    this.maj_id_in = maj_id;
    this.year_in = year;
    if (this.formRef.valid) {
      this.subscription.add( this.assign.getMeritListCount(c_code, maj_id, year).subscribe((data: { info, length }) => {
        this.initialize();
        if (data.length === 0) {
          this.toastr.error("No Data of Merit List Exists");
        } else {
          for (let i = 0; i < data.length; i++) {
            this.meritListCount.push(data.info[i]);
            this.unAssignedForms.push({ m_list: data.info[i].m_list, count: 0 })
          }
        }
      }));

      this.subscription.add( this.assign.GetMeritListStudents(c_code, maj_id, year).subscribe((data: { meritListStd: IMeritListStudents; length }) => {
        for (let i = 0; i < data.length; i++) {
          this.meritListStdArr.push(data.meritListStd[i]);
        }
        this.sort();
        this.UpdateArrayForInputField();//VERY IMPORTANT
        this.UnAssignedForms()
        this.HighestRn();
        this.loader = false;
        this.load = false;
      }));
    } else {
      this.toastr.warning("Select Correct Fields");
      this.loader = false
      this.load = false;
    }
  }

  UpdateArrayForInputField() {
    this.existedRn = []
    this.preAssigned = [];
    this.assignedRn = [];
    this.TempStoreRn = []
    setTimeout(() => {
      this.meritListStdArr.forEach(element => {
        if (element.status == 1 && element.paid == 1) {
          this.assignedRn.push(parseInt(element.f_no));
          this.preAssigned.push({ f_no: element.f_no, rn: element.rn, rolno: element.rolno, status: element.status, reg_no: element.reg_no })
        }
      })
      this.rolnoArr.forEach(element => {
        $('#' + element.f_no).val(element.rn)
        $('#' + element.f_no + 's').val(element.rollno)
        $('#' + element.f_no + 'r').val(element.reg_no)
        this.TempStoreRn.push({ rn: parseInt(element.rn), rolno: element.rollno, f_no: parseInt(element.f_no) })
      })
    }, 1)
  }

  UnAssignedForms() {
    this.unAssignedForms.forEach(element => {
      element.count = 0;
    });
    this.meritListStdArr.forEach(element => {
      if (element.status == 0 && element.paid == 1) {
        for (let i = 0; i < this.unAssignedForms.length; i++) {
          if (element.m_list == this.unAssignedForms[i].m_list) {
            this.unAssignedForms[i].count = this.unAssignedForms[i].count + 1;
            break;
          }
        }
      }
    })
  }

  HighestRn() {
    this.subscription.add( this.assign.CheckHighestRn(this.c_code_in, this.maj_id_in, this.year_in).subscribe((data: any) => {
      this.highestRn = data[0].rn;
    }))
  }

  AssignFullRoll(f_no_in: string, m_list_in: string, rol: string) {
    const { c_code, maj_id, year } = this.formRef.value;
    if (rol != '') {
      this.subscription.add(this.assign.CheckExistingRn(this.year_in, this.c_code_in, this.maj_id_in, rol).subscribe((data: any) => {
        if (data[0].status == 0) {//Check if roll no does not exist in database
          this.subscription.add(this.assign.AssignFullRoll(this.c_code_in, this.maj_id_in, this.year_in, rol).subscribe((data: any) => {
            this.rolnoArr.forEach(element => {
              if (element.f_no == f_no_in) {
                this.rolnoArr = this.rolnoArr.filter(res => res.f_no != f_no_in);
                this.formNoArr = this.formNoArr.filter(res => res != parseInt(f_no_in));
              }
            })//To pop out data from array if duplicate f_no exists to avoid incorrect rollno string
            this.rolnoArr.push({ f_no: f_no_in, rn: rol, rollno: data[0].ROLNO, reg_no: data[0].REG_NO, m_list: m_list_in })
            this.formNoArr.push(parseInt(f_no_in));
            // this.TempStoreRn.push({rn:parseInt(rol),rolno: data[0].ROLNO,f_no:parseInt(f_no_in)})
            this.existedRn = this.existedRn.filter(res => res != f_no_in);
          }));
        }
        else {
          this.rolnoArr.forEach(element => {
            if (element.f_no == f_no_in) {
              this.rolnoArr = this.rolnoArr.filter(res => res.f_no != f_no_in);
              this.formNoArr = this.formNoArr.filter(res => res != parseInt(f_no_in))
            }
          })//To pop out the f_no and rolno from array to make rolnoString null or empty if the rn is already present in database
          this.existedRn.push(f_no_in);
          //this.toastr.error("Roll No Already Exist")
        }
      }))//CheckExistingRn end
    }
    else if (rol == '') {
      this.formNoArr.forEach(element => {
        if (element == parseInt(f_no_in)) {
          this.rolnoArr = this.rolnoArr.filter(res => res.f_no != f_no_in);
          this.formNoArr = this.formNoArr.filter(res => res != parseInt(f_no_in))
        }
      });
      this.existedRn = this.existedRn.filter(res => res != f_no_in)
    }
  }

  getStudent(f_no_in: string, b_id_in: number, m_list_in: number) {
    this.personalInfo = [];
    const { c_code, year, maj_id } = this.formRef.value;
    this.subscription.add(this.assign.GetStdDataThroughForms(f_no_in, this.c_code_in, this.maj_id_in, this.year_in, b_id_in, m_list_in).subscribe((data: any) => {
      if (data.length == 0)
        this.toastr.error("Data Not Found")
      else {
        this.toastr.success("Data Found")
        this.length = data.length;
        for (let i = 0; i < data.length; i++) {
          this.personalInfo.push(data[i]);
        }
        this.InsertDataSingle(this.length)
      }
    }))
  }

  sort() {
    this.meritListStdArr.sort((a: IMeritListStudents, b: IMeritListStudents) => parseInt(a.rn) - parseInt(b.rn));
    this.meritListStdArr.sort((a: IMeritListStudents, b: IMeritListStudents) => b.status - a.status);
    this.meritListStdArr.sort((a: IMeritListStudents, b: IMeritListStudents) => b.paid - a.paid);
    this.meritListStdArr.sort((a: IMeritListStudents, b: IMeritListStudents) => b.maj_chng_needed - a.maj_chng_needed);
    if (this.meritListStdArr[0]?.LVLID == 1)
      this.meritListStdArr.sort((a: IMeritListStudents, b: IMeritListStudents) => b.checked - a.checked);
  }

  InsertDataSingle(length) {
    this.LMSServerAssigned = 0;
    this.HighestRn();
    const { c_code, maj_id } = this.formRef.value;
    for (let i = 0; i < length; i++) {
      //LMS Server
      this.subscription.add(this.assign.AssignRoll(
        this.personalInfo[i].YEAR, this.c_code_in , this.rolnoArr[this.formNoArr.indexOf(parseInt(this.personalInfo[i].F_NO))].rn,
        this.personalInfo[i].F_NO, this.rolnoArr[this.formNoArr.indexOf(parseInt(this.personalInfo[i].F_NO))].rollno,
        this.personalInfo[i].maj_id, this.rolnoArr[this.formNoArr.indexOf(parseInt(this.personalInfo[i].F_NO))].reg_no,
        this.personalInfo[i].NM, this.personalInfo[i].F_NM, this.personalInfo[i].gender, this.personalInfo[i].cnic, this.personalInfo[i].dob
        , this.personalInfo[i].ph1, this.personalInfo[i].add1, this.personalInfo[i].email, this.personalInfo[i].set_no, this.personalInfo[i].relig,
        this.personalInfo[i].shift, this.personalInfo[i].ph2, this.personalInfo[i].add2).subscribe((data2: any) => {
          if (data2 != null || data2 != undefined) {
            if (data2[0]?.assign == '1' && data2[0] != undefined) {
              this.LMSServerAssigned = data2[0].assign;
              this.toastr.success("RollNo Assigned")
              this.assignedRn.push(parseInt(this.personalInfo[i].F_NO));
              this.preAssigned.push({
                f_no: this.personalInfo[i].F_NO,
                rn: this.rolnoArr[this.formNoArr.indexOf(parseInt(this.personalInfo[i].F_NO))].rn,
                rolno: this.rolnoArr[this.formNoArr.indexOf(parseInt(this.personalInfo[i].F_NO))].rollno, status: 1,
                reg_no: this.rolnoArr[this.formNoArr.indexOf(parseInt(this.personalInfo[i].F_NO))].reg_no
              })
              this.meritListStdArr.forEach(element => {
                if (element.f_no == this.personalInfo[i].F_NO) {
                  element.rn = this.rolnoArr[this.formNoArr.indexOf(parseInt(this.personalInfo[i].F_NO))].rn;
                  element.rolno = this.rolnoArr[this.formNoArr.indexOf(parseInt(this.personalInfo[i].F_NO))].rollno;
                  element.reg_no = this.rolnoArr[this.formNoArr.indexOf(parseInt(this.personalInfo[i].F_NO))].reg_no
                  element.status = 1;
                  if (element.reg_no != null && element.reg_no != '' && element.reg_no != '0')
                    element.checked = 1;
                  else
                    element.checked = 0;
                }
              })
              this.HighestRn();
              this.UnAssignedForms()
              this.sort();
              this.AssignRollNoFeeServer(i)
            }
            else {
              this.toastr.error("RollNo Not Assigned. Error at LMS Server")
              this.DeleteStd(this.meritListStdArr[this.meritListStdArr.findIndex(res => res.f_no == this.personalInfo[i].F_NO)])
            }
          }
          else {
            this.toastr.error("RollNo Not Assigned. Error at LMS Server")
            this.DeleteStd(this.meritListStdArr[this.meritListStdArr.findIndex(res => res.f_no == this.personalInfo[i].F_NO)]);
          }
        }, err => {
          this.DeleteStd(this.meritListStdArr[this.meritListStdArr.findIndex(res => res.f_no == this.personalInfo[i].F_NO)]);
          this.toastr.error("RollNo Not Assigned. Error at LMS Server.");
        }))
    }
  }

  AssignRollNoFeeServer(i) {
    const { c_code, maj_id } = this.formRef.value;
    this.FeeServerAssigned = 0;
    //Fee Server
    this.subscription.add( this.assign.AssignRollFeeServer(
      this.personalInfo[i].YEAR, this.c_code_in , this.rolnoArr[this.formNoArr.indexOf(parseInt(this.personalInfo[i].F_NO))].rn,
      this.personalInfo[i].F_NO, this.rolnoArr[this.formNoArr.indexOf(parseInt(this.personalInfo[i].F_NO))].rollno,
      this.personalInfo[i].maj_id, this.rolnoArr[this.formNoArr.indexOf(parseInt(this.personalInfo[i].F_NO))].reg_no,
      this.personalInfo[i].NM, this.personalInfo[i].F_NM, this.personalInfo[i].gender, this.personalInfo[i].cnic, this.personalInfo[i].dob
      , this.personalInfo[i].ph1, this.personalInfo[i].add1, this.personalInfo[i].email, this.personalInfo[i].set_no,
      this.personalInfo[i].relig, this.personalInfo[i].shift, this.personalInfo[i].ph2, this.personalInfo[i].add2,
      this.personalInfo[i].b_id, this.personalInfo[i].tehsilId, this.personalInfo[i].dist_id, this.personalInfo[i].provinceId
    ).subscribe((data2: any) => {
      if (data2) {
        if (data2[0] != undefined) {
          this.feeServerAssigned = data2[0].assign;
          if (parseInt(this.feeServerAssigned) != 1 || parseInt(this.LMSServerAssigned) != 1) {
            this.DeleteStd(this.meritListStdArr[this.meritListStdArr.findIndex(res => res.f_no == this.personalInfo[i].F_NO)])
          }
          else {
          }
        }
        else {
          this.toastr.error("RollNo Not Assigned. Error at Fee Server");
          this.DeleteStd(this.meritListStdArr[this.meritListStdArr.findIndex(res => res.f_no == this.personalInfo[i].F_NO)])
        }
      }
      else {
        this.toastr.error("RollNo Not Assigned. Error at Fee Server");
        this.DeleteStd(this.meritListStdArr[this.meritListStdArr.findIndex(res => res.f_no == this.personalInfo[i].F_NO)])
      }
    }, err => {
      console.log(err);
      this.toastr.error("RollNo Not Assigned. Error at Fee Server");
      this.DeleteStd(this.meritListStdArr[this.meritListStdArr.findIndex(res => res.f_no == this.personalInfo[i].F_NO)]);
    }));
  }

  // InsertAll(m_list_in: string) {
  //   this.formnoString = '';
  //   this.rolnoArr.forEach(element => {
  //     if (element.m_list == m_list_in) {
  //       this.formnoString = this.formnoString + element.f_no + ',';
  //     }
  //   })
  //   this.getStudent(this.formnoString);
  // }

  selectMeritList(m_list) {
    this.formRef.value.rn = ''
    this.m_list = m_list;
    this.UpdateArrayForInputField();
  }

  Handle(e: any) {
    return e.keyCode != 13;
  }

  DeleteStdConfirm(std: any) {
    let msg =
      "Do you really want to Delete this Student?\nName " + std.Nm + "\nRoll Number: " + std.rolno + "\n\n Press OK to proceed";
    if (confirm(msg)) {
      this.DeleteStd(std);
    }
  }

  DeleteStd(std) {
    const { maj_id, year } = this.formRef.value;
    //Fee server
    this.subscription.add(this.assign.DeleteStdFromAdm_StdtFeeServer(std.f_no, std.c_code, this.maj_id_in, this.year_in).subscribe((data: any) => {
    }))
    this.subscription.add( this.subscription9 = this.assign.DeleteStdFromAdm_Stdt(std.f_no, std.c_code, this.maj_id_in, this.year_in).subscribe((data: any) => {
      if (data[0].deleted == 1) {
        this.assignedRn = this.assignedRn.filter(res => res != parseInt(std.f_no));
        this.preAssigned = this.preAssigned.filter(res => res.f_no != std.f_no)
        this.rolnoArr = this.rolnoArr.filter(res => res.f_no != std.f_no);
        this.formNoArr = this.formNoArr.filter(res => res != parseInt(std.f_no));
        this.meritListStdArr.forEach(element => {
          if (element.f_no == std.f_no) {
            element.status = 0;
            element.checked = 0;
          }
        })
        this.UnAssignedForms();
        this.HighestRn();
        this.sort();
      }
    }))
  }

  UpdateCheckedField(index, reg_no) {
    if (this.meritListStdArr[index].reg_no != null && this.meritListStdArr[index].reg_no != '0' && this.meritListStdArr[index].reg_no != undefined && this.meritListStdArr[index].reg_no != '')
      this.meritListStdArr[index].checked = ((!this.meritListStdArr[index].checked) == true) ? 1 : 0;
    else
      this.meritListStdArr[index].checked = 0;
  }

  updateRegistrationNoOnChange(index, f_no, reg_no_in) {
    const indexTemp = this.preAssigned.findIndex(res=>res.f_no==f_no)
    if(indexTemp !=-1)
    this.preAssigned[indexTemp].reg_no = (reg_no_in == '' ? 0 : reg_no_in+'') + ''
    const indexTemp2 = this.formNoArr.indexOf(parseInt(f_no))
    if (indexTemp2 != -1)
      this.rolnoArr[indexTemp2].reg_no = (reg_no_in == '' ? 0 : reg_no_in+'') + '';
  }

  updateRegistrationNoFee(index, f_no) {
    const { c_code, maj_id, year } = this.formRef.value;
    this.subscription.add( this.feeService.updateRegistrationNumberFee(this.meritListStdArr[index].f_no, this.preAssigned[this.preAssigned.findIndex(res=>res.f_no==f_no)].reg_no == '0' ? null : this.preAssigned[this.preAssigned.findIndex(res=>res.f_no==f_no)].reg_no, c_code, maj_id, year).subscribe((res: any) => {
      if (res[0][0]?.warn) {
        this.toastr.error(res[0][0].warn, ' FEE DB')
      }
      else if (res[0][0]?.msg) {
        this.updateRegistrationNoLms(index, f_no);
      }
      else {
        this.toastr.error('Error updating Registration Number FEE DB')
      }
    }))
  }

  updateRegistrationNoLms(index, f_no) {
    const { c_code, maj_id, year } = this.formRef.value;
    this.subscription.add( this.feeService.updateRegistrationNumberLms(this.meritListStdArr[index].f_no, this.preAssigned[this.preAssigned.findIndex(res=>res.f_no==f_no)].reg_no == '0' ? null : this.preAssigned[this.preAssigned.findIndex(res=>res.f_no==f_no)].reg_no, c_code, maj_id, year).subscribe((res: any) => {
      if (res[0][0]?.warn) {
        this.toastr.error(res[0][0].warn, " LMS DB")
      }
      else if (res[0][0]?.msg) {
        this.toastr.success(res[0][0].msg)
        this.meritListStdArr[index].reg_no = this.preAssigned[this.preAssigned.findIndex(res=>res.f_no==f_no)].reg_no;
        if (this.meritListStdArr[index].reg_no == '0')
          this.meritListStdArr[index].checked = 0;
        else
          this.meritListStdArr[index].checked = 1;
        this.sort();
      }
      else {
        this.toastr.error('Error updating Registration Number LMS DB')
      }
    }))
  }

  CategoryFilter(e: any) {
    var value;
    var value2;
    var value3;
    var value4;
    var value5;
    var value6;
    var value7;
    var assign;
    var store;
    var rows;
    $("#myInput1,#myInput2,#myInput3,#myInput4,#myInput5,#myInput6,#myInput7").on("keyup", function () {
      value = $("#myInput1").val().toUpperCase();
      value2 = $("#myInput2").val();
      value3 = $("#myInput3").val().toUpperCase();
      value4 = $("#myInput4").val().toUpperCase();
      value5 = $("#myInput5").val();
      value6 = $("#myInput6").val().toUpperCase();
      value7 = $("#myInput7").val().toUpperCase();
      assign = $("#massign").val();
      rows = $("#myTable #myBody").find("tr").hide();
      rows.filter(function () {
        store = $(this).children(':eq(0)').text().indexOf(value) !== -1
          && $(this).children(':eq(1)').text().indexOf(value2) !== -1
          && $(this).children(':eq(2)').text().indexOf(value3) !== -1
          && $(this).children(':eq(3)').text().indexOf(value4) !== -1
          && $(this).children(':eq(4)').text().indexOf(value5) !== -1
          && $(this).children(':eq(5)').text().indexOf(value6) !== -1
          && $(this).children(':eq(6)').text().indexOf(value7) !== -1;
        return store;
      }).show();
    })
    return e.keyCode != 13;
  }

  CreatePDF() {
    const { year, c_code, maj_id } = this.formRef.value
    if (this.meritListStdArr?.length == 0) {
      this.toastr.warning("No Record Found");
      return;
    }
    else {
      var body = [];
      const header = [];
      let sr = 1;
      var exportDate = this.datePipe.transform(new Date(), 'MMM d, y')
      var image = new Image();
      var page;
      image.src = '../../../assets/images/logo3.png';
      header.push(['Sr#', 'Base', 'Form No', 'Name', 'Father Name', 'Roll No', 'Complete Roll No', 'Registration No', 'Email', 'Phone No']);
      const doc = new jsPDF('l', 'mm', 'A4');
      this.classes.forEach(element => {
        if (element.id == this.c_code_in) {
          this.class = element.nm;
        }
      })
      this.majors.forEach(element => {
        if (element.id == this.maj_id_in) {
          this.majorNm = element.nm;
        }
      })

      for (let k = 0; k < this.meritListCount.length; k++) {
        let y = 54;
        this.meritNo = k + 1;
        sr = 1;
        if (this.meritNo > 1) {
          doc.addPage();
        }
        doc.addImage(image, 257, 5, 25, 30);
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 261, 40);
        doc.setFontSize(22);
        doc.text("GC UNIVERSITY, LAHORE", 150, 14, { align: 'center' });
        doc.setFontSize(18);
        doc.setFont('Helvetica', 'bold');
        doc.text("ROLL NUMBER REPORT", 150, 21, { align: 'center' });
        doc.setFontSize(14);
        doc.text("CLASS: " + this.class, 150, 28, { align: 'center' });
        doc.setFontSize(13);
        doc.text("MAJOR: " + this.majorNm, 150, 35, { align: 'center' });
        doc.setFontSize(12);
        doc.text("SESSION: " + this.year_in, 150, 41, { align: 'center' });
        doc.setFontSize(12);
        doc.text("MERIT LIST: " + this.meritNo, 150, 47, { align: 'center' });
        for (let i = 0; i < this.meritListStdArr.length; i++) {
          body = [];
          if (k + 1 == parseInt(this.meritListStdArr[i].m_list) && this.meritListStdArr[i].paid == 1 && this.meritListStdArr[i].status == 1) {
            body.push([{ content: "SR#", styles: { fontStyle: "bold", fontSize: 7.5, halign: "center" } },
            { content: "ADMISSION BASE", styles: { fontStyle: "bold", fontSize: 7.5, halign: "center" } },
            { content: "FORM#", styles: { fontStyle: "bold", fontSize: 7.5, halign: "center" } },
            { content: "NAME", styles: { fontStyle: "bold", fontSize: 7.5, halign: "center" } },
            { content: "FATHER NAME", styles: { fontStyle: "bold", fontSize: 7.5, halign: "center" } },
            { content: "ROLL#", styles: { fontStyle: "bold", fontSize: 7.5, halign: "center" } },
            { content: "COMPLETE ROLL#", styles: { fontStyle: "bold", fontSize: 7.5, halign: "center" } },
            { content: "REGISTRATION#", styles: { fontStyle: "bold", fontSize: 7.5, halign: "center" } },
            { content: "EMAIL", styles: { fontStyle: "bold", fontSize: 7.5, halign: "center" } },
            { content: "PHONE#", styles: { fontStyle: "bold", fontSize: 7.5, halign: "center" } }],
              [
                { content: sr, styles: { fontSize: 7.5, halign: "center" } },
                { content: this.meritListStdArr[i].b_nm, styles: { fontSize: 7.5, halign: "center" } },
                { content: this.meritListStdArr[i].f_no, styles: { fontSize: 7.5, halign: "center" } },
                { content: this.meritListStdArr[i].Nm, styles: { fontSize: 7.5, halign: "center" } },
                { content: this.meritListStdArr[i].f_nm, styles: { fontSize: 7.5, halign: "center" } },
                { content: this.meritListStdArr[i].rn, styles: { fontSize: 7.5, halign: "center" } },
                { content: this.meritListStdArr[i].rolno, styles: { fontSize: 7.5, halign: "center" } },
                { content: this.meritListStdArr[i].reg_no, styles: { fontSize: 7.5, halign: "center" } },
                { content: this.meritListStdArr[i].email, styles: { fontSize: 7.5, halign: "center" } },
                { content: this.meritListStdArr[i].ph1, styles: { fontSize: 7.5, halign: "center" } },
              ], [{ content: "ADD", styles: { fontStyle: "bold", fontSize: 7.5, halign: "center" } }, { content: this.meritListStdArr[i].add1, colSpan: 9 }]);
            sr++;
            autoTable(doc, {
              theme: 'grid',
              startY: y,
              margin: { top: 10, bottom: 15, left: 5, right: 5 },
              styles: { fontSize: 7.5 },
              body: body,
              columnStyles: {
                0: { cellWidth: 10 },
                1: { cellWidth: 30 },
                2: { cellWidth: 18 },
                3: { cellWidth: 40 },
                4: { cellWidth: 40 },
                5: { cellWidth: 14 },
                6: { cellWidth: 30 },
                7: { cellWidth: 40 },
                8: { cellWidth: 42 },
                9: { cellWidth: 23 }
              },
              didDrawPage: function (data) {
                y = data.cursor.y;
                page = '';
                page = doc.getNumberOfPages();
              }
            });
            y = y + 4;
            doc.setFont('Helvetica', 'bold');
            doc.text("----------------------------------------", 150, y, { align: 'center' });
            y = y + 2;

          }
        }
      }

      var l = (body.length + 65) % 26;
      doc.setFontSize(10);
      for (var i = 1; i <= page; i++) {
        doc.setPage(i);
        doc.text('Page ' + i + " of " + page, 140, 205);
        doc.setFontSize(7);
        doc.text("Note:- Errors and Omissions Expected", 215, 205);
        doc.text("Directorate of Information Technology", 15, 205);
        var p = body.length;
      }

      window.open(URL.createObjectURL(doc.output('blob')), 'Roll Number(' + this.majorNm + '(' + year + ')' + ')');

    }
  }

  DownloadExcel() {
    const { maj_id } = this.formRef.value;
    let body = [];
    let header = [];
    let j = 1;
    this.majors.forEach(element => {
      if (element.id == maj_id) {
        this.majorNm = element.nm;
      }
    })
  
    header.push(['Serial No', 'Base', 'Merit List', 'Form No', 'Name', 'Father Name', 'Roll No', 'Complete Roll No', 'Registration No', 'Email', 'Phone No', 'Address']);
    for (let i = 0; i < this.meritListStdArr.length; i++) {
      console.log("paid:", this.meritListStdArr[i].paid)
      console.log("MStatus:", this.meritListStdArr[i].status)
      if (this.meritListStdArr[i].paid == 1 && this.meritListStdArr[i].status == 1)
        body.push([
          j,
          this.meritListStdArr[i].b_nm,
          this.meritListStdArr[i].m_list,
          this.meritListStdArr[i].f_no,
          this.meritListStdArr[i].Nm,
          this.meritListStdArr[i].f_nm,
          this.meritListStdArr[i].rn,
          this.meritListStdArr[i].rolno,
          this.meritListStdArr[i].reg_no,
          this.meritListStdArr[i].email,
          this.meritListStdArr[i].ph1,
          this.meritListStdArr[i].add1,

        ]);
      j++;
    };
    new AngularCsv(body, "Roll Number(" + this.majorNm + ")", { headers: (header) });
  }

  ClearClassBase() {
    this.formRef?.controls['maj_id'].reset();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
