<form #f="ngForm">
    <div class=" container-fluid" [@SlideInFromLeft]>
        <div class="card shadow">
            <div class="card-header">
                <div>Outstanding Dues Entry</div>
            </div>
            <div class="card-body row">
                <div class="form-group col-lg-4">
                    <label for="sub">Classes</label>
                    <select class="form-control" id="sub" aria-label="ClassCode" ngModel required name="c_code" (change)="ClearFields()">
                        <option *ngFor="let i of classes" value="{{ i.id }}" (keydown.enter)="$event.preventDefault()">
                            {{ i.nm }}
                        </option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="Year">Year</label>
                    <select class="form-control" #session name="year" id="Year" ngModel required (change)="ClearFields()">
                        <option *ngFor="let s of sessions" value="{{s}}" (keydown.enter)="$event.preventDefault()">{{s}}
                        </option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="Fee">Fee</label>
                    <select class="form-control" #fine_id name="fine_id" id="fine_id" ngModel required>
                        <option *ngFor="let f of feeidsNm" value="{{f.fee_id}}"
                            (keydown.enter)="$event.preventDefault()">{{f.fee_nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="amnt">Amount</label>
                    <input type="number" min="0" class="form-control" name="amnt" ngModel required
                        (keydown.enter)="$event.preventDefault()">
                </div>
                <div class="col-lg-4 col-md-6 form-group">

                    <label for="des">Description</label>
                    <span class="login-box" style="margin-top:-47px;float:right">
                        <form>
                            <a *ngIf="manualDes==0" style="font-size:9px;font-weight:bold" (click)="ChangeManualDes(1)">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                MANUAL DES
                            </a>
                            <a *ngIf="manualDes==1" style="font-size:9px;font-weight:bold" (click)="ChangeManualDes(0)">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                OPTIONS
                            </a>
                        </form>
                    </span>


                    <select class="form-control" #session name="des" id="des" ngModel required *ngIf="manualDes==0">
                        <option *ngFor="let f of fineAcc" value="{{f.nm}}" (keydown.enter)="$event.preventDefault()">
                            {{f.nm}}</option>
                    </select>

                    <input class="form-control" name="des" ngModel required *ngIf="manualDes==1"
                        (keydown.enter)="$event.preventDefault()">
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="fine_entry_dt">Fine Entry Date</label>
                    <input type="date" [maxLength]="3" class="form-control" name="fine_entry_dt" #exDate
                        (change)="FineEntryDate(exDate.value)" required (keydown.enter)="$event.preventDefault()">
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="rn">Roll No <b>(Press Enter to Insert)</b></label>
                    <input type="number" min="1" class="form-control" name="rn" ngModel required
                        (keyup)="getStdName($event)" (keydown.enter)="$event.preventDefault()">
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="nm">Name</label>
                    <input value="{{name}}" class="form-control" name="nm" disabled ngModel required>
                </div>
            </div>
            <div *ngIf="existedOutStdEntriesArr?.length>0" class="col-lg-4 col-md-6 form-group"
                style="margin-left:20px">
                <label for="search">Search</label>
                <input class="form-control" name="search" ngModel id="myInput" #myInput (keyup)="SearchTable()"
                    (keydown.enter)="$event.preventDefault()">
            </div>
            <div *ngIf="existedOutStdEntriesArr?.length>0" class="card-body">
                <table class="table table-bordered table-striped" style="font-size:10px">
                    <thead class="thead-dark">
                        <tr style="font-size:14px;text-align:center;font-weight:bold">
                            <th colspan="11">OUTSTANDING DUES</th>
                        </tr>
                        <tr style="font-size:10px;text-align:center;font-weight:bold">
                            <th scope="col">SR #</th>
                            <th scope="col">CHALLAN #</th>
                            <th scope="col">ROLL #</th>
                            <th scope="col">ACTUAL YEAR</th>
                            <th scope="col">SESSION</th>
                            <th scope="col">CLASS</th>
                            <th scope="col">NAME</th>
                            <th scope="col">FEE</th>
                            <th scope="col">AMOUNT</th>
                            <th scope="col">DESCRIPTION</th>
                            <th scope="col">ACTION</th>
                        </tr>

                    </thead>
                    <tbody id="myTable" #myTable>
                        <tr *ngFor="let f of existedOutStdEntriesArr; let j =index;" style="text-align:center;">
                            <td style="font-weight:bold">{{j+1}}</td>
                            <td style="font-weight:bold">{{f.ch_no}}</td>
                            <td style="font-weight:bold">{{f.rolno}}</td>
                            <td style="font-weight:bold">{{f.actyear}}</td>
                            <td style="font-weight:bold">{{f.session}}</td>
                            <td style="font-weight:bold">{{f.class}}</td>
                            <td style="font-weight:bold">{{f.nm}}</td>
                            <td style="font-weight:bold">{{f.fee}}</td>
                            <td style="font-weight:bold">{{f.amnt}}</td>
                            <td style="font-weight:bold">{{f.Des}}</td>
                            <td><button class="btn btn-danger" style="font-size:8px"
                                    (click)="DeleteStdEntry(f,1)">Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</form>