<div [@SlideInFromLeft] class="container-fluid">
  <div class="row card shadow " style="min-height: 80vh;">
    <div class="card-header"  style="padding-top: 10px;">
    <div class=" col-lg-6 col-md-6 form-group">
      Student Timetable</div>
       <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
      <app-details-button [details]="details"></app-details-button>
  </div>
</div>
    <form  #f="ngForm">
      <div class="card-body mb-0" style="margin-top: -10px">
        <div class="row">
        <div class="col-lg-4 col-md-6 form-group">
          <label for="C_Code">Class</label>
          <select #cource (change)="OnClassChange(cource)" class="form-control " name="c_code" id="C_Code" ngModel required>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
          </div>
          <div class="col-lg-4 col-md-6  form-group">
          <label for="Session">Session</label>
          <select class="form-control " #session (change)="OnSessionChange(session)" name="se_id" id="Session" ngModel>
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="Major">Major</label>
          <select #maj class="form-control" #major name="maj_id" id="Major"  (change)="OnGrpChange(maj)"  ngModel>
            <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
          </select>
        </div>

      </div>
      <div class="mt-2">
        <button class="btn btn-danger pr-2" [disabled]="students?.length===0" (click)="downloadAll()"><i class="fa fa-file-pdf-o" for=""></i> Download All Timetable</button>
        <button class="btn btn-success pr-2 ml-2" [disabled]="students?.length===0" (click)="downloadExcel()"> <i class="fa fa-file-excel-o" for=""></i> Download Students List</button>
      </div>
    </div>
      <div class="card-body" style="margin-top: -40px" *ngIf="students?.length>0">
        <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
          <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
      </div>
        <table class="table table-bordered table-striped" id="table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Sr No</th>
              <th scope="col">Roll No</th>
              <th scope="col">Name</th>
              <th scope="col" style="width: 10vw">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let s of students; let i=index" id="values">
              <td>{{i+1}}</td>
              <td>{{s.ROLNO.toUpperCase()}}</td>
              <td>{{s.NM.toUpperCase()}}</td>
              <td><button class="btn btn-primary" (click)='downloadTimetable(s)' style="font-size: 12px;">Download</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  </div>
</div>
