<div class="card" [@SlideInFromLeft]>
  <div class="card-header">
    Upload Course Material
  </div>
  <div class="card-body">
    <form [formGroup]="courseMaterailForm">
      <div class="row">
        <div class="form-group col-lg-6">
          <label for="course">Class</label>
          <select #class class="form-control shadow-sm" (change)="OnClassChange(class)" name="course" id="course">
            <option *ngFor="let c of classes" value="{{c.c_code}}">{{c.c_nm}}</option>
          </select>
        </div>
        <div class="form-group col-lg-6">
          <label for="section">Course</label>
          <select #c class="form-control shadow-sm" name="section" id="section" (change)="onSubjectChange(c)">
            <option *ngFor="let t of teams; let i=index;" value={{i}}>{{t.SUB_CODE}}-{{t.SUB_NM}}-{{t.SECTION}}-{{t.G_NM}}-{{t.T_NO}}</option>
          </select>
        </div>
        <div class="form-group col-lg-6">
          <label for="title">Title:</label>
          <input #title type="text" formControlName='title' class="form-control shadow-sm" name="title" id="title"
            [ngClass]="{ 'is-invalid': f.title.errors &&
                     submitted }" aria-describedby="helpId" placeholder="Title of Upload">
          <small id="helpId" class="form-text text-muted">Title of upload</small>
        </div>

        <div class="col-sm-12 col-lg-6">
          <div class="form-group">
            <label class="text-bold">Attachment</label>
            <div class="input-group">
              <div class="custom-file">
                <input type="file" class="custom-file-input" id="file" name="file" aria-describedby="file"
                  (change)="handleFile($event)" formControlName="file"
                  [ngClass]="{ 'is-invalid': fileToUpload?.size> MAX_FILE_SIZE}">
                <div *ngIf="fileToUpload?.size> MAX_FILE_SIZE" class="invalid-feedback mt-5">
                  <div *ngIf="fileToUpload?.size> MAX_FILE_SIZE">File must be less than 5MB</div>
                </div>
                <label class="custom-file-label shadow-sm" for="file">{{(fileToUpload?.name || "Choose File")}}</label>
              </div>
            </div>
          </div>
        </div>



        <div class="form-group col-lg-6 mt-2">
          <button [disabled]="!tm"
            class="btn btn-outline-primary btn-md" type="button" (click)="loadMaterial()">View Files
          </button>
          <button [disabled]="courseMaterailForm.invalid || fileToUpload?.size> MAX_FILE_SIZE"
            class="btn btn-outline-primary btn-md ml-3" type="submit" (click)="uploadFiles()">Upload Course
          </button>
        </div>
      </div>
    </form>

  </div>

  <div class="card shadow">
    <div class="card-header">
      <div>Course Materials</div>
    </div>
    <div class="card-body mb-3" style="overflow: hidden; overflow-x: scroll">

      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">No.</th>
            <th scope="col">Title</th>
            <th scope="col">File Name</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let f of materail | keyvalue; let i=index;">
            <td>{{i+1}}</td>
            <td>{{f.value.CM_TITLE}}</td>
            <td  class="link-primary"
              [ngStyle]="{ '': f.value.FILE_PATH!==''}">
             <a href="javascript:void(0);" (click)="downloadFile(f.value.FILE_NM, f.value.FILE_PATH)">
               {{f.value.FILE_NM}}</a></td>
            <td><button (click)="onDelCLick(f.value.CM_ID,f.value.FILE_PATH)" class="btn btn-sm btn-danger">Delete</button></td>
            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
