<div class="d-flex justify-content-between mb-1 mt-2 ">
  <div class="list-group-item list-group-item-action">

    <div class="" (click)="toggleEditMode()" style="cursor: pointer;">
      <div class="d-flex w-100 justify-content-between">
        <h6 class="mb-1">{{data.cNm}}-{{data.se}} {{data.subject}} <b>({{data.section}})</b></h6>
        <small>
          <p [ngClass]="{'text-info': !isEditable}">
            {{(data.status===0?"DRAFT":(data.status===1?"PUBLISHED":(data.status===2?"ATTENDANCE LOCK":(data.status===3?"ELIGIBILITY CALCULATED":(data.status===4?"AWARDLIST LOCK":(data.status===5?"AWARDLIST SUBMITTED":"RECORD"))))))}}
          <!-- {{(data.status===0 || data.status===1)? (data.status===1 ? "Published": "Draft"): "Record"}} -->
        </p>
        </small>
      </div>
      <div class="d-flex w-100 justify-content-around mt-2" style="align-items: center;">
        <p>Semester: {{data.tNo}}</p>
        <p>{{data.fm}}</p>

        <p>{{data.day}}:
          {{ '1970-01-01 '+ data.sTime  | date: 'h:mm a'}}-{{'1970-01-01 '+ data.eTime | date: 'h:mm a'}}</p>
        <p>{{data.blkNm}}-{{data.rmNm}}</p>
      </div>
    </div>

    <div *ngIf="editView" class="mt-2">
      <form (ngSubmit)="OnSubmit()" #f="ngForm">
          <hr>
        <div class="row space-remover">

          <div class="col-lg-4 form-group">
            <label for="Teacher">Teacher</label>
            <select #teacher class="form-control" 
              name="fm_id" id="Teacher" ngModel>
              <option *ngFor="let fm of teachers" value="{{fm.id}}" >{{fm.nm}}</option>
            <option  value="-1">Not Assigned</option>

            </select>
            
          </div>

          <div class="col-lg-4 form-group" >
            <label for="Day">Day</label>
            <select #day class="form-control " name="day" id="Day"  ngModel>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>

            </select>
          </div>
          <div class="timess col-lg-4 form-group">
            <label for="startTime">Start Time</label>
            <!--<input #time type="time" class="form-control" name="s_time" id="startTime" min="08:00" max="21:00" ngModel> -->
            <select #time class="form-control" name="s_time" id="startTime" aria-describedby="dueDateHelp" ngModel>
            <option *ngFor="let t of timings" value={{t.start_time}}>{{t.desc}}</option>
            </select>
          </div>
          <div class="col-lg-4 form-group" >
            <label for="duration" class="text-bold">Duration</label>
          <select #dur class="form-control" name="duration" id="duration" ngModel required>
            <option *ngFor="let d of durations" value="{{d.dur}}">{{d.des}}</option>
          </select>
          </div>


          <div class="col-lg-4 form-group">
            <label for="Building" class="text-bold">Building</label>
            <select #blk class="form-control"  name="blk_id" id="Building" ngModel
              (change)="OnBuildingChange(blk)">
              <option *ngFor="let b of blocks" value="{{b.id}}">{{b.nm}}</option>
            </select>
          </div>
          <div class="col-lg-4 form-group">
            <label for="Room">Room</label>
            <select #room class="form-control" name="rm_id" id="Room" ngModel>
              <option *ngFor="let r of rooms" value="{{r.id}}">{{r.nm}}</option>
            </select>
          </div>

          <div class="row mt-1">
            <button class="btn btn-sm btn-outline-primary mt-1" (click)="updateTeacher()" type="button">Update Teacher</button>
            <button class="btn btn-sm btn-outline-primary mt-1"
              type="submit">Update Time</button>

              <button class="btn btn-sm btn-outline-primary mt-1" (click)="updateRoom()" type="button">Change
                Room</button>
  
            <button class="btn btn-sm btn-outline-primary mt-1"
              (click)="OnManageTeacher()" type="button">Manage Teacher</button>
  
            <button class="btn btn-sm btn-outline-danger mt-1"
              (click)="OnDeleteClick()" type="button">Delete</button>
          </div>
        </div>
      </form>
    </div>



  </div>
</div>

<app-manage-teacher *ngIf="manageTeacher" (teacher)="OnNewTeacher($event) " (close)="OnCloseManageTeacher()">
</app-manage-teacher>