import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { AssessmentService } from '../shared/services/Assessment.service';


interface grade {
  ASSIGNMENT_TITLE: string;
  ASSIGNMENT_TYPE: string;
  ASS_OBT_MRKS: number;
  ASS_TOT_MRKS: number;
  DT: string;

}

interface EnrollInfo {
  sub_code: string,
  subject: string,
  t_no: number,
  section: string,
  se_id: number,
  session: string
}

interface stdInfo {
  nm: string,
  nic: string,
  year: number,
  c_code: number,
  class: string,
  d_id: number,
  deptt: string,
  set_no: number,
  maj_id: number,
  major: string,
  rn: number,
  se_id: number,
  session: string,
}

@Component({
  selector: 'app-check-assessment',
  templateUrl: './check-assessment.component.html',
  styleUrls: ['./check-assessment.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class CheckAssessmentComponent implements OnInit {
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Input() enrolInfo: EnrollInfo;
  @Input() std: stdInfo;

  assignments: grade[];
  quiz: grade[];
  projects: grade[];
  presentation: grade[];
  mid: grade[];
  lab: grade[];
  final: grade[];
  noAssessment: boolean = false;

  constructor(
    private assessmentService: AssessmentService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.noAssessment = false;
    this.assessmentService.getDetAssessmentMarkList({ C_CODE: this.std.c_code, GRP: this.std.maj_id, SE_ID: this.enrolInfo.se_id, YEAR: this.std.year, T_NO: this.enrolInfo.t_no, SECTION: this.enrolInfo.section, SUB_CODE: this.enrolInfo.sub_code, RN: this.std.rn }).subscribe((res: grade[]) => {
      this.assignments = [];
      this.quiz = [];
      this.projects = [];
      this.presentation = [];
      this.mid = [];
      this.lab = [];
      this.final = [];
      if (res.length == 0) {
        this.noAssessment = true;
        return;
      }
      if (res) {
        this.quiz = res.filter(x => x.ASSIGNMENT_TYPE === 'Quiz');
        this.assignments = res.filter(x => x.ASSIGNMENT_TYPE === 'Assignment');
        this.presentation = res.filter(x => x.ASSIGNMENT_TYPE === 'Presentation');
        this.projects = res.filter(x => x.ASSIGNMENT_TYPE === 'Project');
        this.mid = res.filter(x => x.ASSIGNMENT_TYPE === 'Mid Term');
        this.lab = res.filter(x => x.ASSIGNMENT_TYPE === 'Lab');
        this.final = res.filter(x => x.ASSIGNMENT_TYPE === 'Final Term');
      }
    }, err => {
      this.toastr.error("Unknown Error");
    });
  }

  OnClose() {
    this.close.emit();
  }

}
