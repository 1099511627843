import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { courseOutlineService } from '../course-outline.service';
import { CourseOutlineModel } from './course-outline.model';
import { Team } from 'src/app/main/shared/services/Timetable.service';

@Component({
  selector: 'app-outline-entry',
  templateUrl: './outline-entry.component.html',
  styleUrls: ['./outline-entry.component.css']
})
export class OutlineEntryComponent implements OnInit {
 @Input() outline:Array<CourseOutlineModel>;
 @Input() tm:Team;
 

  constructor(private toastr: ToastrService, private outServ:courseOutlineService,) {
    this.outline=new Array<CourseOutlineModel>();
   }

  ngOnInit(): void {
  }

  deleteWeek(){
    this.outServ.deleteWeek(this.tm).subscribe(r => {
      
    }, err => {
      console.log(err);
      this.toastr.error("No Internet");
    });
  }

}
