<div class="row">
    <div class="col-lg-5 col-md-6 col-sm-12">
        <div class="card">
            <div class="card-header">
                OVERALL DETAIL
            </div>
            <div class="card-body">
                <div class="chart-wrapper">
                    <canvas baseChart #chart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
                        [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend">
                    </canvas>
                </div>
            </div>
            <div class="card-footer">
                <div class="row pb-2">
                    <div class="col-sm-4 m-auto">Mean: {{this.arr[0]?.mean}}</div>
                    <div class="col-sm-4 m-auto">%Age: {{this.arr[0]?.per}}</div>
                    <div class="col-sm-4 m-auto">Grade: {{this.CharDataCustom[0]?.grade}}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-7 col-md-7 col-sm-12">
        <div class="card">
            <div class="card-header">{{this.CharDataCustom[0]?.secondHeading}}</div>
            <div class="card-body">
                <div class="chart-wrapper">
                    <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels"
                        [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                        [chartType]="lineChartType" [plugins]="lineChartPlugins">
                    </canvas>
                </div>
            </div>
        </div>
    </div>

</div>