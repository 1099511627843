<div [@SlideInFromLeft] class="tabber backdrop" style="overflow:scroll; overflow-y: scroll; margin-left: 5%; margin-top: 1%;">
    <div class="container-fluid mb-1 card shadow alert-box col-lg-8 ml-2 col-md-12 offset-sm-3 ml-2 mr-2 ml-sm-auto mr-sm-auto">
        <div class="card-header d-flex justify-content-between">
            <div>Check Teacher Availability Issues</div>
            <button (click)="OnClose()" class="btn btn-outline-primary btn-sm">Close</button>
        </div>
        <form (ngSubmit)="FetchTimeTable()" #f="ngForm">
            <div class="row card-body space-remover">
                <div class="col-lg-4 form-group">
                    <label for="dpt">Department</label>
                    <select #dpt class="form-control" name="dpt" id="dpt" (change)="OnDeptChange(dpt)" ngModel>
                        <option *ngFor="let d of departments" value="{{d.id}}">{{d.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="fm_id">Teacher</label>
                    <select #fm_id class="form-control" name="fm_id" id="fm_id" ngModel>
                        <option *ngFor="let fm of teachers" value="{{fm.id}}">{{fm.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="t_no">Semester</label>
                    <input #t_no type="number" placeholder="Optional" class="form-control" min="0" max="8" name="t_no"
                        id="t_no" ngModel>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="section">Section</label>
                    <input #section type="text" placeholder="Optional" class="form-control" name="section" id="section"
                        ngModel>
                </div>
                <div>
                    <button style="height: 35px;" class="btn btn-sm btn-outline-primary mt-4" type="submit">Get
                        Information</button>
                </div>
            </div>
        </form>

        <div class="wrapper">
        <div *ngIf="hideInfo" class="d-flex justify-content-between mb-1 mt-0">
            <div class="list-group-item list-group-item-action">
                <div *ngFor="let data of array">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1">{{data.cNm}}-{{data.se}} {{data.subject}}-({{data.sub_code}})
                            <b>{{data.section}}</b>
                        </h6>
                    </div>
                    <div class="d-flex w-100 justify-content-around mt-2" style="align-items: center;">
                        <p>Semester: {{data.tNo}}</p>
                        <!-- <p>{{data.fm}}</p> -->
                        <p>{{data.day}}:
                            {{ '1970-01-01 '+ data.sTime | date: 'h:mm a'}}-{{'1970-01-01 '+
                            data.eTime | date: 'h:mm a'}}
                        </p>
                        <p>{{data.blkNm}}-{{data.rmNm}}</p>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </div>

    </div>
</div>