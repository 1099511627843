import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { SelectList } from "../shared/model/SelectList.model";
import { ClassService } from "../shared/services/Class.service";
import { SessionService } from "../shared/services/Sessions.service";
import { GroupService } from "./../shared/services/Groups.service";
import { AuthenticationService } from "src/app/auth/_services/authentication.service";
import { SlideInFromLeft } from "src/app/transitions";
import { ShiftingService } from "./../shared/services/ShiftingManagement.service";
import * as moment from "moment";

interface tm {
  C_CODE: number;
  GRP: number;
  GRP_NM?: string;
  F_NM?: string;
  SECTION: string;
  SE_ID: number;
  STATUS: number;
  SUB_CODE: string;
  T_NO: number;
  DATE?: string;
}

@Component({
  selector: "app-manage-locking",
  templateUrl: "./manage-locking.component.html",
  styleUrls: ["./manage-locking.component.css"],
  animations: [SlideInFromLeft()],
})
export class ManageLockingComponent implements OnInit {
  groups: Array<SelectList>;
  classes: Array<SelectList>;
  sessions: Array<SelectList>;

  teams: Array<tm>;

  lockForm: UntypedFormGroup;

  isAdmin: boolean = false;
  isExamCell: boolean = false;

  group: number = 0;
  dept: number = 0;

  updating: boolean = false;

  autoLockBool: boolean = false;
  private unlockIndex: number = -1;
  loader = false;
  index: number = -1;
  forwhichcolumndateisvisible = 0;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  this screen is used to lock/unlock the attendance,award list,award list submission to examination office and 
  for to check the status of offered subjects of any semester of any session of any major in its class </h6>
  <h6 class="text-right">یہ اسکرین کسی بھی میجر کی کلاس کے کسی بھی سیشن کے کسی بھی سمسٹر میں حاضری، ایوارڈ لسٹ، ایوارڈ لسٹ سبمیشن کو 
  ایکزامینیشن آفس میں لاک/ان لاک کرنے اور آفر کردہ سبجیکٹس کا اسٹیٹس چیک کرنے کے لیے استعمال ہوتی ہے۔۔
</h6>
  `  ;
  details1 =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'> this screen is used to lock/unlock the attendance,eligibility and 
  for to check the status of offered subjects of any semester of any session of any major in its class </h6>
  <h6 class="text-right"> یہ اسکرین کسی بھی میجر کی کلاس کے کسی بھی سیشن کے کسی بھی سمسٹر میں حاضری، ایلیجیبلٹی کو لاک/ان لاک کرنے اور آفر کردہ سبجیکٹس کا اسٹیٹس چیک کرنے کے لیے استعمال ہوتی ہے۔۔
</h6>
  `  ;

  constructor(
    private classService: ClassService,
    private sessionService: SessionService,
    private toastrService: ToastrService,
    private authenticationService: AuthenticationService,
    private FormBuilder: UntypedFormBuilder,
    private groupService: GroupService,
    private shiftingService: ShiftingService
  ) {

    this.groups = [];
    this.classes = [];
    this.sessions = [];

    this.teams = [];

    this.isAdmin = this.authenticationService.isAdmin();
    this.isExamCell = this.authenticationService.isExamCell();

    this.group = this.authenticationService.getGroup();
    this.dept = this.authenticationService.getUser().D_ID;
  }

  ngOnInit(): void {
    this.loader = false;
    this.lockForm = this.FormBuilder.group({
      grp: ["", Validators.required],
      c_code: ["", Validators.required],
      se_id: ["", Validators.required],
      t_no: ["", Validators.required],
      section: [""],
    });

    if (this.isAdmin || this.isExamCell) {
      this.loadGroups();
    } else {
      this.loadClassess("-1");
    }
  }

  get f() {
    return this.lockForm.controls;
  }

  curdate(): any {
    return moment(new Date()).add(1, "day").format("YYYY-MM-DD");
  }

  loadGroups() {
    this.groupService
      .getGroups({ grp: this.group, role: this.isAdmin || this.isExamCell })
      .subscribe(
        (res: { GRP: number; G_NM: string }[]) => {
          res?.forEach((element) => {
            this.groups.push({ id: element.GRP, nm: element.G_NM });
          });
          if (!this.isAdmin && !this.isExamCell) {
            this.loadClassess(this.group.toString());
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  loadClassess(grp: string) {
    let param = {};
    if (grp === "-1") {
      param["sub_d_id"] = this.dept;
    } else {
      param["grp"] = grp;
    }
    this.classes = [];
    this.classService.getClasses(param).subscribe(
      (res: { C_CODE: number; C_NM: string }[]) => {
        res?.forEach((entry) => {
          this.classes.push({ nm: entry.C_NM, id: entry.C_CODE });
        });
      },
      (err) => {
        this.toastrService.error("Unknown Error");
      }
    );
  }

  getTeams() {
    if (!this.isAdmin && !this.isExamCell) {
      this.lockForm.value.grp = this.group;
      this.lockForm.controls["grp"].setValue(this.group);
    }

    if (this.lockForm.invalid) return;
    this.loader = true;
    this.shiftingService
      .getTeams({
        ...this.lockForm.value,
        d_id: this.dept,
        isAdmin: this.isAdmin || this.isExamCell,
      })
      .subscribe(
        (res: tm[]) => {
          this.teams = res;
          this.loader = false;
        },
        (err) => {
          this.loader = false;
          this.toastrService.error("Unknown Error");
        }
      );
  }

  setPublishStatus(index: number) {
    const tm = this.teams[index];
    if (!tm) return;
    const newStatus = tm.STATUS === 0 ? 1 : 0;
    this.updateTeamStatus(tm, newStatus, "", index);
  }

  setLockStatus(index: number) {
    const tm = this.teams[index];
    if (!tm ||
      tm.STATUS === 0 ||
      tm.STATUS === -1 ||
      tm.STATUS === 4 ||
      tm.STATUS === 5 ||
      tm.STATUS === 6
    )
      return;

    // for date entry
    if (tm.STATUS === 2 || tm.STATUS === 3) {
      this.forwhichcolumndateisvisible = 1;
      this.index = index;
      return;
    }

    const action = "";
    // const action = (tm.STATUS === 3) ? 'unlock' : '';
    // const newStatus = (tm.STATUS === 2 || tm.STATUS === 3) ? 1 : ((this.isExamCell || this.isAdmin) ? 3 : 2);
    const newStatus = (tm.STATUS === 2 || tm.STATUS === 3) ? 1 : 2;
    // const newStatus = 1;

    this.updateTeamStatus(tm, newStatus, action, index);
  }

  dateAssignLocking(e) {
    if (e.date) {
      this.autoLockBool = false;

      if (this.unlockIndex !== -1 && e.date) {
        let tm = this.teams[this.unlockIndex];

        tm.DATE = e.date;

        if (!tm || tm.STATUS === 0 || tm.STATUS === -1 || tm.STATUS === 3)
          return;

        const action = tm.STATUS === 2 ? "unlock" : "lock";
        const newStatus = tm.STATUS === 2 ? 1 : 2;

        this.updateTeamStatus(tm, newStatus, action, this.unlockIndex);
      }
    } else {
      this.unlockIndex = -1;
      this.toastrService.warning("Feild Empty");
    }
  }

  setSubmitStatus(index: number) {
    const tm = this.teams[index];


    if (!tm) return;

    if (tm.STATUS !== 3) {
      this.index = index;
      this.forwhichcolumndateisvisible = 2;
      return;
    }

    let action = "";
    let newStatus = 4;

    this.updateTeamStatus(tm, newStatus, action, index);
  }

  adminSubmission(index: number) {
    const tm = this.teams[index];

    if (!tm) return;

    if (!(tm.STATUS === 3 || tm.STATUS === 4)) {
      this.forwhichcolumndateisvisible = 3;
      this.index = index;
      return;
    }

    let action = "submitAdmin";
    let newStatus = 5;

    this.updateTeamStatus(tm, newStatus, action, index);
  }

  setUnlocking(index: number, date: string) {
    const tm = this.teams[index];

    if (moment.isDate(date)) {
      return;
    }

    if (!tm) return;

    let action = "";
    let newStatus;

    if (tm.STATUS === 2 || tm.STATUS === 3) {
      newStatus = 1;
      action = "unlock";
    } else if (tm.STATUS === 4 || tm.STATUS === 5) {
      newStatus = 3;
      action = tm.STATUS === 5 ? "unsubmit" : "unlockEntry";
    }

    this.updateTeamStatus(tm, newStatus, action, index, date);
    this.index = -1;
  }

  publishAll() {
    if (
      this.lockForm.value.c_code === "" ||
      this.lockForm.value.se_id === "" ||
      this.lockForm.value.t_no === ""
    )
      return;

    let msg = "Do You Really want to Publish timetable for complete class!!";

    if (!confirm(msg)) return;

    const tm: tm = {
      C_CODE: this.lockForm.value.c_code,
      GRP: -1,
      SECTION: "",
      SE_ID: this.lockForm.value.se_id,
      STATUS: 0,
      SUB_CODE: "",
      T_NO: this.lockForm.value.t_no,
    };
    this.updateTeamStatus(tm, 1, "", -1);
  }

  unpublishAll() {
    if (
      this.lockForm.value.c_code === "" ||
      this.lockForm.value.se_id === "" ||
      this.lockForm.value.t_no === ""
    )
      return;

    let msg = "Do You Really want to Unpublish timetable for complete class!!";

    if (!confirm(msg)) return;
    const tm: tm = {
      C_CODE: this.lockForm.value.c_code,
      GRP: -1,
      SECTION: "",
      SE_ID: this.lockForm.value.se_id,
      STATUS: 1,
      SUB_CODE: "",
      T_NO: this.lockForm.value.t_no,
    };
    this.updateTeamStatus(tm, 0, "", -1);
  }

  lockAll() {
    if (
      this.lockForm.value.c_code === "" ||
      this.lockForm.value.se_id === "" ||
      this.lockForm.value.t_no === ""
    )
      return;

    let msg = "Do You Really want to Lock timetable for Complete class!!";

    if (!confirm(msg)) return;
    const tm: tm = {
      C_CODE: this.lockForm.value.c_code,
      GRP: -1,
      SECTION: "",
      SE_ID: this.lockForm.value.se_id,
      STATUS: 1,
      SUB_CODE: "",
      T_NO: this.lockForm.value.t_no,
    };
    this.updateTeamStatus(tm, 3, "lock", -1);
  }

  unlockAll() {
    if (
      this.lockForm.value.c_code === "" ||
      this.lockForm.value.se_id === "" ||
      this.lockForm.value.t_no === ""
    )
      return;

    let msg = "Do You Really want to Unlock timetable for all class!!";

    if (!confirm(msg)) return;

    const tm: tm = {
      C_CODE: this.lockForm.value.c_code,
      GRP: -1,
      SECTION: "",
      SE_ID: this.lockForm.value.se_id,
      STATUS: 2,
      SUB_CODE: "",
      T_NO: this.lockForm.value.t_no,
    };

    this.updateTeamStatus(tm, 1, "unlock", -1);
  }

  updateTeamStatus(
    tm: tm,
    newStatus: number,
    action: string,
    index: number,
    date?: string
  ) {
    this.updating = true;
    this.shiftingService
      .setStatus({ ...tm, newStatus, action, date })
      .subscribe(
        (res: tm[]) => {
          this.updating = false;
          if (res) {
            if (index === -1) {
              this.teams = res;
            } else {
              this.teams[index] = res[0];
            }
            this.toastrService.success("Updated Successfully");
          } else {
            this.toastrService.warning("Failed!, Try Again");
          }
        },
        (err) => {
          this.toastrService.error("Unknown Error");
          this.updating = false;
        }
      );
  }

  loadSessions(session: string) {
    this.sessions = [];
    this.sessionService.getSessions(this.f.c_code.value).subscribe(
      (res: { SE_ID: number; SE_NM: string }[]) => {
        res?.forEach((element) => {
          this.sessions.push({ id: element.SE_ID, nm: element.SE_NM });
        });
      },
      (err) => {
        this.toastrService.error("Unknown Error");
      }
    );
  }

  OnSectionChange() {
    this.teams = [];
  }
  closeEdit() {
    this.index = -1;
    this.getTeams();
  }
  OnClear() {
    this.teams = [];
    this.lockForm.reset();

  }

  OnGrpChange(grp: HTMLSelectElement) {
    this.teams = []
    this.lockForm.controls['c_code'].reset();
    this.lockForm.controls['se_id'].reset();
    this.lockForm.controls['t_no'].reset();
    this.lockForm.controls['section'].reset();
    this.loadClassess(grp.value);
  }

  OnSemesterChange(semester) {
    this.teams = []
    this.lockForm.controls['section'].reset();
  }

  OnSessionChange() {
    this.teams = []
    this.lockForm.controls['t_no'].reset();
    this.lockForm.controls['section'].reset();
  }

  OnClassChange(cource: HTMLSelectElement) {
    this.teams = []
    this.lockForm.controls['se_id'].reset();
    this.lockForm.controls['t_no'].reset();
    this.lockForm.controls['section'].reset();
    this.loadSessions(cource.value);
  }

  AutoLockTrue() {
    this.autoLockBool = true;
  }
  AutoLockFalse() {
    this.autoLockBool = false;
  }
}
