<div [@SlideInFromLeft] class="container-fluid">
  <div class="row card shadow" style="min-height: 80vh;">
    <div class="card-header"  style="padding-top: 10px;">
      <div *ngIf="updating" class="over">
        <div class="loader"></div>
        Loading...
      </div>
      <div class=" col-lg-6 col-md-6 form-group">
        Manage Locking</div> 
         <div *ngIf="(isAdmin || isExamCell)"  class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
        <app-details-button [details]="details"></app-details-button>
    </div>
    <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
      <app-details-button [details]="details1"></app-details-button>
  </div>
  </div>
    <form [formGroup]="lockForm">
      <div class="row card-body">

        <div class="col-lg-4 col-md-6 form-group" *ngIf="isAdmin || isExamCell">
          <label for="grp">Group</label>
          <!-- <div *ngIf="!isAdmin && !isExamCell">
            <input type="text" class="form-control" value='{{ ((this.groups.length>0) ? groups[0].nm:" ")}}' readonly>
            <input type="text" class="form-control" id="grp" formControlName="grp" hidden>
          </div> -->

          <select #grp class="form-control" name="grp" id="grp" (change)="OnGrpChange(grp)" formControlName="grp">
            <option *ngFor="let grp of groups" value="{{grp.id}}">{{grp.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="C_Code">Class</label>
          <select #cource (change)="OnClassChange(cource)" class="form-control " name="c_code" id="C_Code"
            formControlName="c_code">
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Session">Session</label>
          <select class="form-control " (change)="OnSessionChange()" name="se_id" id="Session" formControlName="se_id">
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="semester">Semester</label>
          <select #semester (change)="OnSemesterChange(semester)" class="form-control " name="t_no" id="Semester"
            formControlName="t_no">
            <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]" value="{{i}}">{{i}}</option>
          </select>
        </div>


        <div class="col-lg-4 col-md-6 form-group">
          <label for="Section">Section</label>
          <input type="text" (keydown)="OnSectionChange()" class="form-control" name="section" id="Section" formControlName="section"
            style="text-transform:uppercase">
        </div>

        <div class="col-lg-4 row btn_g1">
          <div><label for=""></label>
            <div class="row pl-4">
              <button type="button" class="btn btn-success m-2" (click)="getTeams()">Filter</button>
              <button type="button" class="btn btn-warning m-2" (click)="OnClear()">Clear</button>
            </div>
          </div>         
        </div>        
        <div *ngIf="isAdmin" class="row mt-2 ml-4 btns">
          <button *ngIf="this.teams.length>0"  type="button" class="btn btn-outline-warning m-2" [disabled]='updating' (click)="publishAll()">Publish
            Class</button>
          <button *ngIf="this.teams.length>0"  type="button" class="btn btn-outline-warning m-2" [disabled]='updating'
            (click)="unpublishAll()">Unpublish Class</button>
          <!-- <button type="button" class="btn btn-outline-warning m-2" [disabled]='updating' (click)="lockAll()">Lock
            Class</button>
          <button type="button" class="btn btn-outline-warning m-2" [disabled]='updating' (click)="unlockAll()">Unlock
            Class</button> -->

        </div>

      </div>
    </form>


    <ng-container *ngIf="loader then ifBlock"></ng-container>
    <ng-template #ifBlock>
        <div style="display:flex;justify-content:center; align-items: center;"><span
                class="loader"></span></div>
    </ng-template>


    <div *ngIf="this.teams.length>0" class="card text-left">
      <div class="card-header">       
        Teams
      </div>
      <div class="card-body">
        <table class="table table-bordered table-striped m-0 p-0">
          <thead class="thead-dark">
            <tr>
              <th scope="col"><h6>Details</h6></th>
              <th *ngIf="isAdmin" scope="col"><h6>Publish Timetable</h6></th>
              <th scope="col"><h6>Lock Attendance</h6></th>
              <th *ngIf="isAdmin || isExamCell" scope="col"><h6>Lock AwardList</h6></th>
              <th *ngIf="isAdmin || isExamCell" scope="col"><h6>Submit AwardList To Examination</h6></th>
              <th scope="col"><h6>Remarks</h6></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let t of teams; let i =index;">
              <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
              <td>
                <h5 class="mb-1">{{t.SUB_CODE}}-{{t.SUB_NM}} <b>({{t.SECTION}})</b></h5>
                <h6 class="mb-1">{{t?.F_NM || ''}} <small>({{t?.GRP_NM || ''}})</small></h6>
              </td>
              <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
              <td *ngIf="isAdmin">
                <label class="switch">
                  <input (click)="setPublishStatus(i)"
                    [checked]="t.STATUS===1||t.STATUS===2||t.STATUS===3||t.STATUS===4||t.STATUS===5||t.STATUS===6"
                    [disabled]="t.STATUS===2||t.STATUS===3||t.STATUS===4||t.STATUS===5||t.STATUS===6" type="checkbox">
                  <span class="slider"></span>
                </label>
              </td>

              <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
              <td>
                <label class="switch">
                  <input (click)="setLockStatus(i)"
                    [checked]="t.STATUS===2||t.STATUS===3||t.STATUS===4||t.STATUS===5||t.STATUS===6"
                    [disabled]="t.STATUS===0||t.STATUS===4||t.STATUS===5||t.STATUS===6" type="checkbox">
                  <span class="slider"></span>
                </label>

                <div *ngIf="index===i&&forwhichcolumndateisvisible===1" class=" d-flex  mt-3 mr-1 ml-2 mr-2"
                  style="justify-content: flex-end; align-items: center;">
                  <div class="m-2">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-sm">Unlock until</span>
                      </div>
                      <input #dt class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                        value="{{curdate()}}" type="date" min="{{curdate()}}">
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="m-3"><button class="btn btn-secondary"
                        (click)="setUnlocking(i, dt.value)">Unlock</button></div>
                    <div class="m-3"><button class="btn btn-outline-danger" (click)="closeEdit()">Close</button></div>
                  </div>
                </div>
              </td>
              <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->

              <td *ngIf="isAdmin || isExamCell">
                <label class="switch">
                  <input (click)="setSubmitStatus(i)" [checked]="t.STATUS===4||t.STATUS===5||t.STATUS===6"
                    [disabled]="t.STATUS===0||t.STATUS===1||t.STATUS===2||t.STATUS===5||t.STATUS===6" type="checkbox">
                  <span class="slider"></span>
                </label>
                <div *ngIf="index===i&&forwhichcolumndateisvisible===2" class=" d-flex  mt-3 mr-1 ml-2 mr-2"
                  style="justify-content: flex-end; align-items: center;">
                  <div class="m-2">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-sm">Unlock until</span>
                      </div>
                      <input #dt class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                        value="{{curdate()}}" type="date" min="{{curdate()}}">
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="m-3"><button class="btn btn-secondary"
                        (click)="setUnlocking(i, dt.value)">Unlock</button></div>
                    <div class="m-3"><button class="btn btn-outline-danger" (click)="closeEdit()">Close</button></div>
                  </div>
                </div>
              </td>

              <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
              <td *ngIf="isAdmin || isExamCell">


                <label class="switch">
                  <input (click)="adminSubmission(i)" [checked]="t.STATUS===5||t.STATUS===6"
                    [disabled]="t.STATUS===0||t.STATUS===1||t.STATUS===2||t.STATUS===4||t.STATUS===6" type="checkbox">
                  <span class="slider"></span>
                </label>

                <div *ngIf="index===i&&forwhichcolumndateisvisible===3" class=" d-flex  mt-3 mr-1 ml-2 mr-2"
                  style="justify-content: flex-end; align-items: center;">
                  <div class="m-2">
                    <div class="input-group input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="inputGroup-sizing-sm">Unlock until</span>
                      </div>
                      <input #dt class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                        value="{{curdate()}}" type="date" min="{{curdate()}}">
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="m-3"><button class="btn btn-secondary"
                        (click)="setUnlocking(i, dt.value)">Unlock</button></div>
                    <div class="m-3"><button class="btn btn-outline-danger" (click)="closeEdit()">Close</button></div>
                  </div>
                </div>
              </td>
              <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
              <td>
                <h6>{{ t.STATUS === 1 ? 'Timetable Published' : t.STATUS === 2 ? 'Attendance Locked' : t.STATUS === 3 ?
                  'Eligibility Calculated' : t.STATUS === 4 ? 'AwardList Locked' : t.STATUS === 5 ? 'AwardList
                  Submitted' : t.STATUS === 6 ? 'Result Compiled' : 'Timetable Drafted'}}
                </h6>
              </td>
              <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
            </tr>
          </tbody>
        </table>
      </div>

    </div>
