import { Component, OnInit, ViewChild } from '@angular/core';
import { jsPDF } from 'jspdf';
import { DatePipe } from "@angular/common";
import autoTable from "jspdf-autotable";
import "jspdf-autotable";
import { SlideInFromLeft } from 'src/app/transitions';
import { NgForm } from '@angular/forms';
import { SelectList } from '../../shared/model/SelectList.model';
import { reportsFeeService } from '../../shared/services/reportsFee.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'src/app/shared/functions/tableSearch';

@Component({
  selector: 'app-comination-wise-report',
  templateUrl: './comination-wise-report.component.html',
  styleUrls: ['./comination-wise-report.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class CominationWiseReportComponent implements OnInit {
  subscription = new Subscription();
  board: Array<SelectList>;
  classes: Array<SelectList>;
  sessions: Array<SelectList>;
  stdArr: Array<{ rolno, nm, add1, brd_rolno, mrks }>
  @ViewChild('f') formRef: NgForm;
  clss: string;
  bord: string;
  loader: boolean;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  you can view and download students board wise data with their board roll number and marks by selecting class
  ,board and session   </h6>
  <h6 class="text-right">آپ طلباء کا بورڈ وائز ڈیٹا ان کے بورڈ رول نمبر اور نمبروں کے ساتھ دیکھ اور ڈاؤن لوڈ کر سکتے ہیں، کلاس، بورڈ اور سیشن منتخب کر کے۔
</h6>
  `  ;
  constructor(
    private reportsFee: reportsFeeService,
    private datePipe: DatePipe,
    private feeSharedService: FeeSharedDataService,
    private toastr: ToastrService
  ) {
    this.stdArr = new Array<{ rolno, nm, add1, brd_rolno, mrks }>();
    this.loader = false;
  }

  ngOnInit(): void {
    this.updateClasses();
    this.updateBoard();
    this.updateSessions();
  }

  updateClasses() {
    this.classes = [];
    this.feeSharedService.getFeeClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateBoard() {
    this.board = [];
    this.feeSharedService.getFeeBoards()
      .then(boards => {
        this.board = boards;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateSessions() {
    this.sessions = []
    this.feeSharedService.getFeeSessions()
      .then(sessions => {
        this.sessions = sessions;
      })
      .catch(error => {
        console.error("Error getting fee session", error);
      });
  }

  getReport() {
    this.stdArr = [];
    this.loader = true
    this.subscription.add(
      this.reportsFee.combinationWiseStrengthReport(this.formRef.value).subscribe((res: any) => {
      res[0].forEach(el => {
        this.stdArr.push(el)
        this.loader = false
        if(this.stdArr?.length == 0) {
          this.toastr.info("No Data to Display");
        }
      })
    }))
  }

  CreatePDF() {
    var body = [];
    const header = [];
    let sr = 1;
    var exportDate = this.datePipe.transform(new Date(), 'MMM d, y')
    var image = new Image();
    var page;
    image.src = '../../../assets/images/logo3.png';

    header.push([
      { content: 'Sr #', styles: { fontStyle: "bold", halign: 'center' } },
      { content: 'Roll #', styles: { fontStyle: "bold", halign: 'center' } },
      { content: 'Name', styles: { fontStyle: "bold", align: 'center' } },
      { content: 'Address', styles: { fontStyle: "bold", align: 'center' } },
      { content: 'Board Roll#', styles: { fontStyle: "bold", halign: 'center' } },
      { content: 'Marks', styles: { fontStyle: "bold", align: 'center' } }]);

    const doc = new jsPDF('p', 'mm', 'A4');

    this.board.forEach(element => {
      if (element.id == this.formRef.value.brd) {
        this.bord = element.nm;
      }
    })

    this.classes.forEach(element => {
      if (element.id == this.formRef.value.c_code) {
        this.clss = element.nm;
      }
    });

    body = [];

    for (let i = 0; i < this.stdArr.length; i++) { 
      body.push([{content:i+1,styles:{fontStyle:'bold',halign:'center' }}, 
      {content:this.stdArr[i].rolno,styles:{fontStyle:'bold',halign:'center' }},
      {content:this.stdArr[i].nm,styles:{fontStyle:'bold'}},
      {content:this.stdArr[i].add1,styles:{fontSize:6.8 }}, 
      {content:this.stdArr[i].brd_rolno,styles:{fontStyle:'bold',halign:'center'}}, 
      {content:this.stdArr[i].mrks,styles:{fontStyle:'bold',halign:'center'}}]);
      sr++;
    }
   

    let yer = this.formRef.value.session;
    let c = this.clss;
    let b = this.bord;

    autoTable(doc, {
      theme: 'grid',
      startY: 50,
      margin: { top: 50, bottom: 10, left:7, right: 7 },
      headStyles:{
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontSize: 8,
        fontStyle: 'bold'
      },
      bodyStyles: {
        lineWidth: 0.1,
        lineColor:[0, 0, 0],
        fontSize: 7.5
      },
      columnStyles: {
        0: {cellWidth: 10},
        1: {cellWidth: 22},
        2: {cellWidth: 35}
      },
      head: header,
      body: body,

      didDrawPage: function () {
        page = '';
        page = doc.getNumberOfPages();
        let height = 20;
        doc.addImage(image, 175, 7, 25, 30);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 176, 42);
        doc.setFontSize(20);
        doc.setFont('Helvetica', 'bold');
        doc.text("GC UNIVERSITY, LAHORE", 105, height, { align: 'center' });
        doc.setFontSize(14);
        doc.setFont('Helvetica', 'bold');
        doc.text("BOARD WISE REPORT",  105, height+9, { align: 'center' });
        doc.text("____________________", 105, height+9, { align: 'center' });
         // Calculate the height of the text
         // var textHeight = doc.getTextDimensions("Board Wise Report").h;

  doc.setFontSize(12);
  doc.text("BOARD: " + b, 105, height+17.5, { align: 'center' });
  // Calculate the height of the text;
  doc.setFontSize(12);
  doc.text("CLASS: " + c + " (" + yer + ")", 105, height + 25, { align: 'center' });
  doc.setFontSize(14);
      }
    });

    var l = (body.length + 65) % 26;
    doc.setFontSize(8);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 100, 295);
      doc.text("Note:- Errors and Omissions are Excepted", 10, 295);
      doc.text("Directorate of Information Technology", 143, 295);
      var p = body.length;
    }
    window.open(URL.createObjectURL(doc.output('blob')), 'aa');
  }

  DownloadExcel() {
    const { maj_id } = this.formRef.value;
    let body = [];
    let header = [];
    let j = 1;
    header.push(['Sr. No.', 'Roll No.', 'Name', 'Address', 'Board Roll #', 'Board Marks']);
    this.stdArr.forEach(el => {
      body.push([j, el.rolno, el.nm, el.add1, el.brd_rolno, el.mrks]);
      j++;
    })
    new AngularCsv(body, "Set Wise Student Strength(" + this.formRef.value.c_code + ")", { headers: (header) });
  }

  ClearClassBase() {
    this.formRef?.controls['brd'].reset();
    this.formRef?.controls['session'].reset();
    this.loader = false
  }

  ClearBoardBase() {
    this.formRef?.controls['session'].reset();
    this.loader = false
  }

  Filter()
  {
    filter()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}