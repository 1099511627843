import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SelectList } from './../shared/model/SelectList.model';
import { NgForm } from '@angular/forms';
import { SlideInFromLeft } from './../../transitions';
import { SessionService } from './../shared/services/Sessions.service';
import { StudentService } from './../shared/services/Student.service';
import { DatePipe } from "@angular/common";
import { jsPDF } from "jspdf";
import autoTable, { UserOptions } from "jspdf-autotable";
import "jspdf-autotable";
import { AttendanceService } from '../attendance/attendance-services/attendance.service';
import { AuthenticationService } from 'src/app/auth/_services';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
interface IStudent {
  F_NM: string,
  MAJOR_NM: string,
  NM: string,
  ROLNO: string,
  SESSION_NM: string
}

interface IResult {
  FINAL_TERM: string,
  SECTION: string,
  SESSIONAL: string,
  SE_ID: string,
  SUB_CODE: string,
  SUB_NM: string,
  YEAR: string,
  T_NO: string
}

interface IAttendance {
  SECTION: string,
  SUB_CODE: string,
  SUB_NM: string,
  TOTAL: number,
  PRESENTS: string,
  ABSENTS: string,
  LEAVES: string,
  PERCENTAGE: string
}

@Component({
  selector: 'app-single-std-awardlist',
  templateUrl: './single-std-awardlist.component.html',
  styleUrls: ['./single-std-awardlist.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class SingleStdAwardlistComponent implements OnInit {
  classes: Array<SelectList>;
  sessions: Array<SelectList>;
  std: IStudent;
  result: IResult[];
  attendance: IAttendance[];

  @ViewChild('f') formRef: NgForm;

  usr = null;
  initialValue: number;
  heading: any;
  c_nm: string;


  constructor(
    private toastr: ToastrService,
    private sessionService: SessionService,
    private studentService: StudentService,
    private datePipe: DatePipe,
    private attendSer: AttendanceService,
    private authenticationService: AuthenticationService,
    private lmsSharedService: LMSharedDataService
  ) {
    this.classes = [];
    this.sessions = [];
    this.result = [];
    this.attendance = [];
    this.usr = this.authenticationService.getUser();
  }

  ngOnInit(): void {
    this.updateClasses();
  }

  onClassChange(c :any) {
    this.c_nm = this.getVal(this.classes, parseInt(c?.value));
    this.heading = c.options[c.selectedIndex].text;
   this.updateSessions();
  }

  updateSessions() {
    console.log("here")
    console.log(this.formRef.value.c_code)
    this.lmsSharedService.filterSessionByCode(this.formRef.value.c_code)
      .then(session => {
        this.sessions = session
        console.log(this.sessions)
        if (this.formRef) {
          this.initialValue = this.sessions[0]?.id;
          this.formRef?.controls['se_id'].setValue(this.initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }

  getVal(arr: Array<SelectList>, key: number): string {
    var result = ''
    arr?.forEach((element) => {
      if (element.id == key) {
        result = element.nm;
      }
    });
    return result;
  }
  // loadSessions() {
  //   this.sessions = [];
  //   const { c_code } = this.formRef.value;
  //   if (!c_code) return;
  //   this.sessionService.getSessions(c_code).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
  //     res?.forEach(entry => {
  //       this.sessions.push({ id: entry.SE_ID, nm: entry.SE_NM });
  //     });
  //   }, err => {
  //     console.log(err);
  //     this.toastr.error("Unknown Error");
  //   });
  // }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
    });
  }

  getResult() {
    this.attendance = [];
    const { c_code, se_id, t_no, year, rn } = this.formRef.value;
  console.log(c_code, se_id, t_no, year, rn );
    if (!c_code || !se_id || !t_no || !year || !rn) return;

    this.std = null;
    this.result = [];

    this.studentService.getSingleStdAwardlist({ c_code, se_id, t_no, year, rn })
      .subscribe((res: { warning: string, student: IStudent, result: IResult[] }) => {
        const { result, warning, student } = res;
        if (warning)
          this.toastr.warning(res.warning)

        this.std = student;
        this.result = result;
      }, err => {
        console.log(err);
        this.toastr.error("Unknown Error");
      })
  }
  getAttendance() {
    this.result = [];
    const { c_code, se_id, t_no, year, rn } = this.formRef.value;

    if (!c_code || !se_id || !t_no || !year || !rn) return;
    this.std = null;
    this.attendance = [];

    this.attendSer.getSingleStdAndAttendance({ c_code, se_id, t_no, year, rn })
      .subscribe((res: { warning: string, student: IStudent, att: IAttendance[] }) => {
        const { att, warning, student } = res;
        if (warning)
          this.toastr.warning(res.warning)

        this.std = student;
        this.attendance = att;
      }, err => {
        console.log(err);
        this.toastr.error("Unknown Error");
      })
  }
  attendancePdf() {
    var page;
    if (!this.std && !this.attendance?.length) {
      return;
    }

    let t = this.formRef.value.t_no;
    let nm = this.usr?.NM;

    let body = [];
    this.attendance.forEach((e, i) => {
      body.push([(i + 1), e.SUB_CODE, e.SUB_NM, e.SECTION, e.TOTAL, e.PRESENTS, e.ABSENTS, e.LEAVES, e.PERCENTAGE]);
    })
    let header;
    header = [['Sr. No.', "Sub Code", "Subject Title", "Section", "Lecture Delivered", 'Lecture Attended', 'Lecture Absented', 'Leaves', 'Percentage(%) Including Relaxation']];
    let image = new Image();
    image.src = "../../../assets/images/logo3.png";
    let exportDate = this.datePipe.transform(new Date(), "MMM d, y");
    const doc = new jsPDF("p", "mm", [297, 210]);
    doc.addImage(image, 175, 10, 25, 30);
    doc.setFontSize(10)
    doc.text(`${exportDate}`, 178, 45);
    doc.setFont("Arial", "bold");
    doc.setFontSize(24);
    doc.text('GC Universty Lahore', 102, 20, { align: 'center' });
    doc.setFontSize(18);
    doc.text('Attendance Report', 102, 28, { align: 'center' });
    doc.setFontSize(16);
    doc.text(`${this.std.MAJOR_NM}`, 102, 40, { align: 'center' });
    doc.setFontSize(14);
    doc.text(`Semester: ${t}`, 102, 50, { align: 'center' });
    doc.text(`Roll Number: ${this.std.ROLNO}`, 102, 60, { align: 'center' });
    doc.setFont("Arial", "bold");
    doc.text(`Name: ${this.std.NM}`, 13, 70);
    doc.text(`Father's Name: ${this.std.F_NM}`, 110, 70);

    autoTable(doc, {
      theme: "grid",
      headStyles: {
        textColor: [0, 0, 0]
        , fillColor: [255, 255, 255], lineWidth: 0.5
      },
      margin: { left: 13, top: 80, bottom: 30 },
      head: header,
      body: body,
      didDrawPage: function (d) {
        doc.setFontSize(10);
        doc.setFont("Arial", "normal");

        doc.text(`PRINTED BY: ${nm}`, 10, 286);
        doc.text("Note:- Errors and Omissions are Excepted", 10, 290);
        doc.text("Directorate of Information Technology", 143, 290);
      },

    });
    doc.setProperties({
      title: `Attendance Profile-Sem(${t})-${this.std.ROLNO}`,
    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }

  genratePdf() {

    if (!this.std && !this.result?.length) {
      return;
    }

    let body = [];
    this.result.forEach((e, i) => {
      body.push([(i + 1), e.SUB_CODE, e.SUB_NM, e.SECTION, e.SESSIONAL, e.FINAL_TERM, e.FINAL_TERM + e.SESSIONAL]);
    })
    let header;
    header = [['Sr. No.', "Sub Code", "Subject Title", "Section", "Sessional", 'Final term', 'Total']];
    let image = new Image();
    var exportDate = this.datePipe.transform(new Date(), 'MMM d, y')
    image.src = "../../../assets/images/logo3.png";
    const doc = new jsPDF("p", "mm", [297, 210]);
    doc.addImage(image, 175, 10, 25, 30);
    doc.setFontSize(10)
    doc.text(`${exportDate}`, 178, 45);
    doc.setFont("Arial", "bold");
    doc.setFontSize(24);
    doc.text('GC Universty Lahore', 102, 20, { align: 'center' });
    doc.setFontSize(18);
    doc.text('Student Marks Detail', 102, 30, { align: 'center' });
    doc.setFontSize(16);

    doc.text(`${this.std.MAJOR_NM}`, 102, 40, { align: 'center' });
    doc.text(`Semester: ${this.result[0]?.T_NO}`, 102, 50, { align: 'center' });
    doc.text(`Roll No: ${this.std.ROLNO}`, 102, 60, { align: 'center' });
    doc.setFont("Arial", "normal");
    doc.setFontSize(12);
    doc.text(`Name: ${this.std.NM}`, 13, 75);
    doc.text(`Father's Name: ${this.std.F_NM}`, 110, 75);

    autoTable(doc, {
      theme: "grid",
      headStyles: {
        textColor: [0, 0, 0]
        , fillColor: [255, 255, 255], lineWidth: 0.5
      },
      margin: { left: 13, top: 78, bottom: 30 },
      head: header,
      body: body,
      didDrawPage: function (d) {
        doc.setFontSize(10);
        doc.setFont("Arial", "normal");
        doc.text("Note:- Errors and Omissions are Excepted", 10, 290);
        doc.text("Directorate of Information Technology", 143, 290);
      },

    });
    // doc.save(`Awardlist-sem${this.result[0]?.T_NO}-${this.std.ROLNO}`);
    doc.setProperties({
      title: `Awardlist-sem${this.result[0]?.T_NO}-${this.std.ROLNO}`,

    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }

  reset() {
    this.sessions = [];
    this.attendance = [];
    this.result = [];
    this.std = null;
    this.formRef.reset();
  }

}
