<div class="main-card mb-3 card" [@SlideInFromLeft]>
  <div class="card-body" style="overflow: hidden; overflow-x: scroll">


    <div class="alert alert-info alert-dismissible">
      <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
      <strong>Please update your information in Profile Screen.</strong>
      <br>
      <div class="mt-2" style="text-align: right; margin-top: 2px;" dir="rtl">
        <p>
          <strong>براہ کرم پروفائل اسکرین میں اپنی معلومات کو اپ ڈیٹ کرلیں۔</strong>
        </p>
      </div>

    </div>


    <span class="ri8">
      <button *ngIf="btnHide" class="btn btn-sm btn-outline-primary ml-2" (click)="CreatePdf()"
        type="button">Create PDF
      </button>
    </span>
    <h5 class="card-title">Time Table</h5>
    <span #convert>
      <table class="mb-0 table table-bordered">
        <thead class="bg-light">
          <tr>
            <th>Day</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let day of timetableData">
            <ng-container *ngFor="let lecture of day; let i = index">
              <th *ngIf="i === 0" scope="row" class="fix-size">{{lecture.DAY}}</th>
              <td>
                <div class="container-fluid max-width-height text-center pb-4 pt-2">
                  <ul class="time-table-column">
                    <li><b>{{ '1970-01-01 '+ lecture.START_TIME | date: 'h:mm a'}}-{{ '1970-01-01 '+ lecture.END_TIME|date:'h:mm a'}}</b></li>
                    <li>BLOCK: {{lecture.BLK_NM}}</li>
                    <li>{{lecture.ROOM_NM}}</li>
                    <li>{{lecture.SUB_CODE}}</li>
                    <li>{{lecture.SUB_NM}}</li>
                    <li>SECTION: <b>{{lecture.SECTION}}</b></li>
                    <li><b>SEMESTER: {{lecture.T_NO}}</b></li>
                    <li>CLASS: {{lecture.C_NM}}</li>
                  </ul>
                </div>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </span>
  </div>
</div>