import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AddteacherComponent } from './../register/addteacher/addteacher.component';
import { SlideInFromLeft } from '../../transitions';
import { UtilService } from 'src/app/Util.service';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { StudentService } from '../shared/services/Student.service';
import { SessionService } from './../shared/services/Sessions.service';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { AttendanceRelaxationService } from '../attendance-relaxation/attendance-relaxation.service';
import { ToastrService } from 'ngx-toastr';
import { jsPDF } from "jspdf";
import autoTable, { UserOptions } from 'jspdf-autotable';
import * as moment from 'moment';
import 'jspdf-autotable';

import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import { DatePipe } from '@angular/common';
interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

interface stdInfo {
  nm: string,
  nic: string,
  year: number,
  c_code: number,
  class: string,
  d_id: number,
  deptt: string,
  set_no: number,
  maj_id: number,
  major: string,
  rn: number,
  se_id: number,
  session: string,
  f_nm: string,
  email: string,
  add1: string,
  ph1: string,
  ph2: string,
  gender: string,
  relig: string,
  rolno: string,
  set_nm: string,
  f_no: string,
  reg_no: string
}

interface EnrollInfo {
  sub_code: string,
  subject: string,
  t_no: number,
  section: string,
  se_id: number,
  session: string
}

interface SubInfo {
  sub_code: string,
  subject: string,
  set_no: number,
  t_no: number
}

export interface Timetable {
  STATUS: number;
  GRP: string;
  G_NM: string;
  C_CODE: number;
  C_NM: string;
  SE_ID: string;
  T_NO: string;
  SECTION: string;
  SUB_CODE: string
  SUB_NM: string;
  DAY: string;
  END_TIME: string;
  ROOM_NM: string;
  START_TIME: string;
  FM_NM: string;
  BLK_NM: string;
  FM_NAME: string;
}

@Component({
  selector: 'app-get-student-detail-pop-up',
  templateUrl: './get-student-detail-pop-up.component.html',
  styleUrls: ['./get-student-detail-pop-up.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class GetStudentDetailPopUpComponent implements OnInit {
  @Input() rolno: string
  @Input() c_code_in: string
  @Output() closeStdDetail = new EventEmitter<void>();
  canManageTT: boolean = false;
  isExamCell: boolean = false;
  canStudentDetail: boolean = false;
  onDepartmentAccess: boolean = false;
  isAdmin: boolean = false;
  dept: number = 0;
  group: number = 0;
  usr: string = null;
  t_no_in;
  hideInput: boolean = false;
  array = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  sessions: Array<SelectList>;
  classes: Array<SelectList>;
  show: boolean = false;
  show1: boolean = false;
  isReadOnly: boolean = true;

  sendEnroll: EnrollInfo;
  sendSTD: stdInfo;
  getDetBtn: boolean = false;
  @ViewChild('fn') usnmRef: NgForm;
  studentInfo: Array<stdInfo>;
  enrollInfo: Array<EnrollInfo>;
  subInfo: Array<SubInfo>;
  rolnoArr: Array<SelectList>;
  genderArr: Array<{ id: string, value: string }>;
  rollno: string;


  constructor(private authenticationService: AuthenticationService,
    private getServe: AttendanceRelaxationService,
    private datePipe: DatePipe,
    private sessionService: SessionService,
    private studentService: StudentService,
    private utilService: UtilService,
    private toastr: ToastrService,
    private classService: ClassService,

  ) {
    this.dept = this.authenticationService.getUser().D_ID;
    this.canManageTT = this.authenticationService.canManageTimetable();
    this.isAdmin = this.authenticationService.isAdmin();
    this.isExamCell = this.authenticationService.isExamCell();
    this.group = this.authenticationService.getGroup();
    this.canStudentDetail = this.authenticationService.seeStudentDetail();
    this.sessions = new Array<SelectList>();
    this.classes = new Array<SelectList>();
    this.studentInfo = new Array<stdInfo>();
    this.enrollInfo = new Array<EnrollInfo>();
    this.subInfo = new Array<SubInfo>();
    this.rolnoArr = new Array<SelectList>();
    this.sendEnroll = null;

  }

  ngOnInit(): void {

    this.onDepartmentAccess = this.canManageTT && !this.canStudentDetail;
    if (this.onDepartmentAccess) {
      this.loadClassess(this.group.toString());
    } else if (this.isExamCell) {
      this.loadClassess('-1');
      this.group = -1;
    }
    else if (this.canStudentDetail) {
      this.loadClassess('-1');
      this.group = -1;
    }
    this.updateSessions();
    this.getUser();
  }
  OnClose() {
    this.closeStdDetail.emit();
  }
  loadClassess(grp: string) {
    let param = {};

    param['grp'] = grp;

    this.classService.getClasses(param).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
    });
  }
  OnClassChange() {
    this.rolnoArr = [];
    this.getDetBtn = false;
    this.updateSessions();
  }
  updateSessions() {
    this.sessions = [];
    this.rolnoArr = [];
    this.getDetBtn = false;
    if (this.c_code_in === "") return;

    this.sessionService.getSessions(this.c_code_in).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(entry => {
        this.sessions.push({ id: entry.SE_ID, nm: entry.SE_NM });
      });
    });
  }
  getUser() {
    //this.name = null;
    this.studentInfo = [];
    this.enrollInfo = [];
    this.subInfo = [];
    if (this.rolno.includes('-')) {
      this.studentService.getStudentInfoDetail(this.rolno).subscribe((res: stdInfo) => {
        if (!res) {
          this.hideInput = false;
          this.toastr.info('Student Details not found.');
          return;
        }

        this.studentInfo.push(res);
        this.hideInput = true;
      });
    }
    else {
      this.toastr.warning('Roll Number Should be Correct.');
      this.hideInput = false;
    }
  }
  onSubmit(name, fName, cnic, email, add1, ph1, ph2, gender, religion) {
    let obj = this.usnmRef.value;

    this.studentService.updateStudentDetailADM({
      c_code: obj?.c_code, se_id: obj?.se_id, rollNo: obj?.rolno, name: name.value,
      fatherName: fName.value, cnic: cnic.value, email: email.value, add1: add1.value, ph1: ph1.value, ph2: ph2.value,
      gender: gender.value, religion: religion.value
    }).subscribe((res: { affectedRows: number }) => {
      if (res?.affectedRows != 0) {
        this.toastr.success('Updated Information Successfullly.');
      }
      else {
        this.toastr.info('No Change any Field.');
      }
    });
    this.studentService.updateStudentDetailADMSFC({
      c_code: obj?.c_code, se_id: obj?.se_id, rollNo: obj?.rolno, name: name.value, fatherName: fName.value, cnic: cnic.value,
      email: email.value, add1: add1.value, ph1: ph1.value, ph2: ph2.value, gender: gender.value, religion: religion.value
    }).subscribe((res: any) => {
      this.toastr.success(res.msg)
    });
  }
  showDetail(s: EnrollInfo) {
    this.sendSTD = this.studentInfo[0];
    this.sendEnroll = s;
    this.show = true;
  }

  showDetail1(s: EnrollInfo) {
    this.sendSTD = this.studentInfo[0];
    this.sendEnroll = s;
    this.show1 = true;
  }


  closeIt() {
    this.show = false;
  }


  closeIt1() {
    this.show1 = false;
  }

  checkEnroll() {
    this.enrollInfo = [];
    this.subInfo = [];
    this.studentService.checkEnroll(this.studentInfo[0]?.year, this.studentInfo[0]?.c_code, this.usnmRef.value?.se_id_new, this.studentInfo[0]?.maj_id, this.studentInfo[0]?.rn, this.usnmRef.value?.t_no)
      .subscribe((res: [EnrollInfo[]]) => {

        if (!res[0]) {
          this.toastr.info('Student not enroll in current Semester');
          return;
        }
        res[0].forEach((entry) => {
          this.enrollInfo.push(entry);
        });
        if (this.enrollInfo?.length == 0) {
          this.toastr.warning('No record Avaialable');
        }
      }, err => {
        this.toastr.error('Student not enroll in current Semester');
      });
  }

  checkSubOffer() {
    this.enrollInfo = [];
    this.subInfo = [];
    // if (this.usnmRef.value.rolno.includes('-')) {
    this.studentService.checkSubOffer(this.studentInfo[0]?.c_code, this.usnmRef.value?.se_id_new, this.studentInfo[0]?.maj_id, this.usnmRef.value?.t_no).subscribe((res: SubInfo[]) => {
      if (!res) {
        // this.hideInput=false;
        this.toastr.info('No Subject is offered');
        return;
      }
      res.forEach((entry) => {
        this.subInfo.push(entry);
      });
      if (this.subInfo?.length == 0) {
        this.toastr.warning('No record Avaialable');
      }
    }, err => {
      this.toastr.error('Unknown Error');
    });
  }

  loadRollno() {
    this.rolnoArr = []
    if (!this.usnmRef.value?.se_id) {
      return;
    }
    let param = { c_code: this.usnmRef.value?.c_code, se_id: this.usnmRef.value?.se_id_new }
    this.studentService.getStudentsRollNumbersForDetail(param).subscribe((
      res: { RN: number, ROLNO: string }[]) => {
      if (res?.length == 0) {
        this.getDetBtn = false;
      }
      else {
        this.getDetBtn = true;
      }
      res?.forEach(element => {
        this.rolnoArr.push({ id: element.RN, nm: element.ROLNO });
      });
    });
  }

  downloadTimetable() {
    var s = {
      ROLNO: this.rolno,
      NM: this.studentInfo[0]?.nm,
      C_CODE: this.studentInfo[0]?.c_code,
      SE_ID: this.studentInfo[0]?.se_id,
      MAJ_ID: this.studentInfo[0]?.maj_id,
      RN: this.studentInfo[0]?.rn,
      YEAR: this.studentInfo[0]?.year
    };
    this.studentService.getTimeTableForPDF(s).subscribe(
      (res: [[{ f_nm, SE_NM, M_ID, T_NO, SUB_COMB }], Timetable[]]) => {
        const liveTimetable = res[1];

        const monday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('monday'));
        const tuesday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('tuesday'));
        const wednesday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('wednesday'));
        const thursday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('thursday'));
        const friday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('friday'));
        let timetable: [Timetable[], Timetable[], Timetable[], Timetable[], Timetable[]];

        timetable = [monday, tuesday, wednesday, thursday, friday];

        const { f_nm: F_NM, SE_NM, M_ID, T_NO, SUB_COMB } = res[0][0]
        this.CreatePdf({ F_NM, SE_NM, M_ID, T_NO, SUB_COMB, NM: s.NM, ROLNO: s.ROLNO }, this.getDaysFromTM(timetable), this.sortByStartTimeTable(timetable));
      });
  }

  private sortByStartTimeTable(tm: [Timetable[], Timetable[], Timetable[], Timetable[], Timetable[]]) {
    for (var i = 0; i < tm?.length; i++) {
      tm[i]?.sort((a, b) =>
        a.START_TIME < b.START_TIME ? -1 :
          a.START_TIME > b.START_TIME ? 1 : 0
      )
    }
    return tm;
  }
  private getDaysFromTM(tm: [Timetable[], Timetable[], Timetable[], Timetable[], Timetable[]]) {
    let dayList: string[] = [];
    for (var i = 0; i < tm?.length; i++) {
      if (tm[i].length > 0)
        dayList.push(tm[i][0].DAY);
    }
    return dayList;
  }

  CreatePdf(std: { F_NM, SE_NM, M_ID, T_NO, SUB_COMB, ROLNO, NM }, days, tm: [Timetable[], Timetable[], Timetable[], Timetable[], Timetable[]]) {
    var body = [];
    var header = [];
    if (days.length == 0) {
      this.toastr.warning('Time Table Not Exist.')
      return;
    }
    // this.toastr.info("Downloading TimeTable");
    var image = new Image();
    image.src = '../../../assets/images/logo3.png';
    const doc = new jsPDF('p', 'mm', [297, 210]);
    doc.addImage(image, 15, 1, 20, 20);
    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y')
    doc.setFontSize(7);
    doc.text(`${exportDate}`, 18, 24);
    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.text("GC UNIVERSITY LAHORE", 40, 8);
    doc.text("Time Table", 40, 14);
    doc.setFontSize(11);

    let clas = std.M_ID;
    let sess = std.SE_NM || '';
    let nam = std.NM.toUpperCase();
    let fnam = std.F_NM.toUpperCase();
    let rol = std.ROLNO.toUpperCase();
    let t = std.T_NO;
    let sub_comb = std.SUB_COMB;

    doc.text(clas, 40, 19);
    doc.setFont('Arial', 'normal');
    doc.text('Session: ' + sess, 40, 24);
    if (this.c_code_in == '1') {
      doc.text("Year : 1", 80, 24);
    } else {
      doc.text("Semester: " + t, 80, 24);
    }
    doc.text("Subject Combination: " + sub_comb, 40, 29);
    doc.setFont('Arial', 'bold');
    doc.setFontSize(10);
    doc.text("Roll No: " + rol, 135, 8);
    doc.text("Name: " + nam, 135, 13);
    doc.text("Father Name: " + fnam, 135, 18);
    var y_val = 0;
    for (var i = 0; i < days.length; i++) {//DAYS
      header.push(
        [
          { content: days[i], colSpan: 7, styles: { halign: 'left', textColor: [0, 0, 0], fontSize: 7, lineColor: [0, 0, 0], fillColor: [256, 256, 256] } }
        ],
        ['Time', 'Block', 'Room', 'SubCode', 'Subject Name', 'Teacher Name', 'Sec']);

      y_val = tm[i].length + 2;

      for (var j = 0; j < tm.length; j++) {//MAJOR ARRAY
        for (var k = 0; k < tm[j].length; k++) {//INNER ARRAY

          if (days[i] == tm[j][k]?.DAY) {
            var obj = tm[j][k];
            var tt = moment(obj?.START_TIME, 'h:mmA').format('hh:mmA') + '-' + moment(obj?.END_TIME, 'h:mmA').format('hh:mmA');

            body.push([tt, obj?.BLK_NM, obj?.ROOM_NM, obj?.SUB_CODE,
              obj?.SUB_NM, obj?.FM_NAME, obj?.SECTION]);
          }
        }
      }
      autoTable(doc, {
        styles: {
          textColor: [0, 0, 0],
          fontSize: 6,
          minCellHeight: 4,
          cellPadding: 1
        },
        theme: 'grid',
        margin: { top: 30, bottom: 0 },
        head: header,
        body: body,
      });
      body = [];
      header = [];
    }

    var y_Axis = 265;
    var x_axis = 14;
    doc.text("Note :", x_axis, y_Axis);
    doc.setFontSize(8).setFont('Times-Roman', 'Bold');
    doc.setTextColor(0, 0, 0);
    doc.text("1.Dars-e-Quran/Tutorial Group Meeting on Friday from 10:30 am to 11:00 am. There will be no Theory/Practical Class on Friday from 10:30 am to 11:10 am", x_axis, y_Axis + 4);
    doc.setTextColor(0, 0, 0);
    doc.text("2.The Duration of each class can be different for different subject depending upon their credit hours.", x_axis, y_Axis + 8);
    doc.text("3.Consult Concerned Chairperson if Teacher is not in class room ", x_axis, y_Axis + 12);
    doc.text("4.In case any query, contact Prof. Inam ul Haq(Incharge General Time Table) in Statistics Department PGB-313 from 09:00 am to 10:30 am.", x_axis, y_Axis + 16);
    doc.text("5.Always keep a photocopy of time table in your possession during academic year, as no duplicate time table will be issued.", x_axis, y_Axis + 20);
    doc.text("* University reserves the right to amend Time Table at any time according to availability of faculty,space and combination.", x_axis, y_Axis + 24);


    doc.setFontSize(7).setFont('Times-Roman');
    doc.setFont('Arial', 'normal');
    doc.text("By:Directorate of Information Technology", 15, 295);
    doc.text('Note: Errors and Omissions are Excepted', 155, 295);

    doc.setProperties({
      title: `Time Table  ${std.ROLNO}`,

    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
    // doc.save('Time Table ' + std.ROLNO);
  }
}
