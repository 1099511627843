<div [@SlideInFromLeft] class="tabber">
    <div class="container-fluid card shadow">
        <div class="card-header">
            <div class="over">
                <div class="loader">
                </div>
                <!-- Loading... -->
            </div>
            <div>Signature For Degree Holders</div>
        </div>
        <form #f="ngForm" class="row">
            <div class="row card-body space-remover">

                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="c_code">Class</label>
                    <select #c_code class="form-control" ngModel name="c_code" id="c_code" required
                        (change)="OnClassChange()">
                        <option *ngFor="let i of classes" value="{{i.id}}"> {{i.nm}} </option>
                    </select>
                </div>
                <!-- <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="se_id">Sessions</label>
                    
                    <select #se_id class="form-control"  name="se_id" id="se_id" required ngModel>
                        <option *ngFor="let s of session" value="{{s.id}}">{{s.nm}}</option>
                    </select>

                </div> -->
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="maj_id" class="text-left">Major
                    </label>
                    <select #maj_id id="maj_id" name="maj_id" ngModel class="form-control" (change)="OnMajorChange()">
                        <option *ngFor="let i of majors" value="{{i.id}}">{{i.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                    <label for="notifDate">Start Date Notification</label>
                    <input type="date" #notifDate class="form-control" ngModel name="notifDate" id="notifDate" required
                        ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                    <label for="notifDate1">End Date Notification</label>
                    <input type="date" #notifDate class="form-control" ngModel name="notifDate1" id="notifDate1"
                        required ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="se_st_year"> Convocation No. & year</label>
                    <input type="text" #convo class="form-control" name="convo" id="convo"
                        placeholder=" For Example: 13th Convocation 2014/2015" ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="category">Degree Category</label>
                    <select #category class="form-control" name="category" ngModel id="category" required>
                        <option>Regular</option>
                        <option>Urgent</option>
                        <option>Duplicate</option>
                    </select>
                </div>
                <div class="test ml-3 d-flex flex-column" style="margin-top:28px">
                    <!-- [disabled]="!f.valid" -->
                    <button class="btn btn-outline-primary" type="button" (click)="OnCategoryChange()" [disabled]="!f.valid">Display Data
                    </button>
                </div>

                <div class="test ml-3 d-flex flex-column" style="margin-top:28px">
                    <!-- [disabled]="!f.valid" -->
                    <button class="btn btn-danger" type="button" (click)="CreatePdf()" [disabled]="!f.valid">
                        <i class="fa fa-file-pdf-o" for=""></i>
                        Download Pdf
                    </button>
                </div>
                <div class="test ml-3">
                    <!-- <button [disabled]="!f.valid" class="btn btn-outline-primary btn-sm" type="submit">Generate Trans.
                    </button> -->
                    <button class="btn btn-warning ml-1" style="margin-top:28px" (click)="OnClear(f)"
                        type="button">Clear Form
                    </button>
                </div>
            </div>
        </form>
        <div class="px-4">
            <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1" *ngIf="isOpen && Details.length>0">  
                <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
            </div>
            <table class="table table-bordered table-striped mt-4"
                *ngIf="isOpen && Details.length>0" id="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Sr #</th>
                        <th scope="col">Roll No</th>
                        <th scope="col">Name</th>
                        <th scope="col">Father Name</th>
                        <th scope="col">Registration No</th>
                        <th scope="col">Degree No</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let detail of Details; let i=index" id="values">
                        <td>{{i+1}}</td>
                        <td>{{detail.rollno}}</td>
                        <td>{{detail.Name}}</td>
                        <td> {{detail.F_name }} </td>
                        <td>{{detail.Reg_no }}</td>
                        <td>{{detail.Deg_no }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>