import { Component, OnInit } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-enrepo',
  templateUrl: './enrepo.component.html',
  styleUrls: ['./enrepo.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class EnrepoComponent implements OnInit {

  constructor(private router:Router,private route:ActivatedRoute) { }

  ngOnInit(): void {
  } 
  AttendanceReportClick(){
    this.router.navigate(['AttendanceReport'], { relativeTo: this.route });
  }
  
}
