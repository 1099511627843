import { Component, OnInit, ViewChild } from "@angular/core";
import { SlideInFromLeft } from "src/app/transitions";
import { ToastrService } from "ngx-toastr";
import { NgForm } from "@angular/forms";
import { AuthenticationService } from "src/app/auth/_services/authentication.service";
import { SelectList } from "../../shared/model/SelectList.model";
import { ClassService } from "../../shared/services/Class.service";
import { reportsFeeService } from "../../shared/services/reportsFee.service";
import { jsPDF } from "jspdf";
import { DatePipe } from "@angular/common";
import autoTable from "jspdf-autotable";
import "jspdf-autotable";
import { SessionService } from "./../../shared/services/Sessions.service";
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import { MajorService } from "../../shared/services/Major.service";
import { filter } from "src/app/shared/functions/tableSearch";
@Component({
  selector: "app-student-general-info",
  templateUrl: "./student-general-info.component.html",
  styleUrls: ["./student-general-info.component.css"],
  animations: [SlideInFromLeft()],
})

export class StudentGeneralInfoComponent implements OnInit {
  classDT: Array<SelectList>;
  isAdmin: boolean = false;
  allData: Array<{ rn; f_no; name; f_name;gender; address; phone; g_phone; group }>;
  @ViewChild("f") formRef: NgForm;
  majors: any;
  sessions: { id: number; year: number; nm: string }[] = [];
  year = 0;
  c_code = 0;
  maj_id = 0;
  se_id = 0;
  stdArr: any;
  cclass = "";
  mmajor = "";
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  you can view and download the general information of students.If you require the major wise data then 
  select major otherwise choose class and session. </h6>
  <h6 class="text-right">آپ طلباء کی عمومی معلومات دیکھ اور ڈاؤن لوڈ کر سکتے ہیں۔ اگر آپ کو میجر وائز ڈیٹا چاہیے تو میجر منتخب کریں ورنہ کلاس اور سیشن منتخب کریں۔
</h6>
  `  ;

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private classService: ClassService,
    private sessionService: SessionService,
    private datePipe: DatePipe,
    private reportsFee: reportsFeeService,
    private majorService: MajorService
  ) {
    this.classDT = new Array<SelectList>();
    this.allData = new Array<{
      rn;
      f_no;
      name;
      f_name;
      gender;
      address;
      phone;
      g_phone;
      group;
    }>();
    this.isAdmin = this.authenticationService.isAdmin();
  }
  loader = false;
  ngOnInit(): void {
    this.classDT = [];
    this.classService
      .getAllClassesFeeServer()
      .subscribe((res: { c_code: number; Des: string }[]) => {
        res?.forEach((entry) => {
          this.classDT.push({
            id: entry.c_code,
            nm: entry.Des,
          });
        });
      });
  }

  getData(s: HTMLSelectElement) {
    this.loader = true;
    this.se_id = parseInt(s.value);
    let i = 0;
    for (i = 0; i < this.sessions.length; i++) {
      if (this.sessions[i].id == this.se_id) this.year = this.sessions[i].year;
    }
    this.allData = [];

    this.reportsFee
      .onStudentGenInfoReport(
        this.formRef.value.c_code,
        this.formRef.value.maj_id,
        this.se_id,
        this.year
      )
      .subscribe(
        (
          res: {
            rn: number;
            f_no: number;
            nm: string;
            f_nm: string;
            gender:string;
            addr: any;
            ph: string;
            gph: string;
            grp: string;
          }[]
        ) => {
          res?.forEach((element) => {
            this.allData.push({
              rn: element.rn,
              f_no: element.f_no,
              name: element.nm,
              f_name: element.f_nm,
              gender: element.gender,
              address: element.addr,
              phone: element.ph,
              g_phone: element.gph,
              group: element.grp,
            });
            this.loader = false;
          });
        }
      );
  }

  OnMajorChange(m: HTMLSelectElement) {
    // this.updateSessions();
  }
  getSessions() {
    this.sessions = [];
    this.sessionService
      .getSessions(this.formRef.value.c_code)
      .subscribe(
        (res: { SE_ID: number; SE_ST_YEAR: number; SE_NM: string }[]) => {
          res?.forEach((entry) => {
            this.sessions.push({
              id: entry.SE_ID,
              year: entry.SE_ST_YEAR,
              nm: entry.SE_NM,
            });
          });
        }
      );
  }

  OnSubmit() {}

  onPdfDownload(c: HTMLSelectElement) {

    this.maj_id = this.formRef.value.maj_id||0;
    const that = this;
    if (this.allData?.length == 0) {
      this.toastr.warning("No Record Found");
      return;
    } else {
      const body = [];
      const header = [];
      var ssessions = "";
      var groupMajors = "";

      var exportDate = this.datePipe.transform(new Date(), "MMM d,y");
      var image = new Image();
      var page;
      var sessionYear = this.formRef.value.se_id;
      for (i = 0; i < this.sessions.length; i++) {
        if (this.sessions[i].id == this.se_id) ssessions = this.sessions[i].nm;
      }
      var majors = this.formRef.value.maj_id;
      for (i = 0; i < this.majors.length; i++) {
        if (this.majors[i].id == this.maj_id) groupMajors = this.majors[i].nm;
      }
      console.log(this.maj_id);
      console.log(groupMajors);

      var inst = this.formRef.value.inst_no;
      var groupClass = this.classDT[c.selectedIndex].nm;
      image.src = "../../../assets/images/logo3.png";

      header.push([
        { content: "Sr #", styles: { fontStyle: "bold", halign: "center" } },
        { content: "Roll #", styles: { fontStyle: "bold", halign: "center" } },
        {
          content: "Form Number",
          styles: { fontStyle: "bold", halign: "center" },
        },
        { content: "Name", styles: { fontStyle: "bold", halign: "center" } },
        {
          content: "Father Name",
          styles: { fontStyle: "bold", halign: "center" },
        },
        { content: "Gender", styles: { fontStyle: "bold", halign: "center" } },
        { content: "Address", styles: { fontStyle: "bold", halign: "center" } },
        { content: "Phone", styles: { fontStyle: "bold", halign: "center" } },
        {
          content: "Guardian's Phone",
          styles: { fontStyle: "bold", halign: "center" },
        },
        {
          content: "Major",
          styles: { fontStyle: "bold", halign: "center" },
        },
      ]);

      const doc = new jsPDF("l", "mm", "A4");

      for (let i = 0; i < this.allData.length; i++) {
        body.push([
          { content: i + 1, styles: { fontStyle: "bold", halign: "center" } },
          {
            content: this.allData[i].rn,
            styles: { fontStyle: "bold", halign: "center" },
          },
          {
            content: this.allData[i].f_no,
            styles: { fontStyle: "bold", halign: "center" },
          },
          {
            content: this.allData[i].name,
            styles: { fontStyle: "bold", halign: "center" },
          },
          {
            content: this.allData[i].f_name,
            styles: { fontStyle: "bold", halign: "center" },
          },
          {
            content: this.allData[i].gender,
            styles: { fontStyle: "bold", halign: "center" },
          },
          { 
            content: this.allData[i].address,
            styles: { halign: "center", fontSize: 6 },
          },
          { content: this.allData[i].phone, styles: { halign: "center" } },
          { content: this.allData[i].g_phone, styles: { halign: "center" } },
          {
            content: this.allData[i].group,
            styles: { fontStyle: "bold", halign: "center" },
          },
        ]);
      }

      console.log(this.maj_id)
      autoTable(doc, {
        theme: "grid",
        startY: 55,
        margin: { top: 55, bottom: 10, left: 7, right: 7 },
        styles: { fontSize: 11 },
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
          fontSize: 8,
          fontStyle: "bold",
        },
        bodyStyles: {
          lineWidth: 0.1,
          lineColor: [0, 0, 0],
          fontSize: 7.5,
        },
        columnStyles: {
          0: { cellWidth: 9 },
          1: { cellWidth: 16 },
          3: { cellWidth: 22 },
          4: { cellWidth: 22 },
        },
        head: header,
        body: body,
        didDrawPage: function () {
          
    
          doc.addImage(image, 255, 7, 25, 30);
          doc.setFontSize(10);
          doc.text(`${exportDate}`, 256, 42);
          doc.setFontSize(20);
          doc.setFont("Helvetica", "bold");
          doc.text("GC UNIVERSITY, LAHORE", 145, 20, { align: "center" });
          doc.setFontSize(14);
          doc.setFont("Helvetica", "bold");
          doc.text("STUDENT GENERAL INFORMATION ", 145, 29, {
            align: "center",
          });
          doc.text("________________________________", 145, 30, {
            align: "center",
          });
          doc.setFontSize(12);
          doc.text("SESSION: " + ssessions, 145, 37, { align: "center" });
          doc.setFontSize(12);
          doc.text("CLASS: " + groupClass, 145, 44, { align: "center" });

          doc.setFontSize(12);
          if (that.maj_id) {
            doc.setFontSize(12);
            doc.text("MAJOR: " + groupMajors, 145, 51, { align: "center" });
          }
          page = "";
          page = doc.getNumberOfPages();
        },
      });
      var l = (body.length + 65) % 26;
      doc.setFontSize(8);
      for (var i = 1; i <= page; i++) {
        doc.setPage(i);
        doc.text("Page " + i + " of " + page, 100, 295);
        doc.text("Directorate of Information & Technology", 10, 295);
        doc.text("Note:- Errors and Omissions Excepted", 150, 295);
        var p = body.length;
      }
      window.open(
        URL.createObjectURL(doc.output("blob")),
        "Student_General_Info(" + sessionYear + ")"
      );
    }
  }

  onClear() {
    this.formRef.reset();
    this.allData = [];
    this.loader = false;
  }

  ClearClassBase() {
    // this.formRef?.controls['year'].reset();
  }

  OnClassChange(c: HTMLSelectElement) {
    this.getMajors();
    this.getSessions();
  }
  OnSessionChange(session) {
    this.getMajors();
  }

  getMajors() {
    this.majors = [];
    this.majorService
      .getMajor({ c_code: this.formRef.value.c_code })
      .subscribe((res: { MAJ_ID: number; MAJ_NM: string }[]) => {
        res?.forEach((entry) => {
          this.majors.push({ id: entry.MAJ_ID, nm: entry.MAJ_NM });
        });
      });
  }

  DownloadExcel(c: HTMLSelectElement, m: HTMLSelectElement) {
    let body = [];
    let header = [];
    let j = 1;
    let i = 0;
    for (i = 0; i < this.classDT.length; i++) {
      if (this.classDT[i].id == parseInt(c.value)) {
        this.cclass = this.classDT[i].nm;
      }
    }
    let k = 0;
    for (k = 0; k < this.majors.length; k++) {
      if (this.majors[k].id == parseInt(m.value)) {
        this.mmajor = this.majors[k].nm;
      }
    }
    header.push([
      "Roll-Number",
      "Form-Number",
      "Name ",
      "Father-Name",
      "Gender",
      "Address",
      "Phone",
      "Guardian-Phone",
      "Major",
    ]);
    this.allData.forEach((el) => {
      body.push([
        el.rn,
        el.f_no,
        el.name,
        el.f_name,
        el.gender,
        el.address,
        el.phone,
        el.g_phone,
        el.group,
      ]);
    });
    new AngularCsv(
      body,
      "Student General Information " + this.cclass + " " + this.mmajor,
      { headers: header }
    );
  }

  Filter()
  {
    filter();
  }
}
