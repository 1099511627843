<div [@SlideInFromLeft]>
    <div class="container-fluid mb-2 card shadow">
        <form #f="ngForm">
            <div class="card-header"  style="padding-top: 10px;">
                <div class=" col-lg-6 col-md-6 form-group">
                  Admission Form</div>
                   <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
                  <app-details-button [details]="details"></app-details-button>
              </div>
            </div>

            <div class="row card-body" style="margin-top: -10px">
                <!-- <div class="col-lg-12 col-md-12 col-sm-12 form-group text-center"><h1>Admission Form</h1></div> -->
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Class</label>
                    <select id="c_code" #course (change)="getMajors(course)" name="c_code" class="form-control" ngModel required>
                        <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
                    </select>

                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Major</label>
                    <select id="maj_id" #course name="maj_id" class="form-control" (change)="get_set_no()" ngModel required>
                        <option *ngFor="let c of major" value="{{c.id}}">{{c.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="year">Year</label>
                    <input type="number" class="form-control" name="year" (change)="getmaxrolno()" id="year" ngModel required >
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Auto Incremented Roll Number</label>
                    <input class="form-control" name="rollno" id="rollno" value={{this.rolno}} readonly ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Auto Incremented Registration Number</label>
                    <input class="form-control" name="reg_no" id="reg_no" value={{this.reg_no}} readonly ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Form No</label>
                    <input class="form-control" name="f_no" id="f_no" ngModel>
                </div>
                <div class="col-lg-12 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Name</label>
                    <input class="form-control" name="name" id="name" ngModel>
                </div>
                <div class="col-lg-12 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Father Name</label>
                    <input class="form-control" name="fname" id="fname" ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">CNIC</label>
                    <input class="form-control" #nic (change)="get_nic_status(nic)" onclick="get_nic_status(nic)"
                        name="nic" id="nic" type="number" mask="00000-0000000-0" ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Date Of Birth</label>
                    <input class="form-control" name="dob" id="dob" type="date" mask="0000-00-00" ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Student's Contact No.</label>
                    <input class="form-control" name="ph1" id="ph1" type="number" limitTo="15" ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Father's Contact No.</label>
                    <input class="form-control" name="ph2" id="ph2" type="number" limitTo="15" ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Gender`</label>
                    <select id="gender" class="form-control" name="gender" id="gender" ngModel>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="N">Other</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Religion</label>
                    <select id="rel" class="form-control" name="rel" ngModel>
                        <option *ngFor="let r of religion" value="{{r.nm}}">{{r.nm}}</option>

                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Email Address`</label>
                    <input class="form-control" name="email" id="email" ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Shift</label>
                    <select id="moreve" class="form-control" name="moreve" id="moreve" ngModel>

                        <option value="M">Morning</option>
                        <option value="E">Evening</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-4 form-group">
                    <label for="Set_No">Subject Combination</label>

                    <select id="set_no" class="form-control" name="set_no" id="set_no" ngModel>
                        <option *ngFor="let c of set_no" value="{{c.id}}">{{c.nm}}</option>
                    </select>
                </div>


                <div class="col-lg-12 col-md-4 col-sm-4 form-group">
                    <label for="Set_No">Temporary Address</label>
                    <input class="form-control" name="add1" id="add1" limitTo="1000" ngModel>
                </div>

                <div class="col-lg-12 col-md-4 col-sm-4 form-group">
                    <label for="Set_No">Permanent Address</label>
                    <input class="form-control" name="add2" id="add2" limitTo="1000" ngModel>
                </div>
                <div class="" style="margin-top: 20px;">
                    <button type="submit" class="btn btn-primary" style="margin-left: 20px;" (click)="onsubmit()" [disabled]="!f.valid">Insert
                        Data</button>
                    <button type="button" class="btn btn-primary" style="margin-left: 20px;" (click)="OnClear()">Clear</button>
                </div>
            </div>
        </form>
    </div>
</div>