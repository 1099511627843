<div class="card" [@SlideInFromLeft]>
  <div class="card-header">
    Course Outline
  </div>
  <div class="card-body">
    <div class="alert alert-danger d-flex justify-content-between" *ngIf="show" (click)="onClose()">
      Invalid Input
      <button class="btn btn-danger">Close</button>
    </div>
    <form (ngSubmit)="OnSubmit()" #form="ngForm">
      <div class="row">
        <div class="form-group col-lg-4">
          <label for="course">Class</label>
          <select #class class="form-control shadow-sm" (change)="OnClassChange(class)" name="course" id="course">
            <option *ngFor="let c of classes" value="{{c.c_code}}">{{c.c_nm}}</option>
          </select>
        </div>

        <div class="form-group col-lg-4">
          <label for="section">Course</label>
          <select #c class="form-control shadow-sm" name="section" id="section" (change)="onCourseChange(c)">
            <option *ngFor="let t of teams; let i=index;" value={{i}}>
              {{t.SUB_CODE}}-{{t.SUB_NM}}-{{t.SECTION}}-{{t.G_NM}}-{{t.T_NO}}</option>
          </select>
        </div>
        <div class="form-group col-lg-4">
          <label for="week">Week :</label>
          <select #w class="form-control shadow-sm" name="week" id="week" (change)="onWeekChange()" required ngModel>
            <option *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]" value="{{i}}">Week-{{i}}</option>
          </select>
        </div>
        <div class="form-group col-lg-6" *ngIf="this.week==1">
          <label for="support">Supporting Team Member :</label>
          <input type="text" class="form-control" name="support" id="support" ngModel>
        </div>
      </div>
      <div class="row">
        <div *ngIf="this.week==1" class="col-lg-8 form-group">
          <label for="Obj">Course Objectives:</label>
          <textarea type="text" class="form-control" name="Obj" id="Obj" rows="5" [(ngModel)]="objvar" required>
              </textarea>
          <span style="color:red" *ngIf="objvar?.length>1000">
            Maximum 1000 letters
          </span>
        </div>
      </div>
      <div class="row">
        <div *ngIf="this.week==1" class="col-lg-4 form-group">
          <label for="Assessment">Assessment:</label>
          <textarea type="text" class="form-control" name="Assessment" id="Assessment" rows="5" [(ngModel)]="assvar"
            required>
                </textarea>
          <span style="color:red" *ngIf="assvar?.length>1000">
            Maximum 1000 letters
          </span>
        </div>
        <div *ngIf="this.week==1" class="col-lg-4 form-group">
          <label for="Outcome">Learning Outcomes:</label>
          <textarea #Outcome="ngModel" type="text" class="form-control" name="Outcome" id="Outcome" rows="5"
            [(ngModel)]="outvar" required>
            <!-- name="titleId" 
            (Change)="titleModel = $event + ''" #titleId="ngModel"      -->
          </textarea>
          <span style="color:red" *ngIf="outvar?.length>1000">
            Maximum 1000 letters
          </span>
        </div>
      </div>
      <div class="row">
        <div *ngIf="this.week==1" class="col-lg-8 form-group">
          <label for="material">Course Material:</label>
          <textarea type="text" class="form-control" name="material" id="material" rows="5" [(ngModel)]="matvar"
            required>
                  </textarea>
          <span style="color:red" *ngIf="matvar?.length>1000">
            Maximum 1000 letters
          </span>
        </div>
      </div>
      <div class="row">
        <div *ngIf="this.week==1" class="col-lg-4 form-group">
          <label for="Plagirism">Plagirism Policy:</label>
          <textarea type="text" class="form-control" name="Plagirism" id="Plagirism" rows="5" [(ngModel)]="plagvar"
            required>
                  </textarea>
          <span style="color:red" *ngIf="plagvar?.length>1000">
            Maximum 1000 letters
          </span>
        </div>
        <div *ngIf="this.week==1" class="col-lg-4 form-group">
          <label for="Attendance">Attendance Policy:</label>
          <textarea type="text" class="form-control" name="Attendance" id="Attendance" rows="5" [(ngModel)]="attvar"
            required>
                  </textarea>
          <span style="color:red" *ngIf="attvar?.length>1000">
            Maximum 1000 letters
          </span>
        </div>
      </div>
      <div class="row">
        <div *ngIf="this.week==1" class="col-lg-8 form-group">
          <label for="Methodology">Teaching Methodology:</label>
          <textarea type="text" class="form-control" name="Methodology" id="Methodology" rows="5" [(ngModel)]="methvar"
            required>
                    </textarea>
          <span style="color:red" *ngIf="methvar?.length>1000">
            Maximum 1000 letters
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 form-group">
          <label for="Objective">Week Goal:</label>
          <textarea type="text" class="form-control" name="Objective" id="Objective" rows="5" [(ngModel)]="goalvar"
            required>
              </textarea>
          <span style="color:red" *ngIf="goalvar?.length>1000">
            Maximum 1000 letters
          </span>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6">
          <br>
          <button class="btn btn-outline-primary btn-md" type="submit">Add Objective</button>
          <button *ngIf="btnDis" class="btn btn-outline-primary btn-md ml-2" type="button" (click)="CreatePdf()">Create
            PDF</button>
        </div>
      </div>
    </form>

  </div>
  <!-- <app-outline-entry [outline]="this.outline" [tm]="this.tm"></app-outline-entry> -->
  <div class="card-header"><b>Outline of Weekly Lectures</b></div>
  <div *ngIf="hideAllWeeks" class="card-body mb-3" style="height: 30rem;overflow: hidden;overflow-y: scroll">
    <div class="list-group shadow">
      <div *ngFor="let o of outline">
        <div class="d-flex justify-content-between mb-1">
          <div class="p-1 bg-danger"></div>
          <div href="#" class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1"> Week {{o.week_no}}</h5>
              <div>
                <span>
                  <button *ngIf="o.week_no==1" class="btn btn-sm btn-outline-success mr-1" (click)="onUpdate(o)"
                    type="button">
                    <span><i class="fa fa-pencil" aria-hidden="true">&nbsp;Edit</i></span>
                  </button>

                  <button *ngIf="o.week_no!=1" class="btn btn-sm btn-outline-success mr-1"
                    (click)="onUpdateSingleWeek(o)" type="button">
                    <span><i class="fa fa-pencil" aria-hidden="true">&nbsp;Edit</i></span>

                  </button>

                </span>
                <!-- <div *ngIf="this.week==1" class="col-lg-4 form-group">
              <label for="Plagirism">Plagirism Policy:</label>
              <textarea type="text" class="form-control" name="Plagirism" id="Plagirism" rows="5" ngModel required>
                      </textarea>
            </div> -->
                <span>
                  <button class="btn btn-sm btn-outline-danger" (click)="deleteWeek(o.week_no)" type="button">
                    <span><i class="far fa-trash-alt" aria-hidden="true"> &nbsp; Delete</i></span>
                  </button>
                </span>
              </div>

            </div>
            <p class="mb-1">{{o.content}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="editMode" class="card-body">
    <form (ngSubmit)="onupdateSubmit(form)" #form="ngForm">
      <div class="row">
        <div class="form-group col-lg-6">
          <label for="upSupport">Supporting Team Member :</label>
          <input #upSupport type="text" class="form-control" name="upSupport" [(ngModel)]="data.support" id="upSupport">
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 form-group">
          <label for="upObj">Course Objectives:</label>
          <textarea #upObj type="text" class="form-control" name="upObj" [(ngModel)]="data.objective" id="upObj"
            rows="5" required>
            </textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 form-group">
          <label for="upAssessment">Assessment:</label>
          <textarea #upAssessment type="text" class="form-control" name="upAssessment" [(ngModel)]="data.assessment"
            id="upAssessment" rows="5" required>
              </textarea>
        </div>
        <div class="col-lg-4 form-group">
          <label for="upOutcome">Learning Outcomes:</label>
          <textarea #upOutcome type="text" class="form-control" name="upOutcome" [(ngModel)]="data.outcome"
            id="upOutcome" rows="5" required>
              </textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 form-group">
          <label for="upMaterial">Course Material:</label>
          <textarea #upMaterial type="text" class="form-control" name="upMaterial" [(ngModel)]="data.material"
            id="upMaterial" rows="5" required>
                </textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 form-group">
          <label for="upPlagirism">Plagirism Policy:</label>
          <textarea #upPlagirism type="text" class="form-control" name="upPlagirism" [(ngModel)]="data.plagirism"
            id="upPlagirism" rows="5" required>
                </textarea>
        </div>
        <div class="col-lg-4 form-group">
          <label for="upAttendance">Attendance Policy:</label>
          <textarea #upAttendance type="text" class="form-control" name="upAttendance" [(ngModel)]="data.attendance"
            id="upAttendance" rows="5" required>
                </textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8 form-group">
          <label for="upMethodology">Teaching Methodology:</label>
          <textarea #upMethodology type="text" class="form-control" name="upMethodology" [(ngModel)]="data.methodology"
            id="upMethodology" rows="5" required>
                  </textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 form-group">
          <label for="upObjective">Week Goal:</label>
          <textarea #upObjective type="text" class="form-control" name="upObjective" [(ngModel)]="data.content"
            id="upObjective" rows="5" ngModel required>
            </textarea>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6">
          <button class="btn btn-outline-primary btn-md">Submit Update</button>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="editModeSingleWeek" class="card-body">
    <form (ngSubmit)="onupdateSubmit(form)" #form="ngForm">
      <div class="row">
        <div class="col-lg-6 form-group">
          <label for="upObjective">Week Goal Updation:</label>
          <textarea #upObjective type="text" class="form-control" name="upObjective" [(ngModel)]="data.content"
            id="upObjective" rows="5" ngModel required>
            </textarea>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-6">
          <button class="btn btn-outline-primary btn-md">Submit Update</button>
        </div>
      </div>

    </form>
  </div>

</div>