import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FaqButtonComponent } from './faq-button/faq-button.component';
import { FaqModalComponent } from './faq-modal/faq-modal.component';
import { FaqAddComponent } from './faq-add/faq-add.component';

@NgModule({
  declarations: [
    FaqButtonComponent,
    FaqModalComponent,
    FaqAddComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    FaqModalComponent,
    FaqButtonComponent
  ]
})
export class FaqModule { }
