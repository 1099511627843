<div [@SlideInFromLeft] class="tabber">
    <div class="container-fluid card shadow px-4">
        <div class="card-header px-0">
            <div>DB Schema Details</div>
        </div>

        <form (ngSubmit)=" OnSubmit()" #f="ngForm" class="px-0">
            <div class="row card-body space-remover px-0">

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="DB_type">Database:</label>
                    <select #DB_type class="form-control" name="DB_type" id="DB_type" required ngModel>
                        <option value="LMS">LMS</option>
                        <option value="FEE">FEE</option>
                        <!-- <option *ngFor="let i of DB_type" value="{{i.nm}}">{{i.nm}}</option> -->
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="routine_type">Routine Type:</label>
                    <select #routine_type class="form-control" (change)="onRoutineChange()" name="routine_type"
                        id="routine_type" required ngModel>
                        <option value="PROCEDURE">PROCEDURES</option>
                        <option value="FUNCTION">FUNCTIONS</option>
                        <option value="TABLE">TABLES</option>
                        <option value="proc_cpu">CPU CONSUMTION</option>
                        <!-- <option *ngFor="let i of routine_type" value="{{i.nm}}">{{i.nm}}</option> -->
                    </select>
                </div>

                <div class="mt-4">
                    <button (click)="getDetail()" class="btn btn-outline-primary ml-3" [disabled]="!f.valid">
                        Fetch Data
                    </button>

                    <button (click)="clear()" class="btn btn-outline-warning ml-3">
                        Clear
                    </button>
                </div>
            </div>

        </form>

        <!-- <div class="col-12 d-flex">
            <div *ngIf="allRoutine"  class="px-0 col-6">
                <table class="table table-bordered table-striped" id="myTable">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col" style="text-align: center;">All Routine</th>
                        </tr>
                    </thead>
                    <tbody id="myBody">
                        <tr *ngFor="let i of allRoutine" style="font-weight:bold">
    
                            <td>{{i.ROUTINE_NAME}}</td>
    
                        </tr>
    
                    </tbody>
                </table>
            </div>

            <div *ngIf="storedRoutine"  class="px-0 col-6">
                <table class="table table-bordered table-striped" id="myTable">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col" style="text-align: center;">Stored Routine</th>
                        </tr>
                    </thead>
                    <tbody id="myBody">
                        <tr *ngFor="let i of storedRoutine" style="font-weight:bold">
    
                            <td>{{i.STORED_ROUTINE}}</td>
    
                        </tr>
    
                    </tbody>
                </table>
            </div>
        </div> -->

        
        <div *ngIf="consumption_flag" class="px-0 col-12">
            <table class="table table-bordered table-striped" id="myTable">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col" style="text-align: center; width: 50%;">Query</th>
                        <th scope="col" style="text-align: center; width: 10%;">Time Taken</th>
                        <th scope="col" style="text-align: center; width: 10%;">Affected Rows</th>
                        <th scope="col" style="text-align: center; width: 10%;">Examined Rows</th>
                        <th scope="col" style="text-align: center; width: 10%;">Last Seen</th>
                    </tr>
                </thead>
                <tbody id="myBody">

                    <tr *ngFor="let i of cpuConsumption" style="font-weight:bold">
                        <td>{{i.DIGEST_TEXT}}</td>
                        <td>{{i.time_taken}}</td>
                        <td>{{i.SUM_ROWS_AFFECTED}}</td>
                        <td>{{i.SUM_ROWS_EXAMINED}}</td>
                        <td>{{i.LAST_SEEN | date: 'yyyy-MM-dd'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>



        <div class="col-12 d-flex">
            <div *ngIf="routineData_flag" class="px-0 col-lg-6 col-md-6 col-sm-6">
                <table class="table table-bordered table-striped" id="myTable">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col" style="text-align: center;">Live Routines</th>
                            <th scope="col" style="text-align: center;"></th>
                        </tr>
                    </thead>
                    <tbody id="myBody">
                        <!-- <tr *ngFor="let i of allRoutine" style="font-weight:bold">
                            <td>{{ i.ROUTINE_NAME ? i.ROUTINE_NAME : 'Missing' }}</td>
                            <td [style.color]="getStatusColor(i.STATUS)">{{getStatusForAllRoutine(i.STATUS)}}</td>
                        </tr> -->

                        <tr *ngFor="let i of allRoutine" [ngStyle]="{'background-color': (i.ROUTINE_NAME ? '' : 'rgba(255, 0, 0, 0.3)') || (i.STATUS === 'Created' ? 'rgba(0, 255, 0, 0.3)' : '')}" style="font-weight:bold">
                            <td>{{ i.ROUTINE_NAME ? i.ROUTINE_NAME : 'MISSING' }}</td>
                            <td>
                                <button *ngIf="i.STATUS === 'Created'" class="upload-button" (click)="addRoutine(i.ROUTINE_NAME)"  data-bs-toggle="tooltip" data-bs-placement="top" title="نئے بنائے گئے پروسیجر کو ٹیبل میں شامل کرنے کے لیے، یہاں کلک کریں۔">
                                    <i class="fas fa-arrow-right"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div *ngIf="routineData_flag" class="px-0 col-lg-6 col-md-6 col-sm-6">
                <table class="table table-bordered table-striped" id="myTable">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col" style="text-align: center;">Stored Routines</th>
                            <!-- <th scope="col" style="text-align: center;">Status</th> -->
                        </tr>
                    </thead>
                    <tbody id="myBody">
                        <tr *ngFor="let i of storedRoutine" [ngStyle]="{'background-color': (i.STORED_ROUTINE ? '' : 'rgba(0, 255, 0, 0.3)') || (i.STATUS === 'Missing' ? 'rgba(255, 0, 0, 0.3)' : '')}" style="font-weight:bold">
                            <td>{{ i.STORED_ROUTINE ? i.STORED_ROUTINE : 'NOT EXIST' }}</td>

                            <!-- <td>{{i.STORED_ROUTINE}}</td> -->
                            <!-- <td [style.color]="getStatusColor(i.STATUS)">{{getStatusForStoredRoutine(i.STATUS)}}</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</div>