import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { CourseModal } from 'src/app/shared/course.modal';
import { SelectList } from '../../shared/model/SelectList.model';
import { ClassService } from '../../shared/services/Class.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DepartmentService } from '../../shared/services/Department.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { EligibiltyService } from './../../shared/services/Eligibilty.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';
declare let $:any;

enum Mode {
  'Eligible',
  'Ineligible',
  'Both'
}

@Component({
  selector: 'app-enrollmentstatus',
  templateUrl: './enrollmentstatus.component.html',
  styleUrls: ['./enrollmentstatus.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class EnrollmentstatusComponent implements OnInit {
  departments: Array<SelectList>;
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  subjects: Array<{ SUB_CODE: string, SUB_NM: string }>;

  groups: Array<SelectList>;
  isAdmin: boolean = false;
  isExam: boolean = false;
  isExamCell: boolean = false;
  dept: number = null;

  public btnHide = false;
  private classS: string;
  private se_id: string;
  private sub_code: string;
  private eligible: string;
  private loading: boolean = false;

  @ViewChild('f') formRef: NgForm;
  courses: CourseModal[] = new Array<CourseModal>();

  results: any[] = [];
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'> you can check the status of offered subjects ,timetable of these subjects and enrollment in these subjects of any 
  semester in any session of its class of a department  </h6>
  <h6 class="text-right">آپ کسی بھی ڈیپارٹمنٹ کی کلاس کے کسی بھی سیشن کے کسی بھی سمسٹر میں آفر کردہ سبجیکٹس، ان سبجیکٹس کے ٹائم ٹیبل اور ان سبجیکٹس میں انرولمنٹ کا اسٹیٹس چیک کر سکتے ہیں۔
</h6>
  `  ;

  constructor(private authenticationService: AuthenticationService,
    private eligibiltyService: EligibiltyService,
    private toastr: ToastrService,
    private departmentService: DepartmentService,
    private classService: ClassService,
    private lmsSharedService: LMSharedDataService,
    private datePipe: DatePipe) {
    this.classes = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.subjects = [];
    this.departments = [];
    this.groups = new Array<SelectList>();
    this.isAdmin = this.authenticationService.isAdmin();
    this.isExamCell = this.authenticationService.isExamCell();
    this.dept = this.authenticationService.getUser().D_ID;
  }
  loader = false;
  ngOnInit(): void {
    this.loader = false;
    this.eligible = 'ELIGIBLE AND INELIGIBLE';
    if (this.isAdmin || this.isExamCell) {
      this.updateDepartments()
    } else {
      this.updateClasses();
    }
  }

  OnDeptChange(d: HTMLSelectElement) {
    this.data = [];
    this.formRef?.controls['c_code'].reset();
    this.formRef?.controls['se_id'].reset();
    this.formRef?.controls['t_no'].reset();
    this.updateClasses();
  }


  OnSemesterChange(sem: HTMLSelectElement) {
    this.data = [];
  }

  OnSessionChange(s: HTMLSelectElement) {
    this.se_id = this.sessions[s.selectedIndex].nm;
    this.data = [];
  }

  OnClassChange(c: HTMLSelectElement) {
    this.data = [];
    this.classS = this.classes[c.selectedIndex].nm;
    this.updateSessions();
  }

  data: any;
  getData() {

    this.data = [];

    let { d_id, c_code, se_id, t_no } = this.formRef.value;

    if (!d_id || !c_code || !se_id || !t_no) {
      return
    };
    this.loader = true;
    this.eligibiltyService.getErollmentStatus(d_id, c_code, se_id, t_no)
      .subscribe(res => {
        this.loader = false;
        this.data = res[0];
        if(this.data?.length == 0)
        this.toastr.info("No Data to Display")
      }, err => {
        this.loader = false;
        console.log(err)
        this.toastr.clear()
        this.toastr.error('Some Error Occured');
      })
  }


  updateDepartments() {
    this.lmsSharedService.getAdminExamDept()
      .then(dept => {
        this.departments = dept;
      })
      .catch(error => {
        console.error("Error getting lms dept", error);
      });
  }


  updateClasses() {
    this.classes = [];
    this.sessions = [];
    this.formRef?.controls['se_id'].reset();

    const sub_d_id = ((this.isAdmin || this.isExamCell) ? this.formRef?.value.d_id : this.dept);
    this.classService.getClasses({ sub_d_id }).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
    });
  }

  updateSessions() {
    this.lmsSharedService.filterSessionByCode(this.formRef.value.c_code)
      .then(session => {
        this.sessions = session
        if (this.formRef) {
          const initialValue = this.sessions[0]?.id;
          this.formRef?.controls['se_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }

  OnCreatePDF() {
    let ty = this.eligible;
    const body = [];
    const header = [];
    let k = 0;
    for (let i = 0; i < this.data.length; i++) {
      k++;
      body.push([k,
        this.data[i].major?.toUpperCase(),
        this.data[i].t_no,
        this.data[i].sub_code,
        this.data[i].subject,
        this.data[i].tm,
        this.data[i].enroll,
      ]);
    }
    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y,h:mm a')
    var image = new Image();
    var page;
    // let subcode=this.formRef.value.sub_code;
    let cls = this.classS;
    let se = this.se_id;
    let sub = this.sub_code;
    var depname;
    this.departments.forEach(element => {
      if (element.id == this.formRef.value.d_id) {
        depname = element.nm;
      }
    });
    let to = this.formRef.value.t_no;
    if (this.formRef.value.t_no == 9) {
      to = '1-8'
    }
    image.src = '../../../assets/images/logo3.png';
    header.push(['Sr.', 'Major', 'Semester', 'Subject Code', 'Subject Name', 'Timetable Status', 'Enrollment Status']);
    const doc = new jsPDF();
    autoTable(doc, {
      // margin: { top: 40, bottom: 10 },
      margin: { top: 34, bottom: 10 },
      styles: { fontSize: 8 },
      head: header,
      body: body,
      didDrawPage: function () {
        doc.addImage(image, 177, 2, 25, 25);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 172, 30);
        doc.setFontSize(20);
        doc.setFont('Arial');
        doc.text("GC UNIVERSITY LAHORE", 100, 10, { align: 'center' });
        doc.setFontSize(12);
        doc.text("ENROLLMENT STATUS : " + depname, 100, 17, { align: 'center' });
        // doc.text("ENROLLMENT STATUS : " + ty + " STUDENTS", 100, 17, { align: 'center' });
        doc.text("CLASS: " + cls?.toUpperCase(), 100, 24, { align: 'center' });
        doc.text("SESSION: " + se?.toUpperCase() + "     SEMESTER: " + to, 100, 31, { align: 'center' });
        // doc.text("SUBJECT: " + sub?.toUpperCase() + " ( "+subcode+" ) ", 100, 38, { align: 'center' });
        doc.setFontSize(10);
        page = doc.getNumberOfPages();
        doc.text("Note:- Errors and Omissions Excepted", 145, 288);
        doc.text("Directorate of Information Technology", 8, 288);
      }
    });
    var l = (body.length + 65) % 26;
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 90, 288);
    }
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
    // doc.save("Enrollment Status of " + depname);
    this.btnHide = false;
  }

  DownloadExcel() {
    var depname;
    this.departments.forEach(element => {
      if (element.id == this.formRef.value.d_id) {
        depname = element.nm;
      }
    });
    let body = [];
    let i = 1;
    this.data.forEach(entry => {
      body.push({ SR: i, Major: entry.major, Semester: entry.t_no, Subject: entry.sub_code, SubName: entry.subject, TimetableStatus: entry.tm, EnrollmentStatus: entry.enroll });
      i++;
    });
    new AngularCsv(body, "Enrollment Status of " + depname, { headers: ['Sr.', 'Major', 'Semester', 'Subject Code', 'Subject Name', 'Timetable Status', 'Enrollment Status'] });
  }

  Filter()
  {
    filter()
  }
}
