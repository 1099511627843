import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from './auth/_services';
import { User } from './auth/_models';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  currentUser: User;
  idleState: string = "Not Started";
  isFaqModalOpen = false;
  isSearchStudentOpen = false;
  isAdmin: boolean;
  canEnrollRepeater: boolean;
  isExamCell: boolean;
  canReadmission: boolean;
  canFeeStructure: boolean;
  financialOffice: boolean;
  endownmentFund: boolean;

  isMenuExpanded = false;
  showManuButton = false;

  
  constructor (
    private authenticationService: AuthenticationService,
    private idle: Idle,
    private cd: ChangeDetectorRef
  ) {
    this.authenticationService.currentUser.subscribe(x => {this.currentUser = x;});
    idle.setIdle(28800); // 8 hrs
    idle.setTimeout(60); // 1 mins
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.isAdmin = authenticationService.isAdmin();
    this.canEnrollRepeater = authenticationService.canEnrollRepeater();
    this.isExamCell = authenticationService.isExamCell();
    this.canReadmission = authenticationService.canReadmission();
    this.canFeeStructure = authenticationService.canFeeStructure();
    this.financialOffice = authenticationService.isFinancialAidOffice();
    this.endownmentFund = authenticationService.isEndownmentFund();


    idle.onIdleEnd.subscribe(() =>{
      this.idleState = 'Started';
      cd.detectChanges();
    });

    idle.onTimeout.subscribe(()=>{
      this.idleState = 'Timedout';
      idle.stop();
      this.idleState = 'Not Started';
      this.authenticationService.logout();
    });

    idle.onIdleStart.subscribe(() =>{
      this.idleState = 'Idle';
    })
  }

  ngOnInit(): void {
    this.setStates();
    this.isAdmin = this.authenticationService.isAdmin();

    this.showManuButton = this.isAdmin || this.canEnrollRepeater || this.isExamCell || 
                          this.canReadmission || this.canFeeStructure || this.financialOffice || this.endownmentFund;;
  }

  setStates() {
    this.idle.watch();
    this.idleState = 'Started';
  }

  // toggleFaqModal() {
  //   this.isFaqModalOpen = !this.isFaqModalOpen;
  // }

  toggleMenu() {
    this.isMenuExpanded = !this.isMenuExpanded;
    // this.isFaqModalOpen = false;
    // this.isSearchStudentOpen = false;
  }

  openModal(mode: string){

    if(mode == "faq"){
    this.isFaqModalOpen = !this.isFaqModalOpen;
    }else if(mode == "searchStudent"){
      this.isSearchStudentOpen = !this.isSearchStudentOpen;
    }

    this.toggleMenu();
  }

  // showModal(): void {
  //   this.isModalVisible = true;
  // }

  hideModal(): void {
    this.isSearchStudentOpen = false;
    // this.location.back();
  }
}