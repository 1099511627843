import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { baseUrl } from './../../../axios';

@Injectable({
    providedIn: 'root'
})

export class EvaluationFormServices {

    constructor(private http: HttpClient) {
    }
    insertNewForm(params) {
        return this.http.post(`${baseUrl}/api/forms/insertEvalFormQues`, params);
    }
    EV_F_assignEvalFrmToSec(params){
        return this.http.post(`${baseUrl}/api/forms/EV_F_assignEvalFrmToSec`,params);
    }
    getQuestions(id:string){
        return this.http.get(`${baseUrl}/api/forms/getQuestions/${id}`);
    }
    getFormNames(){
        return this.http.get(`${baseUrl}/api/forms/getFormNames`)
    }
    getPublishedForms(){
        return this.http.get(`${baseUrl}/api/forms/getPublishedForms`);
    }
    getAllPatterns(){
        return this.http.get(`${baseUrl}/api/forms/getAllPatterns`);
    }
    checkFormNm(nm:string){
        return this.http.get(`${baseUrl}/api/forms/checkFormNm/${nm}`);
    }
    checkFormAssignAndGetDate(params){
        return this.http.post(`${baseUrl}/api/forms/checkFormAssignAndGetDate`,params);
    }
    submissionFormDateExtend(params){
        return this.http.post(`${baseUrl}/api/forms/submissionFormDateExtend`,params);
    }
    updateFormQuestion(params){
        return this.http.put(`${baseUrl}/api/forms/updateFormQuestion`,params);
    }
    deleteFormQuestion(frm_id:number,ques_id:number){
        return this.http.delete(`${baseUrl}/api/forms/deleteFormQuestion/${frm_id}/${ques_id}`);
    }
    EV_F_unPublishForm(params){
        return this.http.put(`${baseUrl}/api/forms/EV_F_unPublishForm`,params);
    }
    EV_F_deleteForm(params){
        return this.http.post(`${baseUrl}/api/forms/EV_F_deleteForm`,params);
    }
}