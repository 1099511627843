<div [@SlideInFromLeft]>
  <div class="container-fluid mb-2 card">
    <form (ngSubmit)="OnSubmit(f)" #f="ngForm">
      <div class="card-header"  style="padding-top: 10px;">
        <div class=" col-lg-6 col-md-6 form-group">
          Add new Subject</div>
           <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
          <app-details-button [details]="details"></app-details-button>
      </div>
    </div>
      <div class="row card-body" style="margin-top: -10px">

        <div class="col-lg-6 col-md-6 form-group">
          <label for="Department" class="text-left">Department
          </label>
          <select #department (change)="OnDeptChange(department)" id="d_id" name="d_id" class="form-control" ngModel
            style="margin-top: -3px">
            <option *ngFor="let d of departments" value="{{d.id}}">{{d.nm}}</option>
          </select>
        </div>


        <div class="col-lg-6 col-md-6 form-group">
          <label for="c_code" class="text-left">Class</label>
          <select id="c_code" #cource (change)="OnClassChange(cource)" name="c_code" class="form-control" ngModel
            style="margin-top: -3px">
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <!-- <div class="col-lg-6 col-md-6 form-group">
          <label for="id" class="text-left">Subject ID</label>
          <input class="form-control" name="id" id="id" type="number" required ngModel>
        </div> -->
        <div class="col-lg-6 col-md-6 form-group">
          <label for="sub_code">Subject Code</label>
          <input class="form-control" name="sub_code" id="sub_code" required ngModel>
        </div>

        <div class="col-lg-6 col-md-6 form-group">
          <label for="sub_nm">Subject Name</label>
          <input class="form-control" name="sub_nm" id="sub_nm" required ngModel>
        </div>

        <div class="col-lg-6 col-md-6 form-group">
          <label for="sub_nm">Subject Short name</label>
          <input class="form-control" name="s_nm" id="s_nm" required ngModel>
        </div>

        <div class="col-lg-6 col-md-6 form-group">
          <label for="sub_nat">Subject Nature</label>
          <select name="sub_nat" id="sub_nat" class="form-control" required ngModel>
            <option *ngFor="let n of subNature" value="{{n.id}}">{{n.nm}}</option>
          </select>
        </div>

        <div class="col-lg-6 col-md-6 form-group">
          <label for="cr_h" class="text-left">Credit Hour</label>
          <input class="form-control" name="cr_h" id="cr_h" required ngModel>
        </div>

        <div class="mt-4">
          <button type="submit" [disabled]="!f.valid" class="btn btn-primary mt-1" style="margin-left: 20px;">Submit</button>
          <button type="button" class="btn btn-warning mt-1" style="margin-left: 20px;" (click)="Clear(f)">Clear</button>
        </div>
      </div> 
      <br>
    </form>
  <div *ngIf="dptsubjects?.length>0" class=" card-header mt-5">
    Subjects 
  </div>
  <div *ngIf="dptsubjects?.length>0" class="card-body">
    <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
      <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
  </div>
    <table class="table table-striped table-bordered" id="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Sr No</th>
          <th scope="col">Subject Code</th>
          <th scope="col">Subject Name</th>
          <th scope="col">Short Name</th>
          <th scope="col">Cr.Hrs</th>
          <th scope="col">Nature</th>                       
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sub of dptsubjects; let i =index;" id="values">
          <td>{{i+1}}</td>
          <td>{{sub.SUB_CODE}}</td>
          <td>{{sub.SUB_NM}}</td>
          <td>{{sub.S_NM}}</td>
          <td>{{sub.CR_H}}</td>                    
          <td>{{sub.NAT_ID==1?'Theory':sub.NAT_ID==2?'Practical':''}}</td>                              
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>
