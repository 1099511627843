<form #f="ngForm">
  <div class="container-fluid" [@SlideInFromLeft]>
    <div class="row card shadow">
      <div class="card-header">
        <div>Level Wise Collection Report</div>
      </div>
      <div class="row card-body pb-0 px-4">
        <div class="col-lg-4 col-md-4 form-group">
          <label for="lvl_id">Study Level</label>
          <select
            class="form-control"
            name="lvl_id"
            id="lvl_id"
            (change)="onLevelChange($event)"
            ngModel
          >
            <option *ngFor="let c of study_lvl" [value]="c.id">
              {{ c.nm }}
            </option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="year">Year</label>
          <select
            class="form-control"
            id="year"
            aria-label="Year"
            ngModel
            required
            name="year"
          >
            <option *ngFor="let s of sessions" [value]="s">{{ s }}</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="class">Class</label>
          <select
            class="form-control"
            id="class"
            aria-label="Class"
            ngModel
            required
            name="class"
          >
            <option *ngFor="let option of classOptions" [value]="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="row card-body">
        <button class="btn btn-outline-danger" (click)="onData()" [disabled]="!f.valid"z>
          <i class="fa fa-file-pdf-o" for=""></i>
          Download Level-Wise Report
        </button>
        <button class="btn ml-3 btn-warning" (click)="onClear()">Clear</button>
      </div>
      <ng-container *ngIf="loader; then ifBlock"></ng-container>
      <ng-template #ifBlock>
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <span class="loader"></span>
        </div>
      </ng-template>
    </div>
  </div>
</form>
