import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../shared/model/SelectList.model';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { FeeService } from '../shared/services/Fee.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { forEach } from 'jszip';

@Component({
  selector: 'app-schp-award',
  templateUrl: './schp-award.component.html',
  styleUrls: ['./schp-award.component.css'],
  animations: [SlideInFromLeft()]
})

export class SchpAwardComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;
  @ViewChild('schp_amnt', { static: true }) usernameElement: ElementRef;
  schpBase: Array<SelectList>;
  schpCat: Array<SelectList>;
  schpBoard: Array<SelectList>;
  classes: Array<SelectList>;
  major: Array<SelectList>;
  schpInfo: Array<SelectList>;
  schpRn: Array<SelectList>;
  schpDist: Array<SelectList>;
  schBank: Array<SelectList>; //added by mehtab for scholar ship bank
  me: boolean;
  meA: Boolean = false;
  flag: boolean = true;
  meB: Boolean = false;
  meC: Boolean = false;
  check: Boolean = false;
  rowChecks: boolean[] = [];
  ch_no_in: any;
  dt: any;
  schpType: any;
  schpCateg: any
  schpDuesDetail: Array<{ amount: number, fee_desc: string, fee_id: number, schp_amnt: number }>;
  schpStdtInfo: Array<any>;
  loadSchpHis: Array<any>;
  count: number;
  schp: number
  totalPayAmnt: number;
  storeTuition: number;
  count2: number;
  msgcount: number
  dueDate2: any;


  constructor(
    private toastr: ToastrService,
    private feeService: FeeService,
    private datepipe: DatePipe
  ) {
    this.schpBase = [];
    this.schpCat = [];
    this.schpBoard = []
    this.loadSchpHis = []
    this.schpDuesDetail = new Array<{ amount: number, fee_desc: string, fee_id: number, schp_amnt: number }>();
    this.schpStdtInfo = new Array<any>();
    this.schBank = new Array<SelectList>();
    this.dueDate2 = this.curdate()//added by mehtab for scholar ship bank
  }

  ngOnInit(): void {
    this.feeService.getSchpType().subscribe((res: { schp_type_id: number, schp_type_des: string }[]) => {
      res?.forEach(element => {
        this.schpBase.push({ id: element.schp_type_id, nm: element.schp_type_des });
      });
    });
    this.count = 1;
    this.count2 = 1;
    this.msgcount = 1;
    this.getbank();//added by mehtab
  }

  onAwardSchp(fee_id, amount, schp_amnt) {
    this.feeService.awardSchp(this.formRef.value.ch_no, fee_id, amount, schp_amnt).subscribe((res: { msg: string, warn: string }) => {
      if (res.msg) {
        this.toastr.success(res.msg);
      }
      else if (res.warn) {
        this.toastr.warning(res.warn);
        this.loadData();
      }
    });
  }

  ScholarShip() {
    this.schp = 1;
    this.totalPayAmnt = 0;
    const { tuition } = this.formRef.value;
    this.storeTuition = 0;
    this.schpDuesDetail.forEach(element => {
      element.schp_amnt = element.amount;
    })
    this.storeTuition = tuition;
    this.schpDuesDetail.forEach(element => {

      if (this.storeTuition >= 0)//8000
      {

        if (element.schp_amnt - this.storeTuition > 0)//7000-8000=-10000
        {
          element.schp_amnt = this.storeTuition;
          this.storeTuition = 0;
        }
        else {
          this.schp = this.storeTuition - element.schp_amnt;//80000-70000
          element.schp_amnt = (this.storeTuition - this.schp);//(80000-10000) 
          this.storeTuition = this.schp;//10000
        }
      }
    })
    this.schpDuesDetail.forEach(element => {
      this.totalPayAmnt = this.totalPayAmnt + (element.amount - element.schp_amnt);
    })
  }

  updateSchpDues(fee_id, actualAmount, schpAmount) {
    this.schpDuesDetail.forEach(element => {
      if (element.fee_id == fee_id)
        element.schp_amnt = schpAmount;
    })
  }

  UpdateSchpType() {
    const { schp_id } = this.formRef.value;
    this.schpBase.forEach(element => {
      if (element.id == schp_id) {
        this.schpType = element.nm;
      }
    })
    this.schpStdtInfo.forEach(element => {
      element.schp_nm = this.schpType;
    })
  }

  Handle(e: any) {
    return e.keyCode != 13;
  }

  UpdateCategory() {
    const { cat_id } = this.formRef.value;
    this.schpCat.forEach(element => {
      if (element.id == cat_id)
        this.schpCateg = element.nm;
    })
    this.schpStdtInfo.forEach(element => {
      element.schp_ctgry = this.schpCateg;
    })
  }

  LoadSchpHistory() {
    this.loadSchpHis = [];
    this.schpStdtInfo = [];
    this.schpDuesDetail = [];
    this.feeService.LoadSchpHistory(this.formRef.value.ch_no).subscribe((res: any) => {
      if (res?.length) {
        this.loadSchpHis = res;
        this.rowChecks = Array(this.loadSchpHis.length).fill(false);
        this.loadSchpHis = this.loadSchpHis.map(item => {
          const newItem = { ...item };
          if (newItem?.schpEntryDt) {
            newItem.schpEntryDt = moment(newItem.schpEntryDt).format('YYYY-MM-DD');
          }
          return newItem;
        });
      }
      this.loadData();
    })
  }

  UpdateCount() {
    this.count = 1;
    this.count2 = 1;
    this.msgcount = 1;
  }

  changeDate(chNo, exDt, index) {
    this.rowChecks[index] = !this.rowChecks[index];
    if (!this.rowChecks[index]) {
      this.feeService.SFAOUpdateSchpEntryDt(chNo,exDt.value).subscribe((res: any) => {
        if (res[0][0]?.msg)
          this.toastr.success(res[0][0].msg);
      })
    }
  }

  //modified by mehtab on dated 12-03-2024 against schp bank and checque
  InsertDuesForScholarShipStdt() {
    const { ch_no, schp_id, cat_id, bnkname, chcq_no } = this.formRef.value;
    if (this.formRef.valid) {
      this.schpDuesDetail.forEach(element => {
        this.feeService.InsertDuesForScholarShipStdt(ch_no, element.fee_id, element.amount, element.schp_amnt,
          schp_id, cat_id, this.schpCateg, bnkname, chcq_no).subscribe((res: any) => {
            if (res[0]?.inserted == 1) {
              this.count++
            }
            else if (res[0]?.inserted == 2) {
              this.count2++
            }
            if (res[0]?.msg) {
              this.msgcount++
            }
            if (this.count == this.schpDuesDetail.length) {
              this.toastr.success("Scholarship Awarded Successfully")
              this.LoadSchpHistory();
            }
            else if (this.count2 == this.schpDuesDetail.length) {
              this.toastr.success("Scholarship Updated Successfully")
              this.LoadSchpHistory();
            }
            else if (this.msgcount == this.schpDuesDetail.length) {
              this.toastr.error(res[0].msg)
            }
          })
      })

    }
    else
      this.toastr.warning("Some Fields are missing")
  }

  loadSchpCat() {
    this.schpCat = [];
    this.feeService.getSchpCat(this.formRef.value.schp_id).subscribe((res: { schp_cat_id: number, schp_cat_des: string }[]) => {
      res?.forEach(element => {
        this.schpCat.push({ id: element.schp_cat_id, nm: element.schp_cat_des });
      });
    });
  }

  DeleteSchpEntry() {
    this.feeService.deleteSchpEntry(this.formRef.value.ch_no).subscribe((res: any) => {
      this.toastr.info(res[0][0].msg)
      this.LoadSchpHistory();
    })
  }

  loadData() {
    this.schpDuesDetail = [];
    this.schpStdtInfo = [];
    this.totalPayAmnt = 0;
    this.ch_no_in = this.formRef.value.ch_no;
    this.feeService.CheckStdInformationByCh(this.ch_no_in).subscribe((res: any) => {
      if (!res) {
        this.toastr.error("Unknown Error!");
      }
      else if (res) {

        if (res[0].warn) {
          this.toastr.warning(res[0].warn);
        }
        else if (res[0]) {
          this.schpStdtInfo = res[0];
          this.schpDuesDetail = res[1];
        }
      }
      this.schpDuesDetail.forEach(element => {
        this.totalPayAmnt = this.totalPayAmnt + (element.amount - element.schp_amnt);
      })
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });
  }

  ViewloadData(ch_no: any) {

    this.schpDuesDetail = [];
    this.schpStdtInfo = [];
    this.totalPayAmnt = 0;
    this.ch_no_in = ch_no;
    this.feeService.CheckStdInformationByCh(ch_no).subscribe((res: any) => {
      if (!res) {
        this.toastr.error("Unknown Error!");
      }
      else if (res) {
        if (res[0].warn) {
          this.toastr.warning(res[0].warn);
        }
        else if (res[0]) {
          for (const i in res[0]) {
            this.schpStdtInfo.push(res[0][i]);
          }
          for (const i in res[1]) {
            this.schpDuesDetail.push(res[1][i]);
          }
        }
      }
      this.schpDuesDetail.forEach(element => {
        this.totalPayAmnt = this.totalPayAmnt + (element.amount - element.schp_amnt);
      })
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });
  }

  curdate(): any {
    return moment(new Date()).format('YYYY-MM-DD');
  }

  curdateDt(dt): any {
    return moment(dt).format('DD-MM-YYYY');
  }

  onClearForm(fn: NgForm) {
    fn.resetForm();
    this.loadSchpHis = [];
    this.schpStdtInfo = [];
    this.schpDuesDetail = [];
  }

  myFunction() {
    this.flag = !this.flag;
    var popUp;
    popUp = document.getElementById("myPopup");
    if (popUp.style.display === "none") {
      popUp.style.display = "block";
    } else {
      popUp.style.display = "none";
    }
  }

  //get bank information //added by mehtab
  getbank() {
    this.feeService.getAllBanks().subscribe((res: { bank_id: number, d_nm: string }[]) => {
      res.forEach(element => {
        this.schBank.push({ id: element.bank_id, nm: element.d_nm })
      })
    })


  }


}
