import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from 'src/app/Util.service';
import { TimetableService } from '../insert-time-table/Timetable.service';
import { TimetableModel } from './model/Timetable.model';
import { SlideInFromLeft } from './../../transitions';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { GroupService } from './../shared/services/Groups.service';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { SessionService } from './../shared/services/Sessions.service';
import { jsPDF } from "jspdf";
import autoTable, { UserOptions } from 'jspdf-autotable'
import 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';


@Component({
  selector: 'app-view-timetable',
  templateUrl: './view-timetable.component.html',
  styleUrls: ['./view-timetable.component.css'],
  animations: [SlideInFromLeft()]
})
export class ViewTimetableComponent implements OnInit {

  @ViewChild('f') formRef: NgForm;
  @ViewChild('sec') subRef: HTMLSelectElement;
  timetableEntries: Map<number, TimetableModel>;
  classes: Array<SelectList>;
  sessions: Array<SelectList>;
  sectionsAndSub: Map<string, Array<{ sub_code: string, sub_nm: string }>>;
  subs: Array<{ sub_code: string, sub_nm: string }>;

  isAdmin: boolean = false;
  deptt : number;
  group: number;
  pdfvalid: boolean = false;
  classS: string;
  se_id: string;
  private array;
  groups: Array<SelectList>;
  sections: Array<string>;
  isPhilosophy:any;
  showInter:boolean = false;
  interMediateGroup:Array<SelectList>;
  // flag: boolean = true;
  isExam: boolean = false;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  In this screen you can view the complete or subject wise timetable of any semester in any session of a class </h6>
  <h6 class="text-right">اس اسکرین میں آپ کسی کلاس کے کسی بھی سیشن میں کسی بھی سمسٹر کا مکمل یا سبجیکٹ وائز ٹائم ٹیبل دیکھ سکتے ہیں۔
</h6>
  `  ;

  constructor(
    private authenticationService: AuthenticationService,
    private timetableService: TimetableService,
    private utilService: UtilService,
    private toastr: ToastrService,
    private groupService: GroupService,
    private classService: ClassService,
    private sessionService: SessionService,
    private datePipe: DatePipe
  ) {
    this.timetableEntries = new Map<number, TimetableModel>();
    this.classes = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.sectionsAndSub = new Map<string, Array<{ sub_code: string, sub_nm: string }>>();
    this.sections = new Array<string>();
    this.subs = new Array<{ sub_code: string, sub_nm: string }>();
    this.groups = new Array<SelectList>();
    this.isAdmin = this.authenticationService.isAdmin();
    this.group = this.authenticationService.getGroup();
    this.deptt = this.authenticationService.getUser().D_ID;

  }


  ngOnInit(): void {
    this.isPhilosophy=this.group;
    this.loadGroups();
    this.interMediateGroup=[
      {id:1, nm:'INTERMEDIATE'},
      {id:5, nm:'INTERMEDIATE (ARTS)'},
      {id:10, nm:'INTERMEDIATE (ICOM)'},
      {id:15, nm:'INTERMEDIATE (Pre-Eng)'},
      {id:20, nm:'INTERMEDIATE (Pre-Med)'},
      {id:25, nm:'INTERMEDIATE (General Sc)'},
      {id:30, nm:'INTERMEDIATE (Computer Sc)'},
      {id:35, nm:'INTERMEDIATE PE O-Level'},
      {id:40, nm:'INTERMEDIATE PM O-Level'}
    ]

  }
  // myFunction() {
  //   this.flag = !this.flag;
  //   var popUp;
  //   popUp = document.getElementById("myPopup");


  //   if (popUp.style.display === "none") {
  //     popUp.style.display = "block";
  //   } else {
  //     popUp.style.display = "none";
  //   }
  // }
  

  loadGroups() {
    this.groupService.getGroups({ grp: this.group, role: this.isAdmin }).subscribe((res: { GRP: number, G_NM: string }[]) => {
      res?.forEach(element => {
        this.groups.push({ id: element.GRP, nm: element.G_NM });
      });
      if(this.groups[0]?.id==1)
        this.showInter=true;

      if (!this.isAdmin) {
        this.updateClasses(-1);
      }

    }, err => {
      console.log(err);
    });
  }


  OnDeleteClick(tmId) {
    this.timetableEntries.delete(tmId);
  }

  OnGrpChange(grp: HTMLSelectElement) {
    this.pdfvalid = false;

    this.group = parseInt(grp.value);
    this.updateClasses(this.group);
  }

  updateTimetable() {
    this.timetableService.getTimetable({...this.formRef.value, grp: this.group.toString(), dept: this.deptt, isAdmin: this.isAdmin}).subscribe(res => {
      this.timetableEntries.clear();
      if (!res) {
        this.toastr.error("Some Error Occured");
        return;
      }
      for (const i in res) {
        this.timetableEntries.set(res[i].tm_id, new TimetableModel(res[i].sub_code, res[i].grp, res[i].tm_id, res[i].d_id, res[i].d_nm, res[i].c_nm, res[i].se, res[i].t_no, res[i].section, res[i].subject, res[i].fm_id, res[i].teacher, res[i].day, res[i].start_time, res[i].end_time, res[i].blk_id, res[i].blk_nm, res[i].rm_id, res[i].rm_nm, res[i].status));
      }
      this.array = Array.from(this.timetableEntries, ([name, value]) => ({ name, value }));

      const temp: TimetableModel[] = Array.from(this.timetableEntries.values());
      let x = [];
      x = [...x, ...temp.filter(a => a.day == "Monday")];
      x = [...x, ...temp.filter(a => a.day == "Tuesday")];
      x = [...x, ...temp.filter(a => a.day == "Wednesday")];
      x = [...x, ...temp.filter(a => a.day == "Thursday")];
      x = [...x, ...temp.filter(a => a.day == "Friday")];
      this.array = x;
      if(this.timetableEntries.size == 0)
      this.toastr.info("No Data to Display")
    }, err => {
      console.log(err);
    });
      this.pdfvalid = true;
  }

  OnClassChange(c: HTMLSelectElement) {
    this.pdfvalid = false;

    this.classS = this.classes[c.selectedIndex].nm;
    this.updateSessions();
    this.updateSections();
  }

  OnSemesterChange(sem: HTMLSelectElement) {
    this.pdfvalid = false;

    this.updateSections();
  }
  OnSessionChange(s: HTMLSelectElement) {
    this.pdfvalid = false;

    this.se_id = this.sessions[s.selectedIndex].nm;
    this.updateSections();
  }

  onSecChange(sec: HTMLSelectElement) {

    this.subs = this.sectionsAndSub.get(sec.value);
    this.formRef.value.sub_code = null;
  }

  OnSubmit() {
    this.updateTimetable();
  }


  updateClasses(grp: number) {
    this.classes = [];
    this.sessions = [];
    this.sectionsAndSub.clear();

    let param = {};
    if (grp === -1) {
      param['sub_d_id'] = this.deptt;
    } else {
      param['grp'] = grp;
    }
    this.classService.getClasses(param).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
    });
  }

  updateSessions() {
    this.sessions = [];
    this.sectionsAndSub.clear();
    this.sessionService.getSessions(this.formRef.value.c_code).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(entry => {
        this.sessions.push({ id: entry.SE_ID, nm: entry.SE_NM });
      });
    });
  }


  updateSections() {
    const {c_code, se_id, t_no } = this.formRef.value;
    if (!this.formRef.value.t_no || this.formRef.value.se_id === "" || this.formRef.value.t_no === "")
      return;

    this.sectionsAndSub.clear();
    this.sections = [];
    this.utilService.getSectionsByTerm({ c_code, se_id, t_no, grp: this.group.toString(), isAdmin: this.isAdmin, dept: this.deptt}).subscribe((res: { section: string, sub_code: string, sub_nm }[]) => {
      if (res) {
        res.forEach(x => {
          if (this.sectionsAndSub.has(x.section))
            this.sectionsAndSub.get(x.section).push({ sub_code: x.sub_code, sub_nm: x.sub_nm });
          else
            this.sectionsAndSub.set(x.section, new Array<{ sub_code: string, sub_nm: string }>({ sub_code: x.sub_code, sub_nm: x.sub_nm }));
        });
        for (const i of this.sectionsAndSub.keys()) {
          this.sections.push(i);
        }
      }
    }, err => {
      console.log(err);
      this.toastr.error(err);
    });
  }
  private async sortByStartTimeTable() {

    this.array?.sort((a, b) =>
      a.sTime < b.sTime ? -1 :
        a.sTime > b.sTime ? 1 : 0
    )
  }
  OnCreatePDF() {
    this.sortByStartTimeTable();
    const body = [];
    const header = [];
    let k = 0;
    for (let i = 0; i < this.array.length; i++) {
      k++;
      body.push([k,
        this.array[i].fm,
        this.array[i].sub_code,
        this.array[i].subject,
        this.array[i].day,
        moment(this.array[i].sTime, 'h:mmA').format('hh:mmA') + "-" + moment(this.array[i].eTime, 'h:mmA').format('hh:mmA'),
        this.array[i].blkNm,
        this.array[i].rmNm,
        this.array[i].section,
      ]);
    }
    let exportDate = this.datePipe.transform(new Date(), 'MMM d,y')
    let image = new Image();
    let page;
    let cls = this.classS;
    let se = this.se_id;
    let to = this.formRef.value.t_no;
    image.src = '../../../assets/images/logo3.png';
    header.push(['Sr.', 'Teacher Name', 'Course Code', 'Course Name', 'Day', 'Time', 'Building', 'Room', 'Section']);
    const doc = new jsPDF('l', 'pt', "a4");
    autoTable(doc, {
      margin: { top: 70, bottom: 20 },
      head: header,
      body: body,
      didDrawPage: function () {
        doc.addImage(image, 400, 1, 55, 55);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 400, 65);
        doc.setFontSize(20);
        doc.setFont('Arial');
        doc.text("GC University Lahore", 38, 30);
        doc.setFontSize(18);
        doc.text(cls, 38, 50);
        doc.text(se, 700, 30);
        doc.text("Term No: " + to, 700, 50);
        doc.setFontSize(10);
        doc.text("Note:- Errors and Omissions Excepted", 650, 580);
        page = doc.getNumberOfPages();
        doc.text("Directorate of Information Technology", 38, 580);
      }
    });
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 400, 580);
      var p = body.length;
    }
    doc.setProperties({
      title: `Timetable-${cls}-${se}`,
      
    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }
  ClearForm(f) {
    f.resetForm();
    this.sections = [];
    this.sessions = [];
    if(this.isAdmin)
      this.classes = [];
    this.subs = [];
    this.pdfvalid = false;
  }
}
