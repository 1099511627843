<form  #f="ngForm">
    <div class=" container-fluid" [@SlideInFromLeft]>
      
       
           <div class="row card shadow" >
               <div class="card-header">
                   <div>Student Detail</div>
               </div>
               <div class="row card-body pb-0" >
                <div class="col-lg-4 col-md-4 form-group">
                    <label for="c_code">Class</label>
                    <select id="c_code" #cource (change)="getSessions()" name="c_code" class="form-control" ngModel>
                      <option *ngFor="let c of class" value="{{c.id}}">{{c.nm}}</option>
                    </select>
                  </div>
   
   
                  <!-- <div class="col-lg-4 col-md-4 form-group">
                    <label for="maj_id">Major</label>
                    <select id="maj_id" name="maj_id" class="form-control" #major (click)="getMajors()" ngModel>
                      <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
                    </select>
                  </div> -->

                  <div class="col-lg-4 col-md-4 form-group">
                    <label for="se_id">Session</label>
                    <select   class="form-control" name="se_id"
                      id="se_id" #session ngModel>
                      <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
                    </select>
                  </div>
                  
                  <div class="col-lg-4 col-md-4 form-group">
                    <label for="t_no">Term</label>
                    <select   class="form-control" name="t_no"
                      id="t_no" #t_no ngModel>
                      <option  value="{{1}}">1</option>
                    </select>
                  </div>

                  <div class="col-lg-4 col-md-4 form-group">
                    <label for="se_id">subject</label>
                    <select   class="form-control" name="sub_code"
                      id="sub_code" #subject ngModel>
                      <option *ngFor="let s of subject" value="{{s.id}}">{{s.nm}}</option>
                    </select>
                  </div>
          


                </div>
                <div class="row card-body pb-0" >
                    <div class="col-lg-4 col-md-4">
                        <!-- <button class="btn  btn-outline-primary mt-4" (click)="getstudent()" >
                            show detail
                        </button> -->
                    </div>
                </div>

                
                
                  
                 
               
               <!-- <div *ngIf="student_table.length!=0" class="card-body" style="overflow-x:scroll; margin-top: 2rem;">

                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>Sr.#</th>
                            <th>RN</th>
                            <th>Full Roll No</th>
                            <th>Name</th>
                            <th>Father Name</th>
                            <th>Set No</th>
                            <th>Set Name</th>
                            <th>Action</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let s of student_table;let j=index">
                            <td>
                                {{j+1}}
                            </td>
                            <td>
                                {{s.rn}}
                            </td>
                            <td>
                                {{s.rolno}}
                            </td>
                            <td>
                                {{s.nm}}
                            </td>
                            <td>
                                {{s.f_nm}}
                            </td>
                            <td>
                                {{s.set_no}}
                            </td>
                            <td>
                                {{s.set_nm}}
                            </td>
                            <td>
                                <button class="btn btn-primary"
                style="font-size:13px;padding-top:3%;padding-bottom:3%;margin-right:2%;margin-left:3%" (click)="getDetail(s.rolno)">Detail</button>
                            </td>



                            
                            
                        </tr>
                    </tbody>
                </table>
            </div> -->
        </div> 
    </div>
</form>
