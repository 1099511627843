import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class SubjectRequestService {

    constructor(private http: HttpClient) {
    }

    getAllReqSubjects(c_code: string, maj_id: string, se_id: string, t_no: string) {
        return this.http.get(`${baseUrl}/util/getAllReqSubjects/${c_code}/${maj_id}/${se_id}/${t_no}`);
    }


    getRequestedSubjectDetails(c_code, maj_id, se_id, t_no) {
        return this.http.get(`${baseUrl}/util/getRequestedSubjectDetails/${c_code}/${maj_id}/${se_id}/${t_no}`);
    }


    getRequestedSubjects() {
        return this.http.get(`${baseUrl}/util/getRequestedSubjects`);
    }
    


    RequestSubject(params) {
        return this.http.post(`${baseUrl}/util/requestSubject`, params);
    }

    rejectSubject(c_code, maj_id, se_id, t_no) {
        return this.http.get(`${baseUrl}/util/rejectSubject/${c_code}/${maj_id}/${se_id}/${t_no}`);
    }


    cancelSubReq(id: string) {
        return this.http.get(`${baseUrl}/util/cancelSubRequest/${id}`);
    }


    

}