import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';

@Injectable({
  providedIn: 'root'
})
export class ChalanService {

  constructor(private http: HttpClient) { 
  }
  getSemesterChalans(year:string,c_code:string,rn:string)
  {
return this.http.post(`${baseUrl}/api/AdmittedForms/getSemesterChalans`,{year,rn,c_code})
  }
  ShowChallanForms(c_code:string,maj_id:string,inst_no:string,year:string,b_id:string,m_list:string,f_rn:string,l_rn:string)
  {
    return this.http.post(`${baseUrl}/api/AdmittedForms/ShowChallanForms`,{c_code,maj_id,inst_no,year,b_id,m_list,f_rn,l_rn})
  }
  CH_ShowChallanForms(c_code:string,maj_id:string,inst_no:string,year:string,b_id:string,m_list:string,ch_no:string)
  {
    return this.http.post(`${baseUrl}/api/AdmittedForms/CH_ShowChallanForms`,{c_code,maj_id,inst_no,year,b_id,m_list,ch_no})
  }
  ClassAndMajor(c_code:string,maj_id:string)
  {
    return this.http.post(`${baseUrl}/api/AdmittedForms/ClassAndMajor`,{c_code,maj_id})
  }
  T_CheckPaidUnpaidShowChallan(c_code,maj_id,sem,year,ch_no,rn:any)
  {
      return this.http.post(`${baseUrl}/api/fee/TCheckPaidUnpaidShowChallan`, {c_code,maj_id,sem,year,ch_no,rn});
  }
  
}
