<div [@SlideInFromLeft] class="tabber">
    <div class="container-fluid card shadow px-0">
        <div class="card-header px-4">
            <div class="over">
                <div class="loader">
                </div>
                <!-- Loading... -->
            </div>
            <div>List Of Eligible candidates</div>
        </div>
        <form #f="ngForm" class="row px-4">
            <div class="row card-body space-remover px-3">
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="se_st_year"> Year</label>
                    <input type="number" #Passing_year class="form-control" min="0" name="Passing_year"
                        id="Passing_year" placeholder=" Year" ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="c_code">Class</label>
                    <select #c_code class="form-control" ngModel name="c_code" id="c_code" required
                        (change)="OnClassChange()">
                        <option *ngFor="let i of class" value="{{i.id}}">{{i.nm}} </option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="maj_id" class="text-left">Major
                    </label>
                    <select #maj_id id="maj_id" name="maj_id" ngModel class="form-control">
                        <option *ngFor="let i of majors" value="{{i.id}}">{{i.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="t_no">Semester</label>
                    <select #t_no class="form-control" ngModel name="t_no" id="t_no" required>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                    <label for="notifDate">Degree Start Date </label>
                    <input type="date" #notifDate class="form-control" ngModel name="notifDate" id="notifDate" required
                        ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                    <label for="notifDate1">Degree End Date </label>
                    <input type="date" #notifDate class="form-control" ngModel name="notifDate1" id="notifDate1"
                        required ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="category">Degree Category</label>
                    <select #category class="form-control" name="category" (change)="OnCategoryChange()" ngModel
                        id="category" required>
                        <option>Regular</option>
                        <option>Urgent</option>
                        <option>Duplicate</option>
                    </select>
                </div>

                <div class="test ml-3 d-flex flex-column">
                    <button [disabled]="!f.valid" class="btn btn-primary" style="margin-top:28px;" type="button"
                        (click)="clickCNIC(100)">Generate List CNIC
                    </button>
                </div>
                <div class="test ml-3 d-flex flex-column">
                    <button [disabled]="!f.valid" class="btn btn-primary" style="margin-top:28px ;"
                        type="button" (click)="click()">Generate List
                    </button>
                </div>
                <div *ngIf="this.CNICDetails?.length" class="test ml-3">
                    <button class="btn btn-danger ml-1" [disabled]="!f.valid" type="button"
                        style="margin-top:28px ;" (click)="CreatePdfCNIC()">
                        <i class="fa fa-file-pdf-o" for=""></i>
                        Download Pdf
                    </button>
                </div>
                <div *ngIf="this.CNICDetails?.length" class="test ml-3">
                    <button class="btn btn-success ml-1" [disabled]="!f.valid" type="button"
                        style="margin-top:28px ;" (click)="DownloadExcelCNIC()">
                        <i class="fa fa-file-excel-o" for=""></i>
                        Download Excel
                    </button>
                </div>
                <div *ngIf="this.Details?.length" class="test ml-3">
                    <button class="btn btn-danger ml-1" [disabled]="!f.valid" type="button"
                        style="margin-top:28px ;" (click)="CreatePdf()">
                        <i class="fa fa-file-pdf-o" for=""></i>
                        Download Pdf
                    </button>
                </div>
                <div *ngIf="this.Details?.length" class="test ml-3">
                    <button class="btn btn-success ml-1" [disabled]="!f.valid" type="button"
                        style="margin-top:28px ;" (click)="DownloadExcel()">
                        <i class="fa fa-file-excel-o" for=""></i>
                        Download Excel
                    </button>
                </div>

                <div class="test ml-3">
                    <button class="btn btn-warning ml-1" type="button" style="margin-top:28px ;"
                        (click)="OnClear(f)">Clear Form
                    </button>
                </div>
            </div>
        </form>
        <loader *ngIf="loader"></loader>
        <div class="px-4">
            <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1" *ngIf="Details?.length">  
                <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
            </div>
            <table *ngIf="Details?.length" class="table table-bordered table-striped mt-4" id="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col" style="width: 4vw">Sr #</th>
                        <th scope="col" style="width: 7vw">Degree #</th>
                        <th scope="col" style="width: 15vw">Registration No</th>
                        <th scope="col" style="width: 15vw">Name</th>
                        <th scope="col" style="width: 5vw">G</th>
                        <th scope="col" style="width: 15vw">Father Name</th>
                        <th scope="col" style="width: 15vw">Roll No</th>
                        <th scope="col" style="width: 7vw">CGPA</th>
                        <th scope="col" style="width: 7vw">Year</th>
                        <!-- <th scope="col" style="width: 15vw">Major</th> -->
                        <th scope="col" style="width: 5vw">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let detail of Details let i=index " id="values">
                        <td>{{i+1}}</td>
                        <td>{{detail.Deg_no }}</td>
                        <td>{{detail.Reg_no }}</td>
                        <td>{{detail.Name}}</td>
                        <td>{{detail.gender}}</td>
                        <td> {{detail.F_name }} </td>
                        <td>{{detail.rollno}}</td>
                        <td>{{detail.CGPA}}</td>
                        <td>{{detail.year}}</td>
                        <!-- <td>{{detail.major}}</td> -->
                        <td>
                            <button class="btn btn-outline-danger btn-sm " #num1 type="button " (click)=" del(detail)" style="font-size: 9px;">
                                <i class="fa fa-edit "></i>Delete
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="px-4">
            <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1" *ngIf="CNICDetails?.length" >  
                <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
            </div>
            <table *ngIf="CNICDetails?.length" class="table table-bordered table-striped mt-4" id="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col" style="width: 4vw">Sr #</th>
                        <th scope="col" style="width: 7vw">Degree #</th>
                        <th scope="col" style="width: 15vw">Registration No</th>
                        <th scope="col" style="width: 15vw">Name</th>
                        <th scope="col" style="width: 6vw">G</th>
                        <th scope="col" style="width: 15vw">Father Name</th>
                        <th scope="col" style="width: 7vw">CNIC</th>
                        <th scope="col" style="width: 7vw">Phone No</th>
                        <th scope="col" style="width: 17vw">Roll No</th>
                        <th scope="col" style="width: 6vw">CGPA</th>
                        <th scope="col" style="width: 6vw">Year</th>
                        <!-- <th scope="col" style="width: 17vw">Major</th> -->
                        <th scope="col" style="width: 4vw">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let detail of CNICDetails let i=index " id="values">
                        <td class="px-0">{{i+1}}</td>
                        <td class="px-0">{{detail.Deg_no }}</td>
                        <td class="px-0">{{detail.Reg_no }}</td>
                        <td class="px-1">{{detail.Name}}</td>
                        <td class="px-0">{{detail.gender}}</td>
                        <td class="px-1"> {{detail.F_name }} </td>
                        <td class="px-1"> {{detail.NIC }} </td>
                        <td class="px-0"> {{detail.ph1 }} </td>
                        <td class="px-0">{{detail.rollno}}</td>
                        <td class="px-0">{{detail.CGPA}}</td>
                        <td class="px-0">{{detail.year}}</td>
                        <!-- <td style="font-size: 9px;" class="px-0">{{detail.major}}</td> -->
                        <td>
                            <button class="btn btn-outline-danger btn-sm" style="font-size: 9px;" #num1 type="button" (click)=" del(detail)">
                                <i class="fa fa-edit "></i>Delete
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>