import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from '../shared/services/Class.service';
import { MajorService } from '../shared/services/Major.service';
import { EnrollmentService } from '../shared/services/Enrollment.service';
import { AuthenticationService } from 'src/app/auth/_services';

@Component({
  selector: 'app-single-enrollment',
  templateUrl: './single-enrollment.component.html',
  styleUrls: ['./single-enrollment.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class SingleEnrollmentComponent implements OnInit {
  departments: Array<SelectList>;
  private usr;
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  rolno:Array<string>;
  subjects:Array<{sub_code:string,sub_nm:string,section:string}>;
  @ViewChild('f') formRef: NgForm;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  You can enroll a single studnet in all subjects of any major of 
  different year and semester in a class and you can check and d
delete the enrollment of single student in all subjects of its semester </h6>
  <h6 class="text-right">آپ کسی بھی میجر کے مختلف سال اور سمسٹر کے ایک کلاس میں تمام سبجیکٹس میں ایک سنگل طالب علم کو انرول کر سکتے ہیں اور آپ سنگل طالب علم کی تمام سبجیکٹس میں اس کے سمسٹر کی انرولمنٹ کو چیک اور ڈیلیٹ کر سکتے ہیں۔
</h6>
  `  ;
  constructor(
    private authenticationservice:AuthenticationService,
    private toastr: ToastrService,
    private majorService:MajorService,
    private classService:ClassService,
    private enrollmentService:EnrollmentService
  ) { 
    this.classes = [];
    this.majors = [];
    this.sessions = [];
    this.subjects=[];
    this.usr=this.authenticationservice.getUser();
  }


  ngOnInit(): void {
    this.istm=[];
    this.loader=false;
    this.classService.getClasses({}).subscribe((res:{C_CODE: number, C_NM: string}[]) => {
      res?.forEach(entry=>{
        this.classes.push({id: entry.C_CODE, nm: entry.C_NM});
      });
    });
  }

  updateMajors() {
    this.majors = [];
    this.sessions = [];
    this.majorService.getMajor({c_code: this.formRef.value.c_code}).subscribe((res:{MAJ_ID:number,MAJ_NM:string}[]) => {
      res?.forEach(entry=>{
        this.majors.push({id: entry.MAJ_ID, nm: entry.MAJ_NM});
      });
    });
  }

 Get_Rolno(){
  this.rolno=[];
  this.enrollmentService.notEnroll(this.formRef.value).subscribe((res:{rolno:string}[]) => {
    res?.forEach(entry => {
      this.rolno.push(entry.rolno);
    });
    },err=>{
      console.log(err);
      this.toastr.error("Unknown Error!");
  });
 }
  
  OnSingleEnroll(){    
    this.loader=true;
    this.subjects=[];
    this.toastr.clear()
    //check if timetable exists...if it exists, only then enroll.
    //if istm.length is zero then tm does not exists
    // if(this.istm.length==0)
    // {
    //   this.loader=false;
    //   this.toastr.clear();
    //   this.toastr.warning("Time Table Not Entered !!");     
    //   return 
    // }
    const {t_no}=this.formRef.value;        
    if(t_no>=1 && t_no<5)
    {
      if(this.allowenrol.length==0)
    {
      this.loader=false;
      this.toastr.warning("No Subject Offered Against Student's Set");     
      return 
    }
    }
    //check if subjects are offered against student set_no then enroll otherwise don't        
    if(this.formRef.valid){      
      this.toastr.info("Enrolling Please Wait...",null,{timeOut:120000})
      this.enrollmentService.singleEnroll(this.formRef.value).subscribe((res:{msg:{msg:string},Subjects:{SUB_CODE:string,SUB_NM:string,SECTION:string}[]}) => {
        this.loader=false;
        this.toastr.clear();
        if(!res?.msg){
          this.toastr.error('Could not Enroll');
        }
        else{
          this.updateSubjects(res?.Subjects);
          this.toastr.success(`Enrolled Successfully!`);
          //this.toastr.success(`Enrolled Successfully!    ${res.msg[0].msg}`);
          
        }
      },err=>{
        this.loader=false;
        console.log(err);
      });
    }
    else{
      this.toastr.warning("Some Fields are Missing!");
    }
  }
  OnCheckEnroll(){
    this.toastr.clear()
    this.loader=true;
    this.toastr.info("Checking Please Wait...",null,{timeOut:120000})
    this.subjects=[];
    if(this.formRef.valid){
      this.enrollmentService.SAO_checkEnrollment(this.formRef.value).subscribe((res:{SUB_CODE:string,SUB_NM:string,SECTION:string}[]) => {
        this.loader=false;
        this.toastr.clear();
        if(res.length==0){
          this.toastr.warning('Student is not Enrolled.');
          return;
        }
        else{
          this.toastr.info('Enrollment is Found.');
          this.updateSubjects(res);
        }
      },err=>{
        this.loader=false;
        console.log(err);
      });
    }
    else{
      this.toastr.warning("Some Fields are Missing!");
    }
  }
  // subject updation this need in two functions.
  updateSubjects(res:{SUB_CODE:string,SUB_NM:string,SECTION:string}[]){
    res?.forEach(e =>{
      this.subjects.push({sub_code:e.SUB_CODE,sub_nm:e.SUB_NM,section:e.SECTION})
    })
  }
  OnDeleteEnroll(){
    this.loader=true;
    this.toastr.clear()
    this.subjects=[];
    if(this.formRef.valid){
      this.toastr.info("Deleting Please Wait...",null,{timeOut:120000})
      const {c_code,se_id,t_no,year_in,maj_id,rolno}=this.formRef.value;
      const facID=this.usr.FM_ID;
      // this.enrollmentService.SAO_deleteEnrollment(this.formRef.value).subscribe((res:{affectedRows:number}) => {
        this.enrollmentService.SAO_deleteEnrollment({c_code:c_code,se_id:se_id,t_no:t_no,year_in:year_in,maj_id:maj_id,facID:facID,rolno:rolno}).subscribe((res:{affectedRows:number}) => {
          this.loader=false;
          this.toastr.clear()
          this.toastr.success('Enrollment Deleted');
        // if(res?.affectedRows==0){
        //   this.toastr.error('Student is not Enrolled.');
        //   return;
        // }
        // else{
        //   this.toastr.info(`Deleted ${res?.affectedRows} Rows Successfully.`);
        // }
      },err=>{
        this.loader=false;
        console.log(err);
      });
    }
    else{
      this.toastr.warning("Some Fields are Missing!");
    }
  }
 
  ClearForm(fn: NgForm) {
    this.majors=[];
    this.rolno=[];
    fn.resetForm();
  }

  
  OnClassChange(c: HTMLSelectElement) {
    this.updateMajors();
  }

  loader=false;
  allowenrol:any
  istm=[]
  onRollNumberChange(){
    this.toastr.clear()
    const {c_code,maj_id,year_in,t_no,rolno}=this.formRef.value;
    if(t_no>=1 && t_no<5)
    this.enrollmentService.checksubjectsetinfoagain(c_code,maj_id, year_in, t_no,rolno).subscribe((res) => {        
      //this.istm=res[2];
      this.allowenrol=res[1]
      if (res[1].length==0)      
      {                          
        this.toastr.clear();
          this.toastr.warning(`Student Set is "${res[0][0].set_no}", No Subject Offered against set "${res[0][0].set_no}", Please Offer Subjects First then Enroll`,null,{timeOut: 120000}); 
      }
      else
      {
        this.toastr.clear()
        this.toastr.success(`Subjects Offered Successfully, You can Enroll`,null,{timeOut: 10000}); 
      }
    });    
    
  }
 
}