import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class FreezeService {

    constructor(private http: HttpClient) {
    }
    getNoRollDes(){
        return this.http.get(`${baseUrl}/api/semesterFreeze/getListOfNoRoll_Res`);
    }
    getNoRollStdt(param){
        return this.http.post(`${baseUrl}/api/semesterFreeze/getNoRollStdt`,param);
    }
    AddNoRollStdt(param){
        return this.http.post(`${baseUrl}/api/semesterFreeze/AddNoRollStdt`,param);
    }
    DeleteNoRollStdt(param){
        return this.http.post(`${baseUrl}/api/semesterFreeze/DeleteNoRollStdt`,param);
    }
    UpdateNoRollStdt(param){
        return this.http.post(`${baseUrl}/api/semesterFreeze/UpdateNoRollStdt`,param);
    }
    AddNoRollStdtFee(param){
        return this.http.post(`${baseUrl}/api/semesterFreeze/AddNoRollStdt`,param);
    }
    DeleteNoRollStdtFee(param){
        return this.http.post(`${baseUrl}/api/semesterFreeze/DeleteNoRollStdt`,param);
    }
    UpdateNoRollStdtFee(param){
        return this.http.post(`${baseUrl}/api/semesterFreeze/UpdateNoRollStdt`,param);
    }
    AddNoRollRemarks(param){
        return this.http.post(`${baseUrl}/api/semesterFreeze/AddNoRollRemarks`,param);
    }
    FreezeReportDate(param){
        return this.http.post(`${baseUrl}/api/semesterFreeze/FreezeReportDate`,param);
    }
    FreezeReportClass(param){
        return this.http.post(`${baseUrl}/api/semesterFreeze/FreezeReportClass`,param);
    }
}