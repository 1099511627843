import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../../../axios';

@Injectable({
    providedIn: 'root'
})

export class EvaluationFormReportsServices {

    constructor(private http: HttpClient) {
    }
    EV_F_getSingleSubjectReport(params) {
        return this.http.post(`${baseUrl}/api/formsReports/EV_F_getSingleSubjectReport`, params);
    }
    EV_F_compilegrp_dpt_rpt(params) {
        return this.http.post(`${baseUrl}/api/formsReports/EV_F_compilegrp_dpt_rpt`, params);
    }

    EV_F_un_compilegrp_dpt_rpt(params) {
        return this.http.post(`${baseUrl}/api/formsReports/EV_F_un_compilegrp_dpt_rpt`, params);
    }

    EV_F_getRptbyGroup(grp_id:string, year_in:number) {
        return this.http.get(`${baseUrl}/api/formsReports/EV_F_getRptbyGroup/${grp_id}/${year_in}`);
    }
    EV_F_getEvaluationFormComments(fm_id:number,t_no:number,section:string,sub_code:string,frm_id:number) {
        return this.http.get(`${baseUrl}/api/formsReports/EV_F_getEvaluationFormComments/${fm_id}/${t_no}/${section}/${sub_code}/${frm_id}`);
    }
    EV_F_getSdtFrmSubDetail(frm_id:number,c_code:number,se_id:number,t_no:number,sub_code:string,section:string){
        return this.http.get(`${baseUrl}/api/formsReports/EV_F_getSdtFrmSubDetail/${frm_id}/${c_code}/${se_id}/${t_no}/${sub_code}/${section}`);
    }

    EV_F_getRptByDept(d_id:string, year_in:number) {
        return this.http.get(`${baseUrl}/api/formsReports/EV_F_getRptByDept/${d_id}/${year_in}`);
    }
    EV_F_getSectionForTchr(params)
    {
        return this.http.post(`${baseUrl}/api/formsReports/EV_F_getSectionForTchr`,params)
    }

    // utilities
    EV_F_un_fillForm(params){
        return this.http.post(`${baseUrl}/api/formsReports/EV_F_un_fillForm`, params);
    }
    EV_F_fillForm(params){
        return this.http.post(`${baseUrl}/api/formsReports/EV_F_fillForm`, params);
    }
    getGrade(per:number):string{
        if(per>=85 && per<=100) return 'A';
        else if(per>=71 && per<=84) return 'B';
        else if(per>=60 && per<=70) return 'C';
        else return 'D';
    }
}