<div class="backdrop">
  <div class="row">
    <div class="col-sm-6 offset-sm-3 ml-2 mr-2 ml-sm-auto mr-sm-auto">
      <div class="alert-box">
        <form
          action=""
          class="clearfix"
          (ngSubmit)="OnChangeClicked(form)"
          #form="ngForm"
        >
          <div class="form-group mb-4">
            <label for="usnm"><b>Username </b></label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="nic"
              name="usnm"
              id="usnm"
              readonly
            />
          </div>
          <div class="form-group mb-4">
            <label for="inputOldPassword"><b>Old Password</b></label>
            <div class="position-relative">
              <input
                [type]="showPassword['oldpass'] ? 'text' : 'password'"
                class="form-control"
                ngModel
                name="oldpass"
                id="inputOldPassword"
                required
              />
              <i
                class="show-password"
                (click)="togglePasswordVisibility('oldpass')"
              >
                <i
                  class="fa"
                  [ngClass]="
                    showPassword['oldpass'] ? 'fa-eye-slash' : 'fa-eye'
                  "
                  style="color: darkgray"
                ></i>
              </i>
            </div>
          </div>
          <div class="form-group mb-4">
            <label for="inputNewPassword"><b>New Password</b></label>
            <div class="position-relative">
              <input
                [type]="showPassword['newpass'] ? 'text' : 'password'"
                class="form-control"
                ngModel
                name="newpass"
                id="inputNewPassword"
                required
                (input)="checkPasswordStrength(form.value.newpass)"
                [ngClass]="{
                  'is-invalid': !isPasswordStrong(),
                  'is-valid': isPasswordStrong()
                }"
              />
              <i
                class="show-password"
                (click)="togglePasswordVisibility('newpass')"
              >
                <i
                  class="fa"
                  [ngClass]="
                    showPassword['newpass'] ? 'fa-eye-slash' : 'fa-eye'
                  "
                  style="color: darkgray"
                ></i>
              </i>
            </div>
            <div class="password-guidelines">
              <p
                [ngClass]="{
                  'text-danger':
                    !hasUpperCase ||
                    !hasLowerCase ||
                    !hasNumber ||
                    !hasSpecialChar ||
                    !isMinLength,
                  'text-success':
                    hasUpperCase &&
                    hasLowerCase &&
                    hasNumber &&
                    hasSpecialChar &&
                    isMinLength
                }"
              >
                Use at least one uppercase letter, one lowercase letter, one
                number, one special character, and minimum 8 characters
              </p>
            </div>
          </div>
          <div class="position-relative form-group">
            <label for="confirmpass"><b>Confirm Password</b></label>
            <div class="position-relative">
              <input
                [type]="showPassword['confirmpass'] ? 'text' : 'password'"
                #confirmpass
                name="confirmpass"
                id="confirmpass"
                ngModel
                required
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    submitted && form.value.newpass !== form.value.confirmpass
                }"
              />
              <i
                class="show-password"
                (click)="togglePasswordVisibility('confirmpass')"
              >
                <i
                  class="fa"
                  [ngClass]="
                    showPassword['confirmpass'] ? 'fa-eye-slash' : 'fa-eye'
                  "
                  style="color: darkgray"
                ></i>
              </i>
            </div>
            <div
              *ngIf="
                form.value.newpass !== form.value.confirmpass &&
                confirmpass.value !== ''
              "
              class="invalid-feedback"
            >
              <div><p>Password is Mismatched</p></div>
            </div>
          </div>
          <button
            [disabled]="!form.valid || !isPasswordStrong()"
            class="btn btn-danger float-right"
            type="submit"
          >
            Change
          </button>
          <button
            class="btn btn-outline-secondary float-right mr-2"
            (click)="onClose()"
          >
            Close
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
