<div class="card" [@SlideInFromLeft]>
    <div class="card-header"  style="padding-top: 10px;">
      <div class=" col-lg-6 col-md-6 form-group">
        Section Allocation</div>
         <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
        <app-details-button [details]="details"></app-details-button>
    </div>
  </div>
  
  <div class="card-body">
    <form (ngSubmit)="OnSubmit()" #f="ngForm">
      <div class="row">


        <div class="col-lg-4 col-md-6 form-group">
          <label for="Day">Group</label>
          <input *ngIf="!isAdmin&&!isExam  && this.isPhilosophy!=410  && this.isPhilosophy!=320" type="text"
            class="form-control" value='{{ ((this.groups.length>0) ? groups[0].nm:" ")}}' name="grp" id="grp" readonly>
          <select *ngIf="this.isPhilosophy==410 || this.isPhilosophy==320" #grp class="form-control" name="grp" id="grp"
            (change)="OnGrpChange(grp)" ngModel>
            <option *ngFor="let grp of groups" value="{{grp.id}}">{{grp.nm}}</option>
          </select>
          <select *ngIf="isAdmin||isExam" #grp class="form-control" name="grp" id="grp" (change)="OnGrpChange(grp)"
            ngModel required>
            <option *ngFor="let grp of groups" value="{{grp.id}}">{{grp.nm}}</option>
          </select>
          <select *ngIf="showInter&&this.isPhilosophy!=410" #grp class="form-control" name="grp" id="grp"
            (change)="OnGrpChange(grp)" ngModel>
            <option *ngFor="let grp of interMediateGroup" value="{{grp.id}}"> {{grp.nm}} </option>
          </select>

        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="C_Code">Class</label>
          <select #cource (change)="OnClassChange(cource)" class="form-control " name="c_code" id="C_Code" ngModel>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Major">Major</label>
          <select class="form-control" #major (change)="OnMajorChange(major)" name="maj_id" id="Major" ngModel>
            <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Session">Session</label>
          <select class="form-control " #session (change)="OnSessionChange(session)" name="se_id" id="Session" ngModel>
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Session">Semester</label>
          <select #semester class="form-control " #session (change)="OnSemesterChange(semester)" name="t_no"
            id="Semester" ngModel>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Subject">Subject</label>
          <select #subject class="form-control" (change)="OnSubjectChange(subject)" name="sub_code" id="Subject"
            ngModel>
            <option *ngFor="let sub of subjects" value="{{sub.sub_code}}">{{sub.sub_code}} {{sub.sub_nm}}</option>
          </select>
        </div>

        <div class="row card-body pl-4" style="margin-top: -2rem;">
          <button (click)="getStudents()"
            [disabled]="!(this.classes?.length&&this.sessions.length&&this.majors.length&&this.subjects.length)"
            class="btn mt-2 ml-2 btn-primary">
            Fetch Data
          </button>
          <button (click)="create_csv(major.value,session.value)" [disabled]="!(this.students?.length>0)"
            class="btn ml-2 mt-2 btn-success">
            Download Excel
          </button>
          <button (click)="Clear(f)" class="btn  btn-warning mt-2 ml-2">
            Clear
          </button>
        </div>
      </div>

      <div *ngIf="this.students?.length>0" class="card-header" style="margin-top: -4rem;">
      </div>
      <div *ngIf="this.students?.length>0" class="card-body list-group px-0"
        style="min-height: 10rem; overflow: hidden; overflow-x: scroll">
        <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">
          <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput"
            (keydown)="Filter()">
        </div>
        <table class="mb-0 table table-bordered table-striped" id="table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Sr No</th>
              <th scope="col">Roll No</th>
              <th scope="col">Name</th>
              <th scope="col">Current Section</th>
              <th *ngFor="let sec of section" style="padding: 0px 1px 1px 1px; width: 6vw" class="mt-0 pt-0 pb-1">
                <div #section class="section section-head pt-0 mt-0" id="whole_class"
                  (click)="UpdateClassSection(section)" (mouseenter)="Popover($event)">
                  {{sec}}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let s of students; let i=index;" id="values">
              <td>{{i+1}}</td>
              <td>{{s.ROLNO.toUpperCase()}}</td>
              <td>{{s.NM.toUpperCase()}}</td>
              <td #curr class="curr" id="new-section" style="background-color:#e3e3e5;">{{s.SECTION}}</td>
              <td *ngFor="let sec of section" style="padding: 2px;">
                <div #section class="section"
                  [ngClass]="{'clicked': s.SECTION===sec, 'section-body': s.SECTION != sec }"
                  (click)="UpdateStudentSection(i, sec,this.curr,s.RN,s.YEAR)" style="color: black; cursor: pointer">
                  {{sec}}</div>
              </td>
          </tbody>
        </table>
      </div>
    </form>
  </div>
</div>