<div *ngIf="isOpen" class="faq-modal card">
  <form #f="ngForm">
    <div class="faq-modal-content">
      <div class="card-header col-lg-12 col-md-12">
        <div class="col-lg-6 col-md-6 form-group">
          <h2 style="color: royalblue;">FAQs</h2>
        </div>
        <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
          <!-- <span *ngIf="isAdmin" class="add-faq-button fa fa-plus" (click)="openAddFaqModal()">Add</span> -->
          <!-- <span class="faq-close fa fa-times" (click)="closeModal()">Close</span> -->
          <button type="button" class="btn btn-outline-primary" (click)="openAddFaqModal()" style="margin-right: 10px; border: none;">
            <i> <img src="../../../assets/insert.png" alt="" style="width: 1.9rem;"></i>
          </button>
          
          <button type="button" class="close faq-close" (click)="closeModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="card-body col-lg-12 col-md-12 scroll mt-0">
        <div class="card-body col-lg-12 col-md-12">
          <ul class="form-group col-lg-12 col-md-12 faq-list scroll">
            <li *ngFor="let faq of faqs; let i = index" class="faq-item">
              <!-- Question -->
              <div class="faq-question" (click)="toggleFaq(i)">
                {{ faq.question }}
              </div>

              <!-- Answer (collapsible) -->
              <div *ngIf="openQuestionIndex === i" class="faq-answer">
                {{ faq.answer }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </form>
  <div id="addFaqModal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <app-faq-add (closeModal)="closeAddFaqModal()"></app-faq-add>
      </div>
    </div>
  </div>
</div>