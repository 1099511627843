import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../../shared/model/SelectList.model';

import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { FeeService } from '../../shared/services/Fee.service';

@Component({
  selector: 'app-fine-description',
  templateUrl: './fine-description.component.html',
  styleUrls: ['./fine-description.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class FineDescriptionComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;
  fineDesc: Array<SelectList>;
  flag: boolean = true;

  


  constructor(
    private toastr: ToastrService,
    private feeService:FeeService
  ) { 
    this.fineDesc = new Array<SelectList>();

  }


  ngOnInit(): void {
    this.loadFineDescription();
  }

  myFunction() {
    this.flag = !this.flag;
    var popUp;
    popUp = document.getElementById("myPopup");


    if (popUp.style.display === "none") {
      popUp.style.display = "block";
    } else {
      popUp.style.display = "none";
    }
  }
  
  OnSubmit(){
  }

  loadFineDescription() {
    this.fineDesc = []; 
    this.feeService.getFineDescription().subscribe((res: { fine_id: number, fine_nm: string }[]) => {
      res?.forEach(element => {
        this.fineDesc.push({ id: element.fine_id, nm: element.fine_nm });
      });
    }, err => {
      console.log(err);
    });

  }

  addFineDescription() {
    if(this.formRef.valid){
      this.feeService.addFineDescription(this.formRef.value.fine_id, this.formRef.value.fine_desc).subscribe((res:{warning:string,fine_id:number,fine_nm:string}) => {
        if(!res){
          this.toastr.error("Unknown Error");
        }
        else if(res.warning){
          this.toastr.warning(res.warning);
        }
        else{
          this.fineDesc.push({id:res.fine_id,nm:res.fine_nm});
          this.toastr.success("Add Successfully!");        }
      }, err => {
        this.toastr.error("Some Error Occured during Adding Fee Description!");
      });
    }
    else{
      this.toastr.warning("Some Fields are Missing!");
    }
  }

  deleteFine(id,index){
      this.feeService.deleteFineDescription(id).subscribe((res:{msg:string, warning:string}) => {
        if(!res){
          this.toastr.error("Unknown Error");
        }
        else if(res.msg){
          this.toastr.success(res.msg);
          this.fineDesc.splice(index,1);
        }
        else{
          this.toastr.warning(res.warning);
        }
      }, err => {
        this.toastr.error("Some Error Occured during Deleting Fee Description!");
      });
  }


  ClearForm(fn: NgForm) {
    fn.resetForm();
  }

  ClearFineIDBase() {
    this.formRef?.controls['fine_desc'].reset();
  }

}
