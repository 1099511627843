import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../../../axios';
@Injectable({
    providedIn: 'root'
})
export class AwardListServices {
    constructor(private http: HttpClient) {
    }

    getAwardList(grp_id:string,c_code: string, se_id: string, t_no: string, sub_code: string, section: string) {
        return this.http.get(`${baseUrl}/awardList/getAwardList/${grp_id}/${c_code}/${se_id}/${t_no}/${sub_code}/${section}`);
    }

    getAwardlistAndStd(params){
        return this.http.post(`${baseUrl}/awardList/getAwardlistAndStd`, params);
    }
    
    getOfferedSubByGroup(grp_id:string,c_code: string, se_id: string, t_no: string){
        return this.http.get(`${baseUrl}/awardList/getOfferedSubByGroup/${grp_id}/${c_code}/${se_id}/${t_no}`);
    }

    getOfferedSubAndSectionByGroup(grp_id:string,c_code: string, se_id: string, t_no: string){
        return this.http.get(`${baseUrl}/api/subjectsOffer/getOfferedSubAndSectionByGroup/${grp_id}/${c_code}/${se_id}/${t_no}`);
    }

    getSectionNames(grp:string, se_id:string, t_no:string, c_code: string, maj_id:string, sub_code: string){
        return this.http.get(`${baseUrl}/awardList/getSectionNames/${grp}/${se_id}/${t_no}/${c_code}/${maj_id}/${sub_code}`);
    }

    updateTotalWeightageChange(params){
        return this.http.post(`${baseUrl}/awardList/updateTotalWeightageChange`,params)
    }

    deleteStdAwardlistandmore(params){
        return this.http.post(`${baseUrl}/awardList/deleteStdAwardlistandmore`,params)
    }

    updateSingleStdMrks_in_AWL(params){
        return this.http.post(`${baseUrl}/awardList/updateSingleStdMrks_in_AWL`,params)
    }

    createAwardlist(param){
        return this.http.post(`${baseUrl}/awardList/createAwardlist`, param);
    }
    //for single std for single subject
    SAO_generateAwardListSingleStd(param){
        return this.http.post(`${baseUrl}/awardList/SAO_generateAwardListSingleStd`, param);
    }
    deleteSingleSubjStdAwardList(param){
        return this.http.post(`${baseUrl}/awardList/deleteSingleSubjStdAwardList`, param);
    }

    getSingleStudentAwardList(year:number,c_code: number,rn:number,t_no: number,maj_id:number,sub_code: string, se_id: number,  section: string) {
        return this.http.get(`${baseUrl}/awardList/CheckSingleAwardList/${year}/${c_code}/${rn}/${t_no}/${maj_id}/${sub_code}/${se_id}/${section}`);
    }
}