<div [@SlideInFromLeft] class="tabber backdrop"
    style="overflow:scroll; overflow-y: scroll; margin-left: 5%; margin-top: 1%;" *ngIf="!closed">
<div
    class="container-fluid ml-1 mb-1 card shadow alert-box  col-lg-8 ml-2 col-md-12 offset-sm-3 ml-2 mr-2 ml-sm-auto mr-sm-auto">
    <div class="card-header d-flex justify-content-between">
        <div>{{header}}</div>
        <button (click)="onClose()"
            class="btn btn-outline-primary btn-sm">Close
        </button>
    </div>
    <app-detailedstudent *ngIf="component == 'detailedstudent'"
        [type]="'popup'" [data]="data">
    </app-detailedstudent>
</div>