<div [@SlideInFromLeft] class="backdrop" style="overflow:hidden; overflow-y: scroll">
  <div class="row">
    <div class="col-lg-6 col-md-12 offset-sm-3 ml-2 mr-2 ml-sm-auto mr-sm-auto">
      <div class="alert-box">
        <form #f="ngForm">
          <div class="container-fluid mb-2 card" style="margin-bottom: 5px;">
            <div class="card-header  d-flex justify-content-between" style="margin-bottom: -20px">
              Choose From other Department 
              <a href="javascript:void(0);" (click)="OnClose()" style="text-transform: lowercase;">
                close</a>
            </div>

            <div class="row card-body" style="margin-bottom: -30px">

              <div class="col-lg-4 col-md-4 form-group">
                <label for="Department">Department</label>
                <select #department class="form-control" name="d_id" id="Department" (change)="OnDeptChange()" required
                  ngModel>
                  <option *ngFor="let d of departments" value="{{d.id}}">{{d.nm}}</option>
                </select>
              </div>

              <div class="col-lg-4 col-md-4 form-group">
                <label for="Teacher">Teacher</label>
                <select #teacher class="form-control" name="fm_id" id="Teacher" required ngModel>
                  <option *ngFor="let fm of teachers |keyvalue"  value="{{fm.key}}">{{fm.value.nm}}</option>
                </select>
              </div>

              <button class="btn btn-primary mt-2" (click)="OnChoose()" [disabled]="!f.valid"
                style="width: 100px; height: 40px; margin-left: 30px; text-align: center;" type="submit">Choose</button>

            </div>
          </div>
        </form>
        <app-addteacher (newTeacher)="OnNewTeacherAdded($event)"></app-addteacher>
      </div>
    </div>
  </div>
</div>