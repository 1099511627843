import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { SlideInFromLeft } from "src/app/transitions";
import { SelectList } from "../../shared/model/SelectList.model";
import { DatePipe } from "@angular/common";
import { ExaminationService } from "../../shared/services/Examination.service";
import autoTable from "jspdf-autotable";
import jsPDF from "jspdf";
import { LMSharedDataService } from "src/app/shared/LMSSharedService";
import { filter } from "src/app/shared/functions/tableSearch";

export interface StudentRecord {
  SUB_CODE: string;
  SUB_TITLE: string;
  SECTION: string;
  TOTAL: number;
}
export interface MajorRecord {
  SUB_CODE: string;
  SUB_TITLE: string;
  MAJOR: string;
  SECTION: string;
  TOTAL: number;
}

@Component({
  selector: 'app-std-strength-sub-sec',
  templateUrl: './std-strength-sub-sec.component.html',
  styleUrls: ['./std-strength-sub-sec.component.css'],
  animations: [SlideInFromLeft()],
})
export class StdStrengthSubSecComponent implements OnInit {
  @ViewChild("f") formRef: NgForm;
  classes: Array<SelectList>;
  sessions: Array<SelectList>;
  refineStd: Array<StudentRecord>;
  loader: boolean;
  stdStrenght:Array<{ SUB_CODE: string,SUB_TITLE: string,TOTAL: number}>;
  majcount:Array<MajorRecord>;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  you can check the students strength in each offered subject  in a semester and count of students according 
  section wise as well as the count of students according to  the major and section in any semester of any session in its class and download the report as well  </h6>
  <h6 class="text-right">آپ کسی بھی سیشن کے کسی بھی سمسٹر میں کلاس کے ہر آفر کردہ سبجیکٹ میں طلباء کی تعداد، سیکشن وائز طلباء کی تعداد، اور میجر و سیکشن کے لحاظ سے طلباء کی تعداد چیک کر سکتے ہیں اور رپورٹ بھی ڈاؤن لوڈ کر سکتے ہیں۔
</h6>
  `  ;

  constructor(
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private examinationService: ExaminationService,
    private lmsSharedService: LMSharedDataService) {
    this.loader = false;
    this.classes = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.refineStd = new Array<StudentRecord>();
    this.stdStrenght= new Array<{ SUB_CODE: string,SUB_TITLE: string,TOTAL: number}>();
    this.majcount=new Array<MajorRecord>;  }

  ngOnInit(): void {
    this.updateClasses();
    this.loader = false;
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  OnClassChange() {
    this.updateSessions();
  }

  updateSessions() {
    this.lmsSharedService.filterSessionByCode(this.formRef.value.c_code)
      .then(session => {
        this.sessions = session
        if (this.formRef) {
          const initialValue = this.sessions[0]?.id;
          this.formRef?.controls['se_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }

  OnSubmit() {
    this.loader = true;
    if (!this.formRef.value) {
      return;
    }
    this.refineStd = [];
    this.stdStrenght=[];
    this.majcount=[];
    // let val:{c_code,se_id, t_no } = this.formRef.value;
    this.examinationService.OnSubjectStrength( this.formRef.value)
      .subscribe((res: StudentRecord[]) => {
        if (res?.length != 0) {
          this.refineStd = res;
        }
        this.loader = false;
      });
  }

  termWiseStrenght(){
    this.loader = true;
    if (!this.formRef.value) {
      return;
    }
    this.refineStd = [];
    this.stdStrenght=[];
    let val:{c_code,se_id, t_no } =JSON.parse(JSON.stringify(this.formRef.value));// this.formRef.value;
    val.c_code=-75;
    this.examinationService.OnSubjectStrength(val)
      .subscribe((res: { SUB_CODE: string,SUB_TITLE: string,TOTAL: number}[]) => {
        if (res?.length != 0) {
          this.stdStrenght = res;
        }
        this.loader = false;
      });
  }
  majorWiseStrenght() {
    this.loader = true;
    if (!this.formRef.value) {
      return;
    }
    this.refineStd = [];
    this.stdStrenght = [];
    this.majcount = [];
  
    let val: { c_code, se_id, t_no } = JSON.parse(JSON.stringify(this.formRef.value));
    val.c_code = -85;
  
    this.examinationService.OnSubjectStrength(val)
      .subscribe((res: { SUB_CODE: string, SUB_TITLE: string, MAJOR: string, SECTION: string, TOTAL: number }[]) => {
        if (res?.length !== 0) {
          this.majcount = res;
        } else {
          this.majcount = [];
        }
     
        this.loader = false;
      }, error => {
        this.loader = false;
        console.error("Error fetching major-wise strength", error);
      });
  }
  
  ClearForm(f) {

    this.sessions = [];
    this.formRef.value.t_no = "";
    this.refineStd = []
    this.stdStrenght = []
    this.majcount=[]
    this.loader = false;
    f.resetForm();
  }

  OnCreatePDF() {
    if (!this.formRef.value) {
      return;
    }
    if (this.refineStd.length == 0 && this.stdStrenght.length ==0 && this.majcount.length ==0) {
      this.toastr.warning("No Data Data Found");
      return;
    }
    const header = [];
    let body = [];
    let t_no = this.formRef.value.t_no;
    let index = this.sessions?.findIndex((se) => se.id == this.formRef.value?.se_id);
    let index1 = this.classes?.findIndex((ce) => ce.id == this.formRef.value?.c_code);
    let se_name = this.sessions[index]?.nm;
    let c_code = this.classes[index1]?.nm;

    let exportDate = this.datePipe.transform(new Date(), "MMM d,y, h:mm a");
    let image = new Image();
    let page;
    let k = 0;

    image.src = "../../../assets/images/logo3.png";
    // let arr=[];
    if(this.refineStd?.length>0){
      header.push(["Sr.", "Subject Code", "Subject Title", "Section", "Total"]);
      this.refineStd.forEach(e => {
        k++;
        body.push([(k), e.SUB_CODE, e.SUB_TITLE, e.SECTION, e.TOTAL]);
      });
    }
    else if(this.majcount?.length>0){
      header.push(["Sr.", "Subject Code", "Subject Title",'Major', "Section", "Total"]);
      this.majcount.forEach(e => {
        k++;
        body.push([(k), e.SUB_CODE, e.SUB_TITLE, e. MAJOR, e.SECTION, e.TOTAL]);
      });
    }
    else{
      header.push(["Sr.", "Subject Code", "Subject Title", "Total"]);
      this.stdStrenght.forEach(e => {
        k++;
        body.push([(k), e.SUB_CODE, e.SUB_TITLE, e.TOTAL]);
      });
    }
    const doc = new jsPDF("p", "mm", "A4");
  
    autoTable(doc, {
      theme: "grid",
      margin: { top: 32, bottom: 8 },
      head: header,
      body: body,
      didDrawPage: function () {
        doc.addImage(image, 178, 2, 25, 25);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 173, 30);
        doc.setFontSize(20);
        doc.setFont("Arial", "bold");
        doc.text("GC UNIVERSITY LAHORE", 100, 10, { align: "center" });
        doc.setFontSize(14);
        doc.setFont("Arial", "bold");
        doc.text(`Class: ${c_code}`, 100, 17, { align: "center" });
        doc.setFontSize(13);
        doc.setFont("Arial", "bold");
        doc.text(`Session: (${se_name})`, 100, 24, { align: "center" });
        doc.text(`Semester: Semester-${t_no}`, 100, 30, { align: "center" });
        page = doc.getNumberOfPages();
      },
    });
    doc.setFontSize(8);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 110, 290, { align: 'center' });
      doc.text("Directorate of Information & Technology",10,290)
      doc.text("Note:- Errors and Omissions Excepted", 150, 290);
    }
    // doc.save(`Subject Wise Strength ${se_name} - ${t_no}`);
    doc.setProperties({
      title: `Subject Offer-${se_name||''}-${t_no}`,
    });

    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }

  Filter()
  {
    filter();
  }
}
