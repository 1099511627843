

import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { AwardListServices } from '../award-list/awardList.services';
import { SelectList } from '../shared/model/SelectList.model';
import { AssessmentService } from '../shared/services/Assessment.service';
import { ClassService } from '../shared/services/Class.service';
import { SessionService } from '../shared/services/Sessions.service';
import { StudentService } from '../shared/services/Student.service';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
export interface Assessment{
  SUB_ASS_ID:number,
  SUB_CODE: string,
  ASSIGNMENT_TITLE: string,
  ASSIGNMENT_TYPE: string,
  ASS_OBT_MRKS: number,
  ASS_TOT_MRKS: number,
  ASS_ID_FM: number,
  ORIGINAL_WEIGHTAGE:number,
  GRP:number,
  ELIGIBLE:string
}
@Component({
  selector: 'app-generate-single-std-awardlist',
  templateUrl: './generate-single-std-awardlist.component.html',
  styleUrls: ['./generate-single-std-awardlist.component.css'],
  animations:[
    SlideInFromLeft()
  ]
})
export class GenerateSingleStdAwardlistComponent implements OnInit {
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  assessments: Array<Assessment>;
  sortedTitle:Array<Assessment>;
  stdObj:{RN: number, YEAR: number,ROLNO:string,NM:string,MAJ_ID:number,MAJ_NM:string};
  subjects: Array<{ sub_code: string, sub_nm: string,set_no:number,t_no:number,section:string,se_id:number,session:string  }>;
  students: Array<{ RN: number, YEAR: number,ROLNO:string,NM:string,MAJ_ID:number,MAJ_NM:string }>;
  sections: Array<string>;
  subDet;
  selectedSubject:string='';
  hideDeleteBtn:boolean=false;

  @ViewChild('f') formRef: NgForm;
  constructor(
    private toastr: ToastrService,
    private classService: ClassService,
    private sessionService: SessionService,
    private studentService:StudentService,
    private assessmentService:AssessmentService,
    private awardList:AwardListServices,
    private lmsSharedService: LMSharedDataService
  ) {
    this.classes = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.assessments = [];
    this.sortedTitle=[];
    this.subjects = new Array<{ sub_code: string, sub_nm: string,set_no:number,t_no:number,section:string,se_id:number,session:string }>();
    this.students = new Array<{ RN: number, YEAR: number,ROLNO:string,NM:string,MAJ_ID:number,MAJ_NM:string }>();
    this.sections = new Array<string>();
  }

  ngOnInit(): void {
    this.updateClasses();
  }

  updateClasses() {
    this.classes = [];
    this.majors = [];
    this.sessions = [];
    this.subjects = [];
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting lms classes", error);
      });
  }

  OnClassChange(){
   this.updateSessions(); 
  }

  onSessionChange() {
  this.formRef?.controls['t_no'].reset();
  this.formRef?.controls['rolno'].reset();
  }

  updateSessions() {
    this.lmsSharedService.filterSessionByCode(this.formRef.value.c_code)
      .then(session => {
        this.sessions = session
        if (this.formRef) {
          const initialValue = this.sessions[0]?.id;
          this.formRef?.controls['se_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }

  OnSemesterChange() {
    this.students=[];
    this.studentService.getStudentByEnrolledT_NO(this.formRef.value.c_code,this.formRef.value.se_id,this.formRef.value.t_no).subscribe((res:{RN:number,YEAR:number,ROLNO:string,NM:string,MAJ_ID:number,MAJ_NM:string}[]) =>{
      res?.forEach(e =>{
        this.students.push({RN:e.RN,YEAR:e.YEAR,ROLNO:e.ROLNO,NM:e.NM,MAJ_ID:e.MAJ_ID,MAJ_NM:e.MAJ_NM})
      });
    })
  }

  OnRollNoChange(rn) {
    this.sortedTitle=[];
    this.subjects=[];
    this.stdObj=this.students.find(e => e.ROLNO==rn.value); 
  }
  getStudentSubject(){
    this.subjects=[];
    this.studentService.checkEnroll(this.stdObj?.YEAR,this.formRef.value.c_code,this.formRef.value.se_id,this.stdObj?.MAJ_ID,this.stdObj.RN,this.formRef.value.t_no).subscribe((res:[{sub_code:string,subject:string,set_no:number,t_no:number,section:string,se_id:number,session:string}[]]) =>{
      if(res[0]?.length==0){
        this.toastr.warning('No Subjects Available.');
        return;
      }
      res[0]?.forEach(e =>{
        this.subjects.push({sub_code:e.sub_code,sub_nm:e.subject,set_no:e.set_no,t_no:e.t_no,section:e.section,se_id:e.se_id,session:e.session});
      })
    })
  }
  createAssessment(subDetail){
    this.subDet=subDetail;
    this.selectedSubject=subDetail.sub_code;
    if(!this.formRef.value){
      return;
    }
    this.assessments=[];
    this.assessmentService.shiftSingleStdAssessments({C_CODE:this.formRef.value.c_code,SE_ID:this.formRef.value.se_id,T_NO:this.formRef.value.t_no,SUB_CODE:subDetail?.sub_code,SECTION:subDetail?.section, YEAR:this.stdObj.YEAR,MAJ_ID:this.stdObj.MAJ_ID,RN:this.stdObj?.RN}).subscribe((res:Assessment[]) =>{
      if(res?.length==0){
        this.toastr.warning('No Any Record Shifted.');
        return;
      }
      if(!res){
        this.toastr.error('Duplicate Entry or Error Occured.');
        return;
      }
      else{
        this.toastr.success('Assessment Created Successfully.');
        this.assessments = res;
        this.sortedTitle = [];
        this.sortedTitle = [...this.sortedTitle, ...this.assessments.filter(x => x.ASSIGNMENT_TYPE === "Quiz")];
        this.sortedTitle = [...this.sortedTitle, ...this.assessments.filter(x => x.ASSIGNMENT_TYPE === "Assignment")];
        this.sortedTitle = [...this.sortedTitle, ...this.assessments.filter(x => x.ASSIGNMENT_TYPE === "Presentation")];
        this.sortedTitle = [...this.sortedTitle, ...this.assessments.filter(x => x.ASSIGNMENT_TYPE === "Project")];
        this.sortedTitle = [...this.sortedTitle, ...this.assessments.filter(x => x.ASSIGNMENT_TYPE === "Lab")];
        this.sortedTitle = [...this.sortedTitle, ...this.assessments.filter(x => x.ASSIGNMENT_TYPE === "Mid Term")];
        this.sortedTitle = [...this.sortedTitle, ...this.assessments.filter(x => x.ASSIGNMENT_TYPE === "Final Term")];
      }
    },err =>{
      console.log('Error Occured: ',err);
    })
  }
  clearBtn(f:NgForm) {
    f.resetForm();
    this.sessions = [];
    this.sections = [];
    this.subjects = [];
    this.stdObj.NM='';
    this.stdObj.MAJ_NM='';
  }
  onChangeMark(oldMarks, SUB_ASS_ID,ts,newMarks,event){    
    if(ts.ORIGINAL_WEIGHTAGE<parseFloat(newMarks)){
      this.toastr.warning('Invalid Marks');
      event.target.value=parseFloat(oldMarks);
      return;
    }
    let obj = this.sortedTitle.find(e => e.SUB_ASS_ID==SUB_ASS_ID);
    let originMark:number = ((obj?.ASS_TOT_MRKS/obj?.ORIGINAL_WEIGHTAGE) * parseFloat(newMarks));
    this.assessmentService.updateSingleStdMrksInAssSubm({C_CODE:this.formRef.value.c_code,SE_ID:this.formRef.value.se_id,T_NO:this.formRef.value.t_no,SUB_CODE:this.subDet?.sub_code,SECTION:this.subDet?.section, YEAR:this.stdObj.YEAR,MAJ_ID:this.stdObj.MAJ_ID,RN:this.stdObj?.RN,ASS_ID:SUB_ASS_ID,MARKS:originMark}).subscribe((res:{msg:number}) =>{
      if(!res){
        this.toastr.error('Error Occured');
        return;
      }
      else{
        this.toastr.success(`Updated Row ${res?.msg}`);
        this.sortedTitle.find(e => e.SUB_ASS_ID==SUB_ASS_ID).ASS_OBT_MRKS=parseFloat(newMarks);
        return;
      }
    },err =>{
      console.log('Error Occured : ',err);
      this.toastr.error(err);
    })
  }

  calculateTotal_Weightage_Marks(){
    return this.sortedTitle.reduce((pre, cur) => pre + cur?.ORIGINAL_WEIGHTAGE, 0);
  }
  calculateTotal_Obtained_Marks(){
    return this.sortedTitle.reduce((pre, cur) => pre + cur?.ASS_OBT_MRKS||0, 0);
  }
  submitToExam(){
    if(this.calculateTotal_Obtained_Marks()>this.calculateTotal_Weightage_Marks()){
      this.toastr.warning('Obtained Marks should not be greater than 100.');
      return;
    }
    else{
      if(confirm('Are you sure, you want to submit to Examination?')){
      this.awardList.SAO_generateAwardListSingleStd({C_CODE:this.formRef.value?.c_code,SE_ID:this.formRef.value?.se_id,MAJ_ID:this.stdObj?.MAJ_ID,T_NO:this.formRef.value?.t_no,
        SECTION:this.subDet?.section,SUB_CODE:this.subDet?.sub_code,RN:this.stdObj?.RN, YEAR:this.stdObj?.YEAR
      }).subscribe((res:{msgAlready:string,arr:{}[]}) =>{
        if(res?.msgAlready=='Already'){
          this.hideDeleteBtn=true;
          this.toastr.warning('Already Submitted');
          return;
        }
        if(res.arr.length>0){
          this.toastr.success('Successfully Submitted to Examination');
          this.sortedTitle=[];
          return;
        }
      })
    }
    }
  }
  deleteSingleSubjStdAwardList(){
    this.awardList.deleteSingleSubjStdAwardList({C_CODE:this.formRef.value?.c_code,SE_ID:this.formRef.value?.se_id,MAJ_ID:this.stdObj?.MAJ_ID,T_NO:this.formRef.value?.t_no,
      SECTION:this.subDet?.section,SUB_CODE:this.subDet?.sub_code,RN:this.stdObj?.RN, YEAR:this.stdObj?.YEAR}).subscribe((res:{msg:number}) =>{
      if(res?.msg>=0){
        this.toastr.success(`Deleted ${res?.msg} Rows Successfully.`);
        this.hideDeleteBtn=false;
        return;
      }
    })
  }
}
