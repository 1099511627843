import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SelectList } from '../../shared/model/SelectList.model';
import { FeeService } from '../../shared/services/Fee.service';
import jsPDF from 'jspdf';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DatePipe } from '@angular/common';
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { filter } from 'src/app/shared/functions/tableSearch';
interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

interface Student {
  sr_no: number,
  base: string,
  m_list: number,
  f_no: number,
  nm: string,
  f_nm: string,
  gender: string,
  brd: number,
  brd_rn: number,
  i_marks: number,
  sub_comb: string
}
@Component({
  selector: 'app-merit-list',
  templateUrl: './merit-list.component.html',
  styleUrls: ['./merit-list.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class MeritListComponent implements OnInit {
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  StudentInfo: Array<Student>;
  groups: Array<SelectList>;
  admBase: Array<SelectList>;
  hide: boolean = false;
  isAdmin: boolean = false;
  group: number;
  showBorder: boolean = false;
  checking1: Array<any>;
  checking2: Array<string>;
  groupName = '';
  className = '';
  baseName = '';
  mlistNum = '';
  sessions: any;
  meritList: any;
  clss: string;
  grp: string;
  base: string;
  loader: boolean;

  @ViewChild('f') formRef: NgForm;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'> You can view and download all merit lists by major and class year. Select the admission basis from the menu or choose "all.
   If a specific merit list number is needed, provide it; otherwise, all merit lists data will be fetched. </h6>
  <h6 class="text-right">آپ تمام میرٹ لسٹس کو میجر اور کلاس سال کے لحاظ سے دیکھ اور ڈاؤن لوڈ کر سکتے ہیں۔ مینو سے داخلے کی بنیاد منتخب کریں یا "تمام" کو منتخب کریں۔ اگر کسی خاص میرٹ لسٹ نمبر کی ضرورت ہے
 تو فراہم کریں؛ ورنہ تمام میرٹ لسٹس کا ڈیٹا آ جائے گا۔
</h6>
  `  ;
  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private feeService: FeeService,
    private datePipe: DatePipe,
    private feeSharedService: FeeSharedDataService
  ) {
    this.classes = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.StudentInfo = new Array<Student>();
    this.checking1 = new Array<any>();
    this.checking2 = new Array<string>();
    this.groups = new Array<SelectList>();
    this.isAdmin = this.authenticationService.isAdmin();
    this.group = this.authenticationService.getGroup();
    this.loader = false;
  }
  ngOnInit(): void {
    this.updateClasses();
    this.updateAdmissionBasis();
    this.updateMeritList();
    this.updateSessions();
  }

  OnSubmit() {
    if (!this.formRef.valid)
      return;
  }

  onBaseChange(b: HTMLSelectElement) {
    this.baseName = this.admBase[b.selectedIndex].nm;
    this.mlistNum = this.formRef.value.m_list;
    this.StudentInfo = [];
  }

  updateAdmissionBasis() {
    this.admBase = []
    this.feeSharedService.getAdmissionBases()
      .then(adminBases => {
        this.admBase = adminBases;
      })
      .catch(error => {
        console.error("Error getting Admission Bases", error);
      });
  }

  updateMeritList() {
    this.feeSharedService.getFeeInst()
      .then(inst => {
        this.meritList = inst;
      })
      .catch(error => {
        console.error("Error getting inst", error);
      });
  }

  updateClasses() {
    this.classes = [];
    this.feeSharedService.getFeeClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateMajors() {
    this.majors.length = 0;
    this.feeSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  updateSessions() {
    this.sessions = []
    this.feeSharedService.getFeeSessions()
      .then(sessions => {
        this.sessions = sessions;
      })
      .catch(error => {
        console.error("Error getting fee session", error);
      });
  }

  OnClassChange(c: HTMLSelectElement) {
    this.className = this.classes[c.selectedIndex].nm;
    this.updateMajors();
  }

  onMeritReport() {
    this.loader = true;
    this.StudentInfo = [];
    this.feeService.onGetMlist(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.year, this.formRef.value.m_list || 0, this.formRef.value.b_id || 0).subscribe((res: { sr_no: number, f_no: number, nm: string, f_nm: string,gender: string, base: string,m_list: number,  brd: number, brd_rn: number, i_marks: number,sub_comb: string }[]) => {
      res?.forEach(entry => {
        this.StudentInfo.push({ sr_no: entry.sr_no,base: entry.base,m_list: entry.m_list, f_no: entry.f_no, nm: entry.
          nm, f_nm: entry.f_nm,gender: entry.gender, brd: entry.brd, brd_rn: entry.brd_rn, i_marks: entry.i_marks,sub_comb: entry.sub_comb, });
      });
      if (!res) {
        this.toastr.error("Unknown Error");
        this.loader = false;
      }
      else {
        this.hide = true;
        this.toastr.info(res.length + ' Record/s Fetched!');
        this.loader = false;
      }
    }
      , err => {
        console.log(err);
        this.loader = false;
        this.toastr.error("Some Error Occured!");
      }
    );
  }
  OnMajorChange(m: HTMLSelectElement) {
    // this.updateSessions();
    this.updateAdmissionBasis();
  }

  CreatePdf(ba: HTMLButtonElement) {
    if (this.StudentInfo?.length == 0) {
      this.toastr.warning("No Record Found");
      return;
    }
    else {
      const body = [];
      const header = [];
      var exportDate = this.datePipe.transform(new Date(), 'MMM d, y')
      var image = new Image();
      var page;
      var sessionYear = this.formRef.value.year;
      image.src = '../../../assets/images/logo3.png';
      // header.push(['Sr#', 'Form No.', 'Name', 'Father Name', 'Board  Name', 'Board Rn', 'Marks']);
      header.push([
        { content: 'Sr #', styles: { fontStyle: "bold", halign: 'center' } },
        { content: 'Admission Base' },
        { content: 'Merit List',styles: { fontStyle: "bold", halign: 'center' } },
        { content: 'Form #', styles: { fontStyle: "bold", halign: 'center' } },
        { content: 'Name' },
        { content: 'Father Name' },
        { content: 'Gender' },
        { content: 'Board Name' },
        { content: 'Board Roll #', styles: { fontStyle: "bold", halign: 'center' } },
        { content: 'Marks', styles: { fontStyle: "bold", halign: 'center' } },
        { content: 'Subject Combination', styles: { fontStyle: "bold", halign: 'center' } },]);

      const doc = new jsPDF('l', 'mm', 'A4');
      this.classes.forEach(element => {
        if (element.id == this.formRef.value.c_code) {
          this.clss = element.nm;
        }
      });
      this.majors.forEach(element => {
        if (element.id == this.formRef.value.maj_id) {
          this.grp = element.nm;
        }
      });
      this.admBase.forEach(element => {
        if (element.id == this.formRef.value.b_id) {
          this.base = element.nm;
        }
      });
      let c = this.clss;
      let g = this.grp;
      let b = this.base;

      for (let i = 0; i < this.StudentInfo.length; i++) {
        body.push([
          { content: this.StudentInfo[i].sr_no, styles: { fontStyle: 'bold', halign: 'center' } },
          { content: this.StudentInfo[i].base.toUpperCase()},
          { content: this.StudentInfo[i].m_list?this.StudentInfo[i].m_list:'', styles: { fontStyle: 'bold', halign: 'center' } },
          { content: this.StudentInfo[i].f_no, styles: { fontStyle: 'bold', halign: 'center' } },
          { content: this.StudentInfo[i].nm.toUpperCase()},
          { content: this.StudentInfo[i].f_nm.toUpperCase() },
          { content: this.StudentInfo[i].gender?this.StudentInfo[i].gender:'', styles: { fontStyle: 'bold', halign: 'center' } },
          { content: this.StudentInfo[i].brd?this.StudentInfo[i].brd:''},
          { content: this.StudentInfo[i].brd_rn?this.StudentInfo[i].brd_rn:'', styles: { fontStyle: 'bold', halign: 'center' } },
          { content: this.StudentInfo[i].i_marks?this.StudentInfo[i].i_marks:'', styles: { fontStyle: 'bold', halign: 'center' } },
          { content: this.StudentInfo[i].sub_comb?this.StudentInfo[i].sub_comb:'', styles: { fontStyle: 'bold', halign: 'center' } },
        ]);
      }
      autoTable(doc, {
        theme: 'grid',
        startY: 63,
        margin: { top: 63, bottom: 15, left: 7, right: 7 },
        styles: { fontSize: 11 },
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
          fontSize: 7.5,
          fontStyle: 'bold'
        },
        bodyStyles: {
          lineWidth: 0.1,
          lineColor: [0, 0, 0],
          fontSize: 7
        },
        columnStyles: {
          0: { cellWidth: 11 },
          1: { cellWidth: 30 },
          2: { cellWidth: 10 },
          3: { cellWidth: 16 },
          4: { cellWidth: 45 },
          5: { cellWidth: 45 },
          6: { cellWidth: 14 },
          7: { cellWidth: 30 },
          8: { cellWidth: 13 },
          9: { cellWidth: 12 },
          10:{ cellWidth: 55 },
        },
        head: header,
        body: body,
        didDrawPage: function () {
          let height = 20;
          doc.addImage(image, 243, 13, 35, 40);
          doc.setFontSize(10);
          doc.text(`${exportDate}`, 250, 58);
          doc.setFontSize(23);
          doc.setFont('Helvetica', 'bold');
          doc.text("GC UNIVERSITY, LAHORE", 140, height+5, { align: 'center' });
          doc.setFontSize(16);
          doc.setFont('Helvetica', 'bold');
          doc.text("MERIT LIST FOR VERIFICATION", 140, height + 14, { align: 'center' });
          doc.text("____________________________", 140, height + 14, { align: 'center' });
          doc.setFontSize(13);
          doc.text("CLASS: " + c + ' (' + sessionYear + ')', 143, height + 23, { align: 'center' });
          doc.setFontSize(12);
          doc.text("MAJOR: " + g, 138, height + 30.5, { align: 'center' });
          if (b != '0') {
          
          doc.text("BASE: " + b, 140, height + 38, { align: 'center' });
          console.log(ba);
          }
          page = '';
          page = doc.getNumberOfPages();
        }
      });
      var l = (body.length + 65) % 26;
      doc.setFontSize(7);
      for (var i = 1; i <= page; i++) {
        doc.setPage(i);
        doc.text('Page ' + i + " of " + page, 140, 200);
        doc.text("Directorate of Information & Technology",8,200)
        doc.text("Note:- Errors and Omissions Excepted", 247, 200);
        var p = body.length;
      }
      // doc.save('meritList-'+this.mlistNum+'-'+sessionYear+'-'+this.baseName);
      window.open(URL.createObjectURL(doc.output('blob')), 'aa');
    }
  }
  DownloadExcel() {
    let body = [];
   // let header = [];
    let i=1;
    //for(i=0;i<this.StudentInfo.length;i++){
    //  body.push(['Sr #','Admission Base','Merit List','Form #','Name','Father Name','Gender','Board Name','Board Roll #','Marks','Subject Combinations' ]);
      this.StudentInfo.forEach(el => {
        body.push([
          el.sr_no,
          el.base,
          el.m_list,
          el.f_no,
          el.nm,
          el.f_nm,
          el.gender,
          el.brd,
          el.brd_rn,
          el.i_marks,
          el.sub_comb

        ]);
        i++;
      })
      new AngularCsv(body,"Merit List Report",{header : ['Sr #','Admission Base','Merit List','Form #','Name','Father Name','Gender','Board Name','Board Roll #','Marks','Subject Combination']})
    //}
    }

  onClearForm(f: NgForm) {
    f.resetForm();
    this.StudentInfo = []
    this.loader = false;
  }

  ClearClassBase() {
    this.formRef?.controls['maj_id'].reset();
    this.formRef?.controls['year'].reset();
    this.formRef?.controls['m_list'].reset();
    this.formRef?.controls['b_id'].reset();
  }

  ClearMajorBase() {
    this.formRef?.controls['year'].reset();
    this.formRef?.controls['m_list'].reset();
    this.formRef?.controls['b_id'].reset();
  }

  ClearYearBase() {
    this.formRef?.controls['m_list'].reset();
    this.formRef?.controls['b_id'].reset();
  }

  ClearMListBase() {
    this.formRef?.controls['b_id'].reset();
  }

  Filter()
  {
    filter();
  }
}