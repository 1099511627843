<div [@SlideInFromLeft] class="tabber backdrop"
  style="overflow: scroll; overflow-y: scroll; margin-left: 5%; margin-top: 1%">
  <div class="container-fluid ml-1 mb-1 card shadow alert-box col-lg-8 ml-2 col-md-12 offset-sm-3 ml-2 mr-2 ml-sm-auto mr-sm-auto">
    <div class="card-header d-flex justify-content-between">
      <div>Student Personal Detail</div>
      <button (click)="OnClose()" class="btn btn-outline-primary btn-sm"> Close </button>
    </div>
    <form #fn="ngForm" >
        <!-- <div class="col-lg-4 col-md-6 form-group">
            <label for="C_Code">Class</label>
            <select #c_code (change)="OnClassChange()" class="form-control " name="c_code" id="c_code" ngModel required>
              <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
            </select>
          </div> -->

          <!-- <div class="col-lg-4 col-md-6 form-group">
            <label for="se_id">Session</label>
            <select #se_id class="form-control " name="se_id" id="se_id" (change)="loadRollno()" required ngModel>
              <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
            </select>
          </div> -->


          <!-- <div class="col-lg-4 col-md-4 col-sm-6 form-group">
            <label for="t_no" class="text-left">Semester</label>
            <select #t_no name="t_no" class="form-control" id="t_no" ngModel required>
              
            </select>
          </div> -->
          <!-- <div class="col-lg-4 col-md-4 form-group">
            <label for="in" class="text-left">Complete Roll Number</label>
            <select #rolno class="form-control" name="rolno" id="rolno" value="rolno" ngModel required>
              <option *ngFor="let s of rolnoArr" value="{{s.nm}}">{{s.nm}}</option>
            </select>
          </div> -->
          <!--  -->
          <!-- <div class="row mt-2 ml-3">
            <button  type="button" [disabled]="!this.getDetBtn" class="btn btn-sm btn-primary mt-1 " (click)="getUser()"
              style="height: calc(1.5em + .75rem + 2px);">
              Get Detail
            </button>

          </div> -->
<!-- 
          <div *ngFor="this.studentInfo.length" class="card-header">
            Student Personal Detail
          </div> -->
  
          <div *ngFor="let s of studentInfo;" class="mt-2 row form-group">
            
            <div  class="col-lg-4 col-md-4">
              <h6 class="card-title">Name</h6>
              <input readonly  #name class="form-control" name="name" id="name" value="{{s.nm.toUpperCase()}}" required>
            </div>
            <div  class="col-lg-4 col-md-4">
              <h6 class="card-title">Father Name</h6>
              <input readonly   #fName class="form-control" name="fName" id="fName" value="{{s.f_nm.toUpperCase()}}" required>
            </div>
            
            <div class="col-lg-4 col-md-4">
              <h6 class="card-title">CNIC</h6>
              <input #cnic class="form-control" name="cnic" id="cnic" value="{{s.nic}}" required readonly>
            </div>
            <div  class="col-lg-4 col-md-4">
              <h6 class="card-title">Roll No</h6>
              <input readonly #rolno class="form-control" name="rolno" id="rol" value="{{s.rolno}}" required>
            </div>
            <div class="mt-2 col-lg-4 col-md-4">
              <h6 class="card-title">Registeration No#</h6>
              <input class="form-control" name="reg_no" id="reg_no" value="{{s.reg_no}}" readonly>
            </div>
            <div  class="mt-2 col-lg-4 col-md-4">
              <h6 class="card-title">Form No</h6>
              <input readonly   #f_no class="form-control" name="f_no" id="f_no" value="{{s.f_no}}" required>
            </div>
            <div  class="mt-2 col-lg-4 col-md-4">
              <h6 class="card-title">Email</h6>
              <input readonly   #email type="email" class="form-control" name="email" id="email" value="{{s.email}}" required>
            </div>
            
            <div class="mt-2 col-lg-4 col-md-4" >  
              <h6 class="card-title">Guardian Contact No.</h6>
              <input  #ph2 type="number" class="form-control" readonly name="ph2" id="ph2" value="{{s.ph2}}" required>
            </div>
  
            <div  class="mt-2 col-lg-4 col-md-4">
              <h6 class="card-title">Gender</h6>
              <input readonly  #gender class="form-control" name="gender" id="gender" value="{{s.gender}}" >
            </div>
  
            <div  class="mt-2 col-lg-4 col-md-4">
              <h6 class="card-title">Religion</h6>
              <input readonly  #religion class="form-control" name="religion" id="religion" value="{{s.relig}}" required>
            </div>
            <div  class="mt-2 col-lg-4 col-md-4">
              <h6 class="card-title">Department</h6>
              <input class="form-control" name="name" id="name" value="{{s.deptt}}" readonly>
            </div>
            <!-- <div class="mt-2 col-lg-4 col-md-4">
              <h6 class="card-title">Class</h6>
              <input class="form-control" name="name" id="name" value="{{s.class}} ({{s.c_code}})" readonly>
            </div> -->
            <div class="mt-2 col-lg-4 col-md-4">
              <h6 class="card-title">Major</h6>
              <input class="form-control" name="name" id="name" value="{{s.major}}" readonly>
            </div>
            <div [hidden]="this.onDepartmentAccess" class="mt-2 col-lg-4 col-md-4">
              <h6 class="card-title">Session</h6>
              <input  class="form-control" name="name" id="name" value="{{s.session}}" readonly>
            </div>
           
            <div class="mt-2 col-lg-4 col-md-4">
              <h6 class="card-title">Year</h6>
              <input class="form-control" name="name" id="name" value="{{s.year}}" readonly>
            </div>
            
            <div class="mt-2 col-lg-8 col-md-4">
              <h6 class="card-title">Set no</h6>
              <input  class="form-control" name="name" id="name" value="{{s.set_no}}" readonly>
            </div>
            <!-- <div [hidden]="this.onDepartmentAccess" class="mt-2 col-lg-8 col-md-4">
              <h6 class="card-title">Set Name</h6>
              <input #set_nm class="form-control" [readonly]="this.isExamCell" name="set_nm" id="set_nm" value="{{s.set_nm}}" >
            </div> -->
            <div class="mt-2 col-lg-4 col-md-4">
              <h6 class="card-title">Student Contact No.</h6>
              <input #ph1 type="number" class="form-control" readonly name="ph1" id="ph1" value="{{s.ph1}}" required>
            </div>
            <!-- [readonly]="this.onDepartmentAccess" -->
            <div class="mt-2 col-lg-8 col-md-6">
              <h6 class="card-title">Address</h6>
              <input #add1 class="form-control" readonly name="add1" id="add1" value="{{s.add1}}" >
            </div>
            <!-- <div *ngIf="this.canStudentDetail" class="mt-2 col-lg-8 col-md-6">
              <h6 class="card-title">Address</h6>
              <input #add1 class="form-control" name="add1" id="add1" value="{{s.add1}}" required>
            </div> -->
            <!-- <div *ngIf="this.canStudentDetail" class="ml-1 mt-4 col-lg-4 col-md-2">
              <button style="height: 40px;" (click)="onSubmit(name,fName,cnic,email,add1,ph1,ph2,gender,religion)"  class="btn btn-primary">Update Information</button>
            </div> -->
  
            <div class="ml-3">
              <button [disabled]="!hideInput" type="button" class="btn btn-sm btn-primary mt-5 ml-1"
                (click)='downloadTimetable()' style="height: calc(1.5em + .75rem + 2px);">
                Download Timetable
              </button>
            </div>
          </div>
        
          
          <!-- 
          <div>
            <table *ngIf="hideInput" class="mb-0 table table-sm table-bordered" >
              <thead class="table-active">
                <th>Name</th>
                <th>Department</th>
                <th>Year</th>
                <th>Class</th>
                <th>Major</th>
                <th>Session</th>
                <th>Set No</th>
              </thead>
              <tbody>
                <tr *ngFor="let s of studentInfo; let i=index;">
                  <td>{{s.nm}}</td>
                  <td>{{s.deptt}} ({{s.d_id}})</td>
                  <td>{{s.year}}</td>
                  <td>{{s.class}} ({{s.c_code}})</td>
                  <td>{{s.major}} ({{s.maj_id}})</td>
                  <td>{{s.session}} ({{s.se_id}})</td>
                  <td>{{s.set_no}}</td>
                  <td>
                    {{s.SECTION}}
                   <div>
                    <input readonly  #newSection   type="text" value="{{s.SECTION}}" width="20px">
                   </div>
                   [readonly]="isExamCell ? !canManageTT:true" 
                  (change)="changeSection(newSection,i,s)"
                  class="section"
                 
                  </td>
                </tr>
              </tbody>
            </table>
          </div> -->
  
          <div *ngIf="hideInput">
            <div class="card-header">
              Student Enrollment
            </div>
        
            <div class="mt-2 row form-group">
              <div class="col-lg-4 col-md-6 form-group">
                <label for="se_id">Session</label>
                <select #se_id_new class="form-control " name="se_id_new" id="se_id_new" required ngModel>
                  <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
                </select>
              </div>
              <div class="col-lg-4 col-md-6">
                <label for="t_no">Semester</label>
                <select #t_no class="form-control" name="t_no" ngModel id="t_no">
                  <option *ngFor="let t of array" value="{{t}}">{{t}}</option>
                  <option value="-1">complete</option>
                </select>
              </div>
              <div class="col-sm-4 col-lg-4 col-md-6">
  
                <button [disabled]="(!t_no.value&&!se_id_new)" type="button" class="btn btn-primary mt-4 " (click)="checkSubOffer()">
                  Subject Offer
                </button>
                <!-- </div>
              <div class="col-sm-4"> -->
                <button [disabled]="(!t_no.value&&!se_id_new)" type="button" class="btn btn-primary ml-2 mt-4 " (click)="checkEnroll()">
                  Check Enrollment
                </button>
              </div>
            </div>
            <hr>
          </div>
  
          <table *ngIf="this.enrollInfo?.length>0" class="mb-0 table table-hover table-bordered">
            <thead class="table-active">
              <th>Sr No.</th>
              <th>Enrolled Subjects</th>
              <th>Semester</th>
              <th>Session</th>
              <th>Section</th>
              <th>Student Attendance</th>
              <th>Student Assessment</th>
            </thead>
            <tbody>
              <tr *ngFor="let s of enrollInfo; let i=index;">
                <td>{{i+1}}</td>
                <td>{{s.subject}} ({{s.sub_code}})</td>
                <td>{{s.t_no}}</td>
                <td>{{s.session}} ({{s.se_id}})</td>
                <td>{{s.section}}</td>
                <td>
                  <button type="button" class="btn btn-sm btn-primary" (click)="showDetail(s)">
                    Attendance
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-sm btn-primary" (click)="showDetail1(s)">
                    Assessment
                  </button>
                </td>
                <!-- <td> -->
                <!-- {{s.SECTION}} -->
                <!-- <div>
          <input readonly  #newSection   type="text" value="{{s.SECTION}}" width="20px">
         </div> -->
                <!-- [readonly]="isExamCell ? !canManageTT:true" 
          (change)="changeSection(newSection,i,s)"
          class="section"
          -->
                <!-- </td> -->
              </tr>
            </tbody>
          </table>
  
          <table *ngIf="this.subInfo?.length>0" class="mb-0 table table-hover table-bordered">
            <thead class="table-active">
              <th>Sr. No</th>
              <th>Offered Subjects</th>
              <th>Semester</th>
              <th>Set no</th>
            </thead>
            <tbody>
              <tr *ngFor="let s of subInfo; let i=index;">
                <td>{{i+1}}</td>
                <td>{{s.subject}} ({{s.sub_code}})</td>
                <td>{{s.t_no}}</td>
                <td>{{s.set_no}}</td>
              </tr>
            </tbody>
          </table> 

      <div class="card-body" style="margin-top: -20px">

      </div>
    </form>
  </div>
</div>


<app-student-detail *ngIf="show" [enrolInfo]="this.sendEnroll" [std]="this.sendSTD" (close)="closeIt()">
</app-student-detail>
<app-check-assessment *ngIf="show1" [enrolInfo]="this.sendEnroll" [std]="this.sendSTD" (close)="closeIt1()">
</app-check-assessment>