import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { courseOutlineService } from './course-outline.service';
import { CourseOutlineModel } from './outline-entry/course-outline.model';
import { SlideInFromLeft } from 'src/app/transitions';
import { AuthenticationService } from './../../auth/_services/authentication.service';
import { Team, TimetableService } from 'src/app/main/shared/services/Timetable.service';
import 'jspdf-autotable';
import { jsPDF } from "jspdf";

@Component({
  selector: 'app-course-outline',
  templateUrl: './course-outline.component.html',
  styleUrls: ['./course-outline.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class CourseOutlineComponent implements OnInit {

  show: boolean;
  @ViewChild('w') selectWeek: ElementRef;
  @ViewChild('form') formRef: NgForm
  outvar = '';
  objvar = '';
  assvar = '';
  matvar = '';
  plagvar = '';
  attvar = '';
  methvar = '';
  goalvar = '';

  week: number;

  tm: Team;
  outline: Array<CourseOutlineModel>;
  //new added arrays.
  objArr: Array<String>;
  outArr: Array<String>;
  contArr: Array<String>;
  assessArr: Array<String>;

  btnDis = false;
  classes: { c_code: string, c_nm: string }[];
  teams: Team[];

  editMode: boolean;
  hideAllWeeks: boolean;
  editModeSingleWeek: boolean;
  data: any;
  @ViewChild('upsupport') upsupport: ElementRef;

  constructor(private toastr: ToastrService,
    private timetableService: TimetableService,
    private outServ: courseOutlineService,
    private authenticationService: AuthenticationService) {
    this.show = false;
    this.classes = new Array<{ c_code: string, c_nm: string }>();
    this.teams = new Array<Team>();
    this.outline = new Array<CourseOutlineModel>();
    this.objArr = new Array<String>();
    this.outArr = new Array<String>();
    this.contArr = new Array<String>();
    this.assessArr = new Array<String>();
    this.editMode = false;
    this.hideAllWeeks = true;
    this.editModeSingleWeek = false;
    this.data = null;
  }
  ngOnInit(): void {
    this.classes = this.timetableService.getClasses();

    if (this.classes?.length > 0) {
      this.loadTeams(parseInt(this.classes[0].c_code));
    }
    this.loadMaterial();
  }
  loadTeams(c_code: number) {
    this.teams = [];
    this.teams = this.timetableService.getTeam(c_code);
    if (this.teams?.length > 0) {
      this.tm = this.teams[0];
    }
    else {
      this.tm = null;
    }
  }
  loadMaterial() {
    this.outServ.viewCourseOutline(this.tm).subscribe(r => {
      this.btnDis = true;
      if (r != null) {
        this.outline = [];
        this.objArr = [];
        this.objArr = r[0]?.OBJECTIVE.split(".");
        if (!!this.objArr && (this.objArr[this.objArr?.length] == "" || this.objArr[this.objArr?.length] == "."))
          this.objArr?.splice(-1, 1);
        this.outArr = [];
        this.outArr = r[0]?.OUTCOME.split(".");
        if (!!this.outArr && (this.outArr[this.outArr?.length] == "" || this.outArr[this.outArr?.length] == "."))
          this.outArr?.splice(-1, 1);
        this.contArr = [];
        this.contArr = r[0]?.Material.split(".");
        this.findAndDelete('.', this.contArr);
        this.assessArr = [];
        this.assessArr = r[0]?.ASSESSMENT.split(".");
        if (!!this.assessArr && (this.assessArr[this.assessArr?.length] == "" || this.assessArr[this.assessArr?.length] == "."))
          this.assessArr?.splice(-1, 1);
      }
      this.outline = [];
      for (const i in r) {
        this.outline.push(new CourseOutlineModel(r[i]?.sub_nm, r[i].cr_h, r[i].nm, r[i].content
          , r[i].Week_no, r[i].T_NO, r[i].TEACHING_METHODOLOGY, r[i].SUB_CODE,
          r[i].SE_ID, r[i].SECTION, r[i].PLAGIRISM_POLICY, r[i].OUT_ID, r[i].OUTCOME,
          r[i].OBJECTIVE, r[i].Material, r[i].MAJ_ID, r[i].FM_ID,
          r[i].D_ID, r[i].C_CODE, r[i].ATTENDANCE_POLICY, r[i].ASSESSMENT, r[i].support));
      }
    }, err => {
      console.log(err);
      this.toastr.error("Bad Internet Connection");
    });
  }
  findAndDelete(ele: any, arr: Array<String>) {
    arr?.forEach((element, index) => {
      if (element == ele) delete arr[index];
    });
  }
  OnClassChange(c: HTMLSelectElement) {
    this.loadTeams(parseInt(c.value));
    this.loadMaterial();
  }

  onCourseChange(c: HTMLSelectElement) {
    this.tm = this.teams[parseInt(c.value)];
    this.editMode = false;
    this.hideAllWeeks = true;
    this.loadMaterial();
  }

  onWeekChange() {
    this.week = this.selectWeek.nativeElement.value;
  }

  onUpdate(o) {
    this.editMode = true;
    this.hideAllWeeks = false;
    this.data = o;
  }
  onUpdateSingleWeek(o) {
    this.editModeSingleWeek = true;
    this.hideAllWeeks = false;
    this.data = o;
  }
  onupdateSubmit(form: NgForm) {
    if (!form.valid || !this.tm) {
      this.toastr.error("All fields must be filled");
      return;
    }
    this.outServ.updateWeekOne({ ...form.value, ...this.tm, week: this.data.week_no }).subscribe(r => {
      if (!r) {
        this.toastr.warning("Some Error Occured");
      } else {
        this.toastr.success("Updated successfully");
        this.loadMaterial();
      }
    }, err => {
      console.log(err);
      this.toastr.error("Some error Occured");
    });
    this.hideAllWeeks = true;
    this.editMode = false;
    this.editModeSingleWeek = false;
  }

  OnSubmit() {
    if (!this.formRef.valid || !this.tm) {
      this.toastr.error("All fields must be filled");
      return;
    }
    if (this.objvar?.length > 1000 || this.assvar?.length > 1000 || this.outvar?.length > 1000 ||
      this.matvar?.length > 1000 || this.plagvar?.length > 1000 || this.attvar?.length > 1000 ||
      this.methvar?.length > 1000 || this.goalvar?.length > 1000) {
      this.toastr.error("Maximum input length violation");
      return;
    }

    this.outServ.insertOutline({ ...this.formRef.value, ...this.tm }).subscribe(r => {
      if (!r) {
        this.toastr.warning("Already Exists");
      } else {
        this.toastr.success("Inserted successfully");
        this.loadMaterial();
      }
    }, err => {
      console.log(err);
      this.toastr.error("Some error Occured");
    });
  }
  onClose() {
    this.show = false;
  }

  deleteWeek(week_no: number) {
    this.outServ.deleteWeek({ ...this.tm, week: week_no }).subscribe(r => {
      if (!r)
        this.toastr.error("Some Error Occured");
      else {
        this.toastr.success("Week-" + week_no + " deleted");
        this.loadMaterial();
      }
    }, err => {
      console.log(err);
      this.toastr.error("No Internet");
    });
  }
  countWords(str) {
    str = str.replace(/(^\s*)|(\s*$)/gi, "");
    str = str.replace(/[ ]{2,}/gi, " ");
    str = str.replace(/\n /, "\n");
    return str.split(' ').length;
  }

  CreatePdf() {
    if (this.outline.length == 0) {
      this.toastr.error('No Data to Export..');
      return;
    }
    var image = new Image();
    image.src = '../../../assets/images/logo3.png';
    const doc = new jsPDF('p', 'mm', [297, 210]);
    doc.addImage(image, 85, 4, 35, 35);
    doc.text("GC UNIVERSITY LAHORE", 100, 45, { align: 'center' });
    doc.text("COURSE HANDBOOK", 100, 52, { align: 'center' });
    doc.setFontSize(10);

    doc.cell(10, 60, 11, 9, "1", 8, "bold");
    doc.cell(8, 60, 70, 9, "Course Title", 8, "bold");
    doc.cell(8, 60, 110, 9, this.outline[0]?.sub_nm, 8, "left");

    doc.cell(10, 67, 11, 9, "2", 16, "bold");
    doc.cell(8, 67, 70, 9, "Course Code", 16, "bold");
    doc.cell(8, 67, 110, 9, this.outline[0]?.sub_code, 16, "left");

    doc.cell(10, 74, 11, 9, "3", 24, "bold");
    doc.cell(8, 74, 70, 9, "Credit Hours", 24, "bold");
    doc.cell(8, 74, 110, 9, this.outline[0].cr_h?.toString() + " ch", 24, "left");

    doc.cell(10, 81, 11, 9, "4", 32, "bold");
    doc.cell(8, 81, 70, 9, "Supporting Team Members", 32, "bold");
    doc.cell(8, 81, 110, 9, this.outline[0]?.support, 32, "left");

    doc.cell(10, 88, 11, 9, "5", 40, "bold");
    doc.cell(8, 88, 70, 9, "Semester", 40, "bold");
    doc.cell(8, 88, 110, 9, this.outline[0]?.t_no.toString(), 40, "left");

    doc.cell(10, 95, 11, 9, "6", 48, "bold");
    doc.cell(8, 95, 70, 9, "Course Instructor", 48, "bold");
    doc.cell(8, 95, 110, 9, this.outline[0]?.nm, 48, "left");

    doc.cell(10, 102, 11, 9, "7", 56, "bold");
    doc.cell(10, 102, 180, 9, "Course Objective", 56, "bold");
    doc.cell(10, 109, 11, 50, " ", 64, "bold");
    doc.cell(10, 109, 180, 50, this.objArr?.toString(), 64, "left");

    doc.cell(10, 116, 11, 9, "8", 72, "bold");
    doc.cell(10, 116, 180, 9, "Learning Outcomes", 72, "bold");
    doc.cell(10, 123, 11, 50, " ", 80, "bold");
    doc.cell(10, 123, 180, 50, this.outArr?.toString(), 80, "left");

    doc.cell(10, 130, 11, 9, "9", 88, "bold");
    doc.cell(10, 130, 180, 9, "Course Contents", 88, "bold");
    doc.cell(10, 137, 11, 50, " ", 96, "bold");
    doc.cell(10, 137, 180, 50, this.contArr?.toString(), 96, "left");
    doc.cellAddPage();

    doc.cell(10, 10, 11, 9, "10", 8, "bold");
    doc.cell(10, 10, 180, 9, "Assessment", 8, "bold");
    doc.cell(10, 17, 11, 50, " ", 16, "bold");
    doc.cell(10, 17, 180, 50, this.assessArr?.toString(), 16, "left");

    doc.cell(10, 38, 11, 9, "12", 40, "bold");
    doc.cell(10, 38, 180, 9, "Plagirism Policy", 40, "bold");
    doc.cell(10, 45, 11, 20, " ", 48, "bold");
    doc.cell(10, 45, 180, 20, this.outline[0]?.plagirism.toString(), 48, "left");

    doc.cell(10, 52, 11, 9, "13", 56, "bold");
    doc.cell(10, 52, 180, 9, "Attendance Policy", 56, "bold");
    doc.cell(10, 59, 11, 20, " ", 64, "bold");
    doc.cell(10, 59, 180, 20, this.outline[0]?.attendance.toString(), 64, "left");
    //methodology replace with attendace.


    doc.cell(10, 66, 11, 9, "14", 72, "bold");
    doc.cell(10, 66, 180, 9, "Outline of Weekly Lecture & Reading List", 72, "bold");

    doc.cell(10, 73, 11, 50, " ", 80, "bold");
    doc.cell(10, 73, 180, 50, "Week-" + this.outline[0]?.week_no.toString() + '\n' + this.outline[0]?.content.toString(), 80, "left");
    var j = 20;
    var m = 8;
    var k = 1;

    for (var i = 0; i < this.outline.length && k < this.outline.length; i++) {
      var w = this.countWords(this.outline[k].content.toString());
      var l = this.outline[k].content.toString().length;
      var hh = Math.floor(((l / w) * 10));
      if (hh >= 25 && hh <= 50)
        hh = 20;
      else if (hh > 50 && hh <= 60)
        hh = 30;
      else if (hh > 60 && hh <= 63)
        hh = 42;
      else if (hh > 63 && hh < 66)
        hh = 53;
      else if (hh >= 66 && hh < 70)
        hh = 57;
      else if (hh >= 70 && hh < 80)
        hh = 68;
      else if (hh >= 80 && hh < 84)
        hh = 75;
      else if (hh >= 85 && hh < 90)
        hh = 80;
      else if (hh >= 90 && hh < 100) {
        hh = 85;
      }
      if (w < 10) {
        // w=20;
        hh = 20;
      }
      doc.cell(10, j, 11, hh, " ", m, "bold");
      doc.cell(10, j, 180, hh, "Week-" + this.outline[k]?.week_no.toString() + '\n' + this.outline[k]?.content.toString(), m, "left");
      k++;
      l = 0;
      w = 0;
      hh = 0;
      if (j == 50) {
        // j=10;
        doc.cellAddPage();
      }
      j = j + 8;
      if (m == 48) {
        m = 8;
      }
      m = m + 8;
    }
    // doc.save(this.outline[0]?.sub_code + " Course Outline");
  window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }
}
