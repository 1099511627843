import { Component, EventEmitter, ViewChild, ElementRef, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
declare var $: any;

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  @ViewChild('form') formRef: NgForm;
  constructor(private authService: AuthenticationService,
    private toastr: ToastrService) {

  }
  // tslint:disable-next-line:no-output-native
  @Output() close = new EventEmitter<void>();

  onClose() {
    this.close.emit();
  }

  ngOnInit(): void {
    setTimeout(() => {
      $('.backdrop').css('opacity', '1');
    }, 50);
  }
  OnChangeClicked(form: NgForm) {
    if (form.value.usr === '' || form.value.email === '') {
      return;
    }

    var unm: string;
    unm = form.value.usr;
      this.authService.forgetPassword(form.value).subscribe((res: { status: string, msg: string }) => {
        if (res.status === "success") {
          this.toastr.success(res.msg);
          this.close.emit();
          setTimeout(() => {
          }, 1000);
        } else {
          this.toastr.warning(res.msg);
        }
      }, err => {
        this.toastr.error(err);
      }
      );
  }
}
