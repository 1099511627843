<div [@SlideInFromLeft]>
    <div class="container-fluid card">
        <div class="card-header d-flex justify-content-between" style="margin-bottom: -2px">
            <div>Evaluation Feedback Report</div>
        </div>

        <form #f="ngForm">
            <div class="card-body">
                <div class="row">

                    <div class="col-lg-4 col-md-6 col-sm-12">
                        <label for="course">Class</label>
                        <select #c class="form-control " (change)="OnClassChange(c)" name="class" id="class">
                            <option *ngFor="let c of classes" value="{{c.c_code}}">{{c.c_nm}}</option>
                        </select>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <label for="course" class="text-bold">Course</label>
                        <select #course class="form-control" (change)="OnCourseChange(course)" name="course"
                            id="course">
                            <option *ngFor="let t of teams; let i=index;" value={{i}}>
                                {{t.SUB_CODE}}-{{t.SUB_NM}}-{{t.SECTION}}-{{t.G_NM}}-{{t.T_NO}}</option>
                        </select>
                    </div>

                    <div class="row ml-1">
                        <button class="btn btn-outline-primary" style="margin-top: 28px; height: 37px;"
                            (click)="onGetReport()">Get
                            Report</button>
                    </div>
                </div>
            </div>
        </form>



        <div>
            <h6 class="text-center" *ngIf="singleSubRpt.length>0">
                Teacher Feedback Detail
            </h6>
        </div>

        <div class="card-header" *ngIf="singleSubRpt.length>0">REPORT IN CHART FORM</div>

<!--  THIS IS A CHILD COMPONENT THAT IS USE FOR MAKING CHARTS. -->
<!-- Modify by zain. 12 july 2022, Eid ul azha 3rd day. -->
        <app-ev-f-show-charts *ngIf="singleSubRpt.length>0" [arr]="arr" [CharDataCustom]="CharDataCustom"></app-ev-f-show-charts>

        <div class="card-header" *ngIf="singleSubRpt.length>0">OVER ALL REPORT</div>

        <div class="row ml-1" style="float:right;">
            <button class="btn btn-outline-primary" *ngIf="singleSubRpt.length>0"
                style="margin-top: 28px; height: 37px;" (click)="createPdf()">Download PDF</button>
        </div>

        <div class="my-3" *ngIf="singleSubRpt.length>0">
            <table class="table table-hover table-bordered">
                <thead class="table-active">
                    <th>Sr.No</th>
                    <th>Short Description</th>
                    <th>Filled By</th>
                    <th>Mean</th>
                    <th>Standard Deviation</th>
                    <th>Percentage %</th>
                    <th>Grade</th>
                </thead>
                <tbody>
                    <tr *ngFor="let d of singleSubRpt; let i=index">
                        <td> {{d.QUES_ID+1}} </td>
                        <td> {{d.SHORT_DES}} </td>
                        <td> {{d.TOTALSTUDENT}} </td>
                        <td> {{d.MEAN}} </td>
                        <td> {{d.STAND_DEV}} </td>
                        <td> {{d.PERCENTAGE}} %</td>
                        <td> {{d.GRADE}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
 