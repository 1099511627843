import { Component, OnInit, ViewChild } from '@angular/core';
import {  NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { SessionService } from './../shared/services/Sessions.service';
import { DatePipe } from '@angular/common';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import 'jspdf-autotable';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';
@Component({
  selector: 'app-classsubjectenrollcount',
  templateUrl: './classsubjectenrollcount.component.html',
  styleUrls: ['./classsubjectenrollcount.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class ClasssubjectenrollcountComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;  
  class: Array<SelectList>;  
  sessions: Array<SelectList>;            
  student:any;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  you can check the count of enrolled and not enrolled students in all
  the offered subjects of any semester in any session of its class and download the reports as well</h6>
  <h6 class="text-right">آپ کسی بھی کلاس کے کسی بھی سیشن کے کسی بھی سمسٹر میں آفر کردہ تمام سبجیکٹس میں انرولڈ اور ناٹ انرولڈ طلباء کی تعداد چیک کر سکتے ہیں اور رپورٹس ڈاؤن لوڈ بھی کر سکتے ہیں۔
</h6>
  `  ;

  constructor(    
    private toastr: ToastrService,    
    private datePipe: DatePipe,    
    private classService:ClassService,
    private sessionService:SessionService, 
    private lmsSharedService: LMSharedDataService   
  ) {
    this.class = new Array<SelectList>();    
    this.sessions = new Array<SelectList>();    
   }
   loader=false;
  ngOnInit(): void {
    this.student=[]
    this.loader=false;
    this.updateClasses();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.class = classes;
      })
      .catch(error => {
        console.error("Error getting lms classes", error);
      });
  }
  getTotalEnrollCount(): number {
    return this.student.reduce((acc, curr) => acc + curr.ENROLL_COUNT, 0);
}

getTotalNotEnrollCount(): number {
    return this.student.reduce((acc, curr) => acc + curr.NOT_ENROLL_COUNT, 0);
}

getTotalTotalCount(): number {
    return this.student.reduce(
        (acc, curr) => acc + curr.ENROLL_COUNT + curr.NOT_ENROLL_COUNT,
        0
    );
}

  OnSemesterChange(){
    this.student=[]        
  }

  OnClassChange(){
    this.formRef.controls['se_id'].reset();
    this.formRef.controls['t_no'].reset();    
    this.student=[]
    this.updateSessions();
  }
   
  OnSessionChange(){
    this.formRef.controls['t_no'].reset();
    this.student=[]
  }

  updateSessions() {
    this.lmsSharedService.filterSessionByCode(this.formRef.value.c_code)
      .then(session => {
        this.sessions = session
        if (this.formRef) {
          const initialValue = this.sessions[0]?.id;
          this.formRef?.controls['se_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }
  
  onViewStrengthClick(){
    this.loader=true;
    this.student=[]
    const{c_code,se_id,t_no} = this.formRef.value;
    this.classService.getClassSubjectEnrollCount(c_code,se_id,t_no).subscribe(res=>{      
      this.loader=false;
      this.student=res;      
    },
    err=>{
      this.loader=false
      this.toastr.error('Error Occured');
    }
    )
console.log(this.student)
  }
  

  DownloadExcel() {
    this.toastr.info('Generating Excel File...',null,{timeOut:120000});
    let body = [];
    let classname='';
    let i = 1;
    this.student.forEach(entry => {
      body.push({ SR: i,SUBCODE:entry.SUBCODE,SUBNAME:entry.SUBNAME,SEMESTER: entry.SEMESTER, ENROLLCOUNT: entry.ENROLL_COUNT, NOTENROLLCOUNT: entry.NOT_ENROLL_COUNT});
      i++;
    });

    //to get class name 
    this.class.forEach(element => {
      if(element.id==this.formRef.value.c_code)
      {
        classname=element.nm
      }
    });
    this.toastr.clear();
    new AngularCsv(body, `${classname} Student Count in each subject`, { headers: ['Sr.','SUBJECT CODE','SUBJECT NAME','SEMESTER', 'ENROLL COUNT','NOT ENROLL COUNT'] });
  }

    OnCreatePDF() {    
      this.toastr.info('Generating pdf...',null,{timeOut:120000});
      //togetclassname
      let classname=''
      let session=''
      this.class.forEach(element => {
        if(element.id==this.formRef.value.c_code)
        {        
          classname=element.nm
        }
      });
      //session
      this.sessions.forEach(element => {
        if(element.id==this.formRef.value.se_id)
        {        
          session=element.nm
        }
      });
    const body = [];
    const header = [];
    let k = 0;
    for (let i = 0; i < this.student.length; i++) {
      k++;
      body.push([k,
        this.student[i].SUBCODE,
        this.student[i].SUBNAME,
        this.student[i].SEMESTER,
        this.student[i].ENROLL_COUNT,
        this.student[i].NOT_ENROLL_COUNT        
      ]);
    }
    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y,h:mm a')
    var image = new Image();
    var page;    
    let cls = classname;    
    let to = this.formRef.value.t_no;
    if(this.formRef.value.t_no==20){
      to = 'COMPLETE';
    }    
    image.src = '../../../assets/images/logo3.png';
    header.push(['Sr #', 'Subject Code', 'Subject Name', 'Semester','ENROLL COUNT','NOT ENROLL COUNT' ]);
    const doc = new jsPDF();
    autoTable(doc, {
      margin: { top: 40, bottom: 10 },
      styles: { fontSize: 8},
      head: header,
      body: body,
      didDrawPage: function () {
        doc.addImage(image, 177, 2, 25, 25);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 172, 30);
        doc.setFontSize(20);
        doc.setFont('Arial');
        doc.text("GC UNIVERSITY LAHORE", 100, 10, { align: 'center' });
        doc.setFontSize(12);
        doc.text("STUDENT COUNT IN EACH SUBJECT", 100, 17, { align: 'center' });
        doc.text("CLASS: " + cls?.toUpperCase(), 100, 24, { align: 'center' });
        doc.text("SESSION: " + session +"     SEMESTER: "+to, 100, 31, { align: 'center' });        
        doc.setFontSize(10);
        page = doc.getNumberOfPages();
        doc.text("Note:- Errors and Omissions Excepted", 145, 288);
        doc.text("Directorate of Information Technology", 8, 288);
      }
    });
    var l = (body.length + 65) % 26;
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 90, 288);
    }
    this.toastr.clear();
    // doc.save(`StudentCount `);   
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }
  

  Filter()
  {
    filter();
  }
}

