import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../../../axios';
@Injectable({
    providedIn: 'root'
})
export class ProgressServices {
    constructor(private http: HttpClient) {
    }
    getExamRslt(c_code: string,se_id:string,start_rn:string,end_rn:string,t_no:number,maj_id:string){
        return this.http.get(`${baseUrl}/progress/getExamRslt2/${c_code}/${se_id}/${start_rn}/${end_rn}/${t_no}/${maj_id}`);
    }

    
    insertUpdateDegInfo(params){
        return this.http.post(`${baseUrl}/api/examSummary/insertUpdateDegInfo`,params);
      }
}