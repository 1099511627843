import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from '../shared/services/Class.service';
import { ExaminationService } from '../shared/services/Examination.service';
import { SessionService } from '../shared/services/Sessions.service';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';

@Component({
  selector: 'app-letter-grd-class',
  templateUrl: './letter-grd-class.component.html',
  styleUrls: ['./letter-grd-class.component.css'],
  animations:[
    SlideInFromLeft()
  ]
})

export class LetterGrdClassComponent implements OnInit {
  @ViewChild("f") formRef: NgForm;
  classes:Array<SelectList>
  session:Array<number>;
  se_st_year='';
  grade:Array<{SE_ID: number,ST_GPER: number, END_GPER: number, L_GRD: string,G_POINT: string, DESCR:string}>;
  
  grade1:Array<{ID:number, SE_ID: number,ST_GPER: number, END_GPER: number, L_GRD: string,G_POINT: string, DESCR:string}>;   

  
  showTable:boolean=false;
  constructor(
    private SessSer:SessionService,
    private examSer:ExaminationService, 
    private toaster:ToastrService,
    private lmsSharedService: LMSharedDataService) {
    this.classes=[];
    this.session = [];
    this.grade = [];
    this.grade1 = [];

  }

  ngOnInit(): void {
    this.updateClasses();
    this.grade.push({SE_ID: 489,ST_GPER: 0, END_GPER: 49, L_GRD: 'F',G_POINT: '0.0', DESCR:'GRADES'});
    this.grade.push({SE_ID: 489,ST_GPER: 50, END_GPER: 54, L_GRD: 'D',G_POINT: '1.0', DESCR:'GRADES'});
    this.grade.push({SE_ID: 489,ST_GPER: 55, END_GPER: 59, L_GRD: 'C-',G_POINT: '1.7', DESCR:'GRADES'});
    this.grade.push({SE_ID: 489,ST_GPER: 60, END_GPER: 64, L_GRD: 'C',G_POINT: '2.0', DESCR:'GRADES'});
    this.grade.push({SE_ID: 489,ST_GPER: 65, END_GPER: 69, L_GRD: 'C+',G_POINT: '2.30', DESCR:'GRADES'});
    this.grade.push({SE_ID: 489,ST_GPER: 70, END_GPER: 74, L_GRD: 'B-',G_POINT: '2.70', DESCR:'GRADES'});
    this.grade.push({SE_ID: 489,ST_GPER: 75, END_GPER: 79, L_GRD: 'B',G_POINT: '3.0', DESCR:'GRADES'});
    this.grade.push({SE_ID: 489,ST_GPER: 80, END_GPER: 84, L_GRD: 'B+',G_POINT: '3.3', DESCR:'GRADES'});
    this.grade.push({SE_ID: 489,ST_GPER: 85, END_GPER: 89, L_GRD: 'A',G_POINT: '3.7', DESCR:'GRADES'});
    this.grade.push({SE_ID: 489,ST_GPER: 90, END_GPER: 100, L_GRD: 'A+',G_POINT: '4.0', DESCR:'GRADES'});
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;    
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  onChangeClass(){
    this.session=[];
    this.se_st_year='';
    this.showTable=false;
  }

  onChangeYear(){
    this.session = [];
    this.showTable=false;
    this.SessSer.EXAM_getSessionID(this.formRef.value.c_code,this.formRef.value.se_st_year).subscribe((res:{SE_ID:number}[]) =>{
      res.forEach(e =>{
        this.session.push(e.SE_ID)
      })
    })
  }

  changeSessionID(){
    this.showTable=true;
  }
    
  onChangeRow(old:string, index:number,row:any,newValue:string,event,isNumaric:boolean,intVal:number){
    if(isNumaric){
      if(intVal==1){
        this.grade1[index].ST_GPER=parseFloat(newValue);
        this.updatefunction(row,event,newValue,old);
      }
      else{
        this.grade1[index].END_GPER=parseFloat(newValue);
        this.updatefunction(row,event,newValue,old);
      }
    }
    else{
      if(intVal==3){
        this.grade1[index].L_GRD=newValue;
        this.updatefunction(row,event,newValue,old);

      }
      else if(intVal==4){
        this.grade1[index].G_POINT=newValue;
        this.updatefunction(row,event,newValue,old);
      }
      else{
        this.grade1[index].DESCR=newValue;
        this.updatefunction(row,event,newValue,old);
      }
    }
  }

  private updatefunction(row:any,event,newValue:string,old:string){
    this.examSer.EXAM_updateLetterGrade(row).subscribe((res:{affectedRows:number}) =>{
      if(res==null || !res){
        event.target.value=old;
        this.toaster.error('Some Error occured.')
      }
      else{
        event.target.value=newValue;
        this.toaster.success('Updated.');
      }
    })
  }

  OnSubmit(){    
    const {c_code, se_st_year, se_id } = this.formRef.value;
    if(this.session[0]!=se_id){
      this.toaster.warning('Select SessionID');
      return;
    }
    this.grade1=[];
    this.examSer.EXAM_insetLetterGrade({c_code, se_st_year, se_id, grade:this.grade}).subscribe((res:{affectedRows:number}) =>{
      if(res==null || !res){
        this.toaster.error('Some Error occured.')
      }
      else{
        this.toaster.success('Record Added Successfully.');
        res[0]?.forEach(e =>{
          this.grade1.push({ID:e.ID,SE_ID: e.SE_ID,ST_GPER: e.ST_GPER, END_GPER: e.END_GPER, L_GRD: e.L_GRD,G_POINT: e.G_POINT, DESCR:e.DESCR})
        })
      }
    })
  }

}
