import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { UtilService } from 'src/app/Util.service';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SubjectOfferService } from './../shared/services/SubjectOffer.service';
import { ClassService } from './../shared/services/Class.service';
import { GroupService } from './../shared/services/Groups.service';
import { SelectList } from '../shared/model/SelectList.model';
import { SessionService } from './../shared/services/Sessions.service';
import { MajorService } from './../shared/services/Major.service';
import { EnrollmentService } from '../shared/services/Enrollment.service';
import { filter } from 'src/app/shared/functions/tableSearch';

interface student {
  ROLNO: string;
  NM: string
  RN: number;
  YEAR: number;
  SECTION: string;
  MAJ_ID: number;
  SUB_CODE: string;
}

interface IDC {
  SUB_CODE: string;
  SECTION: string;
}

@Component({
  selector: 'app-idc-allocation',
  templateUrl: './idc-allocation.component.html',
  styleUrls: ['./idc-allocation.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class IdcAllocationComponent implements OnInit {
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  subjects: Array<{ sub_code: string, sub_nm: string, d_id }>;
  students: Array<student>;
  section: Array<IDC>;
  groups: Array<SelectList>;
  isAdmin: boolean = false;
  group: number;
  isAllocated: boolean = false;
  lastRoll: string;
  isSection: string;
  loader: boolean;

  @ViewChild('f') formRef: NgForm;

  constructor(private authenticationService: AuthenticationService,
    private utilService: UtilService,
    private toastr: ToastrService,
    private groupService: GroupService,
    private subOfferService: SubjectOfferService,
    private classService: ClassService,
    private majorService: MajorService,
    private sessionService: SessionService,
    private enrollmentService: EnrollmentService) {
    this.classes = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.subjects = new Array<{ sub_code: string, sub_nm: string, d_id }>();
    this.students = new Array<student>();
    this.section = new Array<IDC>();

    this.groups = new Array<SelectList>();

    // this.isAdmin = this.authenticationService.isAdmin();
    // this.group = this.authenticationService.getGroup();
  }

  ngOnInit(): void {
    // this.loadGroups();
    this.updateClasses();
    this.loader = false;
  }

  updateClasses() {
    this.classes = [];
    this.majors = [];
    this.sessions = [];
    this.subjects = [];
    this.classService.getidcClasses().subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
    });
  }

  loadGroups() {
    this.groups = [];
    this.majors = [];
    this.subjects = [];
    this.groupService.getIDCGroup(this.formRef.value.c_code).subscribe((res: { GRP: number, G_NM: string }[]) => {
      res?.forEach(element => {
        this.groups.push({ id: element.GRP, nm: element.G_NM });
      });
    }, err => {
      console.log(err);
    });
  }

  updateSessions() {
    this.sessions = [];
    this.subjects = [];

    if (this.formRef.value.c_code === "") return;

    this.sessionService.getSessions(this.formRef.value.c_code).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(entry => {
        this.sessions.push({ id: entry.SE_ID, nm: entry.SE_NM });
      });
    });
  }

  updateSubjects() {
    this.subjects = [];
    if (!this.group || this.formRef.value.maj_id === "") return;

    this.subOfferService.getSubIDC(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.se_id, this.formRef.value.t_no).subscribe((res: { SUB_CODE: string, D_ID: string, SUB_TITLE_PRINT: string }[]) => {
      res?.forEach(entry => {
        this.subjects.push({ sub_code: entry.SUB_CODE, sub_nm: entry.SUB_TITLE_PRINT, d_id: entry.D_ID });
      });
    });
  }

  updateStudentAndSection() {
    this.section = [];
    this.students = [];
    this.loader = true;
    this.enrollmentService.getidcStudentAndSection({ grp: this.group.toString(), c_code: this.formRef.value.c_code, t_no: this.formRef.value.t_no, se_id: this.formRef.value.se_id }).
      subscribe((res: [student[], IDC[]]) => {
        if(res[0].length == 0) this.toastr.warning("Data Not Found");
        res[0]?.forEach(entry => {
          this.students.push(entry);
        })
        res[1]?.forEach(entry => {
          this.section.push(entry);
        })
        this.loader = false;
      }, err => {
        this.loader = false;
        console.log(err);
      })
  }

  fetchRecord() {
    this.updateStudentAndSection();
  }

  OnSubmit() {
    if (!this.formRef.valid)
      return;
  }


  // UpdateClassSection(sec: HTMLElement) {
  //   if (this.isSection === sec.innerHTML) {
  //     sec.style.background = "rgba(255,255,0, 0.3)";
  //     const se = " ";
  //     this.utilService.updateWholeClassSection('565', '21', this.formRef.value.maj_id, '3', this.formRef.value.sub_code, se).subscribe(res => {
  //       this.toastr.success("Section Updated successfully");
  //     }, err => {
  //       console.log(err);
  //     });
  //     this.isSection = "";
  //   }
  //   else {
  //     this.utilService.updateWholeClassSection('565', '21', this.formRef.value.maj_id, '3', this.formRef.value.sub_code, sec.innerHTML).subscribe(res => {
  //       this.toastr.success("Section Updated successfully");
  //     }, err => {
  //       console.log(err);
  //     });
  //     this.isSection = sec.innerHTML;
  //   }
  //   this.updateStudentAndSection();
  // }

  UpdateStudentSection(index: number, sec: string, sub_code: string, rol: string, year: string, maj_id: string) {

    let newSec = (sec === this.students[index].SECTION) ? '1' : sec;

    this.utilService.idcAllocation(year, this.formRef.value.se_id, this.formRef.value.c_code, maj_id, this.formRef.value.t_no, rol, sub_code, newSec).subscribe((res: IDC) => {
      if (res) {
        this.students[index].SECTION = res.SECTION;
        this.students[index].SUB_CODE = res.SUB_CODE;
        this.toastr.success("Section Updated successfully");
      } else {
        this.toastr.warning("Failed to update.");
      }
    }, err => {
      console.log(err);
    });
  }


  OnGrpChange(grp) {
    this.group = parseInt(grp.value);
    this.updateSessions();
  }

  // OnSemesterChange(sem: HTMLSelectElement) {
  //   this.updateSubjects();
  // }

  OnSessionChange(s: HTMLSelectElement) {
    if (this.formRef.value.grp !== "" && this.formRef.value.se_id !== "") {
      // this.updateStudentAndSection();
    }
  }

  // OnSubjectChange(sub: HTMLSelectElement) {
  //   this.updateStudentAndSection();
  // }

  // OnMajorChange(m: HTMLSelectElement) {
  //   // this.updateSessions();
  //   this.updateSubjects();

  // }


  OnClassChange(c: HTMLSelectElement) {
    this.loadGroups();
  }
  Clear(f) {
    f.resetForm();
    this.section = [];
    this.students = [];
    this.loader = false;
  }

  Filter() {
    filter();
  }
}
