import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';

@Injectable({
  providedIn: 'root'
})
export class courseOutlineService {

  constructor(private http: HttpClient) {
  }

  insertOutline(params) {
    return this.http.post(`${baseUrl}/api/courseOutline/insertOutline`, params);
  }
  updateWeekOne(params){
    return this.http.post(`${baseUrl}/api/courseOutline/updateWeekOne`, params);
  }
  viewCourseOutline(param) {
    return this.http.post(`${baseUrl}/api/courseOutline/viewCourseOutline`, param);
  }

  getStdCourseOutline(param) {
    return this.http.post(`${baseUrl}/api/courseOutline/getStdCourseOutline`, param);
  }

  deleteWeek(param){
    return this.http.post(`${baseUrl}/api/courseOutline/deleteWeek`, param);
  }
  
}
