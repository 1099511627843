<div class="row p-2" [@SlideInFromLeft]>
    <div class="col-sm-12">
        <div class="btn-group" role="group" aria-label="Basic example">
                <button style="margin-left:-8px" type="button"  class="btn btn-outline-info" routerLinkActive="active" routerLink="student" *ngIf="isAdmin||canFeeStructure||isEndownmentFund">Student Wise</button>
                <button type="button"  class="btn btn-outline-info" routerLinkActive="active" routerLink="groupBase" *ngIf="isAdmin||canFeeStructure||canEnrolleRepeater||canReadmission||isEndownmentFund">Group-Base Wise</button>
                <button type="button"  class="btn btn-outline-info" routerLinkActive="active" routerLink="demand" *ngIf="isAdmin||canFeeStructure||isEndownmentFund">Demand Collection</button>
                <button type="button"  class="btn btn-outline-info" routerLinkActive="active" routerLink="pendingDues" *ngIf="isAdmin||canFeeStructure||isEndownmentFund">Pending Dues</button>
                <button type="button"  class="btn btn-outline-info" routerLinkActive="active" routerLink="levelwise" *ngIf="isAdmin||canFeeStructure||canEnrolleRepeater||canReadmission||isEndownmentFund">Level Wise</button>
            </div>
    </div>
</div>
<router-outlet></router-outlet>