<div [@SlideInFromLeft]>
  <div class="container-fluid mb-2 card px-0">
    <div class="card-header"  style="padding-top: 10px;">
      <div class=" col-lg-6 col-md-6 form-group">
        Teacher Report</div>
         <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
        <app-details-button [details]="details"></app-details-button>
    </div>
  </div>
    <form (ngSubmit)="OnSubmit()" #f="ngForm">
      <div class="row card-body px-4" style="margin-top: -10px">

        <div *ngIf="isAdmin" class="col-lg-4 col-md-4 col-sm-12 form-group">
          <label for="dpt">Department</label>
          <select #dpt class="form-control" name="dpt" id="dpt" (change)="OnDeptChange(dpt)" ngModel required>
            <option *ngFor="let d of departments" value="{{d.id}}">{{d.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 form-group">
          <label for="Teacher">Teacher</label>
          <select #teacher class="form-control" (change)="OnTeacherChange(teacher)" name="fm_id" id="Teacher" ngModel required>
            <option *ngFor="let fm of teachers" value="{{fm.id}}">{{fm.nm.trim()}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 form-group">
          <label for="c_year">Year</label>
          <select #c_year class="form-control" (change)="OnYearChange()" name="c_year" id="c_year" ngModel required>
            <option value="0">All</option>
            <option *ngFor="let year of currentyears" value="{{year.year}}">{{year.year}}</option>
          </select>
        </div>


        <div style="margin-top: 10px;">
          <button *ngIf="isAdmin" type="button" (click)="OnClickAll()" class="btn btn-primary mt-3"
            style="margin-left: 15px;" [disabled]="!f.valid">View Attendance
            Report</button>
          <button *ngIf="!isAdmin" type="button" (click)="OnClick()" class="btn btn-primary mt-3"
            style="margin-left: 15px;" [disabled]="!f.valid">View Single Teacher Attendance</button>
          <button *ngIf="isAdmin" type="button" (click)="OnClick()" class="btn btn-outline-primary mt-3"
            style="margin-left: 15px;" [disabled]="!f.valid">View Single Teacher</button>
          <button *ngIf="this.data.length>0" type="button" (click)="CreatePDF()" class="btn btn-danger mt-3"
            style="margin-left: 15px;">
            <i class="fa fa-file-pdf-o" for=""></i> Create Single Teacher PDF</button>
          <input *ngIf="btnhide&&isAdmin" (click)="PDFCreate()" class="btn btn-md btn-outline-danger mt-3"
            style="margin-left: 15px;" value="Create PDF">
        </div>
      </div>
      <loader *ngIf="loader"></loader>
      <div class="row card-body px-4" style="margin-top: -3rem;">
        <div *ngIf="this.data.length>0" class="col-lg-4 col-md-4 col-sm-12 form-group">
          <label for="dpt">CNIC</label>
          <input class="form-control" name="dpt" id="dpt" value='{{this.data[0].NIC}}' readonly>
        </div>
        <div *ngIf="this.data.length>0" class="col-lg-4 col-md-4 col-sm-12 form-group">
          <label for="dpt">Email</label>
          <input class="form-control" name="dpt" id="dpt" value='{{this.data[0].EMAIL}}' readonly>
        </div>
        <div *ngIf="this.data.length>0" class="col-lg-4 col-md-4 col-sm-12 form-group">
          <label for="dpt">Phone number</label>
          <input class="form-control" name="dpt" id="dpt" value='{{this.data[0].PH1}}' readonly>
        </div>
      </div>
      <div class="col-lg-12 col-md-6 form-group px-4 mt-0 pt-0"
        *ngIf="data.length > 0 || checkTeacherAttendance.length > 0">
        <input class="form-control col-lg-4 mt-0" type="text" name="Search" placeholder="Search" id="myInput"
          (keydown)="Filter()">
      </div>
      <div *ngIf="checkTeacherAttendance.length>0" class="col-sm-12 col-lg-12 px-4 mt-sm-0 mt-lg-0">
        <table class="table table-bordered table-striped m-0 p-0" style="overflow: hidden; overflow-x: scroll"
          id="table">
          <thead class="thead-dark">
            <tr>
              <th>Sr.No</th>
              <th>Teacher Name</th>
              <th>Session</th>
              <th>Term</th>
              <th>Sub-Code</th>
              <th>Section</th>
              <th>Class</th>
              <th>Attendance</th>
              <th>Assignment</th>
              <th>Quiz</th>
              <th>Mid</th>
              <th>Final</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let f of checkTeacherAttendance,let j = index" id="values">
              <td>
                {{j+1}}
              </td>

              <td *ngIf="f.T_NM===null" style="color: red;">
                Not Assign
              </td>
              <td *ngIf="f.T_NM!==null">
                {{f.T_NM}}
              </td>

              <td>
                {{f.SE}}
              </td>
              <td>
                {{f.SEMESTER}}
              </td>
              <td>
                {{f.SUB_CODE}}
              </td>
              <td>
                {{f.SECTION}}
              </td>
              <td>
                {{f.CLASS}}
              </td>
              <td *ngIf="f.TOTAL_LEC===null" style="color: red;">
                {{0}}
              </td>
              <td *ngIf="f.TOTAL_LEC!==null">
                {{f.TOTAL_LEC}}
              </td>
              <td *ngIf="f.ASSIGNMENT===0" style="color: red;">
                {{f.ASSIGNMENT}}
              </td>
              <td *ngIf="f.ASSIGNMENT!==0">
                {{f.ASSIGNMENT}}
              </td>
              <td *ngIf="f.QUIZ===0" style="color: red;">
                {{f.QUIZ}}
              </td>
              <td *ngIf="f.QUIZ!==0">
                {{f.QUIZ}}
              </td>
              <td *ngIf="f.MID===0" style="color: red;">
                Not Conducted
              </td>
              <td *ngIf="f.MID!==0">
                Conducted
              </td>
              <td *ngIf="f.FINAL===0" style="color: red;">
                Not Conducted
              </td>
              <td *ngIf="f.FINAL!==0">
                Conducted
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <div *ngIf="this.data.length>0" class="col-sm-12 col-lg-12 px-4 mt-sm-5 mt-lg-0">
        <table class="table table-bordered table-striped m-0 p-0" style="overflow: hidden; overflow-x: scroll"
          id="table">
          <thead class="thead-dark">
            <tr>
              <th>Sr No</th>
              <th>Group</th>
              <th>Class</th>
              <th>Session</th>
              <th>Semester</th>
              <th>Section</th>
              <th>Subject Code</th>
              <th>Subject Title</th>
              <th>Credit Hr</th>
              <th>Submitted to Examination</th>
              <th>Attendance</th>
              <th>Assessment</th>
              <th>Students</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let f of data,let j = index" id="values">
              <td>
                {{j+1}}
              </td>
              <td>
                {{f.G_NAME}}
              </td>
              <td>
                {{f.CLASS}}
              </td>
              <td>
                {{f.SE_NM}}
              </td>
              <td>
                {{f.T_NO}}
              </td>
              <td>
                {{f.SECTION}}
              </td>
              <td>
                {{f.SUB_CODE}}
              </td>
              <td>
                {{f.SUB_NM}}
              </td>
              <td>
                {{f.CREDIT_HOUR}}
              </td>

              <td>
                <ng-container *ngIf="f.STATUS > 4; else crossMark">
                  <span class="text-success" style="font-size: 18px;">
                     <!-- Font Awesome tick icon -->
                     <!-- <i class="fas fa-check"></i> -->
                      <i>
                        <img src="../../../assets/tick_mark.png" width="25rem" alt="">
                      </i>
                  </span>
                </ng-container>
                <ng-template #crossMark>
                  <span class="text-danger" style="font-size: 18px;">
                    <!-- Font Awesome times (cross) icon -->
                    <!-- <i class="fas fa-times"></i>  -->
                    <i>
                      <img src="../../../assets/cross.png" width="25rem" alt="">
                    </i>
                  </span>
                </ng-template>
              </td>

              <td>
                <button type="button" (click)="OnAttendance(f)" class="btn btn-sm btn-primary">Attendance</button>
              </td>
              <td>
                <button type="button" (click)="OnAssessment(f)" class="btn btn-sm btn-primary">Assessment</button>
              </td>
              <td>
                <button type="button" (click)="getEmtpyStdAttendance(f,j)"
                  class="btn btn-sm btn-primary">Students</button>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <br>
    </form>
  </div>
</div>