import { Component, OnInit, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { UtilService } from 'src/app/Util.service';
import { StudentService } from '../shared/services/Student.service';
import { SessionService } from './../shared/services/Sessions.service';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { FeeService } from '../shared/services/Fee.service';


interface AcademicInfo {
  SUB_CODE: string[];
  SUB_NM: string[];
  PER: string[];
  GRD: string[];
};

@Component({
  selector: 'app-student-admission-detail',
  templateUrl: './student-admission-detail.component.html',
  styleUrls: ['./student-admission-detail.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class StudentAdmissionDetailComponent implements OnInit {

  isAdmin: boolean = false;
  classes: Array<SelectList>
  sessions: Array<SelectList>;
  rolnoArr: Array<SelectList>;
  getDetBtn: boolean = false;
  @ViewChild('fn') usnmRef: NgForm;
  admissionDetail: Array<{
    nm: string, f_nm: string, nic: any, f_nic: any, year: number, class: string, deptt: string, set_no: number, major:string, rn: number, session: string, email: string, add1: string, ph1: any, ph2: any, gender: string, relig: string, rolno: string  
  }>;

  paidChallan: any;
  unpaidChallans: any;
  schpFeeConcDuesAdj: any;
  outStndDues: any;
  feeRefund: any;
  oneTime: any;

  AcademicDetail: Record<string, AcademicInfo> = {};
  showAcademicTable: boolean = false;
  showAdmissionDetail: boolean = false;
  showFeeDetail: boolean = false;

  showDetail: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private classService: ClassService,
    private sessionService: SessionService,
    private studentService: StudentService,
    private feeService: FeeService,
    private toaster: ToastrService
    ) {
    this.isAdmin = this.authenticationService.isAdmin();
    this.classes = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.rolnoArr = new Array<SelectList>();
    this.admissionDetail = new Array<{
      nm: string, f_nm: string, nic: any, f_nic: any, year: number, class: string, deptt: string, set_no: number, major:string, rn: number, session: string, email: string, add1: string, ph1: string, ph2: string, gender: string, relig: string, rolno: string  
    }>();
    this.paidChallan = [];
    this.unpaidChallans = [];
    this.schpFeeConcDuesAdj = [];
    this.outStndDues = [];
    this.feeRefund = [];
    this.oneTime = [];
   }

  ngOnInit(): void {
    this.loadClassess("-1")
  }
  loadClassess(grp: string) {
    let param = {};

    param['grp'] = grp;

    this.classService.getClasses(param).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
    });
  }

  OnClassChange() {
    this.rolnoArr = [];
    this.getDetBtn = false;
    this.updateSessions();
  }

  updateSessions() {
    this.sessions = [];
    this.rolnoArr = [];
    this.getDetBtn = false;
    if (this.usnmRef.value.c_code === "") return;
    this.sessionService.getSessions(this.usnmRef.value.c_code).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(entry => {
        this.sessions.push({ id: entry.SE_ID, nm: entry.SE_NM });
      });
    });
  }

  loadRollno() {
    this.rolnoArr = []
    if (!this.usnmRef.value?.se_id) {
      return;
    }
    let param = { c_code: this.usnmRef.value?.c_code, se_id: this.usnmRef.value?.se_id }
    this.studentService.getStudentsRollNumbersForDetail(param).subscribe((
      res: { RN: number, ROLNO: string }[]) => {
      if (res?.length == 0) {
        this.getDetBtn = false;
      }
      else {
        this.getDetBtn = true;
      }
      res?.forEach(element => {
        this.rolnoArr.push({ id: element.RN, nm: element.ROLNO });
      });
      // console.log(this.rolnoArr)
    });
  }

  getAdmissionDetail(rolno: HTMLSelectElement, f_no: HTMLSelectElement, ch_no:HTMLSelectElement){

    // console.log("Roll #####" ,r.value);
    if ((!rolno.value) && (!f_no.value) && (!ch_no.value)) {
      this.toaster.warning("Enter at least one option");
      return ;
    }
    this.showAcademicTable = false;
    this.showAdmissionDetail = false;
    this.showFeeDetail = false;

    this.admissionDetail = [];
    // console.log(this.admissionDetail)
    // const {rolno, f_no, ch_no} = this.usnmRef.value;

    this.studentService.getStudentAdmissionDetail(rolno.value, f_no.value, ch_no.value).subscribe((res : any) => {

      // console.log(res);
      this.admissionDetail.push(res[0]);
      this.showAdmissionDetail = true;
      // console.log(this.admissionDetail)
    });
  }

  // getAdmissionDetail() {

  //   console.log( "Outter", this.usnmRef.value.rolno, this.usnmRef.value.f_no, this.usnmRef.value.ch_no);
  //   if ((!this.usnmRef.value?.rolno) && (!this.usnmRef.value?.f_no) && (!this.usnmRef.value?.ch_no)) {
  //     console.log( "Inner", this.usnmRef.value.rolno, this.usnmRef.value.f_no, this.usnmRef.value.ch_no);
  //     this.toaster.warning("Enter at least one option");
  //     return ;
  //   }
  //   console.log(this.usnmRef)
  //   this.showAcademicTable = false;
  //   this.showAdmissionDetail = false;
  //   this.showFeeDetail = false;
  //   this.admissionDetail.length = 0;
  //   this.studentService.getStudentAdmissionDetail(this.usnmRef.value.rolno, this.usnmRef.value.f_no, this.usnmRef.value.ch_no).subscribe(
  //     (res: any) => {
  //         if (res[0]){
  //           this.admissionDetail.push(res[0])
  //         }
  //         console.log(res)
  //         console.log(this.admissionDetail)
  //       }
  //       // ,
  //       // (error) => {
  //       //   console.error('Error occurred:', error);
  //       //   // Handle errors in a more detailed way if needed
  //       // }
  //     );

  //     this.showAdmissionDetail = true;
  //     console.log(this.usnmRef)
  // }

  getFeeDetails(rolno: HTMLSelectElement, f_no: HTMLSelectElement, ch_no:HTMLSelectElement) {
    // this.admissionDetail = [];
    if ((!rolno.value) && (!f_no.value) && (!ch_no.value)) {
      this.toaster.warning("Enter at least one option");
      return ;
    }

    this.showAcademicTable = false;
    this.showFeeDetail = false;
    this.showAdmissionDetail = false;
    this.paidChallan = []
    this.unpaidChallans = [];
    this.schpFeeConcDuesAdj = [];
    this.outStndDues = [];
    this.feeRefund = [];
    this.oneTime = [1]
    this.feeService?.getFeeDetails(rolno.value, f_no.value, ch_no.value).subscribe(
        res => {
          console.log(res)
        res[0]?.forEach(element =>{
          this.paidChallan.push(element)
        })
        res[1]?.forEach(element =>{
          this.paidChallan.push(element)
        })
        res[2]?.forEach(e=>{
          this.unpaidChallans.push(e)
        })
        res[3]?.forEach(e=>{
          this.unpaidChallans.push(e)
        })
        res[4]?.forEach(e=>{
          this.schpFeeConcDuesAdj.push(e)
        })
        res[5]?.forEach(e=>{
          this.outStndDues.push(e)
        })
        res[6]?.forEach(e=>{
          this.feeRefund.push(e)
        })
        // console.log('paid challans', this.paidChallan)
        },
        (error) => {
          console.error('Error occurred:', error);
          // Handle errors in a more detailed way if needed
        }
      );

      this.showFeeDetail = true;
  }

  totalPaidChallans(): number {
    return this.paidChallan.reduce((total, e) => total + parseInt(e.debit), 0);
  }
  totalUnpaidChallans(): number {
    return this.unpaidChallans.reduce((total, e) => total + parseInt(e.debit), 0);
  }

  totalOutstdDues(): number {
    return this.outStndDues.reduce((total, e) => total + parseInt(e.amnt), 0);
  }

  totalFeeRefund(): number {
    return this.feeRefund.reduce((total, e) => total + parseInt(e.amnt), 0);
  }

  getAcademicDetails(rolno: HTMLSelectElement){

    if (!rolno.value) {
      
      this.toaster.warning("Select roll number");
      return ;
    }

    this.AcademicDetail = {};
    this.showAdmissionDetail = false;
    this.showFeeDetail = false;
    this.showAcademicTable = false;
    const {c_code, se_id} = this.usnmRef.value;
    var st_year = "";
    var rn;

    this.sessions.forEach(element => {
      if (element.id == se_id) {
        st_year = element.nm;
      }
    });

    this.rolnoArr.forEach(element => {
      if (element.nm == rolno.value) {
        rn = element.id;
      }
    });

    st_year = st_year.split('-')[0];
    // console.log("Selected", c_code, st_year, rn);

    this.studentService.getStudentAcademicDetail(c_code, st_year, rn).subscribe((res :any) => {
      // console.log(res);

      try {

          for (const obj of res[0]) {
            const { T_NO, SUB_CODE, SUB_NM, GRD, PER } = obj;
            const key = `${T_NO}`;
  
            if (key in this.AcademicDetail) {
              this.AcademicDetail[key].SUB_CODE.push(SUB_CODE);
              this.AcademicDetail[key].SUB_NM.push(SUB_NM);
              this.AcademicDetail[key].GRD.push(GRD);
              this.AcademicDetail[key].PER.push(PER);
  
            }
            else {
              this.AcademicDetail[key] = {
                SUB_CODE: [SUB_CODE],
                SUB_NM: [SUB_NM],
                GRD: [GRD],
                PER: [PER],
              };

            }
          }
  
  
      } catch (error) {
        this.toaster.warning("Missing Parameter");
      }
  
    });

    // console.log(this.AcademicDetail);
    this.showAcademicTable = true;
  }

  keys(): string[] {
    return Object.keys(this.AcademicDetail);
  }

  OnRollChange(){
    this.showAdmissionDetail = false;
    this.showFeeDetail = false;
    this.showAcademicTable = false;
    this.AcademicDetail = {};
    this.usnmRef.form.patchValue({ f_no: '' });
    this.usnmRef.form.patchValue({ ch_no: '' });
  }

  OnF_NoChange(){

    this.showAdmissionDetail = false;
    this.showFeeDetail = false;
    this.showAcademicTable = false;
    this.AcademicDetail = {};

    this.usnmRef.form.patchValue({ rolno: '' });
    this.usnmRef.form.patchValue({ ch_no: '' });

  }

  OnCha_NoChange(){

    this.showAdmissionDetail = false;
    this.showFeeDetail = false;
    this.showAcademicTable = false;
    this.AcademicDetail = {};

    this.usnmRef.form.patchValue({ f_no: '' });
    this.usnmRef.form.patchValue({ rolno: '' });

  }
}
