<form #f="ngForm">
  <div [@SlideInFromLeft]>
    <div class="container-fluid card shadow">
      <div class="card-header"  style="padding-top: 10px;">
        <div class=" col-lg-6 col-md-6 form-group">
          Single Subject Enrollment</div>
           <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
          <app-details-button [details]="details"></app-details-button>
      </div>
    </div>
    

      <div class="row card-body" style="padding-bottom: 0px;">

        <div class="col-lg-4 col-md-6 form-group">
          <label for="C_Code">Class</label>
          <select #cource (change)="OnClassChange(cource)" class="form-control " name="c_code" id="C_Code" ngModel
            required>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="Major">Major</label>
          <select class="form-control" #major name="maj_id" id="Major" (change)="OnMajorChange()" ngModel required>
            <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="class" class="text-left">Year</label>
          <input #yearin class="form-control" name="year_in" id="year_endTime" (keyup)="OnYearChange()" #input ngModel
            required>
        </div>


        <div class="col-lg-4 col-md-6 form-group">
          <label for="t_no">Semester</label>
          <input type="number" class="form-control" #Rolno (change)="updateSubjects()" name="t_no" id="t_no" min="0"
            max="8" ngModel required>
        </div>

        <!-- <div class="col-lg-4 col-md-6 form-group">
            <label for="t_no">Roll Number</label>
            <select class="form-control"  name="rolno" id="rolno" ngModel required>
              <option *ngFor="let r of rolno" value="{{r}}">{{r}}</option>
            </select>
          </div> -->
        <div class="col-lg-4 col-md-6 form-group">
          <label for="Subject">Subject</label>
          <select (change)="OnSubjectChange()" #subject class="form-control" name="sub_code" id="Subject" ngModel>
            <option *ngFor="let sub of subjects" value="{{sub.sub_code}}">{{sub.sub_code}} {{sub.sub_nm}}</option>
          </select>
        </div>
      </div>
      
      <div class="card-header">
        Actions
      </div>
      <div class="row card-body">

        <button (click)="OnSingleEnroll()" class="btn btn-outline-primary" type="button" [disabled]="!f.valid">
        Enrollment
        </button>

        <button (click)="SAO_OnDeleteSingleSubjectEnroll()" class="btn  btn-outline-danger ml-1" type="button" [disabled]="!f.valid">
          Delete
        </button>

        <button class="btn btn-sm btn-outline-primary ml-1" type="button" (click)="ClearForm(f)">Clear Form</button>
      </div>

      <div *ngIf="this.studentcountinset.length>0" class="card-header">
        Number of Students in Sets
      </div>
      <div *ngIf="this.studentcountinset.length>0" class="row card-body">
        <table class="mb-0 table table-hover table-bordered">
          <thead class="table-active">
            <th>Students</th>
            <th>Set no</th>
          </thead>
          <tbody>
            <tr *ngFor="let s of studentcountinset;">
              <td>{{s.studentinset}} </td>
              <td> {{s.set_no}} </td>
            </tr>
            <tr>
              <td> {{this.totalstudents}} (Total)</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="this.subinsetoffer.length>0" class="card-header">
        Subject Offered
      </div>
      <div *ngIf="this.subinsetoffer.length>0" class="row card-body">
        <table class="mb-0 table table-hover table-bordered">
          <thead class="table-active">
            <th>Subject Code</th>
            <th>Set no</th>
          </thead>
          <tbody>
            <tr *ngFor="let s of subinsetoffer;">
              <td>{{s.sub_code}} </td>
              <td> {{s.setinsuboffer}} </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ng-container *ngIf="loader then ifBlock;"></ng-container>
      <ng-template #ifBlock>
        <div style="display:flex;justify-content:center; align-items: center;"><span class="loader"></span></div>
      </ng-template>
    </div>
  </div>
</form>