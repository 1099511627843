import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
  providedIn: 'root'
})
export class AssignRollNoService {

  constructor(private http: HttpClient) { }

  getMajorsWithTitle(c_code: string) {
    return this.http.post(`${baseUrl}/api/fee/getMajorsWithTitle`, { c_code });
  }
  GetMeritListStudents(c_code: string, maj_id: string, year: string) {
    return this.http.post(`${baseUrl}/api/fee/GetMeritListStudents`, { c_code, maj_id, year })
  }
  getMeritListCount(c_code: string, maj_id: string, year: string) {
    return this.http.post(`${baseUrl}/api/fee/getMeritListCount`, { c_code, maj_id, year })
  }
  AssignFullRoll(c_code: string, maj_id: string, year: string, rn: string) {
    return this.http.post(`${baseUrl}/api/fee/AssignFullRoll`, { c_code, maj_id, year, rn })
  }
  FetchStdDataFromOnline(year: string, c_code: string, g_code: string, f_no: string) {
    return this.http.post(`${baseUrl}/api/fee/FetchStdDataFromOnline`, { year, c_code, g_code, f_no })
  }
  fetchStdDataOnline(year: string, c_code: string, maj_id: string, f_no: string, b_id: string, brd_id: string, setno: string, teh_id: string, univ_id: string, dist_id: string) {
    return this.http.post(`${baseUrl}/api/fee/fetchStdDataOnline`, { year, c_code, maj_id, f_no, b_id, brd_id, setno, teh_id, univ_id, dist_id })
  }
  CheckBsCodeOnline2021(year: string, c_code: string, f_no: string) {
    return this.http.post(`${baseUrl}/api/fee/CheckBsCodeOnline2021`, { year, c_code, f_no })
  }

  AssignRoll(year: string, c_code: string, rn: string, f_no: string, rolno: string, maj_id: string, reg_no: string,
    nm: string, f_nm: string, gender: string, cnic: string, dob: string, ph1: string, add1: string, email: string, set_no: string, relig: string, shift: string,
    ph2: string, add2: string) {

    return this.http.post(`${baseUrl}/api/fee/AssignRollNo`, {
      year, c_code, rn, f_no, rolno, maj_id, reg_no, nm, f_nm, gender, cnic, dob, ph1, add1,
      email, set_no, relig, shift, ph2, add2
    })
  }


  AssignRollFeeServer(year: string, c_code: string, rn: string, f_no: string, rolno: string, maj_id: string, reg_no: string,
    nm: string, f_nm: string, gender: string, cnic: string, dob: string, ph1: string, add1: string, email: string, set_no: string, relig: string, shift: string,
    ph2: string, add2: string,
    b_id: string,tehsilId: string,dist_id: string,provinceId: string) {
    return this.http.post(`${baseUrl}/api/fee/AssignRollNoFeeServer`, {
      year, c_code, rn, f_no, rolno, maj_id, reg_no, nm, f_nm, gender, cnic, dob, ph1,
      add1, email, set_no, relig, shift, ph2, add2,
      b_id,tehsilId,dist_id,provinceId
    })
  }

  CheckExistingRn(year: string, c_code: string, maj_id: string, rn: string) {
    return this.http.post(`${baseUrl}/api/fee/CheckExistingRn`, { year, c_code, maj_id, rn })
  }
  GetStdDataThroughForms(f_no: string, c_code: string, maj_id: string, year: string, b_id: number, m_list: number) {
    return this.http.post(`${baseUrl}/api/fee/GetStdDataThroughForms`, { f_no, c_code, maj_id, year, b_id, m_list })
  }
  preAssignedRnForms(preAssignedRnForms: string, maj_id: string, year: string) {
    return this.http.post(`${baseUrl}/api/fee/preAssignedRnForms`, { preAssignedRnForms, maj_id, year })
  }
  DeleteStdFromAdm_Stdt(f_no: string, c_code: string, maj_id, year: string) {
    return this.http.post(`${baseUrl}/api/fee/DeleteStdFromAdm_Stdt`, { f_no, c_code, maj_id, year })
  }
  DeleteStdFromAdm_StdtFeeServer(f_no: string, c_code: string, maj_id, year: string) {
    return this.http.post(`${baseUrl}/api/fee/DeleteStdFromAdm_StdtFeeServer`, { f_no, c_code, maj_id, year })
  }
  CheckHighestRn(c_code: string, maj_id: string, year: string) {
    return this.http.post(`${baseUrl}/api/fee/CheckHighestRn`, { c_code, maj_id, year })
  }
  Check_Paid_Unpaid_Chalans(c_code: string, maj_id: string, year: string, sem: string, ch_no: string, b_id: string, m_lst: string) {
    if (b_id == undefined)
      b_id = null
    if (m_lst == undefined)
      m_lst = null
    return this.http.post(`${baseUrl}/api/fee/Check_Paid_Unpaid_Chalans`, { c_code, maj_id, year, sem, ch_no, b_id, m_lst })
  }
  PayChalan(c_code: string, maj_id: string, year: string, ch_no: string, fm_id: string,paid_date:string) {
    return this.http.post(`${baseUrl}/api/fee/PayChalan`, { c_code, maj_id, year, ch_no, fm_id,paid_date })
  }
  UnpayChallan(c_code: any, maj_id: any, year: any, sem: any, ch_no: any, log_id: any) {
    return this.http.post(`${baseUrl}/api/fee/UnpayChallan`, { c_code, maj_id, year, sem, ch_no, log_id })
  }

}