import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-faq-button',
  templateUrl: './faq-button.component.html',
  styleUrls: ['./faq-button.component.css']
})
export class FaqButtonComponent {
  @Output() openFaqModal = new EventEmitter<void>();
  show: boolean;

  constructor(private router: Router){
  }

  openModal() {
    this.openFaqModal.emit();
  }

  isLoginPage():boolean{
    return this.router.url === '/auth';
  }
}
