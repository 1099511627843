import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';

@Injectable({
  providedIn: 'root'
})
export class AlterService {

  constructor(private http:HttpClient) { }

  Term(c_code:string)
  {
    return this.http.post(`${baseUrl}/api/AlterStdResult/Term`,{c_code});
  }
  
  GetName(c_code:string,SE_ST_YEAR:string,rn:string){
    
    return this.http.post(`${baseUrl}/api/AlterStdResult/Info`,{c_code,SE_ST_YEAR,rn});
  }
  GetStdInfo(c_code:string,maj_id:string,year:string,t_no:string,rn:string){

    return this.http.post(`${baseUrl}/api/AlterStdResult/StdResult`,{c_code,maj_id,year,t_no,rn});
  }
  UpdateRem(year:string,c_code:string,maj_id:String,rn:string,t_no,SUB_CODE:string,SE_ID:string)
  {
    return this.http.post(`${baseUrl}/api/AlterStdResult/UpdateRemarks`,{year,c_code,maj_id,rn,t_no,SUB_CODE,SE_ID});
  }


}
