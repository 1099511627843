import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { baseUrl } from '../../../axios'
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../shared/model/SelectList.model';
import { GroupService } from '../shared/services/Groups.service';
import { DatePipe } from "@angular/common";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DepartmentService } from '../shared/services/Department.service';
import { ClassService } from '../shared/services/Class.service';
import { SessionService } from '../shared/services/Sessions.service';
import { SubjectOfferService } from '../shared/services/SubjectOffer.service';
import { UtilService } from 'src/app/Util.service';
import { filter } from 'src/app/shared/functions/tableSearch';

interface teacherInfo {
  FM_ID: string,
  Name: string,
  Subject: string,
  Sub_code: string,
  Department: string,
  Section: string
}

@Component({
  selector: 'app-submission-check',
  templateUrl: './submission-check.component.html',
  styleUrls: ['./submission-check.component.css'],
  animations: [SlideInFromLeft()]
})

export class SubmissionCheckComponent implements OnInit {

  classes: Array<SelectList>;
  sessions: Array<SelectList>;
  subjects: Array<SelectList>;
  sections: Set<string>;
  gr: number;
  selected: any;
  se_select: any;
  sub: any;
  sect: any;
  sem = 2;
  info: Array<teacherInfo>;
  teacher: Set<teacherInfo>;
  @ViewChild('f') formRef: NgForm;
  flag: boolean;

  isAdmin: boolean = false;
  group: number;
  d_id: number;
  groups: Array<SelectList>;
  departments: Array<SelectList>;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  this screen is used to check the submissions of award list by the teachers of a teacher
  of a department of any semester of any session in its class   </h6>
  <h6 class="text-right">یہ اسکرین کسی بھی سیشن کے کسی بھی سمسٹر کی کلاس میں کسی ڈیپارٹمنٹ کے استاد کی جانب سے ایوارڈ لسٹ کے سبمیشن اسٹیٹس کو چیک کرنے کے لئے استعمال ہوتی ہے۔۔
</h6>
  `  ;

  constructor(private http: HttpClient,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private groupService: GroupService,
    private utilService: UtilService,
    private deptServe: DepartmentService,
    private clsServe: ClassService, private session: SessionService, private subOffer: SubjectOfferService) {
    this.groups = new Array<SelectList>();
    this.departments = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.subjects = new Array<SelectList>();
    this.sections = new Set<string>();
    this.info = new Array<teacherInfo>();
    this.isAdmin = this.authenticationService.isAdmin();
    this.group = this.authenticationService.getGroup();
    this.d_id = this.authenticationService.getUser().D_ID;
  }

  ngOnInit(): void {
    this.loadDepartments();
  }

  loadGroups() {
    if (this.isAdmin)
      this.group = -1;
    this.groupService.getGroupM({ grp: this.group }).subscribe((res: { GRP: number, G_NM: string }[]) => {
      this.gr = res[0]?.GRP;
      res?.forEach(element => {
        this.groups.push({ id: element.GRP, nm: element.G_NM });
      });
      if (res[0])
        this.loadClasses();
      else
        this.sem = -1;
    });
  }

  //getUser


  loadDepartments() {
    if (this.isAdmin)
      this.d_id = -1;
    this.deptServe.getDepartments({ d_id: this.d_id, admin: this.isAdmin, filter: true }).subscribe((res: { D_ID: number, D_NM: string }[]) => {
      this.gr = res[0]?.D_ID;
      res?.forEach(element => {
        this.departments.push({ id: element.D_ID, nm: element.D_NM });
      });
      if (res[0])
        this.loadClasses();
      else
        this.sem = -1;
    });
  }


  loadClasses() {
    this.classes = [];
    this.clsServe.getClasses({ d_id: this.gr, sub_d_id: -75 }).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(element => {
        this.classes.push({ id: element.C_CODE, nm: element.C_NM });
      });
      this.selected = res[0]?.C_CODE;
      this.loadSessions(this.selected);
    });
  }

  loadSessions(c_code: number) {
    this.sessions = [];
    this.session.getSessionsfromTm(c_code).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(element => {
        this.sessions.push({ id: element.SE_ID, nm: element.SE_NM });
      });
      this.se_select = res[0]?.SE_ID;
      this.onSemesterChange();
    });
  }

  onClassChange(c: HTMLSelectElement) {
    this.loadSessions(parseInt(c?.value));
  }


  onSemesterChange() {
    this.subjects = []
    if (!this.formRef.value?.C_Code || !this.se_select || !this.formRef.value?.t_no) {
      return;
    }

    this.subOffer.getSubject(this.formRef.value?.C_Code, this.se_select, this.formRef.value?.t_no).subscribe((
      res: { SUB_CODE: number, S_NM: string }[]) => {
      res?.forEach(element => {
        this.subjects.push({ id: element.SUB_CODE, nm: element.S_NM });
      });
      this.sub = res[0]?.SUB_CODE;
    });
  }

  showList() {
    this.flag = true;
    this.info = [];
    if (!this.formRef.value) {
      this.toastr.error("Session and Semester must be Chosen");
      return;
    }

    this.utilService.getDTeachers({ ...this.formRef.value, grp: this.gr }).subscribe((
      res: { FM_ID: string, F_NM: string, CNIC: string, SUB_CODE: string, S_NM: string, D_NM: string, section: string }[]) => {
      if (res.length == 0) {
        this.toastr.info("All Teachers have Submitted their Result");
        return;
      }

      res?.forEach(element => {
        this.info.push({
          FM_ID: element.FM_ID, Name: element.F_NM, Subject: '' + element.SUB_CODE + ' (' +
            element.S_NM + ')', Sub_code: element.SUB_CODE, Department: element.D_NM, Section: element.section
        });
      });
    });
  }

  showRList() {
    this.flag = false;
    this.info = [];
    if (!this.formRef.value) {
      this.toastr.error("Session and Semester must be Chosen");
      return;
    }

    this.utilService.getRTeachers({ ...this.formRef.value, grp: this.gr }).subscribe((
      res: { FM_ID: string, F_NM: string, SUB_CODE: string, S_NM: string, D_NM: string, section: string }[]) => {
      if (res.length == 0) {
        this.toastr.info("No Teacher has Submitted their Result");
        return;
      }
      res?.forEach(element => {
        this.info.push({
          FM_ID: element.FM_ID, Name: element.F_NM, Subject: '' + element.SUB_CODE + ' (' +
            element.S_NM + ')', Sub_code: element.SUB_CODE, Department: element.D_NM, Section: element.section
        });
      });
    }, err => {
      console.log(err);
    });
  }
  OnCreatePDF() {
    let txt;
    let page;
    let index1 = this.departments?.findIndex((ce) => ce.id == this.formRef.value?.d_id);
    let deptname = this.departments[index1]?.nm;
    let exportDate = this.datePipe.transform(new Date(), "MMM d,y, h:mm a");
    let image = new Image();
    image.src = "../../../assets/images/logo3.png";
    if (this.flag) {
      txt = 'Following teachers have not submitted result of their respective subject and Section.';
      // txt1 = '  And,therefore, are respectfully requested to submit their subject result. So that, futher process could be done';
    }
    else {
      txt = 'Following teachers have Submitted result of their respective subject and Section.';
      // txt1 = ' And, therefore, are appreciated by their respective, Chairperson. Indeed these teachers are the nation builder';
    }
    const doc = new jsPDF('p', 'mm', 'A4');
    doc.setFontSize(20);
    doc.setFont('Arial', 'bold');
    doc.text("GC UNIVERSITY LAHORE", 100, 10, { align: 'center' });
    doc.setFontSize(16);
    doc.setFont('Arial', 'normal');
    doc.text('DEPARTMENT OF ' + deptname?.toUpperCase(), 100, 18, { align: 'center' });
    page = doc.getNumberOfPages();
    doc.addImage(image, 180, 2, 25, 25);
    doc.setFontSize(10);
    doc.text(`${exportDate}`, 175, 30);
    doc.setFontSize(13);
    doc.text('' + txt, 17, 27);
    // doc.text('' + txt1, 20, 35)
    autoTable(doc, {
      startY: 32,
      styles: { fontSize: 10 },
      theme: 'grid',
      html: '#html',
    }
    );
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 110, 290, { align: 'center' });
      doc.text("Note:- Errors and Omissions are Excepted", 10, 290);
      doc.text("Directorate of Information Technology", 153, 290);
    }
    //  doc.save("List of Teachers");
    doc.setProperties({
      title: `List of Teachers`,

    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }

  getClasses(param) {
    return this.http.post(`${baseUrl}/api/class/getClasses`, param);
  }

  Filter()
  {
   filter();
  }
}
