import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { jsPDF } from "jspdf";
import { DatePipe } from '@angular/common';
import autoTable, { Row, UserOptions } from 'jspdf-autotable'
import { SelectList, SelectList2 } from '../shared/model/SelectList.model';
import { ClassService } from '../shared/services/Class.service';
import { GroupService } from './../shared/services/Groups.service';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { AwardListServices } from './awardList.services';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';

interface IExamDetail {
  CONDUCTED: boolean,
  T_MARKS: number;
  NM: string,
  SUB_EXAM_ID: number
}
interface IStudent {
  NM: string,
  ROLNO: string,
  MAJ_ID: number,
  RN: number,
  YEAR: number,
  ELIGIBLE: boolean,
  MARKS: number[],
  GRADE?: string,
}

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}
@Component({
  selector: 'app-award-list',
  templateUrl: './award-list.component.html',
  styleUrls: ['./award-list.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class AwardListComponent implements OnInit {

  awardlistForm: UntypedFormGroup;

  classes: Array<SelectList>;
  groups: Array<SelectList>;
  sessions: Array<SelectList2>;

  sectionsAndSub: Map<string, string[]>;
  sections: Array<string>;
  subjecList: string[];
  subjectNameList: Map<string, string>;


  students: IStudent[];
  examDetails: IExamDetail[];

  private mean: number = null;
  private stdv: number = null;
  private cr_hour: number = null;
  todel_rn='';

  group: number;
  isAdmin: boolean = false;
  isExamCell: boolean = false;
  to_del:number[]=[];


  constructor(private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private datePipe: DatePipe, private classService: ClassService,
    private formBuilder: UntypedFormBuilder,
    private awardListService: AwardListServices,
    private groupService: GroupService,
    private lmsSharedService: LMSharedDataService) {
    this.classes = [];
    this.sessions = [];
    this.groups = [];
    this.sectionsAndSub = new Map<string, string[]>();
    this.subjectNameList = new Map<string, string>();
    this.sections = [];
    this.subjecList = [];

    this.students = [];
    this.examDetails = [];

    this.group = this.authenticationService.getGroup();
    this.isAdmin = this.authenticationService.isAdmin();
    this.isExamCell = this.authenticationService.isExamCell();


  }
  ngOnInit(): void {
    this.awardlistForm = this.formBuilder.group({
      grp: ['', Validators.required],
      c_code: ['', Validators.required],
      se_id: ['', Validators.required],
      t_no: ['', Validators.required],
      sub_code: ['', Validators.required],
      section: ['', Validators.required],
    });
    this.loadGroups();
  }

  get f(){
    return this.awardlistForm
  }

  OnGrpChange(grp: HTMLSelectElement) {
    this.classes = [];
    this.group = parseInt(grp.value);
    this.updateClasses();
    this.resetData();

  }

  OnClassChange(c: HTMLSelectElement) {
    this.resetData();

    this.awardlistForm.controls['sub_code']?.reset();
    this.awardlistForm.controls['se_id']?.reset();
    this.awardlistForm.controls['section']?.reset();
    this.updateSessions();
  }

  OnSemesterChange() {
    this.students = [];
    this.updateSections();
    this.resetData();
  }

  OnSessionChange() {
    this.sections = [];
    this.students = [];
    this.awardlistForm.controls['sub_code']?.reset();
    this.awardlistForm.controls['section']?.reset();

    this.updateSections();
    this.resetData();

  }


  onSubChange(sub) {

    this.resetData();
    this.awardlistForm.controls['section'].reset();
    this.sections = [...this.sectionsAndSub.get(sub.value)];

    if(this.sections?.length){
      this.awardlistForm.controls['section'].setValue(this.sections[0])
    }
  }

  resetData(){
    this.students = [];
    this.stdv =null;
    this.mean =null;
    this.cr_hour=null;
    this.examDetails = [];
  }


  getAwardlist() {
    this.todel_rn='';
    this.to_del=[];
    const { c_code, se_id, grp, t_no, sub_code, section } = this.awardlistForm.value;

    this.students = [];

    this.awardListService.getAwardlistAndStd({ c_code, se_id, grp, t_no, sub_code, section })
      .subscribe((res: { students: IStudent[], examInfo: IExamDetail[], warning?: string, resultStats: { MEAN: number, STDV: number, CR_HOUR: number } }) => {

        if (res) {

          const { students, examInfo, warning } = res;
          const { resultStats } = res;

          this.mean = resultStats?.MEAN;
          this.stdv = resultStats?.STDV;
          this.cr_hour = resultStats?.CR_HOUR;

          if (warning) {
            this.toastr.warning(warning);
          } else {
            // students?.forEach((entry)=>{
            //   console.log(entry);
            //   if(entry.GRADE)
            //   this.students.push(entry)
            // });
            this.students=students;
            this.examDetails = examInfo;
          }
        }

      }, err => {
        console.log(err);
        this.toastr.error("Error Occured");
      });
  }

  createAwardList() {
    const { grp, c_code, se_id, t_no, section, sub_code } = this.awardlistForm.value;

    if (!grp || !c_code || !se_id || !t_no || !section || !sub_code) return;

    this.awardListService.createAwardlist({ grp, c_code, se_id, t_no, section, sub_code }).subscribe((res: { STD: string }) => {
      if (res[0]?.STD != 0) {
        this.toastr.success('Created AwardList Successfully.');
        return;
      }
      else {
        this.toastr.error('Some Error Occured.');
        return;
      }
    })
  }

  onTotalMarksChange(examId: number, newMarks, e) {

    const exam = this.examDetails[examId - 1];

    if (!exam.CONDUCTED || !this.valdateWeightage(examId, parseInt(newMarks))) {
      e.target.value = exam.T_MARKS;
      this.toastr.warning('Inalid Weighage');
      return;
    }

    const { grp, c_code, se_id, t_no, section, sub_code } = this.awardlistForm.value;

    if (!grp || !c_code || !se_id || !section || !sub_code) return;

    if (newMarks == 0) {
      newMarks = null;
    }
    this.awardListService.updateTotalWeightageChange({
      grp, c_code, se_id, t_no, section, sub_code, sub_exam_id: examId, newMarks
    }).subscribe((res: { marks: { T_MRKS: number }[] }) => {
      if (res.marks?.length) {
        this.examDetails[examId - 1].T_MARKS = res.marks[0].T_MRKS;
        this.toastr.success("Updated sucessfully");
      }
    }, err => {
      console.log(err);
      this.toastr.error('Unknown Error');
    })
  }

  valdateWeightage(examId: number, marks: number) {
    if (examId === 1) {
      return (marks === 50)
    } else if (examId === 2) {
      return (marks === 20)
    } else {
      return (marks => 0 && marks <= 30)
    }
  }

  totalWeightage() {
    return this.examDetails.reduce((a, b) => a + (b.CONDUCTED ? b.T_MARKS : 0), 0);
  }

  onChangeMark(index: number, examId: number, e) {

    let std = this.students[index]

    const newMarks = parseFloat(e.target.value);

    if (!this.validateMarks(examId, newMarks)) {
      this.toastr.warning('Invalid marks');
      e.target.value = std.MARKS[examId - 1];
      return;
    }

    const { grp, c_code, se_id, t_no, section, sub_code } = this.awardlistForm.value;

    if (!grp || !c_code || !se_id || !section || !sub_code) return;

    this.awardListService.updateSingleStdMrks_in_AWL({
      grp, c_code, se_id, t_no, section, sub_code, maj_id: std.MAJ_ID, rn: std.RN, year: std.YEAR,
      newMarks, sub_exam_id: examId
    }).subscribe((res: { marks: { MRKS: number }[] }) => {
      if (res.marks.length > 0) {
        std.MARKS[examId - 1] = res.marks[0].MRKS;
        this.toastr.success("Marks Updated Successfully")
      }

    }, err => {
      console.log(err);
      this.toastr.error('Unknown Error');
    })
  }

  validateMarks(exam_id, mrks) {
    return (mrks => 0 && mrks <= this.examDetails[exam_id - 1]?.T_MARKS)
  }

  sesionalMarks(marks: number[]) {
    return Math.round(marks[1] + marks[2] + marks[3] + marks[4]);
  }

  totalMarks(marks: number[]) {

    let sessionalmrks = Math.round(marks[1] + marks[2] + marks[3] + marks[4]);
    let finalmrks = Math.round(marks[0]);

    const total = sessionalmrks + finalmrks;

    return total
  }

  genrateAwardlist() {
    this.OnCreatePDF(true, false);
  }

  emptyAwardlist() {
    this.OnCreatePDF(false, false);
  }

  gradesAwardlist() {
    this.OnCreatePDF(true, true);
  }

  onSelect(checked:boolean , i:number){
    let a='';
    if(checked){
      this.to_del.push(i);
      this.todel_rn+=this.students[i].ROLNO+',';
    }
    else{
      this.to_del=this.to_del.filter(element => element!=i);
      a=this.todel_rn.replace(this.students[i].ROLNO+',','');
      this.todel_rn=a;
    }
  }

  deleteAwardlist(){
    if(confirm("Do you really want to Delete Selected records??")){
      this.awardListService.deleteStdAwardlistandmore({...this.awardlistForm.value,todel_rn: this.todel_rn,flag:1}).subscribe((res :any) => {
        this.todel_rn='';
        if(res[0].affectedRows>0){
          this.toastr.success(res[0].affectedRows+" Deleted");
          for(let idx in this.to_del){
            this.to_del[idx];
            this.students.splice((this.to_del[idx]-parseInt(idx)),1);
          }
          this.to_del=[];
        }
        else{
          this.toastr.warning("No record found");
        }
      },err=>{this.toastr.error("Unknown error")});
    }
  }

  private OnCreatePDF(includeMarks: boolean, examinationFormat: boolean) {
    if (!this.students?.length) {
      this.toastr.warning("No Data To Export");
      return;
    }
    else {
      let stdlist = [];
      const header = [];
  
      this.students.forEach((std, i) => {
        let row = [i + 1, std.ROLNO, std.NM];
        //  && std.ELIGIBLE
        if ((includeMarks)) {
          let marks = [...std.MARKS];

          let final = Math.round(marks.shift());
          let sessional = Math.round(marks.reduce((a, b) => a + b, 0));
          let mid = marks.shift();
          if (examinationFormat) {
            if (std.GRADE == 'In') {
              stdlist.push( 
              [{ content: (i+1), styles:{fontStyle:"bold", fillColor: [220, 220, 220]} },
              { content: std.ROLNO, styles:{fontStyle:"bold", fillColor: [220, 220, 220]} },
              { content: std.NM, styles:{fontStyle:"bold", fillColor: [220, 220, 220]} },
              { content: sessional, styles:{fontStyle:"bold", fillColor: [220, 220, 220]} },
              { content: final, styles:{fontStyle:"bold", fillColor: [220, 220, 220]} },
              { content: (sessional + final), styles:{fontStyle:"bold", fillColor: [220, 220, 220]} },
              { content: std?.GRADE, styles:{fontStyle:"bold", fillColor: [220, 220, 220]} }
              ]);
            }
            else {
              row = [...row, sessional, final, (sessional + final), std?.GRADE];
              stdlist.push(row);
            }
          } else {
            if (std.GRADE == 'In') {
            stdlist.push( 
            [{ content: (i+1), styles:{fontStyle:"bold", fillColor: [220, 220, 220]} },
            { content: std.ROLNO, styles:{fontStyle:"bold", fillColor: [220, 220, 220]} },
            { content: std.NM, styles:{fontStyle:"bold", fillColor: [220, 220, 220]} },
            { content: sessional, styles:{fontStyle:"bold", fillColor: [220, 220, 220]} },
            { content: final, styles:{fontStyle:"bold", fillColor: [220, 220, 220]} },
            { content: (sessional + final), styles:{fontStyle:"bold", fillColor: [220, 220, 220]} },
            { content: std?.GRADE, styles:{fontStyle:"bold", fillColor: [220, 220, 220]} }
            ]);
          }
          else {
            row = [...row, sessional, final, (sessional + final)];
            stdlist.push(row);
          }
          }
        }
        else{
       
          row = [...row];
          stdlist.push(row);
        }
      }
      );

      let exportDate = this.datePipe.transform(new Date(), 'MMM d,y, h:mm a')
      let image = new Image();
      let page;

      const { t_no, section, c_code, se_id, grp, sub_code } = this.awardlistForm.value;

      let grpNm = this.groups.find(x => x.id == grp)?.nm;
      let classNm = this.classes.find(x => x.id == c_code)?.nm;
      let session = this.sessions.find(x => x.id == se_id)?.nm;
      let subNm = this.subjectNameList?.get(sub_code);

      let tmean = this.mean?.toFixed(2);
      let tstdv = this.stdv?.toFixed(2);
      let cr_h = this.cr_hour;
      image.src = '../../../assets/images/logo3.png';
      if (examinationFormat) {
        header.push(['Sr#', 'Roll No', 'Name', 'Semester Work', 'Final Term', 'Marks', 'Grade']);
      } else {
        header.push(['Sr#', 'Roll No', 'Name', 'Semester Work', 'Final Term',  'Total']);
      }
      const doc = new jsPDF('p', 'mm', 'A4');
      autoTable(doc, {
        theme: 'grid',
        headStyles: {
          textColor: [0, 0, 0]
          , fillColor: [255, 255, 255], lineWidth: 0.5
        },
        margin: { top: 45, bottom: 20 },
        styles: { fontSize: 9, cellPadding: 1 },
        head: header,
        body: stdlist,
        didDrawPage: function () {
          doc.setFontSize(22);
          doc.setFont('Arial', 'bold');
          doc.text("GC UNIVERSITY LAHORE", 110, 10, { align: 'center' });
          doc.setFontSize(18);
          doc.setFont('Arial', 'normal');
          if (examinationFormat) {
            doc.text("Office of the Controller of Examinations", 110, 17, { align: 'center' });
            doc.setFontSize(14);
            doc.text("Session:  " + session, 110, 23, { align: 'center' });
            doc.text(`Semester- ${t_no}`, 15, 28);
            doc.addImage(image, 180, 2, 25, 25);
            doc.setFontSize(10);
            doc.text(`${exportDate}`, 175, 30);
          } else {
            doc.text("DEPARTMENT OF " + grpNm, 110, 17, { align: 'center' });
            doc.setFontSize(16);
            doc.text('' + classNm, 110, 23, { align: 'center' });
            doc.text('  Final Examination ' + session, 110, 30, { align: 'center' });
            doc.addImage(image, 180, 2, 25, 25);
            doc.setFontSize(10);
            doc.text(`${exportDate}`, 175, 30);
          }

          doc.setFontSize(12);
          doc.text(`Title: ${subNm}`, 15, 35);
          doc.text(`Course Code: ${sub_code}`, 145, 42);
          doc.text(`Section: ${section}`, 15, 42);
          if (examinationFormat) {
            doc.text(`Mean: ${tmean || '0'}`, 40, 42);
            doc.text(`Standard Deviation: ${tstdv || '0.00'}`, 75, 42);
            doc.text(`Credit Hours: ${cr_h || '0'}`, 145, 36);
          }

          page = '';
          page = doc.getNumberOfPages();
        }
      });
      doc.setFontSize(10);
      for (var i = 1; i <= page; i++) {
        doc.setPage(i);
        doc.text('Page ' + i + " of " + page, 110, 293, { align: 'center' });
        doc.text("Note:- Errors and Omissions Excepted", 145, 293);
        doc.text("Directorate of Information Technology", 10, 293);
      }

      doc.setProperties({
        title: `Awardlist-${sub_code}-${section}`,

      });
      window.open(URL.createObjectURL(doc.output('blob')), '_blank');
    }
  }

  ClearForm(f) {
    f.resetForm();
    this.subjecList.length = 0;
    this.classes = [];
    this.sessions = [];
    this.sections = [];
    this.awardlistForm.value.t_no = '';
  }



  loadGroups() {
    this.groupService.getGroups({ grp: this.group, role: this.isAdmin || this.isExamCell }).subscribe((res: { GRP: number, G_NM: string }[]) => {
      res?.forEach(element => {
        this.groups.push({ id: element.GRP, nm: element.G_NM });
      });
      if (!this.isAdmin) {
        this.updateClasses();
      }
    }, err => {
      console.log(err);
    });
  }

  updateClasses() {
    this.classes = [];
    this.sessions = [];
    this.sections = [];
    this.classService.getClasses({ grp: this.group }).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
    },
      err => {
        console.log(err);
      });
  }

  updateSessions() {
    this.sections = []
    this.sessions = []
    this.lmsSharedService.filterSessionByCode(this.awardlistForm.value.c_code)
      .then(session => {
        this.sessions = session
        if (this.awardlistForm) {
          const initialValue = this.sessions[0]?.id;
          this.awardlistForm?.controls['se_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }

  updateSections() {
    const { grp, c_code, se_id, t_no } = this.awardlistForm.value;

    if (!grp || !c_code || !se_id || !t_no) return;

    this.sectionsAndSub.clear();
    this.subjectNameList.clear();
    this.sections = [];
    this.subjecList = []
    this.awardListService.getOfferedSubAndSectionByGroup(grp, c_code, se_id, t_no).subscribe((res: { section: string, sub_code: string, sub_nm }[]) => {
      if (res) {
        res.forEach(x => {
          if (this.sectionsAndSub.has(x.sub_code))
            this.sectionsAndSub.get(x.sub_code).push(x.section);
          else {
            this.sectionsAndSub.set(x.sub_code, new Array<string>(x.section));
            this.subjectNameList.set(x.sub_code, x.sub_nm);
            this.subjecList.push(x.sub_code)
          }
        });


      }
    }, err => {
      console.log(err);
      this.toastr.error(err);
    });
  }

  Filter()
  {
   filter();
  }
}