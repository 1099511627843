import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectList } from '../shared/model/SelectList.model';
import { DepartmentService } from '../shared/services/Department.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { TeacherService } from '../shared/services/Teacher.service';
import { jsPDF } from 'jspdf';
import { DatePipe } from '@angular/common';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { AttendanceService } from '../attendance/attendance-services/attendance.service';
import { filter } from 'src/app/shared/functions/tableSearch';
interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF
}
interface checkTeacherAttendance {
  CLASS: string,
  SE: string;
  SESSION: string,
  SEMESTER: number,
  SECTION: string,
  SUB_CODE: string,
  SUB_NM: string,  
  T_NM: string,
  TOTAL_LEC: number,
  ASSIGNMENT: number,
  QUIZ: number,
  MID: number,
  FINAL: number,
}
export interface Student {
  attendanceStatus: string;
  ATTEND: string;
  SE: string;
  C_CODE: number;
  DA_DATE: string;
  MAJ_ID: number;
  RN: number;
  ROLNO: string;
  YEAR: number;
  D_ID: number;
  NM: string;
  SESSION: string;
  ATT_NO: string
}
export interface CheckAttendanceDate {
  attendanceStatus: string;
  ATTEND: string;
  SE: string;
  C_CODE: number;
  DA_DATE: string;
  MAJ_ID: number;
  RN: number;
  ROLNO: string;
  YEAR: number;
  D_ID: number;
  NM: string;  
  SESSION: string;
  ATT_NO: string
}
@Component({
  selector: 'app-check-teacher-attendance',
  templateUrl: './check-teacher-attendance.component.html',
  styleUrls: ['./check-teacher-attendance.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class CheckTeacherAttendanceComponent implements OnInit {
  private teacherName;
  public btnhide: boolean;
  departments: Array<SelectList>;
  currentyears: any[] = [];
  teachers: Array<SelectList>;
  studentsEmpty: Array<CheckAttendanceDate>;
  public data;
  isAdmin: boolean = false;
  public depName;
  public departmentID;
  public departmentNM;
  public teacherNIC: string;
  public teacherPH1: string;
  public teacherEMAIL: string;
  @ViewChild('f') formRef: NgForm;
  checkTeacherAttendance: Array<checkTeacherAttendance>
  dept: number;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  You can check and download all academic details of all the subjects that assigned to a teacher like
   the attendances,assesments,students details  and status of award list of the  department  of any year   </h6>
  <h6 class="text-right">آپ کسی بھی سال کے ڈیپارٹمنٹ میں کسی استاد کو تفویض کردہ تمام سبجیکٹس کی تمام تعلیمی تفصیلات، جیسے حاضری، اسیسمنٹس، طلباء کی تفصیلات،
   اور ایوارڈ لسٹ کا اسٹیٹس، چیک اور ڈاؤن لوڈ کر سکتے ہیں۔۔
</h6>
  `  ;
  constructor(private deptService: DepartmentService,
    private TeacherService: TeacherService,
    private teacherService: TeacherService,
    private toaster: ToastrService,
    private authenticationService: AuthenticationService,
    private datePipe: DatePipe,
    private attendanceService: AttendanceService
  ) {
    this.checkTeacherAttendance = new Array<checkTeacherAttendance>();
    this.teachers = new Array<SelectList>();
    this.departments = new Array<SelectList>();
    this.data = new Array();
    this.isAdmin = this.authenticationService.isAdmin();
    this.studentsEmpty = new Array<CheckAttendanceDate>();
  }

  ngOnInit(): void {
    this.btnhide = false;
      this.loadDepartments();
      this.loadCurrentYears();
      this.loader=false;
  }
  loader=false;
  OnSubmit(){

}
  OnClickAll() {
    
    if (!this.formRef.value.dpt) {
      this.toaster.warning("Empty Field");
      return;
    }
    this.loader=true;
    let param;
    if (this.isAdmin) {
      param = this.formRef.value.dpt;
    } else {
      param = this.departmentID;
    }
    this.btnhide = true;
    this.checkTeacherAttendance = [];
    this.data = [];

    // this.toaster.info("Loading ", '', { timeOut: 5000, closeButton: true });
    this.TeacherService.checkTeacherAttendance(param).subscribe((res      
      : {
        CLASS: string, SESSION: string, SEMESTER: number, SECTION: string, SUB_CODE: string, SUB_NM: string, T_NM: string,
        TOTAL_LEC: number, ASSIGNMENT: number, QUIZ: number, MID: number, FINAL: number,SE:string,
      }[]
    ) => {
   
      this.loader=false;
      res?.forEach(entry => {
        this.checkTeacherAttendance.push(entry);
      });
    }, err => {
      this.loader=false;
      this.toaster.error("Some Error Occured");
    })
  }

  loadCurrentYears(){
    this.currentyears = [];
    this.deptService.getCurrentYears().subscribe((res :any)=>{
      this.currentyears = res[0];
    });
  }

  loadDepartments() {
    this.deptService.getDepartments({ admin: true, filter: true }).subscribe((res: { D_ID: number, D_NM: string }[]) => {
      res?.forEach(entry => {
        this.departments.push({ id: entry.D_ID, nm: entry.D_NM });
      });
      if (this.isAdmin) {
        this.departmentNM = this.depName?.nm;
        this.departmentID = this.depName?.id;
      }
      else {
        this.departmentNM = this.departments.find(x => x.id === this.authenticationService.getUser().D_ID).nm;
        this.dept = this.authenticationService.getUser().D_ID;
        this.loadTeachers();
      }
    });
  }
  loadTeachers() {
    this.teachers = [];
    this.teacherService.getTmTeacher(this.dept).subscribe((res: { FM_ID: number, FM_NM }[]) => {
      res?.forEach(element => {
        this.teachers.push({ id: element.FM_ID, nm: element.FM_NM });
      });
    });
  }

  OnDeptChange(dpt) {
    this.departmentNM = this.departments[dpt.selectedIndex].nm;
    this.dept = parseInt(dpt.value);
    if (this.isAdmin) {
      this.loadTeachers();
    }
  }
  OnClick() {
    this.data = [];
    this.checkTeacherAttendance=[];
    if(!this.formRef.value.fm_id)
    {
      this.toaster.warning("Please Select Teacher");
      return;
    }
    if(!this.formRef.value.c_year)
    {
      this.toaster.warning("Please Select Year");
      return;
    }
    this.loader=true;
    this.teacherService.getTeacherTimeTavle({dpt:this.dept,fm_id:this.formRef.value.fm_id, c_year: this.formRef.value.c_year}).subscribe((res: {GRP:number,G_NAME:string, C_CODE: number, CLASS: string, SE_ID: number, SE_NM: string, T_NO: number, SECTION: string, SUB_CODE: string, SUB_NM: string, STATUS: number, NIC: string, PH1: string, EMAIL: string }[]) => {
      res?.forEach(element => {
        this.data.push(element);
      });
      this.loader=false;
    });

  }
  OnAttendance(f: any) {
    let attendance: any = [];
    let totalattendance: any;

    let params = {GRP:f.GRP, C_CODE: f.C_CODE, SE_ID: f.SE_ID, T_NO: f.T_NO, SUB_CODE: f.SUB_CODE, SECTION: f.SECTION };
    this.teacherService.getAttendanceReportReport(params).subscribe((res) => {
      res[0]?.forEach(element => {
        totalattendance = element.DA_DATE
      });
      res[1]?.forEach(element => {
        attendance.push(element);
      });
      this.Attendance(totalattendance, attendance, f);
      // this.Assessment(totalassessment, assessment);
    });
  }
  OnAssessment(f) {
    let assessment: any = [];
    let totalassessment: any;

    let params = {GRP:f.GRP, C_CODE: f.C_CODE, SE_ID: f.SE_ID, T_NO: f.T_NO, SUB_CODE: f.SUB_CODE, SECTION: f.SECTION };
    this.teacherService.getAssessmentReportReport(params).subscribe((res) => {
      res[0]?.forEach(element => {
        totalassessment = element.ASSIGNMENT_TITLE
      });
      res[1]?.forEach(element => {
        assessment.push(element);
      });
      this.Assessment(totalassessment, assessment, f);
    });
  }

  getEmtpyStdAttendance(f,index:number){
      const body = [];
      let k = 1; 
      this.attendanceService.getAttendenceEmpty({GRP:f.GRP, C_CODE:f.C_CODE, SE_ID:f.SE_ID, T_NO:f.T_NO, SECTION:f.SECTION,  SUB_CODE:f.SUB_CODE}).subscribe(
        (res: [Student[], { SESSION: string }]) => {
          this.studentsEmpty = [];
          if (res[0]) {
            res[0]?.forEach(entry => {
              this.studentsEmpty.push(entry);
            })
          }
          let session;
          if (res[1]) {
            session = res[1][0].SESSION;
          }
          if (!this.studentsEmpty.length) {
            this.toaster.warning('No Student Enrolled');
          }
          if (this.studentsEmpty.length) {
            for (let i = 0; i < this.studentsEmpty.length; i++) {
              body.push([k,
                this.studentsEmpty[i].ROLNO.toUpperCase(),
                this.studentsEmpty[i].NM.toUpperCase()
              ]);
              k++;
            }
          }
          const header = [];
          header.push(
            [
              { content: '', colSpan: 1, styles: { halign: 'center', fillColor: [18, 0, 201] } },
              { content: '', colSpan: 1, styles: { halign: 'center', fillColor: [18, 0, 201] } },
              { content: '', colSpan: 1, styles: { halign: 'center', fillColor: [18, 0, 201] } },
        { content: 'Dates', colSpan: 15, styles: { halign: 'center', fillColor: [18, 0, 201] } },
              { content: '', colSpan: 1, styles: { halign: 'center', fillColor: [18, 0, 201] } },
              { content: '', colSpan: 1, styles: { halign: 'center', fillColor: [18, 0, 201] } }
            ], ["Sr.", "Roll No", "Name", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "Attend", "Absent"]
          );
          var exportDate = this.datePipe.transform(new Date(), 'MMM d,y')
          var image = new Image();
          var page;
          var cls = this.data[index].CLASS;
          var sub_code = this.data[index].SUB_CODE;
          var sub_nm = this.data[index].SUB_NM;
          var t_no = this.data[index].T_NO;
          var sec = this.data[index].SECTION;
  
          let fm_nm=this.teacherName;
          image.src = '../../../assets/images/logo3.png';
          const doc = new jsPDF('l', 'pt', "legal");
          doc.setFontSize(10);
  
          doc.setDrawColor(256, 256, 256);
          autoTable(doc, {
            margin: { top: 70, bottom: 20 },
            styles: {
              fontSize: 9,
              lineWidth: 0.5
            },
            columnStyles: {
              0: { cellWidth: 25 },
              1: { cellWidth: 120 },
              2: { cellWidth: 140 },
              18: { cellWidth: 40 },
              19: { cellWidth: 40 }
            },
            head: header,
            body: body,
            tableLineWidth: 1,
            didDrawPage: function () {
              doc.addImage(image, 160, 1, 55, 55);
              doc.setFontSize(10);
              doc.text(`${exportDate}`, 160, 65);
              doc.setFontSize(16);
              doc.setFont('Arial');
              doc.text("Attendance Sheet", 20, 20);
              doc.text("______________", 20, 17);
              doc.text("GC UNIVERSITY LAHORE", 20, 40);
              doc.text("  Lahore", 38, 55);
              doc.setFontSize(11);
              doc.text("Session: ( " + session + " )", 270, 20);
  
              doc.text("Month/Year:________________", 270, 40);
              doc.text("Course Code: " + sub_code, 270, 60);
              doc.text("Class: " + cls, 480, 20);
              doc.text("Semester: " + t_no, 480, 40);
              doc.text("Paper: " + sub_nm, 480, 60);
              doc.text("Section: " + sec, 600, 40);
              doc.setFontSize(12);
              doc.text(" Total Delivered Lectures : ", 750, 30);
              doc.text("____________________________", 750, 35);
              doc.text("____________________________", 750, 10);
              doc.text("|", 749, 35);
              doc.text("|", 749, 21);
              doc.text("|", 749, 26);
              doc.text("|", 917, 21);
              doc.text("|", 917, 35);
              doc.text("|", 917, 26);
              doc.setFontSize(11);
              doc.text("Teacher's Name: " + fm_nm, 750, 60);
              doc.setFontSize(20);
              doc.setFontSize(10);
              page = doc.getNumberOfPages();
              doc.text("Note:- Errors and Omissions Excepted", 815, 600);
              doc.text("Directorate of Information Technology", 38, 600);
            }
          });
          doc.setFontSize(10);
          for (var i = 1; i <= page; i++) {
            doc.setPage(i);
            doc.text('Page ' + i + " of " + page, 480, 600);
          }
          // doc.save();
          // doc.autoPrint();
          // let string = doc.output('datauristring');
          // let embed = "<embed width='100%' height='100%' src='" + string + "'/>"
          // let x = window.open();
          // x.document.open();
          // x.document.write(embed);
          // x.document.close();
          doc.setProperties({
            title: `Subject Offer-${sub_code||""}-${sec||''}-${t_no||""}`,
            
          });
          window.open(URL.createObjectURL(doc.output('blob')), '_blank');
        }, err => {
          console.log(err);
        });
  }

  PDFCreate() {
    if (this.checkTeacherAttendance.length == 0) {
      this.toaster.error('None');
      return;
    }
    const body = [];
    const header = [];
    let k = 0;
    for (let i = 0; i < this.checkTeacherAttendance.length; i++) {
      k++;
      body.push([k,
        (this.checkTeacherAttendance[i].T_NM === null) ? "Not Assign" : this.checkTeacherAttendance[i].T_NM,        
        this.checkTeacherAttendance[i].SE,
        this.checkTeacherAttendance[i].SEMESTER,
        this.checkTeacherAttendance[i].SUB_CODE,
        this.checkTeacherAttendance[i].SECTION,
        this.checkTeacherAttendance[i].CLASS,
        (this.checkTeacherAttendance[i].TOTAL_LEC === null) ? "0" : this.checkTeacherAttendance[i].TOTAL_LEC,
        this.checkTeacherAttendance[i].ASSIGNMENT,
        this.checkTeacherAttendance[i].QUIZ,
        (this.checkTeacherAttendance[i].MID === 0) ? "Not Conducted" : "Conducted",
        (this.checkTeacherAttendance[i].FINAL === 0) ? "Not Conducted" : "Conducted",
      ]);
    }
    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y')
    var image = new Image();
    var deptt = this.departmentNM;
    var page;
    image.src = '../../../assets/images/logo3.png';
    header.push(['Sr.', 'Teacher Name','Session','Term', 'Subject', 'Section', 'Class', 'Attendance', 'Assignments', 'Quizs', 'Mid', 'Final']);
    const doc = new jsPDF('l', 'pt', "a4");
    autoTable(doc, {
      margin: { top: 70, bottom: 20 },
      head: header,
      body: body,
      didDrawPage: function () {
        doc.addImage(image, 400, 1, 55, 55);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 400, 65);
        doc.setFontSize(17);
        doc.setFont('Arial');
        doc.text("GC UNIVERSITY LAHORE", 38, 30);
        doc.text("DEPARTMENT OF " + deptt, 38, 50);
        doc.text("GENERAL REPORT TEACHER WISE", 525, 40);
        doc.setFontSize(10);
        doc.text("Note:- Errors and Omissions Excepted", 650, 580);
        page = doc.getNumberOfPages();
        doc.text("Directorate of Information Technology", 38, 580);
      }
    });

    var l = (body.length + 65) % 26;
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 400, 580);
      var p = body.length;
    }
    doc.setProperties({
      title: `Teacher Report-${deptt}`,
      
    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }
  OnTeacherChange(t) {
    this.teacherName = this.teachers[t.selectedIndex].nm;
    this.data = [];
  }

  OnYearChange(){
    this.data = [];
  }
  Assessment(totalassessment, assessment, f) {
    let quiz=[];
    let Assignment=[];
    let Presentation=[];
    let MidTerm=[];
    let FinalTerm=[];
    let Project=[];
    let Lab=[];
    

    assessment.forEach(element => {
      if(element.ASSIGNMENT_TYPE==='Quiz')
        quiz.push(element);
      else if (element.ASSIGNMENT_TYPE==='Assignment') 
        Assignment.push(element); 
      else if (element.ASSIGNMENT_TYPE==='Presentation') 
      Presentation.push(element); 
      else if (element.ASSIGNMENT_TYPE==='Mid Term') 
      MidTerm.push(element);
      else if (element.ASSIGNMENT_TYPE==='Final Term') 
      FinalTerm.push(element);
      else if (element.ASSIGNMENT_TYPE==='Project') 
      Project.push(element);
      else if (element.ASSIGNMENT_TYPE==='Lab') 
      Lab.push(element);
    });

    let session = f.SE_NM;
    let subject = f.SUB_CODE;
    let sub_nm = f.SUB_NM;
    let section = f.SECTION;
    let t_no = f.T_NO;
    let T_NM = this.teacherName;
    
    const body = [];
    const header = [];
    let k = 0;
    for (let i = 0; i < assessment.length; i++) {
      k++;
      body.push([k,
        assessment[i].ASSIGNMENT_TYPE,
        assessment[i].ASSIGNMENT_TITLE,
        assessment[i].SUBMIT_TYPE + " Form",
        this.datePipe.transform(assessment[i].DT, 'MMM d,y, h:mm a'),
      ]);
    }
    let exportDate = this.datePipe.transform(new Date(), 'MMM d,y, h:mm a')
    let image = new Image();
    let deptt = this.departmentNM;
    let page;
    image.src = '../../../assets/images/logo3.png';
    header.push(
      // [
      //   { content:'Quiz', colSpan: 5,styles: {halign: 'left',textColor: [0, 0, 0], fontSize: 14,lineColor:[0,0,0], fillColor: [256, 256, 256] } }
      // ],
      ['Sr.', 'Assessment Type', 'Assessment Title', 'Submit Type', 'Assessment Date'] 
    );
    const doc = new jsPDF('p', 'mm', "a4");
    autoTable(doc, {
      margin: { top: 55, bottom: 20 },
      head: header,
      body: body,
      didDrawPage: function () {
        doc.addImage(image, 170, 1, 30, 30);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 167, 35);
        doc.setFontSize(19);
        doc.setFont('Arial');
        doc.text("GC UNIVERSITY LAHORE", 100, 10, { align: 'center' });
        doc.setFontSize(15);
        doc.text("DEPARTMENT OF " + deptt, 100, 17, { align: 'center' });
        doc.text("SESSION: " + session + "    SEMESTER: " + t_no, 100, 24, { align: 'center' });
        doc.text("SECTION: " + section, 100, 31, { align: 'center' });
        doc.text("TEACHER NAME : " + T_NM, 100, 38, { align: 'center' });
        doc.text("TOTAL ASSESSMENTS CREATED: " + totalassessment, 100, 45, { align: 'center' });
        doc.text("SUBJECT: " + sub_nm + " ( " + subject + " )", 100, 52, { align: 'center' });
        doc.setFontSize(12);
        doc.text("Note:- Errors and Omissions Excepted", 5, 285);
        page = doc.getNumberOfPages();
        doc.setFont('Arial');
        doc.text("Directorate of Information Technology", 140, 285);
      }
    });
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 100, 285);
    }
    doc.setProperties({
      title: `Subject Offer-${subject||""}-${section||''}-${T_NM||""}`,
      
    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }
  Attendance(totalattendance, attendance, f) {
    let session = f.SE_NM;
    let subject = f.SUB_CODE;
    let sub_nm = f.SUB_NM;
    let section = f.SECTION;
    let t_no = f.T_NO;
    let T_NM = this.teacherName;
    const body = [];
    const header = [];
    let k = 0;
    for (let i = 0; i < attendance.length; i++) {
      k++;
      body.push([k,
        this.datePipe.transform(attendance[i].DA_DATE, 'MMM d,y'),
        // attendance[i].DELIVERED,
        attendance[i].PRESENT,
        attendance[i].ABSENT,
        // attendance[i].LEAV,
        // (((attendance[i].PRESENT) / attendance[i].DELIVERED) * 100).toFixed(2) + "%"
      ]);
    }
    let exportDate = this.datePipe.transform(new Date(), 'MMM d,y, h:mm a')
    let image = new Image();
    let deptt = this.departmentNM;
    let page;
    image.src = '../../../assets/images/logo3.png';
    header.push(['Sr.', 'Marked Attendance Dates', 'Present Students Count', 'Absent Students Count']);
    const doc = new jsPDF('p', 'mm', "a4");
    autoTable(doc, {
      margin: { top: 55, bottom: 20 },
      head: header,
      body: body,
      didDrawPage: function () {
        doc.addImage(image, 170, 1, 30, 30);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 167, 35);
        doc.setFontSize(19);
        doc.setFont('Arial');
        doc.text("GC UNIVERSITY LAHORE", 100, 10, { align: 'center' });
        doc.setFontSize(15);
        doc.text("DEPARTMENT OF " + deptt, 100, 17, { align: 'center' });
        doc.text("SESSION: " + session + "    SEMESTER: " + t_no, 100, 24, { align: 'center' });
        doc.text("SECTION: " + section, 100, 31, { align: 'center' });
        doc.text("TEACHER NAME : " + T_NM, 100, 38, { align: 'center' });

        doc.text("TOTAL ATTENDANCE CREATED: " + totalattendance, 100, 45, { align: 'center' });
        doc.text("SUBJECT: " + sub_nm + " ( " + subject + " )", 100, 52, { align: 'center' });

        doc.setFontSize(12);
        doc.text("Note:- Errors and Omissions Excepted", 5, 285);
        page = doc.getNumberOfPages();
        doc.setFont('Arial');
        doc.text("Directorate of Information Technology", 140, 285);
      }
    });
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 100, 285);
    }
  doc.setProperties({
    title: `Subject Offer-${subject||""}-${section||''}-${T_NM||""}`,
    
  });
  window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }


  CreatePDF(){
    var year = this.formRef.value.c_year;

    if(year === "0"){
      year = "All Current Years";
    }
    let T_NM = this.teacherName;
    const body = [];
    const header = [];
    let k = 0;
    for (let i = 0; i < this.data.length; i++) {
      k++;
      body.push([k,
        this.data[i].G_NAME,
        this.data[i].CLASS,
        this.data[i].SE_NM,
        this.data[i].T_NO,
        this.data[i].SECTION,
        this.data[i].SUB_CODE,
        this.data[i].SUB_NM,
        this.data[i].CREDIT_HOUR
      ]);
    }
    var exportDate = this.datePipe.transform(new Date(), 'MMM d, y')
    let image = new Image();
    let deptt = this.departmentNM;
    let page;
    image.src = '../../../assets/images/logo3.png';
    header.push(['Sr.', 'Group', 'Class', 'Session', 'Semester', 'Section', 'Subject Code', 'Subject Title', 'Credit Hr.']);
    const doc = new jsPDF('p', 'mm', "a4");
    autoTable(doc, {
      margin: { top: 37, bottom: 20 },
      head: header,
      body: body,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0], // sets the text color of the header to black
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontSize: 9.5,
        fontStyle: 'bold',
        halign: 'left'
      },
      bodyStyles: {
        lineWidth: 0.1, // sets the width of the border to 0.2
        lineColor: [0, 0, 0], // sets the color of the border to black
        fontSize: 9.2,
      },
      columnStyles: {
        0: { halign: "center" },
        1: { halign: "center" },
        2: { halign: "center" },
        3: { halign: "center" },
        4: { halign: "left" },
        5: { halign: "left" },
        6: { halign: "center" },
        7: { halign: "center" },
        8: { halign: "left" },
      },
      didDrawPage: function () {
        doc.addImage(image, 177, 3, 25, 30);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 180, 36);
        doc.setFontSize(19);
        doc.setFont('Arial');
        doc.text("GC UNIVERSITY LAHORE", 100, 10, { align: 'center' });
        doc.setFontSize(15);
        doc.text("DEPARTMENT OF " + deptt, 100, 17, { align: 'center' });
        doc.text("TEACHER NAME : " + T_NM, 100, 24, { align: 'center' });
        doc.text("Year : " + year, 100, 30, { align: 'center' });

        doc.setFontSize(12);
        doc.text("Note:- Errors and Omissions Excepted", 5, 285);
        page = doc.getNumberOfPages();
        doc.setFont('Arial');
        doc.text("Directorate of Information Technology", 140, 285);
      }
    });
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 100, 285);
    }
  window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }

  Filter()
  {
    filter();
  }
}
