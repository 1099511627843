import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class ShiftingService {

    constructor(private http: HttpClient) {
    }

    getTeams(param) {
        return this.http.post(`${baseUrl}/api/shifting/getTeams`, param);
    }

    setStatus(param){
        return this.http.post(`${baseUrl}/api/shifting/setStatus`, param);
    }

    getAwardList(param){
        return this.http.post(`${baseUrl}/awardList/U_getAwardList`, param);
    }    
}