import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { SlideInFromLeft } from "src/app/transitions";
import { ProgressServices } from "./progress-services";
import { ToastrService } from "ngx-toastr";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import autoTable, { UserOptions } from "jspdf-autotable";
import * as moment from "moment";
import { SelectList, SelectList4 } from "../shared/model/SelectList.model";
import * as JSZip from "jszip";
import * as fileSaver from "file-saver";
import {arialblack,font,oldeng} from "src/font"
import { PDFDocument } from "pdf-lib";
import { LMSharedDataService } from "src/app/shared/LMSSharedService";

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

export interface subjectInformation {
  atmp_no: number;
  // Father_name: string;
  GP_PER: number;
  // Name: string;
  Roll_number: string;
  SUB_CODE: string;
  c_class: string;
  grd: string;
  // reg_no: null
  req_cr_hr: 3;
  // session: string;
  subject_name: string;
  t_no: number;
  orig_tno;
}
export interface studentNamesInformation {
  DES: string;
  F_NM: string;
  NM: string;
  REG_NO: string;
  ROLNO: string;
  SE_ID: string;
  rolnum: string;
  dur:number,
  isCompleted:boolean
}
export interface studentCgpaInformation {
  ROLNO: string;
  // cr_h: number,
  // earn: number,
  gp: number;
  sgpa: number;
  t_no: number;
  cgpa: number;
  mrks: number;
  t_mrks: number;
}
@Component({
  selector: "app-progress-report",
  templateUrl: "./progress-report.component.html",
  styleUrls: ["./progress-report.component.css"],
  animations: [SlideInFromLeft()]
})

export class ProgressReportComponent implements OnInit {
  classes: Array<SelectList4>;
  sessions: Array<SelectList>;
  majors: Array<SelectList>;
  f: any;
  repeat: Map<string, Map<string, number>>;
  improve: Map<string, Map<number, subjectInformation[]>>;
  transcript: Map<string, Map<number, subjectInformation[]>>;
  stdNamesInfo: Map<string, studentNamesInformation[]>;
  stdCrh: Map<string, number[]>;
  stdCgpaInfo: Map<string, studentCgpaInformation[]>;
  public today: any = new Date();
  endRnValue = 0;
  pdfDoc:any;
  @ViewChild("f") formRef: NgForm;

  constructor(
    private proSer: ProgressServices,
    private toaster: ToastrService,
    private lmsSharedService: LMSharedDataService
  ) { 
    this.classes = [];
    this.majors = [];
    this.sessions = [];
    this.repeat = new Map<string, Map<string, number>>();
    this.improve = new Map<string, Map<number, subjectInformation[]>>();
    this.transcript = new Map<string, Map<number, subjectInformation[]>>();
    this.stdNamesInfo = new Map<string, studentNamesInformation[]>();
    this.stdCgpaInfo = new Map<string, studentCgpaInformation[]>();
    this.stdCrh= new Map<string, number[]>();
    this.today = moment(this.today).format("DD-MMM-YYYY");
     this.pdfDoc = PDFDocument.create();
  }
  ngOnInit(): void {
    this.updateClasses();
  }

  changeStartRn() {
    this.endRnValue = parseInt(this.formRef.value.start_rn) + 5;
  }

  OnSubmit() {
    this.transcript.clear();
    this.stdCgpaInfo.clear();
    this.stdNamesInfo.clear();
    this.improve.clear();
    this.repeat.clear();
    this.stdCrh.clear();
    this.toaster.info("Creating Progress Reports");
    this.proSer
      .getExamRslt(
        this.formRef.value?.c_code,
        this.formRef.value?.se_id,
        this.formRef.value?.start_rn,
        this.formRef.value?.end_rn,
        this.formRef.value?.t_no,
        this.formRef.value?.maj_id
      )
      .subscribe(
        (
          res: [
            studentNamesInformation[],
            subjectInformation[],
            studentCgpaInformation[],
            {Roll_number:string,orig_tno:number,req_cr_hr:number}[]
          ]
        ) => {
          res[0]?.forEach((entry) => {
            if (this.stdNamesInfo.has(entry.rolnum)) {
              this.stdNamesInfo.get(entry.rolnum).push(entry);
            } else {
              this.stdNamesInfo.set(
                entry.rolnum,
                new Array<studentNamesInformation>(entry)
              );
            }
          });
      res[3]?.forEach((entry) => {
        if(!(this.stdCrh.has(entry.Roll_number)))
      this.stdCrh.set(entry.Roll_number,new Array<number>());
      this.stdCrh.get(entry.Roll_number)[entry.orig_tno-1]=entry.req_cr_hr;
      });
          res[1]?.forEach((entry) => {
      if (this.repeat.has(entry.Roll_number)) {
          
            if (
              this.repeat.get(entry.Roll_number).has(entry.SUB_CODE) ||
                (entry.atmp_no > 1)
              ) {
                if (this.improve.has(entry.Roll_number)) {
                  if (this.improve.get(entry.Roll_number).has(entry.orig_tno)) {
                    this.improve.get(entry.Roll_number).get(entry.orig_tno).push(entry);
                  } else {
                    this.improve.get(entry.Roll_number).set(entry.orig_tno,new Array<subjectInformation>(entry));
                  }
                } else {
                  this.improve.set(
                    entry.Roll_number,
                    new Map<number, subjectInformation[]>()
                  );
                  this.improve
                    .get(entry.Roll_number)
                    .set(entry.orig_tno, new Array<subjectInformation>(entry));
                }
              } else {
                this.repeat
                  .get(entry.Roll_number)
                  .set(entry.SUB_CODE, entry.orig_tno);
                if (this.transcript?.get(entry.Roll_number)?.has(entry.orig_tno)) {
                  this.transcript
                    .get(entry.Roll_number)
                    .get(entry.orig_tno)
                    .push(entry);
                } else {
                  this.transcript
                    .get(entry.Roll_number)
                    .set(entry.orig_tno, new Array<subjectInformation>(entry));
                }
              }
            } else {
              this.repeat.set(entry.Roll_number,new Map<string,number>());
              this.repeat.get(entry.Roll_number).set(entry.SUB_CODE,entry.orig_tno);
              this.transcript.set(
                entry.Roll_number,
                new Map<number, subjectInformation[]>()
              );
              this.transcript
                .get(entry.Roll_number)
                .set(entry.orig_tno, new Array<subjectInformation>(entry));
            }
          });

          res[2]?.forEach((entry) => {
            if (!(this.stdCgpaInfo.has(entry.ROLNO))) {
              this.stdCgpaInfo.set(
                entry.ROLNO,
                new Array<studentCgpaInformation>()
              );
            } 
            this.stdCgpaInfo.get(entry.ROLNO)[entry.t_no-1]=entry;
          });
          try {
           this.downloadAll();
          } catch (err) {
            this.toaster.error("data inconsistent");
          }
        }
      );
  }

  getLvlId(arr: Array<SelectList4>, key: number): number {
    let result:number;
    arr?.forEach((element) => {
      if (element.id == key) {
        result = element.alt_id;
      }
    });
    return result;
  }

  func1() {
    // var bufferArr:ArrayBuffer[]=[];
    for (const i of this.stdNamesInfo) {
     this.OnCreatePDF(i, this.improve.get(i[0]),this.stdCrh.get(i[0]));
    }
    // const pdfBytes =  this.pdfDoc.save();
    // this.merger(bufferArr);
  }

  // async merger(bufferArr:ArrayBuffer){
  //   // bufferArr?.forEach((value)=>{
  //     const firstDoc = await PDFDocument.load(bufferArr);
  //     const firstPage = this.pdfDoc.copyPages(firstDoc, firstDoc.getPageIndices());
  //     firstPage.forEach((page) => this.pdfDoc.addPage(page));
  //   // }
  //   // );
  //   // const pdfBytes = await this.pdfDoc.save();
  //   // fileSaver.saveAs(
  //   //   new Blob([pdfBytes], { type: "application/pdf" }),
  //   //   "StudentsTranscripts"
  //   // );
  //   return this.pdfDoc;
    
  // }

  async mergePdfs(pages: ArrayBuffer[]) {
    const mergedPdf = await PDFDocument.create();
    mergedPdf.setTitle('Progress Report',{showInWindowTitleBar:true})
    const actions = pages.map(async (pdfBuffer) => {
      const pdf = await PDFDocument.load(pdfBuffer);
      const copiedPages = await mergedPdf.copyPages(pdf,pdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    });
    await Promise.all(actions);
    mergedPdf.setTitle('Progress Report');
    const pdfBytes = await mergedPdf.save();
    let file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL,'_blank');

    // return pdfBytes;
  }

 async downloadAll() {
    const buffers = [];
    //  const actions =
    for (const i of this.stdNamesInfo) {
       const  buf=await this.OnCreatePDF(i, this.improve.get(i[0]),this.stdCrh.get(i[0]));
        if(buf!=null)
        buffers.push(buf);
      }
    if (buffers.length <= 0) {
      this.toaster.warning("No Any Data To Export");
      return;
    } else {
      this.toaster.success(
        `Data of ${buffers.length} Student/s Exported.`
      );
      this.mergePdfs(buffers);
    }

  }

  func() {
    var c = this.f;
    var zip = new JSZip();
    for (const i of this.stdNamesInfo) {
      var doc = this.OnCreatePDF(i, this.improve.get(i[0]),this.stdCrh.get(i[0]));
      if (typeof doc !== "undefined" || doc !== null) {
        try {
          zip.file(i[1][0].ROLNO + ".pdf", doc);
        } catch (err) {
          this.toaster.error("Something went wrong!");
        }
      } else {
        this.toaster.error("Data does not exists for " + i[1][0].ROLNO);
      }
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
      fileSaver.saveAs(content, c.MAJTITLE + " " + c.SE_ID + ".zip");
    });
  }

  private async OnCreatePDF(num, improve,crhArr:number[]) {
    let lvlBool=(this.getLvlId(this.classes,this.formRef.value?.c_code))>4;
    // private OnCreatePDF(num, improve) { Monotype Old English Text W01';
    const roman=['I','II','III','IV','V','VI','VII','VIII'];
    let pageNumber=1;
    var data: any;
    data = "";
    data = num[1][0];
    if (data == null || data == undefined || data == "") return null;
    var body = [];
    var body1 = [];
    const header = [];
    var se = data.SE_ID;
    // var stNm = this.initCap(data.NM);
    var stNm = data.NM;
    // var f_nm = this.initCap(data.F_NM);
    var f_nm = data.F_NM;
    let duration=data.dur;
    var stNm = data.NM;
    var rlNo = data.rolnum;
    var rolNo = data.ROLNO;
    var rgNo = "";
    rgNo = data.REG_NO;
    var page = 0;
    var majTitle = data.MAJTITLE;
    var totalCrH = 0.0;
    var totalGradePoint = 0.0;
    var dt = this.today;
    let c_Codee=this.formRef?.value?.c_code;
    var sub: Map<number,subjectInformation[]>;
    if (this.transcript?.size == 0 || this.transcript == undefined) return null;
    sub = this.transcript.get(rlNo);
    if (sub?.size == 0 || sub == undefined) return null;
    
    let isComp="incomplete";
    if(sub?.size==(duration*2) && data.isCompleted){
      isComp="complete"; 
    }
    if (this.stdCgpaInfo?.size == 0 || this.stdCgpaInfo == undefined) return null;
    var cgpa: studentCgpaInformation[] = this.stdCgpaInfo.get(rlNo);
    if (cgpa?.length == 0 || cgpa == undefined) return null;
    header.push(["Course Code", "Title", "Cr.Hr.", "Grd.pts.", "Grade"]);
    let y = 38;
    let z=5;
    const doc = new jsPDF("p", "mm", [215.9,329.4]);
    // doc.addFileToVFS('Old English Wd Bold Bold-bold.ttf', font);
    // doc.addFont('Old English Wd Bold Bold-bold.ttf', 'Old English', 'bold');
    doc.addFileToVFS('OLDENGL.ttf', oldeng);
    doc.addFont('OLDENGL.ttf', 'OLDENGL', 'bold');
    // let image = new Image();
    // image.src = '../../../assets/gcu_name.png';
    doc.addFileToVFS('FontsFree-Net-ariblk-normal.ttf', arialblack);
    doc.addFont('FontsFree-Net-ariblk-normal.ttf', 'Arial', 'black');
    doc.addFileToVFS('Arialn-normal.ttf', font);
    doc.addFont('Arialn-normal.ttf', 'Arial', 'normal');
    doc.setFontSize(24);
    doc.setFont( 'OLDENGL', "bold");
    // doc.setFont("times", "bold");
    doc.text("GC University Lahore", 102, 8+z, { align: 'center' });
        // doc.addImage(image, 54, 7 ,90,7 );
    doc.setFontSize(14);
    // doc.setFont("helvetica", "bold");
    doc.setFont("Arial", "black");
    
    doc.text("" + majTitle, 102, 13+z, { align: 'center' });
    doc.text("" + se, 102, 19+z, { align: 'center' });
    doc.setFont("courier", "bold");
    doc.text(" PROGRESS REPORT", 102, 26+z, { align: 'center' });
    // doc.setFont("times", "normal");
    doc.setFontSize(8);
    doc.setFont("Arial", "black");
    doc.text("Name: ", 23, 31+z);
    doc.setFont("Arial", "normal");
    doc.text("" + stNm, 47, 31+z);
    doc.setFont("Arial", "black");
    doc.text("Father Name: ", 23, 36+z);
    doc.setFont("Arial", "normal");
    doc.text("" + f_nm, 47, 36+z);
    doc.setFont("Arial", "black");
    doc.text("Roll Number: ", 135, 31+z);
    doc.setFont("Arial", "normal");
    doc.text("" + rolNo, 162, 31+z);
    doc.setFont("Arial", "black");
    doc.text("Registration No: ", 135, 36+z);
    doc.setFont("Arial", "normal");
    if (rgNo == null || rgNo == "") {
      rgNo = "";
    }
    doc.text("" + rgNo, 162, 36+z);
    for (let i = 1; i <= sub.size; i++) {
      var flag;
      if (improve != null) {
        flag = improve.get(i);
      }
      var arr = sub.get(i);
      var cr_h;
      var earn;
      var sgpa = cgpa[i - 1]?.sgpa?.toFixed(2);
      var gp = cgpa[i - 1]?.gp;
      var ind=3;
      totalGradePoint += gp;
      totalGradePoint = Math.round(totalGradePoint * 100) / 100;
      for (let j = 0; j < arr?.length; j++) {
        body.push([
          arr[j].SUB_CODE?.toUpperCase(),
          arr[j].subject_name,
          arr[j].req_cr_hr?.toFixed(2),
          arr[j].GP_PER?.toFixed(2),
          arr[j].grd,
        ]);
      }
      cr_h=crhArr[i-1];
      earn=crhArr[i-1];
      totalCrH += cr_h;
      autoTable(doc, {
        startY: y + 10,
        styles: {
          fontSize: 8,
          cellPadding: 0.4,
          font:'helvetica'
        },
        theme: "plain",
        margin: {left: 18 },
        columnStyles: {
          0: { minCellWidth: 35},
          1: { minCellWidth: 100},
          2: { minCellWidth: 15,halign:'justify' },
          3: { minCellWidth: 15,halign:'justify' },
          4: { minCellWidth: 15,halign:'justify'},
        },
        head: header,
        tableWidth:180,
        body: body,
        didDrawPage: function (d) {
          doc.setFontSize(8);
          doc.setFont("Arial", "black");
          if(lvlBool && i==((duration*2)-1) && (c_Codee==7 || c_Codee==56 || c_Codee==14 || c_Codee==15 || c_Codee==50 || c_Codee==9 || c_Codee==57) ){
            
            doc.text("Semester : "+roman[i-1]+" & "+roman[i], 15, y + 8);
            isComp="complete"; 
          }
       
           else if(lvlBool && i==((duration*2)-1) && (c_Codee==10  || c_Codee==12 || c_Codee==16 || c_Codee==17 || c_Codee==18 || c_Codee==53 || c_Codee==54 || c_Codee==77)){
            doc.text("Semester : "+roman[i-1], 15, y + 8);
           }
           else if(lvlBool && i==((duration*2)-1) && (c_Codee==28 )){
          
            doc.text("Semester : "+roman[i+1], 15, y + 8);
           }
          // if(lvlBool && i==((duration*2)-1))
          // doc.text("Semester : "+roman[i-1], 15, y + 8);
          // doc.text("Semester : "+roman[i-1]+" & "+roman[i], 15, y + 8);
          // doc.text("Semester : "+roman[i-1]+" & "+roman[i], 15, y + 8);
          //doc.text("Semester : "+roman[i-1], 15, y + 8);
          else

             if(c_Codee==28)
             {
                doc.text("Semester : " + roman[i+1], 15, y + 8);
                isComp="complete"; 
             }
             else  {
              doc.text("Semester : " + roman[i-1], 15, y + 8);
           }
      
       
           
          doc.setFontSize(8);
          page = doc.getNumberOfPages();
          y = d.cursor.y;
          doc.setFont("Arial", "black");
          if(!flag){
            doc.text(
`Cr.Hr. Earned =      ${earn || 0}            Cr.Hr. for GPA =      ${cr_h || 0}       Grade Points =        ${gp || 0.0}              SGPA =      ${sgpa || 0.00}`,
              21,
              y + 3
            );
          }
        },
      });
      if (flag) {
        doc.setFontSize(8);
        doc.setFont("Arial", "black");
        doc.text("Repeated/Improved", 15, y + 3);
        for (var j = 0; j < flag.length; j++) {
          let arr:subjectInformation[] = flag;
          body1.push([
            arr[j].SUB_CODE.toUpperCase(),
            arr[j].subject_name,
            arr[j].req_cr_hr?.toFixed(2),
            arr[j].GP_PER?.toFixed(2),
            arr[j].grd,
          ]);
          // if (earn != cr_h) earn += arr[j].req_cr_hr;
        }
        y = y + 4;
        autoTable(doc, {
          startY: y,
          head: header,
          body: body1,
          styles: {
            fontSize: 8,
            cellPadding: 0,
          },
          theme: "plain",
          tableWidth:180,
          margin: { left: 18 },
          columnStyles: {
            0: { minCellWidth: 35 },
            1: { minCellWidth: 100 },
            2: { minCellWidth: 15, minCellHeight: 0,halign:'justify' },
            3: { minCellWidth: 15, minCellHeight: 0 ,halign:'justify'},
            4: { minCellWidth: 15, minCellHeight: 0 ,halign:'justify'},
          },
          didDrawPage: function (d) {
            y = d.cursor.y;
            doc.text(
              `Cr.Hr. Earned =      ${earn || 0}            Cr.Hr. for GPA =      ${cr_h || 0}       Grade Points =        ${gp?.toFixed(2) || 0.0}              SGPA =      ${sgpa || 0.00}`,
              21,
              y + 3
            );
          
          },
        });
      }
      if(y>=240  && (i<sub.size)){
        doc.setFont("helvetica","bold").setFontSize(10);
      doc.text("Deputy Controller of Examinations", 130, 30+y);
      doc.addPage( [215.9,329.4],'p');
      doc.setPage(++pageNumber);
      y=38;
      doc.setFontSize(24); 
      doc.setFont( 'OLDENGL', "bold");
      // doc.setFont("times", "bold");
      doc.text("GC University Lahore", 102, 8+z, { align: 'center' });
          // doc.addImage(image, 54, 7 ,90,7 );
      doc.setFontSize(14);
      // doc.setFont("helvetica", "bold");
      doc.setFont("Arial", "black");
      doc.text("" + majTitle, 102, 13+z, { align: 'center' });
      doc.text("" + se, 102, 19+z, { align: 'center' });
      doc.setFont("courier", "bold");
      doc.text(" PROGRESS REPORT", 102, 26+z, { align: 'center' });
      // doc.setFont("times", "normal");
      doc.setFontSize(8);
      doc.setFont("Arial", "black");
      doc.text("Name: ", 23, 31+z);
      doc.setFont("Arial", "normal");
      doc.text("" + stNm, 47, 31+z);
      doc.setFont("Arial", "black");
      doc.text("Father Name: ", 23, 36+z);
      doc.setFont("Arial", "normal");
      doc.text("" + f_nm, 47, 36+z);
      doc.setFont("Arial", "black");
      doc.text("Roll Number: ", 135, 31+z);
      doc.setFont("Arial", "normal");
      doc.text("" + rolNo, 162, 31+z);
      doc.setFont("Arial", "black");
      doc.text("Registration No: ", 135, 36+z);
      doc.setFont("Arial", "normal");
      if (rgNo == null || rgNo == "") {
        rgNo = "";
      }
      doc.text("" + rgNo, 162, 36+z);  
        }
      body1 = [];
      // doc.setFont("helvetica", "bold");
      // doc.setFont("helvetica", "normal");
      body = [];
    }
    var plus = y + 3;

    var spec = cgpa[sub.size - 1]?.cgpa?.toFixed(2);
    // if (page == 1) {
      doc.setDrawColor(0, 0, 0);
      doc.setFont("Arial", "black");
      // doc.setLineHeightFactor(2)
      doc.line(15, plus + 2, 200, plus + 2);
      doc.setFontSize(8);
      // doc.setFont("Arial", "normal");
//       doc.text(
// `Total Cr.Hrs. For CGPA =     ${totalCrH || ""} \t\t\t\t     Total Grade Points =       ${totalGradePoint || ""} \t\t\t\t     CGPA =     ${(spec || "")}`,
//         15,
//         plus + 7
//       );
      doc.text(
`Total Cr.Hrs. For CGPA =     ${totalCrH || ""}                             Total Grade Points =       ${totalGradePoint?.toFixed(2) || ""}                                     CGPA =     ${(spec || "")}`,
        15,
        plus + 7
      );
      doc.setFont("helvetica","bold");
      doc.text(
      `Duration of ${majTitle} is ${duration} Years(${duration*2} Semesters).This Result is ${isComp}`,
        15,
        12 + plus
      );
      doc.text("Note: Errors and Omissions are Excepted.", 15, 17 + plus);
      doc.text("Prepared By: ___________", 15, 22 + plus);
      doc.text("Checked By: ___________", 15, 27 + plus);
      doc.text("Date of issue: " + dt, 15, 32 + plus);
      doc.setFontSize(10);
      doc.text("Deputy Controller of Examinations", 130, 32 + plus);
    // }
    // doc.save(rolNo.replace('.','-'));
    // return doc.output('blob');
    

    return await doc.output("arraybuffer");
    // return output;
  }

  initCap(words) {
    var separateWord = words.toLowerCase().split(" ");
    for (var i = 0; i < separateWord.length; i++) {
      separateWord[i] =
        separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(" ");
  }
  
  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;  
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateMajors() {
    this.majors.length = 0;
    this.lmsSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  OnClassChange() {
    this.updateMajors();
    // this.updateSessions();
  }

  // updateSessions() {
  //   this.lmsSharedService.filterSessionByCode(this.formRef.value.c_code)
  //     .then(session => {
  //       this.sessions = session
  //       if (this.formRef) {
  //         const initialValue = this.sessions[0]?.id;
  //         this.formRef?.controls['se_id'].setValue(initialValue);
  //       }
  //     })
  //     .catch(error => {
  //       console.log("Error getting sessions", error)
  //     })
  // }
}
