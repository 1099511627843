<div class="container-fluid" [@SlideInFromLeft]>
    <div class="row">
        <div class="col-sm-12 p-2">
            <div class="card shadow-sm">

                <div class="card-header">
                    <div>Major Title Change</div>
                </div>
                <div class="mb-0">
                <form (ngSubmit)="OnSubmit()" #f="ngForm">
                    <div class="card-body row">
                        <div class="col-lg-4 col-md-6 form-group">
                            <label for="C_Code">Class</label>
                            <select #c_code class="form-control " name="c_code"
                                id="c_code" ngModel required>
                                <option *ngFor="let c of classes"
                                    value="{{c.id}}">{{c.nm}}</option>
                            </select>
                        </div>
                        <div class="ml-1 mt-4">
                            <button [disabled]="!f.valid"
                                class="btn btn-primary mt-1" type="submit" [disabled]="!f.valid">Get
                                Majors</button>
                        </div>

                    </div>
                </form>
                <loader *ngIf="loader"></loader>
            </div>

                <div class="card-body mt-0"
                    style="background-color: transparent; min-height: 15rem;" *ngIf="majors.length>0">
                    <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
                        <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
                    </div>
                    <table class="table table-bordered table-striped" id="table">
                        <thead class="thead-dark">
                            <th>Sr No</th>
                            <th>Major Name</th>
                            <th>Print Title</th>
                            <th>Department</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let q of majors; let i=index;" id="values">
                                <td> {{i+1}} </td>
                                <td> {{q.GEN_MAJ}} </td>
                                <td>
                                    {{q.TITLE}}
                                    <div [ngClass]="{'disabled-overlay': modal && index == i}" *ngIf="modal && index == i">
                                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                                          <div class="modal-dialog modal-dialog-centered" role="document">
                                            <div class="modal-content">
                                              <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Update Major Print Title</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openModal(i)">
                                                  <span aria-hidden="true">&times;</span>
                                                </button>
                                              </div>
                                              <form #form="ngForm" (ngSubmit)="onupdateSubmit(form)">
                                              <div class="modal-body">
                                                    <textarea #majorName type="text"
                                                    class="form-control"
                                                    name="majorName"
                                                    [(ngModel)]="content" id="majorName"
                                                    rows="2" ngModel required style="font-weight: bold;">
                                                </textarea>
                                              </div>
                                              <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="openModal(i)">Close</button>
                                                <button class="btn btn-primary" type="submit" (click)="$event.stopPropagation()"> Save </button>
                                              </div>
                                            </form>
                                            </div>
                                          </div>
                                        </div>
                                        </div>  
                                </td>
                                <td> {{q.D_NM}} </td>
                                <td>
                                    <div
                                        style="display: flex; align-items: center; justify-content: center;">
                                        <span>
                                            <button
                                                class="btn btn-sm btn-success"
                                                type="button"
                                                (click)="onUpdateQuestion(q,i)" (click)="openModal(i)" data-toggle="modal" data-target="#exampleModal" data-whatever="@getbootstrap">
                                                <span>
                                                    <i class="fa fa-pencil"
                                                        aria-hidden="true"
                                                        title="Edit"></i>
                                                </span>
                                            </button>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>