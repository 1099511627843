<div [@SlideInFromLeft] class="tabber backdrop" style="overflow:scroll; overflow-y: scroll; margin-left: 5%; margin-top: 1%;">
  <div class="container-fluid ml-1 mb-1 card shadow alert-box  col-lg-8 ml-2 col-md-12 offset-sm-3 ml-2 mr-2 ml-sm-auto mr-sm-auto">
    <div class="card-header d-flex justify-content-between">
      <div>Room Availability</div>
          <button (click)="OnClose()" class="btn btn-outline-primary btn-sm">Close</button>
  </div>
    <form #f="ngForm" (ngSubmit)="onSubmit()">
      <div class="row card-body space-remover">
        <div class="col-lg-3 form-group">
          <label for="blk_id" class="text-bold">Building</label>
          <select #blk_id class="form-control" name="blk_id" id="blk_id" ngModel required
            (change)="OnBuildingChange(blk_id)">
            <option *ngFor="let b of blocks" value="{{b.id}}">{{b.nm}}</option>
          </select>
        </div>
        <div class="col-lg-3 form-group">
          <label for="rm_id">Room</label>
          <select #rm_id class="form-control" name="rm_id" id="rm_id" ngModel required>
            <option value="" selected disabled hidden>Optional</option>
            <option *ngFor="let r of rooms;" value="{{r.id}}">{{r.nm}}</option>
          </select>
        </div>
        <div class="col-lg-3 form-group">
          <label for="day">Day</label>
          <select #day class="form-control" name="day" id="day" ngModel>
            <option value="" selected disabled hidden>Optional</option>
            <option *ngFor="let r of ['Monday','Tuesday','Wednesday','Thursday','Friday']" value="{{r}}">{{r}}</option>
          </select>
        </div>
        <div>
          <button style="height: 35px;" class="btn btn-sm btn-outline-primary mt-4" type="submit">Get Information
            </button>
        </div>
      </div>
    </form>

    <div class="wrapper">

    <div *ngIf="hideInfo" class="d-flex justify-content-between mb-1">
      <div class="list-group-item list-group-item-action">
        <div *ngFor="let data of array">
          <div class="d-flex w-100 justify-content-between">
            <h6 class="mb-1">{{data.cNm}}-{{data.se}} {{data.subject}}-({{data.sub_code}})
              <b>{{data.section}}</b>
            </h6>
            <h6 [ngClass]="data?.status==1?'text-success':'text-secondary'">
              {{data?.status==0?'Draft':'Publish'}}
            </h6>
          </div>
          <div class="d-flex w-100 justify-content-around mt-2" style="align-items: center;">
            <p>Semester: {{data.tNo}}</p>
            <p>{{data.fm}}</p>
            <p>{{data.day}}:
              {{ '1970-01-01 '+ data.sTime | date: 'h:mm a'}}-{{'1970-01-01 '+
              data.eTime | date: 'h:mm a'}}
            </p>
            <p>Department: {{data.deptNm}}</p>
          </div>
          <hr>
        </div>
      </div>

    </div>
  </div>


  </div>
</div>