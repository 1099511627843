import { Component, OnInit } from '@angular/core';
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';
import { ClassService } from '../../shared/services/Class.service';
import { reportsFeeService } from '../../shared/services/reportsFee.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import jsPDF from 'jspdf';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { filter } from 'src/app/shared/functions/tableSearch';

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'app-pending-dues',
  templateUrl: './pending-dues.component.html',
  styleUrls: ['./pending-dues.component.css']
})
export class PendingDuesComponent implements OnInit{

  dept: any[] = [];
  sessions: any;
  deptVar = undefined;
  session = undefined;
  studentData: any = [];
  totalAmount = 0;
  deptName  = undefined;
  loader: boolean = false;

  constructor(
    private feeSharedService: FeeSharedDataService,
    private classService: ClassService,
    private feeReportService: reportsFeeService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
  ){}

  ngOnInit(): void {
    this.updateSessions();
    this.getDept();
  }

  updateSessions() {
    this.sessions = []
    this.feeSharedService.getFeeSessions()
      .then(sessions => {
        this.sessions = sessions;
      })
      .catch(error => {
        console.error("Error getting fee session", error);
      });
  }

  getDept(){
    this.dept = []
    this.classService.getDept().subscribe((result: any) =>{
      this.dept = result
    },
    err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    }
  )

  }

  onSubmit(form: NgForm){
    this.loader = true;
    this.studentData = [];
    this.deptVar = form.value.d_id;
    this.session = form.value.year_in;

    if(!this.deptVar || !this.session){
      this.toastr.error("All fields must be filled.")
      return;
    }
    this.feeReportService.getUnpaidChallans(this.deptVar, this.session).subscribe(
      (data:any)=>{
        if (!data) {
          this.toastr.error("Unknown Error!");
        }
        else {
          this.studentData = data;
          this.loader = false;
          this.calculateTotalAmount();
          this.getDeptName(this.deptVar)
        }
      }, err => {
        console.log(err);
        this.toastr.error("Unknown Error!");
      });

    }

    calculateTotalAmount() {
      this.totalAmount = 0;
      this.studentData.forEach((student) => {
        this.totalAmount += Number(student.pending_amount);
      });
    }

    getDeptName (d_id: number){
      this.dept.forEach((dept)=>{
        if(dept.D_id == d_id){
          this.deptName = dept.D_NM;
        }
      })
    }
  

    CreatePDF() {
      if (this.studentData?.length == 0) {
        this.toastr.warning("No Record Found");
        // this.loader = false
        return;
      }
      else {
        this.toastr.success("Generating PDF")
        const body = [];
        const header = [];
        for (let i = 0; i < this.studentData.length; i++) {
          body.push([
            { content: i + 1, styles: { fontStyle: "bold", halign: 'center' } },
            { content: this.studentData[i].nm, styles: { fontStyle: "bold" } },
            { content: this.studentData[i].rolno, styles: { fontStyle: "bold", halign: 'center' } },
            this.studentData[i].major,
            this.studentData[i].Unpaid_Challans,
            this.studentData[i].pending_amount
          ]);
        }

        body.push([
          { content: 'Total Pending Amount', colSpan: 5, styles: { halign: 'center', fontStyle: 'bold' } },
          { content: this.totalAmount, styles: { halign: 'center', fontStyle: 'bold' } }
        ]);
        var exportDate = this.datePipe.transform(new Date(), 'MMM d, y')
        var image = new Image();
        var page;
        var dept = this.deptName;
        var sessionVar = this.session;
        image.src = '../../../assets/images/logo3.png';
        // if (this.subOrNot == 1)
          header.push(['Sr#', 'Name', 'Roll#', 'Major', 'Unpaid Challans', 'Pending Amount']);
        // else
        //   header.push(['Sr#', 'Challan#', 'Form/Roll#', 'Major', 'Inst#', 'Name', 'Father Name', 'Due Date']);
        const doc = new jsPDF('p', 'mm', 'A4');
        let y = 60;
        autoTable(doc, {
          theme: 'grid',
          startY: y,
          margin: { top: 50, bottom: 10, left: 7, right: 7 },
          head: header,
          body: body,
          headStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0], // sets the text color of the header to black
            lineColor: [0, 0, 0],
            lineWidth: 0.1, // sets the width of the border to 0.2
            fontSize: 8,
            fontStyle: 'bold'
          }, // sets the border color of the header to black
          bodyStyles: {
            lineWidth: 0.1, // sets the width of the border to 0.2
            lineColor: [0, 0, 0], // sets the color of the border to black
            fontSize: 7,
          },
          columnStyles: {
            0: { cellWidth: 10 },
            // 1: { cellWidth: 16 }
          },
          didDrawPage: function () {
            let height = 25;
            doc.addImage(image, 175, 10, 25, 30);
            doc.setFontSize(10);
            doc.text(`${exportDate}`, 177, 45);
            doc.setFontSize(20);
            doc.setFont('Helvetica', 'bold');
            doc.text("GC UNIVERSITY, LAHORE", 105, height, { align: 'center' });
            doc.setFontSize(14);
            doc.setFont('Helvetica', 'bold');
            doc.text("PENDING DUES REPORT", 105, height + 9, { align: 'center' });
            doc.text("______________________", 105, height + 9, { align: 'center' });
            
            doc.setFontSize(12);
            // doc.text("Merit list Summary", 150, 26, { align: 'center' });
            doc.text("DEPARTMENT: " + dept, 105, height + 17, { align: 'center' });
            // doc.text("MAJOR: "+majVar, 105, height+12, { align: 'center' });
            doc.text("SESSION: " + sessionVar, 105, height + 24.5, { align: 'center' });
            page = '';
            page = doc.getNumberOfPages();
          }
        });
        var l = (body.length + 65) % 26;
        doc.setFontSize(7);
        for (var i = 1; i <= page; i++) {
          doc.setPage(i);
          doc.text('Page ' + i + " of " + page, 99, 294);
          doc.text("Directorate of Information & Technology", 10, 295)
          doc.text("Note:- Errors and Omissions Excepted", 150, 295);
          var p = body.length;
        }
        window.open(URL.createObjectURL(doc.output('blob')), '_blank');
      }
    }

    Filter() {
      filter();
    }
}
