import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../auth/_services/authentication.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../shared/model/SelectList.model';
import { LockStudentService } from '../lockstudent/lockstudent.service';
import 'jspdf-autotable';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';

interface lockedstd {
  year: number,
  rollno: string,
  reg_no: string,
  nm: string,
  f_nm: string,
  lock: number
}
interface IStudent {
  YEAR: number,
  ROLNO: string,
  REG_NO: string,
  NM: string,
  F_NM: string,
  LOC: number
}
interface array {
  counter: number
}

@Component({
  selector: 'app-lock-student',
  templateUrl: './lockstudent.component.html',
  styleUrls: ['./lockstudent.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class LockStudentComponent implements OnInit {
  @ViewChild('f') lockstudentForm: NgForm;
  @ViewChild('u') updateForm: NgForm;

  class: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  students: Array<IStudent>;
  lockstd: Array<lockedstd>;
  rnlist: Array<array>;
  clickcheck: boolean = false;
  undoenable: boolean = false;
  count = 0;
  neg = 0;
  loader: boolean = false;
  loader1: boolean = false;
  rolnoo: string;
  superAdmin: boolean;
  unlockstd: Array<lockedstd>

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private lockService: LockStudentService,
    private lmsSharedService: LMSharedDataService

  ) {
    this.superAdmin = authenticationService.superAdmin();
    this.class = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.students = [];
    this.lockstd = [];
    this.unlockstd = [];
  }
  ngOnInit(): void {
    this.updateClasses();
    this.loader = false;
  }

  lockstatus: boolean = false;
  unlockstatus: number = 0;

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.class = classes;
      })
      .catch(error => {
        console.error("Error getting lms classes", error);
      });
  }

  OnClassChange() {
    this.majors = [];
    this.sessions = [];
    this.updateMajors();
    this.updateSessions();
  }

  updateMajors() {
    this.majors.length = 0;
    this.lmsSharedService.filterMajorByCode(this.lockstudentForm.value.c_code)
      .then(major => {
        this.majors = major
        if (this.lockstudentForm) {
          const initialValue = this.majors[0]?.id;
          this.lockstudentForm?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  OnMajorChange() {

  }

  updateSessions() {
    this.lmsSharedService.filterSessionByCode(this.lockstudentForm.value.c_code)
      .then(session => {
        this.sessions = session
        if (this.lockstudentForm) {
          const initialValue = this.sessions[0]?.id;
          this.lockstudentForm?.controls['se_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }

  OnSessionChange() {
    this.students = [];
    this.lockstd = [];
  }

  getStudents() {
    this.loader = true;
    this.students = [];
    this.lockstd = [];
    this.unlockstd = [];
    const { year, c_code, maj_id, se_id, t_no } = this.lockstudentForm.value;
    this.lockService.getStudentInfo(year, c_code, maj_id, se_id, t_no).subscribe((data: any) => {
      for (let i = 0; i < data.length; i++) {
        this.students.push({ YEAR: data[i].YEAR, ROLNO: data[i].ROLNO, REG_NO: data[i].REG_NO, NM: data[i].NM, F_NM: data[i].F_NM, LOC: data[i].LOC })
      }
      this.loader = false;
    });
  };

  OnLockClick() {
    this.loader1 = true;
    this.count = 0;
    this.clickcheck = true;
    if (this.lockstd.length == 0) {
      this.loader1 = false;
      this.toastr.warning("No student selected");
    }
    for (let i = 0; i < this.lockstd.length; i++) {
      let roll = this.lockstd[i].rollno;
      let rn = parseInt(roll.substring(0, 4));
      this.lockService.updateStudentInfo(this.lockstd[i].year, this.lockstudentForm.value.c_code, this.lockstudentForm.value.maj_id, this.lockstudentForm.value.se_id, rn, this.lockstudentForm.value.t_no).subscribe((data: any) => {
        if (data.status == 0) {
          this.toastr.warning("Students already locked");
        }
        else if (data.status == 1 || data.status == 2 || data.status == 3 || data.status == 4 || data.status == 5) {
          // this.lockstatus=true;
          this.count++;

        }
        else {
          this.loader1 = false;
          this.toastr.error("error occured");
        }
        if (this.count == this.lockstd.length) {
          this.loader1 = false;
          this.toastr.success(`${this.count}`, "students locked")
          this.lockstd = [];
        }
      });
    }
  }

  OnUndoClick() {
    this.count = 0;
    this.neg = 0;
    this.loader = true;
    this.clickcheck = true;
    if (this.unlockstd.length == 0) {
      this.loader = false;
      this.toastr.warning("No student selected");
    }
    for (let i = 0; i < this.unlockstd.length; i++) {
      let roll = this.unlockstd[i].rollno;
      let rn = parseInt(roll.substring(0, 4));
      this.lockService.undoStudentInfo(this.unlockstd[i].year, this.lockstudentForm.value.c_code, this.lockstudentForm.value.maj_id, this.lockstudentForm.value.se_id, rn, this.lockstudentForm.value.t_no).subscribe((data: any) => {
        if (data.status == 1) {
          // this.lockstatus=true;
          this.count++;

        }
        else if (data.status == 2 || data.status == 3 || data.status == 4 || data.status == 5) {
          this.count++;
        }
        else if (data.status == 6) {
          this.count++;
          this.neg++;
        }
        else {
          this.loader = false;
          this.toastr.error("error occured");
        }
        if (this.count == this.unlockstd.length) {
          this.loader = false;
          this.toastr.success(`${this.count - this.neg}`, "students unlocked");
          if (this.neg) {
            this.toastr.warning(`${this.neg}`, "student already unlocked");
          }
          this.unlockstd = [];
        }
      });
    }
  }

  onCheckboxChange(e, obj: any) {
    if (e.target.checked) {
      this.lockstd.push({ year: obj.YEAR, rollno: obj.ROLNO, reg_no: obj.REG_NO, nm: obj.NM, f_nm: obj.F_NM, lock: obj.LOC });
    }
    else {
      this.unlockstd.push({ year: obj.YEAR, rollno: obj.ROLNO, reg_no: obj.REG_NO, nm: obj.NM, f_nm: obj.F_NM, lock: obj.LOC });
      this.lockstd.forEach((entry) => {
        if (obj.ROLNO == entry.rollno) {
          this.lockstd = this.lockstd.filter(
            res => res.rollno !== obj.ROLNO)
        }
      })
    }
  }

  OnClickbutton() {
    this.clickcheck = true;
    this.getStudents();
  }
   
  checkDisabled(i: any) {
    this.lockstd.push({ year: i.YEAR, rollno: i.ROLNO, reg_no: i.REG_NO, nm: i.NM, f_nm: i.F_NM, lock: i.LOC });
  }

  OnClear() {
    this.resetform();
    this.clickcheck = false;
    this.loader = false;
  }

  resetform() {
    this.sessions = [];
    this.majors = [];
    this.class = [];
    this.students = [];
    this.lockstd = [];
    this.loader = false;
  }

  checkboxtrue(i: any) {
    return i.LOC == 12;
  }

  Filter() {
    filter()
  }
}