<div [@SlideInFromLeft]>
  <div class="container-fluid mb-2 card">
    <form [formGroup]="awardlistForm" #f="ngForm" class="px-0">
      <div class="card-header mb-0 px-3">Award List</div>
      <div class="row card-body px-3 mt-0">
        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="grp">Group</label>
          <select #grp class="form-control" name="grp" id="grp" (change)="OnGrpChange(grp)" formControlName="grp">
            <option *ngFor="let g of groups" value="{{g.id}}">{{g.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="c_code" class="text-left">Class
          </label>
          <select #c_code (change)="OnClassChange(c_code)" id="c_code" name="c_code" class="form-control"
            formControlName="c_code">
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="se_id" class="text-left">Session
          </label>
          <select #se_id id="se_id" (change)="OnSessionChange()" name="se_id" class="form-control"
            formControlName="se_id">
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="t_no">Semester</label>
          <select #t_no id="t_no" (change)="OnSemesterChange()" name="t_no" class="form-control" formControlName="t_no">
            <option *ngFor="let s of [0,1,2,3,4,5,6,7,8]" value="{{s}}">{{s}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="sub_code">Subject</label>
          <select #sub_code class="form-control " (change)="onSubChange(sub_code) " name="sub_code" id="sub_code"
            required formControlName="sub_code">
            <option *ngFor="let sub of subjecList" value="{{sub}}">
              {{sub}}-{{subjectNameList.get(sub)}}
            </option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-12 form-group">
          <label for="section">Section</label>
          <select #sec class="form-control " name="section" id="section" required formControlName="section">
            <option *ngFor="let s of sections" value="{{s}}" id="{{sub_code.value+s}}">{{s}}</option>
          </select>
        </div>



        <!-- [disabled]="!f.valid" -->
        <div class="mt-3">
          <button type="button" (click)="getAwardlist()" [disabled]="!f.valid" class="btn btn-outline-primary"
            style="margin-left: 15px;">View
            Award List</button>

          <!-- <button class="btn btn-outline-primary" [disabled]="!f.valid" type="button" (click)="createAwardList()"
            style="margin-left: 15px;">Create AwardList</button> -->

          <button class="btn btn-warning" type="button" (click)="ClearForm(f)" style="margin-left: 15px;">Clear</button>

        </div>

        <div *ngIf="this.students.length>0" class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0">
          <div class="card text-left">
            <div class="card-header"><i class="fa fa-line-chart">Student AwardList</i></div>
            <div class="card-body px-3" id="content">
              <div id="setMarginTop" class="row mb-2 px-0 mb-4">
                <div>
                  <button type="button" class="btn btn-outline-primary" (click)="genrateAwardlist()"> General Award List
                  </button>
                </div>

                <!-- <div *ngIf="false">
                  <button type="button" class="btn btn-outline-primary" (click)="WithPassFail(2)"
                    style="margin-left: 5px;">Award List With Pass Fail</button>
                </div> -->

                <div>
                  <button type="button" class="btn btn-outline-primary" (click)="emptyAwardlist()"
                    style="margin-left: 5px;">Award List Without Numbers</button>
                </div>

                <div>
                  <button type="button" class="btn btn-outline-primary" (click)="gradesAwardlist()"
                    style="margin-left: 5px;">Award List With Grades</button>
                </div>

                <!-- <div>
                  <button type="button" class="btn btn-outline-primary" (click)="general(1)"
                    style="margin-left: 5px;">Award List With Mean And STDV</button>
                </div> -->
              </div>

              <div class="col-lg-4 col-md-6 form-group px-0">  
                <input class="form-control" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
              </div>
              <!-- <button type="button" class="btn btn-outline-danger" style="float:right" (click)="deleteAwardlist()"> Delete</button> -->
              <table class="table table-bordered table-striped mt-4" style="overflow: hidden; overflow-x: scroll" id="myTable">
                <thead class="thead-dark">
                  <tr>
                    <th style="width: 1vw;">Sr.</th>
                    <th style="width: 12vw;">Roll No</th>
                    <th style="width: 15vw;">Name</th>
                    <th style="width: 3vw;" style="font-size: small;"
                      [ngClass]="{ 'text-danger': (!examDetails[2].CONDUCTED)}" class="text-center">
                      {{examDetails[2].NM}} <br>
                      <b style="font-size: smaller;">/<input [disabled]="!examDetails[2].CONDUCTED" #w1 type="text"
                          (change)="onTotalMarksChange(examDetails[2].SUB_EXAM_ID, w1.value,
                          $event)" value="{{examDetails[2].T_MARKS}}" class="marks weightage bg-dark" style="color: white"></b>
                    </th>

                    <th style="width: 3vw;" style="font-size: small;"
                      [ngClass]="{ 'text-danger': (!examDetails[3].CONDUCTED)}" class="text-center">
                      {{examDetails[3].NM}} <br>
                      <b style="font-size: smaller;">/<input [disabled]="!examDetails[3].CONDUCTED" #w2 type="text"
                          (change)="onTotalMarksChange(examDetails[3].SUB_EXAM_ID, w2.value,
                          $event)" value="{{examDetails[3].T_MARKS}}" class="marks weightage bg-dark" style="color: white"></b>
                    </th>

                    <th style="width: 3vw;" style="font-size: small;"
                      [ngClass]="{ 'text-danger': (!examDetails[4].CONDUCTED)}" class="text-center">
                      {{examDetails[4].NM}} <br>
                      <b style="font-size: smaller;">/<input [disabled]="!examDetails[4].CONDUCTED" #w3 type="text"
                          (change)="onTotalMarksChange(examDetails[4].SUB_EXAM_ID, w3.value,
                          $event)" value="{{examDetails[4].T_MARKS}}" class="marks weightage bg-dark" style="color: white"></b>
                    </th>

                    <th style="width: 3vw;" style="font-size: small;"
                      [ngClass]="{ 'text-danger': (!examDetails[1].CONDUCTED)}" class="text-center">
                      {{examDetails[1].NM}} <br>
                      <b style="font-size: smaller;">/<input [disabled]="!examDetails[1].CONDUCTED" #w4 type="text"
                          (change)="onTotalMarksChange(examDetails[1].SUB_EXAM_ID, w4.value,
                          $event)" value="{{examDetails[1].T_MARKS}}" class="marks weightage bg-dark" style="color: white"></b>
                    </th>
                    <th style="width: 3vw;" style="font-size: small;">
                      Sessional
                    </th>

                    <th style="width: 3vw;" style="font-size: small;"
                      [ngClass]="{ 'text-danger': (!examDetails[0].CONDUCTED)}" class="text-center">
                      {{examDetails[0].NM}} <br>
                      <b style="font-size: smaller;">/<input [disabled]="!examDetails[0].CONDUCTED" #w5 type="text"
                          (change)="onTotalMarksChange(examDetails[0].SUB_EXAM_ID, w5.value,
                          $event)" value="{{examDetails[0].T_MARKS}}" class="marks weightage bg-dark" style="color: white"></b>
                    </th>
                    <th>Total: {{totalWeightage()}}</th>
                    <th>Grade</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody *ngIf="examDetails.length">
                  <tr *ngFor="let std of students; let i = index" [ngClass]="{ 'text-danger': (!std.ELIGIBLE)}" id="values">
                    <td>
                      {{i+1}}
                    </td>
                    <td>
                      {{std.ROLNO.toUpperCase()}}
                    </td>
                    <td>
                      {{std.NM.toUpperCase()}}
                    </td>
                    <td>
                      <div class="center">
                      <input [disabled]=" (!std.ELIGIBLE || !examDetails[2].CONDUCTED)" class="marks text-center"
                        [ngClass]="{ 'text-danger': (!std.ELIGIBLE)}" type="number"
                        (change)="onChangeMark(i,examDetails[2].SUB_EXAM_ID, $event)" min="0" value="{{std.MARKS[2]}}">
                        </div>
                    </td>
                    <td>
                      <div class="center">
                      <input [disabled]=" (!std.ELIGIBLE || !examDetails[3].CONDUCTED)" class="marks text-center"
                        [ngClass]="{ 'text-danger': (!std.ELIGIBLE)}" type="number"
                        (change)="onChangeMark(i,examDetails[3].SUB_EXAM_ID, $event)" min="0" value="{{std.MARKS[3]}}">
                        </div>
                    </td>
                    <td>
                      <div class="center">
                      <input [disabled]=" (!std.ELIGIBLE || !examDetails[4].CONDUCTED)" class="marks text-center"
                        [ngClass]="{ 'text-danger': (!std.ELIGIBLE)}" type="number"
                        (change)="onChangeMark(i,examDetails[4].SUB_EXAM_ID, $event)" min="0" value="{{std.MARKS[4]}}">
                        </div>
                    </td>
                    <td>
                      <div class="center">
                      <input [disabled]=" (!std.ELIGIBLE || !examDetails[1].CONDUCTED)" class="marks text-center"
                        [ngClass]="{ 'text-danger': (!std.ELIGIBLE)}" type="number"
                        (change)="onChangeMark(i,examDetails[1].SUB_EXAM_ID, $event)" min="0" value="{{std.MARKS[1]}}">
                        </div>
                    </td>
                    <td>
                      {{sesionalMarks(std?.MARKS)}}
                    </td>
                    <td>
                      <div class="center">
                      <input [disabled]=" (!std.ELIGIBLE || !examDetails[0].CONDUCTED)" class="marks text-center"
                        [ngClass]="{ 'text-danger': (!std.ELIGIBLE)}" type="number"
                        (change)="onChangeMark(i,examDetails[0].SUB_EXAM_ID, $event)" min="0" value="{{std.MARKS[0]}}">
                        </div>
                    </td>
                    <td class="text-center">
                      {{totalMarks(std?.MARKS)}}
                    </td>
                    <td class="text-center">
                      {{std?.GRADE}}
                    </td>
                    <td>
                      <div class="center">
                      <input #todelete type="checkbox" name="todelete" style="transform: scale(1.5); cursor: pointer;" (change)="onSelect(todelete.checked,i)">
                    </div>
                    </td>
                  </tr>

                </tbody>
              </table>

              <button type="button" class="btn btn-outline-danger" [disabled]="this.todel_rn==''" style="float:right"
                (click)="deleteAwardlist()"> Delete</button>
            </div>
          </div>
          <br>
        </div>
      </div>
    </form>
  </div>
</div>