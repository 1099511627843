import { Component, OnInit,ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { NgForm } from '@angular/forms';
import { FeeService } from '../shared/services/Fee.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dues-adjustments',
  templateUrl: './dues-adjustments.component.html',
  styleUrls: ['./dues-adjustments.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class DuesAdjustmentsComponent implements OnInit {

  
  StudentInfoNew:Array<{ch_no:number,dueDate:string,num:number,sem:number,nm:number,f_nm:string}>;
  StudentInfoOld:Array<{ch_no:number,dueDate:string,num:number,sem:number,nm:number,f_nm:string}>;
  StudentAmountNew:Array<{acc:string,debit:number}>;
  StudentFullAmountNew: Array<{acc:string,debit:number}>;
  StudentAmountOld:Array<{acc:string,debit:number}>;
  showBorder:boolean=false;

  @ViewChild('f') formRef: NgForm;

  constructor(
    private feeService: FeeService,
    private toastr: ToastrService
  ) { 
    this.StudentInfoNew=new Array<{ch_no:number,dueDate:string,num:number,sem:number,nm:number,f_nm:string}>();
    this.StudentInfoOld=new Array<{ch_no:number,dueDate:string,num:number,sem:number,nm:number,f_nm:string}>();
    this.StudentAmountNew=new Array<{acc:string,debit:number}>();
    this.StudentFullAmountNew=new Array<{acc:string,debit:number}>();
    this.StudentAmountOld=new Array<{acc:string,debit:number}>();
  }


  ngOnInit(): void {
  }

  getChallan(){
    let msg =
    "Do you really want to Adjust Dues of Challan# "+this.formRef.value.oldCh+" into Challan# "+this.formRef.value.newCh;
  if (confirm(msg)) {
    this.adjustDues();
    this.getChallanOld();
    this.getChallanNew();
  }
  }
  getChallan2(){
    // this.adjustDues();
    this.getChallanOld();
    this.getChallanNew();
  }

  getChallanOld(){
    this.StudentInfoOld=[];
    this.StudentAmountOld=[];
    this.feeService.getOldChallanForm(this.formRef.value.oldCh).subscribe(res=> {
      if(!res){
      this.toastr.error("Unknown Error!");
    }
    else if(res){
      this.showBorder=true;
      res[1]?.forEach(entry => {
        this.StudentInfoOld.push(entry);
      })
      res[2]?.forEach(entry=>{
        this.StudentAmountOld.push(entry);
      })
    }
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });

  }


  getChallanNew(){
    this.StudentInfoNew=[];
    this.StudentAmountNew=[];
    this.StudentFullAmountNew=[];
    this.feeService.getChForm(this.formRef.value.newCh).subscribe(res=> {
      if(!res){
      this.toastr.error("Unknown Error!");
    }
    else if(res){
      this.showBorder=true;
      res[1]?.forEach(entry => {
        this.StudentInfoNew.push(entry);
      })
      res[2]?.forEach(entry=>{
        this.StudentAmountNew.push(entry);
      })
      res[3]?.forEach(entry=>{
        this.StudentFullAmountNew.push(entry);
      })
    }
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });

  }

  adjustDues(){
    if(this.formRef.value.oldCh && this.formRef.value.newCh && this.formRef.value.oldCh != this.formRef.value.newCh){    
    this.feeService.onDuesAdjustments(this.formRef.value.oldCh, this.formRef.value.newCh).subscribe((res: { msg: string, warning: string }) => {
      if (!res) {
      this.toastr.error("Cannot Adjust Dues!");
    }
    else if(res.msg){
      this.toastr.success(res.msg);
    }
    else if(res.warning){
      this.toastr.warning(res.warning);
    }
    else {
      this.toastr.error("Unknown Error!");
    }
  });
}else{
    this.toastr.error("Please Insert Valid Challan Numbers");
  }
  }

  OnSubmit() {
    if (!this.formRef.valid)
      return;
  }
}
