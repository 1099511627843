<form #f="ngForm">
    <div class=" container-fluid" [@SlideInFromLeft]>
        <div class="row card shadow">
            <div class="card-header"  style="padding-top: 10px;">
                <div class=" col-lg-6 col-md-6 form-group">
                  Overall Student Strength Report</div>
                   <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
                  <app-details-button [details]="details"></app-details-button>
              </div>
            </div>
            <div class="row card-body" style="margin-top:-5px">
                <div class="col-lg-4 col-md-4 form-group">
                    <label for="lvl_id">Study Level</label>
                    <select #lvl_id class="form-control " name="lvl_id" id="lvl_id" ngModel required>
                        <option *ngFor="let c of study_lvl" value="{{c.id}}">{{c.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-4 form-group">
                    <label for="year">Year</label>
                    <input class="form-control" #year name="year" id="year" min="2000" max="3000" ngModel required>
                </div>

                <div class="mb-3 col-12">
                    <button class="btn btn-success" (click)="getData()" [disabled]="!f.valid">
                        <i class="fa fa-file-excel-o" for=""></i>
                        Download Report Excel
                    </button>
                </div>
                <div class="center col-12"> <loader *ngIf="loader"></loader> </div>
            </div>
        </div>
    </div>
</form>