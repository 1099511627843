import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
import { param } from 'jquery';
@Injectable({
    providedIn: 'root'
})

export class StudentService {

    constructor(private http: HttpClient) {
    }

    getInfo(param) {
        return this.http.post(`${baseUrl}/api/student/getInfo`, param);
    }

    updateNIC(param){
        return this.http.post(`${baseUrl}/api/student/updateNIC`, param);
    }

    getStdInfoSubDetails(c_code:string,se_id:string,t_no:string,userNm:string){
        return this.http.get(`${baseUrl}/api/student/getStdInfoSubDetails/${c_code}/${se_id}/${t_no}/${userNm}`);
    }

    getStudentInfoDetail(rolNo:string){
        return this.http.get(`${baseUrl}/api/student/getStudentInfoDetail/${rolNo}`);
    }

    getStudentAdmissionDetail(rolNo:any, f_no:any, ch_no: any){
        return this.http.post(`${baseUrl}/api/student/getStudentAdmissionDetail`, {rolNo, f_no, ch_no});
    }
    
    checkEnroll(year:number,c_code:number,se_id:number,maj_id:number,rn:number,t_no:number){
        return this.http.get(`${baseUrl}/api/student/checkEnroll/${year}/${c_code}/${se_id}/${maj_id}/${rn}/${t_no}`);
    }

    checkSubOffer(c_code:number,se_id:number,maj_id:number,t_no:number){
        return this.http.get(`${baseUrl}/api/student/checkSubOffer/${c_code}/${se_id}/${maj_id}/${t_no}`);
    }
    
    getStdList(param){
        return this.http.post(`${baseUrl}/api/student/getStdList`, param);
    }

    getTimeTableForPDF(param) {
        return this.http.post(`${baseUrl}/api/student/getTimeTableForPDF`, param);
    }

    getStudentsRollNumbers(param){
        return this.http.post(`${baseUrl}/api/student/getStudentsRollNumbers`, param);
    }
    getStudentsRollNumbersForDetail(param){
        return this.http.post(`${baseUrl}/api/student/getStudentsRollNumbersForDetail`, param);
    }
    getStudentNameWithRolno(rolno:string){
        return this.http.get(`${baseUrl}/api/student/getStudentNameWithRolno/${rolno}`);
    }
    getSingleStdAwardlist(param) {
        return this.http.post(`${baseUrl}/api/student/getSingleStdAndAwardlist`, param);
    }
    updateStudentDetailADM(params){
        return this.http.post(`${baseUrl}/api/student/updateStudentDetailADM`, params);
    }
    updateStudentDetailADMFee(params){
        return this.http.post(`${baseUrl}/api/student/updateStudentDetailADMFee`, params);
    }
    updateStudentDetailADMSFC(params){
        return this.http.post(`https://sfc.gcu.edu.pk/api/lms/updateStdData`, params);
    }
    
    getStudentByEnrolledT_NO(c_code:number,se_id:number,t_no:number){
        return this.http.get(`${baseUrl}/api/student/getStudentByEnrolledT_NO/${c_code}/${se_id}/${t_no}`);
    }
    getStudentByEnrolledT_NOy(c_code:number,se_id:number,t_no:number,year:number){
        return this.http.get(`${baseUrl}/api/student/getStudentByEnrolledT_NOy/${c_code}/${se_id}/${t_no}/${year}`);
    }
    // Student Report Screen for Departmental Admin
    getStudentSubjectWise(params){
        return this.http.post(`${baseUrl}/api/student/getStudentSubjectWise`, params);
    }

    //Major Change Screen record
    getADMStudentMajor(param){
        return this.http.post(`${baseUrl}/api/student/getADMStudentMajor`, param);
    }

    getADMStudentMajorFee(param){
        return this.http.post(`${baseUrl}/api/student/getADMStudentMajorFee`, param);
    }

    checkingTnoForMajTransfer(param){
        return this.http.post(`${baseUrl}/api/student/checkingTnoForMajTransfer`, param);
    }

    getSubjectCombination(param){
        return this.http.post(`${baseUrl}/api/student/getSubjectCombination`, param); 
    }

    updateSubjectCombination(param){
        return this.http.post(`${baseUrl}/api/student/updateSubjectCombination`, param);
    }
    updateStudentMajor(param){
        return this.http.post(`${baseUrl}/api/student/updateStudentMajor`, param);
    }
    updateStudentMajorFeeServer(param){
        return this.http.post(`${baseUrl}/api/student/updateStudentMajorFeeServer`, param);
    }
    updateStudentClass(param){
        return this.http.post(`${baseUrl}/api/student/updateStudentClass`, param);
    }

    getStudentName(params){
        return this.http.post(`${baseUrl}/api/student/getStudentName`, params);
    }

    getDetailedStudent(c_code:number,se_id:number,rolno:string,t_no:number){
        return this.http.get(`${baseUrl}/api/student/getDetailedStudent/${c_code}/${se_id}/${rolno}/${t_no}`);
    }

    getStudentNameByRolNo(params){
        return this.http.post(`${baseUrl}/api/student/getStudentNameByRolNum`, params);
    }

    getEnrolledSubjects(params){
        return this.http.post(`${baseUrl}/api/student/getEnrolledSubjects`, params);
    }

    getStudentAcademicDetail(c_code:any, st_year:any, rn: any){
        return this.http.post(`${baseUrl}/api/student/S_StdtAcademicDetail`, {c_code, st_year, rn});
    }

    insertAdmissionStd(params){
        return this.http.post(`${baseUrl}/api/student/adm_admission_std`, params);
    }
    GetMaxRollNumberPlusOne(param){
        return this.http.post(`${baseUrl}/api/student/GetMaxRollNumberPlusOne`, param); 
    }
    Get_nic_status(param){
        return this.http.post(`${baseUrl}/api/student/CheckDuplicateNIC`, param);
    }

}