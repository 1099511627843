<div class="card" [@SlideInFromLeft]>
  <div class="card-header" style="padding-top: 10px;">
    <div class=" col-lg-6 col-md-6 form-group">
      STUDENT DETAILS</div>
    <div class="col-lg-6 col-md-6 form-group"
      style="text-align: right; text-transform: capitalize;">
      <app-details-button [details]="details"></app-details-button>
    </div>
  </div>

  <div class="row card-body">
    <div class="col-sm-12 col-lg-12 p-2">
      <form #f="ngForm">
        <div class="row">

          <div class="col-lg-4 col-md-6 form-group">
            <label for="C_Code">Class</label>
            <select (change) = "OnClassChange()" id="c_code" name="c_code" class="form-control" ngModel
              [disabled]="type == 'popup'">
              <option *ngFor="let c of classes"
                value="{{c.id}}">{{c.nm}}</option>
            </select>
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="se_id">Sessions</label>
            <select #se_id class="form-control " name="se_id" id="se_id"
              (change)="loadRollno()" required ngModel
              [disabled]="type == 'popup'">
              <option *ngFor="let s of sessions"
                value="{{s.id}}">{{s.nm}}</option>
            </select>
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="in">Complete Roll Number(s)</label>
            <select #rolno class="form-control" name="rolno" id="rolno"
              value="rolno" ngModel required [disabled]="type == 'popup'">
              <option *ngFor="let s of rolnoArr"
                value="{{s.nm}}">{{s.nm}}</option>
            </select>
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="t_no">Semester</label>
            <select #semester class="form-control" #session name="t_no"
              id="Semester" ngModel required [disabled]="type == 'popup'">
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">COMPLETE</option>
            </select>
          </div>

        </div>
        <div class="row mt-3">
          <button
            class="btn  col-lg-3 col-md-3 col-sm-12 btn-sm btn-primary ml-3 mt-2"
            (click)="getDetailedStudentData()" (click)="getUser()"
            type="button" [disabled]="valid">View Student Details
          </button>
          <h5 class=" col-lg-8 col-md-3 col-sm-12  mt-2 text-right"><span
              class="btn1 badge badge-secondary">
              Status: {{remarks}}
            </span></h5>
        </div>
      </form>
    </div>

    <div *ngIf="this.showbuttonspallete" class="row mt-3 mb-3">
      <button class="btn btn-sm btn-warning ml-4  mt-2 "
        (click)="Onshowpersonaldetailsclick()" type="button">Personal
        Details
      </button>
      <button class="btn btn-sm btn-warning ml-2  mt-2 "
        (click)="Onshowsubjectofferclick()" type="button">Subject Offer
      </button>
      <button class="btn btn-sm btn-warning ml-2  mt-2 "
        (click)="Onshowenrollmentclick()" type="button">Enrollment
      </button>
      <button class="btn btn-sm btn-warning ml-2  mt-2 "
        (click)="Onshowattendanceclick()" type="button">Attendance
      </button>
      <button class="btn btn-sm btn-warning ml-2  mt-2 "
        (click)="Onshowawardlistclick()" type="button">Award List
      </button>
      <button class="btn btn-sm btn-warning ml-2  mt-2 "
        (click)="Onshowexamresultclick()" type="button">Exam Result
      </button>
      <button class="btn btn-sm btn-warning ml-2  mt-2 "
        (click)="Onshowexamsummaryclick()" type="button">Exam Summary
      </button>
      <!--added by mehtab on dated 25-03-2024-->
      <button class="btn btn-sm btn-danger ml-2  mt-2 "
        (click)="getFeeDetails(rolno, rolno, rolno)" type="button">Fee
        Details
      </button>
      <button class="btn btn-sm btn-danger ml-2  mt-2 "
        (click)="Onshowrepeaterdataclick()" type="button">Repeat Data
      </button>

      <button *ngFor="let s of status" class="btn btn-sm btn-light ml-2 mt-2"
        style="font-weight: bold; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;">
        Status: {{s.des}}
      </button>
    </div>

    <div style="display: flex; align-items: center; justify-content: center;"
      class="col-12 mt-5"> <loader *ngIf="loader"></loader></div>

    <div *ngIf="this.showpersonaldetails"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Personal
            Details</i>
        </div>

        <div class="card-body">
          <div class="col-sm-12 col-lg-12 p-2">
            <form #fn="ngForm">
              <div *ngFor="let s of studentadm_stdt;" class=" row form-group">

                <div class="col-lg-4 col-md-4">
                  <h6 class="card-title">Name</h6>
                  <input [readonly]="!canStudentDetail && !this.isAdmin" #name
                    class="form-control" name="name"
                    id="name" value="{{s.nm.toUpperCase()}}" required>
                </div>
                <div class="col-lg-4 col-md-4">
                  <h6 class="card-title">Father Name</h6>
                  <input [readonly]="!canStudentDetail && !this.isAdmin" #fName
                    class="form-control" name="fName"
                    id="fName" value="{{s.f_nm.toUpperCase()}}" required>
                </div>
                <div class="col-lg-4 col-md-4">
                  <h6 class="card-title">CNIC</h6>
                  <input [readonly]="!canStudentDetail && !this.isAdmin" #cnic
                    class="form-control" name="cnic"
                    id="cnic" value="{{s.nic}}" required>
                </div>
                <div class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Date of Birth</h6>
                  <input [readonly]="!canStudentDetail && !this.isAdmin" #dob
                    class="form-control" name="dob" id="dob"
                    value="{{s.DOB}}" required>
                </div>

                <!-- <div class="col-lg-4 col-md-4 mt-2">
                <h6 class="card-title">Registration No</h6>
                <input readonly #regno class="form-control" name="regno"
                  id="regno" value="{{s.reg_no}}">
              </div> -->
                <!-- <div class="mt-2 col-lg-4 col-md-4">
                <h6 class="card-title">Registration No</h6>
                <input [readonly]="this.onDepartmentAccess||this.isExamCell " #reg_no type="reg_no" class="form-control"
                  name="reg_no" id="reg_no" value="{{s.reg_no}}" required>
              </div> -->

                <div class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Email</h6>
                  <input [readonly]="!this.isAdmin " #email type="email"
                    class="form-control" name="email" id="email"
                    value="{{s.email}}" required>
                </div>
                <div [hidden]="this.onDepartmentAccess "
                  class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Student Contact No</h6>
                  <input #ph1 type="number" class="form-control"
                    [readonly]="!canEnrollRepeater && !this.isAdmin"
                    name="ph1" id="ph1" value="{{s.ph1}}" required>
                </div>
                <div [hidden]="this.onDepartmentAccess"
                  class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Guardian Contact No</h6>
                  <input #ph2 type="number" class="form-control"
                    [readonly]="!canEnrollRepeater && !this.isAdmin"
                    name="ph2" id="ph2" value="{{s.ph2}}" required>
                </div>
                <div class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Gender</h6>
                  <input [readonly]="this.onDepartmentAccess" #gender
                    class="form-control" name="gender" id="gender"
                    value="{{s.gender}}">
                </div>

                <div class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Religion</h6>
                  <input [readonly]="this.onDepartmentAccess||this.isExamCell"
                    #religion class="form-control"
                    name="religion" id="religion" value="{{s.relig}}" required>
                </div>
                <div class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Department</h6>
                  <input class="form-control" name="name" id="name"
                    value="{{s.deptt}}" readonly>
                </div>
                <div class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Major</h6>
                  <input class="form-control" name="name" id="name"
                    value="{{s.major}}" readonly>
                </div>
                <div class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Year</h6>
                  <input class="form-control" name="name" id="name"
                    value="{{s.year}}" readonly>
                </div>
                <div [hidden]="this.onDepartmentAccess"
                  class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Session</h6>
                  <input class="form-control" name="name" id="name"
                    value="{{s.session}}" readonly>
                </div>
                <div *ngIf="!(studentadm_stdt[0]?.c_code == 1)"
                  class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Set no</h6>
                  <input class="form-control" name="name" id="name"
                    value="{{ s.set_no }}" readonly>
                </div>

                <div *ngIf="studentadm_stdt[0]?.c_code==1"
                  class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Elective Courses</h6>
                  <input class="form-control" name="name" id="name"
                    value="{{s.set_no}}" readonly>
                </div>

                <div *ngIf="!(studentadm_stdt[0]?.c_code == 1)"
                  class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Registration No.</h6>
                  <input #reg_no class="form-control" name="reg_no" id="reg_no"
                    value="{{s.reg_no}}" readonly>
                </div>

                <div *ngIf="studentadm_stdt[0]?.c_code==1"
                  class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Registration No.</h6>
                  <input #reg_no class="form-control" name="reg_no" id="reg_no"
                    (change)="OnRegUpdate(reg_no.value)"
                    value="{{s.reg_no}}">
                </div>

                <div *ngIf="studentadm_stdt[0]?.c_code==1"
                  class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Board No. Part I</h6>
                  <input #BRD_RN class="form-control" name="BRD_RN" id="BRD_RN"
                    (change)="OnBoardUpdate(BRD_RN.value)"
                    value="{{s.BRD_RN}}">
                </div>

                <div *ngIf="studentadm_stdt[0]?.c_code==1"
                  class="mt-2 col-lg-4 col-md-4">
                  <h6 class="card-title">Board No. Part II</h6>
                  <input #BRD_RN_2 class="form-control" name="BRD_RN_2"
                    id="BRD_RN_2"
                    (change)="OnBoard2Update(BRD_RN_2.value)"
                    value="{{s.BRD_RN_2}}">
                </div>
                <!-- <div class="mt-2 col-lg-4 col-md-4">
                <h6 class="card-title">Set no</h6>
                <input class="form-control" name="name" id="name" value="{{s.set_no}}" readonly>
              </div> -->
                <!-- [readonly]="this.onDepartmentAccess" -->
                <div [hidden]="this.onDepartmentAccess"
                  class="mt-2 col-lg-12 col-md-0">
                  <h6 class="card-title">Address</h6>
                  <input #add1 class="form-control"
                    [readonly]="!canEnrollRepeater && !this.isAdmin" name="add1"
                    id="add1" value="{{s.add1}}">
                </div>

                <div [hidden]="this.onDepartmentAccess"
                  class="mt-2 col-lg-12 col-md-0">
                  <h6 class="card-title">Address 2</h6>
                  <input #add2 class="form-control"
                    [readonly]="!canEnrollRepeater && !this.isAdmin" name="add2"
                    id="add2" value="{{s.add2}}">
                </div>
                <div
                  *ngIf="this.canStudentDetail || this.isAdmin || this.isExamCell"
                  class="ml-1 mt-4 col-lg-4 col-md-2">
                  <button style="height: 40px;" (click)="UpdateStudentDetail()"
                    (click)="onSubmit(name,fName,cnic,email,add1,ph1,ph2,gender,religion,dob,add2)"
                    (click)="onSubmitFee(name,fName,cnic,email,add1,ph1,ph2,gender,religion,dob,add2)"
                    class="btn btn-primary">Update Information</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="this.showsubjectoffer && !loader"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Subject
            Offer</i></div>
        <div class="card-body">
          <table class="table table-bordered table-striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Sr #</th>
                <th scope="col">Subject Code</th>
                <th scope="col">Subject Name</th>
                <th scope="col">Session</th>
                <th scope="col">Semester</th>
                <th scope="col">Set #</th>
                <th scope="col">Reg/Sum</th>
                <th scope="col">Comp/Elec</th>
                <th scope="col">Credit Hours</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of sub_offered; let i =index;">
                <td>{{i+1}}</td>
                <td>{{element.SUB_CODE}}</td>
                <td>{{element.SUB_NM}}</td>
                <td>{{element.SESSION}}</td>
                <td>{{element.T_NO}}</td>
                <td>{{element.SET_NO}}</td>
                <td>{{element.REG_SUMR}}</td>
                <td>{{element.C_ELE}}</td>
                <td>{{element.CR_H}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="this.showenrollment && !loader"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i
            class="fa fa-line-chart">Enrollment</i></div>
        <div class="card-body">
          <table class="table table-bordered table-striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Sr #</th>
                <th scope="col">Roll #</th>
                <th scope="col">Session</th>
                <th scope="col">Semester</th>
                <th scope="col">Subject Code</th>
                <th scope="col">Subject Name</th>
                <th scope="col">Section</th>
                <th scope="col">Set No</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of enrollment; let i =index;">
                <td>{{i+1}}</td>
                <td>{{element.ROLNO}}</td>
                <td>{{element.SESSION}}</td>
                <td>{{element.T_NO}}</td>
                <td>{{element.SUB_CODE}}</td>
                <td>{{element.SUB_NM}}</td>
                <td>{{element.SECTION}}</td>
                <td>{{element.SET_NO}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="this.showattendance && !loader"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i
            class="fa fa-line-chart">Attendance</i></div>
        <div class="card-body">
          <table class="table table-bordered table-striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Sr #</th>
                <th scope="col">Roll #</th>
                <th scope="col">Session</th>
                <th scope="col">Semester</th>
                <th scope="col">Subject Code</th>
                <th scope="col">Subject Name</th>
                <th scope="col">Section</th>
                <th scope="col">Teacher</th>
                <th scope="col">Attendance</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of attendance; let i =index;">
                <td>{{i+1}}</td>
                <td>{{element.RN}}</td>
                <td>{{element.SESSION}}</td>
                <td>{{element.T_NO}}</td>
                <td>{{element.SUB_CODE}}</td>
                <td>{{element.SUB_NM}}</td>
                <td>{{element.SECTION}}</td>
                <td>{{element.TEACHER}}</td>
                <td>{{element.ATTENDANCE}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="this.showawardlist && !loader"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Award
            List</i></div>
        <div class="card-body">
          <table class="table table-bordered table-striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Sr #</th>
                <th scope="col">Session</th>
                <th scope="col">Semester</th>
                <th scope="col">Sub Code</th>
                <th scope="col">Subject Name</th>
                <th scope="col">Section</th>
                <th scope="col">Sessional</th>
                <th scope="col">Final Term</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let r of award_lst; let i =index;">
                <td>{{i+1}}</td>
                <td>{{r.SESSION}}</td>
                <td>{{r.T_NO}}</td>
                <td>{{r.SUB_CODE}}</td>
                <td>{{r.SUB_NM}}</td>
                <td>{{r.SECTION}}</td>
                <td>{{r.SESSIONAL}}</td>
                <td>{{r.FINAL_TERM}}</td>
                <td>{{r.FINAL_TERM + r.SESSIONAL}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="this.showexamresult && !loader"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Exam
            Result</i></div>
        <div class="card-body">
          <table class="table table-bordered table-striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Sr #</th>
                <th scope="col">Year</th>
                <th scope="col">Roll Number</th>
                <th scope="col">Session</th>
                <th scope="col">Semester</th>
                <th scope="col">Subject Code</th>
                <th scope="col">Subject Name</th>
                <th scope="col">Total Marks</th>
                <th scope="col">Obtained Marks</th>
                <th scope="col">Grade</th>
                <th scope="col">Attempt No</th>
                <th scope="col">Remarks</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of ex_rslt; let i = index;">
                <td>{{i+1}}</td>
                <td>{{element.YEAR}}</td>
                <td>{{element.RN}}</td>
                <td>{{element.SESSION}}</td>
                <td>{{element.T_NO}}</td>
                <td>{{element.SUB_CODE}}</td>
                <td>{{element.subname}}</td>
                <td>{{element.MAX_MRKS}}</td>
                <td>{{element.MRKS_SS}}</td>
                <td>{{element.GRD}}</td>
                <td>{{element.ATMP_NO}}</td>
                <td>{{element.REM}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="this.showexamsummary && !loader"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Exam
            Summary</i></div>
        <div class="card-body">
          <table class="table table-bordered table-striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Sr # </th>
                <th scope="col">Year</th>
                <th scope="col">Roll Number</th>
                <th scope="col">Session</th>
                <th scope="col">Semester</th>
                <th scope="col">Total Marks</th>
                <th scope="col">Obtained Marks</th>
                <th scope="col">Grade</th>
                <th scope="col">Remarks</th>
                <th scope="col">CGPA</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of ex_sum; let i =index;">
                <td>{{i+1}}</td>
                <td>{{element.YEAR}}</td>
                <td>{{element.RN}}</td>
                <td>{{element.SESSION}}</td>
                <td>{{element.T_NO}}</td>
                <td>{{element.TOTAL_MRKS}}</td>
                <td>{{element.MRKS}}</td>
                <td>{{element.GRD}}</td>
                <td>{{element.REM}}</td>
                <td>{{element.CGPA}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="this.Rshowenrollment && !loader"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Repeater
            Enrollment</i></div>
        <div class="card-body">
          <table class="table table-bordered table-striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Sr #</th>
                <th scope="col">Roll #</th>
                <th scope="col">Session</th>
                <th scope="col">Semester</th>
                <th scope="col">Subject Code</th>
                <th scope="col">Subject Name</th>
                <th scope="col">Section</th>
                <th scope="col">Set No</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of Renrollment; let i =index;">
                <td>{{i+1}}</td>
                <td>{{element.ROLNO}}</td>
                <td>{{element.SESSION}}</td>
                <td>{{element.T_NO}}</td>
                <td>{{element.SUB_CODE}}</td>
                <td>{{element.SUB_NM}}</td>
                <td>{{element.SECTION}}</td>
                <td>{{element.SET_NO}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="this.Rshowattendance && !loader"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Repeater
            Attendance</i></div>
        <div class="card-body">
          <table class="table table-bordered table-striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Sr #</th>
                <th scope="col">Roll #</th>
                <th scope="col">Session</th>
                <th scope="col">Semester</th>
                <th scope="col">Subject Code</th>
                <th scope="col">Subject Name</th>
                <th scope="col">Section</th>
                <th scope="col">Teacher</th>
                <th scope="col">Attendance</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of Rattendance; let i =index;">
                <td>{{i+1}}</td>
                <td>{{element.RN}}</td>
                <td>{{element.SESSION}}</td>
                <td>{{element.T_NO}}</td>
                <td>{{element.SUB_CODE}}</td>
                <td>{{element.SUB_NM}}</td>
                <td>{{element.SECTION}}</td>
                <td>{{element.TEACHER}}</td>
                <td>{{element.ATTENDANCE}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="this.Rshowawardlist && !loader"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Repeater Award
            List</i></div>
        <div class="card-body">
          <table class="table table-bordered table-striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Sr #</th>
                <th scope="col">Session</th>
                <th scope="col">Semester</th>
                <th scope="col">Sub Code</th>
                <th scope="col">Subject Name</th>
                <th scope="col">Section</th>
                <th scope="col">Sessional</th>
                <th scope="col">Final Term</th>
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let r of Raward_lst; let i =index;">
                <td>{{i+1}}</td>
                <td>{{r.SESSION}}</td>
                <td>{{r.T_NO}}</td>
                <td>{{r.SUB_CODE}}</td>
                <td>{{r.SUB_NM}}</td>
                <td>{{r.SECTION}}</td>
                <td>{{r.SESSIONAL}}</td>
                <td>{{r.FINAL_TERM}}</td>
                <td>{{r.FINAL_TERM + r.SESSIONAL}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div *ngIf="this.Rshowexamresult && !loader"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Repeater Exam
            Result</i></div>
        <div class="card-body">
          <table class="table table-bordered table-striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Sr #</th>
                <th scope="col">Year</th>
                <th scope="col">Roll Number</th>
                <th scope="col">Session</th>
                <th scope="col">Semester</th>
                <th scope="col">Subject Code</th>
                <th scope="col">Subject Name</th>
                <th scope="col">Total Marks</th>
                <th scope="col">Obtained Marks</th>
                <th scope="col">Grade</th>
                <th scope="col">Attempt No</th>
                <th scope="col">Remarks</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of Rex_rslt; let i = index;">
                <td>{{i+1}}</td>
                <td>{{element.YEAR}}</td>
                <td>{{element.RN}}</td>
                <td>{{element.SESSION}}</td>
                <td>{{element.T_NO}}</td>
                <td>{{element.SUB_CODE}}</td>
                <td>{{element.subname}}</td>
                <td>{{element.MAX_MRKS}}</td>
                <td>{{element.MRKS_SS}}</td>
                <td>{{element.GRD}}</td>
                <td>{{element.ATMP_NO}}</td>
                <td>{{element.REM}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--added by Mehtab on datd 25-03-2024-->
    <div *ngIf="this.showfeedetails"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">

    </div>
    <!--ended by mehtab-->

    <!-- 
    <div *ngIf="this.Rshowexamsummary" class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0" style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Repeater Exam Summary</i></div>
        <div class="card-body">
          <table class="table table-bordered striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">No.</th>
                <th scope="col">Year</th>
                <th scope="col">Roll Number</th>
                <th scope="col">Session</th>
                <th scope="col">Semester</th>
                <th scope="col">Total Marks</th>
                <th scope="col">Obtained Marks</th>
                <th scope="col">Grade</th>
                <th scope="col">Remarks</th>
                <th scope="col">CGPA</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of Rex_sum; let i =index;">
                <td>{{i+1}}</td>
                <td>{{element.YEAR}}</td>
                <td>{{element.RN}}</td>
                <td>{{element.SESSION}}</td>
                <td>{{element.T_NO}}</td>
                <td>{{element.TOTAL_MRKS}}</td>
                <td>{{element.MRKS}}</td>
                <td>{{element.GRD}}</td>
                <td>{{element.REM}}</td>
                <td>{{element.CGPA}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
 -->

    <div *ngIf="this.showfeedetails && !loader"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Paid
            Challans</i></div>
        <div class="card-body">
          <table class="table table-bordered table-striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Challan #</th>
                <th scope="col">Inst #</th>
                <th scope="col">Due Date</th>
                <th scope="col">Paid Date</th>
                <th scope="col">Total Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let e of paidChallan">
                <td>{{e.ch_no}}</td>
                <td>{{e.inst_no}}</td>
                <td>{{e.due_dt}}</td>
                <td>{{e.paid_date}}</td>
                <td>{{e.debit}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br>
    </div>

    <div *ngIf="this.showfeedetails && !loader"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">Unpaid
            Challans</i></div>
        <div class="card-body">
          <table class="table table-bordered table-striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Challan #</th>
                <th scope="col">Inst #</th>
                <th scope="col">Description</th>
                <th scope="col">Due Date</th>
                <th scope="col">Total Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let e of unpaidChallans">
                <td>{{e.ch_no}}</td>
                <td>{{e.inst_no}}</td>
                <td>{{e.des}}</td>
                <td>{{e.due_dt}}</td>
                <td>{{e.debit}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br>
    </div>

    <div *ngIf="this.showfeedetails && !loader"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0"
      style="overflow: hidden; overflow-x: scroll">
      <div class="card text-left">
        <div class="card-header"><i class="fa fa-line-chart">DUES ADJUSTMENT/
            FEE CONCESSION/ SCHOLARSHIP</i></div>
        <div class="card-body">
          <table class="table table-bordered table-striped m-0 p-0">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Challan#</th>
                <th scope="col">Inst-No</th>
                <th scope="col">Dues Adjustment Description</th>
                <th scope="col">Fee Concession Description</th>
                <th scope="col">Fee Conc Entry Date</th>
                <th scope="col">Scholarship Description</th>
                <th scope="col">Schp Entry Date</th>
                <th scope="col">Dues Adj </th>
                <th scope="col">Fee Conc</th>
                <th scope="col">Schp</th>
                <th scope="col">Concession Amount </th>
                <th scope="col">Actual Amount</th>
                <th scope="col">Payable Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let e of schpFeeConcDuesAdj">
                <td>{{e.ch_no}}</td>
                <td>{{e.inst_no}}</td>
                <td>{{e.dues_adj_des}}</td>
                <td>{{e.fee_conc_des}}</td>
                <td>{{e.fee_conc_dt}}</td>
                <td>{{e.schp_des}}</td>
                <td>{{e.schp_dt}}</td>
                <td>{{e.dues_adj_amnt}}</td>
                <td>{{e.fee_conc_amnt}}</td>
                <td>{{e.schp_dues_amnt}}</td>
                <td>{{e.fee_conc_amnt}}</td>
                <td>{{e.ActAmnt}}</td>
                <td>{{e.payableAmnt}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br>
    </div>