<div class="backdrop">
  <div class="row">
    <div class="col-sm-6  ml-2 mr-2 ml-sm-auto mr-sm-auto">
      <div class="alert-box">

        <div class="card-header shadow-sm">
          <div>Sub Offer Requests</div>
        </div>

        <div class="d-flex justify-content-between mb-1"
          >
          <div class="p-1 bg-danger"></div>
          <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <!-- <h6 class="mb-1">{{s.maj_nm}}</h6> -->
              <small>Requested</small>
            </div>
            <div class="d-flex w-100 justify-content-around" style="align-items: center;">
              <!-- <p>{{s.d_nm}}</p>
              <p>{{s.c_nm}}</p>
              <p>{{s.se}}</p>
              <p>Semester: {{s.t_no}}</p> -->
            </div>
          </div>
        </div>
        <div class="card-body" style="height: 20rem;overflow: hidden;overflow-y: scroll">
          <div class="list-group shadow">

            
          </div>
        </div>


        







        <!-- <form #form='ngForm' action="" class="clearfix" (ngSubmit)="OnChangeClicked(form)">
          
          
          <button class="btn btn-danger float-right" type="submit">Change</button>
          <button class="btn btn-outline-secondary float-right mr-2">Close</button>
        </form> -->
      </div>
    </div>
  </div>
</div>
