import { Component,ElementRef, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SelectList } from '../../shared/model/SelectList.model';
import { ClassService } from '../../shared/services/Class.service';
import { reportsFeeService} from '../../shared/services/reportsFee.service';
import { FeeService} from '../../shared/services/Fee.service';

@Component({
  selector: 'app-class-base-wise-collection-report',
  templateUrl: './class-base-wise-collection-report.component.html',
  styleUrls: ['./class-base-wise-collection-report.component.css'],
  animations: [SlideInFromLeft()]
})
export class ClassBaseWiseCollectionReportComponent implements OnInit {
  classes: Array<SelectList>;
  admBase: Array<SelectList>;
  stdtData: Array<{rolno,c_code,maj_id,prev_mrks,nm,f_nm,b_id,sub_comb,add}>;
  isAdmin: boolean = false;
  @ViewChild('f') formRef: NgForm;
    constructor(
      private authenticationService: AuthenticationService,
      private toastr: ToastrService,
      private classService: ClassService,
      private fee: FeeService,
      private reportsFee: reportsFeeService
    ) {
      this.classes= new Array<SelectList>();
      this.admBase = new Array<SelectList>();
      this.stdtData = new Array<{rolno,c_code,maj_id,prev_mrks,nm,f_nm,b_id,sub_comb,add}>();
      this.isAdmin = this.authenticationService.isAdmin();
     }

     ngOnInit(): void {
      this.classes = [];
      this.classService.getAllClassesFeeServer().subscribe((res: { c_code: number, Des: string }[]) => {
        res?.forEach(entry => {
          this.classes.push({ id: entry.c_code, nm: entry.Des });
        });
      });
      this.onGetAdmissionBase();
    }

    onGetAdmissionBase(){
      this.admBase=[];
      this.fee.getAdmissionBasis().subscribe((res: { b_id: number, b_nm: string }[]) => {
        res?.forEach(entry => {
          this.admBase.push({ id: entry.b_id, nm: entry.b_nm });
        });
      });
    }

    onDownloadReport(){
      this.reportsFee.onCollectionReportClassBaseWise(this.formRef.value).subscribe(res=>{
      });
    }

    OnSubmit(){
    }

    onClear(){
      this.formRef.reset();
    }
    }