import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class GroupService {

    constructor(private http: HttpClient) {
    }

    getGroups(params) {
        return this.http.post(`${baseUrl}/api/group/getGroups`, params);
    }

    getGroupM(params) {
        return this.http.post(`${baseUrl}/api/group/getGroupM`, params);
    }
    
    getGroupsDetail(grp: string) {
        return this.http.get(`${baseUrl}/api/group/getGroupDetails/${grp}`);
    }

    addNewGroup(params){
        return this.http.post(`${baseUrl}/api/group/addNewGroup`,params);
    }

    delGroupMajor(params){
        return this.http.post(`${baseUrl}/api/group/deleteMajor`, params);
    }
    delGroup(params) {
        return this.http.post(`${baseUrl}/api/group/delete`, params);
    }

    //For IDC Groups
    getIDCGroup(c_code:number){
        return this.http.get(`${baseUrl}/api/group/getIDCGroups/${c_code}`);
    }
}