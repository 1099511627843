import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { baseUrl } from './../axios';

@Injectable({
    providedIn: 'root'
})

export class UtilService {

    constructor(private http: HttpClient) {
    }

    getSubjects(d_id: string, c_code: string) {
        return this.http.get(`${baseUrl}/util/getSubjects/${d_id}/${c_code}`);
    }

    getSections(c_code: string, maj_id: string, se_id: string, sub_code: string) {
        return this.http.get(`${baseUrl}/util/getSections/${c_code}/${maj_id}/${se_id}/${sub_code}`);
    }
    getSectionsTerm(c_code: string, t_no: string, se_id: string, sub_code: string) {
        return this.http.get(`${baseUrl}/util/getSectionsTerm/${c_code}/${t_no}/${se_id}/${sub_code}`);
    }

    getEligibilitySections(c_code: string, t_no: string, se_id: string, sub_code: string, type: string,d_id: number) {
        // return this.http.post(`${baseUrl}/util/S_EligibiltySections/${c_code}/${t_no}/${se_id}/${sub_code}/${type}`);
        return this.http.post(`${baseUrl}/util/S_EligibiltySections`, { c_code, t_no, se_id, sub_code, type,d_id });
    }

    getSectionsByTerm(params) {
        return this.http.post(`${baseUrl}/util/getSectionsByTerm`, params);
    }

    insertTimetable(params) {
        return this.http.post(`${baseUrl}/timetable/insert`, params);
    }

    insertRoom(params) {
        return this.http.post(`${baseUrl}/util/insertRoom`, params);
    }

    displayRoomId() {
        return this.http.get(`${baseUrl}/util//Roomid`);
    }

    displayRoom(params) {
        let id: any = {
            'blk_id': params
        }
        return this.http.post(`${baseUrl}/util/displayRooms`, id);
    }


    insertBlock(params) {
        return this.http.post(`${baseUrl}/util/insertBlock`, params);
    }


    insertSubject(params) {
        return this.http.post(`${baseUrl}/util/insertSubject`, params);
    }
    getSubjectNature() {
        return this.http.get(`${baseUrl}/util/getSubjectNature`);
    }

    searchSubjectByCode(sub_code: string) {
        return this.http.get(`${baseUrl}/util/searchSubjectByCode/${sub_code}`);
    }

    searchSubjectByName(name: string) {
        return this.http.get(`${baseUrl}/util/searchSubjectByName/${name}`);
    }

    // Ali new services

    getRollNumbers(se_id: number) {
        return this.http.get(`${baseUrl}/util/getRollNumbers/${se_id}`);
    }

    getSectionChangeInfo(year: string, c_code: string, rn: string, t_no: string, sec: string, sub_code: string) {
        let params = new HttpParams().set('year', year.valueOf()).set('c_code', c_code.valueOf()).set('rn', rn.valueOf()).set('t_no', t_no.valueOf()).set('sec', sec.valueOf()).set('sub_code', sub_code.valueOf());
        return this.http.post(`${baseUrl}/util/getSectionChangeInfo`, params);
    }

    getStudentsBySubCode(se_id: string, c_code: string, maj_id: string, t_no: string, sub_code: string) {
        return this.http.get(`${baseUrl}/util/getStudentsBySubCode/${se_id}/${c_code}/${maj_id}/${t_no}/${sub_code}`);
    }

    getRequestedSubjectsByDept(d_id: string) {
        return this.http.get(`${baseUrl}/util/getRequestedSubjects/${d_id}`);
    }


    getRequestedSubjectsByClass(d_id: string, c_code: string) {
        return this.http.get(`${baseUrl}/util/getRequestedSubjects/${d_id}/${c_code}`);
    }

    getRequestedSubjectsByMaj(d_id: string, c_code: string, maj_id: string) {
        return this.http.get(`${baseUrl}/util/getRequestedSubjects/${d_id}/${c_code}/${maj_id}`);
    }

    updateWholeClassSection(se_id: string, c_code: string, maj_id: string, t_no: String, sub_code: string, sec: string) {
        let params = new HttpParams().set('se_id', se_id.valueOf()).set('c_code', c_code.valueOf()).set('maj_id', maj_id.valueOf()).set('t_no', t_no.valueOf()).set('sub_code', sub_code.valueOf()).set('sec', sec.valueOf());
        return this.http.post(`${baseUrl}/util/updateWholeClassSection`, params);
    }

    getStudentName(grp: string, rn: string, year: string, c_code: string) {
        return this.http.get(`${baseUrl}/util/getStudentName/${grp}/${rn}/${year}/${c_code}`);
    }

    sectionAllocation(year: string, se_id: string, c_code: string, maj_id: string, t_no: string, rol: String, sub_code: string, sec: string) {
        return this.http.post(`${baseUrl}/util/sectionAllocation`, {
            year, c_code, se_id, maj_id, t_no, rol, sub_code, sec
        });
    }

    idcAllocation(year: string, se_id: string, c_code: string, maj_id: string, t_no: string, rol: string, sub_code: string, sec: string) {
        return this.http.get(`${baseUrl}/util/getIdcAllocation/${year}/${se_id}/${c_code}/${maj_id}/${t_no}/${rol}/${sub_code}/${sec}`);
    }

    //AWARD LIST FOR
    getOfferedSub(c_code: String, maj_id: string, se_id, t_no: string) {
        return this.http.get(`${baseUrl}/util/getOfferedSub/${c_code}/${maj_id}/${se_id}/${t_no}`);
    }
    // need to change it in 2 componenets.

    getSect(c_code: string, se_id: string, t_no: string, sub_code: string, maj_id: string) {
        return this.http.get(`${baseUrl}/util/getSect/${c_code}/${se_id}/${t_no}/${sub_code || 'null'}/${maj_id || 0}`);

    }
    // getSect(c_code:string,se_id:string,t_no:string,maj_id:number){
    //     // return this.http.get(`${baseUrl}/util/getSect/${c_code}/${se_id}/${t_no}/${sub_code||'null'}`);
    //     return this.http.get(`${baseUrl}/util/getSect/${c_code}/${se_id}/${t_no}/${maj_id}`);
    // }
    getDptNmOfUsr(d_id: string) {
        return this.http.get(`${baseUrl}/util/getDptNmOfUsr/${d_id}`);
    }

    //Exam Result API  
    getClasses() {
        return this.http.get(`${baseUrl}/api/class/getTmClasses/`);
    }
    getSessions(cls) {
        return this.http.get(`${baseUrl}/api/session/getSessionsFromTm/${cls}`);
    }
    exam_award_list(params) {
        return this.http.post(`${baseUrl}/api/result/exam_award_list`, params);
    }
    EXAM_RESULT_SECTION(params) {
        return this.http.post(`${baseUrl}/api/result/EXAM_RESULT_SECTION`, params);
    }
    EXAM_RESULT_SECTION_maj(params) {
        return this.http.post(`${baseUrl}/api/result/EXAM_RESULT_SECTION`, params);
    }
    Delete_RESULT_SECTION(params) {
        return this.http.post(`${baseUrl}/api/result/deleteCompiledResult`, params);
    }

    getDTeachers(params) {
        return this.http.post(`${baseUrl}/api/result/getDTeachers`, params);
    }

    getRTeachers(params) {
        return this.http.post(`${baseUrl}/api/result/getRTeachers`, params);
    }

    getAnn(fm_id) {
        return this.http.get(`${baseUrl}/api/announcement/intimate/${fm_id.FM_ID}/${fm_id.Sub_code}/${fm_id.Section}`);
    }

    getDesignation() {
        return this.http.get(`${baseUrl}/util/getDesignation`);
    }

    getReligion() {
        return this.http.get(`${baseUrl}/util/getReligion`);
    }

    getDB_Detail(DB_type: any, routine_type: any) {
        return this.http.post(`${baseUrl}/util/getDB_Detail`, {DB_type, routine_type});
    }

    S_insert_routine(routine_name: any, mode: any) {
        return this.http.post(`${baseUrl}/util/S_insert_routine`, {routine_name, mode});
    }

    getCPU_Consumption() {
        return this.http.post(`${baseUrl}/util/getCPU_Consumption`, {});
    }
}