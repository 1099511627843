import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../shared/model/SelectList.model';
import { MajorService } from '../shared/services/Major.service';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';
export interface MajorData{
  C_CODE:number,
  D_NM:string,
  GEN_MAJ:string,
  MAJ_ID:number,
  TITLE:string
}
@Component({
  selector: 'app-update-major-nm',
  templateUrl: './update-major-nm.component.html',
  styleUrls: ['./update-major-nm.component.css'],
  animations:[
    SlideInFromLeft()
  ]
})
export class UpdateMajorNmComponent implements OnInit {
  classes: Array<SelectList>;
  majors:Array<MajorData>;
  @ViewChild('f') formRef: NgForm;
  updateMood:boolean=false;
  Obj = null;
  content:string='';
  changeIndex:number=-1;
  loader: boolean = false;
  modal: boolean = false;
  index: number;
  constructor(
    private majorService:MajorService,
    private _toaster:ToastrService,
    private lmsSharedService: LMSharedDataService) { 
    this.classes = new Array<SelectList>();
    this.majors = new Array<MajorData>();
  }
  ngOnInit(): void {
    this.loader = false;
    this.updateClasses();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting lms classes", error);
      });
  }

  OnSubmit(){
    this.changeIndex=-1;
    this.updateMajors();
  }

  updateMajors() {
    this.majors = [];
    this.loader = true;
    this.modal = false;
    this.updateMood = false;
    this.majorService.getMajorsWithTitle(this.formRef.value.c_code).subscribe((res:MajorData[]) => {      
      if(res.length!=0)
        this.majors=res;
      else
        this._toaster.info('No Majors Available.');
      this.loader = false
    });
  }
  
  onUpdateQuestion(q,index:number){
    this.Obj=q;
    this.content = this.Obj.TITLE;
    this.changeIndex=index;
    // this.modal = !this.modal; 
    // this.updateMood=!this.updateMood;
  }

  onupdateSubmit(form:NgForm){
    this.majorService.updateMajorTitle({c_code:this.Obj.C_CODE, maj_id:this.Obj.MAJ_ID,title:form.value.majorName}).subscribe(res=>{
      if(res){
        this._toaster.success('Updated');
        this.majors[this.changeIndex].TITLE=form.value.majorName;
        this.changeIndex=-1;
        this.modal = !this.modal; 
        this.updateMood=!this.updateMood;
      }
    }, err => {
      console.log(err);
      this._toaster.error("Some error Occured");
    })
  }

  Filter()
  {
   filter();
  }

  openModal(i){
    this.index = i;
    this.updateMood=!this.updateMood;
    this.modal = !this.modal; 
  }
}
