import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
import { param } from 'jquery';
@Injectable({
    providedIn: 'root'
})

export class TeacherService {

    constructor(private http: HttpClient) {
    }

    insertTeacher(params) {
        return this.http.post(`${baseUrl}/api/teacher/insert`, params);
    }

    getTeachers(d_id: number) {
        return this.http.get(`${baseUrl}/api/teacher/getTeachers/${d_id}`);
    }
    OgetTeachers(d_id: number,grp: number,c_code: number,se_id: number,t_no: number,sub_code: string,section:string) {
        return this.http.get(`${baseUrl}/api/teacher/OgetTeachers/${d_id}/${grp}/${c_code}/${se_id}/${t_no}/${sub_code}/${section}`);
    }

    O_getsection(d_id: number,grp: number,c_code: number,se_id: number,t_no: number,sub_code: string) {
        return this.http.get(`${baseUrl}/api/teacher/Ogetsection/${d_id}/${grp}/${c_code}/${se_id}/${t_no}/${sub_code}`);
    }

    S_UpdateFM_info(param){
        return this.http.post(`${baseUrl}/api/teacher/S_update_fm_info`, param);

    }




    getInfo(param) {
        return this.http.post(`${baseUrl}/api/teacher/getInfo`, param);
    }

    getTeacherInformation(fm_id, d_id) {
        return this.http.get(`${baseUrl}/api/teacher/getTeacherInformation/${fm_id}/${d_id}`);
    }


    updateTeacherInformation(params) {
        return this.http.post(`${baseUrl}/api/teacher/updateInfofm`, params);
    }

    updateNIC(param) {
        return this.http.post(`${baseUrl}/api/teacher/updateUsername`, param);
    }

    checkTeacherAttendance(d_id) {
        return this.http.get(`${baseUrl}/api/teacher/checkTeacherAttendance/${d_id}`);
    }
    getTmTeacher(d_id: number) {
        return this.http.get(`${baseUrl}/api/teacher/getTmTeacher/${d_id}`);
    }
    getTeacherTimeTavle(params) {
        return this.http.post(`${baseUrl}/api/teacher/getTeacherTimeTavle`,params);
    }
    getAttendanceReportReport(params) {
        return this.http.post(`${baseUrl}/api/teacher/getAttendanceReportReport`,params);
    }
    getAssessmentReportReport(params) {
        return this.http.post(`${baseUrl}/api/teacher/getAssessmentReportReport`,params);
    }

    //user detail
   getuserdetail() {
        return this.http.post(`${baseUrl}/util/UserDetail`,{});
    }

    getstudentdetail(param) {
        return this.http.post(`${baseUrl}/util/studentdetail`,param);
    }
    getadmyear() {
        return this.http.post(`${baseUrl}/util/adm_stdt_year`,{});
    }
    
    deleteuser(param){
        return this.http.post(`${baseUrl}/util/deleteuser`,param);
      }
      deletestudent(param){
        return this.http.post(`${baseUrl}/util/deletestudent`,param);
      }
      userloc(param){
        return this.http.post(`${baseUrl}/util/userloc`,param);
      }
      userunloc(param){
        return this.http.post(`${baseUrl}/util/userunloc`,param);
      }
      studentloc(param){
        return this.http.post(`${baseUrl}/util/studentloc`,param);
      }
      studentunloc(param){
        return this.http.post(`${baseUrl}/util/studentunloc`,param);
      }

}