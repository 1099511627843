import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../shared/model/SelectList.model';
import { DatePipe } from '@angular/common';
import { EnrollmentService } from '../shared/services/Enrollment.service';
import { jsPDF } from "jspdf";
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { filter } from 'src/app/shared/functions/tableSearch';

@Component({
  selector: 'app-studentdetail',
  templateUrl: './studentdetail.component.html',
  styleUrls: ['./studentdetail.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class StudentdetailComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;
  SharedInfo: Array<{rolno:string}>
  class: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  c_code: string;
  maj_id:string;
  se_id: string;
  c_nm: string;
  heading: string;
  sub_comb: any[];
  classes: any;
  student_table:Array<any>;
  stdDet:boolean=false;
  rolno:any
  c_code_in:any
  showInput: boolean = false;
  loader: boolean;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>this screen displays students personal details,timetable,subject offered and enrollment of students against
   their class ,major and particular session</h6>
  <h6 class="text-right">اس اسکرین میں طلباء کی ذاتی تفصیلات، ٹائم ٹیبل، آفر کیے ہوئے مضامین اور طلباء کی کلاس،  میجر اور  سیشن میں ان کی انرولمنٹ  دکھائی جاتی ہے۔</h6>
  `
  ;


  constructor( private authenticationService: AuthenticationService,
    private toastr: ToastrService, 
    private datePipe: DatePipe,
    private EnrollmentService:EnrollmentService,
    private lmsSharedService: LMSharedDataService) {
    this.class = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.student_table=[];
   }

  ngOnInit(): void {
    this.updateClasses();
    this.loader = false;
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.class = classes;
      })
      .catch(error => {
        console.error("Error getting lms classes", error);
      });
  }

  showTable() {
    this.showInput = true;
  }

  updateMajors() {
    this.majors.length = 0;
    this.lmsSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
        this.updateSessions();
        this.stdDet=false;
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  updateSessions() {
    this.lmsSharedService.filterSessionByCode(this.formRef.value.c_code)
      .then(session => {
        this.sessions = session
        if (this.formRef) {
          const initialValue = this.sessions[0]?.id;
          this.formRef?.controls['se_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }

  getstudent(){
    this.student_table=[];
    this.loader = true;
    let i=0
    this.EnrollmentService.getClassStudentsForEnrollment(this.formRef.value.c_code,this.formRef.value.maj_id,this.formRef.value.se_id).subscribe((res:any)=>{
      res[i].forEach(el=>{
        this.student_table.push(el)
        this.showTable();
      })
      this.loader = false;
    })
  }

  getDetail(rolno_in){
    this.stdDet=true;
    this.rolno=rolno_in;
    this.c_code_in=this.formRef.value.c_code;
  }

  CloseStd()
  {
    this.stdDet=false;
  } 
  
  OnCreatePDF() {
    this.toastr.info('Generating pdf...', null, { timeOut: 120000 });
    const body = [];
    const header = [];

    let k = 0;
    for (let i = 0; i < this.student_table.length; i++) {
      k++;
      body.push([k,
        this.student_table[i].rn,
        this.student_table[i].rolno,
        this.student_table[i].nm,
        this.student_table[i].f_nm,
        this.student_table[i].set_no,
      ]);
    }
    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y,h:mm a')
    var image = new Image();
    var page;

    let to = this.formRef.value.t_no;
    if (this.formRef.value.t_no == 9) {
      to = 'COMPLETE';
    }
    image.src = '../../../assets/images/logo3.png';
    header.push(['Sr #', 'RN' , 'Roll No', 'Student Name','Father Name','Set No']);
    const doc = new jsPDF();
    autoTable(doc, {
      margin: { top: 56, bottom: 10 },
      styles: { fontSize: 10 },
      head: header,
      body: body,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontSize: 9.5,
        fontStyle: 'bold',
        halign: 'left'
      },
      bodyStyles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        fontSize: 9.2,

      },
      columnStyles: {
        0: { halign: "center" },
        1: { halign: "left" },
        2: { halign: "left" },
        3: { halign: "left" },
        4: { halign: "left" },
        5: { halign: "center" },
      },
      didDrawPage: function () {
        doc.addImage(image, 177, 10, 25, 25);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 172, 38);
        doc.setFontSize(20);
        doc.setFont('Lobster');
        doc.text("GC UNIVERSITY LAHORE", 100, 25, { align: 'center' });
        doc.setFontSize(16);
        doc.text("Office of the Controller of Examination", 100, 35, { align: 'center' });
        doc.setFontSize(16);
        doc.setFontSize(10);
        page = doc.getNumberOfPages();
        doc.setFontSize(12);
        doc.text("Note:- Errors and Omissions Excepted", 135, 288);
        doc.text("Directorate of Information Technology", 8, 288);
      }
    });
    var l = (body.length + 65) % 26;
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 90, 288);
    }
    this.toastr.clear();
    //doc.save(`StudentCount `);
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');

  }
  
  Filter()
  {
    filter();
  }
}
  

