import { Injectable } from '@angular/core';
import { ClassService } from '../main/shared/services/Class.service';
import { FeeService } from '../main/shared/services/Fee.service';
import { MajorService } from '../main/shared/services/Major.service';

@Injectable({
    providedIn: 'root',
})
export class FeeSharedDataService {
    private classes: any[] = [];
    private instNo: any[] = [];
    private majors: any[] = [];
    private sessions: any[] = [];
    private boards: any[] = [];
    private adminBases: any[] = [];
    private banks: any[] = [];
    private feeAcc: any[] = [];
    private feeSchpType: any[] = [];
    private feeSchpCateg: any[] = [];
    private updateClassPromise: Promise<any> | null = null;
    private updateInstPromise: Promise<any> | null = null;
    private updateMajorPromise: Promise<any> | null = null;
    private updateSessionPromise: Promise<any> | null = null;
    private updateBoardPromise: Promise<any> | null = null;
    private updateBasesPromise: Promise<any> | null = null;
    private updateBankPromise: Promise<any> | null = null;
    private updateFeeAccPromise: Promise<any> | null = null;
    private updateSchpType: Promise<any> | null = null;
    private updateSchpCateg: Promise<any> | null = null;

    constructor(
        private classService: ClassService, 
        private feeService: FeeService, 
        private majorService: MajorService
        ) { this.initialize(); }

    initialize() {
        this.updateFeeMajor();
        this.updateFeeMajor();
        this.updateFeeInst();
        this.updateFeeSessions();
        this.updateAdmissionBases();
    }

    updateFeeClasses(): Promise<any[]> {
        if (!this.updateClassPromise) {
            this.updateClassPromise = new Promise((resolve, reject) => {
                this.classService.getAllClassesFeeServer().subscribe(
                    (res: { c_code: number, Des: string }[]) => {
                        this.classes = res?.map(entry => ({ id: entry.c_code, nm: entry.Des })) || [];
                        this.updateClassPromise = null; // Reset the promise
                        resolve(this.classes);
                    },
                    error => {
                        console.error("Error fetching classes", error);
                        this.updateClassPromise = null; // Reset the promise on error
                        reject(error);
                    }
                );
            });
        }
        return this.updateClassPromise;
    }

    getFeeClasses(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.classes.length === 0) {
                this.updateFeeClasses()
                    .then((updatedClasses) => {
                        resolve(updatedClasses);
                    })
                    .catch((error) => {
                        console.error("Error updating fee classes", error);
                        resolve([]);
                    });
            } else {
                resolve(this.classes);
            }
        });
    }

    updateFeeInst(): Promise<any[]> {
        if (!this.updateInstPromise) {
            this.updateInstPromise = new Promise((resolve, reject) => {
                this.feeService.getInstallmentNo().subscribe((res: { inst_no }[]) => {
                    if (res) {
                        for (let i = 0; i < res.length; i++) {
                            this.instNo.push(res[i].inst_no)
                        }
                        this.updateInstPromise = null; // Reset the promise
                        resolve(this.instNo);
                    }
                }, error => {
                    console.error("Error fetching installments", error);
                    this.updateInstPromise = null; // Reset the promise on error
                    reject(error);
                });
            });
        }

        return this.updateInstPromise;
    }

    getFeeInst(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.instNo.length === 0) {
                this.updateFeeInst()
                    .then((updateInstallment) => {
                        resolve(updateInstallment);
                    })
                    .catch((error) => {
                        console.error("Error updating fee inst_no", error);
                        resolve([]);
                    });
            } else {
                resolve(this.instNo);
            }
        });
    }

    updateFeeMajor(): Promise<any[]> {
        if (!this.updateMajorPromise) {
            this.updateMajorPromise = new Promise((resolve, reject) => {
                this.majorService.getMajorFeeServer({ c_code: '0' }).subscribe((res: any) => {
                    this.majors = res;
                    resolve(this.majors);
                }, error => {
                    console.error("Error fetching majors", error);
                    this.updateMajorPromise = null; // Reset the promise on error
                    reject(error);
                });
            });
        }
        return this.updateMajorPromise;
    }

    getFeeMajor(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.majors.length === 0) {
                this.updateFeeMajor()
                    .then((updateMajors) => {
                        resolve(updateMajors);
                    })
                    .catch((error) => {
                        console.error("Error updating fee majors", error);
                        resolve([]);
                    });
            } else {
                resolve(this.majors);
            }
        });
    }

    filterMajorByCode(c_code: any): Promise<any[]> {
        return new Promise((resolve) => {
            this.getFeeMajor()
                .then(majors => {
                    const majVal = majors
                        .filter(major => major.C_CODE == c_code)
                        .map(filteredMajor => ({ id: filteredMajor.MAJ_ID, nm: filteredMajor.MAJ_NM }));
                    resolve(majVal);
                })
                .catch(error => {
                    console.error("Error getting majors", error);
                    resolve([])
                });
        }
        )
    }

    updateFeeSessions(): Promise<any[]> {
        if (!this.updateSessionPromise) {
            this.updateSessionPromise = new Promise((resolve, reject) => {
                this.feeService.getSessionYear().subscribe((res: any) => {
                    res.forEach(element => {
                      this.sessions.push(element.SE_ST_YEAR)
                    });
                    resolve(this.sessions);
                  }, error => {
                    console.error("Error fetching sessions", error);
                    this.updateSessionPromise = null; // Reset the promise on error
                    reject(error);
                });
            });
        }
        return this.updateSessionPromise;
    }

    getFeeSessions(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.sessions.length == 0) {
                this.updateFeeSessions()
                    .then((updateSessions) => {
                        resolve(updateSessions);
                    })
                    .catch((error) => {
                        console.error("Error updating fee sessions", error);
                        resolve([]);
                    });
            } else {
                resolve(this.sessions);
            }
        });
    }

    updateFeeBoards(): Promise<any[]> {
        if (!this.updateBoardPromise) {
            this.updateBoardPromise = new Promise((resolve, reject) => {
                this.feeService.getSchpBoard().subscribe((res: { brd_id: number, brd_nm: string }[]) => {
                    res.forEach(entry => {
                      this.boards.push({ id: entry.brd_id, nm: entry.brd_nm })
                    });
                    resolve(this.boards);
                  }, error => {
                    console.error("Error fetching boards", error);
                    this.updateBoardPromise = null; // Reset the promise on error
                    reject(error);
                });
            });
        }
        return this.updateBoardPromise;
    }

    getFeeBoards(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.boards.length == 0) {
                this.updateFeeBoards()
                    .then((updateBoards) => {
                        resolve(updateBoards);
                    })
                    .catch((error) => {
                        console.error("Error updating fee boards", error);
                        resolve([]);
                    });
            } else {
                resolve(this.boards);
            }
        });
    }

    updateAdmissionBases(): Promise<any[]> {
        if (!this.updateBasesPromise) {
            this.updateBasesPromise = new Promise((resolve, reject) => {
                this.feeService.getAdmissionBasis().subscribe((res: { b_id: number, b_nm: string }[]) => {
                    res.forEach(entry => {
                      this.adminBases.push({ id: entry.b_id, nm: entry.b_nm})
                    });
                    resolve(this.adminBases);
                  }, error => {
                    console.error("Error fetching adminBases", error);
                    this.updateBasesPromise = null; // Reset the promise on error
                    reject(error);
                });
            });
        }
        return this.updateBasesPromise;
    }

    getAdmissionBases(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.adminBases.length == 0) {
                this.updateAdmissionBases()
                    .then((updateBase) => {
                        resolve(updateBase);
                    })
                    .catch((error) => {
                        console.error("Error updating fee Admission Bases", error);
                        resolve([]);
                    });
            } else {
                resolve(this.adminBases);
            }
        });
    }

    updateFeeBanks(): Promise<any[]> {
        if (!this.updateBankPromise) {
            this.updateBankPromise = new Promise((resolve, reject) => {
                this.feeService.getAllBanks().subscribe((res: { bank_id: number, s_nm: string, d_nm: string }[]) => {
                    res.forEach(entry => {
                      this.banks.push({ id: entry.bank_id, nm: entry.s_nm })
                    });
                    resolve(this.banks);
                  }, error => {
                    console.error("Error fetching banks", error);
                    this.updateBankPromise = null; // Reset the promise on error
                    reject(error);
                });
            });
        }
        return this.updateBankPromise;
    }

    getFeeBanks(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.banks.length == 0) {
                this.updateFeeBanks()
                    .then((updateBanks) => {
                        resolve(updateBanks);
                    })
                    .catch((error) => {
                        console.error("Error updating fee Banks", error);
                        resolve([]);
                    });
            } else {
                resolve(this.banks);
            }
        });
    }

    updateFeeAcc(): Promise<any[]> {
        if (!this.updateFeeAccPromise) {
            this.updateFeeAccPromise = new Promise((resolve, reject) => {
                this.feeService.getFeeDescription().subscribe((res: { fee_id: number, fee_nm: string }[]) => {
                    res.forEach(entry => {
                      this.feeAcc.push({ fee_id: entry.fee_id, fee_nm: entry.fee_nm })
                    });
                    resolve(this.feeAcc);
                  }, error => {
                    console.error("Error fetching feeAcc", error);
                    this.updateFeeAccPromise = null; // Reset the promise on error
                    reject(error);
                });
            });
        }
        return this.updateFeeAccPromise;
    }

    getFeeAcc(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.feeAcc.length == 0) {
                this.updateFeeAcc()
                    .then((updateFeeAcc) => {
                        resolve(updateFeeAcc);
                    })
                    .catch((error) => {
                        console.error("Error updating fee description", error);
                        resolve([]);
                    });
            } else {
                resolve(this.feeAcc);
            }
        });
    }

    updateFeeSchpType(): Promise<any[]> {
        if (!this.updateSchpType) {
            this.updateSchpType = new Promise((resolve, reject) => {
                this.feeService.getSchpType().subscribe((res: { schp_type_id: number, schp_type_des: string }[]) => {
                    res.forEach(entry => {
                      this.feeSchpType.push({ id: entry.schp_type_id, nm: entry.schp_type_des })
                    });
                    resolve(this.feeSchpType);
                  }, error => {
                    console.error("Error fetching feeAcc", error);
                    this.updateSchpType = null; // Reset the promise on error
                    reject(error);
                });
            });
        }
        return this.updateSchpType;
    }

    getFeeSchpType(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.feeSchpType.length == 0) {
                this.updateFeeSchpType()
                    .then((updateSchpType) => {
                        resolve(updateSchpType);
                    })
                    .catch((error) => {
                        console.error("Error updating fee Schp Types", error);
                        resolve([]);
                    });
            } else {
                resolve(this.feeSchpType);
            }
        });
    }

    // updateFeeSchpCateg(): Promise<any[]> {
    //     if (!this.updateSchpCateg) {
    //         this.updateSchpCateg = new Promise((resolve, reject) => {
    //             this.feeService.getSchpCat(0).subscribe((res: { schp_cat_id: number, schp_cat_des: string }[]) => {
    //                 res.forEach(entry => {
    //                     this.feeSchpCateg.push({ id: entry.schp_cat_id, nm: entry.schp_cat_des })
    //                   });
    //                 resolve(this.feeSchpCateg);
    //             }, error => {
    //                 console.error("Error fetching Categories", error);
    //                 this.updateSchpCateg = null; // Reset the promise on error
    //                 reject(error);
    //             });
    //         });
    //     }
    //     return this.updateSchpCateg;
    // }

    // getFeeSchpCateg(): Promise<any[]> {
    //     return new Promise((resolve) => {
    //         if (this.feeSchpCateg.length === 0) {
    //             this.updateFeeSchpCateg()
    //                 .then((updateCateg) => {
    //                     resolve(updateCateg);
    //                 })
    //                 .catch((error) => {
    //                     console.error("Error updating fee categories", error);
    //                     resolve([]);
    //                 });
    //         } else {
    //             resolve(this.feeSchpCateg);
    //         }
    //     });
    // }

    // filterSchpCategByCode(schpType: any): Promise<any[]> {
    //     return new Promise((resolve) => {
    //         this.getFeeSchpCateg()
    //             .then(categ => {
    //                 const categVal = categ
    //                     .filter(category => category.id == schpType)
    //                     .map(filteredCateg => ({ id: filteredCateg.id, nm: filteredCateg.nm }));
    //                 resolve(categVal);
    //             })
    //             .catch(error => {
    //                 console.error("Error getting majors", error);
    //                 resolve([])
    //             });
    //     }
    //     )
    // }
}
