import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SelectList } from '../shared/model/SelectList.model';
import { FeeService } from '../shared/services/Fee.service';
import { DatePipe } from '@angular/common';
import jsPDF from 'jspdf';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';
import { Subscription } from 'rxjs';
import { filter } from 'src/app/shared/functions/tableSearch';

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}
interface Student {
  sr_no: number,
  nm: string,
  f_nm: string,
  ch_no: number,
  num: string,
  dat: string,
  inst_no: string,
  maj: string,
  status: string,
}

interface AdmBase {
  bid: number;
  b_nm: string;
}

@Component({
  selector: 'app-fee-report',
  templateUrl: './fee-report.component.html',
  styleUrls: ['./fee-report.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class FeeReportComponent implements OnInit {
  subscription = new Subscription();
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  StudentInfo: Array<Student>;
  groups: Array<SelectList>;
  admBase: Array<SelectList>;
  sessions: Array<SelectList>;
  meritList: any;
  isAdmin: boolean = false;
  group: number;
  showBorder: boolean = false;
  checking1: Array<any>;
  checking2: Array<string>;
  hideBtn: boolean;
  groupName;
  className = '';
  baseName = '';
  subOrNot;
  loader = false;

  @ViewChild('f') formRef: NgForm;
  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private feeService: FeeService,
    private datePipe: DatePipe,
    private feeSharedService: FeeSharedDataService

  ) {
    this.classes = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.StudentInfo = new Array<Student>();
    this.checking1 = new Array<any>();
    this.checking2 = new Array<string>();
    this.admBase = new Array<SelectList>();

    this.groups = new Array<SelectList>();
    this.isAdmin = this.authenticationService.isAdmin();
    this.group = this.authenticationService.getGroup();
    this.hideBtn = false;
    this.groupName = '';
    this.subOrNot = 0;
  }
  ngOnInit(): void {
    this.updateClasses();
    this.updateSessions();
    this.updateMeritList();
  }

  OnSubmit() {
    if (!this.formRef.valid)
      return;
  }

  updateClasses() {
    this.classes = [];
    this.feeSharedService.getFeeClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateMajors() {
    this.majors.length = 0;
    this.feeSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  updateSessions() {
    this.sessions = []
    this.feeSharedService.getFeeSessions()
      .then(sessions => {
        this.sessions = sessions;
      })
      .catch(error => {
        console.error("Error getting fee session", error);
      });
  }

  OnMajorChange(m: HTMLSelectElement) {
    this.groupName = this.majors[m.selectedIndex].nm;
  }

  NullField() {
    this.StudentInfo = []
  }

  OnClassChange(c: HTMLSelectElement) {
    this.StudentInfo = []
    this.className = this.classes[c.selectedIndex].nm;
    // this.updateMajors();
  }

  onBaseChange(b: HTMLSelectElement) {
    this.baseName = this.admBase[b.selectedIndex].nm;
    this.StudentInfo = [];
  }

  onInstallmentChange() {
    this.StudentInfo = [];
    this.updateAdmissionBasis();
  }

  updateAdmissionBasis() {
    this.admBase = []
    this.feeSharedService.getAdmissionBases()
      .then(adminBases => {
        this.admBase = adminBases;
      })
      .catch(error => {
        console.error("Error getting Admission Bases", error);
      });
  }

  updateMeritList() {
    this.feeSharedService.getFeeInst()
      .then(inst => {
        this.meritList = inst;
      })
      .catch(error => {
        console.error("Error getting inst", error);
      });
  }

  onDuesReport() {
    this.StudentInfo = [];
    this.loader = true;
    this.subscription.add(this.feeService.onGetDuesReport(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.year, this.formRef.value.inst_no, this.formRef.value.b_id || 0, this.formRef.value.m_list || 0).
      subscribe((res: { sr_no: number, nm: string, f_nm: string, ch_no: number, num: string, paid_date: string, inst_no: string, maj: string, status: string }[]) => {
        res?.forEach(entry => {
          this.StudentInfo.push({ sr_no: entry.sr_no, nm: entry.nm, f_nm: entry.f_nm, ch_no: entry.ch_no, num: entry.num, dat: entry.paid_date, inst_no: entry.inst_no, maj: entry.maj, status: entry.status });
        });
        if (!res) {
          this.toastr.error("Unknown Error");
          this.loader = false
        }
        else {
          this.toastr.info(res.length + ' Record/s Fetched!');
        }
        this.loader = false
      }
        , err => {
          console.log(err);
          this.loader = false
          this.toastr.error("Some Error Occured! Maybe Internet Issue. Please Refresh");
        }
      ));
  }

  ClearForm(fn: NgForm) {
    fn.resetForm();
    this.StudentInfo = []
    this.loader = false
  }

  CreatePDF() {
    if (this.StudentInfo?.length == 0) {
      this.toastr.warning("No Record Found");
      this.loader = false
      return;
    }
    else {
      this.toastr.success("Generating PDF")
      const body = [];
      const header = [];
      for (let i = 0; i < this.StudentInfo.length; i++) {
        body.push([
          { content: i + 1, styles: { fontStyle: "bold", halign: 'center' } },
          { content: this.StudentInfo[i].ch_no, styles: { fontStyle: "bold", halign: 'center' } },
          { content: this.StudentInfo[i].num, styles: { fontStyle: "bold" } },
          this.StudentInfo[i].maj,
          { content: this.StudentInfo[i].inst_no, styles: { halign: 'center' } },
          this.StudentInfo[i].nm,
          this.StudentInfo[i].f_nm,
          this.datePipe.transform(this.StudentInfo[i].dat, 'dd-MM-yyyy'),
        ]);
      }
      var exportDate = this.datePipe.transform(new Date(), 'MMM d, y')
      var image = new Image();
      var page;
      var meritNo = this.formRef.value.m_list, subVar = this.subOrNot, classVar = this.className, majVar = this.groupName, baseVar = this.baseName;
      var sessionYear = this.formRef.value.year;
      image.src = '../../../assets/images/logo3.png';
      if (this.subOrNot == 1)
        header.push(['Sr#', 'Challan#', 'Form/Roll#', 'Major', 'Inst#', 'Name', 'Father Name', 'Paid Date']);
      else
        header.push(['Sr#', 'Challan#', 'Form/Roll#', 'Major', 'Inst#', 'Name', 'Father Name', 'Due Date']);
      const doc = new jsPDF('p', 'mm', 'A4');

      autoTable(doc, {
        theme: 'grid',
        startY: 50,
        margin: { top: 50, bottom: 10, left: 7, right: 7 },
        head: header,
        body: body,
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0], // sets the text color of the header to black
          lineColor: [0, 0, 0],
          lineWidth: 0.1, // sets the width of the border to 0.2
          fontSize: 8,
          fontStyle: 'bold'
        }, // sets the border color of the header to black
        bodyStyles: {
          lineWidth: 0.1, // sets the width of the border to 0.2
          lineColor: [0, 0, 0], // sets the color of the border to black
          fontSize: 7,
        },
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 16 }
        },
        didDrawPage: function () {
          let height = 20;
          if (meritNo != null)
            height = 10
          doc.addImage(image, 175, 10, 25, 30);
          doc.setFontSize(10);
          doc.text(`${exportDate}`, 177, 45);
          doc.setFontSize(20);
          doc.setFont('Helvetica', 'bold');
          doc.text("GC UNIVERSITY, LAHORE", 105, height, { align: 'center' });
          doc.setFontSize(14);
          doc.setFont('Helvetica', 'bold');
          if (subVar == 1) {

            doc.text("SUBMISSION OF DUES REPORT", 105, height + 9, { align: 'center' });
            doc.text("____________________________", 105, height + 9, { align: 'center' });
          }
          if (subVar == 2) {
            doc.text("NON SUBMISSION OF DUES REPORT", 105, height + 9, { align: 'center' });
            doc.text("_________________________________", 105, height + 9, { align: 'center' });
          }
          doc.setFontSize(12);
          // doc.text("Merit list Summary", 150, 26, { align: 'center' });
          doc.text("CLASS: " + classVar, 105, height + 17, { align: 'center' });
          // doc.text("MAJOR: "+majVar, 105, height+12, { align: 'center' });
          doc.text("SESSION: " + sessionYear, 105, height + 24.5, { align: 'center' });
          if (meritNo != null) {
            doc.text("BASE: " + baseVar, 105, height + 31.5, { align: 'center' });
            doc.text("MERIT LIST: " + meritNo, 105, height + 38.5, { align: 'center' });
          }
          page = '';
          page = doc.getNumberOfPages();
        }
      });
      var l = (body.length + 65) % 26;
      doc.setFontSize(7);
      for (var i = 1; i <= page; i++) {
        doc.setPage(i);
        doc.text('Page ' + i + " of " + page, 99, 294);
        doc.text("Directorate of Information & Technology", 10, 295)
        doc.text("Note:- Errors and Omissions Excepted", 150, 295);
        var p = body.length;
      }
      window.open(URL.createObjectURL(doc.output('blob')), '_blank');
    }
  }


  onSubDuesReport(num) {
    this.loader = true
    this.subOrNot = num;
    this.StudentInfo = [];
    this.feeService.onGetSubDuesReport(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.year,
      this.formRef.value.inst_no, this.formRef.value.b_id || 0, this.formRef.value.m_list || 0).
      subscribe((res: { sr_no: number, nm: string, f_nm: string, ch_no: number, num: string, dat: string, inst_no: string, maj: string, status: string }[]) => {
        if (!res) {
          this.toastr.warning('No Record(s) Found!');
          this.loader = false
          return;
        }
        else {
          res?.forEach(entry => {
            this.StudentInfo.push({ sr_no: entry.sr_no, nm: entry.nm, f_nm: entry.f_nm, ch_no: entry.ch_no, num: entry.num, dat: entry.dat, inst_no: entry.inst_no, maj: entry.maj, status: entry.status });
          });
          this.StudentInfo.sort((a: Student, b: Student) => a.sr_no - b.sr_no);
          this.hideBtn = true;
          this.toastr.info(res.length + ' Record/s Fetched!');
        }
        this.loader = false
      }
        , err => {
          console.log(err);
          this.loader = false
          this.toastr.error("Some Error Occured! Maybe Internet Issue. Please Refresh");
        }
      );
  }

  onNonSubDuesReport(num) {
    this.loader = true;
    this.subOrNot = num;
    this.StudentInfo = [];
    this.feeService.onGetNonSubDuesReport(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.year, this.formRef.value.inst_no, this.formRef.value.b_id || 0, this.formRef.value.m_list || 0).subscribe((res: { sr_no: number, nm: string, f_nm: string, ch_no: number, num: string, dat: string, inst_no: string, maj: string, status: string }[]) => {
      if (!res) {
        this.toastr.error("Unknown Error");
        this.loader = false
      }
      else {
        res?.forEach(entry => {
          this.StudentInfo.push({ sr_no: entry.sr_no, nm: entry.nm, f_nm: entry.f_nm, ch_no: entry.ch_no, num: entry.num, dat: entry.dat, inst_no: entry.inst_no, maj: entry.maj, status: entry.status });
        });
        this.StudentInfo.sort((a: Student, b: Student) => a.sr_no - b.sr_no);
        this.hideBtn = true;
        this.toastr.info(res.length + ' Record/s Fetched!');
      }
      this.loader = false
    }
      , err => {
        console.log(err);
        this.loader = false
        this.toastr.error("Some Error Occured! Maybe Internet Issue. Please refresh");
      }
    );
  }

  ClearClassBase() {
    this.formRef?.controls['year'].reset();
    this.formRef?.controls['inst_no'].reset();
  }

  ClearYearBase() {
    this.formRef?.controls['inst_no'].reset();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  Filter()
  {
    filter();
  }
}