import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectList, SelectList2 } from '../../shared/model/SelectList.model';
import { SessionService } from '../../shared/services/Sessions.service';
import { SlideInFromLeft } from "src/app/transitions";
import { DegreeprintService } from '../../shared/services/degreeprint.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ToastrService } from 'ngx-toastr';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { DatePipe } from '@angular/common';
import { filter } from 'src/app/shared/functions/tableSearch';

export interface Data {
  year: number,
  rollno: string,
  Name: string,
  F_name: string,
  Reg_no: string,
  gender: string,
  major: string,
  Deg_no: number,
  CGPA: number,
  rn: number,
  stu_year: number
}

interface DataNIC extends Data {
  NIC: string;
  ph1: string;
}

@Component({
  selector: 'app-eligible-candidate',
  templateUrl: './eligible-candidate.component.html',
  styleUrls: ['./eligible-candidate.component.css'],
  animations: [SlideInFromLeft()],
})

export class EligibleCandidateComponent implements OnInit {
  @ViewChild("f") formRef: NgForm
  class: Array<SelectList>
  majors: Array<SelectList>
  Details: Array<Data>;
  CNICDetails: Array<DataNIC>;
  session: Array<SelectList2>;
  category: string = "";
  isOpen: boolean;
  loader: boolean;
  constructor(
    private sessionService: SessionService, 
    private degree: DegreeprintService, 
    private toaster: ToastrService,
    private lmsSharedService: LMSharedDataService,
    private datePipe: DatePipe) {
    this.class = [];
    this.majors = [];
    this.session = [];
    this.Details = [];
    this.CNICDetails = [];
    this.loader =false;
  }

  ngOnInit(): void {
    this.updateClasses();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.class = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateMajors() {
    this.majors.length = 0;
    this.lmsSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  OnClassChange() {
    this.updateMajors();
    // this.getSession();
  }

  getSession() {
    this.session = [];
    this.sessionService.getSessions(this.formRef.value.c_code).subscribe(
      (res: { SE_ID: number; SE_NM: string; SE_ST_YEAR: number }[]) => {
        res?.forEach((e) => {
          this.session.push({
            id: e.SE_ID,
            nm: e.SE_NM,
            se_st_year: e.SE_ST_YEAR,
          });
        });
        // this.se_select = res[0]?.SE_ID;
      }
    );
  }

  OnCategoryChange() {
    if (this.formRef.value.category === "Urgent") {
      this.category = "U";
    }
    else if (this.formRef.value.category === "Regular") {
      this.category = "R";
    }
    else if (this.formRef.value.category === "Duplicate") {
      this.category = "D"
    }
  }

  click() {
    this.Details = [];
    this.CNICDetails = []
    this.isOpen = true;
    this.loader = true

    this.degree.getEligibleCandidateForDegree(this.formRef.value.c_code, this.formRef.value.t_no, 0, this.formRef.value.maj_id, this.formRef.value.notifDate
      , this.formRef.value.notifDate1, this.category,2).subscribe((res: any) => {
        if (res != '') {
          this.toaster.success("Data Loaded");
          for (var i = 0; i < res.length; i++) {
            this.Details.push({ year: res[i].Year, rollno: res[i].ROLNO, Name: res[i].nm, F_name: res[i].F_NM, Reg_no: res[i].REG_NO, gender: res[i].gender, major: res[i].maj_nm, Deg_no: res[i].DEG_NO, CGPA: res[i].CGPA, rn: res[i].rn, stu_year: res[i].stu_year });
          }
        }
        else {
          this.toaster.error("Data Not Found");
        }
        this.loader = false
      });
  }

  clickCNIC(num) {
    this.Details = []
    this.CNICDetails = [];
    this.isOpen = true;
    this.loader = true;

    this.degree.getEligibleCandidateForDegree(this.formRef.value.c_code, this.formRef.value.t_no, 0, this.formRef.value.maj_id, this.formRef.value.notifDate
      , this.formRef.value.notifDate1, this.category,1).subscribe((res: any) => {
        if (res != '') {
          this.toaster.success("Data Loading");
          for (var i = 0; i < res.length; i++) {
            this.CNICDetails.push({
              year: res[i].Year, rollno: res[i].ROLNO, Name: res[i].nm, F_name: res[i].F_NM, Reg_no: res[i].REG_NO, gender: res[i].gender, major: res[i].maj_nm, Deg_no: res[i].DEG_NO, CGPA: res[i].CGPA, rn: res[i].rn, stu_year: res[i].stu_year,
              NIC: res[i].NIC, ph1: res[i].ph1
            });
          }
        }
        else {
          this.toaster.error("Data Not Found");
        }
        this.loader = false
      });
  }

  CreatePdf() {
    var Year = this.formRef.value.Passing_year;
    var categ = this.formRef.value.category;
    var classes = this.class[this.class.findIndex(res=> res.id == this.formRef.value.c_code)].nm;
    var major = this.majors[this.majors.findIndex(res=> res.id == this.formRef.value.maj_id)].nm;
    this.toaster.success("PDF In Progress");
    const header = [];
    var body = [];
    var exportDate = this.datePipe.transform(new Date(), 'MMM d, y')
    var image = new Image();
    image.src = '../../../assets/images/logo3.png';

    this.Details.forEach((e, i) => {
      body.push([(i + 1), e.Deg_no, e.Reg_no, e.Name, e.gender, e.F_name, e.rollno, e.CGPA.toFixed(2), e.year])
    });

    const doc = new jsPDF('landscape');

    header.push(['Sr#', 'Degree No', 'Registration No', 'Name', 'G', 'Father Name', 'Roll No', 'CGPA', 'Year']);

    autoTable(doc, {
      theme: "grid",
      margin: { top: 48, bottom: 5, left: 7, right: 5 },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontSize: 8,
        fontStyle: 'bold',
        halign: 'center'
      },
      bodyStyles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        fontSize: 7,
        halign: 'center'
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 20 },
        2: { cellWidth: 52 },
        3: { cellWidth: 52 },
        4: { cellWidth: 15 },
        5: { cellWidth: 52 },
        6: { cellWidth: 52 },
        7: { cellWidth: 15 },
        8: { cellWidth: 15 }
      },
      head: header,
      body: body,
      didDrawPage: function (d) {
        doc.addImage(image, 265, 7, 25, 30);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 267, 42);
        doc.setFontSize(22);
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(22);
        doc.text("GC University, Lahore", 150, 13, { align: 'center' });
        doc.setFontSize(14);
        doc.text("List of Candidates Eligible to Receive the Degree in Convocation " + Year, 150, 22, { align: 'center' });
        doc.setFontSize(13);
        doc.text(`Class: ${classes}`, 150, 29, { align: 'center' });
        doc.text(`Major: ${major}`, 150, 36, { align: 'center' });
        doc.text(`Category: ${categ}`, 150, 43, { align: 'center' });
      },
    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }

  CreatePdfCNIC() {
    var Year = this.formRef.value.Passing_year;
    var categ = this.formRef.value.category;
    var classes = this.class[this.class.findIndex(res=> res.id == this.formRef.value.c_code)].nm;
    var major = this.majors[this.majors.findIndex(res=> res.id == this.formRef.value.maj_id)].nm;

    this.toaster.success("PDF In Progress");
    const header = [];
    var body = [];
    var exportDate = this.datePipe.transform(new Date(), 'MMM d, y')
    var image = new Image();
    image.src = '../../../assets/images/logo3.png';

    this.CNICDetails.forEach((e, i) => {
      body.push([(i + 1), e.Deg_no, e.Reg_no, e.Name, e.gender, e.F_name, e.NIC, e.ph1, e.rollno, e.CGPA.toFixed(2), e.year])
    });

    const doc = new jsPDF('landscape');

    header.push(['Sr#', 'Degree No', 'Registration No', 'Name', 'G', 'Father Name', 'CNIC', 'Phone No', 'Roll No', 'CGPA', 'Year']);

    autoTable(doc, {
      theme: "grid",
      margin: { top: 48, bottom: 5, left: 7, right: 5 },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontSize: 8,
        fontStyle: 'bold',
        halign: 'center'
      },
      bodyStyles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        fontSize: 7,
        halign: 'center'
      },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 20 },
        2: { cellWidth: 43 },
        3: { cellWidth: 43 },
        4: { cellWidth: 10 },
        5: { cellWidth: 43 },
        6: { cellWidth: 25 },
        7: { cellWidth: 25 },
        8: { cellWidth: 40 },
        9: { cellWidth: 12 },
        10: { cellWidth: 12 }
      },
      head: header,
      body: body,
      didDrawPage: function (d) {
        doc.addImage(image, 265, 7, 25, 30);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 267, 42);
        doc.setFontSize(22);
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(22);
        doc.text("GC University, Lahore", 150, 13, { align: 'center' });
        doc.setFontSize(14);
        doc.text("List of Candidates Eligible to Receive the Degree in Convocation " + Year, 150, 22, { align: 'center' });
        doc.setFontSize(13);
        doc.text(`Class: ${classes}`, 150, 29, { align: 'center' });
        doc.text(`Major: ${major}`, 150, 36, { align: 'center' });
        doc.text(`Category: ${categ}`, 150, 43, { align: 'center' });
       
      },
    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }

  DownloadExcel() {
    var Year = this.formRef.value.Passing_year;
    let body = [];
    let header = [];

    header.push(['Sr#', 'Degree No', 'Registration No', 'Name', 'G', 'Father Name', 'Roll No', 'CGPA', 'Year', 'Major']);
    this.Details.forEach((e, i) => {
      body.push([(i + 1), e.Deg_no, e.Reg_no, e.Name, e.gender, e.F_name, e.rollno, e.CGPA.toFixed(2), e.year, e.major])
    });

    new AngularCsv(body, "Eligible Candidate List (" + Year + ")", { headers: (header) });
  }

  DownloadExcelCNIC() {
    var Year = this.formRef.value.Passing_year;
    let body = [];
    let header = [];

    header.push(['Sr#', 'Degree No', 'Registration No', 'Name', 'G', 'Father Name','CNIC', 'Phone No.', 'Roll No', 'CGPA', 'Year', 'Major']);
    this.CNICDetails.forEach((e, i) => {
      body.push([(i + 1), e.Deg_no, e.Reg_no, e.Name, e.gender, e.F_name, e.NIC, e.ph1, e.rollno, e.CGPA.toFixed(2), e.year, e.major])
    });

    new AngularCsv(body, "Eligible Candidates List with CNIC(" + Year + ")", { headers: (header) });
  }

  OnClear(cls: NgForm) {
    this.CNICDetails = [];
    this.Details = [];
    this.loader = false
    cls.resetForm();
    this.isOpen = false;
  }

  del(single_stud) {
    this.degree.DeleteFromDegInfo(this.formRef.value.c_code, this.formRef.value.maj_id, single_stud.rn, single_stud.stu_year).subscribe((res: any) => {
      if (res != '') {
        this.toaster.success("Successfully Data Deleted");
        this.click();
      }
    });
  }

  Filter()
  {
   filter();
  }
}
