<form #f="ngForm">
    <div class=" container-fluid" [@SlideInFromLeft]>
        <div class="row card shadow">
            <div class="card-header"  style="padding-top: 10px;">
                <div class=" col-lg-6 col-md-6 form-group">
                  Board Wise Student Strength Report</div>
                   <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
                  <app-details-button [details]="details"></app-details-button>
              </div>
            </div>
            <div class="row card-body px-4 mb-1 pb-0" style="margin-top:-5px">
                <div class="col-lg-4 col-md-4 form-group">
                    <label for="brd">Class</label>
                    <select #c_code class="form-control " name="c_code"
                        id="c_code" ngModel (change)="ClearClassBase()" ngModel required>
                        <option *ngFor="let c of classes"
                            value="{{c.id}}">{{c.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-4 form-group">
                    <label for="brd">Board</label>
                    <select #brd class="form-control " name="brd" id="brd"
                        ngModel (change)="ClearBoardBase()" ngModel required>
                        <option *ngFor="let b of board"
                            value="{{b.id}}">{{b.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="session">Session</label>
                    <select class="form-control" #session name="session"
                        id="session" ngModel required>
                        <option *ngFor="let s of sessions"
                            value="{{s}}">{{s}}</option>
                    </select>
                </div>
            </div>

            <div class="ml-4 mb-3" style="margin-top:-5px">
                <button class="btn btn-primary" (click)="getReport()" [disabled]="!f.valid">
                    Get Report
                </button>
                <button *ngIf="stdArr.length>0"
                    class="btn btn-danger ml-2" (click)="CreatePDF()">
                    <i class="fa fa-file-pdf-o" for></i>
                    Download Report Pdf
                </button>

                <button *ngIf="stdArr.length>0"
                    class="btn btn-success ml-2"
                    (click)="DownloadExcel()">
                    <i class="fa fa-file-excel-o" for></i>
                    Download Report Excel
                </button>
            </div>
            <loader *ngIf="loader"></loader>
            <div *ngIf="stdArr.length > 0" class="px-4">
                <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">
                    <input class="form-control col-lg-4" type="text"
                        name="Search" placeholder="Search" id="myInput"
                        (keydown)="Filter()">
                </div>
                <table class="table table-bordered table-striped mt-4"
                    id="tableID" id="table" style="font-size:10px">
                    <thead class="thead-dark">
                        <th style="width: 5vw">Sr #</th>
                        <th style="width: 15vw">Roll #</th>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Board Roll #</th>
                        <th>Marks</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of this.stdArr; let k=index" id="values">
                            <td>{{k+1}}</td>
                            <td>{{i.rolno}}</td>
                            <td>{{i.nm}}</td>
                            <td>{{i.add1}}</td>
                            <td>{{i.brd_rolno}}</td>
                            <td>{{i.mrks}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</form>