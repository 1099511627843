import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';


@Injectable({
  providedIn: 'root'
})
export class SubCombinationsService {

  constructor(private http: HttpClient) { }

  getSubjectCombinations(maj_id: number, c_code: string) {
    var param = { c_code, maj_id };
    return this.http.post(`${baseUrl}/api/student/getSubjectCombination/`, param);
  }

  insertSubjectCombinations(param: any) {
    return this.http.post(`${baseUrl}/api/subjectComb/insertSubjectCombination/`, param)
  }
  insertSubjectCombinationsFee(param:any){
    return this.http.post(`${baseUrl}/api/subjectComb/insertSubjectCombinationFee/`,param)
  }

  deleteSubjectCombination(c_code: any, g_code: any, set_no: any) {
    var param: any = { c_code, g_code, set_no };
    return this.http.post(`${baseUrl}/api/subjectComb/deleteSubjectCombination`, param)
  }
}