import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';


import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { SelectList } from './../shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { MajorService } from './../shared/services/Major.service';
import { EnrollmentService } from './../shared/services/Enrollment.service';
import { TIMEOUT } from 'dns';


@Component({
  selector: 'app-generate-time-table',
  templateUrl: './generate-time-table.component.html',
  styleUrls: ['./generate-time-table.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class GenerateTimeTableComponent implements OnInit {
  classes: Array<SelectList>;
  majors: Array<SelectList>;

  @ViewChild('f') formRef: NgForm;

  constructor(
    private toastr: ToastrService,
    private classService: ClassService,
    private majorService:MajorService,
    private enrollmentService:EnrollmentService
  ) { 
    this.classes = [];
    this.majors = [];
  }

  ngOnInit(): void {
    this.loader=false;
    this.classService.getClasses({}).subscribe((res:{C_CODE: number, C_NM: string}[]) => {
      res?.forEach(entry=>{
        this.classes.push({id: entry.C_CODE, nm: entry.C_NM});
      });
    });
  }

  loader=false;
  updateMajors() {
    this.majors = [];
    this.majorService.getMajor({c_code: this.formRef.value.c_code}).subscribe((res:{MAJ_ID:number,MAJ_NM:string}[]) => {
      res?.forEach(entry=>{
        this.majors.push({id: entry.MAJ_ID, nm: entry.MAJ_NM});
      });
    });
  
  }
  OnMajorChange(){
    this.formRef?.controls['t_no'].reset();
    this.formRef?.controls['year_in'].reset();
  }
  OnYearChange(){
    this.formRef?.controls['t_no'].reset();
  }
  Self_Support_Enrollment() {
    this.loader=true;
    this.toastr.clear()
    this.toastr.info("Enrolling Please Wait...",null,{timeOut:120000});
    if(this.formRef.valid&& this.formRef.value.maj_id!=="")
    {      
      this.enrollmentService.self_suppporting_enrollment(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.t_no, this.formRef.value.year_in).subscribe((res:{msg: string ,warning: string}) => {
        this.loader=false;
        this.toastr.clear();
        if(!res){
          this.toastr.error("Data Not Found!");
        }
        else if(res[0][0].warning){
          this.toastr.warning(res[0][0].warning);
        }
        else if(res[0][0].msg){
          this.toastr.success(res[1][0].msg);
        } 
        else if(res[1][0].warning){
          this.toastr.warning(res[1][0].warning);
        }
      }, err => {
        this.loader=false;
        this.toastr.error("Some Error Occured during Enrollment!");
      });
    }
    else{
      this.toastr.warning("Some Fields are Missing!");
    }
  }



  FA_FSC_Section_Allocation(){
    if(this.formRef.valid&& this.formRef.value.maj_id!==""){
      this.loader=true;
    this.toastr.clear()
    this.toastr.info("Allocating Section...",null,{timeOut:120000});
    this.enrollmentService.Fa_fsc_section_allocation(this.formRef.value.year_in,this.formRef.value.c_code,this.formRef.value.t_no).subscribe(res => {
      this.loader=false;
      this.toastr.clear();
      this.toastr.success("FA FSC section Allocation Completed!");
      },err=>{
        this.loader=false;
        console.log(err);
      this.toastr.error("Some Error Occured during FA FSC section Allocation!");
    });
  }
  else{
    this.toastr.warning("Some Fields are Missing!");
  }
  }


  FA_FSC_Section_Allocation_Major(){    
    if(this.formRef.valid &&this.formRef.value.maj_id!==""){
      this.loader=true;
    this.toastr.clear()
    this.toastr.info("Allocating Section...",null,{timeOut:120000});
    this.enrollmentService.Fa_fsc_sec_allocation_maj(this.formRef.value.year_in,this.formRef.value.c_code,this.formRef.value.t_no,this.formRef.value.maj_id).subscribe(res => {
      this.loader=false;
      this.toastr.clear();
      this.toastr.success("FA FSC Section Allocation Major Completed!");
      },err=>{
        this.loader=false;
      console.log(err);
        this.toastr.error("Some Error Occured during FA FSC section Allocation Major!");
    });
  }
  else{
    this.toastr.warning("Some Fields are Missing!");
  }
  }


  Section_Change(){
    if(this.formRef.valid){
      this.loader=true;
      this.toastr.clear()
    this.toastr.info("Changing Section...",null,{timeOut:120000});
    this.enrollmentService.section_change(this.formRef.value.year_in,this.formRef.value.c_code,this.formRef.value.t_no).subscribe(res => {
      this.loader=false;
      this.toastr.clear();
      this.toastr.success("Section Changed Completed!");
      },err=>{
        this.loader=false;
      console.log(err);
      this.toastr.error("Some Error Occured during Section Changed!");
    });
  }
  else{
    this.toastr.warning("Some Fields are Missing!");
  }
  }


  BA_BSC_Section_Allocation(){
    if(this.formRef.valid){
      this.loader=true;
      this.toastr.clear()
    this.toastr.info("Allocating Section...",null,{timeOut:120000});
    this.enrollmentService.ba_bsc_section_allocation(this.formRef.value.year_in,this.formRef.value.c_code,this.formRef.value.t_no).subscribe(res => {
      this.loader=false;
      this.toastr.clear()
      this.toastr.success("BA BSC section Allocation Completed!");
      },err=>{
        this.loader=false;
        console.log(err);
      this.toastr.error("Some Error Occured during BA BSC section Allocation!");
    });
  }
    else{
      this.toastr.warning("Some Fields are Missing!");
    }
  }

  BA_BSC_Section_Allocation_Major(){
    if(this.formRef.valid &&this.formRef.value.maj_id!==""){
      this.loader=true;
      this.toastr.clear()
    this.toastr.info("Allocating Section...",null,{timeOut:120000});
    this.enrollmentService.ba_bsc_section_allocation_maj(this.formRef.value.year_in,this.formRef.value.c_code,this.formRef.value.t_no,this.formRef.value.maj_id).subscribe(res => {
      this.loader=false;
      this.toastr.clear();
      this.toastr.success("BA BSC Section Allocation Major Completed!");
      },err=>{
        this.loader=false;
        console.log(err);
      this.toastr.error("Some Error Occured during BA BSC section Allocation Major!");
    });
  }
  else{
    this.toastr.warning("Some Fields are Missing!");
  }
  }


  BA_BSC_Repeater_TimeTable(){
    if(this.formRef.valid){
      this.loader=true;
      this.toastr.clear()
    this.toastr.info("Allocating Repeater Timetable...",null,{timeOut:120000});
    this.enrollmentService.babsc_repeater_timetable(this.formRef.value.c_code,this.formRef.value.year_in,this.formRef.value.t_no).subscribe(res => { 
      this.loader=false; 
      this.toastr.clear();
      this.toastr.success("BA BSC Repeater Timetable Completed!");
      },err=>{
        this.loader=false;
        console.log(err);
      this.toastr.error("Some Error Occured during BA BSC Repeater Timetable!");
    });
  }
  else{
    this.toastr.warning("Some Fields are Missing!");
  }
  }

  Delete_Major_Records(){
    if(this.formRef.valid &&this.formRef.value.maj_id!==""){
      this.loader=true;
      this.toastr.clear()
    this.toastr.info("Deleting...",null,{timeOut:120000});
      this.enrollmentService.delete_maj_records(this.formRef.value.c_code,this.formRef.value.year_in,this.formRef.value.t_no,this.formRef.value.maj_id).subscribe((res:{msg: string ,warning: string}) => {
        this.loader=false;
        this.toastr.clear();
        if(!res){
          this.toastr.error("Unnown Error");
        }
        else if(res.msg){
          this.toastr.success(res.msg);
        }
        else{
          this.toastr.warning(res.warning);
        }
      }, err => {
        this.loader=false;
        console.log(err);
        this.toastr.error("Some Error Occured during Deletion!");
      });
    }
    else{
      this.toastr.warning("Some Fields are Missing!");
    }
  }


  Delete_All_Records(){
    if(this.formRef.valid){
      this.loader=true;
      this.toastr.clear()
    this.toastr.info("Deleting...",null,{timeOut:120000});
      this.enrollmentService.delete_all_records(this.formRef.value.c_code,this.formRef.value.year_in,this.formRef.value.t_no).subscribe((res:{msg: string ,warning: string}) => {
        this.loader=false;
        this.toastr.clear();
        if(!res){
          this.toastr.error("Unnown Error");
        }
        else if(res.msg){
          this.toastr.success(res.msg);
        }
        else{
          this.toastr.warning(res.warning);
        }
      }, err => {
        this.loader=false;
        console.log(err);
        this.toastr.error("Some Error Occured during Deletion!");
      });
    }
    else{
      this.toastr.warning("Some Fields are Missing!");
    }
  }

  OnSubmit(){
  }
 
  ClearForm(fn: NgForm) {
    fn.resetForm();
    this.classes = [];
    this.majors = [];
  }
  
  OnClassChange(c: HTMLSelectElement) {
    this.updateMajors();
    this.formRef?.controls['t_no'].reset();
    this.formRef?.controls['maj_id'].reset();
    this.formRef?.controls['year_in'].reset();
  }

}
