import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { SelectList } from './../shared/model/SelectList.model';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { ClassService } from './../shared/services/Class.service';
import { StudentService } from './../shared/services/Student.service';
import { SessionService } from './../shared/services/Sessions.service';
import { FreezeService } from './../shared/services/Freeze.service';
export interface StudentRecord {
  YEAR: number,
  C_CODE: number,
  SE_ID: number,
  RN: number,
  ROLNO: number,
  NM: number,
  NR_ID: number,
  NR_DES: string
}
export interface Remarks {
  NR_ID: number,
  NR_DES: string,
  STATUS: number
}
@Component({
  selector: 'app-freeze-semester',
  templateUrl: './freeze-semester.component.html',
  styleUrls: ['./freeze-semester.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class FreezeSemesterComponent implements OnInit {
  edit: boolean = false;
  index: number;
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  StudentRecord: Array<StudentRecord>;
  Remarks: Array<Remarks>;
  StudentName: string;
  @ViewChild('f') formRef: NgForm;

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private classService: ClassService,
    private sessionService: SessionService,
    private studentService: StudentService,
    private freezeService: FreezeService,
  ) {
    this.classes = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.StudentRecord = new Array<StudentRecord>();
    this.Remarks = new Array<Remarks>();
    this.StudentName = '';
  }

  ngOnInit(): void {
    this.classService.getClasses({}).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
    });
    this.NoRollRes_Des();
  }

  updateSessions() {
    this.sessions = [];
    this.sessionService.getSessions(this.formRef.value?.C_CODE).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(element => {
        this.sessions.push({ id: element.SE_ID, nm: element.SE_NM });
      });
    });
  }

  onYearChange() {
    this.sessions = [];
    this.StudentName = '';
    this.formRef?.controls['RN'].reset();
    this.formRef?.controls['DATE'].reset();
    this.formRef?.controls['T_NO'].reset();
  }

  NoRollRes_Des() {
    this.freezeService.getNoRollDes().subscribe((res: Remarks[]) => {
      this.Remarks = res;
    })
  }

  OnClassChange(cou) {
    this.updateSessions();
    this.StudentName = '';
    this.formRef?.controls['RN'].reset();
    this.formRef?.controls['DATE'].reset();
    this.formRef?.controls['T_NO'].reset();
  }

  toggleRecord(i) {
    this.edit = !this.edit;
    this.index = i;
  }
  // 
  Update(i, NR_ID) {
    let index = this.Remarks.findIndex((se) => se.NR_ID == this.formRef.value.NR_ID);
    let status = this.Remarks[i]?.STATUS;
    this.freezeService.UpdateNoRollStdt({ YEAR: this.formRef.value?.YEAR, C_CODE: this.formRef.value?.C_CODE, RN: this.formRef.value?.RN, SE_ID: this.formRef.value?.SE_ID, STATUS: status, NR_ID_NEW: NR_ID }).subscribe((res: { affectedRows: number }) => {
      if (res.affectedRows === 1) {
        this.toastr.success("Updated Successfully");
        this.FetchData();
        this.edit = false;
      } else {
        this.toastr.error("Not Update");
      }
    },
      err => {
        console.log(err);
        this.toastr.error("some err occured");
      });
    this.freezeService.UpdateNoRollStdtFee({ YEAR: this.formRef.value?.YEAR, C_CODE: this.formRef.value?.C_CODE, RN: this.formRef.value?.RN, SE_ID: this.formRef.value?.SE_ID, STATUS: status, NR_ID_NEW: NR_ID }).subscribe((res: { affectedRows: number }) => {
      if (res.affectedRows === 1) {
        this.toastr.success("Updated Successfully");
        this.FetchData();
        this.edit = false;
      } else {
        this.toastr.error("Not Update");
      }
    },
      err => {
        console.log(err);
        this.toastr.error("some err occured");
      });
  }

  OnSessionChange(se) {

  }

  OnSemesterChange() {

  }

  OnRollNumberChange() {
    this.studentService.getStudentName({ c_code: this.formRef.value?.C_CODE, year: this.formRef.value?.YEAR, rn: this.formRef.value?.RN }).subscribe(res => {
      this.StudentName = res[0]?.NM;
    }, err => {
      console.log(err);
    })
  }
  // 
  AddRecord() {
    if (!this.formRef?.valid)
      return;
    let index = this.Remarks.findIndex((se) => se.NR_ID == this.formRef.value.NR_ID);
    let status = this.Remarks[index]?.STATUS;
    this.freezeService.AddNoRollStdt({ YEAR: this.formRef.value?.YEAR, C_CODE: this.formRef.value?.C_CODE, RN: this.formRef.value?.RN, SE_ID: this.formRef.value?.SE_ID, DATE: this.formRef.value?.DATE, NR_ID: this.formRef.value?.NR_ID, T_NO: this.formRef.value?.T_NO, STATUS: status }).subscribe((res: { affectedRows: number }) => {
      if (res?.affectedRows) {
        this.toastr.success("Data Inserted Successfully!")
        this.FetchData();
      }
      if(res[0][0]?.result){
        this.toastr.success(res[0][0].result)
      }
      else
        this.toastr.warning("Already Exist")
    }, err => {
      this.toastr.error("Some Error Occured");
      console.log(err);
    })
    this.freezeService.AddNoRollStdtFee({ YEAR: this.formRef.value?.YEAR, C_CODE: this.formRef.value?.C_CODE, RN: this.formRef.value?.RN, SE_ID: this.formRef.value?.SE_ID, DATE: this.formRef.value?.DATE, NR_ID: this.formRef.value?.NR_ID, T_NO: this.formRef.value?.T_NO, STATUS: status }).subscribe((res: { affectedRows: number }) => {
      if (res?.affectedRows) {
        this.toastr.success("Data Inserted Successfully!")
        this.FetchData();
      }
      if(res[0][0]?.result){
        this.toastr.success(res[0][0].result)
      }
      else
        this.toastr.warning("Already Exist")
    }, err => {
      this.toastr.error("Some Error Occured");
      console.log(err);
    })
  }

  ClearForm(f) {
    f.resetForm();
    this.StudentName = "";
    this.StudentRecord = [];
  }

  FetchData() {
    this.freezeService.getNoRollStdt({ YEAR: this.formRef.value?.YEAR, C_CODE: this.formRef.value?.C_CODE, RN: this.formRef.value?.RN, SE_ID: this.formRef.value?.SE_ID }).subscribe((res: StudentRecord[]) => {
      if (res?.length == 0) {
        this.toastr.warning('No Data Found');
      }
      else {
        this.StudentRecord = res;
      }
    }, err => {
      console.log(err);
    })
  }
  // 
  DeleteRecord(year, c_code, se_id, rn) {
    this.freezeService.DeleteNoRollStdt({ YEAR: year, C_CODE: c_code, SE_ID: se_id, RN: rn }).subscribe((res: { affectedRows: number }) => {
      if (res.affectedRows === 1) {
        this.toastr.success(res.affectedRows + " Row Delete Successfully");
        this.FetchData();
      }
    })
    this.freezeService.DeleteNoRollStdtFee({ YEAR: year, C_CODE: c_code, SE_ID: se_id, RN: rn }).subscribe((res: { affectedRows: number }) => {
      if (res.affectedRows === 1) {
        this.toastr.success(res.affectedRows + " Row Delete Successfully");
        this.FetchData();
      }
    })
  }
}
