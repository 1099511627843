<div class="card" [@SlideInFromLeft]>
  <form #f="ngForm">
    <div class="card-header" style="padding-top: 10px;">
    <div class=" col-lg-6 col-md-6 form-group">Assign Roll No</div>
    <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
      <app-details-button [details]="details"></app-details-button>
  </div>
  </div>
    <div class="card-body row px-4">
      <div class="form-group col-lg-4 col-md-6 col-sm-12">
        <label for="sub">Classes</label>
        <select class="form-control" id="sub" aria-label="ClassCode" ngModel required name="c_code"
          (ngModelChange)="updateMajors()" (change)="ClearClassBase()">
          <option *ngFor="let i of classes" value="{{ i.id }}">
            {{ i.nm }}
          </option>
        </select>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-12 form-group">
        <label for="major">Major</label>
        <select class="form-control" #major name="maj_id" id="major" required ngModel>
          <option *ngFor="let m of majors" value="{{ m.id }}">
            {{ m.nm }}
          </option>
        </select>
      </div>
      <div class="col-lg-4 col-md-6 form-group">
        <label for="year_in">Year</label>
        <select class="form-control" id="year" aria-label="Year" ngModel required name="year">
          <option *ngFor="let s of sessions" value="{{s}}">{{s}}</option>
        </select>
      </div>
      <div class="mt-5 px-0 col-lg-12">
        <button style="margin-left: 1rem; margin-top: -2.5%" class="btn btn-primary" (click)="ShowResult()"  [disabled]="!f.valid">
          Show Result
        </button>
        <button style="margin-left: 1rem; margin-top: -2.5%" class="btn btn-danger" (click)="CreatePDF()"  [disabled]="!f.valid">
          <i class="fa fa-file-pdf-o" for=""></i>
          Download Report Pdf
        </button>
        <button style="margin-left: 1rem; margin-top: -2.5%" class="btn btn-success" (click)="DownloadExcel()"  [disabled]="!f.valid"> 
          <i class="fa fa-file-excel-o" for=""></i>
          Download Report Excel
        </button>
      </div>

      <div class="mt-2 px-3" *ngIf="meritListCount?.length>0">
        <span class="mr-4">
          <span style="background-color: #ffb19e;" id="Dup" class="spanIn mr-1"></span>
          <label class="form-check-label font-16" for="Dup">
            Duplicate Rollno Student(s)</label>
        </span>
        <span class="mr-4">
          <span style="background-color: #f2f3cb;" id="unpaid" class="spanIn mr-1"></span>
          <label class="form-check-label font-16" for="unpaid">
            Unpaid Student(s)</label>
        </span>
        <span class="mr-4">
          <span style="background-color: #e6ffe6;" id="assign" class="spanIn mr-1"></span>
          <label class="form-check-label font-16" for="assign">
            Assigned Rollno Student(s)</label>
        </span>
        <span class="mr-4">
          <span style="background-color: #a0d9ef;" id="assign" class="spanIn mr-1"></span>
          <label class="form-check-label font-16" for="assign">
            Change Class/Major to shift Rollno</label>
        </span>
      </div>
    </div>
    <loader *ngIf="loader"></loader>
    <div class="card-body row" style="margin-top: -3%">
      <span *ngIf="meritListCount.length!=0" class="meritList">SELECT MERIT LIST</span>
      <nav *ngFor="let i of meritListCount;let y=index" style="margin-bottom:3%;display:flex">
        <span class="justify-content-end ms-2 ml-2" style="width:100%">
          <span class="page-item"><a class="page-link hover" (click)="selectMeritList(i?.m_list)"
              [ngClass]="{assigned: unAssignedForms[y]?.count==0,status: unAssignedForms[y]?.count!=0}"
              style="cursor:pointer;width:100%">{{i?.m_list}}</a></span>
        </span>
      </nav>
      <table *ngFor="let i of meritListCount;let y=index" class="table table-striped" style=" margin-top:-2%"
        id="myTable">
        <div *ngIf="i?.m_list==m_list" style="margin-top:0%">
          <thead style="font-size: 11px" class="thead-dark">
            <tr>
              <th colspan="8">
                <div class="row" style="justify-content:space-around">
                  <span style="font-size: 13px;">UNASSIGNED FORMS: <p style="color:red;display:inline">
                      {{unAssignedForms[y]?.count}}</p></span>
                  <span style="font-size: 16px">MERIT LIST: <p style="color:red;display:inline">{{ i?.m_list }}</p>
                  </span>
                  <span style="font-size: 13px">HIGHEST ROLL # ASSIGNED OVERALL:
                    <p style="color:red;display:inline">{{highestRn}}</p>
                  </span>
                </div>
              </th>
            </tr>
            <tr style="text-align: center; font-size: 12px;">
              <th class="form-group pl-2 pr-2" style="width:10%">ADMISSION BASE</th>
              <th class="form-group pl-0 pr-2" style="width:9%">FORM NO #</th>
              <th class="form-group pl-0 pr-2" style="width:12%">STUDENT NAME</th>
              <th class="form-group pl-0 pr-2" style="width:12%">FATHER NAME</th>
              <th class="form-group pl-0 pr-2" style="width:7%">ASSIGN ROLL #</th>
              <th class="form-group pl-0 pr-2" style="width:13%">COMPLETE ROLL #</th>
              <th class="form-group pl-0 pr-2" style="width:13%">REGISTRATION #</th>
              <th class="form-group pl-0 pr-2" style="width:13%">ACTIONS</th>
            </tr>
            <tr>
              <th class="form-group pl-2 pr-2" style="width:10%">
                <input class="form-control" id="myInput1" type="text" placeholder="Search" style="font-size:12px"
                  (keydown)="CategoryFilter($event)">
              </th>
              <th class="form-group pl-0 pr-2" style="width:9%">
                <input class="form-control" id="myInput2" type="text" placeholder="Search" style="font-size:12px"
                  (keydown)="CategoryFilter($event)">
              </th>
              <th class="form-group pl-0 pr-2" style="width:12%">
                <input class="form-control" id="myInput3" type="text" placeholder="Search" style="font-size:12px"
                  (keydown)="CategoryFilter($event)">
              </th>
              <th class="form-group pl-0 pr-2" style="width:12%">
                <input class="form-control" id="myInput4" type="text" placeholder="Search" style="font-size:12px"
                  (keydown)="CategoryFilter($event)">
              </th>
              <th class="form-group pl-0 pr-2" style="width:7%">
                <input class="form-control" id="myInput5" type="text" placeholder="Search" style="font-size:12px"
                  (keydown)="CategoryFilter($event)">
              </th>
              <th class="form-group  pl-0 pr-2" style="width:13%">
                <input class="form-control" id="myInput6" type="text" placeholder="Search" style="font-size:12px"
                  (keydown)="CategoryFilter($event)">
              </th>
              <th class="form-group  pl-0 pr-1" style="width:13%">
                <input class="form-control" id="myInput7" type="text" placeholder="Search" style="font-size:12px"
                  (keydown)="CategoryFilter($event)">
              </th>
              <th class="form-group pl-1 pr-2" style="width:13%">
                <input class="form-control" id="myInput7" type="text" placeholder="Search" style="font-size:12px"
                  (keydown)="CategoryFilter($event)">
              </th>
            </tr>
          </thead>
          <ng-container *ngIf="load; then IfBlock"></ng-container>
          <ng-template #IfBlock>
            <tr>
              <th colspan="9">
                <div style="text-align: center">
                  <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Fetching Data. Please Wait
                  </button>
                </div>
              </th>
            </tr>
          </ng-template>
          <tbody id="myBody">
            <ng-container *ngFor="let j of meritListStdArr; let p = index">
            <tr *ngIf="i.m_list == j.m_list" [ngClass]="{
                status: existedRn.indexOf(j.f_no) != -1,
                assigned: assignedRn.indexOf(j.f_no) != -1,
                maj_chng: meritListStdArr[p]?.maj_chng_needed == 1,
                notPaid: meritListStdArr[p]?.paid == 0}" style="font-size: 11px">

              <td style="font-weight: bold" class="px-2 pt-2 pb-1">{{ j?.b_nm?.toUpperCase() }}</td>
              <td style="font-weight: bold" class="pl-0 pr-2 pt-2 pb-1">{{ j?.f_no }}</td>
              <td style="font-weight: bold" class="pl-0 pr-2 pt-2 pb-1">{{ j?.Nm?.toUpperCase() }}</td>
              <td style="font-weight: bold" class="pl-0 pr-2 pt-2 pb-1">{{ j?.f_nm?.toUpperCase() }}</td>
              <td class="pl-0 pr-2 py-2">
                <span style="font-size:0.1px;display: none" class="py-0">
                  {{preAssigned[assignedRn.indexOf(j?.f_no)]?.rn}}</span>
                <span style="font-size:0.1px; display: none"
                  class="py-0">{{rolnoArr[formNoArr.indexOf(j?.f_no)]?.rn}}</span>
                <input class="form-control py-0" ngModel aria-label="rn" name="rn" id="rol" id="{{j.f_no }}" #rol
                  style="font-size:13px;font-weight:bold" (keydown)="Handle($event)"
                  (ngModelChange)="AssignFullRoll(j?.f_no, j?.m_list,rol.value)" placeholder="Insert Roll #"
                  maxLength="4" [value]="preAssigned[assignedRn.indexOf(j?.f_no)]?.rn" [readonly]="
                    j?.paid == 0 ||
                    assignedRn.indexOf(j.f_no) != -1 ||
                    preAssigned.indexOf(j.f_no) != -1 ||
                    j?.maj_chng_needed == 1" />
              </td>
              <td class="pl-0 pr-2 py-2">
                <span style="font-size:0.1px;display: none" class="py-0">
                  {{rolnoArr[formNoArr.indexOf(j.f_no)]?.rollno}}</span>
                <span style="font-size:0.1px;display: none" class="py-0">
                  {{preAssigned[assignedRn.indexOf(j?.f_no)]?.rolno}}</span>
                <input class="form-control py-0" placeholder="Complete Roll #" disabled id="rolno" id="{{j.f_no}}s"
                  aria-label="rolno" ngModel name="rolno" style="font-size:12px;font-weight:bold"
                  value="{{rolnoArr[formNoArr.indexOf(j.f_no)]?.rollno}}"
                  value="{{preAssigned[assignedRn.indexOf(j?.f_no)]?.rolno}}" />
              </td>
              <td class="pl-0 py-2 mx-0 px-0">
                <span style="font-size:0.1px;display: none" class="py-0">
                  {{rolnoArr[formNoArr.indexOf(j.f_no)]?.reg_no}}</span>
                <span style="font-size:0.1px;display: none" class="py-0">
                  {{preAssigned[assignedRn.indexOf(j?.f_no)]?.reg_no}}</span>
                <span class="px-0 mx-0 d-flex" [ngClass]="{marginTop: j.LVLID ==1 && j.paid == 1}">
                  <input class="form-control py-0 mr-1" #reg_no placeholder="Registration No"
                    [readonly]="(j.LVLID !=1) || (j.paid == 0) || (j.LVLID == 1 && j.checked == 1)" id="reg"
                    id="{{j.f_no}}r" aria-label="reg" ngModel name="reg" style="font-size:12px;font-weight:bold"
                    value="{{rolnoArr[formNoArr.indexOf(j.f_no)]?.reg_no == 0 ? '' : rolnoArr[formNoArr.indexOf(j.f_no)]?.reg_no}}"
                    value="{{preAssigned[assignedRn.indexOf(j?.f_no)]?.reg_no == 0 ? '' : preAssigned[assignedRn.indexOf(j?.f_no)]?.reg_no}}"
                    (keyup)="updateRegistrationNoOnChange(p, j.f_no, reg_no.value)" />
                  <span (click)=" UpdateCheckedField(p, reg_no.value);">
                    <input id="inpLock" type="checkbox" class="mx-0" *ngIf="j?.LVLID == 1 && j?.paid == 1"
                      [checked]="j?.checked" (click)="$event.preventDefault(); $event.stopPropagation();">
                    <label class="btn-lock" for="inpLock" *ngIf="j?.LVLID == 1 && j?.paid == 1"
                      style="display: flex; align-items: center; justify-content: center;">
                      <svg width="36" height="40" viewBox="0 0 36 40">
                        <path class="lockb"
                          d="M27 27C27 34.1797 21.1797 40 14 40C6.8203 40 1 34.1797 1 27C1 19.8203 6.8203 14 14 14C21.1797 14 27 19.8203 27 27ZM15.6298 26.5191C16.4544 25.9845 17 25.056 17 24C17 22.3431 15.6569 21 14 21C12.3431 21 11 22.3431 11 24C11 25.056 11.5456 25.9845 12.3702 26.5191L11 32H17L15.6298 26.5191Z">
                        </path>
                        <path class="lock" d="M6 21V10C6 5.58172 9.58172 2 14 2V2C18.4183 2 22 5.58172 22 10V21"></path>
                        <path class="bling" d="M29 20L31 22"></path>
                        <path class="bling" d="M31.5 15H34.5"></path>
                        <path class="bling" d="M29 10L31 8"></path>
                      </svg>
                    </label>
                  </span>

                </span>
              </td>
              <td class="px-1 py-1">
                <div class="pl-0 pr-1 py-0">
                  <ng-container *ngIf="j?.paid == 1;then ifBlock;else ElseBlock"></ng-container>

                  <ng-template #ifBlock>
                    <div class="row px-2 py-0 my-0" style="justify-content: space-around; align-items: center;"
                      *ngIf="j?.maj_chng_needed==0">
                      <button style="margin-left: 0.3rem;font-size: 10px;"
                        *ngIf="assignedRn.indexOf(j.f_no) == -1 || j?.LVLID != 1"
                        class="btn btn-outline-primary form-group col-lg-4 btnCss px-0 my-0"
                        (click)="getStudent(j?.f_no, j?.b_id, j?.m_list)" [ngClass]="{assignBtn: assignedRn.indexOf(j.f_no) != -1 ||preAssigned.indexOf(j.f_no) != -1,
                          notPaidBtn: j?.paid == 0}" [disabled]="existedRn.indexOf(j.f_no) != -1 ||assignedRn.indexOf(j.f_no) != -1 ||
                          j?.paid == 0">
                        ASSIGN</button>

                      <button style="margin-left: 0.2rem;font-size: 10px;"
                        *ngIf="j.LVLID == 1 && assignedRn.indexOf(j.f_no) != -1" [disabled]="j.checked == 1"
                        class="btn btn-warning form-group col-lg-7 btnCss px-0 my-0"
                        (click)="updateRegistrationNoFee(p, j.f_no)">UPDATE REG#</button>

                      <button class="btn btn-outline-danger form-group col-lg-4 btnCss px-0 my-0" [ngClass]="{marginRight: j.LVLID == 1 && assignedRn.indexOf(j.f_no) != -1 }"
                        (click)="DeleteStdConfirm(j)" [disabled]="assignedRn.indexOf(j.f_no) == -1"
                        style="font-size:10px;">
                        DELETE
                      </button>

                      <button [ngClass]="{ PaidBtn: j?.paid == 1 }"
                        *ngIf="assignedRn.indexOf(j.f_no) == -1 || j?.LVLID != 1"
                        style="margin-right: 0.3rem;font-size:10px;cursor:default"
                        class="btn btn-outline-primary col-lg-3 btnCss form-group px-0 my-0" disabled>
                        PAID
                      </button>
                    </div>

                    <div class="row" style="padding: 0px 14px 0px 14px" *ngIf="j?.maj_chng_needed==1">
                      <button [ngClass]="{ maj_chng_btn: j?.maj_chng_needed == 1 }" style="cursor:default; color:white"
                        class="btn btn-outline-primary col-md-12 btnCss" disabled>
                        CHANGE CLASS/MAJOR
                      </button>
                    </div>
                  </ng-template>

                  <ng-template #ElseBlock>
                    <div class="row my-0" style="padding: 0px 14px 0px 14px">
                      <button class="btn btn-outline-primary col-md-12 btnCss mx-0 my-0" [disabled]="j?.paid == 0"
                        [ngClass]="{ notPaidBtn: j?.paid == 0 }" style="cursor:default;color: white;">
                        UNPAID
                      </button>
                    </div>
                  </ng-template>
                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
          <!-- <tr>
            <th colspan="9">
              <div style="text-align: center">
                <button class="btn btn-outline-primary" [disabled]="rolnoArr.length == 0"
                  (click)="InsertAll(i?.m_list)">
                  Assign Roll No For Merit List {{ i?.m_list }}
                </button>
              </div>
            </th>
          </tr> -->
        </div>
      </table>
    </div>
  </form>
</div>