<div class="card" [@SlideInFromLeft]>
  <div class="card-header">Student Subject Attendance Profile</div>
  <div class="row card-body">
    <div class="col-sm-12 col-lg-12 p-2">
      <form #form='ngForm'>
        <div class="row">
          <div class="form-group col-sm-12 col-lg-4">
            <label for="course">Class</label>
            <select #c class="form-control " (change)="OnClassChange(c)" name="class" id="class">
              <option *ngFor="let c of classes" value="{{c.c_code}}">{{c.c_nm}}</option>
            </select>
          </div>
          <div class="form-group col-sm-12 col-lg-4">
            <label for="course" class="text-bold">Course</label>
            <select #course class="form-control" (change)="OnCourseChange(course)" name="course" id="course">
              <option *ngFor="let t of teams; let i=index;" value={{i}}>
                {{t.SUB_CODE}}-{{t.SUB_NM}}-{{t.SECTION}}-{{t.G_NM}}-{{t.T_NO}}</option>
            </select>
          </div>
          <div class="mt-4">
            <button class="btn btn-primary mt-1 ml-3" (click)="OnSubmit()" type="button" [disabled]="!form.valid">View Profile
            </button>
            <button *ngIf="this.btnHide" class="btn btn-outline-danger mt-1 ml-3" type="button"
              (click)="OnCreatePDF()">Download PDF</button>
          </div>
        </div>
      </form>
      <loader *ngIf="loading" class="mt-5"></loader>
    </div>
    <div class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0" style="overflow: hidden; overflow-x: scroll" *ngIf="students.length>0">
      <div class="card text-left">

        <div class="card-header"> <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><i
            class="fa fa-line-chart">Student Profile</i></div>
        <div class="card-body">
          <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1" *ngIf="students.length > 0">  
            <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
        </div>
          <table class="table table-bordered table-striped m-0 p-0" id="table" style="overflow: hidden; overflow-x: scroll">
            <thead class="thead-dark">
              <tr>
                <th>Sr.</th>
                <th>Roll No</th>
                <th>Name</th>
                <th>Total Lectures</th>
                <th>Presents</th>
                <th>Absents</th>
                <th>Leaves</th>
                <th>Percentage</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let std of students; let i = index" id="values">
                <td>
                  <div class="">{{i+1}}</div>
                </td>
                <td>
                  <div class="">{{std.ROLNO.toUpperCase()}}</div>
                </td>
                <td>
                  <div class="">{{std.NM.toUpperCase()}}</div>
                </td>
                <td>
                  <div class="badge badge-primary p-2">{{std?.DELIVERED}} Lectures</div>
                </td>
                <td>
                  <div class="badge badge-success p-2">{{std?.PRESENT}} Presents</div>
                <td>
                  <div class="badge badge-danger p-2">{{std?.ABSENT}} Absents</div>
                </td>
                <td>
                  <div class="badge badge-info p-2">{{std?.LEAV}} Leaves</div>
                </td>
                <td>
                  <div class="">{{(((std?.PRESENT+std?.LEAV)/std?.DELIVERED)*100||0).toFixed(0)}}%</div>
                </td>
                <td>
                  <div style="color: green" *ngIf="(((std?.PRESENT+std?.LEAV)/std?.DELIVERED)*100||0)>=80">Eligible</div>
                  <div style="color: red" *ngIf="(((std?.PRESENT+std?.LEAV)/std?.DELIVERED)*100||0)<80">Ineligible</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br>
    </div>
  </div>
</div>