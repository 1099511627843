import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';

import { DepartmentModel } from './models/Department.model';
import { ClassModel } from './models/Class.model';
import { MajorModel } from './models/Major.model';
import { SessionModel } from './models/Session.model';
import { StudentRollNumberModel } from './models/StudentRollNumber.model';
import { StudentNameModel } from './models/StudentName.model';
import { SubjectModel } from './models/Subject.model';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { GroupService } from './../shared/services/Groups.service';

import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { UtilService } from 'src/app/Util.service';
import { SubjectOfferService } from './../shared/services/SubjectOffer.service';
import { SessionService } from './../shared/services/Sessions.service';
import { SelectList } from '../shared/model/SelectList.model';
import { DepartmentService } from './../shared/services/Department.service';
import { ClassService } from './../shared/services/Class.service';
import { MajorService } from './../shared/services/Major.service';



@Component({
  selector: 'app-section-change',
  templateUrl: './section-change.component.html',
  styleUrls: ['./section-change.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class SectionChangeComponent implements OnInit {
  departments: Array<SelectList>;
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  RollNo: Array<StudentRollNumberModel>;
  Sname: Array<StudentNameModel>;
  subjects: Array<SubjectModel>;
  
  groups: Array<SelectList>;
  isAdmin: boolean = false;
  group: number;


  @ViewChild('f') formRef: NgForm;



  constructor(
    private authenticationService: AuthenticationService,
    private subOfferService: SubjectOfferService,
    private utilService: UtilService,
    private toastr: ToastrService,
    private sessionService: SessionService,
    private groupService: GroupService,

    private departmentService: DepartmentService,
    private classService: ClassService,
    private majorService:MajorService
  ) {
    this.departments = new Array<SelectList>();
    this.classes = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.RollNo = new Array<StudentRollNumberModel>();
    this.Sname = new Array<StudentNameModel>();
    this.subjects = new Array<SubjectModel>();
    this.groups = new Array<SelectList>();


    this.isAdmin = this.authenticationService.isAdmin();
    this.group = this.authenticationService.getGroup();
  }

  // ngOnInit(): void {
  //   this.departmentService.getDepartments({ admin: true }).subscribe((res: { D_ID: number, D_NM: string }[]) => {
  //     res?.forEach(entry => {
  //       this.departments.push({ id: entry.D_ID, nm: entry.D_NM });
  //     });
  //   });
  // }

  ngOnInit(): void {
    this.loadGroups();
  }

  
  loadGroups() {
    this.classes = [];
    this.majors = [];
    this.sessions = [];
    this.subjects = [];
    this.groupService.getGroups({ grp: this.group, role: this.isAdmin }).subscribe((res: { GRP: number, G_NM: string }[]) => {
      res?.forEach(element => {
        this.groups.push({ id: element.GRP, nm: element.G_NM });
      });

      if (!this.isAdmin) {
        this.updateClasses();
      }
    }, err => {
      console.log(err);
    });
  }


  
  updateClasses() {
    this.classes = [];
    this.majors = [];
    this.sessions = [];
    this.subjects = [];
    this.classService.getClasses({ grp: this.group }).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
    });
  }
  
  updateMajors() {
    this.majors = [];
    this.sessions = [];
    this.majorService.getMajor({ d_id: this.formRef.value.d_id, c_code: this.formRef.value.c_code }).subscribe((res: { MAJ_ID: number, MAJ_NM: string }[]) => {
      res?.forEach(entry => {
        this.majors.push({ id: entry.MAJ_ID, nm: entry.MAJ_NM });

      });
    });

  }

  updateSessions() {
    this.sessions = [];
    this.sessionService.getSessions(this.formRef.value.c_code).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(entry => {
        this.sessions.push({ id: entry.SE_ID, nm: entry.SE_NM });
      });
    });
  }

  upadteStudentName(fn: NgForm) {
    this.Sname = [];
    this.utilService.getStudentName(this.group.toString(),this.formRef.value.rn, fn.value.year_in, this.formRef.value.c_code).subscribe(res => {
      for (const i in res) {
        this.Sname.push(new StudentNameModel(res[i].S_NM));
      }
    }, err => {
      console.log(err);
    });
  }


  updateRollNo() {
    this.RollNo = [];
    this.utilService.getRollNumbers(this.formRef.value.se_id).subscribe(res => {
      for (const i in res) {
        this.RollNo.push(new StudentRollNumberModel(res[i].RN));
      }
    }, err => {
      console.log(err);

    });
  }


  updateSubjects() {
    this.subjects = [];
    this.subOfferService.getOfferedSub(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.se_id, this.formRef.value.t_no).subscribe(res => {
      for (const i in res) {
        this.subjects.push(new SubjectModel(res[i].SUB_CODE, res[i].SUB_NM));
      }
      if (this.subjects.length < 1) {
        this.toastr.warning("No subject offered yet");
      }
    });
  }


  inserting(fn: NgForm) {
    this.utilService.getSectionChangeInfo(fn.value.year_in, this.formRef.value.c_code, this.formRef.value.rn, this.formRef.value.t_no, fn.value.in, this.formRef.value.sub_code).subscribe((res:{msg:string}) => {
      if(!res){
        this.toastr.error("Some Error Occured!");
      }
      else if(res.msg){
       this.toastr.success(res.msg);
     }     
    }, err => {
      console.log(err);
      this.toastr.error("Some Error Occured!");
     });
  }


  OnSubmit() {
    if (!this.formRef.valid)
      return;
  }

  OnSemesterChange(sem: HTMLSelectElement) {
    this.updateSubjects();
  }


  OnSubjectChange(sub: HTMLSelectElement) {
  }
  OnGrpChange(grp) {
    this.group = parseInt(grp.value);
    this.updateClasses();
  }


  OnRollNoChange(fn: NgForm) {
    this.upadteStudentName(fn);
  }

  OnSessionChange(s: HTMLSelectElement) {
    this.updateRollNo();
  }

  OnMajorChange(m: HTMLSelectElement) {
    this.updateSessions();
  }


  OnClassChange(c: HTMLSelectElement) {
    this.updateMajors();
  }

  OnDeptChange(d: HTMLSelectElement) {
    this.updateClasses();
  }


}







