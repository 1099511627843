import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
  providedIn: 'root'
})

export class ExaminationService {

  constructor(private http: HttpClient) {
  }
  OnSubjectStrength(params) {
    return this.http.post(`${baseUrl}/api/examination/OnSubjectStrength`, params);
  }

  UpdateInfo(c_code: number, se_id: number, tr_no: number, rn: number, maj_id: number, section: string, year: number, sub_code: string, quiz_total: number, press_total: number, mid_total: number, final_total: number, project_total: number, quiz_obtain: number, press_obtain: number, mid_obtain: number, final_obtain: number, project_obtain: number,) {
    return this.http.post(`${baseUrl}/api/examination/OnEvaluation`, { c_code: c_code, se_id: se_id, t_no: tr_no, rn: rn, maj_id: maj_id, section: section, year: year, sub_code: sub_code, quiz_total: quiz_total, press_total: press_total, mid_total: mid_total, final_total: final_total, project_total: project_total, quiz_obtain: quiz_obtain, press_obtain: press_obtain, mid_obtain: mid_obtain, final_obtain: final_obtain, project_obtain: project_obtain });
  }
  UpdateThesisAwardList(c_code: number, rn: number, year: number, maj_id: number, se_id: number, t_no: number, section: string, sub_code: string, thesisFinal: number, thesisObtain: number) {
    return this.http.post(`${baseUrl}/api/examination/ThesisAwardList`, { c_code: c_code, rn: rn, year: year, maj_id: maj_id, se_id: se_id, t_no: t_no, section: section, sub_code: sub_code, thesisFinal: thesisFinal, thesisObtain: thesisObtain });
  }
  CheckThesisAwardList(c_code: number, rn: string, year: number, maj_id: number, se_id: number, t_no: number, section: string, sub_code: string) {
    return this.http.post(`${baseUrl}/api/examination/enterThesisAwardListFah`, { c_code: c_code, rn: rn, year: year, maj_id: maj_id, se_id: se_id, t_no: t_no, section: section, sub_code: sub_code });
  }
  deleteThesisAwardListByS(c_code: number, rn: string, year: number, maj_id: number, se_id: number, t_no: number, section: string, sub_code: string) {
    return this.http.post(`${baseUrl}/api/examination/deleteThesisAwardListByS`, { c_code: c_code, rn: rn, year: year, maj_id: maj_id, se_id: se_id, t_no: t_no, section: section, sub_code: sub_code });
  }
  GetSectionForCourse(year: number, c_code: number, maj_id: number, se_id: number, rn: number, sub_code: string) {
    return this.http.post(`${baseUrl}/api/examination/GetSection`, { year: year, c_code: c_code, maj_id: maj_id, se_id: se_id, rn: rn, sub_code: sub_code });
  }
  getSectionsAagianstCourse(se_id: number, c_code: number, t_no: number, year: number, maj_id: number, sub_code: string) {
    return this.http.post(`${baseUrl}/api/examination/getSectionsAagianstCourse`, { se_id: se_id, c_code: c_code, t_no: t_no, year: year, maj_id: maj_id, sub_code: sub_code });
  }
  EXAM_insetLetterGrade(params) {
    return this.http.post(`${baseUrl}/api/examination/EXAM_insetLetterGrade`, params);
  }

  EXAM_updateLetterGrade(params) {
    return this.http.post(`${baseUrl}/api/examination/EXAM_updateLetterGrade`, params);
  }

  getStudentsByS(c_code: number, maj_id: number, se_id: number, t_no: number, sub_code: string, section: string) {
    return this.http.post(`${baseUrl}/api/examination/getStudentsByS`, { c_code: c_code, maj_id: maj_id, se_id: se_id, t_no: t_no, sub_code: sub_code, section: section });
  }
  getStudentsByenrollS(c_code: number, maj_id: number, se_id: number, t_no: number, sub_code: string, section: string) {
    return this.http.post(`${baseUrl}/api/examination/getStudentsByenrollS`, { c_code: c_code, maj_id: maj_id, se_id: se_id, t_no: t_no, sub_code: sub_code, section: section });
  }
}