import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SelectList } from '../../shared/model/SelectList.model';
import { ClassService } from '../../shared/services/Class.service';
import { reportsFeeService } from '../../shared/services/reportsFee.service';
import { FeeService } from '../../shared/services/Fee.service';
import { jsPDF } from 'jspdf';
import { DatePipe } from "@angular/common";
import { EnrollmentService } from '../../shared/services/Enrollment.service';
import autoTable from "jspdf-autotable";
import "jspdf-autotable";
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';
import { Subscription } from 'rxjs';
import { filter } from 'src/app/shared/functions/tableSearch';
@Component({
  selector: 'app-demand-collection-report-class-wise',
  templateUrl: './demand-collection-report-class-wise.component.html',
  styleUrls: ['./demand-collection-report-class-wise.component.css'],
  animations: [SlideInFromLeft()]
})
export class DemandCollectionReportClassWiseComponent implements OnInit {

  classDT: Array<SelectList>;
  isAdmin: boolean = false;
  sessions: any;
  rolno: Array<SelectList>;
  allData: Array<{ rolno, name, demand, collection, remarks }>;
  @ViewChild('f') formRef: NgForm;
  instNo: Array<number>;
  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private classService: ClassService,
    private datePipe: DatePipe,
    private reportsFee: reportsFeeService,
    private enrollmentService: EnrollmentService,
    private feeService:FeeService,
    private feeSharedService: FeeSharedDataService
  ) {
    this.classDT = new Array<SelectList>();
    this.allData = new Array<{ rolno, name, demand, collection, remarks }>();
    this.isAdmin = this.authenticationService.isAdmin();
    this.rolno = new Array<SelectList>();
    this.instNo = new Array<number>();

  }

  ngOnInit(): void {
    this.updateSessions();
    this.updateClasses();
    this.updateInstNO();
  }

  updateClasses() {
    this.classDT = [];
    this.feeSharedService.getFeeClasses()
      .then(classes => {
        this.classDT = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateInstNO() {
    this.instNo = []
    this.feeSharedService.getFeeInst()
    .then(inst => {
      this.instNo = inst;
    })
    .catch(error => {
      console.error("Error getting inst", error);
    });
  }

  updateSessions() {
    this.sessions = []
    this.feeSharedService.getFeeSessions()
      .then(sessions => {
        this.sessions = sessions;
      })
      .catch(error => {
        console.error("Error getting fee session", error);
      });
  }
  
  onSubmit(){}

  getRolno() {
    this.rolno = [];
    this.enrollmentService.notEnroll(this.formRef.value).subscribe((res: { rn: number, rolno: string }[]) => {
      res?.forEach(entry => {
        this.rolno.push({ id: entry.rn, nm: entry.rolno });
      })
    });
  }

  getData() {
    this.allData = [];
    this.reportsFee.onDemandCollectionReport(this.formRef.value).subscribe((res: { rolno: string, nm: string, demand: number, collection: number, remarks: string }[]) => {
      res?.forEach(element => {
        this.allData.push({ rolno: element.rolno, name: element.nm, demand: element.demand, collection: element.collection, remarks: element.remarks });
      });
    });
  }

  onPdfDownload(c: HTMLSelectElement) {
    if (this.allData?.length == 0) {
      this.toastr.warning("No Record Found");
      return;
    }
    else {
      const body = [];
      const header = [];

      var exportDate = this.datePipe.transform(new Date(), 'MMM d, y');
      var image = new Image();
      var page;
      var sessionYear = this.formRef.value.year_in;
      var inst = this.formRef.value.inst_no;
      var groupClass = this.classDT[c.selectedIndex].nm;
      image.src = '../../../assets/images/logo3.png';
      for (let i = 0; i < this.allData.length; i++) {
        let k = i + 1;
        body.push([
          k,
          this.allData[i].rolno,
          this.allData[i].name,
          this.allData[i].demand,
          this.allData[i].collection,
          this.allData[i].remarks
        ]);
      }

      header.push(['Sr#', 'Roll Number', 'Name', 'Demand', 'Collection', 'Remarks']);

      const doc = new jsPDF('l', 'mm', 'A4');

      doc.addImage(image, 257, 5, 25, 28);
      doc.setFontSize(10);
      doc.text(`${exportDate}`, 261, 37);
      doc.setFontSize(22);
      doc.setFont('Arial', 'bold');
      doc.text("GC UNIVERSITY, LAHORE", 150, 15, { align: 'center' });
      doc.setFontSize(20);
      doc.setFont('Arial', 'bold');
      doc.text("Class Wise Demand Collection Report", 150, 26, { align: 'center' });
      doc.setFontSize(14);
      doc.text("Session: " + sessionYear, 150, 34, { align: 'center' });
      doc.setFontSize(16);
      doc.text("Class: " + groupClass, 150, 42, { align: 'center' });
      doc.setFontSize(14);
      doc.text("Installment: " + inst, 150, 50, { align: 'center' });
      autoTable(doc, {
        // theme: 'grid',
        theme: 'striped',
        startY: 55,
        margin: { top: 5, bottom: 10 },
        styles: { fontSize: 11 },
        head: header,
        body: body,
        didDrawPage: function () {
          page = '';
          page = doc.getNumberOfPages();
        }
      });
      doc.setFontSize(10);
      for (var j = 1; j <= page; j++) {
        doc.setPage(j);
        doc.text('Page ' + j + " of " + page, 140, 205);
        doc.text("Note:- Errors and Omissions Excepted", 235, 205);
        doc.text("Directorate of Information Technology", 10, 205);
      }
      doc.text("Treasurer: ", 215, 200);
      doc.setDrawColor('black');
      doc.line(235, 200, 260, 200);
      doc.text('Checked By: ', 20, 200);
      doc.setDrawColor('black');
      doc.line(40, 200, 70, 200);

      // doc.save('Demand_Collection_Report(' + sessionYear + ')');
      window.open(URL.createObjectURL(doc.output('blob')), 'Demand_Collection_Report(' + sessionYear + ')');
    }
  }

  onClear() {
    this.formRef.reset();
    this.allData = [];
  }

  ClearClassBase() {
    this.formRef?.controls['year_in'].reset();
    this.formRef?.controls['inst_no'].reset();
  }

    ClearYearBase() {
    this.formRef?.controls['inst_no'].reset();
  }

  Filter() {
    filter();
  }
}