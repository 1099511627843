import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
import { Observable } from 'rxjs';
export interface IsDetail {
  ID: number,
  YEAR: number,
  C_CODE: number,
  SE_ID: number,
  RN: number,
  D_O_NOTIF: Date,
  P_YEAR: number,
  DEG_REC_YEAR: number,
  DEG_REC_DATE: Date,
  DEG_NO: number,
  DT: Date,
  MAJ_ID: number,
  DEG_CTGRY: string,
  TRNS_NO: number
}
@Injectable({
  providedIn: 'root'
})

export class DegreeprintService {

  constructor(private http: HttpClient) { }



  getDegreeInfo(c_code: number, maj_id: number, notifDate: Date, notifDate1: Date, category: string, t_no: number) {
    return this.http.get(`${baseUrl}/api/degree/degreeprint/${c_code}/${maj_id}/${notifDate}/${notifDate1}/${category}/${t_no}`);
  }

  getStudentAddressByS(c_code: number, maj_id: number, notifDate: Date, notifDate1: Date, category: string) {
    return this.http.get(`${baseUrl}/api/degree/getStudentAddressByS/${c_code}/${maj_id}/${notifDate}/${notifDate1}/${category}`);
  }

  getStudentDetailsByEndRolNo(c_code: number, maj_id: number, notifDate: Date, notifDate1: Date, category: string, st_rn: number, year: number) {
    return this.http.get(`${baseUrl}/api/degree/getStudentDetailsAddressByS/${c_code}/${maj_id}/${notifDate}/${notifDate1}/${category}/${st_rn}/${year}`);
  }
  getStudentAddressBetweenSt_end_rnByS(c_code: number, maj_id: number, notifDate: Date, notifDate1: Date, category: string, st_rn: number, end_rn: number, st_year: number, end_year: number) {
    return this.http.get(`${baseUrl}/api/degree/getStudentAddressBetweenSt_end_rnByS/${c_code}/${maj_id}/${notifDate}/${notifDate1}/${category}/${st_rn}/${end_rn}/${st_year}/${end_year}`);
  }
  getInfo(c_code: number, maj_id: number, year: string) {
    return this.http.get(`${baseUrl}/api/degree/degreerecieveinfo/${c_code}/${maj_id}/${year}`);
  }
  UpdateInfo(c_code: number, deg_no: number, year: number, rn: number, se_id: number, deg_rcv_date: Date, trans_no: number) {
    return this.http.post(`${baseUrl}/api/degree/updateinfo`, { c_code: c_code, deg_no: deg_no, year: year, rn: rn, se_id: se_id, deg_rcv_date: deg_rcv_date, trans_no: trans_no });
  }
//update degree category

UpdateDegCtegory(c_code: number, year: number, rn: number, se_id: number,category:string) {
  return this.http.get(`${baseUrl}/api/degree/UpdateDegCtegory/${c_code}/${year}/${rn}/${se_id}/${category}`);
}
  // ELIGIBLE CANDIDATE  OR SIGNATURE FOR DEGREE HOLDERS
  getEligibleCandidateForDegree(c_code: number,t_no:number,se_id:number, maj_id: number, stDate: Date, endDate1: Date, category: string, status: number) {
    return this.http.get(`${baseUrl}/api/degree/getEligibleCandidateForDegree/${c_code}/${t_no}/${se_id}/${maj_id}/${stDate}/${endDate1}/${category}/${status}`);
  }
  // delete from deg info table
  DeleteFromDegInfo(c_code: number, maj_id: number,rn:number,  stu_year: number) {
    return this.http.get(`${baseUrl}/api/degree/DeleteFromDegInfo/${c_code}/${maj_id}/${rn}/${stu_year}`);
  }

   // Get Status of Trascript Number
   getTranscriptStatus(c_code: number,st_year:number,end_year:number, stDate: Date, endDate1: Date) {
    return this.http.get(`${baseUrl}/api/degree/getTranscriptStatus/${c_code}/${st_year}/${end_year}/${stDate}/${endDate1}`);
  };

  //update degree date of notification

UpdateD_O_Notif(c_code: number, year: number, rn: number, se_id: number,D_O_NOTIF:Date) {
  return this.http.get(`${baseUrl}/api/degree/UpdateD_O_Notif/${c_code}/${year}/${rn}/${se_id}/${D_O_NOTIF}`);
};

  //update degree date of notification

  UpdateP_Year(c_code: number, year: number, rn: number, se_id: number,p_year:string) {
    return this.http.get(`${baseUrl}/api/degree/UpdateP_Year/${c_code}/${year}/${rn}/${se_id}/${p_year}`);
  }

}
