<!-- <div (click)="toggleMenu()" class="menu-icon-container">
    <i class="menu-icon"></i>
</div> -->

<!-- Expandable Menu -->
<!-- <div *ngIf="isMenuExpanded" class="menu-content">
    <app-faq-button *ngIf="isAdmin" (openFaqModal)="toggleFaqModal()"></app-faq-button>
    <app-faq-modal *ngIf="isAdmin" [isOpen]="isFaqModalOpen"></app-faq-modal>
</div> -->


<div *ngIf="showManuButton" class="menu-wrapper">
  <!-- Main Button -->
  <button
    class="btn btn-menu"
    (click)="toggleMenu()"
    aria-expanded="false"
    [attr.aria-controls]="'dropup-menu'"
  >
    <img src="../assets/menu.png" alt="Menu" class="menu-icon" />
  </button>

  <!-- Drop-Up Buttons -->
  <div
    id="dropup-menu"
    class="btn-group-vertical dropup-menu"
    *ngIf="isMenuExpanded"
  >
    <button class="btn" (click)="openModal('searchStudent')">
      <img src="../assets/search.png" alt="Search" class="menu-icon" />
    </button>
    <button class="btn" (click)="openModal('faq')">
      <img src="../assets/question.png" alt="FAQ" class="menu-icon" />
    </button>
  </div>
</div>



<app-faq-modal *ngIf="showManuButton" [isOpen]="isFaqModalOpen"></app-faq-modal>

<!-- <app-faidnamesearch *ngIf="isAdmin && isSearchStudentOpen"></app-faidnamesearch> -->



<div class="container">
  <div class="modal fade" tabindex="-1" role="dialog" [ngClass]="{'show': isSearchStudentOpen}"
    [ngStyle]="{'display': isSearchStudentOpen ? 'block' : 'none'}">
    <div class="modal-dialog modal-dialog-centered custom-modal" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Search Student</h5>
          <button type="button" class="close" (click)="hideModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <div>
            <div class="card shadow bg-white border-0 mt-3 mb-5 custom-modal-card" id="tablecard">

              <app-faidnamesearch *ngIf="showManuButton"></app-faidnamesearch>
              
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="hideModal()">Close</button>
        </div> -->
      </div>
    </div>
  </div>
</div>


<router-outlet></router-outlet>
