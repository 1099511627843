import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { SessionService } from '../shared/services/Sessions.service';

interface Sessions{
  se_id:number,
  se_st_dt:Date,
  se_e_dt:Date,
  session_year:string,
  gp_per:string,
  att_per:string,
  p_criteria:number
 }

@Component({
  selector: 'app-new-class',
  templateUrl: './new-class.component.html',
  styleUrls: ['./new-class.component.css']
})
export class NewClassComponent implements OnInit {
 classes: Array<SelectList>;
  @ViewChild('f') formRef: NgForm;
  newSe_id: number;
  sessions: Array<Sessions>;
  cls;
  st_dt_s;
  end_dt_e;
  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private classService: ClassService,
    private sessService: SessionService
  ) {
    this.sessions= new Array<Sessions>();
    this.classes = new Array<SelectList>();
  }

  ngOnInit(): void {
    this.st_dt_s = moment(new Date()).format('YYYY-MM-DD');
    this.end_dt_e = moment(new Date()).format('YYYY-MM-DD');


    this.classes = [];
    this.classService.getClasses('-1').subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
      this.cls = this.classes[0].id;
      if(this.classes?.length){
      this.getSessions(this.cls);
      }
    });
    this.onEndYearChange();
  }

  onEndYearChange() {
    this.sessService.getNewSe_id().subscribe((res: { newSe_id: number }[]) => {
      this.newSe_id = (1 + res[0].newSe_id);
    },
      (err) => {
        this.toastr.error("Already Exist");
      });
      this.getSessions(this.formRef?.value.C_Code);
  }

  insertSession() {
    if (!this.formRef.valid) {
      this.toastr.warning("Please Enter All Fields");
      return;
    }

    this.sessService.insertSession(this.formRef.value).subscribe((res) => {
      this.toastr.success("Successfully Added");
    },
      (err) => {
        this.toastr.error("Already Exist");
      });

      this.sessService.insertSessionFeeServer(this.formRef.value).subscribe((res) => {
      })
    this.onEndYearChange();
  }

  OnSubmit() {
    if (!this.formRef.valid)
      return;
  }
  getSessions(c:any) {
    this.sessions = new Array<Sessions>(
    );
    this.sessService.getSessionsAll(c).subscribe((res) => {
      if(res){
      res[0]?.forEach(element => {
        this.sessions.push(element);
      });
    }
    });
  }
  deleteSession(index:any,se_id:any){
    this.sessService.deleteSessionID(se_id).subscribe((res:{ msg: string, warning: string})=>{
      if(res){
        this.toastr.success(res.msg);
        this.getSessions(this.formRef.value.C_Code);
      }
        else
        this.toastr.warning("You can't delete this SE_ID");
    }, err=>{
      this.toastr.error("Unknown error");      
    });
  }
}