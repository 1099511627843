<form #f="ngForm">
    <div [@SlideInFromLeft] class="container-fluid ">
        <div class="card shadow ">

            <div class="card-header">
                <div>Lock Student</div>
            </div>
            <div class="row card-body mb-0">

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="year">Year</label>
                    <input type="number" class="form-control"
                        placeholder="Admission Year" min="2000"
                        name="year" id="year" required ngModel>
                </div>

                <div class="col-lg-4 col-md-6 form-group ">
                    <label for="c_code ">Class</label>
                    <select #c_code class="form-control " name="c_code"
                        id="c_code"
                        (change)="OnClassChange()" ngModel required>
                        <option *ngFor="let i of class"
                            value="{{i.id}}">{{i.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 form-group ">
                    <label for="maj_id">Major</label>
                    <select class="form-control" #major_id name="maj_id"
                        id="maj_id" (change)="OnMajorChange()" ngModel required>
                        <option *ngFor="let i of majors"
                            value="{{i.id}}">{{i.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 form-group ">
                    <label for="se_id ">Session</label>
                    <select class="form-control" #se_id name="se_id" id="se_id"
                        (change)="OnSessionChange()" ngModel required>
                        <option *ngFor="let i of sessions"
                            value="{{i.id}}">{{i.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group ">
                    <label for="t_no ">Semester</label>
                    <select class="form-control " #t_no name="t_no" id="t_no"
                        ngModel required>
                        <option *ngFor="let i of [0,1,2,3,4,5,6,7,8] "
                            value="{{i}}">{{i}}</option>
                    </select>
                </div>
                <div style="margin-top:25px;">
                    <button class="btn btn-outline-primary ml-3 mt-1 "
                        [disabled]="!f.valid" (click)="OnClickbutton()">
                        Student Details</button>
                    <button class="btn btn-warning ml-2 mt-1 "
                        (click)="OnClear()">Clear
                    </button>
                    <!-- <img *ngIf="isloader " style="width:100%; height: 100px; " src="../../assets/loader.gif " alt="loading gif "> -->
                </div>
            </div>
            <div class="center col-12">
                <loader *ngIf="loader"></loader>
            </div>

            <div class="row card-body ml-1 mt-0 pt-0"
                style="overflow: hidden; overflow-x: scroll;" *ngIf="clickcheck">
                <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1" *ngIf="students.length > 0">  
                    <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
                </div>
                <table class="table table-bordered table-striped"
                    style="overflow: hidden; overflow-x: scroll" *ngIf="students?.length>0" id="table">
                    <thead class="thead-dark">
                        <tr>
                            <th style="width: 4vw;">Sr #</th>
                            <th style="width: 8vw;">Year</th>
                            <th style="width: 12vw;">Roll #</th>
                            <th style="width: 15vw;">Registration #</th>
                            <th style="width: 12vw;">Name</th>
                            <th style="width: 12vw;">Father Name</th>
                            <th style="width: 7vw;">Lock Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of students; let j=index" id="values">
                            <td>
                                {{j+1}}
                            </td>
                            <td>
                                {{i.YEAR}}
                            </td>
                            <td>
                                {{i.ROLNO}}
                            </td>
                            <td>
                                {{i.REG_NO}}
                            </td>
                            <td>
                                {{i.NM}}
                            </td>
                            <td>
                                {{i.F_NM}}
                            </td>
                            <td>
                                <input
                                    style="margin-top:1%;transform:scale(1.5);cursor:pointer;"
                                    type="checkbox" value [checked]='i.LOC==12'
                                    (click)="onCheckboxChange($event,i)">
                            </td>
                        </tr>
                    </tbody>

                </table>
                <div class="ml-1 d-flex" *ngIf="students?.length>0" >
                    <button style="width: 6vw; position:relative; float: right;" class="btn btn-warning mr-3"
                        id="btn" (click)="OnLockClick()">Lock</button>
                    <button style="width: 6vw; position:relative; float: right" class="btn btn-danger mr-3"
                        id="btn" (click)="OnUndoClick()">Unlock</button>
                </div>
            </div>
        </div>
    </div>
</form>