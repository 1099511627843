<div [@SlideInFromLeft]>
  <div class="card shadow">

    <div class="card-header"  style="padding-top: 10px;">
      <div class=" col-lg-6 col-md-6 form-group">
        Subject Wise Strength Report</div>
         <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
        <app-details-button [details]="details"></app-details-button>
    </div>
  </div>
    <form (ngSubmit)=" termWiseStrenght()" #f="ngForm" class="row">
      <div class="card-body row">

        <div class="col-lg-4 col-md-6 form-group">
          <label for="C_Code">Class</label>
          <select #cource (change)="OnClassChange()" class="form-control " name="c_code" id="C_Code" ngModel required>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Session">Session</label>
          <select class="form-control " #session name="se_id" id="Session" ngModel required>
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="semester">Semester</label>
          <select #t_no class="form-control" name="t_no" id="t_no" ngModel required>
            <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]" value="{{i}}">{{i}}</option>
          </select>
        </div>
        <div class="row ml-2">
          <button [disabled]="!f.valid" class="btn btn-primary mt-3 ml-2" type="submit">Term Wise
            Strength</button>
          <button [disabled]="!f.valid" class="btn btn-primary ml-2 mt-3" type="button"
            (click)="OnSubmit()">Section Wise Strength</button>
            <button [disabled]="!f.valid" class="btn btn-primary ml-2 mt-3" type="button"
            (click)="majorWiseStrenght()">Major and Section Wise Strength</button>
          <button [disabled]="!(this.refineStd?.length>0) && !(this.stdStrenght?.length>0) && !(this.majcount?.length>0)"
            class="btn btn-danger ml-2 mt-3" type="button" (click)="OnCreatePDF()">
            <i class="fa fa-file-pdf-o" for=""></i>
            Download PDF</button>
          <button class="btn btn-warning ml-2 mt-3" type="button" (click)="ClearForm(f)">Clear</button>
        </div>

      </div>
    </form>
    <loader *ngIf="loader"></loader>
    <div *ngIf="this.refineStd?.length>0 " class="card-body mb-3 px-4 mt-0"
      style=" min-height: 15rem;">
      <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
        <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
    </div>
      <table class="table table-bordered table-striped m-0 p-0 " id="table">
        <thead class="thead-dark">
          <tr class="table-active">
            <th>Sr #</th>
            <th>Subject Code</th>
            <th>Subject Title</th>
            <th>Section</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let roll of refineStd; let i=index" id="values">
            <td>
              {{i+1}}
            <td>
              {{roll?.SUB_CODE}}
            </td>
            <td>
              {{roll?.SUB_TITLE}}
            </td>
            <td>
              {{roll?.SECTION}}
            </td>
            <td>
              {{roll?.TOTAL}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="majcount?.length > 0" class="card-body mb-3 px-4 mt-0" style="min-height: 15rem;">
      <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
        <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
      </div>
    
      <table class="table table-bordered table-striped m-0 p-0" id="table">
        <thead class="thead-dark">
          <tr class="table-active">
            <th>Sr #</th>
            <th>Subject Code</th>
            <th>Subject Title</th>
            <th>Major</th>
            <th>Section</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of majcount; let i = index" id="values">
            <td>{{ i + 1 }}</td>
            <td>{{ item.SUB_CODE }}</td>
            <td>{{ item.SUB_TITLE }}</td>
            <td>{{ item.MAJOR }}</td>
            <td>{{ item.SECTION }}</td>
            <td>{{ item.TOTAL }}</td>
          </tr>
        </tbody>
      </table>
    </div>
        <div *ngIf="this.stdStrenght?.length>0" class="card-body mb-3 px-4 mt-0"
      style=" min-height: 15rem;">
      <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
        <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
    </div>
    <table class="table table-bordered table-striped m-0 p-0" id="table">
      <thead class="thead-dark">
        <tr>
          <th>Sr #</th>
          <th>Subject Code</th>
          <th>Subject Title</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let roll of stdStrenght; let i=index" id="values">
          <td>
            {{i+1}}
          <td>
            {{roll?.SUB_CODE}}
          </td>
          <td>
            {{roll?.SUB_TITLE}}
          </td>
          <td>
            {{roll?.TOTAL}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>