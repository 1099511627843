import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';

import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { FeeService } from '../../shared/services/Fee.service';
import { SelectList } from '../../shared/model/SelectList.model';


@Component({
  selector: 'app-fee-description',
  templateUrl: './fee-description.component.html',
  styleUrls: ['./fee-description.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class FeeDescriptionComponent implements OnInit {
  feeDesc: Array<SelectList>;

  @ViewChild('f') formRef: NgForm;


  constructor(
    private toastr: ToastrService,
    private feeService:FeeService

  ) { 
    this.feeDesc = new Array<SelectList>();
  }


  ngOnInit(): void {
    this.loadFeeDescription();
  }

  OnSubmit(){
  }

  loadFeeDescription() {
    this.feeDesc = []; 
    this.feeService.getFeeDescription().subscribe((res: { fee_id: number, fee_nm: string }[]) => {
      res?.forEach(element => {
        this.feeDesc.push({ id: element.fee_id, nm: element.fee_nm });
      });
    }, err => {
      console.log(err);
    });
  }

  addFeeDescription() {
    if(this.formRef.valid){
      this.feeService.addFeeDescription(this.formRef.value.fee_id, this.formRef.value.fee_desc).subscribe((res:{warning:string,fee_id:number,fee_nm:string}) => {
        if(!res){
          this.toastr.error("Unknown Error");
        }
        else if(res.warning){
          this.toastr.warning(res.warning);
        }
        else{
          this.feeDesc.push({id:res.fee_id,nm:res.fee_nm});
          this.toastr.success("Add Successfully!");        }
      }, err => {
        this.toastr.error("Some Error Occured during Adding Fee Description!");
      });
    }
    else{
      this.toastr.warning("Some Fields are Missing!");
    }
  }

  deleteFee(id,index){
      this.feeService.deleteFeeDescription(id).subscribe((res:{msg:string, warning:string}) => {
        if(!res){
          this.toastr.error("Unknown Error");
        }
        else if(res.msg){
          this.toastr.success(res.msg);
          this.feeDesc.splice(index,1);
        }
        else{
          this.toastr.warning(res.warning);
        }
      }, err => {
        this.toastr.error("Some Error Occured during Adding Fee Description!");
      });
  }

  ClearForm(fn: NgForm) {
    fn.resetForm();
  }

  ClearFeeIDBase() {
    this.formRef?.controls['fee_desc'].reset();
  }

}

