<form (ngSubmit)="OnSubmit()" #f="ngForm">
  <div [@SlideInFromLeft] class="tabber">
    <div class="container-fluid mb-2 card shadow">
      <div  class="card-header"  style="padding-top: 10px;">
        <div class=" col-lg-6 col-md-6 form-group">Insert Time Table</div>
        
        <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
          <app-details-button [details]="details"></app-details-button>
      </div>
      </div>
      <div class="row card-body mt-3 space-remover">
<!-- adding intermediate -->
        <div class="col-lg-6 form-group">
          <label for="Day">Group</label>
          <input *ngIf="!isAdmin&&!isExam  && this.isPhilosophy!=410  && this.isPhilosophy!=320 && !this.showInter" type="text" class="form-control"
            value='{{ ((this.groups.length>0) ? groups[0].nm:" ")}}' name="grp" id="grp" readonly>
          <select *ngIf="isAdmin && this.isPhilosophy!=410" #grp class="form-control" name="grp" id="grp" (change)="OnGrpChange(grp)" ngModel>
            <option *ngFor="let grp of groups" value="{{grp.id}}">{{grp.nm}}</option>
          </select>
          <select *ngIf="this.isPhilosophy==410 || this.isPhilosophy==320 || showInter" #grp class="form-control" name="grp" id="grp" (change)="OnGrpChange(grp)" ngModel>
            <option *ngFor="let grp of groups" value="{{grp.id}}">{{grp.nm}}</option>
          </select>
          <!-- <select *ngIf="showInter&&this.isPhilosophy!=410" #grp class="form-control" name="grp" id="grp" (change)="OnGrpChange(grp)" ngModel>
            <option *ngFor="let grp of groups" value="{{grp.id}}"> {{grp.nm}} </option>
          </select> -->
        </div>

        <div class="col-lg-6 form-group">
          <label for="C_Code">Class</label>
          <select #cource (change)="OnClassChange(cource)" class="form-control " name="c_code" id="C_Code" ngModel>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-6 form-group">
          <label for="Session">Session</label>
          <select class="form-control " #session (change)="OnSessionChange(session)" name="se_id" id="Session" ngModel>
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>

        <div class="col-lg-6 form-group">
          <label for="semester">Semester</label>
          <select #semester (change)="OnSemesterChange(semester)" class="form-control " name="t_no" id="Semester" required
            ngModel>
            <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]" value="{{i}}">{{i}}</option>
          </select>
        </div>
        

        <div *ngIf="isAdmin" class="col-lg-6 form-group">
          <label for="Department">Department</label>
          <select #department (change)="OnDeptChange(department)" class="form-control" name="d_id"
            id="Department"
            required ngModel>
            <option *ngFor="let d of departments" value="{{d.id}}">{{d.nm}}</option>
          </select>
        </div>

        <div class="col-lg-6 form-group">
          <label for="Subject">Subject</label>
          <select #subject class="form-control" name="sub_code" id="Subject" (focus)="checkSubject()" (change)="onSubChange(subject)" ngModel required>
            <option *ngFor="let sub of subjects; let i=index;"  value="{{i}}">{{sub.sub_code}}  ({{sub.sub_nm}} )</option>
          </select>
        </div>

        <div class="col-lg-6 form-group">
          <label for="Teacher">Teacher</label>
          <select #teacher class="form-control" (change)="OnTeacherChange(teacher)"
            [ngClass]="{ 'is-invalid': !isTeacherFree}" name="fm_id" id="Teacher" ngModel>
            <option *ngFor="let fm of teachers" value="{{fm.id}}">{{fm.nm}}</option>
            <option  value="-1">Not Assigned</option>

          </select>
          <div *ngIf="!isTeacherFree" class="invalid-feedback">
            <div *ngIf="!isTeacherFree">Teacher is not Available</div>
          </div>
        </div>


        <div *ngIf="isAdmin" class="col-lg-3 form-group">
          <label for="Section">Section</label>
          <input type="text" class="form-control" name="section" id="Section" (change)="OnSectionChange()"
            [ngClass]="{ 'is-invalid': !isSectionFree }" style="text-transform:uppercase" ngModel required>
          <div *ngIf="!isSectionFree" class="invalid-feedback">
            <div *ngIf="!isSectionFree">Section Is not Available</div>
          </div>
        </div>
        <div *ngIf="!isAdmin" class="col-lg-3 form-group">
          <label for="Section">Section</label>
          <select class="form-control" name="section" id="Section" (change)="OnSectionChange()"
          [ngClass]="{ 'is-invalid': !isSectionFree }" style="text-transform:uppercase" ngModel required>
          <option hidden></option>
            <option *ngFor="let sec of sectionScheme" value="{{sec}}">{{sec}}</option>
          </select>
        </div>

        <div class="col-lg-3 form-group">
          <label for="Day">Day</label>
          <select #day class="form-control " name="day" id="Day" ngModel required (change)="OnDayChange(day)">
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
            <option value="Sunday">Sunday</option>
          </select>
        </div>

        <div class="timess col-lg-3 form-group">
          <label for="startTime">Start Time</label>
          <select #time class="form-control" (change)="OnTimeChange(time)"
            [ngClass]="{ 'is-invalid': !isTeacherFree || !isSectionFree }" name="s_time" id="startTime" ngModel>
            <option *ngFor="let t of timings" value={{t.start_time}}>{{t.desc}}</option>
          </select>

          <div *ngIf="!isTeacherFree || !isSectionFree" class="invalid-feedback">
            <div *ngIf="!isTeacherFree || !isSectionFree">Time Slot is not Available</div>
          </div>
        </div>

        <div class="col-lg-3 form-group">
          <label for="Building" class="text-bold">Duration</label>
          <select #dur class="form-control" name="duration" id="duration" ngModel required (change)="OnDurChange(dur)">
            <option *ngFor="let d of durations" value="{{d.dur}}">{{d.des}}</option>
          </select>
        </div>

        <div class="col-lg-3 form-group">
          <label for="Building" class="text-bold">Building</label>
          <select #blk class="form-control" name="blk_id" id="Building" ngModel required
            (change)="OnBuildingChange(blk)">
            <option *ngFor="let b of blocks" value="{{b.id}}">{{b.nm}}</option>
          </select>
        </div>

        <div class="col-lg-3 form-group">
          <label for="Room">Room</label>
          <select class="form-control" (change)="onRoomChange()" name="rm_id" id="Room" ngModel required >
            <option *ngFor="let r of rooms;" value="{{r.id}}">{{r.nm}}</option>
          </select>
        </div>
        <div class="col-lg-12 p-0">
         
              <div class="di p-0 bg-light"> 
                <hr class="p-0">  
                <div class="col-lg-12">     
          <input class="mr-2 pl-2" type="checkbox" name="status" id="status" value="1" ngModel>
          <label for="status" class="font-weight-bold text-uppercase ml-1"> Publish Timetable</label>
        </div>
          <hr class="p-0">  
        </div>
        </div>
        
        <div class="row mt-3">        
            <button [disabled]="!f.valid || !selectedSubject || !this.allow" class="btn btn-sm btn-primary mt-1" type="submit">Insert
              Entry</button>
          <button class="btn btn-outline-primary btn-sm  mt-1 ml-2" (click)="OnManageTeacher()" type="button">Manage
            Teacher</button>

          <button class="btn btn-outline-primary btn-sm mt-1 ml-2" (click)="f.resetForm()" type="button">Clear</button>
          
          <button class="btn btn-outline-primary btn-sm mt-1 ml-2" (click)="checkTeacherAvailable()" type="button">Teacher Availability
            </button>

            <button class="btn btn-outline-primary btn-sm mt-1 ml-2"  [disabled]="add_teacher"  (click)="AddTeacher()" type="button">Add Teacher
            </button>

             <button class="btn btn-outline-primary btn-sm mt-1 ml-2" (click)="checkRoomAvailable()" type="button">Room Availability
            </button>                   
          </div>          
      </div>

      <ng-container *ngIf="loader then ifBlock"></ng-container>
      <ng-template #ifBlock>
          <div style="display:flex;justify-content:center; align-items: center;"><span
                  class="loader"></span></div>
      </ng-template>
      
      <div class="card-body mb-3 mt-3" style="min-height: 7rem;">
        <div class="list-group shadow">

          <div class="list-group shadow" *ngFor="let entry of timetableEntries | keyvalue">
            <div class="d-flex">
              <app-timetable-entry [inData]='entry.value' [editable]='true' (delete)="OnDeleteClick($event)"
                style="flex: auto;">
              </app-timetable-entry>
            </div>
          </div>
          <div class="list-group shadow" *ngFor="let entry of cData | keyvalue">
            <div class="d-flex">
              <app-timetable-entry [inData]='entry.value' [editable]='true' (delete)="OnDeleteClick($event)"
                style="flex: auto;">
              </app-timetable-entry>
            </div>
          </div>
        </div>
      </div>
      
    </div>

  </div>
</form>


<app-manage-teacher *ngIf="manageTeacher" (teacher)="OnNewTeacher($event) " (close)="OnCloseManageTeacher()">
</app-manage-teacher>

<app-teacher-available *ngIf="checkTeacher" (close)="OnCloseAvailableTeacher()"></app-teacher-available>
<app-room-available *ngIf='checkRoom' (close)="onCloseRoomAvailable()"></app-room-available>
<app-add-teacher *ngIf="addTeacher" [Form_data]="data" (c_data)=" ch_data($event)" (close)="onCloseAddTeacher()"></app-add-teacher>