import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from '../shared/services/Class.service';
import { SessionService } from '../shared/services/Sessions.service';
import { SubjectOfferService } from '../shared/services/SubjectOffer.service';
import { ExamSummaryService } from '../shared/services/ExamSummary.service';
import { StudentService } from '../shared/services/Student.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';

interface SubjectsWithGrades {
  SUB_CODE: string,
  SUB_NM: string,
  GRD: string,
  SE_ID: number,
  SE_NM:string,
  ATMP_NO: number,
  STATUS: string,
  T_NO:number,
  ORIG_TNO:number
}
interface ExemptedList {
  SUB_CODE: string,
  SUB_NM: string,
  GRD: string,
  ATMP_NO: number,
  SE_ID: number,
  SE_NM:string,
  STATUS: string,
  ORIG_TNO:number
}
interface ReplacedList {
  Pre_SE_ID:number,
  Pre_TNO:number,
  Pre_SUB_CODE: string,
  Pre_SUB_NM: string,
  Rep_SE_ID:number,
  Rep_TNO:number,
  Rep_SUB_CODE: string,
  Rep_SUB_NM: string,
  ATMP_NO: number,
}

@Component({
  selector: 'app-subject-replacement',
  templateUrl: './subject-replacement.component.html',
  styleUrls: ['./subject-replacement.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class SubjectReplacementComponent implements OnInit {
  isAdmission: boolean;
  isExam : boolean;
  classes: Array<SelectList>;
  rolnumbers: Array<SelectList>;
  subjects: Array<SelectList>;
  sessions: Array<SelectList>;
  subjectslist: Array<SubjectsWithGrades>;
  current:SubjectsWithGrades;
  ExemptedList: Array<ExemptedList>;
  ReplacedList: Array<ReplacedList>;
  NM: string;
  lockForm: UntypedFormGroup;
  Replacment_Se_id: number = 0;
  Replacment_T_no: number = 0;
  Replace_sub_code: string = '';
  Original_Sub: string = '';
  edit: boolean = false;
  replace: boolean = false;
  exempt: boolean = false;
  index: number;
  status: string = '';
  @ViewChild('f') formRef: NgForm;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'> This screen is used to exempt the student subjects as well to replace student subject( a subject that is not going to be offered  again by department)
</h6>
  <h6 class="text-right">یہ اسکرین طلباء کے مضامین کو ایکزمپٹ کرنے کے ساتھ ساتھ طلباء کے مضامین کو تبدیل کرنے کے لئے استعمال ہوتی ہے (ایسے مضامین جو دوبارہ شعبے کی طرف سے آفر نہیں کیے جائیں گے)۔
</h6>
  `  ;


  constructor(private classService: ClassService,
    private authenticationService: AuthenticationService,
    private sessionService: SessionService,
    private studentService: StudentService,
    private examSummaryService: ExamSummaryService,
    private subjectOfferService: SubjectOfferService,
    private FormBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private lmsSharedService: LMSharedDataService) {
    this.classes = [];
    this.rolnumbers = [];
    this.isAdmission = this.authenticationService.canEnrollRepeater();
    this.isExam = this.authenticationService.isExamCell();
    this.subjectslist = [];
    this.sessions = [];
    this.subjects = [];
    this.ExemptedList=[];
    this.ReplacedList=[];
    this.NM = '';
    this.status = '';
  }

  ngOnInit(): void {
    this.lockForm = this.FormBuilder.group({
      c_code: ['', Validators.required],
      year: ['', Validators.required],
      t_no: ['', Validators.required],
      rolno: ['', Validators.required]
    })
    this.updateClasses();
  }

  OnClassChange() {
    this.sessions = [];
    this.loadSessions();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
    });
  }

  loadSessions() {
    this.NM='';
    this.rolnumbers=[];
    this.sessions = [];
    this.sessionService.getSessions(this.f.c_code.value).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(element => {
        this.sessions.push({ id: element.SE_ID, nm: element.SE_NM });
      })
    }, err => {
      this.toastrService.error("Unknown Error");
    });
  }
  
  curdate(): any {
    return moment(new Date()).format('YYYY-MM-DD');
  }

  OnSemesterChange() {
    this.loadRollno();
  }

  loadRollno() {
    this.NM='';
    const { c_code, year,t_no } = this.lockForm?.value;
    if (!c_code || !year|| !t_no){
      return;
    } 
    this.rolnumbers = []
    this.studentService.getStudentsRollNumbers(this.lockForm?.value).subscribe((
      res: { RN: number, ROLNO: string }[]) => {
      res?.forEach(element => {
        this.rolnumbers.push({ id: element.RN, nm: element.ROLNO });
      });
    });
  }

  getName(rolno: HTMLSelectElement) {
    this.studentService.getStudentNameWithRolno(rolno.value).subscribe((
      res: { NM: string }) => {
      this.NM = res?.NM;
    });
  }
  OnYearChange() {
    this.rolnumbers=[];
    this.NM='';
  }

  getSubjects() {
    this.edit = false;
    this.ReplacedList = [];
    this.ExemptedList=[];
    this.subjectslist=[];
    let rn = this.rolnumbers.find(ind => ind.nm==this.lockForm?.value.rolno)?.id;
    const { c_code, year ,t_no} = this.lockForm?.value;
    if (!c_code || !year || !rn||!t_no){
      return;
    } 
    this.subjectOfferService.getExRsltSubjects({form:this.lockForm?.value,rn:rn}).subscribe((
      res: SubjectsWithGrades[]) => {
      res?.forEach(element => {
        this.subjectslist.push(element);
      })
      if (!this.subjectslist.length)
        this.toastrService.warning("No Data Found");
    });
  }
  get f() {
    return this.lockForm.controls;
  }
  toggleRecord(i) {
    this.edit = !this.edit;
    this.replace = false;
    this.exempt = false;
    this.index = i;
    this.status = this.subjectslist[i].STATUS;
    this.current = this.subjectslist[i];
  }



  OnReplacmentSessionChange(repse_id) {
    this.Replacment_Se_id = repse_id.value;
  }
  OnReplacmentT_noChange(rept_no) {
    this.Replacment_T_no = rept_no.value;
    this.loadSubjects(rept_no);
  }
  OnReplacmentSubChange(i, sub) {
    this.Replace_sub_code = sub.value;
    this.Original_Sub = this.subjectslist[i].SUB_CODE;
  }


  loadSubjects(rept_no) {
    this.subjects = [];
    this.subjectOfferService.getSubject(this.lockForm.value?.c_code, this.Replacment_Se_id, this.Replacment_T_no).subscribe((
      res: { SUB_CODE: number, S_NM: string }[]) => {
      res?.forEach(element => {
        this.subjects.push({ id: element.SUB_CODE, nm: element.S_NM });
      });
      if (!this.subjects.length)
        this.toastrService.warning("No Data Found");
    });
  }

  OnExempt(i, exempt) {
    this.index = i;

      if (this.subjectslist[i]?.STATUS !== 'EXEMPTED') {
        let msg =
        "Do you really want to EXEMPT this Subject?\n\n Press OK to proceed";
        if (confirm(msg)) {
        this.StatusUpdate(i,"EXEMPTED");
        this.subjectslist[i].STATUS="EXEMPTED";
      }
      }
      else {
        let msg =
        "Do you really want to UNEXEMPT this Subject?\n\n Press OK to proceed";
        if (confirm(msg)) {
        this.StatusUpdate(i,'EA');
        this.subjectslist[i].STATUS='EA';
        this.replace=false;
        this.exempt=false;
        this.edit=false;
      }
    }
  }

  updateOrigTno(sub,se_id,t_no,newtno){
    let rn = this.rolnumbers.find(ind => ind.nm==this.lockForm?.value.rolno)?.id;
    this.examSummaryService.updateOrigTno({...this.lockForm.value, se_id:se_id,t_no: t_no, rolno:rn,sub_code: sub,newORIG_TNO:newtno.value}).subscribe(res => {
      if(res[0]?.affectedRows==1)
      this.toastrService.success("Orignal Term Updated");
      else
      this.toastrService.error("Could not Update Orignal Term");
    });

  }

  updateAttpNo(sub,se_id,t_no,newatt){
    let rn = this.rolnumbers.find(ind => ind.nm==this.lockForm?.value.rolno)?.id;
    this.examSummaryService.updateAttpNo({...this.lockForm.value, se_id:se_id,t_no: t_no, rolno:rn,sub_code: sub,newATMP_NO:newatt.value}).subscribe(res => {
      if(res[0]?.affectedRows==1)
      this.toastrService.success("Attempt Number Updated");
      else
      this.toastrService.error("Could not Update Attempt Number");
    });

  }

  onDeleteExRslt(sub,se_id,t_no){
    if(confirm("Do you want to delete the Subject?\nPress ok to continue")){
      let rn = this.rolnumbers.find(ind => ind.nm==this.lockForm?.value.rolno)?.id;
    this.examSummaryService.deleteExamRslt(this.lockForm.value?.c_code,this.lockForm.value?.year,se_id, t_no,rn, sub).subscribe(res => {
      if(res){  
      this.toastrService.success("Deleted successfully");
        //Remove Entry from Array of Repeaters......
        this.deleteFromList(sub,se_id,t_no);}
        else{
          this.toastrService.error("Some Error Occur");
        }
    }, err => {
      this.toastrService.error("Some Error Occured");
      console.log(err);
    });
  }
  else{
  }
   }

   

deleteFromList(sub,se_id,t_no){
  for(let i in this.subjectslist){
      if(this.subjectslist[i].SUB_CODE==sub && this.subjectslist[i].SE_ID==se_id && this.subjectslist[i].T_NO==t_no)
        this.subjectslist.splice(Number(i),1);
  }
}

StatusUpdate(i,status){
  let rn = this.rolnumbers.find(ind => ind.nm==this.lockForm?.value.rolno)?.id;
    let params = {year:this.lockForm.value.year, se_id: this.subjectslist[i]?.SE_ID, t_no: this.subjectslist[i]?.T_NO, rn: rn, sub_code: this.subjectslist[i]?.SUB_CODE,status:status };
  this.examSummaryService.OnExamStatusExempted(params)
    .subscribe((res) => {
      if (res === 1){
        if(status=='EA' || status==null){
          this.toastrService.success(`UnExempted Successfully.`);
        }
        else
        this.toastrService.success(`Exempted Successfully.`);
       
      }else{
        this.toastrService.error("Some Error Occured");
      }
    }
    );
  this.exempt = true;
}


  ONREPLACE(i) {
    this.replace = !this.replace;
    this.index = i;
  }

  SubjectReplace(i) {
    let params = { t_no: this.subjectslist[i]?.T_NO, rolno: this.lockForm.value.rolno, orig_sub_code: this.Original_Sub,
      rep_se_id: this.Replacment_Se_id, rep_t_no: this.Replacment_T_no, rep_sub_code: this.Replace_sub_code, atmp_no: this.subjectslist[i].ATMP_NO
    };
    this.examSummaryService.OnSubjectReplacementInsert(params).subscribe((
      res) => {
      if (res === 1) {
        this.toastrService.success("Inserted Successfully");
        this.getSubjects();
      }else{
        this.toastrService.error("Some Error Occured");
      }
    });
  }

  getExempted(){

    this.edit = false;
    this.ReplacedList = [];
    this.ExemptedList=[];
    this.subjectslist=[];

    let rn = this.rolnumbers.find(ind => ind.nm==this.lockForm?.value.rolno)?.id;
    const { c_code, year ,t_no} = this.lockForm?.value;
    if (!c_code || !year || !rn||!t_no){
      return;
    } 
    this.examSummaryService.OnExemptedSubjects({form:this.lockForm?.value,rn:rn}).subscribe((
      res: ExemptedList[]) => {
        if(res?.length===0){
          this.toastrService.warning("No Exempted Subject found");
          return;
        }
      res?.forEach(element => {
        this.ExemptedList.push(element);
      })
      if (!this.ExemptedList.length)
        this.toastrService.warning("No Data Found");
    });
  }
  getReplaced(){
    this.edit = false;
    this.ReplacedList = [];
    this.ExemptedList=[];
    this.subjectslist=[];
    let rn = this.rolnumbers.find(ind => ind.nm==this.lockForm?.value.rolno)?.id;
    const { c_code, year ,t_no} = this.lockForm?.value;
    if (!c_code || !year || !rn||!t_no){
      return;
    } 
    this.examSummaryService.OnSubReplaced({form:this.lockForm?.value,rn:rn}).subscribe((
      res: ReplacedList[]) => {
        if(res?.length===0){
          this.toastrService.warning("No Subject found");
          return;
        }
      res?.forEach(element => {
        this.ReplacedList.push(element);
      })
      if (!this.ReplacedList.length)
        this.toastrService.warning("No Data Found");
    });
  }
}
