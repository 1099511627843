<div [@SlideInFromLeft] class="container-fluid">
  <div class="card shadow" style="min-height: 60vh;">
    <div class="card-header"  style="padding-top: 10px;">
      <div class=" col-lg-6 col-md-6 form-group">
        Subject  Detail</div>
         <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
        <app-details-button [details]="details"></app-details-button>
    </div>
  </div>
    <div class="my-0 py-1">
    <form class="row card-body mb-0 pb-0" #fn="ngForm">
      <div class="row card-body mb-0 pb-0">
        <div class="col-lg-6 col-md-6 col-sm-3 form-group pb-0">
          <label for="class" class="text-left">Search Subject</label>
          <input class="form-control" name="in" id="endTime" #input ngModel required>
        </div>
        <div class="row mt-4 pt-0">
          <button [disabled]="!fn.valid" (click)="searchBySubcode(fn)" type="button" class="btn btn-primary mt-1 ml-4">By
            Sub-Code</button>
          <button [disabled]="!fn.valid" (click)="searchByName(fn)" type="button" class="btn btn-primary mt-1 ml-3">By
            Sub-Name</button>
        </div>
      </div>
    </form>
    </div>
    <div class="my-0 py-2">
    <form class="row card-body"  #f="ngForm">
      <div class="col-lg-6 col-md-6 form-group my-0 py-0">
        <label for="c_code">Class</label>
        <select id="c_code" class="form-control" #cource (change)="OnClassChange(cource)" name="c_code" ngModel>
          <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
        </select>
      </div>
      <div class="col-lg-6 col-md-6 form-group my=0 py-0">
        <label for="Department">Department
        </label>
        <select placeholder="Optional" class="form-control" id="d_id" name="d_id" #department (change)="OnDeptChange(department)" ngModel>
          <!-- <option value="" disabled selected hidden>Optional</option> -->
          <option *ngFor="let d of departments" value="{{d.id}}">{{d.nm}}</option>
        </select>
      </div>
        <div class="row ml-3">
        <button  (click)="GetSubjects()" type="button"
          class="btn btn-primary ml-0" [disabled]="!f.valid">Get Subjects</button>
        <button *ngIf="this.subjects.length>0" (click)="DownloadExcel()" type="button"
          class="btn btn-success ml-2"><i class="fa fa-file-excel-o" for=""></i> Download Excel</button>
          <button *ngIf="this.subjects.length>0" (click)="DownloadPDF()" type="button"
          class="btn btn-danger ml-2"><i class="fa fa-file-pdf-o" for=""></i> Download PDF</button>
          <button (click)="ClearBtn()" type="button"
          class="btn btn-warning ml-2">Clear</button>
      </div>
    </form>
  </div>
    <div>
      <div class="card-body" style="overflow-x:scroll;" *ngIf="subjects.length > 0">
        <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
          <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
      </div>
        <table class="table table-bordered table-striped" id="table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Sr No</th>
              <th scope="col">Subject Code</th>
              <th scope="col">Name</th>
              <th scope="col">Credit Hour</th>
              <th scope="col">Subject Nature</th>
              <!-- <th scope="col">Nick Name</th> -->
              <!-- <th scope="col">Department</th> -->
              <!-- <th scope="col">Class</th> -->
              <th scope="col" *ngIf="this.isExamCell" style="width:4vw">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let s of subjects; let i = index" id="values">
              <!-- {{ s | json }} -->
              <td>{{i+1}}</td>
              <td>{{s.SUB_CODE}}</td>
              <td>{{s.SUB_NM}} </td>
              <td>{{s.cr_h}}</td>
              <td>{{s.nat_id}}</td>
              <!-- <td>{{s.nickName}}</td> -->
              <!-- <td>{{s.subDept}}</td> -->
              <!-- <td>{{s.subClass}}</td> -->
              <td *ngIf='i===index && edit && this.isExamCell' class="input-group input-group-sm p-0 m-0">
                <div [ngClass]="{'disabled-overlay': modal}">
                  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">Update Subject</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openModal()">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <form #u="ngForm">
                            <div class="form-group">
                              <label for="sub_nm" class="col-form-label">Subject Name</label>
                              <input type="text" class="form-control" id="sub_nm" name="sub_nm" #sub_nm
                               ngModel required [(ngModel)]="update.sub_nm" style="font-weight: bold;">
                            </div>
                            <div class="form-group">
                              <label for="cr_h_u" class="col-form-label">Credit Hour</label>
                              <input type="number" class="form-control" id="cr_h_u" name="cr_h_u" min="1"
                               ngModel required [(ngModel)]="update.cr_h" style="font-weight: bold;">
                            </div>
          
                            <div class="form-group">
                              <label for="sub_nat_u" class="col-form-label">Subject Nature</label>
                              <select class="form-control" name="sub_nat_u" id="sub_nat_u" 
                              [(ngModel)]="update.nat_id" required ngModel>
                                <option value="1">Theory</option>
                                <option value="2">Practical</option>
                                <option value="3">Thesis/Project</option>
                                <option value="4">Internship</option>
                              </select>
                            </div>
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="openModal()">Close</button>
                          <button class="btn btn-primary" (click)="Update(s.subId,s, $event)">
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>  
              </td>
              <td *ngIf="this.isExamCell" class="center">
                <button class="btn" style="background-color: orange;" (click)="openModal()" data-toggle="modal" data-target="#exampleModal" data-whatever="@getbootstrap" (click)="toggleRecord(i,s)">
                  <i class="fas fa-edit" style="color:white"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>      
      </div>
    </div>
  </div>
</div>
