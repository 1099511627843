import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList, SelectList2 } from '../../shared/model/SelectList.model';
import { DegreeprintService } from '../../shared/services/degreeprint.service';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';
export interface Data {
  rollno: string,
  Name: string,
  F_name: string,
  Reg_no: string,
  Deg_no: number,
}
@Component({
  selector: 'app-signaturefordegreeholders',
  templateUrl: './signaturefordegreeholders.component.html',
  styleUrls: ['./signaturefordegreeholders.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class SignaturefordegreeholdersComponent implements OnInit {

  @ViewChild('f') formRef: NgForm;

  classes: Array<SelectList>;
  session: Array<SelectList2>;
  majors: Array<SelectList>;
  Details: Array<Data>;
  category: string;
  t_no: number;
  classname: string;
  majname: string;
  isOpen: boolean = false;
  iSEnable: boolean = false;
  constructor(
    private toaster: ToastrService, 
    private degree: DegreeprintService,
    private lmsSharedService: LMSharedDataService) {
    this.classes = [];
    this.session = [];
    this.majors = [];
    this.Details = [];
  }

  ngOnInit(): void {
    this.updateClasses();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateMajors() {
    this.majors.length = 0;
    this.lmsSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  OnClassChange() {
    // this.getSession();
    this.updateMajors();
    for (var i = 0; i < this.classes.length; i++) {
      if (this.formRef.value.c_code == this.classes[i].id) {
        this.classname = this.classes[i].nm;
        return;
      };
    }
  }
  // getSession() {
  //   this.session = [];
  //   this.sessionService.getSessions(this.formRef.value.c_code).subscribe(
  //       (res: { SE_ID: number; SE_NM: string; SE_ST_YEAR: number }[]) => {
  //         res?.forEach((e) => {
  //           this.session.push({
  //             id: e.SE_ID,
  //             nm: e.SE_NM,
  //             se_st_year: e.SE_ST_YEAR,
  //           });
  //         });
  //         // this.se_select = res[0]?.SE_ID;
  //       }
  //     );
  // }

  OnMajorChange() {
    for (var i = 0; i < this.majors.length; i++) {
      if (this.formRef.value.maj_id == this.majors[i].id) {
        this.majname = this.majors[i].nm;
        return;
      };
    }
  }

  OnCategoryChange() {
    this.isOpen = true;
    this.t_no = 0;
    if (this.formRef.value.category === "Urgent") {
      this.category = "U";
      this.degree.getEligibleCandidateForDegree(this.formRef.value.c_code, this.t_no, 0, this.formRef.value.maj_id, this.formRef.value.notifDate
        , this.formRef.value.notifDate1, this.category, 0).subscribe((res: any) => {
          if (res != null) {
            this.toaster.success("Data Loading");
            for (var i = 0; i < res.length; i++) {
              this.Details.push({ rollno: res[i].ROLNO, Name: res[i].nm, F_name: res[i].F_NM, Reg_no: res[i].REG_NO, Deg_no: res[i].DEG_NO });
            }
          }
          else {
            this.toaster.error("Data Not Found");
          }
        });
    }
    else if (this.formRef.value.category === "Regular") {
      this.category = "R";
      this.degree.getEligibleCandidateForDegree(this.formRef.value.c_code, this.t_no, 0, this.formRef.value.maj_id, this.formRef.value.notifDate
        , this.formRef.value.notifDate1, this.category, 0).subscribe((res: any) => {
          if (res != null) {
            this.toaster.success("Data Loading");
            for (var i = 0; i < res.length; i++) {
              this.Details.push({ rollno: res[i].ROLNO, Name: res[i].nm, F_name: res[i].F_NM, Reg_no: res[i].REG_NO, Deg_no: res[i].DEG_NO });
            }
            this.toaster.success("Data Loaded");
          }
          else {
            this.toaster.error("Data Not Found");
          }
        });

    }
    else if (this.formRef.value.category === "Duplicate") {
      this.category = "D";
      this.degree.getEligibleCandidateForDegree(this.formRef.value.c_code, this.t_no, 0, this.formRef.value.maj_id, this.formRef.value.notifDate
        , this.formRef.value.notifDate1, this.category, 0).subscribe((res: any) => {
          if (res != null) {
            this.toaster.success("Data Loading");
            for (var i = 0; i < res.length; i++) {
              this.Details.push({ rollno: res[i].ROLNO, Name: res[i].nm, F_name: res[i].F_NM, Reg_no: res[i].REG_NO, Deg_no: res[i].DEG_NO });
            }
          }
          else {
            this.toaster.error("Data Not Found");
          }
        });
    }
  }

  click() {
    this.t_no = 0;
    this.degree.getEligibleCandidateForDegree(this.formRef.value.c_code, this.t_no, this.formRef.value.se_id, this.formRef.value.maj_id, this.formRef.value.notifDate
      , this.formRef.value.notifDate1, this.category, 0).subscribe((res: any) => {
        if (res != null) {
          this.toaster.success("Data Loading");
          for (var i = 0; i < res.length; i++) {
            this.Details.push({ rollno: res[i].ROLNO, Name: res[i].nm, F_name: res[i].F_NM, Reg_no: res[i].REG_NO, Deg_no: res[i].DEG_NO });
          }
        }
        else {
          this.toaster.error("Data Not Found");
        }
      });
  }

  CreatePdf() {
    var convo = this.formRef.value.convo;
    var classname = this.classname;
    var majname = this.majname;
    this.toaster.success("PDF In Progress");
    const header = [];
    var body = [];

    this.Details.forEach((e, i) => {
      body.push([(i + 1), e.Deg_no, e.Reg_no, e.Name, e.F_name, e.rollno])
    });
    const doc = new jsPDF('landscape');
    header.push(['Sr#', 'Degree No', 'Registration No', 'Name', 'Father Name', 'Roll No', 'National Id Card No', 'Signature']);

    autoTable(doc, {
      theme: "grid",
      headStyles: {
        textColor: [0, 0, 0]
        , fillColor: [255, 255, 255], lineWidth: 0.5
      },
      margin: { top: 40, bottom: 5 },

      head: header,
      body: body,
      didDrawPage: function (d) {
        //   y = d.cursor.y;
        doc.setFontSize(25);
        doc.setFont('Calibri', 'bold');
        doc.text("GC University Lahore", 150, 13, { align: 'center' });
        doc.setFontSize(18);
        doc.setFont('Calibri', 'bold');
        doc.text("List of Eligible Candidates to Receive The Degree in " + convo, 150, 26, { align: 'center' });
        doc.setFontSize(12);
        doc.setFont('Calibri', 'bold');
        doc.text(classname + " in " + majname, 150, 36, { align: 'center' });


      }
    });



    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  };


  OnClear(cls: NgForm) {
    this.Details = [];
    cls.resetForm();

    this.isOpen = false;
  }

  Filter()
  {
    filter();
  }
}
