import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { UtilService } from 'src/app/Util.service';
import { SubjectModel } from '../insert-time-table/models/Subject.model';
import { RequestedSubjectModel } from './RequestedSubject.model';
import { SubjectOfferService } from './../shared/services/SubjectOffer.service';
import { GroupService } from './../shared/services/Groups.service';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { DepartmentService } from './../shared/services/Department.service';
import { MajorService } from './../shared/services/Major.service';
import { DatePipe } from '@angular/common';
import { jsPDF } from "jspdf";
import autoTable, { UserOptions } from 'jspdf-autotable'
import 'jspdf-autotable';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';
interface recentSub {
  CR_H: number,
  GP_PER: string,
  C_ELE: string,
  REG_SUMR: string,
  AB_REL: number,
  SET_NO: number,
  PR_ORD: number,
  NAT_ID: number

}

interface subOffer {
  SUB_NM: string,
  SUB_CODE: string,
  CR_H: number,
  GP_PER: string,
  C_ELE: string,
  REG_SUMR: string,
  PR_ORD: number,
  AB_REL: number,
  SET_NO: number,
  DES_NM: string,
  NAT_ID: number
}

@Component({
  selector: 'app-subject-offer',
  templateUrl: './subject-offer.component.html',
  styleUrls: ['./subject-offer.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class SubjectOfferComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;
  @ViewChild('u') updateForm: NgForm;
  departments: Array<SelectList>;
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  subjects: Array<SubjectModel>;
  offeredSubjects: Array<subOffer>;

  requestedSubs: Array<RequestedSubjectModel>;
  groups: Array<SelectList>;

  allow: boolean = false;

  isAdmin: boolean = false;
  isExam: boolean = false;
  canManageTT: boolean = false;
  group: number;

  temp: boolean = false;
  classS: string;
  Major: string;
  se_id: string;
  pre_order: any;
  latest_pre_order: any;
  ErrorOnPre_Ord: any;
  showUpdate: boolean = false;
  updatingIndex: number = -1;
  dept: any;
  CR_H: any = "";
  cr_h_u: number = 0;
  absolute_relative: any = 0;
  abs_u: number = 0;
  sub_nat_u: number = 0;
  isPhilosophy: any;
  sub_comb: Array<{ SET_NO: number, SUB_COMB: string }>;
  departmentId: any;
  examdptid: any = 102;
  ditid: any = 404;
  index: number;
  updateMood: boolean;
  // flag: boolean = true;
  modal: boolean = false;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'> You can offer a subject in this your major for any semester,session and for any class and you can also check your 
 offered subjects and delete or update them as well   </h6>
  <h6 class="text-right">آپ اپنے میجر میں کسی بھی سمسٹر، سیشن، اور کلاس کے لئے ایک سبجیکٹ آفر کر سکتے ہیں اور آپ اپنے آفر کردہ سبجیکٹس کو چیک، ڈیلیٹ یا اپڈیٹ بھی کر سکتے ہیں۔
</h6>
  `  ;

  constructor(
    private authenticationService: AuthenticationService,
    private utilService: UtilService,
    private toastr: ToastrService,
    private subOffer: SubjectOfferService,
    private datePipe: DatePipe,
    // private subReqService: SubjectRequestService,
    private groupService: GroupService,
    private classService: ClassService,
    private departmentService: DepartmentService,
    private majorService: MajorService,
    private lmsSharedService: LMSharedDataService
  ) {
    this.departments = new Array<SelectList>();
    this.classes = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.subjects = new Array<SubjectModel>();
    this.offeredSubjects = [];
    this.requestedSubs = new Array<RequestedSubjectModel>();
    this.groups = new Array<SelectList>();
    this.sub_comb = [];
    //++++++++++++++++++++++++++
    this.departmentId = parseInt(this.authenticationService.getUser().D_ID);
    //++++++++++++++++++++++++++
    this.isAdmin = this.authenticationService.isAdmin();
    this.isExam = this.authenticationService.isExamCell();
    this.canManageTT = this.authenticationService.canManageTimetable();
    this.group = this.authenticationService.getGroup();
    this.dept = this.authenticationService.getUser().D_ID;
    this.temp = this.authenticationService.canManageTimetable();

    if (this.departmentId == this.ditid || this.departmentId == this.examdptid) {
      // console.log("Allow")
    }
    else {
      // console.log("Not Allow")
    }
  }

  ngOnInit(): void {
    console.log(this.group)
    this.isPhilosophy = this.group;
    this.loadGroups();
    if (this.isAdmin || this.isExam) {
      this.departmentService.getDepartments({ admin: (this.isAdmin || this.isExam) }).subscribe((res: { D_ID: number, D_NM: string }[]) => {
        res?.forEach(entry => {
          this.departments.push({ id: entry.D_ID, nm: entry.D_NM });
        });
      });
    }
  }
  

  loadGroups() {
    this.groupService.getGroups({ grp: this.group, role: this.isAdmin || this.isExam }).subscribe((res: { GRP: number, G_NM: string }[]) => {
      res?.forEach(element => {
        this.groups.push({ id: element.GRP, nm: element.G_NM });
      });

      if (!this.isAdmin || !this.isExam) {
        this.updateClasses();
      }
    }, err => {
      console.log(err);
    });
  }
  // myFunction() {
  //   this.flag = !this.flag;
  //   var popUp;
  //   popUp = document.getElementById("myPopup");


  //   if (popUp.style.display === "none") {
  //     popUp.style.display = "block";
  //   } else {
  //     popUp.style.display = "none";
  //   }
  // }


  OnGrpChange(grp) {
    this.group = parseInt(grp.value);
    this.updateClasses();
  }

  OnDeptChange(d: HTMLSelectElement) {
    this.dept = d;
  }

  OnClassChange(c: HTMLSelectElement) {

    this.classS = this.classes[c.selectedIndex]?.nm;
    this.sub_comb = [];
    this.updateMajors();
    if (this.formRef.value.c_code !== "") {
      this.updateSubjects();
    }
  }

  loadSubCombs() {
    this.sub_comb = [];
    this.subOffer.getSubjectComb(this.formRef.value.maj_id, this.formRef.value.c_code, this.formRef.value.t_no).subscribe((res: { SET_NO: number, SUB_COMB: string }[]) => {
      if (res?.length > 0) {
        this.sub_comb = res;
      }
    }, err => {
      console.log('error occured: ', err);
      this.toastr.error('Some Error Occured.');
    })
  }

  loadSubCombsBySession() {
    this.sub_comb = [];
    this.subOffer.getSubjectCombBySession(this.formRef.value.maj_id, this.formRef.value.c_code, this.formRef.value.t_no, this.formRef.value.se_id).subscribe((res: { SET_NO: number, SUB_COMB: string }[]) => {
      if (res?.length > 0) {
        this.sub_comb = res;
      }
    }, err => {
      console.log('error occured: ', err);
      this.toastr.error('Some Error Occured.');
    })
  }

  loadMajors(s: HTMLSelectElement) {
    this.Major = this.majors[s.selectedIndex]?.nm;
    this.se_id = this.sessions[0]?.nm;
    this.updateSessions();
  }

  OnSessionChange(s: HTMLSelectElement) {
    this.se_id = this.sessions[s.selectedIndex]?.nm;
    this.updateOfferedSubs();
  }

  OnMajorChange(m: HTMLSelectElement) {
    this.Major = this.majors[m.selectedIndex]?.nm;
    this.updateOfferedSubs();
    this.updateSessions();
    this.sub_comb = [];
    // +++++++++++++++++++++++++++++++
    // this.getMajLock()  

  }
  maj_lock: number;
  getMajLock() {
    const { c_code, maj_id, se_id, t_no } = this.formRef.value;
    this.majorService.getMajorLock(parseInt(c_code), parseInt(maj_id), se_id, t_no).subscribe(res => {
      this.maj_lock = res[0].offer_lock
    })
  }
  // +++++++++++++++++++++++++++++++

  OnSemesterChange(semester) {
    if (this.formRef.value.se_id !== "")
      this.updateOfferedSubs();
    // this.loadSubCombs();
    // gets sub_cmbination from adm_stdt
    this.loadSubCombsBySession();
    this.getMajLock()
  }

  OnSubChange(sub) {
    this.latest_pre_order = 0;
    if (this.offeredSubjects) {
      const ids = this.offeredSubjects?.map(object => {
        return object.PR_ORD;
      });
      this.latest_pre_order = Math.max(...ids);
      this.latest_pre_order = this.latest_pre_order + 1;
    } else {
      this.latest_pre_order = 1;
    }
    let index;
    index = this.subjects.find(e => e.subCode === sub);
    this.CR_H = index.CR_H;
    if (index.NAT_ID === 1 || index.NAT_ID === 2) {
      this.absolute_relative = 1;
    } else {
      this.absolute_relative = 0;
    }

  }
  GPAPercentaage(e) {
    if (e == 'P') {
      this.absolute_relative = 0;
    } else if (e == 'G') {
      this.absolute_relative = 1;
    }
  }
  /*
    cancelSubRequest(subReqId) {
      console.log("cancel clicked");
      console.log(subReqId);
      this.subReqService.cancelSubReq(subReqId).subscribe(res => {
        if (!res) {
          this.toastr.warning("Already cancelled");
        } else {
          this.toastr.success("Deleted Successfully");
          this.updateRequestedSubs();
        }
      }, err => {
        this.toastr.error("Error Occured");
      })
    }
    */
  OnPriOrderInsert(pre) {
    this.ErrorOnPre_Ord = [];
    this.ErrorOnPre_Ord = this.offeredSubjects.filter((e) => e.PR_ORD == pre);
  }

  updateClasses() {
    this.classes = [];
    this.majors = [];
    this.sessions = [];
    this.subjects = [];
    this.classService.getClasses({ grp: this.group }).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
    },
      err => {
        console.log(err);
        console.log("some err occured");
      });
  }

  updateMajors() {
    this.majors = [];
    this.sessions = [];
    this.majorService.getMajor({ grp: this.group.toString(), c_code: this.formRef.value.c_code }).subscribe((res: { MAJ_ID: number, MAJ_NM: string }[]) => {
      res?.forEach(entry => {
        this.majors.push({ id: entry.MAJ_ID, nm: entry.MAJ_NM });
      });
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });
  }

   updateSessions() {
    this.lmsSharedService.filterSessionByCode(this.formRef.value.c_code)
      .then(session => {
        this.sessions = session
        if (this.formRef) {
          const initialValue = this.sessions[0]?.id;
          this.formRef?.controls['se_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }

  updateSubjects() {
    this.subjects = [];
    this.utilService.getSubjects('-1', this.formRef.value.c_code).subscribe(res => {
      for (const i in res) {
        this.subjects.push(new SubjectModel(res[i].SUB_CODE, res[i].SUB_NM, res[i].CR_H, res[i].NAT_ID));
      }
    });
  }

  OnSubmit() {
    if (parseInt(this.departmentId) != parseInt(this.examdptid) && parseInt(this.departmentId) != parseInt(this.ditid)) {

      if (this.maj_lock == 1) {
        this.toastr.warning("Subject Offer Locked!");
        return
      }
    }

    this.formRef.value.set_no = parseInt(this.formRef.value.set_no);
    if (!this.latest_pre_order) {
      this.toastr.warning("Please Enter Print Order");
      return;
    }
    this.formRef.value.grp = this.group.toString();
    this.formRef.value.d_id = this.dept;
    this.formRef.value.abs = this.absolute_relative;
    this.subOffer.offerSub(this.formRef.value).subscribe((res: recentSub) => {
      if (!res)
        this.toastr.warning("Already Offered");
      else {
        this.toastr.success("Offered Successfully");
        this.latest_pre_order++;
        const { sub_code } = this.formRef.value;
        const { subName } = this.subjects.find(x => x.subCode === sub_code);
        this.offeredSubjects.unshift({ ...res, SUB_NM: subName, SUB_CODE: sub_code, DES_NM: "" });
      }
    }, err => {
      this.toastr.error("Failed to Offer Subject");
    });
  }

  updateOfferedSubs() {
    this.offeredSubjects = [];
    if (this.formRef.value.t_no === "" || this.formRef.value.se_id === "")
      return;

    // this.subReqService.getAllReqSubjects(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.se_id, this.formRef.value.t_no).subscribe(res => {
    //   this.requestedSubs = [];
    //   for (const i in res) {
    //     this.requestedSubs.push(new RequestedSubjectModel(res[i].sub_req_id, res[i].sub_nm, res[i].se, res[i].ab_rel, res[i].c_ele, res[i].cr_h, res[i].gp_per, res[i].reg_sumr, res[i].t_no, res[i].title, res[i].status));
    //   }
    // }, err => {
    //   console.log(err);
    // });

    this.subOffer.getOfferedSubWithDetails(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.se_id, this.formRef.value.t_no).subscribe((res: subOffer[]) => {
      this.offeredSubjects = res;
    }, err => {
      console.log(err);
      this.toastr.error(err);
    }
    )
  }

  deleteSub(index: number, sub_code: string, set_no: string) {
    if (confirm("Do you really want to delete?? \nPress OK to continue")) {
      const { c_code, maj_id, se_id, t_no } = this.formRef.value;

      this.subOffer.deleteSubOffer({ c_code, maj_id, se_id, t_no, sub_code, set_no }).subscribe((res: { msg: string, warning: string }) => {
        if (res?.msg) {
          this.toastr.success(res?.msg);
          this.offeredSubjects.splice(index, 1);
        }
        else
          this.toastr.warning(res?.warning || "Failed!, Try again.");
      }, err => {
        this.toastr.error("Unknown error");
      });
    }
  }

  update(index: number, obj: any) {
    this.cr_h_u = obj.CR_H;
    this.abs_u = obj.AB_REL;
    this.sub_nat_u = obj.NAT_ID;
    this.showUpdate = !this.showUpdate;
    this.updatingIndex = index;
  }

  save(obj: any, index: number) {

    const { c_code, maj_id, se_id, t_no } = this.formRef.value;

    this.cr_h_u = this.updateForm.value.cr_h_u;
    this.abs_u = this.updateForm.value.abs_u;
    this.sub_nat_u = this.updateForm.value.sub_nat_u;


    let sub_code, set_no;

    // cr_h_u = cr_h_u==''? obj.CR_H:cr_h_u;
    // abs_u = abs_u==''? obj.AB_REL:abs_u;
    // sub_nat_u = sub_nat_u==''? obj.NAT_ID:sub_nat_u;

    sub_code = obj.SUB_CODE;
    set_no = obj.SET_NO;

    this.subOffer.updateSubOffer({ c_code, maj_id, se_id, t_no, sub_code, set_no, cr_h_u: this.cr_h_u, abs_u: this.abs_u, sub_nat_u: this.sub_nat_u }).subscribe((res: { affectedRows: number }) => {
      if (res?.affectedRows != 0) {
        this.offeredSubjects[index].CR_H = this.cr_h_u;
        this.offeredSubjects[index].AB_REL = this.abs_u;
        this.offeredSubjects[index].NAT_ID = this.sub_nat_u;
        this.showUpdate = false;
        this.updatingIndex = -1;
        this.modal = !this.modal; 
        this.toastr.success('Updated Successfully');
      }
      else
        this.toastr.warning("Failed!, Try again");
    }, err => {
      this.toastr.error("Unknown error");
    });

  }


  OnCreatePDF() {
    const body = [];
    const header = [];
    let k = 0;
    for (let i = 0; i < this.offeredSubjects?.length; i++) {
      k++;
      body.push([k,
        this.offeredSubjects[i]?.SUB_CODE,
        this.offeredSubjects[i]?.SUB_NM,
        this.offeredSubjects[i]?.CR_H,
        (this.offeredSubjects[i]?.GP_PER == 'G') ? "GPA" : "Percentage",
        this.offeredSubjects[i]?.C_ELE,
        this.offeredSubjects[i]?.SET_NO,
        this.offeredSubjects[i]?.REG_SUMR,
        this.offeredSubjects[i]?.DES_NM,
      ]);
    }
    let exportDate = this.datePipe.transform(new Date(), 'EEE, d MMM yyyy HH:mm:ss')
    let image = new Image();
    let page;
    let cls = this.classS;
    let se = this.se_id;
    let index2 = this.majors.findIndex(ind => ind.id == this.formRef.value.maj_id);
    let maj = this.majors[index2]?.nm;
    let to = this.formRef.value.t_no;
    image.src = '../../../assets/images/logo3.png';
    header.push(['Sr.', 'Subject Code', 'Subject Title', 'Credit Hour', 'Grading Type', 'Comp/Elec', 'Set No.', 'Regular/Summer', 'Subject Nature']);
    const doc = new jsPDF('l', 'pt', "a4");
    autoTable(doc, {
      margin: { top: 70, bottom: 20 },
      head: header,
      body: body,
      didDrawPage: function () {
        doc.addImage(image, 400, 1, 55, 55);
        doc.setFontSize(10);
        doc.setFontSize(12);
        doc.setFont('Arial');
        doc.text("CLASS: " + cls, 38, 30);
        doc.text("MAJOR: " + (maj || ''), 38, 50);
        doc.setFontSize(14);

        doc.text(se, 720, 30);
        doc.text("Term No: " + to, 720, 50);
        doc.setFontSize(10);
        page = doc.getNumberOfPages();
      }
    });
    doc.setFontSize(10);
    for (let i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.text("Page " + i + " of " + page, 380, 590);
      if (page === 1) {
        doc.text("Printed Date: " + exportDate, 38, 575);
        doc.text("Note:- Errors and Omissions Excepted", 650, 590);
        doc.text("Directorate of Information Technology", 38, 590);
        doc.text("________________________________", 650, 540);
        doc.text("        Chairperson Signature         ", 650, 555);
      } else {
        doc.text("Printed Date: " + exportDate, 38, 575);
        doc.text("Note:- Errors and Omissions Excepted", 650, 590);
        doc.text("Directorate of Information Technology", 38, 590);
        if (i === page) {
          doc.text("________________________________", 650, 540);
          doc.text("                 Chairperson         ", 650, 555);
        }
      }
    }

    doc.setProperties({
      title: `Subject Offer-${cls}-${maj || ''}-${se}-${to}`,

    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }

  OnClear() {
    this.formRef.resetForm();
    this.majors = [];
    this.sessions = [];
    this.departments = [];
    this.subjects = [];
    if (this.isAdmin || this.isExam) {
      this.classes = [];
    }
  }

  Filter()
  {
    filter();
  }

  openModal(i){
    this.index = i;
    this.updateMood = !this.updateMood;
    this.modal = !this.modal; 
  }
}
