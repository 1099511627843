<form #f="ngForm">
    <div class=" container-fluid" [@SlideInFromLeft]>
        <div class="row card shadow">
            <div class="card-header pb-0 px-1">
                <div class="col-lg-4 col-md-6 form-group">
                    Award Scholarship
                </div>
            </div>
            <div class="card-body pb-0">
                <div class="row">
                    <div class="col-lg-4 col-md-6 form-group">
                        <label for="ch_no">Challan Number</label>
                        <input type="text" #ch_no class="form-control" name="ch_no" min="1" ngModel required
                            (keydown)="Handle($event)">
                    </div>
                    <div style="margin-left:14px;margin-top:18px">
                        <button class="btn btn-outline-primary" (click)="LoadSchpHistory()"  [disabled]="!f.valid">Search Challan</button>
                    </div>
                    <div style="margin-left:14px;margin-top:18px">
                        <button class="btn  btn-warning" (click)="onClearForm(f)" style="margin-left:15px">
                            Clear Form
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 form-group" *ngIf="schpStdtInfo?.length>0">
                        <label for="schp_id">Scholarship Type</label>
                        <select #schp_id class="form-control" name="schp_id" id="schp_id" (change)="loadSchpCat()"
                            ngModel required (change)="UpdateSchpType()">
                            <option *ngFor="let m of schpBase" value="{{m.id}}">{{m.nm}}</option>
                        </select>
                    </div>

                    <div class="col-lg-4 col-md-6 form-group" *ngIf="schpStdtInfo?.length>0">
                        <label for="cat_id">Scholarship Category</label>
                        <select #cat_id class="form-control" name="cat_id" id="cat_id" ngModel required
                            (change)="UpdateCategory()">
                            <option *ngFor="let n of schpCat" value="{{n.id}}">{{n.nm}}</option>
                        </select>
                    </div>
                    <div class="col-lg-4 col-md-6 form-group" *ngIf="schpStdtInfo?.length>0">
                        <label for="tuition">Insert Scholarship Amount</label>
                        <input type="number" #ch_no class="form-control" name="tuition" min="1" ngModel
                            (keydown)="Handle($event)">
                    </div>
                    <!-- //added by mehtab for scholarship -->
                    <div class="col-lg-4 col-md-6 form-group" *ngIf="schpStdtInfo?.length>0">
                        <label for="bnkname">Enter Scholarship Bank</label>
                        <select #bnkname class="form-control" name="bnkname" id="bnkname" ngModel>
                            <option *ngFor="let b of schBank" value="{{b.id}}">{{b.nm}}"</option>
                        </select>
                    </div>
                    <div class="col-lg-4 col-md-6 form-group" *ngIf="schpStdtInfo?.length>0">
                        <label for="tuition">Enter Cheque Number</label>
                        <input type="number" #chcq_no class="form-control" name="chcq_no" min="1" ngModel
                            (keydown)="Handle($event)">
                    </div>
                    <!-- //ended by mehtab for scholarship -->
                </div>
            </div>
            <div class="card-body pt-0 mt-1" style="margin-bottom:5%">
                <div class="row">
                    <div *ngIf="schpStdtInfo?.length>0" style="margin-left:15px">
                        <button class="btn btn-success" (click)="ScholarShip()">Insert Amount</button>
                    </div>
                    <div *ngIf="schpStdtInfo?.length>0" style="margin-left:2%">
                        <button class="btn btn-success" (click)="UpdateCount()" (click)="InsertDuesForScholarShipStdt()"
                            [disabled]="schpStdtInfo[0].paid">
                            Generate/Update Challan
                        </button>
                    </div>
                    <div *ngIf="schpStdtInfo?.length>0" style="margin-left:2%">
                        <button class="btn btn-danger" (click)="DeleteSchpEntry()" [disabled]="schpStdtInfo[0].paid">
                            Delete Scholarship
                        </button>
                    </div>
                </div>

                <div class="card-body px-0" *ngIf="loadSchpHis.length!=0">
                    <div class="shadow bg-white">
                        <table class="table table-bordered table-striped" style="font-size:12px">
                            <thead class="thead-dark">
                                <tr>
                                    <th colspan="9" style="text-align:center"> STUDENT SCHOLARSHIP HISTORY</th>
                                </tr>
                                <tr style="font-size:11px;text-align:center">
                                    <th scope="col">CHALLAN #</th>
                                    <th scope="col">ROLL # / FORM #</th>
                                    <th scope="col">INST #</th>
                                    <th scope="col">SCHP STATUS</th>
                                    <th scope="col">AMOUNT AWARDED</th>
                                    <th scope="col">SCHP ENTRY DATE</th>
                                    <th scope="col">PAID DATE</th>
                                    <th scope="col">CHALLAN STATUS</th>
                                    <th scope="col">ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- background-color:#F3F9F9; -->
                                <tr *ngFor="let a of loadSchpHis; let i = index">
                                    <td style="font-weight:bold">{{a.p_ch_no}}</td>
                                    <td style="font-weight:bold">{{a.num}}</td>
                                    <td style="font-weight:bold">{{a.inst_no}}</td>
                                    <td style="font-weight:bold">{{a.schpStatus}}</td>
                                    <td style="font-weight:bold">{{a.schpAmnt}}</td>
                                    <td style="display:flex;text-align:center;">
                                        <div style="text-align:center; flex-grow:1">
                                            <ng-container *ngIf="!a.schpEntryDt && !rowChecks[i]; else elseBlock">
                                                <span>{{a.schpEntryDt}}</span>
                                            </ng-container>

                                            <ng-template #elseBlock style="align-items:center">
                                                <div style="display: flex; flex-direction: row; align-items:center">
                                                    <input type="date" style="font-size:12px;" value="{{a.schpEntryDt}}"
                                                        class="form-control col-lg-9 mr-1" name="exDate" #exDate>
                                                    <button *ngIf="a.schpEntryDt" class="btn btn-success" style=" line-height: 1em;
                               padding: 3% 5% 3% 8%;align-items:center" (click)="changeDate(a.ch_no,exDate,i)">
                                                        <ng-container
                                                            *ngIf="!rowChecks[i]; then ifBlock else elseBlock"></ng-container>
                                                        <ng-template #ifBlock>
                                                            <i class="fa fa-edit"
                                                                style="font-size:1vw;margin-top:-0.5%"></i>
                                                        </ng-template>
                                                        <ng-template #elseBlock>
                                                            <i class="fa fa-check"
                                                                style="font-size:1vw;margin-top:-0.5%;"></i>
                                                        </ng-template>
                                                    </button>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </td>
                                    <td style="font-weight:bold">{{a.paidDate}}</td>
                                    <td>
                                        <span *ngIf="a.paid==1&&a.sub_inst==0"
                                            style="color:green;font-weight:bold">PAID</span>
                                        <span *ngIf="a.paid==0&&a.sub_inst==0"
                                            style="color:red;font-weight:bold">UNPAID</span>
                                        <span *ngIf="a.sub_inst==1"
                                            style="color:blue;font-weight:bold">SUBINSTALLMENTS</span>
                                    </td>
                                    <td><button class="btn btn-warning" (click)="ViewloadData(a.ch_no)"
                                            style="font-size:10px;font-weight:bold">VIEW</button></td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="card-body px-0 mb-1 mt-0 pt-0">
                    <div class="card" *ngFor="let i of schpStdtInfo"
                        style="box-shadow: 2px 2px 15px #888888;margin-bottom:2%">


                        <div style="display:flex;justify-content:space-between" class="row">

                            <div style="font-size:20px;font-weight:bold;margin:2% 0% 0% 8%">CHALLAN # {{i.p_ch_no}}
                            </div>
                            <div style="margin:2% 8% 0% 0%">
                                <ng-container *ngIf="i.paid==1; then ifBlock else elseBlock"></ng-container>
                                <ng-template #elseBlock>
                                    <span style="font-size:20px;color:red;font-weight:bold" *ngIf="i.sub_inst==0">
                                        UNPAID
                                    </span>
                                </ng-template>

                                <ng-template #ifBlock>
                                    <span style="font-size:20px;color:green;font-weight:bold;">
                                        PAID
                                    </span>
                                </ng-template>
                            </div>
                        </div>
                        <div class="card-header " style="justify-content:space-evenly">
                            <span>
                                Installment # {{i.inst_no}}
                            </span>
                            <span>
                                Sub Installments # {{i.sub_inst_no}}
                            </span>

                            <span>
                                Due Date: {{i.due_date}}
                            </span>
                            <span>
                                Paid Date: {{i.paidDate}}
                            </span>
                        </div>

                        <div class="card-body row"
                            style="padding-top:2%;padding-bottom:2%; justify-content:space-evenly;font-size:13px">
                            <div>
                                <div style="display:flex;">
                                    Name:
                                    <pre> </pre> <b>{{i.nm.toUpperCase()}}</b>
                                </div>
                                <div style="display:flex;">
                                    Buyer Code:
                                    <pre> </pre> <b>{{i.num}}</b>
                                </div>
                                <div style="display:flex;">
                                    Bank:
                                    <pre> </pre> <b>{{i.bank_nm}}</b>
                                </div>
                                <div style="display:flex;">
                                    Scholarship:
                                    <pre> </pre> <b>{{i.schp_dues_des}}</b>
                                </div>
                                <div style="display:flex;">
                                    Fee Concession:
                                    <pre> </pre> <b>{{i.fee_conc_des}}</b>
                                </div>
                            </div>

                            <div>
                                <div style="display:flex;">
                                    Father Name:
                                    <pre> </pre> <b>{{i.f_nm.toUpperCase()}}</b>
                                </div>
                                <div style="display: flex;">
                                    Description/University Dues:
                                    <pre> </pre><b>{{i.Unides}}</b>
                                </div>
                                <div style="display:flex;">
                                    Date Extension:
                                    <pre> </pre> <b>{{i.dtExt}}</b>
                                </div>
                                <div style="display: flex;">
                                    Scholarship Amount
                                    <pre> </pre><b>{{i.schp_amnt}}</b>
                                </div>
                                <div style="display:flex;">
                                    Fee Concession Amount:
                                    <pre> </pre> <b>{{i.fee_amnt}}</b>
                                </div>

                            </div>

                            <div>
                                <div style="display:flex;">
                                    Major:
                                    <pre> </pre> <b>{{i.maj}}</b>
                                </div>

                                <div style="display: flex;">
                                    Actual Amount:
                                    <pre> </pre><b>{{i.actAmnt}}</b>
                                </div>

                                <div style="display: flex;">
                                    Dues Adjustment:
                                    <pre> </pre><b>{{i.dues_adj_des}}</b>
                                </div>
                                <div style="display: flex;">
                                    Scholarship Entry Date:
                                    <pre> </pre><b>{{i.schp_dt}}</b>
                                </div>
                                <div style="display: flex;">
                                    Fee Conc Entry Date:
                                    <pre> </pre><b>{{i.fee_dt}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-0 mt-0">
                            <div class="shadow bg-white">
                                <table class="table table-bordered table-striped" style="font-size:12px">
                                    <thead class="thead-dark">
                                        <tr style="font-size:11px;text-align:center">
                                            <th style="font-weight:bold" scope="col">FEE DESCRIPTION</th>
                                            <th style="font-weight:bold" scope="col">TOTAL AMOUNT</th>
                                            <th style="font-weight:bold" scope="col">AMOUNT AWARDED</th>
                                            <th style="font-weight:bold" scope="col">AMOUNT AFTER SCHOLARSHIP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let a of schpDuesDetail">
                                            <td #fee_id value="a.fee_id" style="font-weight:bold">{{a.fee_desc}}</td>
                                            <td #amount style="font-weight:bold">{{a.amount}}</td>
                                            <td style="font-weight:bold">
                                                {{a.schp_amnt}}
                                            </td>
                                            <td style="font-weight:bold">
                                                {{a.amount-a.schp_amnt}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" style="font-weight:bold;font-size:12px">PAYABLE AMOUNT AFTER
                                                SCHOLARSHIP</td>
                                            <td style="font-weight:bold">{{totalPayAmnt}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</form>