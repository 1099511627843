import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsButtonComponent } from './details-button.component';


@NgModule({
  declarations: [DetailsButtonComponent],
  imports: [
    CommonModule
  ],
  exports: [DetailsButtonComponent]
})
export class DetailsButtonModule { }
