import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { UtilService } from 'src/app/Util.service';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SubjectOfferService } from './../shared/services/SubjectOffer.service';
import { GroupService } from './../shared/services/Groups.service';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { SessionService } from './../shared/services/Sessions.service';
import { MajorService } from './../shared/services/Major.service';
import { EnrollmentService } from '../shared/services/Enrollment.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { filter } from 'src/app/shared/functions/tableSearch';

declare let $:any;
interface student {
  ROLNO: string;
  NM: string
  RN: number;
  YEAR: number;
  SECTION: string;
  MAJ_ID: string;
}

@Component({
  selector: 'app-section-allocate',
  templateUrl: './section-allocate.component.html',
  styleUrls: ['./section-allocate.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class SectionAllocateComponent implements OnInit {
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  subjects: Array<{ sub_code: string, sub_nm: string, d_id, tm_status: number }>;

  students: Array<student>;
  section: Array<string>;

  groups: Array<SelectList>;
  isAdmin: boolean = false;
  group: number;
  isAllocated: boolean = false;
  lastRoll: string;
  isSection: string;
  dept: number;
  // flag: boolean = true;
  isExam: boolean = false;
  isPhilosophy: any;

  @ViewChild('f') formRef: NgForm;
  showInter:boolean = false;
  interMediateGroup:Array<SelectList>;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>you can allocate the section to students in 
  different subjects of any semester in any session in its major in their class
  and download excel report as well</h6>
  <h6 class="text-right">آپ کسی بھی سیشن میں کسی بھی سمسٹر کے مختلف سبجیکٹس میں طلباء کو ان کے میجر میں ان کی کلاس میں سیکشن الاٹ کر سکتے ہیں
   اور ایکسل رپورٹ بھی ڈاؤن لوڈ کر سکتے ہیں۔
</h6>
  `  ;

  constructor(
    private authenticationService: AuthenticationService,
    private utilService: UtilService,
    private toastr: ToastrService,
    private groupService: GroupService,
    private classService: ClassService,
    private sessionService: SessionService,
    private subOfferService: SubjectOfferService,
    private majorService: MajorService,
    private enrollmentService: EnrollmentService
  ) {
    this.classes = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.subjects = new Array<{ sub_code: string, sub_nm: string, d_id, tm_status: number }>();
    this.students = new Array<student>();
    this.section = new Array<string>();

    this.groups = new Array<SelectList>();

    this.isAdmin = this.authenticationService.isAdmin();
    this.group = this.authenticationService.getGroup();
    this.dept = this.authenticationService.getUser().D_ID;
  }


  ngOnInit(): void {
    console.log(this.group)
    this.isPhilosophy = this.group;
    this.loadGroups();
    this.updateIntermediateGroups();
  }
  // myFunction() {
  //   this.flag = !this.flag;
  //   var popUp;

  //   popUp = document.getElementById("myPopup"); 


  //   if (popUp.style.display === "none") {
  //     popUp.style.display = "block";
  //   } else {
  //     popUp.style.display = "none";
  //   }
  // }


  updateIntermediateGroups(){
    this.interMediateGroup=[
      {id:35, nm:'INTERMEDIATE PE O-Level'},
      {id:40, nm:'INTERMEDIATE PM O-Level'}
    ]
  }

  loadGroups() {
    this.classes = [];
    this.majors = [];
    this.sessions = [];
    this.subjects = [];
    this.groupService.getGroups({ grp: this.group, role: this.isAdmin }).subscribe((res: { GRP: number, G_NM: string }[]) => {
      res?.forEach(element => {
        this.groups.push({ id: element.GRP, nm: element.G_NM });
      });

      if(this.groups[0]?.id==1)  
        this.showInter=true;

      if (!this.isAdmin) {
        this.updateClasses();
      }
    }, err => {
      console.log(err);
    });
  }

  updateClasses() {
    this.classes = [];
    this.majors = [];
    this.sessions = [];
    this.subjects = [];
    this.classService.getClasses({ grp: this.group }).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
    });
  }

  updateMajors() {
    this.majors = [];
    this.sessions = [];
    this.subjects = [];
    this.majorService.getMajor({ grp: this.group.toString(), c_code: this.formRef.value.c_code }).subscribe((res: { MAJ_ID: number, MAJ_NM: string }[]) => {
      res?.forEach(entry => {
        this.majors.push({ id: entry.MAJ_ID, nm: entry.MAJ_NM });
      });
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });
  }

  updateSessions() {
    this.sessions = [];
    this.subjects = [];

    if (this.formRef.value.c_code === "") return;

    this.sessionService.getSessions(this.formRef.value.c_code).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(entry => {
        this.sessions.push({ id: entry.SE_ID, nm: entry.SE_NM });
      });
    });
  }

  updateSubjects() {
    this.subjects = [];
    if (!this.group || this.formRef.value.c_code === "" ||
      this.formRef.value.se_id === "" || this.formRef.value.t_no === "") return;


    this.subOfferService.getOfferedSubByGroup(this.group.toString(), this.formRef.value.c_code, this.formRef.value.se_id, this.formRef.value.t_no).subscribe((res: { SUB_CODE: string, D_ID: string, SUB_TITLE_PRINT: string, tm_status: number  }[]) => {
      res?.forEach(entry => {
        if (entry.tm_status == 1) {
        this.subjects.push({ sub_code: entry.SUB_CODE, sub_nm: entry.SUB_TITLE_PRINT, d_id: entry.D_ID, tm_status: entry.tm_status });
        }
      });
    });
  }


  updateStudentAndSection() {
    this.section = [];
    this.students = [];
    if(!this.formRef?.value.sub_code)return;
    this.enrollmentService.getStudentAndSection({ grp: this.group.toString(), c_code: this.formRef?.value.c_code, maj_id: this.formRef?.value.maj_id, t_no: this.formRef?.value.t_no, se_id: this.formRef?.value.se_id, sub_code: this.formRef?.value.sub_code, isAdmin: this.isAdmin, dept: this.dept }).
      subscribe((res: [student[], { SECTION: string }[]]) => {
        res[0]?.forEach(entry => {
          this.students.push(entry);
        })
        res[1]?.forEach(entry => {
          this.section.push(entry.SECTION);
        })
        if(this.students.length<=0){
          this.toastr.warning("Missing Student Enrollment");
        }
      }, err => {
        console.log(err);
      })
  }

  OnSubmit() {
    if (!this.formRef.valid)
      return;
  }


  UpdateClassSection(sec: HTMLElement) {
    if (this.isSection === sec.innerHTML) {
      sec.style.background = "rgba(255,255,0, 0.3)";
      const se = " ";
      this.utilService.updateWholeClassSection(this.formRef.value.se_id, this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.t_no, this.formRef.value.sub_code, se).subscribe(res => {
        this.toastr.success("Section Updated successfully");
      }, err => {
        console.log(err);
      });
      this.isSection = "";
    }
    else {
      this.utilService.updateWholeClassSection(this.formRef.value.se_id, this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.t_no, this.formRef.value.sub_code, sec.innerHTML).subscribe(res => {
        this.toastr.success("Section Updated successfully");
      }, err => {
        console.log(err);
      });
      this.isSection = sec.innerHTML;
    }
    this.updateStudentAndSection();

  }

  UpdateStudentSection(index: number, sec: string, curr: HTMLElement, rol: string, year: string) {

    let newSec = (sec === this.students[index].SECTION) ? '' : sec;

    const student = this.students[index];

    this.utilService.sectionAllocation(year, this.formRef.value.se_id, this.formRef.value.c_code, student?.MAJ_ID, this.formRef.value.t_no, rol, this.formRef.value.sub_code, newSec).subscribe((res: { SECTION: string }) => {
      if (res) {
        this.students[index].SECTION = res.SECTION;
        this.toastr.success("Section Updated successfully");
      } else {
        this.toastr.warning("Failed to update.");
      }
    }, err => {
      console.log(err);
    });
  }


  create_csv(maj, se) {
    const Major = (this.majors.find(m => m.id == maj)).nm;
    const Session = (this.sessions.find(s => s.id == se)).nm;
    const subject = this.formRef.value.sub_code;
    var Orderstudents = new Array<student>();
    Orderstudents = [...this.students];

    Orderstudents.forEach(entry => {
      if (entry.SECTION == null)
        entry.SECTION = '';
    })

    Orderstudents.sort((a, b) => {
      let fa = a.SECTION.toLowerCase(),
        fb = b.SECTION.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    let body = [];
    let i = 1;
    Orderstudents.forEach(entry => {
      body.push({ sr: i, Rolno: entry.ROLNO, name: entry.NM.toUpperCase(), Section: entry.SECTION });
      i++;
    });
    new AngularCsv(body, Major + "-" + Session + "-" + subject, { headers: ['Sr.', 'Roll Number.', 'Name', 'Section'] });
  }

  OnGrpChange(grp) {
    this.group = parseInt(grp.value);
    this.updateClasses();
  }

  OnSemesterChange(sem: HTMLSelectElement) {
    this.updateSubjects();
  }

  OnSessionChange(s: HTMLSelectElement) {
    if (this.formRef.value.t_no !== "") {
      this.updateSubjects();
    }
  }

  OnSubjectChange(sub: HTMLSelectElement) {
    // this.updateStudentAndSection();
  }

  OnMajorChange(m: HTMLSelectElement) {
    this.updateSessions();
  }


  OnClassChange(c: HTMLSelectElement) {
    this.updateMajors();
    this.formRef?.controls['maj_id'].reset();;
  }
  Clear(f) {
    f.resetForm();
    this.majors = [];
    this.sessions = [];
    this.subjects = [];
    this.students = [];
    if (this.isAdmin) {
      this.classes = [];
    }
  }

  getStudents() {
    this.updateStudentAndSection();
  }

  Filter()
  {
    filter();
  }

  Popover(event) {
    $(event.target).popover({
      title: "Section Allocation to whole class",
      trigger: "hover",
      html: true,
      placement: "top",
      content: "By pressing this, same section will be allocated to every student.<br><br> اس کو دبانے سے ہر طالب علم کو ایک ہی سیکشن الاٹ کر دیا جائے گا۔"
  });
  }
}
