import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class EnrollmentService {

    constructor(private http: HttpClient) {
    }

    self_suppporting_enrollment(c_code: string, maj_id: string, t_no: string, year: string) {
        return this.http.post(`${baseUrl}/api/enrollment/self_suppporting_enrollment`, { c_code: c_code, maj_id: maj_id, t_no: t_no, year: year });
    }

    Fa_fsc_section_allocation(year: string, c_code: string, t_no: string) {
        return this.http.post(`${baseUrl}/api/enrollment/Fa_fsc_section_allocation`, { year: year, c_code: c_code, t_no: t_no });
    }

    Fa_fsc_sec_allocation_maj(year: string, c_code: string, t_no: string, maj_id: string) {
        return this.http.post(`${baseUrl}/api/enrollment/Fa_fsc_sec_allocation_maj`, { year: year, c_code: c_code, t_no: t_no, maj_id: maj_id });
    }
    section_change(year: string, c_code: string, t_no: string) {
        return this.http.post(`${baseUrl}/api/enrollment/section_change`, { year: year, c_code: c_code, t_no: t_no });
    }

    ba_bsc_section_allocation(year: string, c_code: string, t_no: string) {
        return this.http.post(`${baseUrl}/api/enrollment/ba_bsc_section_allocation`, { year: year, c_code: c_code, t_no: t_no });
    }

    ba_bsc_section_allocation_maj(year: string, c_code: string, t_no: string, maj_id: string) {
        return this.http.post(`${baseUrl}/api/enrollment/ba_bsc_section_allocation_maj`, { year: year, c_code: c_code, t_no: t_no, maj_id: maj_id });
    }

    babsc_repeater_timetable(c_code: string, year: string, t_no: string) {
        return this.http.post(`${baseUrl}/api/enrollment/babsc_repeater_timetable`, { c_code: c_code, year: year, t_no: t_no });
    }

    delete_maj_records(c_code: string, year: string, t_no: string, maj_id: string) {
        return this.http.post(`${baseUrl}/api/enrollment/delete_maj_records`, { c_code: c_code, year: year, t_no: t_no, maj_id: maj_id });
    }

    delete_all_records(c_code: string, year: string, t_no: string) {
        return this.http.post(`${baseUrl}/api/enrollment/delete_all_records`, { c_code: c_code, year: year, t_no: t_no });
    }

    getStudentAndSection(param) {
        return this.http.post(`${baseUrl}/api/enrollment/getStudentAndSection`, param);
    }

    checkEnrollmentStatus(params) {
        return this.http.post(`${baseUrl}/api/enrollment/U_checkEnrollmentStatus`,params);
    }

      
    notEnroll(params) {
        return this.http.post(`${baseUrl}/api/enrollment/notEnroll`,params);
    }

    notEnrollFeeServer(params) {
        return this.http.post(`${baseUrl}/api/enrollment/notEnrollFeeServer`,params);
    }

    singleEnroll(params){
        return this.http.post(`${baseUrl}/api/enrollment/single_enrollment`,params);
    }
    // added by zain  13/04/2022 10:25 pm
    SAO_checkEnrollment(params){
        return this.http.post(`${baseUrl}/api/enrollment/SAO_checkEnrollment`,params);
    }

    // added by zain  13/04/2022 10:51 pm
    SAO_deleteEnrollment(params){
        return this.http.post(`${baseUrl}/api/enrollment/SAO_deleteEnrollment`,params);
    }

    enrollSingleSubject(params){
        return this.http.post(`${baseUrl}/api/enrollment/enrollSingleSubject`,params);
    }
    // added by zain  14/04/2022 12:02 am
    SAO_OnDeleteSingleSubjectEnroll(params){
        return this.http.post(`${baseUrl}/api/enrollment/SAO_OnDeleteSingleSubjectEnroll`,params);
    }
    


    // For IDC
    getidcStudentAndSection(param) {
        return this.http.post(`${baseUrl}/api/enrollment/getIDCStudentAndSection`, param);
    }

    // For Re-Admission....
    getAdmStudent(c_code,se_id,year,rn){
        return this.http.get(`${baseUrl}/api/enrollment/getStudentData/${c_code}/${se_id}/${year}/${rn}`);
    }

    insertAdmRecord(rolno,cat,se_id,t_no){
        return this.http.get(`${baseUrl}/api/enrollment/insertNewStudentRecord/${rolno}/${cat}/${se_id}/${t_no}`);
    }
    insertAdmRecordFeeServer(rolno,cat,se_id,t_no){
        return this.http.get(`${baseUrl}/api/enrollment/insertNewStudentRecordFee/${rolno}/${cat}/${se_id}/${t_no}`);
    }

    delAdmRecord(rolno,t_no){
        return this.http.get(`${baseUrl}/api/enrollment/deleteAdmRecord/${rolno}/${t_no}`);
    }
    delAdmRecordFee(rolno,t_no){
        return this.http.get(`${baseUrl}/api/enrollment/deleteAdmRecordFee/${rolno}/${t_no}`);
    }

    checksubjectsetinfo(c_code,maj_id,year_in,t_no,sub_code){
        return this.http.get(`${baseUrl}/api/enrollment/checksubjectsetinfo/${c_code}/${maj_id}/${year_in}/${t_no}/${sub_code}`);
    }
    checksubjectsetinfoagain(c_code,maj_id,year_in,t_no,rolno){
        return this.http.get(`${baseUrl}/api/enrollment/checksubjectsetinfoagain/${c_code}/${maj_id}/${year_in}/${t_no}/${rolno}`);
    }

    checksubjectsetinfoagainenrol(c_code,maj_id,year_in,t_no){
        return this.http.get(`${baseUrl}/api/enrollment/checksubjectsetinfoagainenrol/${c_code}/${maj_id}/${year_in}/${t_no}`);
    }
    getClassStudentsForEnrollment(c_code,maj_id,se_id)
    {
        
        return this.http.post(`${baseUrl}/api/enrollment/getClassStudentsForEnrollment`, { c_code, maj_id,se_id})
    }

       // studylevelandyear
       getstudylevelalongwithyear(){
        return this.http.get(`${baseUrl}/api/enrollment/getstudylevelandyear`);
    }
    getlevelwisestudentcount(lvl_id,year_in){
        return this.http.get(`${baseUrl}/api/enrollment/getlevelwisestudentcount/${lvl_id}/${year_in}`);
    }

    getattreport(date_in,d_id,date_to,t_no){
        return this.http.get(`${baseUrl}/api/enrollment/getattreport/${date_in}/${d_id}/${date_to}/${t_no}`);
    }

    getTotalStudentsInSingleYear(lvl_id,year_in){
        return this.http.get('${baseUrl}/api/enrollment/getTotalStudentsInSingleYear/${lvl_id}/${year_in}');        
    }
}