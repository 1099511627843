<!-- <form (ngSubmit)="OnSubmit()" #f="ngForm" > -->
  <form #f (ngSubmit)="OnSubmit()">
    <div [@SlideInFromLeft]>
      <div class="container-fluid card shadow">
        <div class="card-header" style="padding-top: 10px;">
          <div class="col-lg-4 col-md-6 form-group">
            Fine Description
          </div>
          <!-- <div class="div1">
              <button class="btn1 fa" (click)="myFunction()" 
              [ngClass]="flag ? 'fa-exclamation-circle' : 'fa-close'"
              [ngStyle]="{'color': flag? 'blue' : 'tomato'}"
              ></button> 
              <div class="popuptext" style="font-size: 10px; display: none;" id="myPopup">
              <h6>I am Here, look at me!</h6>
              <p style="margin-right: 4px; margin-left: 4px;">I am your stereo, it beats for you so listen close. There is an old man sitting next to me. MAking love to his tonic and gin</p>
              <p style="margin-right: 4px; margin-left: 4px;">Hey son can you please play me a memory, I am not sure how it goes, but it's sad and it's sweet and i knew it complete when i wore a younger man's clothes</p>
          </div>
      </div> -->
    </div>

        <div class="row card-body" style="padding-bottom: 0px;">    
          
          <div class="col-lg-4 col-md-6 form-group">
            <label for="fineId">Fine ID</label>
            <input type="number" class="form-control" name="fine_id" min="1" ngModel required (change)="ClearFineIDBase()">
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="fineDescription">Description</label>
            <input type="string" class="form-control" name="fine_desc" min="1" ngModel required>
          </div>
        </div>
  
        <div class="card-header">
          Actions
        </div>
        <div class="row card-body">
          <div class="">
            <button  (click)="addFineDescription()" class="btn ml-3 btn-outline-primary" [disabled]="!f.valid">
              Add
            </button>
         
  
            <button class="btn ml-1 btn-outline-primary" type="button" (click)="ClearForm(f)">Clear Form</button>
  
          </div>
  
  
        </div>
        <div class=" card-header mt-3" *ngIf='fineDesc?.length'>
            Fine Description
          </div>
          <div  class="card-body">
            <table class="table table-bordered table-striped" *ngIf="fineDesc?.length>0">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Fine ID</th>
                  <th scope="col">Description</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let f of fineDesc; let j =index;">
                  <td>{{f.id}}</td>
                  <td>{{f.nm}}</td>
                  <td><button class="btn btn-danger" (click)="deleteFine(f.id,j)" style="font-size: 12px;">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
  </form>
  