import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class AssessmentService {

    constructor(private http: HttpClient) {
    }

    updateAssessment(id:number,marks:number,weightage:number,dueDate,publishDate){
        return this.http.post(`${baseUrl}/api/assessment/updateAssessment`, { id, marks, weightage, dueDate,publishDate});
    }
    insertFmAssessment(params) {
        return this.http.post(`${baseUrl}/api/assessment/insertAssignment`, params);
    }

    getAssessmentList(param) {
        return this.http.post(`${baseUrl}/api/assessment/getAssessmentList`, param);
    }


    getStdAssessmentList(id: number) {
        return this.http.get(`${baseUrl}/api/assessment/getStdAssementList/${id}`);
    }


    getAssessments(params){
        return this.http.post(`${baseUrl}/api/assessment/getAssessments`,params)
    }



    insertStdAssessment(params){
        return this.http.post(`${baseUrl}/api/assessment/insertStdAssessment`, params);
    }

    
    // std assessment
    getStdAssessments(params){
        return this.http.post(`${baseUrl}/api/assessment/getStdAssessments`, params);

    }


    //fm
    updateAssessmentMarks(params) {
        return this.http.put(`${baseUrl}/api/marks/updateStudentMarks`, params);
    }

    //std grade book
    getAssessmentMarkList(params) {
        return this.http.post(`${baseUrl}/api/marks/getAssessmentMarksList`, params);
    }
    
    getDetAssessmentMarkList(params) {
        return this.http.post(`${baseUrl}/api/marks/getDetAssessmentMarksList`, params);
    }
    
    
    updateWeightage(id: number, newWeightage){
        return this.http.post(`${baseUrl}/api/marks/updateWeightage`, {id, newWeightage});
    }
// For single awardlist/Assessment creation of single subject
    shiftSingleStdAssessments(params){
        return this.http.post(`${baseUrl}/api/assessment/shiftSingleStdAssessments`, params);
    }
    updateSingleStdMrksInAssSubm(params){
        return this.http.post(`${baseUrl}/api/assessment/updateSingleStdMrksInAssSubm`, params);
    }
}