import {ActionReducerMap} from '@ngrx/store';
import * as fromTeacherInformation from '../main/teacher-information/store/teacher-information.reducer';
import * as fromTimeTable from '../main/time-table/store/time-table.reducer';
import * as fromCourseUpload from '../main/course-upload/store/course-upload.reducer';
import * as fromAssignment from '../main/mark-assessment/assignments/store/assignment.component.reducer';

export interface AppState {
  fromTeacherInformation: fromTeacherInformation.State;
  fromTimeTable: fromTimeTable.State;
  fromCourseUpload: fromCourseUpload.State;
  fromAssignment: fromAssignment.State;
}

export const appReducers: ActionReducerMap<AppState> = {
    fromTeacherInformation: fromTeacherInformation.teacherInformationReducer,
    fromTimeTable: fromTimeTable.TimeTableReducer,
    fromCourseUpload: fromCourseUpload.CourseUploadReducer,
    fromAssignment: fromAssignment.AssignmentComponentReducer
  }
;
