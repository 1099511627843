import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from '../../transitions';
import { Timetable, TimetableService } from '../shared/services/Timetable.service';
import { DatePipe } from '@angular/common';
import autoTable, { UserOptions } from 'jspdf-autotable'
import 'jspdf-autotable';
import { AuthenticationService } from 'src/app/auth/_services';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { jsPDF } from "jspdf";
import { Location } from '@angular/common';
interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

@Component({
  selector: 'app-time-table',
  templateUrl: './time-table.component.html',
  styleUrls: ['./time-table.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class TimeTableComponent implements OnInit {
  @ViewChild('convert') data: ElementRef;
  days: Array<string>;
  btnHide: boolean;
  time: any;
  currentURL='';

  public timetableData: [Timetable[], Timetable[], Timetable[], Timetable[], Timetable[]];
  private usr = this.authenticationService.getUser();
  constructor(private timetableService: TimetableService,
    private datePipe: DatePipe, private toastr: ToastrService,
    private authenticationService: AuthenticationService
  ) {
    this.days = new Array<string>();
    this.btnHide = false;
    this.currentURL = window.location.href;
  }

  ngOnInit() {
    this.timetableData = this.timetableService.getTimetable();
    
      this.sortTimeTableByTime();
      this.getDaysFromTM();
  }
  private async sortTimeTableByTime() {
    for (var i = 0; i < this.timetableData?.length; i++) {
      this.timetableData[i]?.sort((a, b) =>
        a.START_TIME < b.START_TIME ? -1 :
          a.START_TIME > b.START_TIME ? 1 : 0
      )
    }
  }
  private getDaysFromTM() {
    for (var i = 0; i < this.timetableData?.length; i++) {
      var d: string = '';
      for (var j = 0; j < this.timetableData[i]?.length; j++) {
        d = this.timetableData[i][j]?.DAY.toString();
        if (!this.days.includes(d)) {
          this.days.push(d);
        }
      }
    }
    if (this.days.length != 0) {
      this.btnHide = true;
    }
  }

  CreatePdf() {
    var body = [];
    var header = [];
    if (this.days.length == 0) {
      this.toastr.warning('Time Table Not Exist.')
      return;
    }
    this.toastr.info("Downloading TimeTable");
    var image = new Image();
    image.src = '../../../assets/images/logo3.png';
    const doc = new jsPDF('p', 'mm', [297, 210]);
    doc.addImage(image, 180, 2, 18, 18);
    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y')
    doc.setFontSize(8);
    doc.text(`${exportDate}`, 181, 24);
    doc.setFontSize(14);
    doc.setFont('Arial', 'bold');
    doc.text("GC UNIVERSITY LAHORE", 100, 12, { align: 'center' });
    doc.setFontSize(13);
    doc.setFont('Arial', 'normal');
    doc.text("Teacher Time Table", 100, 20, { align: 'center' });
    doc.setFontSize(11);
    doc.setFont('Arial', 'bold');
    doc.text('Teacher Name: ', 15, 27);
    var teacherName = this.usr.NM;
    doc.text(teacherName, 47, 27);

    for (var i = 0; i < this.days.length; i++) {//DAYS
      header.push(
        [
          { content: this.days[i], colSpan: 8, styles: { halign: 'left', textColor: [0, 0, 0], fontSize: 11, fillColor: [255, 255, 255] } }
        ], ['Semester', 'Course Code', 'Course Name', 'Time', 'Building', 'Room', 'Section', 'Class']
      );

      for (var j = 0; j < this.timetableData.length; j++) {//MAJOR ARRAY
        for (var k = 0; k < this.timetableData[j].length; k++) {//INNER ARRAY

          if (this.days[i] == this.timetableData[j][k]?.DAY) {
            var obj = this.timetableData[j][k];
            var tt = moment(obj?.START_TIME, 'h:mmA').format('hh:mmA') + '-' + moment(obj?.END_TIME, 'h:mmA').format('hh:mmA');
            body.push([obj?.T_NO, obj?.SUB_CODE, obj?.SUB_NM, tt, obj?.BLK_NM, obj?.ROOM_NM, obj?.SECTION, obj?.C_NM]);
          }
        }
      }
      autoTable(doc, {
        styles: { fontSize: 7 },
        columnStyles: {
          0: {
            fontStyle: 'bold', fontSize: 8
          }
        },
        theme: 'grid',
        margin: { top: 33, bottom: 10 },
        head: header,
        body: body,
      });
      body = [];
      header = [];
    }
    var a = this.toastr;
    setTimeout(function () {
      a.success("Downloaded");
    }, 1000);

    doc.save('Time Table');
  }
}