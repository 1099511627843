<!-- <div class="row p-2" [@SlideInFromLeft]> -->
    <!-- <div class="col-sm-12"> -->
      <!-- <div class="btn-group" role="group" aria-label="Basic example"> -->
        <!-- <p>Hello</p> -->
        <!-- <button type="button" class="btn btn-outline-info" routerLinkActive="active" routerLink="singleSubjectStudents">Report</button> -->
        <!-- <button type="button" class="btn btn-outline-info" routerLinkActive="active"
          routerLink="subject">Subject Calender</button> -->
      <!-- </div> -->
    <!-- </div> -->
  <!-- </div> -->
  <!-- <br><br> -->
  <router-outlet></router-outlet>
  