<div class="card" [@SlideInFromLeft]>
  <form #f="ngForm">
    <div class="card-header">Manual Challan</div>
    <div class="card-body row">
      <div class="form-group col-lg-4">
        <label for="sub">Classes</label>
        <select class="form-control" id="sub" aria-label="ClassCode" ngModel required name="c_code"
          (ngModelChange)="updateMajors()" (change)="ClearClassBase()">
          <option *ngFor="let i of classes" value="{{ i.id }}">
            {{ i.nm }}
          </option>
        </select>
      </div>

      <div class="col-lg-4 col-md-6 form-group">
        <label for="major">Major</label>
        <select class="form-control" #major name="maj_id" id="major" required ngModel (change)="ClearMajorBase()">
          <option *ngFor="let m of majors" value="{{ m.id }}">
            {{ m.nm }}
          </option>
        </select>
      </div>

      <div class="col-lg-4 col-md-6 form-group">
        <label for="Installment">Installment</label>
        <select #installment class="form-control " #session name="inst_no" (change)="UpdateEntry()" ngModel (change)="ClearInstBase()">
          <option value="0">OTHER</option>
          <option value="-1">SUBJECT REPEATER</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
      </div>

      <div class="col-lg-4 col-md-6 form-group">
        <label for="Year">Year</label>
        <select class="form-control" #session name="year_in" id="Year" (change)='OnYearChange()' ngModel>
          <option *ngFor="let s of sessions" value="{{s}}">{{s}}</option>
        </select>
      </div>


      <div class="col-lg-4 col-md-6 form-group">
        <label for="rolno">Roll Number</label>
        <select class="form-control" name="rolno" id="rolno" ngModel required>
          <option *ngFor="let r of rolnos" value="{{r.nm}}">{{r.nm}}</option>
        </select>
      </div>
      <div class="ml-3" style="margin-top:24px">
        <button class="btn btn-outline-primary" (click)="getCreateChallan()" [disabled]="!f.valid">Create a New Challan</button>
      </div>
      
      <div class="card-body ml-3 mr-3 mt-4" style="box-shadow: 2px 2px 15px #888888;" *ngIf="StudentInfo?.length>0" >
        <div *ngIf="pendingDues?.length > 0 || pendingSubDues?.length > 0" class="card-header mb-2" style="background-color: #fda0b4;">
          <div class="container-fluid d-flex justify-content-between align-items-center">
            <ul class="mt-2">
              <li *ngIf="pendingDues?.length > 0" >
                <b>Your Challan(s) for semester ({{pendingDues[0].Unpaid_Challans}}) of amount RS.{{pendingDues[0].pending_amount}} is pending.</b></li>
            <li *ngIf="pendingSubDues?.length > 0">
              <b>Your Sub-Challan(s) for semester ({{pendingSubDues[0].Unpaid_subChallans}}) of amount RS.{{pendingSubDues[0].pending_amount_subCha}} is pending.</b></li>
            </ul>
            
            <div class="d-flex align-items-center">
              <b>REMARKS: {{pendingDues[0].remarks}}</b> 
            </div>
        </div>
        </div>
      <div class="row" style="justify-content:space-evenly;font-size:14px" *ngFor="let s of StudentInfo; let j =index;">
        <div class="mt-2 mb-2">
          <div style="display:flex;">
            <td><b>CHALLAN#: </b>{{s.ch_no}}</td>
          </div>
        
          <div style="display: flex;" class="mt-3">
            <td><b>NAME: </b>{{s.nm}}</td>
          </div>
        </div>

        <div class="mt-2 mb-2">
          <div style="display:flex;">
            <td><b>FORM/ROLL-NO: </b>{{s.num}}</td>
          </div>
          
          <div style="display: flex;" class="mt-3">
            <td><b>FATHER NAME: </b>{{s.f_nm}}</td>
          </div>

        </div>

        <div class="mt-2 mb-2">
          <div style="display: flex;">
            <td><b>DUE DATE: </b>{{s.dueDate| date : "dd-MM-y"}}</td>
          </div>
          <div style="display: flex;" class="mt-3">
            <td><b>SEMESTER/INST-NO: </b>{{s.sem}}</td>
          </div>

        </div>
      </div>
        <div class="row" 
        style="padding-top:2%;padding-bottom:2%;justify-content:space-evenly;font-weight:bold" id="divP">

              <div>
                  <div *ngFor="let f of feeDesc1" class="row mb-1"style="justify-content:space-between;">  
                  <span >{{f.fee_nm}}</span>
                  <span class="ml-1"><input class="inpF" type="number" min="0"#inp (keydown)="Handle($event)"  (keyup)="InsertIntoStudentAmount(f.fee_id,inp.value)"></span>
                </div>
              </div>
                
                <div class="ml-1">  
                <div *ngFor="let f of feeDesc2"  class="row mb-1" style="justify-content:space-between">  
                  <span>{{f.fee_nm}}</span>
                  <span class="ml-1"><input class="inpF" type="number" min="0" #inp2 (keydown)="Handle($event)" (keyup)="InsertIntoStudentAmount(f.fee_id,inp2.value)"></span>
                </div>
                </div>
                
                <div class="ml-1">  
                <div *ngFor="let f of feeDesc3"  class="row mb-1" style="justify-content:space-between">  
                  <span>{{f.fee_nm}}</span>
                  <span class="ml-1">
                    <input class="inpF" [attr.placeholder]="f.fee_id ==35 || f.fee_id == 36 ? 'FOR FINE' : ''" type="number" min="0" #inp3 (keydown)="Handle($event)"  (keyup)="InsertIntoStudentAmount(f.fee_id,inp3.value)"></span>
                </div>
              </div>
        </div>
        <div style="font-size:16px;margin-left:12px;margin-top:18px;" >
          <b>TOTAL AMOUNT: </b><b style="color:red;font-size:18px">{{totalAmnt}}</b>
        </div>  
        <div class="row" style="justify-content:right;align-items:right;flex-grow:2;margin-top:-4.5%">

            <div class="col-lg-3 mt-3"> 
              <input type="date" value="{{curdate()}}" [maxLength]="3" class="form-control Due" name="exDate" #exDate
                (change)="DueDate(exDate.value)" style="border: 1px solid black" placeholder="Due Date....">
            </div>

            <div class="col-lg-4 mt-3 row">
              <input class="form-control Due" style="float:right;border: 1px solid black" placeholder="DUES FOR...." name="des_in"  (keydown)="Handle($event)"  ngModel required>
            </div>
            
            <div class="col-lg-2 mr-3 mt-3" >
              <button class="btn btn-success col-lg-12 DownBtn"  (click)="InsertAndPrintChallan()"
              style="float:right" >
              <i class=" fa fa-duotone fa-download DownBtn"></i> Download
            </button>
            </div>
        </div> 
      </div>
    </div>
  </form>
</div>
