import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})
export class MajorService {

    constructor(private http: HttpClient) {
    }
    LockUnlockAllClasses(lock:number){
        return this.http.get(`${baseUrl}/api/major/LockUnlockAllClasses/${lock}`);
    }

    LockUnlockSpecificClass(c_code:number,t_no:number,lock:number){
        return this.http.get(`${baseUrl}/api/major/LockUnlockSpecificClass/${c_code}/${t_no}/${lock}`);
    }
    LockSpecificMajor(c_code:number,maj_id:number,se_id:string,t_no:number,action:number,lock:number){
        return this.http.get(`${baseUrl}/api/major/LockUnlockSpecificMajor/${c_code}/${maj_id}/${se_id}/${t_no}/${action}/${lock}`);
    }

    getClassLock(c_code:number,t_no:string) {
        return this.http.get(`${baseUrl}/api/major/getClassLock/${c_code}/${t_no}`);
    }

    getMajorLock(c_code:number,maj_id:number,se_id:number,t_no:string) {
        return this.http.get(`${baseUrl}/api/major/getMajorLock/${c_code}/${maj_id}/${se_id}/${t_no}`);
    }

    LockUnlockAllMajors(lock:number){
        return this.http.get(`${baseUrl}/api/major/LockUnlockAllMajors/${lock}`);
    }

    getlockingdetailformajor(c_code:number,maj_id:number,se_id:number,t_no:number){
        return this.http.get(`${baseUrl}/api/major/getlockingdetailformaj/${c_code}/${maj_id}/${se_id}/${t_no}`);
    }

    getlockingdetailforclass(c_code:number){
        return this.http.get(`${baseUrl}/api/major/getlockingdetailforclass/${c_code}`);
    }

    //d_id, c_code, grp to filter
    getMajor(params) {
        return this.http.post(`${baseUrl}/api/major/getsharedMajor/`, params);
    }
    
    getMajorFeeServer(params) {
        return this.http.post(`${baseUrl}/api/major/getMajorFeeServer/`, params);
    }

    getMajors(){
        return this.http.get(`${baseUrl}/api/major/getMajors/`);
    }

    std_PDF(rolno:string){
    return this.http.get(`${baseUrl}/api/session/std_PDF/${rolno}`);
    }
    getMajorsWithTitle(c_code:string){
        return this.http.get(`${baseUrl}/api/major/getMajorsWithTitle/${c_code}`);
    }
    updateMajorTitle(params){
        return this.http.put(`${baseUrl}/api/major/updateMajorTitle`, params);
    }
}