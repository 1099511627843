<form #f="ngForm">
  <div [@SlideInFromLeft] class="tabber">
    <div class="container-fluid card shadow">
      <div class="card-header" style="padding-top: 10px">
        <div class="col-lg-6 col-md-6 form-group">Recalculate Eligibility</div>
        <div
          class="col-lg-6 col-md-6 form-group"
          style="text-align: right; text-transform: capitalize"
        >
          <app-details-button [details]="details"></app-details-button>
        </div>
      </div>
      <div class="row card-body space-remover">
        <div class="col-lg-4 col-md-6 form-group">
          <label for="rolno">Roll Number</label>
          <input
            type="text"
            class="form-control"
            name="rolno"
            id="rolno"
            (change)="loadSession()"
            required
            ngModel
          />
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="se_id">Session</label>
          <select
            class="form-control"
            #se_id
            name="se_id"
            id="se_id"
            ngModel
            required
          >
            <option *ngFor="let s of sessions" value="{{ s.se_id }}">
              {{ s.year }}
            </option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="t_no">Semester</label>
          <select
            #t_no
            class="form-control"
            name="t_no"
            id="t_no"
            (change)="loadSubject()"
            required
            ngModel
          >
            <option
              *ngFor="let i of [0, 1, 2, 3, 4, 5, 6, 7, 8]"
              value="{{ i }}"
            >
              {{ i }}
            </option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="sub_code">Subject</label>
          <select
            class="form-control"
            #sub_code
            name="sub_code"
            id="sub_code"
            (change)="loadData()"
            ngModel
            required
          >
            <option *ngFor="let s of sub" value="{{ s.SUB_CODE }}">
              {{ s.SUB_CODE }}-{{ s.sub_nm }}
            </option>
          </select>
        </div>

        <!-- <div *ngIf="stdt" class="row mt-3 col-lg-4 col-md-6 form-group">
                    <button type="button" class="btn btn-sm btn-primary" style="margin-top: 0.85rem!important;">
                     Recalculate Eligibility
                    </button>
                </div> -->
      </div>
      <!-- <div class="div1">
        <h5>
          <span class="btn1 badge badge-secondary">
            Percentage: {{ this.var }}%
            <br />
            Status: {{ this.stat }}
          </span>
        </h5>
      </div> -->

      <div
        *ngIf="this.stdt.length"
        class="card-body"
        style="overflow-x: scroll; margin-top: -4rem"
      >
        <table class="table table-hover thead-dark">
          <thead>
            <tr class="bg-light">
              <th colspan="2" class="text-left">
                Percentage: {{ this.var }}%
              </th>
              <th colspan="2" class="text-right">Status: {{ this.stat }}</th>
            </tr>
            <tr class="thead-dark">
              <th>Month</th>
              <th>Date</th>
              <th>Attend</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let x of stdt; let i = index"
              [ngClass]="{ 'text-danger': x.attend == 'a' }"
            >
              <td value="x.month_nm">
                {{ x.mnth }}
              </td>
              <td value="x.id">
                {{ x.da_date }}
              </td>
              <td>
                {{ x.attend }}
              </td>
              <td>
                <!-- x.allow -->
                <div class="form-check form-switch col-lg-4 col-md-6 pb-0">
                  <!-- <button class="btn btn-outline-primary" #present
                                        style="margin-top: -37px; inline-size: min-content; min-inline-size: max-content;"
                                        (click)="updateDailyAttendance(x,$event)" type="button">Mark
                                        Present
                                    </button> -->
                  <!-- <div class=""> -->

                  <!-- 
                                                <input class="form-check-input" type="checkbox" id="{{'x-' + i}}"
                                        (change)="updateDailyAttendance(x,$event)" [checked]="x.attend == 'p'">
                                        <label class="form-check-label" for="{{'x-' + i}}">Update</label>  
                                    -->
                  <button
                    [ngClass]="
                      x.attend == 'p'
                        ? 'btn btn-outline-danger'
                        : 'btn btn-outline-primary'
                    "
                    (click)="updateDailyAttendance(x, $event)"
                  >
                    {{ x.attend == "p" ? "Mark Absent" : "Mark Present" }}
                  </button>

                  <!-- </div> -->
                  <!-- <label class="cs custom-control-label text-center" for="{{'x-' + i}}" ></label>
                                    <input #attendanceToggle type="checkbox"
                                    class="custom-control-input"
                                    name="{{x.rolno}}-attendanceStatus"
                                    [checked]="x.attend == 'a'"
                                    (change)="updateDailyAttendance(x,$event)"
                                    id="{{'x-' + i}}"> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <div *ngIf="stdt">
                <div class="mb-3">
                    <table class="table table-bordered table-striped m-0 ml-5 p-0 " style="width: 70%;">
                        <thead class="thead-dark">
                            <tr class="table-active">
                                <th>Month</th>
                                <th>Date</th>
                                <th>Attend</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of stdt;let i=index" [ngClass]="{'text-danger':x.attend=='a'}">
                                <td value="x.month_nm">
                                    {{x.mnth}}
                                </td>
                                <td value="x.id">
                                    {{x.da_date}}
                                </td>
                                <td>
                                    {{x.attend}}
                                </td>
                                <td>
                                    <div *ngIf="x.allow" class="form-check form-switch col-lg-4 col-md-6 pb-0"> -->
    <!-- <button class="btn btn-outline-primary" #present
                                            style="margin-top: -37px; inline-size: min-content; min-inline-size: max-content;"
                                            (click)="updateDailyAttendance(x,$event)" type="button">Mark
                                            Present
                                        </button> -->
    <!-- <div class=""> -->
    <!-- <input class="form-check-input" type="checkbox" id="{{'x-' + i}}"
                                            (change)="updateDailyAttendance(x,$event)" [checked]="x.attend == 'p'">
                                            <label class="form-check-label" for="{{'x-' + i}}">Update</label> -->
    <!-- </div> -->
    <!-- <label class="cs custom-control-label text-center" for="{{'x-' + i}}" ></label>
                                        <input #attendanceToggle type="checkbox"
                                        class="custom-control-input"
                                        name="{{x.rolno}}-attendanceStatus"
                                        [checked]="x.attend == 'a'"
                                        (change)="updateDailyAttendance(x,$event)"
                                        id="{{'x-' + i}}"> -->

    <!-- </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
  </div>
</form>
