<div class="row p-2" [@SlideInFromLeft]>
  <div class="col-sm-12">
    <div class="btn-group" role="group" aria-label="Basic example" style="margin-left: -8px">
      <button type="button" class="btn btn-outline-info" routerLinkActive="active" routerLink="AttendanceReport"
         (click)="AttendanceReportClick()">Attendance Report</button>    
    </div>
  

  
    
   
  </div>
</div>
<router-outlet></router-outlet>