import {Injectable} from '@angular/core';
import {
  Router,
  CanLoad,
  Route,
  UrlSegment
} from '@angular/router';

import {AuthenticationService} from '../_services';
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanLoad {
  constructor(private authenticationService: AuthenticationService,
              private router: Router) {
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {

    if (!this.authenticationService.loggedIn()){
        this.router.navigate(['auth']);
      }
    return this.authenticationService.loggedIn();
  }
}
