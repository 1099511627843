<div [@SlideInFromLeft] class="tabber backdrop" style="overflow:scroll; overflow-y: scroll; margin-left: 5%; margin-top: 1%;">
    <div class="container-fluid mb-1 card shadow alert-box  col-lg-8 ml-2 col-md-12 offset-sm-3 ml-2 mr-2 ml-sm-auto mr-sm-auto">
      <div class="card-header d-flex justify-content-between">
        <div>Student Details </div>
            <button (click)="OnClose()" class="btn btn-outline-primary btn-sm">Close</button>
    </div>
    <div class="col-lg-12 col-md-12" style="display: flex !important; width: 100%;">
    <div class="card" style="flex: none !important; width: 50%;">
        <div class="card-header" style="flex: none !important;">
          <span *ngIf="attendanceLoading" class="spinner-border  spinner-border-sm mr-1"></span>
          Overall Attendance Report with Detail
        </div>
        <div class="card-body" style="flex: none !important;">
          <div class="form-group mt-2">
            <h4>{{this.enrolInfo.sub_code}} ({{this.enrolInfo.subject}})</h4>
          </div>
          <div class="chart-wrapper">
            <canvas baseChart #chart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
              [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend">
            </canvas>
          </div>
        </div>
        <div class="card-footer" style="flex: none !important;">
          <div class="row pb-2">
            <div class="col-sm-4 m-auto">Presents: {{this.presents}}</div>
            <div class="col-sm-4 m-auto">Absents: {{this.absents}}</div>
          </div>
        </div>
      </div>
        <div class="card" style="width: 100% !important;">
          <div class="card-header">Details </div>
          <div class="card-body">
            <div class="chart-wrapper">
                <table class="table">
                  <thead class="thead-dark">
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let a of attendance; let i=index;" [ngClass]="{ 'text-danger' : a.ATTEND==='a' || a.ATTEND === 'A' }">
                        <td>{{i+1}}</td>
                        <td>{{a.DA_DATE | date: "dd MMM , y" }}</td>
                        <td >
                          {{(a.ATTEND==='p' || a.ATTEND === 'P')? "Present" : "Absent"}}</td>
                      </tr>
                    </tbody>
                  </table></div>
          </div>
        </div>

      </div>
    </div>
  </div>