<form (ngSubmit)="OnSubmit()" #f="ngForm">
    <div class="container-fluid" [@SlideInFromLeft]>
        <div class="row ">
            <div class="col-sm-12 p-2">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <div>Sessions</div>
                    </div>
                    <div class="row card-body pb-0">
                        <div class="col-lg-4 col-md-6 form-group">
                            <label for="C_Code">Class</label>
                            <select #cource class="form-control " name="C_Code"
                                id="C_Code" (change)="onEndYearChange()"
                                [(ngModel)]="cls">
                                <option *ngFor="let c of classes"
                                    value="{{c.id}}">{{c.nm}}</option>
                            </select>
                        </div>

                        <div class="col-lg-4 col-md-6 form-group">
                            <label for="st_dt">Session Start Date</label>
                            <input #st_dt type="date" [maxLength]="3"
                                (change)="onEndYearChange()"
                                class="form-control"
                                name="st_dt" id="st_dt" [(ngModel)]="st_dt_s">
                        </div>

                        <div class="col-lg-4 col-md-6 form-group">
                            <label for="end_dt">Session End Date</label>
                            <input #end_dt type="date" [maxLength]="3"
                                (change)="onEndYearChange()"
                                class="form-control"
                                name="end_dt" id="end_dt"
                                [(ngModel)]="end_dt_e">
                        </div>

                        <div class="col-lg-4 col-md-6 form-group">
                            <label for="se_id">Session Id</label>
                            <input type="number" class="form-control"
                                name="se_id" readonly min="1"
                                [(ngModel)]="newSe_id"
                                required>
                        </div>

                        <div class="col-lg-4 col-md-6 form-group">
                            <label for="gp">GPA/Percentage</label>
                            <select class="form-control" name="gp" id="gp"
                                required ngModel>
                                <option value="G">GPA</option>
                                <option value="P">Percentage</option>
                            </select>
                        </div>

                        <div class="col-lg-4 col-md-6 form-group">
                            <label for="att">Attendance %age</label>
                            <input type="number" class="form-control" name="att"
                                min="1" ngModel required>
                        </div>

                        <div class="col-lg-4 col-md-6 form-group">
                            <label for="pc">Pass Criteria</label>
                            <input type="number" class="form-control" name="pc"
                                min="1" ngModel required>
                        </div>
                    </div>

                    <div class="card-body">
                        <button class="btn btn-outline-primary"
                            (click)="insertSession()" [disabled]="!f.valid">
                            Add New Session
                        </button>
                    </div>
                    <div *ngIf="this.sessions?.length" class="card-body"
                        style="overflow-x:scroll; margin-top: -2rem;">

                        <table
                            class="table table-bordered table-striped">
                            <thead class="thead-dark">
                                <tr style="text-align: center;">
                                    <th>Sr.#</th>
                                    <th>Se_id</th>
                                    <th>Session</th>
                                    <th>Session Start Date</th>
                                    <th>Session End Date</th>
                                    <th>Grading</th>
                                    <th>Attendance Criteria</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let x of sessions;let i=index"
                                    [ngClass]="{'text-danger':x.attend=='a'}"
                                    style="text-align: center;">
                                    <td><b>{{i+1}}</b></td>
                                    <td><b>
                                            {{x.se_id}}</b>
                                    </td>
                                    <td>
                                        <b>{{x.session_year}}</b>
                                    </td>
                                    <td>
                                        <b>{{x.se_st_dt|date:'MMM d, y'}}</b>
                                    </td>
                                    <td>
                                        <b>{{x.se_e_dt|date:'MMM d, y'}}</b>
                                    </td>
                                    <td><b>{{x.gp_per}}</b></td>
                                    <td><b>{{x.att_per}}</b></td>
                                    <td>
                                        <button
                                            class="form-control btn btn-outline-danger fa fa-trash"
                                            (click)="deleteSession(i, x.se_id)"></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>