<form (ngSubmit)="OnSubmit()" #f="ngForm">
    <div class=" container-fluid" [@SlideInFromLeft]>
        <div class="row card shadow">
            <div class="card-header">
                <div>Adjust Dues against Class or Major Shifting</div>
            </div>
            <div class="row card-body pb-0">


                <div class="col-lg-4 col-md-6 form-group">
                    <label for="challan1">Old Paid Challan Number</label>
                    <input type="text" id="ch1" class="form-control" name="oldCh" min="2000" ngModel required>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="challan2">New Unpaid Challan Number</label>
                    <input type="number" id="ch1" class="form-control" name="newCh" min="2000" ngModel required>
                </div>


            </div>

            <div class="card-header">
                Actions
            </div>
            <div class="row card-body">
                <button class="btn ml-3 btn-outline-primary" (click)="getChallan2() " [disabled]="!f.valid">
                    Search Challans
                </button>
                <button class="btn ml-3 btn-outline-success" (click)="getChallan() "  [disabled]="!f.valid">
                    Adjust Dues
                </button>

            </div>
            <div class="d-flex justify-content-md-around  ">
                <div *ngIf="showBorder" style=" border: 1px solid black; border-radius: 50px;">

                    <div *ngIf="StudentInfoOld?.length>0" class="card-body">
                        <table class="table table-hover">
                            <tbody>
                                <tr *ngFor="let s of StudentInfoOld; let j =index;">
                                    <div style="display: flex; flex-flow:row nowrap; justify-content:space-around">

                                        <div style="display:flex; flex-flow:column nowrap; text-align:left">
                                            <td><b>Challan Number : </b>{{s.ch_no}}</td>
                                            <td><b>Form/Roll Number : </b>{{s.num}}</td>
                                            <td><b>Name : </b>{{s.nm}}</td>
                                        </div>

                                        <div style="display: flex; flex-flow: column nowrap; text-align: left;">
                                            <td><b>Due Date : </b>{{s.dueDate| date:'dd-MM-yyyy'}}</td>
                                            <td><b>Semester : </b>{{s.sem}}</td>
                                            <td><b>Father Name : </b>{{s.f_nm}}</td>
                                        </div>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="StudentAmountOld?.length>0" class="card-body">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <div
                                        style="display: flex; flex-flow:row nowrap; justify-content:space-between;  width: 85%; margin: 0 auto;">

                                        <div style="display:flex; flex-flow:column nowrap; text-align:left">
                                            <th scope="col">Head of Account</th>
                                        </div>

                                        <div>
                                            <th scope="col">Amount</th>
                                        </div>

                                    </div>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of StudentAmountOld; let j =index;">
                                    <div>

                                        <div
                                            style="display: flex; flex-flow:row nowrap; justify-content:space-between; width: 85%; margin: 0 auto;">

                                            <div style="display:flex; flex-flow:column nowrap; text-align:left">
                                                <td>{{s.acc}}</td>
                                            </div>

                                            <div style="display:flex; flex-flow:column nowrap; text-align:left">
                                                <td>{{s.debit}}</td>

                                            </div>

                                        </div>

                                    </div>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>



                <div *ngIf="showBorder" style=" border: 1px solid black; border-radius: 50px;">

                    <div *ngIf="StudentInfoNew?.length>0" class="card-body">
                        <table class="table table-hover">
                            <tbody>
                                <tr *ngFor="let s of StudentInfoNew; let j =index;">
                                    <div style="display: flex; flex-flow:row nowrap; justify-content:space-around">

                                        <div style="display:flex; flex-flow:column nowrap; text-align:left">
                                            <td><b>Challan Number : </b>{{s.ch_no}}</td>
                                            <td><b>Form/Roll Number : </b>{{s.num}}</td>
                                            <td><b>Name : </b>{{s.nm}}</td>
                                        </div>

                                        <div style="display: flex; flex-flow: column nowrap; text-align: left;">
                                            <td><b>Due Date : </b>{{s.dueDate| date:'dd-MM-yyyy'}}</td>
                                            <td><b>Semester : </b>{{s.sem}}</td>
                                            <td><b>Father Name : </b>{{s.f_nm}}</td>
                                        </div>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div *ngIf="StudentAmountNew?.length>0" class="card-body">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <div
                                        style="display: flex; flex-flow:row nowrap; justify-content:space-between;  width: 85%; margin: 0 auto;">

                                        <div style="display:flex; flex-flow:column nowrap; text-align:left">
                                            <th scope="col">Head of Account</th>
                                        </div>

                                        <div>
                                            <th scope="col">Amount</th>
                                        </div>

                                    </div>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let s of StudentAmountNew; let j =index;">
                                    <div>

                                        <div
                                            style="display: flex; flex-flow:row nowrap; justify-content:space-between; width: 85%; margin: 0 auto;">

                                            <div style="display:flex; flex-flow:column nowrap; text-align:left">
                                                <td>{{s.acc}}</td>
                                            </div>

                                            <div style="display:flex; flex-flow:column nowrap; text-align:left">
                                                <td>
                                                    <div class="row">
                                                    <span>{{s.debit}}</span>
                                                    <span><b> /{{StudentFullAmountNew[j].debit}}</b></span>
                                                </div>
                                                </td>

                                            </div>

                                        </div>

                                    </div>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</form>