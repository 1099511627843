import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from "src/app/transitions";
import { NgForm } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { UtilService } from 'src/app/Util.service';

@Component({
  selector: 'app-db-detail',
  templateUrl: './db-detail.component.html',
  styleUrls: ['./db-detail.component.css'],
  animations: [SlideInFromLeft()],
})
export class DbDetailComponent implements OnInit {


  allRoutine: any[];
  storedRoutine: any[];
  cpuConsumption: any[];
  loader: boolean = false;

  routineData_flag : boolean = false;
  consumption_flag: boolean = false;

  DB_type: Array<{ id: number, nm: string }>;
  routine_type: Array<{ id: number, nm: string }>;
  @ViewChild("f") formRef: NgForm;

  constructor(private toastr: ToastrService, private utilservice: UtilService) { }


  ngOnInit(): void {

    this.DB_type = [];
    this.DB_type.push({ id: 1, nm: "LMS" })
    this.DB_type.push({ id: 2, nm: "FEE" });


    this.routine_type = [];
    this.routine_type.push({ id: 1, nm: "PROCEDURE" })
    this.routine_type.push({ id: 2, nm: "FUNCTION" })
    this.routine_type.push({ id: 3, nm: "TABLES" });
  }

  OnSubmit() { }

  onRoutineChange() {

    this.allRoutine = [];
    this.storedRoutine = [];
    this.cpuConsumption = [];

    this.routineData_flag = false;
    this.consumption_flag = false;
  }

  getDetail() {

    this.allRoutine = [];
    this.storedRoutine = [];
    this.cpuConsumption = [];

    this.routineData_flag = false;
    this.consumption_flag = false;

    const { DB_type, routine_type } = this.formRef.value;
    console.log(DB_type, routine_type);

    if (!DB_type) {
      return this.toastr.warning("Select Schema");
    }
    if (!routine_type) {
      return this.toastr.warning("Select Type");
    }

    if(routine_type == "proc_cpu"){

      this.utilservice.getCPU_Consumption().subscribe((res:any) => {
        console.log("CPU",res);
        this.cpuConsumption = res[0];
        this.consumption_flag = true;
      }); 

    }else{

      this.utilservice.getDB_Detail(DB_type, routine_type).subscribe((res: any) => {
        // console.log(res);
        this.allRoutine = res[0];
        this.storedRoutine = res[1];
  
        //Function for sorting the Routines
        this.arraySorting();

        this.routineData_flag = true;
  
        console.log(this.allRoutine, "All Routines");
        console.log(this.storedRoutine, "Stored Routines");
      });

    }

    
  }

  arraySorting() {

    //Sorting All the currently running Routines of the Database
    this.allRoutine.sort((a, b) => {
      const nameA = a.ROUTINE_NAME.toUpperCase();
      const nameB = b.ROUTINE_NAME.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });


    //Sorting All the stored Routines of the Database in the table
    this.storedRoutine.sort((a, b) => {
      const nameA = a.STORED_ROUTINE.toUpperCase();
      const nameB = b.STORED_ROUTINE.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    // Assuming both arrays are already sorted alphabetically

    let index = 0;
    while (index < this.allRoutine.length && index < this.storedRoutine.length) {
      const allRoutineName = this.allRoutine[index].ROUTINE_NAME;
      const storedRoutineName = this.storedRoutine[index].STORED_ROUTINE;

      if (allRoutineName === storedRoutineName) {
        index++;
      } else {
        // Insert 1 or 0 at the corresponding index
        if (this.allRoutine[index].STATUS === 'Created') {
          this.storedRoutine.splice(index, 0, { ROUTINE_NAME: "", STATUS: 'Empty' });
          index++;
        } else if (this.storedRoutine[index].STATUS === 'Missing') {
          this.allRoutine.splice(index, 0, { STORED_ROUTINE: "", STATUS: 'Empty' });
          index++;
        }
      }
    }

    // If any array is longer, handle remaining elements
    // while (index < this.allRoutine.length) {
    //   const allRoutineName = this.allRoutine[index].ROUTINE_NAME;
    //   this.storedRoutine.splice(index, 0, { ROUTINE_NAME: allRoutineName, STATUS: '0' });
    //   this.allRoutine.splice(index, 0, { ROUTINE_NAME: '', STATUS: '1' });
    //   index++;
    // }

    // while (index < this.storedRoutine.length) {
    //   const storedRoutineName = this.storedRoutine[index].ROUTINE_NAME;
    //   this.allRoutine.splice(index, 0, { ROUTINE_NAME: storedRoutineName, STATUS: '0' });
    //   this.storedRoutine.splice(index, 0, { ROUTINE_NAME: '', STATUS: '1' });
    //   index++;
    // }

  }

  // getStatusForAllRoutine(status: string): string {
  //   if (status === 'Created') {
  //     return 'Created';
  //   } else if (status === 'Missing') {
  //     return 'Missing';
  //   } else {
  //     return ''; // Handle other statuses as needed
  //   }
  // }

  // getStatusForStoredRoutine(status: string): string {
  //   if (status === 'Created') {
  //     return 'Created';
  //   } else if (status === 'Missing') {
  //     return 'Missing';
  //   } else {
  //     return ''; // Handle other statuses as needed
  //   }
  // }

  // getStatusColor(status: string): string {
  //   if (status === 'Created') {
  //     return 'green';
  //   } else if (status === 'Missing') {
  //     return 'red';
  //   } else {
  //     return ''; // Handle other statuses as needed
  //   }
  // }

  addRoutine(routine_name: any){

    const {routine_type} = this.formRef.value;
    var mode = 0;

    if (routine_type == "PROCEDURE") {
      mode = 1;
    }else if(routine_type == "FUNCTION"){
      mode = 2;
    }else if (routine_type == "TABLE") {
      mode = 3;
    }
    
    this.utilservice.S_insert_routine(routine_name, mode).subscribe((res: any) => {

      console.log(res);

      if (res[0][0].msg) {
        this.toastr.success(res[0][0].msg);
        this.getDetail();
      }
    });


  }

  clear() {
    this.routineData_flag = false;
    this.consumption_flag = false;
    
    this.allRoutine = [];
    this.storedRoutine = [];
    this.cpuConsumption = [];
    this.formRef.reset();
  }


}
