import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MainComponent } from './main.component';
import { MainRoutingModule } from './main-routing.module';
import { AutoCloseDirective } from './auto.close.directive';
import { AppComponentEventEmitterService } from './event-emmiter.service';
import { ChartsModule } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewStudentProfileComponent } from './view-student-profile/view-student-profile.component';
import { CourseUploadComponent } from './course-upload/course-upload.component';
import { TimeTableComponent } from './time-table/time-table.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { InsertTimeTableComponent } from './insert-time-table/insert-time-table.component';
import { SubjectOfferComponent } from './subject-offer/subject-offer.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { RequestedSubComponent } from './requested-sub/requested-sub.component';
import { ManageTeacherComponent } from './manage-teacher/manage-teacher.component';
import { AddteacherComponent } from './register/addteacher/addteacher.component';
import { ViewRequestedSubjectsComponent } from './view-requested-subjects/view-requested-subjects.component';
import { ViewTimetableComponent } from './view-timetable/view-timetable.component';
import { TimetableEntryComponent } from './timetable-entry/timetable-entry.component';
import { GenerateTimeTableComponent } from './generate-time-table/generate-time-table.component';
import { RequestedSubDetailComponent } from './requested-sub/requested-sub-detail/requested-sub-detail.component';
import { SectionAllocateComponent } from './section-allocate/section-allocate.component';
import { SectionChangeComponent } from './section-change/section-change.component';
import { AttendanceRelaxationComponent } from './attendance-relaxation/attendance-relaxation.component';
import { InsertSubjectComponent } from './insert-subject/insert-subject.component';
// import { EligibilityComponent } from './eligibility/eligibility.component';
import { RecoverUserComponent } from './recover-user/recover-user.component';
import { CourseOutlineComponent } from './course-outline/course-outline.component';
import { OutlineEntryComponent } from './course-outline/outline-entry/outline-entry.component';
import { ManageLockingComponent } from './manage-locking/manage-locking.component';
import { AwardListComponent } from './award-list/award-list.component';
import { ProgressReportComponent } from './progress-report/progress-report.component';
import { AnnoucementComponent } from './annoucement/annoucement.component';
import { TranscriptComponent } from './transcript/transcript.component';
import { EximComponent } from './exim/exim.component';
import { SingleEnrollmentComponent } from './single-enrollment/single-enrollment.component';
import { SubjectFeeComponent } from './subject-fee/subject-fee.component';
import { ReAdmittedComponent } from './re-admitted/re-admitted.component';
import { IdcAllocationComponent } from './idc-allocation/idc-allocation.component';
import { SubmissionCheckComponent } from './submission-check/submission-check.component';
import { TeacherAvailableComponent } from './teacher-available/teacher-available.component';
import { AutoLockTimeComponent } from './auto-lock-time/auto-lock-time.component';
import { RepeaterImproverComponent } from './repeater-improver/repeater-improver.component';
import { CheckTeacherAttendanceComponent } from './check-teacher-attendance/check-teacher-attendance.component';
import { CheckStudentDetailComponent } from './check-student-detail/check-student-detail.component';
import { FeeReportComponent } from './fee-report/fee-report.component';
import { FeeRefundComponent } from './fee-refund/fee-refund.component';
import { RoomAvailableComponent } from './room-available/room-available.component';
import { StdTimetabelListComponent } from './std-timetabel-list/std-timetabel-list.component';
import { StudentDetailComponent } from './student-detail/student-detail.component';
import { CheckAssessmentComponent } from './check-assessment/check-assessment.component';
import { SessionNewComponent } from './session-new/session-new.component';
import { ReEligComponent } from './re-elig/re-elig.component';
import { SubjectOfferReportComponent } from './subject-offer-report/subject-offer-report.component';
import { SchpAwardComponent } from './schp-award/schp-award.component';
import { SubjectReplacementComponent } from './subject-replacement/subject-replacement.component';
import { UpdateMajorNmComponent } from './update-major-nm/update-major-nm.component';
import { SingleStdAwardlistComponent } from './single-std-awardlist/single-std-awardlist.component';
import { GenerateSingleStdAwardlistComponent } from './generate-single-std-awardlist/generate-single-std-awardlist.component';
import { DepartmentReportsComponent } from './department-reports/department-reports.component';
import { AdmChangeRecordComponent } from './adm-change-record/adm-change-record.component';
import { CollectionReportComponent } from './collection-report/collection-report.component';
import { FreezeSemesterComponent } from './freeze-semester/freeze-semester.component';
import { EvaluationResponseDetailComponent } from './evaluation-response-detail/evaluation-response-detail.component';
import { AlterStudentResultComponent } from './alter-student-result/alter-student-result.component';
import { LetterGrdClassComponent } from './letter-grd-class/letter-grd-class.component';
import { TeacherSideEvalReportComponent } from './teacher-side-eval-report/teacher-side-eval-report.component';
import { DuesAdjustmentsComponent } from './dues-adjustments/dues-adjustments.component';
import { AssignRollMeritComponent } from './assign-roll-merit/assign-roll-merit.component';
import { EVFShowChartsComponent } from './ev-f-show-charts/ev-f-show-charts.component';
import { DegreeRecieveInfoTestComponent } from './degree-recieve-info-test/degree-recieve-info-test.component';
import { DegreeReportPrintComponent } from './degree-report-print/degree-report-print.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NewClassComponent } from './new-class/new-class.component';
import { AddSubjectCombinationsComponent } from './add-subject-combinations/add-subject-combinations.component';
import { AddTeacherComponent } from './add-teacher/add-teacher.component';
import { ManualChallanFahComponent } from './manual-challan-fah/manual-challan-fah.component';
import { CheckStudentDataComponent } from './check-student-data/check-student-data.component';
import { OfferedsubjectsreportComponent } from './offeredsubjectsreport/offeredsubjectsreport.component';
import { EnrocomComponent } from './enrocom/enrocom.component';
import { StudentdetailComponent } from './studentdetail/studentdetail.component';
import { GetStudentDetailPopUpComponent } from './get-student-detail-pop-up/get-student-detail-pop-up.component';
import { LevelWiseStudentsComponent } from './level-wise-students/level-wise-students.component';
import { DetailedstudentComponent } from './detailedstudent/detailedstudent.component';
import { ClasssubjectenrollcountComponent } from './classsubjectenrollcount/classsubjectenrollcount.component';
import { EnrepoComponent } from './enrepo/enrepo.component';
import { OverallattendanceComponent } from './overallattendance/overallattendance.component';
import { ResultTransferComponent } from './result-transfer/result-transfer.component';
import { OutstandDuesEntryComponent } from './outstand-dues-entry/outstand-dues-entry.component';
import { FeeRefundReportsComponent } from './fee-refund-reports/fee-refund-reports.component';
import { CalendarComponent } from './calendar/calendar.component';
import { InterMarksViewComponent } from './inter-marks-view/inter-marks-view.component';
import { LockStudentComponent } from './lockstudent/lockstudent.component';
import { StudentAdmissionDetailComponent } from './student-admission-detail/student-admission-detail.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { LoaderModule } from './loader/loader.module';
import { AdmissionFormComponent } from './admission-form/admission-form.component';
import { DbDetailComponent } from './db-detail/db-detail.component';
import { AdmittedSetComponent } from './admitted-set/admitted-set.component';
import { DetailsButtonModule } from '../details-button/details-button.module';
import { FaidnamesearchComponent } from './faidnamesearch/faidnamesearch.component';
import { CollectionReportModule } from './collection-report/collection-report.module';
import { ModalPopUpComponent } from './shared/components/modal-pop-up/modal-pop-up.component';
// import { FreezeReportComponent } from './freeze-report/freeze-report.component';
@NgModule({
  declarations: [
    MainComponent,
    ViewStudentProfileComponent,
    CourseUploadComponent,
    TimeTableComponent,
    AutoCloseDirective,
    ChangePasswordComponent,
    InsertTimeTableComponent,
    SubjectOfferComponent,
    SubjectsComponent,
    RequestedSubComponent,
    ManageTeacherComponent,
    AddteacherComponent,
    ViewRequestedSubjectsComponent,
    ViewTimetableComponent,
    TimetableEntryComponent,
    RequestedSubDetailComponent,
    SectionAllocateComponent,
    SectionChangeComponent,
    DegreeRecieveInfoTestComponent,
    AssignRollMeritComponent,
    AttendanceRelaxationComponent,
    InsertSubjectComponent,
    RecoverUserComponent,
    CourseOutlineComponent,
    OutlineEntryComponent,
    ManageLockingComponent,
    AwardListComponent,
    ProgressReportComponent,
    AnnoucementComponent,
    TranscriptComponent,
    EximComponent,
    SubmissionCheckComponent,
    TeacherAvailableComponent,
    SubjectFeeComponent,
    ReAdmittedComponent,
    IdcAllocationComponent,
    SubmissionCheckComponent,
    AutoLockTimeComponent,
    RepeaterImproverComponent,
    AutoLockTimeComponent,
    CheckTeacherAttendanceComponent,
    CheckStudentDetailComponent,
    FeeReportComponent,
    FeeRefundComponent,
    RoomAvailableComponent,
    StdTimetabelListComponent,
    StudentDetailComponent,
    CheckAssessmentComponent,
    SessionNewComponent,
    ReEligComponent,
    SubjectOfferReportComponent,
    SchpAwardComponent,
    SubjectReplacementComponent,
    UpdateMajorNmComponent,
    SingleStdAwardlistComponent,
    DepartmentReportsComponent,
    AdmChangeRecordComponent,
    GenerateSingleStdAwardlistComponent,
    CollectionReportComponent,
    FreezeSemesterComponent,
    EvaluationResponseDetailComponent,
    AlterStudentResultComponent,
    LetterGrdClassComponent,
    TeacherSideEvalReportComponent,
    DuesAdjustmentsComponent,
    EVFShowChartsComponent,
    DegreeReportPrintComponent,
    DashboardComponent,
    NewClassComponent,
    AddSubjectCombinationsComponent,
    AddTeacherComponent,
    ManualChallanFahComponent,
    CheckStudentDataComponent,
    OfferedsubjectsreportComponent,
    EnrocomComponent,
    StudentdetailComponent,
    GetStudentDetailPopUpComponent,
    LevelWiseStudentsComponent, DetailedstudentComponent,
    ClasssubjectenrollcountComponent, EnrepoComponent,
    OverallattendanceComponent,
    ResultTransferComponent,
    OutstandDuesEntryComponent,
    FeeRefundReportsComponent,
    CalendarComponent,
    InterMarksViewComponent,
    LockStudentComponent,
    StudentAdmissionDetailComponent,
    UserDetailComponent,
    AdmissionFormComponent,
    DbDetailComponent,
    AdmittedSetComponent,
    FaidnamesearchComponent,
    ModalPopUpComponent
    // FreezeReportComponent
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoaderModule,
    DetailsButtonModule,
    CollectionReportModule
  ],
  exports:[DetailsButtonModule, DetailedstudentComponent, ModalPopUpComponent, FaidnamesearchComponent],
  providers: [AppComponentEventEmitterService],
  // schemas: [
  // CUSTOM_ELEMENTS_SCHEMA,
  // NO_ERRORS_SCHEMA
  //]
})
export class MainModule { }
