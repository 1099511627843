<form (ngSubmit)="onSubmit()" #f="ngForm">
  <div class=" container-fluid" [@SlideInFromLeft]>
    <div class="row card shadow">
      <div class="card-header">Demand Collection Report</div>
      <div class="row card-body pb-0 px-4">
        <div class="col-lg-4 col-md-6 form-group">
          <label for="C_Code">Class</label>
          <select #c_code class="form-control " name="c_code" id="C_Code"
            ngModel (change)="ClearClassBase()">
            <option *ngFor="let c of classDT" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="year_in">Year</label>
          <select class="form-control" id="year_in" aria-label="Year" ngModel
            required name="year_in" (change)="getRolno()"
            (change)="ClearYearBase()">
            <option *ngFor="let s of sessions" value="{{s}}">{{s}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="inst_no">Installment</label>
          <!-- <input #inst_no type="number" class="form-control" name="inst_no" min="1" ngModel> -->
          <select #installment class="form-control " name="inst_no"
            ngModel="selectedInstNo">
            <option *ngFor="let entry of instNo" [value]="entry">{{ entry
              }}</option>
          </select>
        </div>

        <div *ngIf="installment.value>1" class="col-lg-4 col-md-6 form-group">
          <label for="f_rolno">First Roll Number</label>
          <select #f_rolno class="form-control" name='f_rolno' id="f_rolno"
            ngModel required>
            <option *ngFor="let r of rolno" value="{{r.nm}}">{{r.nm}}</option>
          </select>
        </div>

        <div *ngIf="installment.value==1" class="col-lg-4 col-md-6 form-group">
          <label for="f_rolno">First Form Number</label>
          <input type="text" class="form-control" name="f_rolno" min="1" ngModel
            required>
        </div>

        <div *ngIf="installment.value>1" class="col-lg-4 col-md-6 form-group">
          <label for="l_rolno">Second Roll Number</label>
          <!-- <input type="text" class="form-control" name="l_rolno" min="1" ngModel required> -->
          <select #l_rolno class="form-control" name='l_rolno' id="l_rolno"
            ngModel required>
            <option *ngFor="let r of rolno" value="{{r.nm}}">{{r.nm}}</option>
          </select>
        </div>

        <div *ngIf="installment.value==1" class="col-lg-4 col-md-6 form-group">
          <label for="l_rolno">Second Form Number</label>
          <input type="text" class="form-control" name="l_rolno" min="1" ngModel
            required>
        </div>
      </div>

      <div class="row card-body">
        <button class="btn btn-outline-primary" (click)="getData()" [disabled]="!f.valid">
          Get Report
        </button>
        <button *ngIf="this.allData.length>0"
          class="btn ml-3 btn-outline-primary" (click)="onPdfDownload(c_code)">
          Download
        </button>
        <button class="btn ml-3 btn-warning" (click)="onClear()">
          Clear
        </button>
      </div>
      <div class="px-4" *ngIf="allData.length > 0">
        <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">
          <input class="form-control col-lg-4" type="text" name="Search"
            placeholder="Search" id="myInput" (keydown)="Filter()">
        </div>
        <table
          class="table table-bordered table-striped mt-4"
          id="table">
          <thead class="thead-dark">
            <th>
              Sr #
            </th>
            <th>
              Roll Number
            </th>
            <th>
              Name
            </th>
            <th>
              Demand
            </th>
            <th>
              Collection
            </th>
            <th>
              Remarks
            </th>
          </thead>
          <tbody>
            <tr *ngFor="let i of this.allData; let j=index" id="values">
              <td>
                {{j+1}}
              </td>
              <td>
                {{i.rolno}}
              </td>
              <td>
                {{i.name}}
              </td>
              <td>
                {{i.demand}}
              </td>
              <td>
                {{i.collection}}
              </td>
              <td>
                {{i.remarks}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>