<form (ngSubmit)="OnSubmit()" #f="ngForm">
    <div class=" container-fluid" [@SlideInFromLeft]>
        <div class="row card shadow">
            <div class="card-header">
                <div>Student Wise Collection Report</div>
            </div><div class="row card-body pb-0 px-4">
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="c_code">Class</label>
                    <select #c_code class="form-control " name="c_code" id="c_code" (change)="onClassChange()" ngModel (change)="ClearClassBase()">
                        <option *ngFor="let c of classesArr" value="{{c.id}}">{{c.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="year_in">Year</label>
                    <select class="form-control" id="year_in" aria-label="Year" ngModel
                      required name="year_in"
                      (change)="getRolno()" (change)="ClearYearBase()">
                      <option *ngFor="let s of sessions" value="{{s}}">{{s}}</option>
                    </select>
                  </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="f_rn">Starting Roll Number</label>
                    <select #f_rn class="form-control" name='f_rn' id="f_rn" ngModel>
                        <option *ngFor="let r of rolno" value="{{r.id}}">{{r.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="l_rn">Ending Roll Number</label>
                    <select #l_rn class="form-control" name='l_rn' id="l_rn" ngModel>
                        <option *ngFor="let r of rolno" value="{{r.id}}">{{r.nm}}</option>
                    </select>
                </div>
                <!-- <span class="login-box ml-3" style="margin-top:-10px">
                    <form>
                        <a *ngIf="manualRn==0" style="font-size:12px;font-weight:bold;" (click)="ChangeManualRn(1)">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            MANUAL ROLL#
                        </a>
                        <a *ngIf="manualRn==1" style="font-size:12px;font-weight:bold;" (click)="ChangeManualRn(0)">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            ROLL# LIST
                        </a>
                    </form>
                </span> -->

                <!-- <div class="col-lg-4 col-md-6 form-group">
                    <label for="inst_no">Installment</label>
                    <select #inst_no class="form-control" name="inst_no" id="inst_no" ngModel >
                        <option value="1">1st Installment</option>
                        <option value="2">2nd Installment</option>
                        <option value="3">3rd Installment</option>
                        <option value="4">4th Installment</option>
                        <option value="5">5th Installment</option>
                        <option value="6">6th Installment</option>
                        <option value="7">7th Installment</option>
                        <option value="8">8th Installment</option>
                        <option value="12">All Installments</option>
                    </select>
                </div> -->
            </div>

            <div class="row card-body">
                <button class="btn btn-outline-danger ml-1" (click)='onReport(c_code)' [disabled]="!f.valid">
                    <i class="fa fa-file-pdf-o" for=""></i>
                    Download Report Pdf
                </button>
                <button class="btn ml-3 btn-warning" (click)="onClear()">
                    Clear
                </button>
                
            </div>
            <ng-container *ngIf="loader then ifBlock;"></ng-container>
            <ng-template #ifBlock>
                <div style="display:flex;justify-content:center; align-items: center;"><span
                        class="loader"></span></div>
            </ng-template>
        </div>
    </div>
</form>