<div class="card" [@SlideInFromLeft]>
  <div class="card-header">Alter_Student_Result

    <!-- <div class="div1">
      <button class="btn1 fa" (click)="myFunction()" [ngClass]="flag ? 'fa-exclamation-circle' : 'fa-close'"
      [ngStyle]="{'color': flag? 'green' : 'blue'}"
      ></button> 

      <div class="popuptext" style="font-size: 10px; display: none;" id="myPopup">
       <h6>Examination Screen purpose</h6>
       <p style="margin-right: 4px; margin-left: 4px;">View student grades on this screen. Clicking the button 'Mark' sets the selected student's grade and grade point  to 0</p>
       <p style="margin-right: 4px; margin-left: 4px;">اس اسکرین پر طلباء کے درجات دیکھیں۔ بٹن پر کلک کرنا منتخب طالب علم کے گریڈ اور گریڈ پوائنٹ کو 0 پر سیٹ کرتا ہے۔</p>
      </div>

     </div> -->

  </div>
  <form id="form1" #f="ngForm">
    <div class="row card-body px-3">
      <div class="form-group  col-lg-4">
        <label for="sub">Classes</label>
        <select class="form-control" id="sub" aria-label="ClassCode" ngModel name="c_code" ngMOdel required>
          <option *ngFor="let i of classes " value="{{i.id}}">{{ i.nm }}</option>
        </select>
      </div>

      <div class="form-group col-lg-4">
        <label for="floatingInput">Session Start Year</label>
        <input type="text" id="floatingInput" ngModel name="SE_ST_YEAR" class="form-control" ngModel required>
      </div>

      <div class="form-group  col-lg-4">
        <label for="sub">Term No</label>
        <select class="form-control" id="sub" aria-label="T_no" ngModel name="t_no" ngModel required>
          <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]" value="{{i}}">{{ i }}</option>
        </select>
      </div>

      <div class="form-group col-lg-4">
        <label for="floatingInput">Roll No - <b>(Do not enter pre 0)</b></label>
        <input type="text" ngModel name="rn" class="form-control">
      </div>

      <div class="form-group col-lg-4">
        <label for="floatingInput">Name</label>
        <input type="text" ngModel value={{name}} name="NM" readonly class="form-control">
      </div>
      <div class="ml-3" style="margin-top:2.3%"><button (click)="func()"
          class="btn btn-outline-primary col-md-12" [disabled]="!f.valid">Display Result</button></div>
    </div>
    <div class="card-header">Subject Information</div>
    <div class="row card-body">
      <ng-container *ngIf="valid; then IfBlock"></ng-container>
      <ng-template #IfBlock>
        <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1" >  
          <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
      </div>
        <table id="table" class="table table-bordered table-striped" id="table">
          <thead class="table-dark">
            <tr>
              <th>Subject Code</th>
              <th>Subject Title</th>
              <th>Year</th>
              <th>Grade</th>
              <th>Grade Point</th>
              <th style="width:10vw">Result Late</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let i of result" id="values">
              <td>{{i?.SUB_CODE}}</td>
              <td>{{i?.sub_nm}}</td>
              <td> {{i?.session}}</td>
              <td>{{i?.GRD}}</td>
              <td>{{i?.GP_PER}}</td>
              <td><button class="btn btn-success col-md-12" style="font-size:12px"
                  (click)="Clicked(i.SUB_CODE,i.sub_nm,i.SE_ID)">Mark</button> </td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </div>
  </form>
</div>