<div [@SlideInFromLeft]>
    <div class="card">
      <div class="row">
        <div class="col-sm-12">
          <div class="card-header d-flex justify-content-between">
            <div>
              Announcements
            </div>
            <div class="badge badge-pill badge-primary p-2">
            </div>
          </div>
  
          <div>
            <div class="card-body">
  
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Assessment</th>
                    <th scope="col">Detail</th>
                    <th scope="col">Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let s of announcements">
                    <td>{{s.ANN_TITLE}}</td>
                    <td>{{s.ANN_DESC}}</td>
                    <td>{{s.ANN_DATE | date : " d, MMM , y, h:mm a"}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  