<div class="container-fluid" [@SlideInFromLeft]>
  <div class="row">
    <div class="col-sm-12 p-2">
      <div class="card shadow-sm">
        <form (ngSubmit)="OnSubmit()" (ngSubmit)="OnSubmitFee()" #f="ngForm">
          <div class="card-header">
            Add Subject Combinations
          </div>
          <div class="row card-body" style="margin-top: -10px">

            <div class="col-lg-6 col-md-6 form-group">
              <label for="Class" class="text-left">Class
              </label>
              <select id="c_code" #course (change)="OnClassChange(course)"
                name="c_code" class="form-control" ngModel>
                <option *ngFor="let c of classes"
                  value="{{c.id}}">{{c.nm}}</option>
              </select>

            </div>
            <div class="col-lg-6 col-md-6 form-group">
              <label for="Major" class="text-left">Major</label>
              <select id="maj_id" #major (change)="OnMajorChange(major)"
                name="maj_id" class="form-control" ngModel>
                <option *ngFor="let m of majors"
                  value="{{m.id}}">{{m.nm}}</option>
              </select>
            </div>

            <div class="col-lg-6 col-md-6 form-group">
              <label for="Set_No">Set No</label>
              <input class="form-control" name="Set_No" id="sub_code" required
                ngModel>
            </div>

            <div class="col-lg-6 col-md-6 form-group">
              <label for="sub_1">Subject-1</label>
              <input class="form-control" name="sub_1" id="sub_1" required
                ngModel>
            </div>

            <div class="col-lg-6 col-md-6 form-group">
              <label for="sub_2">Subject-2</label>
              <input class="form-control" name="sub_2" id="sub_2" required
                ngModel>
            </div>

            <div class="col-lg-6 col-md-6 form-group">
              <label for="sub_3">Subject-3</label>
              <input name="sub_3" id="sub_3" class="form-control" required
                ngModel>
            </div>

            <div class="col-lg-6 col-md-6 form-group">
              <label for="sub_4" class="text-left">Subject-4</label>
              <input class="form-control" name="sub_4" id="sub_4" value="null"
                placeholder="Optional" ngModel>
            </div>
            <div class="col-lg-6 col-md-6 form-group">
              <label for="Status">Status</label>
              <select #status class="form-control " name="status" id="Status"
                required ngModel>
                <option *ngFor="let st of ['Inactive','Active']; let i=index"
                  value="{{i}}">{{st}} </option>
              </select>
            </div>

            <div class style="margin-top: 20px;">
              <button type="submit" [disabled]="!f.valid"
                class="btn btn-primary"
                style="margin-left: 20px; ">Submit</button>
              <button type="button" class="btn btn-primary"
                style="margin-left: 20px;" (click)="Clear(f)">Clear</button>
            </div>
          </div>
          <br>
        </form>
        <div *ngIf="sub_comb?.length>0" class=" card-header mt-5">
          Subject combinations
        </div>
        <div *ngIf="sub_comb?.length>0" class="card-body">
          <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
            <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
        </div>
          <table class="table table-bordered table-striped" id="table">
            <thead class="thead-dark">
              <tr>
                <th scope="col">Set No</th>
                <th scope="col">Combinations</th>
                <!-- <th scope="col">Name</th>
              <th scope="col">Cr.Hrs.</th>
              <th scope="col">Grading</th>
              <th scope="col">Type</th>
              <th scope="col">Subject Nature</th>
              <th scope="col">Absolute/Relative</th>
              <th scope="col">Set No.</th> -->
                <!-- <th *ngIf="(this.isAdmin||this.isExam || this.canManageTT)" scope="col">Actions</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sub of sub_comb; let i =index;" id="values">
                <!-- <td>{{i+1}}</td> -->

                <td>{{sub.SET_NO}}</td>
                <td>{{sub.SET_NM}}</td>
                <!-- <div class="form-group"> -->

                <td *ngIf="(isAdmin)">
                  <!-- <button *ngIf="(this.isAdmin||this.isExam)" class="btn btn-outline-success" (click)="update(i,sub)"> -->
                  <!-- <span>
                    <i class="fa fa-pencil" aria-hidden="true" title="Edit"></i>
                  </span>
                </button>-- -->
                  <button class="btn btn-outline-danger"
                    (click)="deleteSubComb(course.value,major.value,sub.SET_NO)">
                    <span>
                      <i class="far fa-trash-alt" aria-hidden="true"
                        title="Delete"></i>
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
