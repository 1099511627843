import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../../../axios';
@Injectable({
    providedIn: 'root'
})
export class TimetableService {

    constructor(private http: HttpClient) {
    }

    validateTime(params) {
        return this.http.post(`${baseUrl}/timetable/validateTime`, params);
    }

    validateUpdateEntry(tm_id: string, fm_id: string, day: string, s_time: string, dur: string, blk_id: string, rm_id: string, status: number) {
        return this.http.post(`${baseUrl}/timetable/validateUpdateEntry`, {
            tm_id: tm_id,
            fm_id: fm_id,
            day: day,
            s_time: s_time,
            dur: dur,
            blk_id: blk_id,
            rm_id: rm_id,
            status: status
        });
    }
 
    insertTimetable(params)
    {
        return this.http.post(`${baseUrl}/timetable/insert`, params);
    }

    getTimetable(params) {
        return this.http.post(`${baseUrl}/timetable/getTimetable`, params);
    }
    getTimetableView(t_no: string, section:string, fm_id:any) {
        return this.http.get(`${baseUrl}/timetable/getTmForView/${t_no || 0}/${section || 'null'}/${fm_id}`);
    }

    updateEntry(tmId: string, fmId: string, day: string, s_time: string, duration: string, blkId: string,rmId: string) {
        return this.http.post(`${baseUrl}/timetable/updateEntry`,{
            tm_id: tmId,
            fm_id: fmId,
            day: day,
            s_time: s_time,
            dur: duration,
            blk_id: blkId,
            rm_id: rmId
        });
    }

    deleteEntry(tm_id:string){
        return this.http.get(`${baseUrl}/timetable/deleteEntry/${tm_id}`);
    }

    validateTeacher(fm_id: String, day: String, s_time: String, e_time: string, status: string){
        return this.http.get(`${baseUrl}/api/teacher/validateTeacher/${fm_id}/${day}/${s_time}/${e_time}/${status || 0}`);
    }

    getSectionScheme(params){
        return this.http.post(`${baseUrl}/timetable/getSectionScheme`,params)
    }

    getTimeDuration(){
        return this.http.get(`${baseUrl}/timetable/getTimeDuration`);
    }
}

