import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class EligibiltyService {

    constructor(private http: HttpClient) {
    }

    getsections(d_id:number,c_code:number, t_no:number,se_id:number, sub_code:string ){           
        return this.http.get(`${baseUrl}/api/vieweligibility/getSections/${d_id}/${c_code}/${t_no}/${se_id}/${sub_code}`);
    }
    getEligibilityReport(param) {
        return this.http.post(`${baseUrl}/api/attandence/calculateEligibilty/`,param);
    }

    
    getInterEligibilityReport(param) {        
        return this.http.post(`${baseUrl}/api/attandence/calculateInterEligibilty/`,param);
    }

    getErollmentStatus(d_id:number,c_code:number,se_id:number, t_no:number){           
        return this.http.get(`${baseUrl}/api/vieweligibility/getErollmentStatus/${d_id}/${c_code}/${se_id}/${t_no}`);
    }
}