<form (ngSubmit)="OnSubmit()" #f="ngForm">
    <div class=" container-fluid" [@SlideInFromLeft]>
      <div class="row card shadow">
        <div class="card-header">
          <div>Repeaters/ Subject Improvers</div>
        </div>
        <div class="row card-body pb-0">
  
  
  
          <div class="col-lg-4 col-md-6 form-group">
            <label for="c_code">Class</label>
            <select #c_code (change)="OnClassChange(c_code)" class="form-control " name="c_code" id="c_code" ngModel required>
              <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
            </select>
          </div>
  
          <div class="col-lg-4 col-md-6 form-group">
            <label for="year_in">Year</label>
            <input type="number" class="form-control" name="year_in" min="2000" ngModel required (change)="getRolno()">
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="rn">Roll Number</label>
            <!-- <input type="number" class="form-control" name="rn" min="1" ngModel required> -->
            <select #rn class="form-control" name='rn' id="rn" ngModel required>
              <option *ngFor="let r of rolno" value="{{r.id}}">{{r.nm}}</option>
            </select>
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="Session">New Session</label>
            <select class="form-control " #session  name="se_id" id="Session" ngModel>
              <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
            </select>
          </div>          
  
          <div class="col-lg-4 col-md-6 form-group">
            <label for="Installment">Term No</label>
            <select #installment class="form-control " #session   name="inst"
               ngModel>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </select>
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="Installment">Subject/ Full Repeater</label>
            <select #installment class="form-control " #session   name="inst"
               ngModel>
              <option value="1">Subject Repeater</option>
              <option value="2">Complete Repeater</option>
            </select>
          </div>

        

       
        <!-- <div class="row card-body">
          <button (click)="Addinstallment(pubDate.value,dueDate.value)" class="btn  btn-outline-primary">
                Add
            </button>
        </div> -->
    </div>


     
    </div>
    </div>
  </form>