import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SelectList } from '../../shared/model/SelectList.model';
import { ClassService } from '../../shared/services/Class.service';
import { reportsFeeService} from '../../shared/services/reportsFee.service';
import { jsPDF } from 'jspdf';
import { DatePipe } from "@angular/common";
import autoTable from "jspdf-autotable";
import "jspdf-autotable";
import * as moment from 'moment';
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';

@Component({
  selector: 'app-self-support-form-report',
  templateUrl: './self-support-form-report.component.html',
  styleUrls: ['./self-support-form-report.component.css'],
  animations: [SlideInFromLeft()]
})
export class SelfSupportFormReportComponent implements OnInit {

  classDT: Array<SelectList>;
  isAdmin: boolean = false;
  sessions: any;
  allData: Array<{ch_no,rolno,inst_no,paid_dt,name,f_name,descr,amount}>;
  @ViewChild('f') formRef: NgForm;
    constructor(
      private authenticationService: AuthenticationService,
      private toastr: ToastrService,  
      private datePipe: DatePipe,
      private reportsFee: reportsFeeService,
      private feeSharedService: FeeSharedDataService
    ) {
      this.classDT= new Array<SelectList>();
      this.allData = new Array<{ch_no,rolno,inst_no,paid_dt,name,f_name,descr,amount}>();
      this.isAdmin = this.authenticationService.isAdmin();
     }

     ngOnInit(): void {
      this.updateClasses();
      this.updateSessions();
    }

    updateClasses() {
      this.classDT = [];
      this.feeSharedService.getFeeClasses()
        .then(classes => {
          this.classDT = classes;
        })
        .catch(error => {
          console.error("Error getting fee classes", error);
        });
    }

    updateSessions() {
      this.sessions = []
      this.feeSharedService.getFeeSessions()
        .then(sessions => {
          this.sessions = sessions;
        })
        .catch(error => {
          console.error("Error getting fee session", error);
        });
    }

    getData(){
      this.allData=[];
      this.reportsFee.onSelfSupportFormReport(this.formRef.value).subscribe((res: {ch_no:any,rolno:any,inst_no:number,paid_dt:string,nm:string,f_nm:string,descr:any,amount:number}[])=>{
        res?.forEach(element => {
          this.allData.push({ch_no:element.ch_no,rolno:element.rolno,inst_no:element.inst_no,paid_dt:moment(element.paid_dt).format('DD-MMM-YYYY'),name:element.nm,f_name:element.f_nm,descr:element.descr,amount:element.amount});
         });
        });
    }

    OnSubmit(){
    }

    onPdfDownload(c: HTMLSelectElement){
      if (this.allData?.length == 0) {
        this.toastr.warning("No Record Found");
        return;
      }
      else {
       const body = [];
       const header = [];
       
       var exportDate = this.datePipe.transform(new Date(), 'MMM d,y');
       var image = new Image();
       var page;
       var sessionYear=this.formRef.value.year;
       var inst = this.formRef.value.inst_no;
       var groupClass=this.classDT[c.selectedIndex].nm;
       image.src = '../../../assets/images/logo3.png';

       header.push([
       {content:'Challan Number', styles: { fontStyle: "bold", halign: 'center' }},
       {content:'Paid Date',styles: { fontStyle: "bold", halign: 'center' }}, 
       {content:'Name',styles: { fontStyle: "bold", halign: 'center' }},
       {content:'Father Name',styles: { fontStyle: "bold", halign: 'center' }},
       {content:'Description',styles: { fontStyle: "bold", halign: 'center' }},
       {content:'Amount',styles: { fontStyle: "bold", halign: 'center' }}]);
        
       const doc = new jsPDF('p', 'mm', 'A4');

       let k;
       for (let i = 0; i < this.allData.length; i++) {
        k = i+1;
        body.push([
          {content:this.allData[i].ch_no,styles: { fontStyle: "bold", halign: 'center' }},
          {content:this.allData[i].paid_dt,styles: { halign: 'center' }},
          {content:this.allData[i].name,styles: { fontStyle: "bold",}},
          {content:this.allData[i].f_name,styles: { fontStyle: "bold", }},
          {content:this.allData[i].descr,styles: { fontSize: 6}},
          {content:this.allData[i].amount,styles: { fontStyle: "bold", halign: 'center' }}
        ]);
      }
    
       autoTable(doc, {
        theme: 'grid',
        startY: 55,
        margin: {top:55, bottom: 10, left: 7, right: 7 },
        styles:{fontSize:11},
        headStyles:{
         fillColor: [255, 255, 255],
         textColor: [0, 0, 0],
         lineColor: [0, 0, 0],
         lineWidth: 0.1,
         fontSize: 8,
         fontStyle: 'bold'
       },
       bodyStyles: {
         lineWidth: 0.1,
         lineColor:[0, 0, 0],
         fontSize: 7.5
       },
       columnStyles: {
         0: {cellWidth: 19},
         1: {cellWidth: 24},
         2: {cellWidth: 35},
         3: {cellWidth: 35},
       },
       head: header,
       body: body, 
         didDrawPage: function () {
          doc.addImage(image, 175, 7, 25, 30);
          doc.setFontSize(10);
          doc.text(`${exportDate}`, 176, 42);
          doc.setFontSize(20);
          doc.setFont('Helvetica', 'bold');
          doc.text("GC UNIVERSITY, LAHORE", 105, 20, { align: 'center' });
          doc.setFontSize(14);
          doc.setFont('Helvetica', 'bold');
          doc.text("Self-Support Form Report", 105, 29, { align: 'center' });
          doc.text("_________________________", 105, 31, { align: 'center' });
          doc.setFontSize(12);
          doc.text("CLASS: "+groupClass, 105, 38, { align: 'center' });
          doc.setFontSize(12);
          doc.text("SESSION: "+sessionYear, 105, 45, { align: 'center' });
          page = '';
          page = doc.getNumberOfPages();
         }
       });
       var l = (body.length + 65) % 26;
       doc.setFontSize(8);
       for (var i = 1; i <= page; i++) {
         doc.setPage(i);
         doc.text('Page ' + i + " of " + page, 100, 295);
         doc.text("Directorate of Information & Technology",10,295)
         doc.text("Note:- Errors and Omissions Excepted", 150, 295);
         var p = body.length;
       }
       window.open(URL.createObjectURL(doc.output('blob')), 'Student_General_Info(' + sessionYear + ')');
    }
  }

  onClear(){
    this.formRef.reset();
    this.allData=[];
  }

  ClearClassBase() {
    this.formRef?.controls['year'].reset();
}

}