import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class SubjectOfferService {

    constructor(private http: HttpClient) {
    }
    getOfferedSubWithDetailsW(c_code: string, maj_id: string, se_id: string, t_no: string) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/offerSubjectwithdetailsW/${c_code}/${maj_id}/${se_id}/${t_no}`);
    }
    offerSubject(c_code, maj_id, se_id, t_no) {
        return this.http.get(`${baseUrl}/util/offerSubject/${c_code}/${maj_id}/${se_id}/${t_no}`);
    }

    getOfferedSubByClass(c_code: string, se_id: string, t_no: string) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/getOfferedSubByWholeClass/${c_code}/${se_id}/${t_no}`);
    }
    ExamgetSubjects(c_code: string, se_id: string, t_no: string) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/EXAM_getSubjects/${c_code}/${se_id}/${t_no}`);
    }

    getOfferedSub(c_code: string, maj_id: string, se_id, t_no: string) {
        return this.http.get(`${baseUrl}/util/getOfferedSub/${c_code}/${maj_id}/${se_id}/${t_no}`);
    }

    getOfferedSubByGroup(grp: string, c_code: string, se_id, t_no: string) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/getOfferedSubByGroup/${grp}/${c_code}/${se_id}/${t_no}`);
    }


    getSubjectOfferForSingleSubject(control: string, c_code: string, maj_id: number, year: number, t_no: number) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/getSubjectOfferForSingleSubject/${control}/${c_code}/${maj_id}/${year}/${t_no}`);
    }

    getOfferedSubByDid(grp: string, c_code: string, se_id, t_no: string, status: number) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/getSubjectsByDepartment/${grp}/${c_code}/${se_id}/${t_no}/${status}`);
    }

    offerSub(params) {
        return this.http.post(`${baseUrl}/api/subjectsOffer/offerSubject`, params);
    }

    getOfferedSubWithDetails(c_code: string, maj_id: string, se_id: string, t_no: string) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/offerSubjectwithdetails/${c_code}/${maj_id}/${se_id}/${t_no}`);
    }

    getSubjectComb(g_code: number, c_code: string, t_no: string) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/getSubjectComb/${g_code}/${c_code}/${t_no}`);
    }

    getSubjectCombBySession(g_code: number, c_code: string, t_no: string, se_id: number) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/getSubjectCombBySession/${g_code}/${c_code}/${t_no}/${se_id}`);
    }

    deleteSubOffer(params) {
        return this.http.post(`${baseUrl}/api/subjectsOffer/delSubOffer`, params);
    }

    updateSubOffer(params) {
        return this.http.post(`${baseUrl}/api/subjectsOffer/updateSubOffer`, params);
    }

    getSubIDC(c_code, maj_id, se_id, t_no) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/getOfferedSubForIDC/${c_code}/${maj_id}/${se_id}/${t_no}`);
    }


    getSubjectsByDept(param) {
        return this.http.post(`${baseUrl}/api/subjectsOffer/getSubListByDept`, param);
    }

    subjectOfferMajWise(param) {
        return this.http.post(`${baseUrl}/api/subjectsOffer/subjectOfferMajWise`, param);
    }
    getSubject(c_code: number, se_id: number, t_no: number) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/getOfferedSubByWholeClass/${c_code}/${se_id}/${t_no}`);
    }

    getRTeachers(params) {
        return this.http.post(`${baseUrl}/api/result/getRTeachers`, params);
    }

    // For Subject Replacement Screen

    getExRsltSubjects(param) {
        return this.http.post(`${baseUrl}/api/subjectsOffer/EXAM_getExRsltSubjects`, param);
    }
    // get subjects for forms
    getCurrentWorkingSubjects(grp: number, c_code: number, se_id: number, t_no: number, section: string) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/getCurrentWorkingSubjects/${grp}/${c_code}/${se_id}/${t_no}/${section}`);
    }
    // get subjects for forms
    getSubjects_Class_Sess_Tno(c_code: number, se_id: number, t_no: number, maj_id: number, section: string) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/getSubjects_Class_Sess_Tno/${c_code}/${se_id}/${t_no}/${maj_id}`);
    }
    //   +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    getSubjects_Class_Sess_Tno_count(frm_id: number, c_code: number, se_id: number, t_no: number, maj_id: number, section: string) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/getSubjects_Class_Sess_Tno_count/${frm_id}/${c_code}/${se_id}/${t_no}/${section}/${maj_id}`);
    }
    //   +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    getSubjectsOfTchrFrReports(fm_id: number, t_no: number, section: string, d_id: number) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/getSubjectsOfTchrFrReports/${fm_id}/${t_no}/${section}/${d_id}`);
    }

    //Update Subject Title in Sub
    UpdateSubName(param) {
        return this.http.post(`${baseUrl}/api/subjectsOffer/EXAM_UpdateSubName`, param);
    }
    EV_F_getSubjectsOfDpt(d_id: string, c_code: string, se_id: string, t_no: string) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/EV_F_getSubjectsOfDpt/${d_id}/${c_code}/${se_id}/${t_no}`);
    }
    // by sUFFYAN
    getSubjectsByS(c_code: number, se_id: number, t_no: number, maj_id: number) {
        return this.http.get(`${baseUrl}/api/subjectsOffer/getSubjectsByS/${c_code}/${se_id}/${t_no}/${maj_id}`);
    }



}