<div [@SlideInFromLeft] class="container-fluid">
  <div class="row card shadow " style="min-height: 40vh;">
    <div class="card-header"  style="padding-top: 10px;">
    <div class=" col-lg-6 col-md-6 form-group">Student Detail </div>
    <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
      <app-details-button [details]="details"></app-details-button>
  </div>
    </div>

    <form #fn="ngForm">
      <div class="card-body" style="margin-top: -10px">

        <div class="row">

          <div class="col-lg-4 col-md-6 form-group">
            <label for="C_Code">Class</label>
            <select #c_code (change)="OnClassChange()" class="form-control " name="c_code" id="c_code" ngModel required>
              <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
            </select>
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="se_id">Session</label>
            <select #se_id class="form-control " name="se_id" id="se_id" (click)="loadRollno()" required ngModel>
              <!-- <option value="" disabled>Select Session</option> -->
              <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
            </select>
          </div>

          <div class="col-lg-4 col-md-4 form-group">
            <label for="in" class="text-left">Complete Roll Number</label>
            <select #rolno class="form-control" name="rolno" id="rolno" value="rolno" ngModel required>
              <option *ngFor="let s of rolnoArr" value="{{s.nm}}">{{s.nm}}</option>
            </select>
          </div>

          <div class="row mt-2 ml-3">
            <button type="button" [disabled]="!this.getDetBtn" class="btn btn-sm btn-primary mt-1 " (click)="getUser()"
              style="height: calc(1.5em + .75rem + 2px);">
              Get Detail
            </button>

          </div>
          <div class="row mt-2 ml-4">
            <button [disabled]="!hideInput" type="button" class="btn btn-sm btn-primary mt-1 "
              (click)='downloadTimetable()' style="height: calc(1.5em + .75rem + 2px);">
              Download Timetable
            </button>
          </div>
        </div>

        <div *ngIf="this.studentInfo.length" class="card-header">
          Student Personal Detail
        </div>

        <div *ngFor="let s of studentInfo;" class="mt-2 row form-group">

          <div class="col-lg-4 col-md-4">
            <h6 class="card-title">Name</h6>
            <input [readonly]="!canStudentDetail && !this.isAdmin" #name class="form-control" name="name" id="name"
              value="{{s.nm.toUpperCase()}}" required>
          </div>
          <div class="col-lg-4 col-md-4">
            <h6 class="card-title">Father Name</h6>
            <input [readonly]="!canStudentDetail && !this.isAdmin" #fName class="form-control" name="fName" id="fName"
              value="{{s.f_nm.toUpperCase()}}" required>
          </div>
          <div class="col-lg-4 col-md-4">
            <h6 class="card-title">CNIC</h6>
            <input [readonly]="!canStudentDetail && !this.isAdmin" #cnic class="form-control" name="cnic" id="cnic" value="{{s.nic}}" required>
          </div>
          <div class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Date of Birth</h6>
            <input [readonly]="!canStudentDetail && !this.isAdmin" #dob class="form-control" name="dob" id="dob" value="{{s.DOB}}" required>
          </div>

        <!-- <div class="col-lg-4 col-md-4 mt-2">
            <h6 class="card-title">Registration No</h6>
            <input readonly #regno class="form-control" name="regno"
              id="regno" value="{{s.reg_no}}">
          </div> -->
          <!-- <div class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Registration No</h6>
            <input [readonly]="this.onDepartmentAccess||this.isExamCell " #reg_no type="reg_no" class="form-control"
              name="reg_no" id="reg_no" value="{{s.reg_no}}" required>
          </div> -->

          <div class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Email</h6>
            <input [readonly]="!canStudentDetail && !this.isAdmin" #email type="email" class="form-control" name="email" id="email"
              value="{{s.email}}" required>
          </div>
          <div [hidden]="this.onDepartmentAccess " class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Student Contact No.</h6>
            <input #ph1 type="number" class="form-control" [readonly]="!canEnrollRepeater && !this.isAdmin"
              name="ph1" id="ph1" value="{{s.ph1}}" required>
          </div>
          <div [hidden]="this.onDepartmentAccess" class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Guardian Contact No.</h6>
            <input #ph2 type="number" class="form-control" [readonly]="!canEnrollRepeater && !this.isAdmin"
              name="ph2" id="ph2" value="{{s.ph2}}" required>
          </div>
          <div class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Gender</h6>
            <input [readonly]="this.onDepartmentAccess" #gender class="form-control" name="gender" id="gender"
              value="{{s.gender}}">
          </div>

          <div class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Religion</h6>
            <input [readonly]="this.onDepartmentAccess||this.isExamCell" #religion class="form-control" name="religion"
              id="religion" value="{{s.relig}}" required>
          </div>
          <div class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Department</h6>
            <input class="form-control" name="name" id="name" value="{{s.deptt}}" readonly>
          </div>
          <div class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Major</h6>
            <input class="form-control" name="name" id="name" value="{{s.major}}" readonly>
          </div>
          <div class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Year</h6>
            <input class="form-control" name="name" id="name" value="{{s.year}}" readonly>
          </div>
          <div [hidden]="this.onDepartmentAccess" class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Session</h6>
            <input class="form-control" name="name" id="name" value="{{s.session}}" readonly>
          </div>
          <div *ngIf="!(studentInfo[0]?.c_code == 1)" class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Set no</h6>
            <input class="form-control" name="name" id="name" value="{{ s.set_no }}" readonly>
          </div>

          <div *ngIf="studentInfo[0]?.c_code==1" class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Elective Courses</h6>
            <input class="form-control" name="name" id="name" value="{{s.set_no}}" readonly>
          </div>

          <div *ngIf="!(studentInfo[0]?.c_code == 1)" class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Registration No.</h6>
            <input #reg_no class="form-control" name="reg_no" id="reg_no" value="{{s.reg_no}}" readonly>
          </div>

          <div *ngIf="studentInfo[0]?.c_code==1" class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Registration No.</h6>
            <input #reg_no class="form-control" name="reg_no" id="reg_no" (change)="OnRegUpdate(reg_no.value)"
              value="{{s.reg_no}}">
          </div>

          <div *ngIf="studentInfo[0]?.c_code==1" class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Board No. Part I</h6>
            <input #BRD_RN class="form-control" name="BRD_RN" id="BRD_RN" (change)="OnBoardUpdate(BRD_RN.value)"
              value="{{s.BRD_RN}}">
          </div>

          <div *ngIf="studentInfo[0]?.c_code==1" class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Board No. Part II</h6>
            <input #BRD_RN_2 class="form-control" name="BRD_RN_2" id="BRD_RN_2"
              (change)="OnBoard2Update(BRD_RN_2.value)" value="{{s.BRD_RN_2}}">
          </div>
          <!-- <div class="mt-2 col-lg-4 col-md-4">
            <h6 class="card-title">Set no</h6>
            <input class="form-control" name="name" id="name" value="{{s.set_no}}" readonly>
          </div> -->
          <!-- [readonly]="this.onDepartmentAccess" -->
          <div [hidden]="this.onDepartmentAccess" class="mt-2 col-lg-12 col-md-0">
            <h6 class="card-title">Address</h6>
            <input #add1 class="form-control" [readonly]="!canEnrollRepeater && !this.isAdmin" name="add1" id="add1" value="{{s.add1}}">
          </div>

          <div [hidden]="this.onDepartmentAccess" class="mt-2 col-lg-12 col-md-0">
            <h6 class="card-title">Address 2</h6>
            <input #add2 class="form-control" [readonly]="!canEnrollRepeater && !this.isAdmin" name="add2" id="add2" value="{{s.add2}}">
          </div>
          <div *ngIf="this.canStudentDetail || this.isAdmin || this.isExamCell" class="ml-1 mt-4 col-lg-4 col-md-2">
            <button style="height: 40px;" (click)="UpdateStudentDetail()" (click)="onSubmit(name,fName,cnic,email,add1,ph1,ph2,gender,religion,dob,add2)"
            (click)="onSubmitFee(name,fName,cnic,email,add1,ph1,ph2,gender,religion,dob,add2)"
              class="btn btn-primary">Update Information</button>
          </div>
        </div>

        <div *ngIf="hideInput">
          <div class="card-header">
            Student Enrollment
          </div>

          <div class="mt-2 row form-group">
            <div class="col-lg-4 col-md-6 form-group">
              <label for="se_id">Session</label>
              <select #se_id_new class="form-control " name="se_id_new" id="se_id_new" required ngModel>
                <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
              </select>
            </div>
            <div class="col-lg-4 col-md-6">
              <label for="t_no">Semester</label>
              <select #t_no class="form-control" name="t_no" ngModel id="t_no">
                <option *ngFor="let t of array" value="{{t}}">{{t}}</option>
                <option value="-1">complete</option>
              </select>
            </div>
            <div class="col-sm-4 col-lg-4 col-md-6">

              <button [disabled]="(!t_no.value&&!se_id_new)" type="button" class="btn btn-primary mt-4 "
                (click)="checkSubOffer()">
                Subject Offer
              </button>
              <button [disabled]="(!t_no.value&&!se_id_new)" type="button" class="btn btn-primary ml-2 mt-4 "
                (click)="checkEnroll()">
                Check Enrollment
              </button>
            </div>
          </div>
          <hr>
        </div>

        <table *ngIf="this.enrollInfo?.length>0" class="mb-0 table table-hover table-bordered">
          <thead class="table-active">
            <th>Sr No.</th>
            <th>Enrolled Subjects</th>
            <th>Semester</th>
            <th>Session</th>
            <th>Section</th>
            <th>Student Attendance</th>
            <th>Student Assessment</th>
          </thead>
          <tbody>
            <tr *ngFor="let s of enrollInfo; let i=index;">
              <td>{{i+1}}</td>
              <td>{{s.subject}} ({{s.sub_code}})</td>
              <td>{{s.t_no}}</td>
              <td>{{s.session}} ({{s.se_id}})</td>
              <td>{{s.section}}</td>
              <td>
                <button type="button" class="btn btn-sm btn-primary" (click)="showDetail(s)">
                  Attendance
                </button>
              </td>
              <td>
                <button type="button" class="btn btn-sm btn-primary" (click)="showDetail1(s)">
                  Assessment
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <table *ngIf="this.subInfo?.length>0" class="mb-0 table table-hover table-bordered">
          <thead class="table-active">
            <th>Sr. No</th>
            <th>Offered Subjects</th>
            <th>Semester</th>
            <th>Set no</th>
          </thead>
          <tbody>
            <tr *ngFor="let s of subInfo; let i=index;">
              <td>{{i+1}}</td>
              <td>{{s.subject}} ({{s.sub_code}})</td>
              <td>{{s.t_no}}</td>
              <td>{{s.set_no}}</td>
            </tr>
          </tbody>
        </table>

      </div>
    </form>
  </div>
</div>

<app-student-detail *ngIf="show" [enrolInfo]="this.sendEnroll" [std]="this.sendSTD" (close)="closeIt()">
</app-student-detail>
<app-check-assessment *ngIf="show1" [enrolInfo]="this.sendEnroll" [std]="this.sendSTD" (close)="closeIt1()">
</app-check-assessment>