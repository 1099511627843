import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "src/app/auth/_services/authentication.service";
import { SlideInFromLeft } from "src/app/transitions";
import { SubjectOfferService } from "./../shared/services/SubjectOffer.service";
import { SelectList } from "../shared/model/SelectList.model";
import { MajorService } from './../shared/services/Major.service';
import { DatePipe } from "@angular/common";
import { jsPDF } from "jspdf";
import autoTable, { UserOptions } from "jspdf-autotable";
import "jspdf-autotable";
import { LMSharedDataService } from "src/app/shared/LMSSharedService";
import { FeeSharedDataService } from "src/app/shared/FeeSharedService";
declare let $:any;
interface subOffer {
  SUB_NM: string;
  SUB_CODE: string;
  CR_H: number;
  GP_PER: string;
  C_ELE: string;
  REG_SUMR: string;
  AB_REL: number;
  SET_NO: number;
  DES_NM: string;
  MAJ_NM: string;
}
@Component({
  selector: "app-subject-offer-report",
  templateUrl: "./subject-offer-report.component.html",
  styleUrls: ["./subject-offer-report.component.css"],
  animations: [SlideInFromLeft()],
})
export class SubjectOfferReportComponent implements OnInit {
  @ViewChild("f") formRef: NgForm;
  majors: Array<SelectList>;
  classes: Array<SelectList>;
  sessions: Array<SelectList>;
  // offeredSubjects: Array<subOffer>;

  subjects: Map<string, Map<number, subOffer[]>>;

  majorList: string[];

  isAdmin: boolean = false;
  isExam: boolean = false;
  suboffer: boolean = false;

  temp: boolean = false;
  selectedClass: string;
  selectedSe_id: string;
  departmentId: any;
  examdptid: any = 102;
  ditid: any = 404;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'> you can check all the offered subjects of different majors in a class and offer same subject for another major of same session </h6>
  <h6 class="text-right">آپ کسی کلاس میں مختلف میجرز کے تمام آفر کردہ سبجیکٹس چیک کر سکتے ہیں اور اسی سیشن کے لئے کسی دوسرے میجر کے لئے بھی وہی سبجیکٹ آفر کر سکتے ہیں۔
</h6>
  `  ;


  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private majorService: MajorService,
    private subOffer: SubjectOfferService,
    private datePipe: DatePipe,
    private lmsSharedService: LMSharedDataService,
    private feeSharedService: FeeSharedDataService
  ) {
    this.majors = new Array<SelectList>();
    this.classes = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.majorList = [];
    this.subjects = new Map<string, Map<number, subOffer[]>>();

    this.isAdmin = this.authenticationService.isAdmin();
    this.isExam = this.authenticationService.isExamCell();
  }

  ngOnInit(): void {
    this.departmentId = parseInt(this.authenticationService.getUser().D_ID);
    this.suboffer = false;
    this.updateClasses();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting lms classes", error);
      });
  }

  OnClassChange() {
    var iid = parseInt(this.formRef.value.c_code);
    var results = this.classes.filter(function (entry) {
      return entry.id === iid;

    });
    this.updateMajors();
    this.selectedClass = results[0].nm;
    this.updateSessions();
  }

  updateSessions() {
    this.lmsSharedService.filterSessionByCode(this.formRef.value.c_code)
      .then(session => {
        this.sessions = session
        if (this.formRef) {
          const initialValue = this.sessions[0]?.id;
          this.formRef?.controls['se_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }

  OnSemesterChange() {
  }


  viewDetail() {
    this.suboffer = false;
    if (this.formRef.value.t_no === "" || this.formRef.value.se_id === "")
      return;

    this.majorList = [];
    this.subjects.clear();
    this.suboffer = false;
    this.subOffer.ExamgetSubjects(this.formRef.value.c_code, this.formRef.value.se_id,
      this.formRef.value.t_no).subscribe((res: subOffer[]) => {
        this.catagorizeSubjects(res);

      },
        (err) => {
          console.log(err);
          this.toastr.error(err);
        }

      );
  }

  updateMajors() {
    this.majors.length = 0;
    this.feeSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['Nmaj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  public navigateToSection(section: string) {
    window.location.hash = '';
    window.location.hash = section;
  }


  SubjectShift() {
    this.suboffer = !this.suboffer;
  }


  onMajorChange() {
    this.getMajLock()
  }


  maj_lock: number;
  getMajLock() {
    const { c_code, Nmaj_id, Nse_id, Nt_no } = this.formRef.value;
    this.majorService.getMajorLock(parseInt(c_code), parseInt(Nmaj_id), Nse_id, Nt_no).subscribe(res => {
      this.maj_lock = res[0].offer_lock
    })
  }

  AutoSubjectOffer() {
    if (parseInt(this.departmentId) != parseInt(this.examdptid) && parseInt(this.departmentId) != parseInt(this.ditid)) {
      if (this.maj_lock == 1) {
        this.toastr.warning("Subject Offer Locked!");
        return
      }
    }
    if (this.formRef.value.t_no === "" || this.formRef.value.se_id === "")
      return;

    this.majorList = [];
    this.subjects.clear();
    let params = {
      Oc_code: this.formRef.value.c_code, Ose_id: this.formRef.value.se_id, Ot_no: this.formRef.value.t_no,
      Nse_id: this.formRef.value.Nse_id, Nt_no: this.formRef.value.Nt_no, Nmaj_id: this.formRef.value.Nmaj_id
    }
    this.subOffer.subjectOfferMajWise(params).subscribe((res: subOffer[]) => {
      this.toastr.success("Subject Offer Successfully");

      this.catagorizeSubjects(res);
    },
      (err) => {
        console.log(err);
        this.toastr.error(err);
      }

    );
  }


  catagorizeSubjects(subjectList: subOffer[]) {
    subjectList?.forEach((e) => {
      if (this.subjects.has(e.MAJ_NM)) {
        if (this.subjects.get(e.MAJ_NM).has(e.SET_NO)) {
          this.subjects.get(e.MAJ_NM).get(e.SET_NO).push(e);
        } else {
          this.subjects.get(e.MAJ_NM).set(e.SET_NO, new Array<subOffer>(e));
        }
      } else {
        this.subjects.set(e.MAJ_NM, new Map());
        this.subjects.get(e.MAJ_NM).set(e.SET_NO, new Array<subOffer>(e))
      }
    });

    this.majorList = Array.from(this.subjects?.keys());

    if (this.subjects?.size === 0) {
      this.toastr.warning("No Subject Offer");
    }
  }


  getSubjects(majorName: string) {
    let subs = [];
    for (let s of this.subjects.get(majorName)?.values()) {
      subs = [...subs, ...s.values()]
    }
    return subs;
  }

  allMajListPDF() {
    this.createPdf(true);
  }

  singleMajPDF(majNm: string) {
    this.createPdf(false, majNm)
  }



  createPdf(complete: boolean, majorName?: string) {
    if (this.subjects.size == 0) {
      return;
    }
    let iid = parseInt(this.formRef.value.se_id);
    let results = this.sessions.find(entry => entry.id === iid);
    this.selectedSe_id = results.nm;
    let selectedSemester = this.formRef.value.t_no;

    let body = [];
    let header = [];
    this.toastr.info("Downloading TimeTable");
    let image = new Image();
    image.src = "../../../assets/images/logo3.png";
    const doc = new jsPDF("p", "mm", [297, 300]);
    doc.addImage(image, 180, 2, 18, 18);
    let exportDate = this.datePipe.transform(new Date(), "MMM d,y");
    doc.setFontSize(8);
    doc.text(`${exportDate}`, 181, 24);
    doc.setFontSize(14);
    doc.setFont("Arial", "bold");

    doc.text("CLASS:", 60, 10);
    doc.text(this.selectedClass, 80, 10);

    doc.text("TERM NUMBER:", 82, 17);
    doc.text(selectedSemester, 124, 17);

    doc.text("SESSION:", 78, 24);
    doc.text(this.selectedSe_id, 103, 24);
    doc.setFont("Arial", "normal");

    header.push();
    let y = 26;
    for (let maj of this.subjects.keys()) {
      // check for single major list 
      // ahmad 16 feb, 2022
      if (!complete && maj !== majorName) continue;
      //MAJORS
      for (let setNo of this.subjects.get(maj).keys()) {
        header = [[
          { content: maj, colSpan: 4, styles: { halign: 'left', fillColor: [22, 160, 133] } },
          { content: `Set No.:  ${setNo}`, colSpan: 4, styles: { halign: 'center', fillColor: [22, 160, 133] } }
        ],
        ['Sr. No.', "Sub Code", "Subject Name", "Credit Hrs.", "Nature", 'Type', "ABREL", "GPPER"]
        ]
        //SETNO
        let arr: subOffer[];
        arr = this.subjects.get(maj).get(setNo);
        arr.forEach((entry, i) => {
          //putting array into body against this major and this setno
          body.push([(i + 1),
          entry.SUB_CODE,
          entry.SUB_NM,
          entry.CR_H,
          entry.DES_NM,
          entry.C_ELE,
          entry.AB_REL == 1 ? "RELATIVE" : "ABSOLUTE",
          entry.GP_PER == 'G' ? "GRADE" : "%AGE"
          ]);
        })

        autoTable(doc, {
          theme: "grid",
          margin: { bottom: 30 },
          startY: (y + 9),
          head: header,
          body: body,
          didDrawPage: function (d) {
            y = d.cursor.y;
          },
        });
        body = [];
      }
    }
    var a = this.toastr;
    setTimeout(function () {
      a.success("Downloaded");
    }, 1000);
    // var c = this.selectedClass;
    this.toastr.success("PDF Download Successfully");
    // doc.save(`Sub Offer List-${(complete) ? 'Complete' : majorName}-${selectedSemester}-${this.selectedSe_id}`);

    doc.setProperties({
      title: `Sub Offer List-${(complete) ? 'Complete' : majorName}-${selectedSemester}-${this.selectedSe_id}`,

    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }

  Filter(index)
  {
    $(document).ready(function(){
      $(`#myInput${index}`).on("keyup", function() {
        var value = $(this).val().toLowerCase();
        $(`#table${index} #values${index}`).filter(function() {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
      });
    });
  }
}
