import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { baseUrl} from '../../../axios';

export interface announement {
  ANN_TITLE: string;
  ANN_DESC: string;
  ANN_DATE: string;
}

@Component({
  selector: 'app-annoucement',
  templateUrl: './annoucement.component.html',
  styleUrls: ['./annoucement.component.css']
})
export class AnnoucementComponent implements OnInit {
  announcements
// socket:any;
  constructor(private http: HttpClient) { 
    // this.socket = new WebSocket(`${wsUrl}`);
  }

  ngOnInit(): void {

    this.getNotification().subscribe((res: announement[]) =>{
      this.announcements=res;
    }

    );
  }

  getNotification(){
    return this.http.get(`${baseUrl}/api/announcement/T_Notification`);
  }


}
