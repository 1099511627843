import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../shared/model/SelectList.model';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { FeeService } from '../shared/services/Fee.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-re-elig',
  templateUrl: './re-elig.component.html',
  styleUrls: ['./re-elig.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class ReEligComponent implements OnInit {
  sessions: Array<SelectList>;
  sub: Array<SelectList>;
  var: any;
  stat = '';
  count = 0;
  total = 0;
  stdt: Array<{ rolno: any, sub_code: any, t_no: number, sub_nm: string, section: string, month_nm: number, mnth: string, da_date: any, id: any, attend: string }>;
  @ViewChild('f') formRef: NgForm;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  you can change the eligibility of any student by marking present/absent in their any subject 
  in their semester of any session.You can also view the eligiblke status of the student. </h6>
  <h6 class="text-right">آپ کسی بھی سیشن کے سمسٹر میں کسی بھی سبجیکٹ میں طلباء کی حاضری/غیر حاضری مارک کر کے ان کی ایلیجیبلٹی کو تبدیل کر سکتے ہیں۔
  آپ طالب علم کی ایلیجیبلٹی اسٹیٹس بھی دیکھ سکتے ہیں۔۔
</h6>
  `
  constructor(private feeService: FeeService,
    private toastr: ToastrService,
    private datepipe: DatePipe) {
      this.var=0.0;
    this.stdt = new Array<{ rolno: any, sub_code: any, t_no: number, sub_nm: string, section: string, month_nm: number, mnth: string, da_date: any, id: any, attend: string, allow: boolean }>();
  }

  ngOnInit(): void {
  }

  loadData() {
    this.stdt = [];
    this.var = 0.0;
    this.stat = '';
    this.count = 0;
    this.total = 0;
    this.feeService.calReElig(this.formRef.value).subscribe(res => {
      if (res) {
        res[0]?.forEach(element => {
          var flag = (element.attend == 'a');
          this.stdt.push({ ...element, allow: flag });
          if (element.attend == 'a') {
            this.count = this.count + 1;
          }
        });
        this.total = this.stdt.length;
        this.count = this.total - this.count;
        if(this.total!=0){
        this.var = ((this.count / this.total) * 100).toFixed(2);
        if (this.var < 80) {
          this.stat = 'InEligible';
        }
        else if(this.var>=80){
          this.stat = 'Eligible';
        }
        }
        else{
          this.var=0.0;
          this.stat='';
        }
      }
      else if(!res){
        this.var=0.0;
        this.stat='';
      }
    });
  }

  loadSession() {
    this.sessions = [];
    this.sub = [];
    this.stdt = [];
    this.feeService.sessionsEnroll(this.formRef.value).subscribe(res => {
      if (res) {
        res[0]?.forEach(element => {
          this.sessions.push(element);
        });
      }
    });
  }

  loadSubject() {
    this.sub = [];
    this.stdt = [];
    this.var=0.0;
    this.stat='';
    this.feeService.subEnroll(this.formRef.value).subscribe(res => {
      if (res) {
        res[0]?.forEach(element => {
          this.sub.push(element);
        });
      }
    });
  }

  onChange() {
    this.loadData();
  }

  updateDailyAttendance(student: any, present) {
    if (student.attend == 'a') {
      present.target.parentElement.parentElement.parentElement.classList.remove('text-danger');
      present.target.parentElement.parentElement.parentElement.classList.add('text-info');
      student.attend = 'p';
      this.count = this.count + 1;
      this.var = ((this.count / this.total) * 100).toFixed(2);
      if (this.var < 80) {
        this.stat = 'InEligible';
      }
      else {
        this.stat = 'Eligible';
      }
    }
    else if (student.attend == 'p') {
      present.target.parentElement.parentElement.parentElement.classList.remove('text-info');
      present.target.parentElement.parentElement.parentElement.classList.add('text-danger');
      student.attend = 'a';
      this.count = this.count - 1;
      this.var = ((this.count / this.total) * 100).toFixed(2);
      if (this.var < 80) {
        this.stat = 'InEligible';
      }
      else {
        this.stat = 'Eligible';
      }
    }
    student.id = moment(new Date(student.id)).format('YYYY-MM-DD');
    this.feeService.updateAttendance({ ...this.formRef.value, month_nm: student.month_nm, id: student.id, attend: student.attend }).subscribe(res => {
      if (res) {
        this.toastr.success(res[0][0].msg);
        // if(res[0][0].msg=="")
        // this.stdt=[];
        // res[1]?.forEach(element => {
        //   this.stdt.push(element); 
        //  });
      }
      else if (!res) {
        this.toastr.warning('Unknown Error!');
      }
    });
  }
}