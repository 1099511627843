import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../../shared/model/SelectList.model';
import { DegreeprintService } from '../../shared/services/degreeprint.service';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';

export interface Data {
  trans_no: number,
  rollno: string,
  Name: string,
  F_name: string,
  notifDate: string
}
@Component({
  selector: 'app-status-of-transcript-report',
  templateUrl: './status-of-transcript-report.component.html',
  styleUrls: ['./status-of-transcript-report.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class StatusOfTranscriptReportComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;
  classes: Array<SelectList>;
  Details: Array<Data>;
  isOpen: boolean = true;
  constructor(
    private degree: DegreeprintService, 
    private toaster: ToastrService,
    private lmsSharedService: LMSharedDataService) {
    this.classes = [];
    this.Details = [];
  }

  ngOnInit(): void {
    this.updateClasses();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  DataLoad() {
    this.toaster.info("Please Wait Data is Loading");
    this.degree.getTranscriptStatus(this.formRef.value.c_code, this.formRef.value.st_year, this.formRef.value.end_year, this.formRef.value.notifDate, this.formRef.value.notifDate1).subscribe((res: any) => {
      if (res != '') {
        this.toaster.success("Data Load Successfully");
        for (var i = 0; i < res.length; i++) {
          this.Details.push({ trans_no: res[i].TRNS_NO, rollno: res[i].ROLNO, Name: res[i].NM, F_name: res[i].F_NM, notifDate: res[i].NotificationDate });
        }
      }
      else {
        this.toaster.error("Data Not Found");
      }
    });
  }
  OnReport() {
    this.toaster.success("PDF In Progress");
    const header = [];
    var body = [];
    // this.Details.forEach((e,i)=>{
    //   body.push([(i+1), e.year, e.rollno, e.Name,e.F_name,e.Reg_no,e.gender,e.major,e.Deg_no, e.CGPA])
    // });
    this.Details.forEach((e, i) => {
      body.push([(i + 1), e.trans_no, e.rollno, e.Name, e.F_name, e.notifDate])
    });

    const doc = new jsPDF('p', 'mm', [297, 210]);

    header.push(['Sr#', 'Trans No', 'Roll No', 'Name', 'Father Name', 'Date', 'Status']);

    autoTable(doc, {
      theme: "grid",
      headStyles: {
        textColor: [0, 0, 0]
        , fillColor: [255, 255, 255], lineWidth: 0.5
      },
      margin: { top: 20, bottom: 5 },

      head: header,
      body: body,
      didDrawPage: function (d) {
        //   y = d.cursor.y;
        doc.setFontSize(22);
        doc.text("GC University Lahore", 105, 12, { align: 'center' });
      },
    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }
  DownloadExcel() {
    var body = [];
    var header = [];

    header.push(['Sr#', 'Trans No', 'Roll No', 'Name', 'Father Name', 'Date', 'Status']);
    this.Details.forEach((e, i) => {
      body.push([(i + 1), e.trans_no, e.rollno, e.Name, e.F_name, e.notifDate])
    });

    new AngularCsv(body, "Transcript Status Report", { headers: (header) });
  }
  OnClear(cls: NgForm) {
    cls.resetForm();
    this.isOpen = false;
  }

  Filter()
  {
    filter();
  }
}
