import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import * as fromApp from './../store/app.reducers';
import { Store } from '@ngrx/store';
import { CourseModal } from '../shared/course.modal';
import { AppComponentEventEmitterService } from './event-emmiter.service';
import { FadeIn } from '../transitions';
import { AuthenticationService } from '../auth/_services';
import { FileService } from '../main/shared/services/File.service';
import { SRMService } from '../auth/_services/srm.service';
import { relative } from 'path';
declare var $: any;

@Component({
  template: 'The href is: {{href}}',
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  animations: [
    FadeIn()
  ]
})
export class MainComponent implements OnInit {
  public href: string = "";
  public semesterCourses: CourseModal[];
  public teacherName: string;
  public FM_ID: string;
  showResetForm = false;
  @Input() message: string;
  showChangePassword = false;
  showMessage = false;

  isAdmin: boolean;
  isHod: Boolean;
  isTeacher: boolean;
  canAccessStudentProfile: boolean;
  canManageTimetable: boolean;
  canEnroll: boolean;
  canEnrolleRepeater: boolean;
  isExamCell: boolean;
  canAddSubject: boolean;
  canAllocateSection: boolean;
  canManageAwardList: boolean;
  canReadmission: boolean;
  canIDC: boolean;
  canFeeStructure: boolean;
  stdDetail: boolean;
  superAdmin: boolean;
  canRecoverUser: boolean;
  isFinancialAidOffice: boolean;
  isEndownmentFund: boolean;
  isQEC: boolean;
  isDashboard: boolean;
  navAction: any;
  navActionLocal: any;
    constructor(
    private SRM: SRMService,
    private router: Router,
    private fileService: FileService,
    private store: Store<fromApp.AppState>,
    private clickEvent: AppComponentEventEmitterService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService) {
    this.isAdmin = authenticationService.isAdmin();
    this.superAdmin = authenticationService.superAdmin();
    this.isTeacher = authenticationService.isTeacher();
    this.canManageTimetable = authenticationService.canManageTimetable();
    this.canEnroll = this.authenticationService.canEnroll();
    this.canEnrolleRepeater = this.authenticationService.canEnrollRepeater();
    this.isExamCell = this.authenticationService.isExamCell();
    this.canAddSubject = authenticationService.canAddSubject();
    this.canAccessStudentProfile = authenticationService.canAccessStudentProfile();
    this.canAllocateSection = authenticationService.canAllocateSection();
    this.canManageAwardList = authenticationService.canManageAwardList();
    this.canReadmission = authenticationService.canReadmission();
    this.canIDC = authenticationService.canIDC();
    this.canFeeStructure = authenticationService.canFeeStructure();
    this.stdDetail = authenticationService.seeStudentDetail();
    this.canRecoverUser = authenticationService.seeStudentDetail();
    this.isFinancialAidOffice = authenticationService.isFinancialAidOffice();
    this.isEndownmentFund = authenticationService.isEndownmentFund();
    this.isQEC = authenticationService.isQEC();
    this.isHod = authenticationService.isHod();
    this.isDashboard = authenticationService.isDashboard();

  }
  test(v) {
    console.log("Event riggered");
  }

  onHomeClicked() {
    this.router.navigate(['timeTable'], { relativeTo: this.route });
  }

  onCourseOutlineClicked() {
    this.router.navigate(['courseOutline'], { relativeTo: this.route });
  }


  onAttRelaxClicked() {
    this.router.navigate(['AttendanceRelaxtion'], { relativeTo: this.route });
  }

  OncourseUploadClicked() {
    this.router.navigate(['courseUpload'], { relativeTo: this.route });
  }

  OnStudentQueriesClicked() {
    this.router.navigate(['studentQueries'], { relativeTo: this.route });
  }

  OnTeacherInfoClicked() {
    this.router.navigate(['teacherInformation'], { relativeTo: this.route });
  }

  onSubmissionCheck() {
    this.router.navigate(['ResultSubmissionCheck'], { relativeTo: this.route });
  }
  onQueryCheck(event: Event) {
    event.stopPropagation();
    this.router.navigate(['exam-query'], { relativeTo: this.route });
  }
  OnStudentGeneralInfo(){
    this.router.navigate(['studentGeneralInfo'], { relativeTo: this.route });
  }
  Onadmittedset(){
    this.router.navigate(['admittedset'],{ relativeTo: this.route} );
  }
  OnGenderSt(){
    this.router.navigate(['genderWise'],{relativeTo: this.route});
  }
  OnMeritListInfo(){
    this.router.navigate(['mListReport'], { relativeTo: this.route });
  }
  OnOverall(){
    this.router.navigate(['overallDump'], { relativeTo: this.route });
  }
  OnSetWiseClassStrenght(){
    this.router.navigate(['StudentStrength'], { relativeTo: this.route });
  }
  OnBoardWise(){
    this.router.navigate(['boardWise'], { relativeTo: this.route });
  }
  OnSubjectWiseStrength(){
    this.router.navigate(['SubjectSectionStrength'], { relativeTo: this.route });
  }
  // OnlevelWiseStCount(){
  //   this.router.navigate(['LevelWiseStudentStrength'], { relativeTo: this.route });
  // }
  OnEnrollmentStatusReport(){
    this.router.navigate(['EnrollmentStatus'], { relativeTo: this.route });
  }
  OnSubjectWiseEnrollmentCount(){
    this.router.navigate(['StudentCountEachSubject'], { relativeTo: this.route });
  }
  // OnlevelWiseStCountYear(){
  //   this.router.navigate(['LevelWiseStudentStrengthYearly'], { relativeTo: this.route });
  // }

  onEligibilityClicked() {
    this.router.navigate(['Eligiblity'], { relativeTo: this.route });
  }
  OnSubOffer() {
    this.router.navigate(['subjectOffer'], { relativeTo: this.route });
  }

  closeMenu() {
    const HamButton = document.getElementById('navBarHamBtn');
    const mainDivContainingNav = document.getElementById('main-container');

    if (!HamButton.classList.contains('is-active')) {
      HamButton.classList.add('is-active');
      mainDivContainingNav.classList.add('sidebar-mobile-open');
    }

    const hamButtonLg = document.getElementById('navBarHamBtn-lg');
    if (!hamButtonLg.classList.contains('is-active')) {
      hamButtonLg.classList.add('is-active');
      mainDivContainingNav.classList.add('closed-sidebar');
      this.clickEvent.announceClick(true);
    }
  }
  OnInsertTimeTable() {
    this.router.navigate(['InsertTimeTable'], { relativeTo: this.route });
  }

  OnSectionChange() {
    this.router.navigate(['SectionChange'], { relativeTo: this.route });
  }

  onSectionAllocate() {
    this.router.navigate(['SectionAllocate'], { relativeTo: this.route });
  }

  onIDCSectionAllocate() {
    this.router.navigate(['idcAllocation'], { relativeTo: this.route });
  }

  ongenerateTimeTable() {
    this.router.navigate(['GenerateTimeTable'], { relativeTo: this.route });
  }

  OnSubjectEnrollment() {
    this.router.navigate(['subjectEnrollment'], { relativeTo: this.route });
  }
  OnReAdmission() {
    this.router.navigate(['ReAdmitted'], { relativeTo: this.route });
  }
  onNewSession() {
    this.router.navigate(['sessionNew'], { relativeTo: this.route });
  }
  onlockstd() {
    this.router.navigate(['lockstudent'], { relativeTo: this.route });
  }

  onFreezeReport(){
    this.router.navigate(['FreezeReport'],  { relativeTo: this.route }); 
  }
  
  onReElig() {
    this.router.navigate(['reElig'], { relativeTo: this.route });
  }

  OnSubDisplay() {
    this.router.navigate(['subjects'], { relativeTo: this.route });
  }
  OnRegister() {
    this.router.navigate(['register'], { relativeTo: this.route });
  }
  OnShowMenuListItem(id: string) {
  }
  OnReportsClick(id: string) {
  }

  OnClassShift() {
    this.router.navigate(['shiftingmodule'], { relativeTo: this.route });
  }

  OnRequestedSub() {
    this.closeMenu();
    this.router.navigate(['requestedSub'], { relativeTo: this.route });
  }
  OnExamResult() {
    this.router.navigate(['examModule'], { relativeTo: this.route });
  }
  OnViewTimeTable() {
    this.router.navigate(['ViewTimeTable'], { relativeTo: this.route });
  }
  OnDepartmentReports() {
    this.router.navigate(['departmentReports'], { relativeTo: this.route });
  }
  navigateToLocking() {
    this.router.navigate(['lockmanagement'], { relativeTo: this.route });
  }
  OnAdmChangeRecord() {
    this.router.navigate(['AdmChangeRecord'], { relativeTo: this.route });
  }
  OnnavBarHamBtnClicked() {
    const HamButton = document.getElementById('navBarHamBtn');
    const mainDivContainingNav = document.getElementById('main-container');
    if (HamButton.classList.contains('is-active')) {
      HamButton.classList.remove('is-active');
      mainDivContainingNav.classList.remove('sidebar-mobile-open');
    } else {
      HamButton.classList.add('is-active');
      mainDivContainingNav.classList.add('sidebar-mobile-open');
    }
  }

  OnnavBarHamBtn_lgClicked() {
    this.navAction = localStorage.getItem('navAction');
    this.navAction = this.navAction == null ? 'true' : this.navAction;
    console.log(this.navAction,"navaction")
    const hamButtonLg = document.getElementById('navBarHamBtn-lg');
    const mainDivContainingNav = document.getElementById('main-container');
    if (this.navAction == 'true') {
      hamButtonLg.classList.remove('is-active');
      mainDivContainingNav.classList.add('closed-sidebar');
      this.clickEvent.announceClick(false);
      this.navActionLocal = true;
    } else {
      hamButtonLg.classList.add('is-active');
      mainDivContainingNav.classList.remove('closed-sidebar');
      this.clickEvent.announceClick(true);
      this.navActionLocal = false;
    }
  }

  OnnavBarHamBtn_lgClickedLocal() {
    const hamButtonLg = document.getElementById('navBarHamBtn-lg');
    const mainDivContainingNav = document.getElementById('main-container');
    if (hamButtonLg.classList.contains('is-active')) {
      hamButtonLg.classList.remove('is-active');
      mainDivContainingNav.classList.add('closed-sidebar');
      this.clickEvent.announceClick(false);
      this.navActionLocal = true;
      localStorage.removeItem('navAction');
      localStorage.setItem('navAction', this.navActionLocal);
    } else {
      this.navActionLocal = false;
      hamButtonLg.classList.add('is-active');
      mainDivContainingNav.classList.remove('closed-sidebar');
      this.clickEvent.announceClick(true);
      localStorage.removeItem('navAction');
      localStorage.setItem('navAction', this.navActionLocal);
    }
  }

  OnAppHeaderMobileMenu() {
    const mobileMenu = document.getElementById('app-header-mobile-menu');
    const buttonContent = document.getElementById('content_mobile');
    const buttonActivated = mobileMenu.classList.contains('active');
    if (buttonActivated) {
      mobileMenu.classList.remove('active');
      buttonContent.classList.remove('header-mobile-open');
    } else {
      mobileMenu.classList.add('active');
      buttonContent.classList.add('header-mobile-open');
    }
  }
  ngOnInit(): void {
    this.href = this.router.url;
    this.teacherName = this.authenticationService.getUser()?.NM;
    this.FM_ID = this.authenticationService.getUser()?.FM_ID;
    this.isAdmin = this.authenticationService.isAdmin();
    // jquery
    $('.menu-list ul').on('click', () => {
      if (window.innerWidth < 992) {
        $('#navBarHamBtn').removeClass('is-active');
        $('#main-container').removeClass('sidebar-mobile-open');
      }
    });
    $('ul.vertical-nav-menu > li:not(.menu-list):not(.app-sidebar__heading)').on('click', () => {
      if (window.innerWidth < 992) {
        $('#navBarHamBtn').removeClass('is-active');
        $('#main-container').removeClass('sidebar-mobile-open');
      }
    });

    this.clickEvent.showMessage.subscribe(
      value => {
        this.showMessage = true;
      }
    );
    this.OnnavBarHamBtn_lgClicked();
  }


  OnAddSub() {
    this.router.navigate(['AddSubject'], { relativeTo: this.route });
  }

  onRecoverUserClicked() {
    this.router.navigate(['recoverUser'], { relativeTo: this.route });
  }
  // onCloseResetForm() {
  //   this.showResetForm = false;
  // }

  // onShowResetForm() {
  //   this.showResetForm = true;
  // }

  onSingleEnroll() {
    this.router.navigate(['singleEnroll'], { relativeTo: this.route });
  }
  onuserdetail(){
    this.router.navigate(['UserDetail'],{relativeTo: this.route});
  }

  onDBdetail(){
    this.router.navigate(['SchemaDetail'],{relativeTo: this.route});
  }

  onSingleSubjectEnroll() {
    // this.router.navigate(['singleEnroll'], { relativeTo: this.route });
    this.router.navigate(['singleSubjectEnroll'], { relativeTo: this.route });
  }

  OnAssignmentClicked() {
    this.router.navigate(['assignment'], { relativeTo: this.route });
  }

  OnProjectsClicked() {
    this.router.navigate(['project'], { relativeTo: this.route });
  }

  OnPresentationClicked() {
    this.router.navigate(['presentation'], { relativeTo: this.route });
  }

  OnLabClicked() {
    this.router.navigate(['lab'], { relativeTo: this.route });
  }

  OnMidTermClicked() {
    this.router.navigate(['midTerm'], { relativeTo: this.route });
  }

  OnFinalTermClicked() {
    this.router.navigate(['finalTerm'], { relativeTo: this.route });
  }

  OnCheckEnrollmentClicked() {
    this.router.navigate(['CheckEnrollment'], { relativeTo: this.route });
  }

  OnCMClicked() {
    this.router.navigate(['CM'], { relativeTo: this.route });
  }

  OnAttendanceClicked(classid: String) {
    this.href = this.router.url;
    this.addActiveListClass(classid);
    this.router.navigate(['attendance'], { relativeTo: this.route });
  }

  OnViewStudentProfileClicked() {
    this.href = this.router.url;
    this.router.navigate(['viewStudentProfile'], { relativeTo: this.route });
  }
  OnGernerateTimeTable() {
    this.router.navigate(['GenerateTimeTable'], { relativeTo: this.route });
  }


  OnCheckTeacherAttendance() {
    this.router.navigate(['checkAttendence'], { relativeTo: this.route });
  }

  onLogout() {
    this.authenticationService.logout();
  }

  OnChangePasswordClicked() {
    this.showChangePassword = true;
  }

  onClickNotification() {
    this.router.navigate(['Announcements'], { relativeTo: this.route });
  }

  onCloseChangePasswordForm() {
    this.showChangePassword = false;
  }

  OnQuizClicked() {
    this.router.navigate(['quiz'], { relativeTo: this.route });
  }

  onCloseMessage() {
    this.showMessage = false;
  }

  navigate(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }
  navigate2(path: string) {
    this.router.navigate([path], { relativeTo: this.route });
  }
  navigateToManageAwardlist(classid: String) {
    this.href = this.router.url;
    this.addActiveListClass(this.href);
    this.router.navigate(['ManageAwardList'], { relativeTo: this.route });
  }
  OnStudentCountEachSubjectClick() {
    this.router.navigate(['StudentCountEachSubject'], { relativeTo: this.route });
  }

  OnStudentLeaves() {
    this.router.navigate(['dutyLeaves'], { relativeTo: this.route });
  }

  GenerateSingleStdAwardList() {
    this.router.navigate(['GenerateSingleStdAwardList'], { relativeTo: this.route });
  }

  OnProgressReportClick() {
    this.router.navigate(['progressReport'], { relativeTo: this.route });
  }
  OnAwardListClicked() {
    this.href = this.router.url;
    this.router.navigate(['awardList'], { relativeTo: this.route });
  }
  OnTransciptClicked() {
    this.router.navigate(['transcript'], { relativeTo: this.route });
  }
  OnDegreePrintClicked() {
    this.router.navigate(['degree'], { relativeTo: this.route })
  }
  OnDegreeRecieveInfoClicked() {
    this.router.navigate(['degree-recieve-info'], { relativeTo: this.route })
  }

  onDataExport() {
    this.router.navigate(['DataExport'], { relativeTo: this.route });
  }

  onResultCompile() {
    this.router.navigate(['resultCompile'], { relativeTo: this.route });
  }
  // onTeacherAvailClicked(){
  //   this.router.navigate(['teacherIssue'],{ relativeTo: this.route } );
  // }

  onFeeDescription() {
    this.router.navigate(['feeDescription'], { relativeTo: this.route });
  }

  onNewClass() {
    this.router.navigate(['newClass'], { relativeTo: this.route });
  }

  onInstallmentDefinitionEntry() {
    this.router.navigate(['installmentDefinitionEntry'], { relativeTo: this.route });
  }

  onChallanGenerate() {
    this.router.navigate(['challanGeneration'], { relativeTo: this.route });
  }

  onInstallmentPeriod() {
    this.router.navigate(['installmentPeriod'], { relativeTo: this.route });
  }

  onSubInstall() {
    this.router.navigate(['subInstall'], { relativeTo: this.route });
  }

  // onFineDescription(){
  //   this.router.navigate(['fineDescription'], { relativeTo: this.route });
  // }

  onDuesAdjustment() {
    this.router.navigate(['duesAdjustment'], { relativeTo: this.route });
  }

  onAssigningRollNo() {
    this.router.navigate(['assignRollNumber'], { relativeTo: this.route });
  }

  onConcessionCategory() {
    this.router.navigate(['concessionCategory'], { relativeTo: this.route });
  }

  onPrintChallan() {
    this.router.navigate(['printChallan'], { relativeTo: this.route });
  }

  onDateExtension() {
    this.router.navigate(['dateExtension'], { relativeTo: this.route });
  }

  onSubjectFee() {
    this.router.navigate(['subjectFee'], { relativeTo: this.route });
  }

  onRepeat() {
    this.router.navigate(['repeaterImprover'], { relativeTo: this.route });
  }

  onMlistReport() {
    this.router.navigate(['mListReport'], { relativeTo: this.route });
  }

  onFeeUtilClicked() {
    this.router.navigate(['feeUtil'], { relativeTo: this.route });
  }

  onOutStandingDues() {
    this.router.navigate(['outDues'], { relativeTo: this.route });
  }

  onFeeReport() {
    this.router.navigate(['feeReport'], { relativeTo: this.route });
  }

  onCollectionReport() {
    this.router.navigate(['collectionReport'], { relativeTo: this.route });
  }

  onDateExtensionReport() {
    this.router.navigate(['dateExtensionReport'], { relativeTo: this.route });
  }

  onDemandCollectionReportClass() {
    this.router.navigate(['demandCollectionReport'], { relativeTo: this.route });
  }

  onSelfSupportFormReport() {
    this.router.navigate(['selfSupportFormReport'], { relativeTo: this.route });
  }

  onFaidExpenditure() {
    this.router.navigate(['faidExpenditure'], { relativeTo: this.route });
  }
  onStdtGenInfo() {
    this.router.navigate(['studentGeneralInfo'], { relativeTo: this.route });
  }

  OnSubOfferShift() {
    this.router.navigate(['subjectOfferReport'], { relativeTo: this.route });
  }
  onFeeRefund() {
    this.router.navigate(['feeRefund'], { relativeTo: this.route });
  }
  onFeeRefundRep() {
    this.router.navigate(['feeRefundRep'], { relativeTo: this.route });
  }
  updateMajorTitle() {
    this.router.navigate(['majorTitle'], { relativeTo: this.route });
  }
  ExaminationReports() {
    this.router.navigate(['ExaminationReports'], { relativeTo: this.route });
  }

  SignatureForDegreeHolder() {
    this.router.navigate(['signaturefordegreeholders'], { relativeTo: this.route });
  }
  StatusOfTranscriptReportComponent() {
    this.router.navigate(['StatusOfTranscriptReportComponent'], { relativeTo: this.route });
  }


  EligbleCandidateForDegree() {
    this.router.navigate(['EligibleCandidateComponent'], { relativeTo: this.route });
  }

  degreeholderforgovernerhouse() {
    this.router.navigate(['degreeholderforgovernerhouse'], { relativeTo: this.route });
  }
  singlestdaddressforletters() {
    this.router.navigate(['singlestdaddressforletters'], { relativeTo: this.route });
  }

  CheckStdData() {
    this.router.navigate(['CheckStudentDataComponent'], { relativeTo: this.route })
  }
  onFeeConcession() {
    this.router.navigate(['feeConcession'], { relativeTo: this.route });
  }

  onClassBankRelation() {
    this.router.navigate(['classBankRelation'], { relativeTo: this.route });
  }

  onSchpInfo() {
    this.router.navigate(['schpInfo'], { relativeTo: this.route });
  }

  onSchpAwardList() {
    this.router.navigate(['schpAwardList'], { relativeTo: this.route });
  }

  onSchpAppForm() {
    this.router.navigate(['schpAppForm'], { relativeTo: this.route });
  }

  onSchpAward() {
    this.router.navigate(['schpAward'], { relativeTo: this.route });
  }

  onManualChallan() {
    this.router.navigate(['manualChallan'], { relativeTo: this.route });
  }

  OnMainComponentClick(event: Event) {
    document.getElementById("contentId").classList.remove("show");
    document.getElementById("content_mobile").classList.remove("header-mobile-open");
  }
  onCheckStudentDetail() {
    this.router.navigate(['studentDetail'], { relativeTo: this.route });
  }
  onCheckStudentAdmissionDetail() {
    this.router.navigate(['studentAdmissionDetail'], { relativeTo: this.route });
  }
  onEvaluationForms() {
    this.router.navigate(['EvaluationForms'], { relativeTo: this.route });
  }
  onEvaluationFormReports() {
    this.router.navigate(['FeedbackReports'], { relativeTo: this.route });
  }
  onEvaluationResponseClick() {
    this.router.navigate(['EvaluationResponseDetail'], { relativeTo: this.route });
  }
  onAdmittedForms() {
    this.router.navigate([], { relativeTo: this.route })
  }

  onTeacherSideEvaluationReport() {
    this.router.navigate(['EvaluationReport'], { relativeTo: this.route });
  }
  onLetterGrade() {
    this.router.navigate(['letterGrade'], { relativeTo: this.route });
  }
  onPrintSingleChallan() {
    this.router.navigate(['PrintSingleChallan'], { relativeTo: this.route });
  }
  onChallanDataInsertion() {
    this.router.navigate(['onChallanDataInsertion'], { relativeTo: this.route });
  }
  onNewSubInstall() {
    this.router.navigate(['onNewSubInstall'], { relativeTo: this.route });
  }
  onManualChallanF() {
    this.router.navigate(['onNewManualChallan'], { relativeTo: this.route });
  }
  addActiveListClass(classid: String) {
    let elements = document.getElementsByClassName("list-item");
    for (var i = 0; i < elements.length; i++) {
      if (elements[i].id === classid) {
        elements[i].classList.add("list-item-active");
      }
      else {
        elements[i].classList.remove("list-item-active");
      }
    }
  }
  OnDetailedStudentClick() {
    this.router.navigate(['DetailedStudent'], { relativeTo: this.route });
  }
  OnGetSFAOStudentBudget() {
    this.router.navigate(['sfaoStudentBudget'], { relativeTo: this.route });
  }
  OnLevelWiseStudentStrengthClick() {
    this.router.navigate(['LevelWiseStudentStrength'], { relativeTo: this.route });
  }
  onEvaluationCompileResponse() {
    this.router.navigate(['EvaluationCompile'], { relativeTo: this.route });
  }
  OnEnrocomClick() {
    this.router.navigate(['enrocom'], { relativeTo: this.route });
  }
  OnEnrepoClick() {
    this.router.navigate(['enrepo'], { relativeTo: this.route });
  }
  OnOverallAttendanceClick() {
    this.router.navigate(['OverallAttendanceReport'], { relativeTo: this.route });
  }
  OnSubjectOfferReportClick() {
    this.router.navigate(['offeredsubjectsreport'], { relativeTo: this.route });
  }
  onDownload() {
    let path = './data//40//578//2/A//CA-1201//CourseMaterial//Eligibilty-1640852265477.pdf';
    let nm = 'Eligibility';
    this.fileService.downloadFile(path)
      .subscribe(
        success => {
          saveAs(success, nm);
        },
        err => {
          console.log(err);
          alert('Server error while downloading file.');
        }
      );
  }

  onClickSRMS() {
    let authuserdata2;
    let authuserdata1 = this.authenticationService.getUser();
    this.SRM.fmdetails(authuserdata1.FM_ID).subscribe(res => {

      window.open("http://111.68.103.118:10107?id=" + res, "SRM");
    })
  }

onAdmissionForm() {
this.router.navigate(['Admission-from'], { relativeTo: this.route });



}



}
