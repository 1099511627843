<form #f="ngForm">
    <div class=" container-fluid" [@SlideInFromLeft]>
        <div class="row card shadow">
            <div class="card-header">
                <div>Fee/Fund Refund Reports</div>
            </div>
            <div class="row card-body pb-0">

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="rep">Select Report Type</label>
                    <select #rep class="form-control" name="rep" id="rep" (change)="UpdateRepType()" ngModel required>
                        <option value="1">All Classes</option>
                        <!-- <option value="2">All Classes (All Sessions)</option> -->
                        <option value="3">Class Wise</option>
                        <option value="4">Class Wise (All Sessions)</option>
                        <option value="5">Student Wise</option>
                        <option value="6">Date Wise</option>
                    </select>
                </div>
                <div class="form-group col-lg-4" *ngIf="(rep.value==3)||(rep.value==4)||(rep.value==5)">
                    <label for="sub">Classes</label>
                    <select class="form-control" id="sub" aria-label="ClassCode" ngModel required name="c_code">
                        <option *ngFor="let i of classesArr" value="{{ i.id }}"
                            (keydown.enter)="$event.preventDefault()">
                            {{ i.nm }}
                        </option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 form-group" *ngIf="(rep.value==5)">
                    <label for="actyear">Actual Session <b>(Same for non-repeaters)</b></label>
                    <select class="form-control" #actyear name="actyear" id="actyear" ngModel required>
                        <option *ngFor="let s of sessions" value="{{s}}" (keydown.enter)="$event.preventDefault()">{{s}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 form-group" *ngIf="(rep.value==1)||(rep.value==3)||(rep.value==5)">
                    <label for="Year">Current Session</label>
                    <select class="form-control" #session name="year" id="Year" ngModel required>
                        <option *ngFor="let s of sessions" value="{{s}}" (keydown.enter)="$event.preventDefault()">{{s}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 form-group" *ngIf="!(rep.value==5)">
                    <label for="fine_entry_dt">Start Date</label>
                    <input type="date" [maxLength]="3" class="form-control" name="start_dt" #exDate
                        (change)="StartDate(exDate.value)" required (keydown.enter)="$event.preventDefault()">
                </div>
                <div class="col-lg-4 col-md-6 form-group" *ngIf="!(rep.value==5)">
                    <label for="fine_entry_dt">End Date</label>
                    <input type="date" [maxLength]="3" class="form-control" name="end_dt" #exDate2
                        (change)="EndDate(exDate2.value)" required (keydown.enter)="$event.preventDefault()">
                </div>


                <div class="col-lg-4 col-md-6 form-group" *ngIf="(rep.value==5)">
                    <label for="rn">Roll No</label>
                    <input type="number" min="1" class="form-control" name="rn" ngModel required
                        (keyup)="getStdName($event)" (keydown.enter)="$event.preventDefault()">
                </div>

                <div class="col-lg-4 col-md-6 form-group" *ngIf="(rep.value==5)">
                    <label for="nm">Name</label>
                    <input value="{{name}}" class="form-control" name="nm" disabled ngModel required>
                </div>
                <div class="row card-body" >
                    <button class="btn btn-primary" (click)="onReport(rep.value)" style="margin-top:-10px;margin-left:-5px" [disabled]="!f.valid">
                        Download
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>