import { Component, EventEmitter, ViewChild, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';

declare var $: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.css']
})
export class ChangePasswordComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;

  submitted: boolean = false;
  user = this.authService.getUser();
  hasUpperCase: boolean = false;
  hasLowerCase: boolean = false;
  hasNumber: boolean = false;
  hasSpecialChar: boolean = false;
  isMinLength: boolean = false;
  nic: string = '';

  showPassword: { [key: string]: boolean } = {
    oldpass: false,
    newpass: false,
    confirmpass: false
  };

  constructor(
    private authService: AuthenticationService,
    private toastr: ToastrService
  ) {}

  @Output() close = new EventEmitter<void>();

  onClose() {
    this.close.emit();
  }

  ngOnInit(): void {
    setTimeout(() => {
      $('.backdrop').css('opacity', '1');
    }, 50);
    this.nic = this.user.NIC;
    }

  OnChangeClicked(form: NgForm) {
    this.submitted = true;

    if (form.value.usnm === '' || form.value.oldpass === '' || form.value.newpass === '' || form.value.newpass !== form.value.confirmpass || !this.isPasswordStrong()) {
      this.submitted = false;
      return;
    }

    this.authService.changePassword(form.value).subscribe(
      (res: { status: number }) => {
        this.submitted = false;
        if (res?.status == 1) {
          this.toastr.success("Password Changed Successfully");
          setTimeout(() => {
            this.close.emit();
          }, 1000);
        } else {
          this.toastr.warning("Incorrect Previous Password");
        }
      },
      error => {
        this.toastr.error("Some Error Occurred");
        this.submitted = false;
      }
    );
  }

  checkPasswordStrength(password: string): void {
    this.hasUpperCase = /[A-Z]/.test(password);
    this.hasLowerCase = /[a-z]/.test(password);
    this.hasNumber = /\d/.test(password);
    this.hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    this.isMinLength = password.length >= 8;
  }

  isPasswordStrong(): boolean {
    return this.hasUpperCase && this.hasLowerCase && this.hasNumber && this.hasSpecialChar && this.isMinLength;
  }

  togglePasswordVisibility(field: string): void {
    this.showPassword[field] = !this.showPassword[field];
  }
  
}
