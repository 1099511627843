<div [@SlideInFromLeft] class="backdrop" style="overflow:hidden; overflow-y: scroll">
    <div class="row">
      <div class="col-lg-6 col-md-10 offset-sm-3 ml-2 mr-2 ml-sm-auto mr-sm-auto">
        <div class="alert-box">
          <form [formGroup]="dateForm" class="form">
            <div class="container-fluid mb-2 card" style="padding: 1.5rem;">
                <p>Assign date to auto lock the subject</p>
                <input formControlName = "date" class= "form-control" style="border-radius: 5px; width: 95%; height: 3rem;" type="date">
                <div class="buttons-div">
                <button class="btn btn-primary mt-2"style="width: 100px; height: 40px; margin-left: 30px; text-align: center;" (click)="onClose()">Close</button>
                <button class="btn btn-primary mt-2"style="width: 100px; height: 40px; margin-left: 30px; text-align: center;" (click)="onAssign()">Assign</button>
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>