import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { oldeng } from 'src/font';
import { EvaluationFormReportsServices } from '../evaluation-form-reports/EvaluationFormsReports.services';
import { Team, TimetableService } from '../shared/services/Timetable.service';

@Component({
  selector: 'app-teacher-side-eval-report',
  templateUrl: './teacher-side-eval-report.component.html',
  styleUrls: ['./teacher-side-eval-report.component.css'],
  animations:[
    SlideInFromLeft()
  ]
})
export class TeacherSideEvalReportComponent implements OnInit {
  arr:[{mean:number,per:number}]=[{mean:0,per:0}];
  CharDataCustom:[{secondHeading:string,grade:string, lab:string[], meanArr:number[],perArr:number[]}]=[{secondHeading:'',grade:'',lab:[],meanArr:[],perArr:[]}];
  singleSubRpt:Array<{GRADE:string, MEAN: string, PERCENTAGE: string,QUES_ID: number, SHORT_DES:string, STAND_DEV:number, TOTALSTUDENT:number, TOTALRESPONSEOPTIONS:number}>;
  grade:string = '';

  usr=null;
  classes: { c_code: string, c_nm: string }[];
  teams: Team[];
  tm: Team;  

    constructor(private toaster: ToastrService,public datepipe: DatePipe,private timetableService: TimetableService,
      private authenticationService: AuthenticationService,private ev_f_repSer:EvaluationFormReportsServices) {
      this.usr=this.authenticationService.getUser();
      this.singleSubRpt=[];
    }
  
    ngOnInit(): void {
      this.classes = this.timetableService.getClasses();
      if (this.classes.length > 0) {
        this.loadTeams(parseInt(this.classes[0].c_code));
      } 
    }

    loadTeams(c_code: number) {
      this.teams = this.timetableService.getTeam(c_code);
      if (this.teams?.length > 0) {
        this.tm = this.teams[0];
      }
      else {
        this.tm = null;
      }
    }
    OnClassChange(c: HTMLSelectElement) {
      this.loadTeams(parseInt(c.value));
    }
    OnCourseChange(c: HTMLSelectElement) {
      this.tm = this.teams[parseInt(c.value)];
      this.singleSubRpt=[];
    }
  // fm_id,t_no,section,sub_code
  onGetReport(){
    this.singleSubRpt=[];
    if(this.tm==null) return;
      this.ev_f_repSer.EV_F_getSingleSubjectReport({fm_id:this.usr.FM_ID,t_no:this.tm.T_NO,section:this.tm.SECTION,sub_code:this.tm.SUB_CODE}).subscribe((res:{GRADE:string,MEAN: string,PERCENTAGE: string,QUES_ID: number,SHORT_DES:string,STAND_DEV:number,TOTALSTUDENT:number,TOTALRESPONSEOPTIONS:number}[]) =>{
        if(res?.length==0){
          this.toaster.warning('Data Not Found.');
          return;
        }
        res?.forEach(e =>{
          this.singleSubRpt.push({QUES_ID:e.QUES_ID,MEAN: e.MEAN,SHORT_DES:e.SHORT_DES,PERCENTAGE:e.PERCENTAGE,GRADE:e.GRADE,STAND_DEV:e.STAND_DEV, TOTALSTUDENT:e.TOTALSTUDENT,TOTALRESPONSEOPTIONS:e.TOTALRESPONSEOPTIONS})
// line chart settings and data addition
          this.CharDataCustom[0].lab.push(e.SHORT_DES);
          this.CharDataCustom[0].meanArr.push(parseFloat(e.MEAN));
          this.CharDataCustom[0].perArr.push(parseFloat(e.PERCENTAGE));
        })
// pie chart settings and data push
        this.arr = this.findTotalMean();
        this.grade = this.ev_f_repSer.getGrade(this.arr[0]?.per);
// passing both charts data to the object and passing to child comp.
        this.CharDataCustom = [{secondHeading:"QUESTION WISE REPORT",grade:this.grade,lab:this.CharDataCustom[0].lab,meanArr:this.CharDataCustom[0].meanArr, perArr:this.CharDataCustom[0].perArr}];
      })
  }
  findTotalMean():[{mean:number,per:number}]{
    let totalMean:number=0;
    let totalPercentage:number=0;
    totalMean = this.singleSubRpt.reduce((pre, cur) => { return (pre + parseFloat(cur.MEAN)) }, 0)
    totalPercentage = this.singleSubRpt.reduce((prePer, curPer) => (prePer + parseFloat(curPer.PERCENTAGE)),0);
    
    totalMean = (totalMean/this.singleSubRpt?.length);
    totalPercentage = (totalPercentage/(this.singleSubRpt?.length*100))*100;

    return [{mean:parseFloat(totalMean.toFixed(4)),per:parseFloat(totalPercentage.toFixed(4))}];
  }

  createPdf() {
    if (this.singleSubRpt.length == 0) return;
    let body = [];
    let header = [];
    let nm=this.usr?.NM;
    this.toaster.info("Downloading");
    let image = new Image();
    image.src = "../../../assets/images/logo3.png";
    const doc = new jsPDF("p", "mm", [297, 210]);
    doc.addImage(image, 180, 2, 26, 26);
    let exportDate = this.datepipe.transform(new Date(), "MMM d, y, h:mm:ss a");
    doc.setFontSize(20);
    doc.addFileToVFS('OLDENGL.ttf', oldeng);
    doc.addFont('OLDENGL.ttf', 'OLDENGL', 'bold');
    doc.setFont('OLDENGL', "bold");
    doc.text("GC University Lahore", 100, 10, { align: 'center' });
    doc.setFontSize(14);
    doc.setFont("Arial", "bold");
    doc.text(`Name of Instructor : ${this.usr?.NM}`, 10, 18);
    doc.text(`Course Code & Title : (${this.tm.SUB_CODE}) - ${this.tm.SUB_NM}`, 10, 25);
    doc.text(`Total Mean : ${this.arr[0]?.mean.toFixed(4)}`, 10, 32);
    doc.text(`%Age : ${this.arr[0]?.per.toFixed(2)}%`, 70, 32);
    doc.text(`Grade : ${this.grade}`, 130, 32);
    doc.setFont("Arial", "normal");

    header.push();
        header = [['Sr. No.', "Short Description","Filled By", "Mean", "Standard Deviation", "%Age", 'Grade']]
        this.singleSubRpt.forEach((entry, i)=>{
          body.push([
          (i+1),
          entry.SHORT_DES,
          entry.TOTALSTUDENT,
          entry.MEAN,
          entry.STAND_DEV,
          entry.PERCENTAGE+'%',
          entry.GRADE
          ]);
        })
        autoTable(doc, {
          theme: "grid",
          margin: {top:40, bottom: 15, left: 10, right: 10  },
          head: header,
          body: body,
          didDrawPage: function (d) {
            doc.setFontSize(10);
            doc.text(`PRINTED BY: ${nm}`, 10, 286);
            doc.text("Note:- Errors and Omissions are Excepted", 10, 290);
            doc.text(`${exportDate}`, 159, 286);
            doc.text("Directorate of Information Technology", 143, 290);
          },
        });
    let a = this.toaster;
    setTimeout(function () {
      a.success("Downloaded");
    }, 1000);
    this.toaster.success("PDF Download Successfully");
    doc.setProperties({
      title: `Teacher Feedback Report`      
    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }
}