<div [@SlideInFromLeft] class="tabber">
    <div class="container-fluid card shadow">
        <div class="card-header">
            <div class="over">
                <div class="loader"></div>
                <!-- Loading... -->
            </div>
            <div>Student Addresses</div>
        </div>
        <form #f="ngForm" class="row">
            <div class="row card-body space-remover">
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="c_code">Class</label>
                    <select #c_code class="form-control" ngModel name="c_code" id="c_code" required
                        (change)="OnClassChange()">
                        <option *ngFor="let i of class" value="{{i.id}}">{{i.nm}} </option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="maj_id" class="text-left">Major
                    </label>
                    <select #maj_id id="maj_id" name="maj_id" ngModel class="form-control">
                        <option *ngFor="let i of majors" value="{{i.id}}">{{i.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                    <label for="notifDate">Start Date Notification</label>
                    <input type="date" #notifDate class="form-control" ngModel name="notifDate" id="notifDate" required
                        ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                    <label for="notifDate1">End Date Notification</label>
                    <input type="date" #notifDate class="form-control" ngModel name="notifDate1" id="notifDate1"
                        required ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="category">Degree Category</label>
                    <select #category class="form-control" name="category" ngModel id="category" required
                        (change)="OnCategoryChange()">
                        <option>Regular</option>
                        <option>Urgent</option>
                        <option>Duplicate</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="start_rn">Start Roll Number</label>
                    <select #start_rn id="start_rn" name="start_rn" ngModel class="form-control"
                        (change)="OnStartRnChange(start_rn)">
                        <option *ngFor="let i of StudentDetails" value="{{i.ROLNO}}">{{i.ROLNO}}</option>
                    </select>
                    <!-- <p style="color:red;">Please click on it!</p> -->
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="end_rn">End Roll Number</label>
                    <select #endRollNumber id="endRollNumber" name="endRollNumber" ngModel class="form-control"
                        (change)="OnEndRnChange(start_rn,endRollNumber)">
                        <option *ngFor="let i of ending_array" value="{{i.ROLNO}}">{{i.ROLNO}}</option>
                    </select>
                </div>
                <!-- <div class="col-lg-4 form-group">
                    <label for="se_st">Session Start</label>
                    <input type="number" #se_st class="form-control" min="0" name="se_st" id="se_st" ngModel>
                </div> -->
                <!-- <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="se_st_year">Year</label>
                    <input type="number" #se_st_year class="form-control" min="0" name="se_st_year" id="se_st_year" placeholder="Admission Year" ngModel>
                </div> -->

                <!-- <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="se_id">Session</label>

                    <select #se_id class="form-control" name="se_id" id="se_id" required>
                        <option >Session</option>
                    </select>

                </div> -->


                <!-- <div class="col-lg-3 form-group">
                    <label for="se_st_year">Session Start Year</label>
                    <input type="number" #se_st_year class="form-control" name="se_st_year" id="se_st_year" ngModel>
                </div>

                <div class="col-lg-3 form-group">
                    <label for="se_e_year">Session End Year</label>
                    <input type="number" #se_e_year class="form-control" name="se_e_year" id="se_e_year" ngModel>
                </div> -->

                <!-- <div class="col-lg-3 form-group">
                    <label for="se_id">Session</label>
                    <select class="form-control" #se_id name="se_id" id="se_id" ngModel>
                        <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
                    </select>
                </div> -->

                <!-- <div class="col-lg-4 col-md-6 col-sm-12 form-group mt-2">
                    <label></label>
                    <input type="text" class="form-control" name="" id="" placeholder="Name" readonly>
                </div> -->
                <!-- <div class="col-lg-4 form-group mt-2">
                    <label></label>
                    <input type="text" class="form-control" name="" id="" placeholder="Roll Number" value="{{this.st_rn}}" readonly>
                </div> -->

                <!-- <div class="col-lg-3 col-md-4 col-sm-6 form-group">
                    <label for="passYear">Passing Year</label>
                    <input type="number" #passYear class="form-control" name="passYear" id="passYear" required ngModel>
                </div> -->

                <div class="test ml-3 d-flex flex-column" style="margin-top:28px">
                    <!-- [disabled]="!f.valid" -->
                    <button class="btn btn-outline-danger" type="button" (click)="OnPdfCreate()" [disabled]="!f.valid">
                        <i class="fa fa-file-pdf-o" for=""></i> Create Pdf
                    </button>
                </div>
                <div class="test ml-3">
                    <!-- <button [disabled]="!f.valid" class="btn btn-outline-primary btn-sm" type="submit">Generate Trans.
                    </button> -->
                    <button class="btn btn-warning ml-1" style="margin-top:28px" (click)="onClearForm(f)"
                        type="button">Clear Form
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>