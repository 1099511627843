import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from "@angular/forms";
import { FaqService } from '../../main/shared/services/faq.service';
import { AuthenticationService } from 'src/app/auth/_services';

@Component({
  selector: 'app-faq-modal',
  templateUrl: './faq-modal.component.html',
  styleUrls: ['./faq-modal.component.css']
})

export class FaqModalComponent {
  @ViewChild("f") formRef: NgForm;
  @Input() isOpen: boolean = false;
  openQuestionIndex: number | null = null;
  isAdmin: boolean;
  faqs: Array<{question, answer, role_index}> = [];

  constructor(private faqService: FaqService,
    private authenticationService: AuthenticationService
  ) {
    this.isAdmin = authenticationService.isAdmin();
  }

  ngOnInit() {
    this.getFaq();
    this.isAdmin = this.authenticationService.isAdmin();
  }

  toggleFaq(index: number) {
    if (this.openQuestionIndex === index) {
      this.openQuestionIndex = null;  // Close the question if it's already open
    } else {
      this.openQuestionIndex = index; // Open the clicked question
    }
  }

  closeModal() {
    this.isOpen = false;
  }

  openAddFaqModal(): void {
    const modal = document.getElementById('addFaqModal');
    if (modal) {
      modal.style.display = 'block';
    }
  }

  closeAddFaqModal(): void {
    const modal = document.getElementById('addFaqModal');
    if (modal) {
      modal.style.display = 'none';
    }
    this.getFaq();
  }

  getFaq(){
    this.faqs = []
    this.faqService.getFaqs().subscribe((res:{question, answer, role_index}[])=>{
      res?.forEach((entry)=>{
        this.faqs.push({question:entry.question, answer:entry.answer,role_index:entry.role_index})
      }
      );
    });
  }

  addFaq() {
    this.faqService.addFaq(this.formRef.value);
    this.getFaq();
  }

  onModalClose() {
    this.getFaq(); // Call getFaq when modal is closed
  }
}
