<div class="row p-2" [@SlideInFromLeft]>
  <div class="col-sm-12">
    <div class="btn-group" role="group" aria-label="Basic example">
      <button type="button" class="btn btn-outline-info" (click)="onExportForm()">Export Data</button>
      <button type="button" class="btn btn-outline-info" (click)="onImportForm()">Import Data</button>
    </div>
  </div>
</div>

<form (ngSubmit)="OnSubmit()" #f class="show" id="export" name="form">
  <div [@SlideInFromLeft] class="tabber">
    <div class="container-fluid mb-2 card shadow">
      <div class="card-header   d-flex justify-content-between">
        <div class="">Data Export</div>
      </div>
      <div class="row card-body mt-3 space-remover">
        <div class="col-lg-6 form-group">
          <label for="tn">Table Name</label>
          <select #tn class="form-control" name="tn" id="tn" ngModel required>
            <option *ngFor="let tb of arr" value="{{tb.tb_id}}">{{tb.tb_id}}</option>
          </select>
        </div>
      </div>

      <div class="row card-body mt-3 space-remover">
        <div class="col-lg-6 form-group">
          <label for="dt">From Date : </label>
          <input type="date" #cource class="form-control " name="dt" id="dt" required ngModel>
        </div>

        <div class="col-lg-6 form-group">
          <label for="t_to">To Date :</label>
          <input type="date" class="form-control " #session name="t_to" id="t_to" ngModel>
        </div>
      </div>
      
      <div class="row mt-3">
      <button class="btn btn-default csv" type="submit()">View Data</button>
      <button class="btn btn-default xls" type="button" (click)="downloadCSV()">Export to xls</button>
      <button class="btn btn-default txt"  type="button" (click)="onButtonClick()">Write Query</button>
      </div>

      <div id="hid" class="hide" >
        <textarea #inty id="mun" name="mun" class="mun" ngModel rows="5" cols="60"></textarea>
        <textarea #inty id="mu" name="mu" class="mu" ngModel rows="5" cols="60" placeholder="RESULT" readonly></textarea>
        <div>
      <button type="button" (click)='OnGetQuery()' class="inH btn btn-outline-primary">Execute</button>
      </div>
        </div>

      <table class="table table-bordered bg-light mt-4" id="tableID">
        <th *ngFor="let t of col">
          {{t}}
        </th>
        <tr *ngFor="let i of content">
           <td *ngFor="let k of i">
             {{k}}
           </td> 
        </tr>
      </table>

    </div>
  </div>
</form>

<form (ngSubmit)="OnSubmit()" #e class="hide" id="import" name="import">
  <div [@SlideInFromLeft] class="tabber">
    <div class="container-fluid mb-2 card shadow">
      <div class="card-header   d-flex justify-content-between">
        <div class="">Data Import</div>
      </div>
      <div class="row card-body mt-3 space-remover">
        <div class="col-lg-6 form-group">
          <label for="tna">Table Name</label>
          <select #tn class="form-control" name="tn" id="tn" ngModel="check" required (click)='onTabCol(e)'>
            <option *ngFor="let tb of arr" value="{{tb.tb_id}}">{{tb.tb_id}}</option>
          </select>
        </div>
      </div>
      <div class="card-header">
        Columns
      </div>
      <div class="card-body" style="justify-content: space-between;">

        <div   *ngFor="let t of col" style="display: flex; justify-content: space-between;">
          <label for="tabCol" >
            <input type="checkbox" ng-checked="check" class="check" checked >
              {{t}}
              <br>
            </label>
        </div>
    </div>
    </div>
  </div>
</form>