<form #fn="ngForm">
    <div class="container-fluid" [@SlideInFromLeft]>
        <div class="row card shadow " style="min-height: 40vh;">
            <div class="card-header">
                Student Admission Detail
            </div>

            <div class="card-body" style="margin-top: -10px">
                <div class="row">
                    <div class="col-lg-4 col-md-6 form-group">
                        <label for="C_Code">Class</label>
                        <select #c_code (change)="OnClassChange()" class="form-control " name="c_code" id="c_code"
                            ngModel required>
                            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
                        </select>
                    </div>

                    <div class="col-lg-4 col-md-6 form-group">
                        <label for="se_id">Session</label>
                        <select #se_id class="form-control " name="se_id" id="se_id" (change)="loadRollno()" required
                            ngModel>
                            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
                        </select>
                    </div>

                    <div class="col-lg-4 col-md-4 form-group">
                        <label for="rolno" class="text-left">Complete Roll Number</label>
                        <select #rolno class="form-control" (click)="OnRollChange()" name="rolno" id="rolno"
                             ngModel required>
                            <option *ngFor="let s of rolnoArr" value="{{s.nm}}">{{s.nm}}</option>
                        </select>
                    </div>

                    <div class="col-lg-4 col-md-6 form-group">
                        <label for="f_no">Form No.</label>
                        <input #f_no type="number" (click)="OnF_NoChange()" class="form-control" name="f_no" id="f_no" value="f_no" ngModel required>
                    </div>

                    <div class="col-lg-4 col-md-6 form-group">
                        <label for="ch_no">Challan No.</label>
                        <input #ch_no type="number" (click)="OnCha_NoChange()" class="form-control" name="ch_no" id="ch_no" value="ch_no" ngModel
                            required="">
                    </div>
                </div>
                <div class="row">
                    <div class="row mt-2 ml-3">
                        <button type="button" class="btn btn-sm btn-primary mt-1 " (click)="getAdmissionDetail(rolno, f_no, ch_no)"
                            style="height: calc(1.5em + .75rem + 2px);">
                            Get Admission Detail
                        </button>
                    </div>
                    <div class="row mt-2 ml-3 pl-1">
                        <button type="button" class="btn btn-sm btn-primary mt-1 " (click)="getFeeDetails(rolno, f_no, ch_no)"
                            style="height: calc(1.5em + .75rem + 2px);">
                            Get Fee Detail
                        </button>
                    </div>

                    <div class="row mt-2 ml-3 pl-1">
                        <button type="button" class="btn btn-sm btn-primary mt-1 " (click)="getAcademicDetails(rolno)"
                            style="height: calc(1.5em + .75rem + 2px);">
                            Get Academic Detail
                        </button>
                    </div>
                    <!-- <div class="row mt-2 ml-3 pl-1">
                        <button type="button" [disabled]="!this.getDetBtn" class="btn btn-sm btn-primary mt-1 "
                            style="height: calc(1.5em + .75rem + 2px);">
                            Get Academic Detail
                        </button>
                    </div> -->
                </div>
            </div>


            <div *ngIf="showAcademicTable" style="margin: 10px;">

                <ng-container *ngFor="let key of keys()">
                    <h2 class="ml-3"> Semester-{{ key }}</h2>

                    <table class="table table-hover">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col">Subject Code</th>
                                <th scope="col">Subject Name</th>
                                <th scope="col">Grade</th>
                                <th scope="col">Attendance</th>

                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let item of AcademicDetail[key].SUB_CODE; let i = index">
                                <td>{{ AcademicDetail[key].SUB_CODE[i] }}</td>
                                <td>{{ AcademicDetail[key].SUB_NM[i] }}</td>
                                <td>{{ AcademicDetail[key].GRD[i] }}</td>
                                <td>{{ AcademicDetail[key].PER[i] }} %</td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

            </div>


            <div *ngIf="showAdmissionDetail">

                <div class="card-header shadow">
                    <div>Personal Information
                    </div>
                </div>

                <!-- <div class="row card-body pb-0" *ngIf="this.me==true"> -->
                <div class="row card-body pb-0" *ngFor="let a of admissionDetail">
                    <div class="col-lg-4 col-md-6 form-group">
                        <div *ngIf="a.nm; then thenStdtNmBlk else elseStdtNmBlk" class="row card-body pb-0"></div>
                        <ng-template #thenStdtNmBlk>
                            <label for="stdt_nm1">Student Name</label>
                            <input type="text" #stdt_nm class="form-control" id="ali1" name="stdt_nm1" value="0" min="1"
                                readonly ngModel="{{a.nm}}">
                        </ng-template>
                        <ng-template #elseStdtNmBlk>
                            <label for="stdt_nm">Student Name</label>
                            <input type="text" #stdt_nm class="form-control" id="ali" name="stdt_nm" min="1" ngModel>
                        </ng-template>
                    </div>

                    <div class="col-lg-4 col-md-6 form-group">
                        <div *ngIf="a.f_nm; then thenStdtFNmBlk else elseStdtFNmBlk" class="row card-body pb-0"></div>
                        <ng-template #thenStdtFNmBlk>
                            <label for="f_nm">Father Name</label>
                            <input type="text" #f_nm class="form-control" name="f_nm" min="1" readonly value="0"
                                ngModel="{{a.f_nm}}">
                        </ng-template>
                        <ng-template #elseStdtFNmBlk>
                            <label for="f_nm">Father Name</label>
                            <input type="text" #f_nm class="form-control" name="f_nm" value={{a.f_nm}} min="1" ngModel>
                        </ng-template>
                    </div>

                    <div class="col-lg-4 col-md-6 form-group">
                        <div *ngIf="a.nic; then thenStdtNicBlk else elseStdtNicBlk" class="row card-body pb-0"></div>
                        <ng-template #thenStdtNicBlk>
                            <label for="s_nic">Student NIC</label>
                            <input type="text" #s_nic1 class="form-control" name="s_nic" min="1" readonly value="0"
                                ngModel="{{a.nic}}">
                        </ng-template>
                        <ng-template #elseStdtNicBlk>
                            <label for="s_nic">Student NIC</label>
                            <input type="text" #s_nic class="form-control" name="s_nic" value={{a.nic}} min="1" ngModel>
                        </ng-template>
                    </div>


                    <div class="col-lg-4 col-md-6 form-group">
                        <div *ngIf="a.ph1; then thenStdtPhBlk else elseStdtPhBlk" class="row card-body pb-0"></div>
                        <ng-template #thenStdtPhBlk>
                            <label for="ph_no">Phone Number</label>
                            <input type="number" #ph_no class="form-control" name="ph_no" min="1" readonly value="0"
                                ngModel="{{a.ph1}}">
                        </ng-template>
                        <ng-template #elseStdtPhBlk>
                            <label for="ph_no">Phone Number</label>
                            <input type="number" #ph_no class="form-control" name="ph_no" value={{a.ph1}} min="1"
                                ngModel>
                        </ng-template>
                    </div>

                    <div class="col-lg-4 col-md-6 form-group">
                        <div *ngIf="a.email; then thenStdtEmailBlk else elseStdtEmailBlk" class="row card-body pb-0">
                        </div>
                        <ng-template #thenStdtEmailBlk>
                            <label for="email">Email</label>
                            <input type="email" #email class="form-control" name="email" min="1" required readonly
                                value="0" ngModel="{{a.email}}">
                        </ng-template>
                        <ng-template #elseStdtEmailBlk>
                            <label for="email">Email</label>
                            <input type="email" #email class="form-control" name="email" value={{a.email}} min="1"
                                required ngModel>
                        </ng-template>
                    </div>

                    <div class="col-lg-4 col-md-6 form-group">
                        <div *ngIf="a.gender; then thenStdtGndrBlk else elseStdtGndrBlk" class="row card-body pb-0">
                        </div>
                        <ng-template #thenStdtGndrBlk>
                            <label for="gender">Gender</label>
                            <input type="gender" #gender class="form-control" name="gender" min="1" required readonly
                                value="0" ngModel="{{a.gender}}">
                        </ng-template>
                        <ng-template #elseStdtGndrBlk>
                            <label for="gender">Gender</label>
                            <input type="gender" #gender class="form-control" name="gender" value={{a.gender}} min="1"
                                required ngModel>
                        </ng-template>
                    </div>

                    <div class="col-lg-4 col-md-6 form-group">
                        <div *ngIf="a.relig; then thenStdtReligBlk else elseStdtReligBlk" class="row card-body pb-0">
                        </div>
                        <ng-template #thenStdtReligBlk>
                            <label for="relig">Religon</label>
                            <input type="relig" #relig class="form-control" name="relig" min="1" required readonly
                                value="0" ngModel="{{a.relig}}">
                        </ng-template>
                        <ng-template #elseStdtReligBlk>
                            <label for="relig">Religon</label>
                            <input type="relig" #relig class="form-control" name="relig" value={{a.relig}} min="1"
                                required ngModel>
                        </ng-template>
                    </div>

                    <div class="col-lg-8 col-md-10 form-group" style="width: max-content; height: max-content;">
                        <div *ngIf="a.add1; then thenStdtAddBlk else elseStdtAddBlk" class="row card-body pb-0"></div>
                        <ng-template #thenStdtAddBlk>
                            <label for="addr">Address</label>
                            <input type="text" #addr class="form-control" name="addr" min="1" required readonly
                                value="0" ngModel="{{a.add1}}">
                        </ng-template>
                        <ng-template #elseStdtAddBlk>
                            <label for="addr">Address</label>
                            <input type="text" #addr class="form-control" name="addr" value={{a.add1}} min="1" required
                                ngModel>
                        </ng-template>
                    </div>
                </div>

                <div class="card-header shadow">
                    <div>Educational Information
                    </div>
                </div>

                <div class="row card-body pb-0" *ngFor="let a of admissionDetail">

                    <div class="col-lg-4 col-md-6 form-group">
                        <div *ngIf="a.deptt; then thenStdtDepttBlk else elseStdtDepttBlk" class="row card-body pb-0">
                        </div>
                        <ng-template #thenStdtDepttBlk>
                            <label for="deptt">Department</label>
                            <input type="text" #deptt class="form-control" name="deptt" min="1" readonly value="0"
                                ngModel="{{a.deptt}}">
                        </ng-template>
                        <ng-template #elseStdtDepttBlk>
                            <label for="deptt">Department</label>
                            <input type="text" #deptt class="form-control" name="deptt" value={{a.deptt}} min="1"
                                ngModel>
                        </ng-template>
                    </div>


                    <div class="col-lg-4 col-md-6 form-group">
                        <div *ngIf="a.class; then thenStdtClsBlk else elseStdtClsBlk" class="row card-body pb-0">
                        </div>
                        <ng-template #thenStdtClsBlk>
                            <label for="class">Class</label>
                            <input type="text" #class class="form-control" name="class" min="1" readonly value="0"
                                ngModel="{{a.class}}">
                        </ng-template>
                        <ng-template #elseStdtClsBlk>
                            <label for="class">Class</label>
                            <input type="text" #class class="form-control" name="class" value={{a.class}} min="1"
                                ngModel>
                        </ng-template>
                    </div>

                    <div class="col-lg-4 col-md-6 form-group">
                        <div *ngIf="a.session; then thenStdSetBlk else elseStdtSeBlk" class="row card-body pb-0">
                        </div>
                        <ng-template #thenStdSetBlk>
                            <label for="session">Session</label>
                            <input type="text" #session class="form-control" name="session" min="1" readonly value="0"
                                ngModel="{{a.session}}">
                        </ng-template>
                        <ng-template #elseStdtSeBlk>
                            <label for="session">Session</label>
                            <input type="text" #session class="form-control" name="session" value={{a.session}} min="1"
                                ngModel>
                        </ng-template>
                    </div>

                    <div class="col-lg-8 col-md-6 form-group">
                        <div *ngIf="a.major; then thenStdMajtBlk else elseStdtMajBlk" class="row card-body pb-0">
                        </div>
                        <ng-template #thenStdMajtBlk>
                            <label for="major">Major</label>
                            <input type="text" #major class="form-control" name="major" min="1" readonly value="0"
                                ngModel="{{a.major}}">
                        </ng-template>
                        <ng-template #elseStdtMajBlk>
                            <label for="major">Major</label>
                            <input type="text" #major class="form-control" name="major" value={{a.major}} min="1"
                                ngModel>
                        </ng-template>
                    </div>

                    <div class="col-lg-4 col-md-6 form-group">
                        <div *ngIf="a.rolno; then thenStdRntBlk else elseStdtRnBlk" class="row card-body pb-0">
                        </div>
                        <ng-template #thenStdRntBlk>
                            <label for="rolno">Roll No</label>
                            <input type="text" #rolno class="form-control" name="rolno" min="1" readonly value="0"
                                ngModel="{{a.rolno}}">
                        </ng-template>
                        <ng-template #elseStdtRnBlk>
                            <label for="rolno">Roll No</label>
                            <input type="text" #rolno class="form-control" name="rolno" value={{a.rolno}} min="1"
                                ngModel>
                        </ng-template>
                    </div>
                </div>

            </div>



            <div *ngIf="showFeeDetail">

                <div class="card-header shadow">
                    <div>Fee Details
                    </div>
                </div>

                <div class="row card-body pb-0" *ngFor="let a of oneTime">

                    <div class="col-lg-12 col-md-10 col-sm-6">
                        <div class="card-header">
                            Paid Challans
                        </div>
                        <div>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Challan#</th>
                                        <th scope="col">Inst-No</th>
                                        <th scope="col">Due Date</th>
                                        <th scope="col">Paid Date</th>
                                        <th scope="col">Total Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let e of paidChallan">
                                        <td>{{e.ch_no}}</td>
                                        <td>{{e.inst_no}}</td>
                                        <td>{{e.due_dt}}</td>
                                        <td>{{e.paid_date}}</td>
                                        <td>{{e.debit}}</td>
                                    </tr>
                                    <tr>
                                        <th colspan="4" scope="row" style="text-align: center;">Total</th>
                                        <th scope="row">{{totalPaidChallans()}}</th>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-10 col-sm-6">
                        <div class="card-header">
                            Unpaid Challans
                        </div>
                        <div>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Challan#</th>
                                        <th scope="col">Inst-No</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Due Date</th>
                                        <th scope="col">Total Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let e of unpaidChallans">
                                        <td>{{e.ch_no}}</td>
                                        <td>{{e.inst_no}}</td>
                                        <td>{{e.des}}</td>
                                        <td>{{e.due_dt}}</td>
                                        <td>{{e.debit}}</td>
                                    </tr>
                                    <tr>
                                        <th colspan="4" scope="row" style="text-align: center;">Total</th>
                                        <th scope="row">{{totalUnpaidChallans()}}</th>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-10 col-sm-6">
                        <div class="card-header">
                            OutStanding Dues
                        </div>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Challan#</th>
                                    <th scope="col">Entry Date</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Tuition Fee</th>
                                    <th scope="col">Misc(REG)</th>
                                    <th scope="col">Misc(SSP)</th>
                                    <th scope="col">Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <tr *ngFor="let e of outStndDues">
                                    <td>{{e.ch_no}}</td>
                                    <td>{{e.fine_entry_date}}</td>
                                    <td>{{e.des}}</td>
                                    <td>{{e.due_dt}}</td>
                                    <td>{{e.debit}}</td>
                                    <td>{{e.debit}}</td>
                                    <td>{{e.debit}}</td>
                                </tr> -->
                                <tr>
                                    <th colspan="6" scope="row" style="text-align: center;">Total</th>
                                    <th scope="row">{{totalOutstdDues()}}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-lg-12 col-md-10 col-sm-6">
                        <div class="card-header">
                            Fee Refund
                        </div>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Entry Date</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Ttution Fee</th>
                                    <th scope="col">Library Sec</th>
                                    <th scope="col">Exam Fee</th>
                                    <th scope="col">Hostel Sec</th>
                                    <th scope="col">Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <tr *ngFor="let e of feeRefund">
                                    <td>{{e.fine_entry_date}}</td>
                                    <td>{{e.des}}</td>
                                    <td>{{e.des}}</td>
                                    <td>{{e.due_dt}}</td>
                                    <td>{{e.debit}}</td>
                                    <td>{{e.debit}}</td>
                                    <td>{{e.debit}}</td>
                                </tr> -->
                                <tr>
                                    <th colspan="6" scope="row" style="text-align: center;">Total</th>
                                    <th scope="row">{{totalFeeRefund()}}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-lg-12 col-md-10 col-sm-6">
                        <div class="card-header">
                            DUES ADJUSTMENT/ FEE CONCESSION/ SCHOLARSHIP
                        </div>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Challan#</th>
                                    <th scope="col">Inst-No</th>
                                    <th scope="col">Dues Adjustment Description</th>
                                    <th scope="col">Fee Concession Description</th>
                                    <th scope="col">Fee Conc Entry Date</th>
                                    <th scope="col">Scholarship Description</th>
                                    <th scope="col">Schp Entry Date</th>
                                    <th scope="col">Dues Adj </th>
                                    <th scope="col">Fee Conc</th>
                                    <th scope="col">Schp</th>
                                    <th scope="col">Concession Amount </th>
                                    <th scope="col">Actual Amount</th>
                                    <th scope="col">Payable Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let e of schpFeeConcDuesAdj">
                                    <td>{{e.ch_no}}</td>
                                    <td>{{e.inst_no}}</td>
                                    <td>{{e.dues_adj_des}}</td>
                                    <td>{{e.fee_conc_des}}</td>
                                    <td>{{e.fee_conc_dt}}</td>
                                    <td>{{e.schp_des}}</td>
                                    <td>{{e.schp_dt}}</td>
                                    <td>{{e.dues_adj_amnt}}</td>
                                    <td>{{e.fee_conc_amnt}}</td>
                                    <td>{{e.schp_dues_amnt}}</td>
                                    <td>{{e.fee_conc_amnt}}</td>
                                    <td>{{e.ActAmnt}}</td>
                                    <td>{{e.payableAmnt}}</td>
                                </tr>
                                <tr>
                                    <th colspan="7" scope="row" style="text-align: center;">Total</th>
                                    <th scope="row">0</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>