<div [@SlideInFromLeft]>
  <div class="container-fluid card shadow">
    <div class="card-header"  style="padding-top: 10px;">
      <div class=" col-lg-6 col-md-6 form-group">
        Offer Subject</div>
         <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
        <app-details-button [details]="details"></app-details-button>
    </div>
  </div>>
    <form (ngSubmit)="OnSubmit()" #f="ngForm">
      <div class="row card-body .space-remover mb-4">

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Day">Group</label>
          <input *ngIf="!isAdmin&&!isExam  && this.isPhilosophy!=410  && this.isPhilosophy!=320"
            type="text" class="form-control"
            value='{{ ((this.groups.length>0) ? groups[0].nm:" ")}}' name="grp"
            id="grp" readonly>
          <select *ngIf="isAdmin||isExam" #grp class="form-control" name="grp"
            id="grp" (change)="OnGrpChange(grp)"
            ngModel required>
            <option *ngFor="let grp of groups"
              value="{{grp.id}}">{{grp.nm}}</option>
          </select>
          <select *ngIf="this.isPhilosophy==410 || this.isPhilosophy==320" #grp class="form-control"
            name="grp" id="grp" (change)="OnGrpChange(grp)" ngModel>
            <option *ngFor="let grp of groups"
              value="{{grp.id}}">{{grp.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="c_code">Class</label>
          <select id="c_code" #cource (change)="OnClassChange(cource)"
            name="c_code" class="form-control" ngModel>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="maj_id">Major</label>
          <select id="maj_id" name="maj_id" class="form-control" #major
            (change)="OnMajorChange(major)" ngModel>
            <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="se_id">Session</label>
          <select (change)="OnSessionChange(session)"
            (focus)="loadMajors(session)" class="form-control" name="se_id"
            id="se_id" #session ngModel>
            <option *ngFor="let s of sessions"
              value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="semester">Semester</label>
          <select #semester (change)="OnSemesterChange(semester)"
            class="form-control " name="t_no" id="Semester"
            required ngModel>
            <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]"
              value="{{i}}">{{i}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <!-- <label for="set_no">Set Number</label> -->
          <label for="set_no">Set Number/Subject Combination</label>
          <select #set id="set_no" name="set_no" class="form-control" required
            ngModel>
            <option *ngFor="let d of sub_comb"
              value="{{d.SET_NO}}">{{d.SUB_COMB}}</option>
          </select>
          <!-- <input #set type="number" class="form-control" id="set_no_in" [ngModel]="1" name="set_no_in" required ngModel> -->
        </div>

        <div *ngIf="this.isAdmin||this.isExam"
          class="col-lg-4 col-md-6 form-group">
          <label for="Department">Department</label>
          <select #department (change)="OnDeptChange(department.value)"
            id="d_id" name="d_id" class="form-control"
            ngModel>
            <option *ngFor="let d of departments"
              value="{{d.id}}">{{d.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="sub_code">Subject Code</label>
          <select #subject class="form-control"
            (change)="OnSubChange(subject.value)" id="sub_code" name="sub_code"
            required ngModel>
            <option *ngFor="let sub of subjects"
              value="{{sub.subCode}}">{{sub.subCode}}-{{sub.subName}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="cr_h">Credit Hr</label>
          <input type="number" class="form-control" id="cr_h" name="cr_h"
            [ngModel]="this.CR_H" ngModel required
            readonly>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="pr_ord">Print Order</label>
          <input #pr_ord type="number" class="form-control"
            [ngModel]="this.latest_pre_order"
            (change)="OnPriOrderInsert(pr_ord.value)" id="pr_ord" name="pr_ord"
            min="0" ngModel required>
          <small *ngIf="ErrorOnPre_Ord?.length" style="color: red;">Enter next
            number, This number already used</small>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="comp">Compulsory/Elective</label>
          <select class="form-control" name="cmp" id="comp" required ngModel>
            <!-- <option value="MAJOR">Major</option> -->
            <option value="COMPULSORY">Compulsory</option>
            <option value="ELECTIVE">Elective</option>
            <option value="DISTRIBUTION">Distribution</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="per">GPA/Percentage</label>
          <select #GPAPerc class="form-control" name="gp" id="per" required
            ngModel
            (change)="GPAPercentaage(GPAPerc.value)">
            <option value="G">GPA</option>
            <option value="P">Percentage</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="reg">Regular/Summer</label>
          <select class="form-control" name="reg" id="reg" required ngModel>
            <option value="Regular">Regular</option>
            <option value="Summer">Summer</option>
          </select>
        </div>

        <button id="submit" class="btn btn-primary mt-3 ml-3"
          [disabled]="(!f.valid||ErrorOnPre_Ord?.length)">Subject
          Offer</button>
        <button type="button" (click)="OnCreatePDF()"
          *ngIf="this.offeredSubjects.length>0"
          class="btn btn-danger mt-3"><i class="fa fa-file-pdf-o" for></i>
          Download PDF</button>
        <button type="button" (click)="OnClear()"
          class="btn btn-warning mt-3">Clear</button>
      </div>
    </form>

    <div *ngIf="offeredSubjects?.length>0" class="card-header mt-2">
      Offered Subjects
    </div>
    <div *ngIf="offeredSubjects?.length>0" class="card-body">
      <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">
        <input class="form-control col-lg-4" type="text" name="Search"
          placeholder="Search" id="myInput" (keydown)="Filter()">
      </div>
      <table class="table table-bordered table-striped" id="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Sr No</th>
            <th scope="col">Course Code</th>
            <th scope="col">Name</th>
            <th scope="col">Cr Hrs</th>
            <th scope="col">Grading</th>
            <th scope="col">Type</th>
            <th scope="col">Subject Nature</th>
            <th scope="col">Absolute/Relative</th>
            <th scope="col">Set No</th>
            <th *ngIf="(this.isAdmin||this.isExam || this.canManageTT)"
              scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sub of offeredSubjects; let i =index;" id="values">
            <td>{{i+1}}</td>
            <td>{{sub.SUB_CODE}}</td>
            <td>{{sub.SUB_NM}}</td>
            <td>{{sub.CR_H}}</td>
            <td>{{(sub.GP_PER=='G')?"GPA":"Percentage"}}</td>
            <td>{{sub.C_ELE}}</td>
            <td>{{sub.NAT_ID==1?'Theory':sub.NAT_ID==2?'Practical':''}}</td>
            <td>{{sub.AB_REL==0?'Absolute':sub.AB_REL==1?'Relative':''}}</td>
            <td>{{sub.SET_NO}}</td>

            <td *ngIf="(isAdmin||isExam||canManageTT)">
              <button *ngIf="(this.isAdmin||this.isExam)"
                class="fa fa-pencil btn btn-outline-success"
                (click)="update(i,sub)" aria-hidden="true"
                title="Edit" (click)="openModal(i)" data-toggle="modal" data-target="#exampleModal" data-whatever="@getbootstrap"></button>
              <button class="btn btn-outline-danger fa fa-trash-alt"
                aria-hidden="true" title="Delete"
                (click)="deleteSub(i, sub.SUB_CODE,sub.SET_NO )"></button>

              <div class="center"
                *ngIf="modal && index == i && (this.isAdmin||this.isExam||this.canManageTT)"
                [ngClass]="{'disabled-overlay': modal && index == i}">
                <div class="modal fade" id="exampleModal" tabindex="-1"
                  role="dialog" aria-labelledby="exampleModalLabel"
                  aria-hidden="true" data-backdrop="false">
                  <div class="modal-dialog modal-dialog-centered"
                    role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel" style="font-weight: bold;">UPDATE {{sub.SUB_CODE}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openModal(i)">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                      <form #u="ngForm">
                        <div class="form-group">
                          <label for="cr_h_u">Credit Hr</label>
                          <input type="number" class="form-control" id="cr_h_u"
                            name="cr_h_u" min="1" [(ngModel)]="cr_h_u"
                            ngModel required>
                        </div>

                        <div class="form-group">
                          <label for="abs_u">Absolute/Relative</label>
                          <select class="form-control" name="abs_u" id="abs_u"
                            [(ngModel)]="abs_u" required ngModel>
                            <option value="0">Absolute</option>
                            <option value="1">Relative</option>
                          </select>
                        </div>

                        <div class="form-group">
                          <label for="sub_nat_u">Subject Nature</label>
                          <select class="form-control" name="sub_nat_u"
                            id="sub_nat_u" [(ngModel)]="sub_nat_u" required
                            ngModel>
                            <option value="1">Theory</option>
                            <option value="2">Practical</option>
                            <option value="3">Thesis/Project</option>
                            <option value="4">Internship</option>
                          </select>
                        </div>
                        <div class="col-12 center">
                          <button class="btn btn-primary"
                            (click)="save(sub,i)">Update</button>
                        </div>
                      </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>