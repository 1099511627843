
<div class=" container-fluid" [@SlideInFromLeft]>
    <form #f="ngForm">
        <div class="row card shadow">
            <div class="card-header" style="padding-top: 10px;">
                <div class=" col-lg-6 col-md-6 form-group">Display Details of Student Set</div>
                <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
                    <app-details-button [details]="details"></app-details-button>
                </div>
            </div>
            <div class="row card-body pb-0">
                <div class="col-lg-4 col-md-4 form-group">
                    <label for="c_code">Class</label>
                    <select id="c_code" #cource (change)="updateMajors()"
                        name="c_code" class="form-control" ngModel required>
                        <option *ngFor="let c of class"
                            value="{{c.id}}">{{c.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-4 form-group">
                    <label for="maj_id">Major</label>
                    <select id="maj_id" name="maj_id" class="form-control"
                        #major ngModel required>
                        <option *ngFor="let m of majors"
                            value="{{m.id}}">{{m.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-4 form-group">
                    <label for="se_id">Session</label>
                    <select class="form-control" name="se_id"
                        id="se_id" #session ngModel required>
                        <option *ngFor="let s of sessions"
                            value="{{s.id}}">{{s.nm}}</option>
                    </select>
                </div>
            </div>
            <div class="row card-body">
                <div class="ml-3">
                    <button class="btn btn-primary"
                        (click)="getstudent()" [disabled]="!f.valid">
                        Show Detail
                    </button>
                    <button class="btn ml-2 btn-danger"
                        (click)="OnCreatePDF()" [disabled]="!showInput">
                        <i class="fa fa-file-pdf-o" for></i>
                        Download PDF
                    </button>
                </div>
            </div>
            <div class="col-12 center"> <loader *ngIf="loader"></loader> </div>
            <div *ngIf="student_table.length!=0" class="px-4">
                <div class="px-2">
                    <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">
                        <input class="form-control col-lg-4" type="text"
                            name="Search" placeholder="Search" id="myInput"
                            (keydown)="Filter()">
                    </div>
                    <table class="table table-striped table-bordered"
                        id="table">
                        <thead class="thead-dark">
                            <tr>
                                <th>Sr #</th>
                                <th>Roll #</th>
                                <th>Complete Roll #</th>
                                <th>Name</th>
                                <th>Father Name</th>
                                <th>Set #</th>
                                <th>Set Combination</th>
                                <th>Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of student_table;let j=index"
                                id="values">
                                <td>
                                    {{j+1}}
                                </td>
                                <td>
                                    {{s.rn}}
                                </td>
                                <td>
                                    {{s.rolno}}
                                </td>
                                <td>
                                    {{s.nm}}
                                </td>
                                <td>
                                    {{s.f_nm}}
                                </td>
                                <td>
                                    {{s.set_no}}
                                </td>
                                <td>
                                    {{s.set_nm}}
                                </td>
                                <td>
                                    <button class="btn btn-primary"
                                        style="font-size:13px;padding-top:3%;padding-bottom:3%;margin-right:2%;margin-left:3%"
                                        (click)="getDetail(s.rolno)">Detail</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </form>
</div>
<app-get-student-detail-pop-up *ngIf="stdDet" (closeStdDetail)="CloseStd()"
    [rolno]="rolno" [c_code_in]="c_code_in"></app-get-student-detail-pop-up>