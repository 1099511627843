import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { StudentService } from '../shared/services/Student.service';
import { TeacherService } from './../shared/services/Teacher.service';

@Component({
  selector: 'app-recover-user',
  templateUrl: './recover-user.component.html',
  styleUrls: ['./recover-user.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class RecoverUserComponent implements OnInit {

  isAdmin: boolean = false;
  dept: number = 0;
  usr: string = null;

  nic: string = null;
  name: string = null;


  @ViewChild('fn') usnmRef: NgForm;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'> This screen is used to update the CNIC and for to reset the password of a LMS user.If the user is faculty member,
  then write their CNIC,if it is student then write their Roll No in username.</h6>
  <h6 class="text-right">یہ اسکرین ایل ایم ایس یوزر کا شناختی کارڈ نمبر اپڈیٹ کرنے اور پاسورڈ ری سیٹ کرنے کے
 لئے استعمال ہوتی ہے۔ اگر یوزر فیکلٹی ممبر ہے، تو ان کا شناختی کارڈ نمبر لکھیں، اگر یوزر اسٹوڈنٹ ہے تو ان کے یوزر نیم میں رول نمبر لکھیں۔
</h6>
  `
  ;



  constructor(private authenticationService: AuthenticationService,
    private studentService: StudentService,
    private teacherService: TeacherService,
    private toastr: ToastrService
  ) {
    this.dept = this.authenticationService.getUser().D_ID;
    this.isAdmin = this.authenticationService.isAdmin();
  }

  ngOnInit(): void {
  }

  getUser() {

    this.usr = this.usnmRef.value.in;
    if (!this.usr || this.usr === "") {
      return;
    }
    else if (this.usr.includes('-')) {
      this.studentService.getInfo({ usnm: this.usr, role: this.isAdmin, d_id: this.dept }).subscribe((res: { NM: string, NIC: string, ROLNO: string }) => {
        this.loadData(res);
      });
    } else {
      this.teacherService.getInfo({ usnm: this.usr, role: this.isAdmin, d_id: this.dept }).subscribe((res: { NM: string, NIC: string }) => {
        this.loadData(res);
      });
    }

  }

  loadData(res) {
    this.nic = null;
    this.name = null;
    if (res) {
      this.name = res.NM;
      this.nic = res.NIC;
    } else {
      this.name = null;
      this.nic = null;
    }
  }

  updatenic(){
    if(this.usr.includes('-')){
      this.updateStdNIC();
    }
    else{
      this.updateFmNIC();
    }
  }

  updateFmNIC() {
    const { nic } = this.usnmRef.value;

    this.teacherService.updateNIC({usnm: this.usr, newusnm: nic}).subscribe(res => {
      if (res) {
        this.toastr.success("Updated Successfully");
        this.nic = nic;
        this.usr = this.nic;
      } else {
        this.toastr.warning("Invalid/Duplicate NIC");
      }
    }, err => {
      console.log(err);
      this.toastr.error("Unknown error occured");
    });
  }

  updateStdNIC() {
    const {nic} = this.usnmRef.value;

    this.studentService.updateNIC({ nic: this.usr, newnic: nic}).subscribe(res => {
      if (res) {
        this.toastr.success("Updated Successfully");
        this.nic = nic;
      } else {
        this.toastr.warning("Invalid/Duplicate NIC");
      }
    }, err => {
      console.log(err);
      this.toastr.error("Unknown error occured");
    });
  }
  resetPw() {
    this.authenticationService.resetPwDefault({ usnm: this.usr, role: (this.usr.includes('-') ? 'STDR' : 'FMR') }).subscribe(res => {
      if (res) {
        this.toastr.success("Password Reset to Default.");
      }
    }, err => {
      console.log(err);
      this.toastr.error("Some error occured");
    })
  }
}
