import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../../../axios';

@Injectable({
  providedIn: 'root'
})
export class LockStudentService {
  constructor(private http: HttpClient) { }

  getStudentInfo(year: number, c_code: number, maj_id: number, se_id: number,t_no:number) {
    return this.http.get<any>(`${baseUrl}/api/stdtLockAdm/getStudentInfo/${year}/${c_code}/${maj_id}/${se_id}/${t_no}`);
  }

  updateStudentInfo(year: number, c_code: number, maj_id: number, se_id: number, rn: number, t_no: number) {
    const payload = { year, c_code, maj_id, se_id, rn, t_no };
    return this.http.post<any>(`${baseUrl}/api/stdtLockAdm/updateStudentInfo`, payload);
  }
  undoStudentInfo(year: number, c_code: number, maj_id: number, se_id: number, rn: number, t_no: number) {
    const payload = { year, c_code, maj_id, se_id, rn, t_no };
    return this.http.post<any>(`${baseUrl}/api/stdtLockAdm/undoStudentInfo`, payload);
  }
  getlockedsubs(c_code:number){
    return this.http.get<any>(`${baseUrl}/api/stdtLockAdm/getlockedsubs/${c_code}`);
  }
 
}