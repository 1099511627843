import { DegreeReportPrintComponent } from './degree-report-print/degree-report-print.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { TimeTableComponent } from './time-table/time-table.component';
import { CourseUploadComponent } from './course-upload/course-upload.component';
import { ViewStudentProfileComponent } from './view-student-profile/view-student-profile.component';
import { InsertTimeTableComponent } from './insert-time-table/insert-time-table.component';
import { SubjectOfferComponent } from './subject-offer/subject-offer.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { RequestedSubComponent } from './requested-sub/requested-sub.component';
import { ViewTimetableComponent } from './view-timetable/view-timetable.component';
import { GenerateTimeTableComponent } from './generate-time-table/generate-time-table.component';
import { AccessGuard } from '../auth/_helpers/Access.guard';
import { TeacherGuard } from '../main/shared/helpers/TeacherGuard';
import { AwardListGuard } from '../main/shared/helpers/AwardListGuard';
import { ManageTimetableGuard } from '../main/shared/helpers/ManageTimetableGuard';
import { StudentDetailManageTimetableGuard } from '../main/shared/helpers/StudentDetailManageTimetableGuard';
import { GenralTimetableGuard } from '../main/shared/helpers/GenralTimetableGuard';
import { ExaminationGuard } from './shared/helpers/ExaminationGuard';
import { lockingManagementGuard } from './shared/helpers/lockingManagementGuard';
import { SectionChangeComponent } from './section-change/section-change.component';
import { SectionAllocateComponent } from './section-allocate/section-allocate.component';
import { AttendanceRelaxationComponent } from './attendance-relaxation/attendance-relaxation.component';
import { InsertSubjectComponent } from './insert-subject/insert-subject.component';
import { RecoverUserComponent } from './recover-user/recover-user.component';
import { CourseOutlineComponent } from './course-outline/course-outline.component';
import { ManageLockingComponent } from './manage-locking/manage-locking.component';
import { AwardListComponent } from './award-list/award-list.component';
import { ProgressReportComponent } from './progress-report/progress-report.component';
import { SectionAllocationGuard } from './shared/helpers/SectionAllocation.guard';
import { AnnoucementComponent } from './annoucement/annoucement.component';
import { TranscriptComponent } from './transcript/transcript.component';
import { SingleEnrollmentComponent } from './single-enrollment/single-enrollment.component';
import { EximComponent } from './exim/exim.component';
import { FeeDescriptionComponent } from './fee-util/fee-description/fee-description.component';
import { FineDescriptionComponent } from './fee-util/fine-description/fine-description.component';
import { ConcessionCategoryComponent } from './fee-util/concession-category/concession-category.component';
import { SubjectFeeComponent } from './subject-fee/subject-fee.component';
import { RepeaterImproverComponent } from './repeater-improver/repeater-improver.component';
import { ReAdmittedComponent } from './re-admitted/re-admitted.component';
import { IdcAllocationComponent } from './idc-allocation/idc-allocation.component';
import { SubmissionCheckComponent } from './submission-check/submission-check.component';
import { ReadmissionGuard } from './shared/helpers/ReadmissionGuard';
import { IDCGuard } from './shared/helpers/IDCGuard';
import { CheckTeacherAttendanceComponent } from './check-teacher-attendance/check-teacher-attendance.component';
import { CheckStudentDetailComponent } from './check-student-detail/check-student-detail.component';
import { MeritListComponent } from './fee-general-reports/merit-list/merit-list.component';
import { FeeReportComponent } from './fee-report/fee-report.component';
import { FeeRefundComponent } from './fee-refund/fee-refund.component';
import { SuperAdminGuard } from './shared/helpers/superAdminGuard';
import { StdTimetabelListComponent } from './std-timetabel-list/std-timetabel-list.component';
import { StudentDetailGuard } from './shared/helpers/StudentDetailGuard';
import { SessionNewComponent } from './session-new/session-new.component';
import { ReEligComponent } from './re-elig/re-elig.component';
import { SubjectOfferReportComponent } from './subject-offer-report/subject-offer-report.component';
import { SingleSubjectEnrollmentComponent } from './single-subject-enrollment/single-subject-enrollment.component';
import { SchpAwardComponent } from './schp-award/schp-award.component';
import { DateExtensionReportComponent } from './fee-general-reports/date-extension-report/date-extension-report.component';
import { CheckEnrollmentComponent } from '../check-enrollment/check-enrollment.component';
import { SubjectReplacementComponent } from './subject-replacement/subject-replacement.component';
import { UpdateMajorNmComponent } from './update-major-nm/update-major-nm.component';
import { SingleStdAwardlistComponent } from './single-std-awardlist/single-std-awardlist.component';
import { GenerateSingleStdAwardlistComponent } from './generate-single-std-awardlist/generate-single-std-awardlist.component';
import { StudentGeneralInfoComponent } from './fee-general-reports/student-general-info/student-general-info.component';
import { SelfSupportFormReportComponent } from './fee-general-reports/self-support-form-report/self-support-form-report.component';
import { FreezeSemesterComponent } from './freeze-semester/freeze-semester.component';
import { EvaluationResponseDetailComponent } from './evaluation-response-detail/evaluation-response-detail.component';
import { LetterGrdClassComponent } from './letter-grd-class/letter-grd-class.component';
import { AlterStudentResultComponent } from './alter-student-result/alter-student-result.component';
import { QecGaurd } from './shared/helpers/QecGaurd';
import { TeacherSideEvalReportComponent } from './teacher-side-eval-report/teacher-side-eval-report.component';
import { NewClassComponent } from './new-class/new-class.component';
import { DegreeRecieveInfoTestComponent } from './degree-recieve-info-test/degree-recieve-info-test.component';
import { AssignRollMeritComponent } from './assign-roll-merit/assign-roll-merit.component';
import { DuesAdjustmentsComponent } from './dues-adjustments/dues-adjustments.component';
import { SignaturefordegreeholdersComponent } from './degree/signaturefordegreeholders/signaturefordegreeholders.component';
import { StatusOfTranscriptReportComponent } from './degree/status-of-transcript-report/status-of-transcript-report.component'
import { DegreeholdersforgovernerhouseComponent } from './degree/degreeholdersforgovernerhouse/degreeholdersforgovernerhouse.component';
import { SinglestdaddressforletterComponent } from './degree/singlestdaddressforletter/singlestdaddressforletter.component';
import { CanFeeStructureGaurd } from './shared/helpers/CanFeeStructureGaurd';
import { AddSubjectCombinationsComponent } from './add-subject-combinations/add-subject-combinations.component';
import { CheckStudentDataComponent } from './check-student-data/check-student-data.component';
import { ManualChallanFahComponent } from './manual-challan-fah/manual-challan-fah.component';
import { GroupBaseWiseCollectionReportComponent } from './collection-report/group-base-wise-collection-report/group-base-wise-collection-report.component';
import { EligibleCandidateComponent } from './degree/eligible-candidate/eligible-candidate.component';
import { StudentdetailComponent } from './studentdetail/studentdetail.component';
import { DetailedstudentComponent } from './detailedstudent/detailedstudent.component';
import { OverallattendanceComponent } from './overallattendance/overallattendance.component';
import { InterMarksViewComponent } from './inter-marks-view/inter-marks-view.component';
// import { LevelWiseStudentsComponent } from './level-wise-students/level-wise-students.component';
import { ClasssubjectenrollcountComponent } from './classsubjectenrollcount/classsubjectenrollcount.component';
import { ResultTransferComponent } from './result-transfer/result-transfer.component'
import { OutstandDuesEntryComponent } from './outstand-dues-entry/outstand-dues-entry.component';
import { FeeRefundReportsComponent } from './fee-refund-reports/fee-refund-reports.component';
import { LockStudentComponent } from './lockstudent/lockstudent.component';
import { StudentAdmissionDetailComponent } from './student-admission-detail/student-admission-detail.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { AdmissionFormComponent } from './admission-form/admission-form.component';
import { DbDetailComponent } from './db-detail/db-detail.component';
import { OverallDumpComponent } from './strength-report-module/overall-dump/overall-dump.component';
import { CominationWiseReportComponent } from './strength-report-module/comination-wise-report/comination-wise-report.component';
import { StudentStrengthComponent } from './examination-reports/student-strength/student-strength.component';
import { StdStrengthSubSecComponent } from './examination-reports/std-strength-sub-sec/std-strength-sub-sec.component';
import { EnrollmentstatusComponent } from './enrepo/enrollmentstatus/enrollmentstatus.component';
import { SingleyearstrengthComponent } from './enrepo/singleyearstrength/singleyearstrength.component';
import { AdmittedSetComponent } from './admitted-set/admitted-set.component';
import { FaidnamesearchComponent } from './faidnamesearch/faidnamesearch.component';


// import { GenderWiseComponent } from './strength-report-module/gender-wise/gender-wise.component'; 
const routes: Routes = [
  {
    path: '', component: MainComponent, children: [
      //teacher
      { path: 'attendance',   canActivate: [TeacherGuard], canLoad: [TeacherGuard], loadChildren: () => import(`./attendance/attendance.module`).then(m => m.AttendanceModule) },

      //  TEACHER FEEDBACK EVALUATION SYSTEM, QecGaurd,SuperAdminGuard
      { path: 'EvaluationForms', canActivate: [QecGaurd], canLoad: [QecGaurd], loadChildren: () => import(`./evaluation-form/EvaluationForms.module`).then(m => m.EvaluationFormsModule) },
      { path: 'EvaluationCompile', canActivate: [QecGaurd], canLoad: [QecGaurd], loadChildren: () => import(`./evaluation-compile-responses/evaluationCompileRes.module`).then(m => m.EvaluationCompileResponseModule) },
      { path: 'FeedbackReports', canActivate: [QecGaurd], canLoad: [QecGaurd], loadChildren: () => import(`./evaluation-form-reports/evaluation-form-reports.module`).then(m => m.EvaluationFormReportsModule) },
      { path: 'EvaluationResponseDetail', canActivate: [QecGaurd], canLoad: [QecGaurd], component: EvaluationResponseDetailComponent },
      { path: 'FreezeReport',  loadChildren: () => import('./freeze-report/freeze-report.module').then(m=>m.FreezeReportModule)},
      // teacher side report
      { path: 'EvaluationReport', canActivate: [TeacherGuard], canLoad: [TeacherGuard], component: TeacherSideEvalReportComponent },
      //Degree Module
      { path: 'degreee', loadChildren: () => import(`./degree/degree.module`).then(m => m.DegreeModule) },
      { path: 'signaturefordegreeholders', component: SignaturefordegreeholdersComponent },
      { path: 'StatusOfTranscriptReportComponent', component: StatusOfTranscriptReportComponent },
      { path: 'degreeholderforgovernerhouse', component: DegreeholdersforgovernerhouseComponent },
      { path: 'EligibleCandidateComponent', component: EligibleCandidateComponent },
      { path: 'singlestdaddressforletters', component: SinglestdaddressforletterComponent },
      { path: 'ExaminationReports', loadChildren: () => import(`./examination-reports/ExaminationReports.module`).then(m => m.ExaminationReportsModule) },
      { path: 'CheckEnrollment', canActivate: [SuperAdminGuard], canLoad: [SuperAdminGuard], component: CheckEnrollmentComponent },
      {path: 'stdGenRep', component: StudentGeneralInfoComponent},
      // {path: 'genderWise', component: GenderWiseComponent},
      {
        path: 'assignment', canActivate: [TeacherGuard], canLoad: [TeacherGuard],
        loadChildren: () => import(`./mark-assessment/assignments/assignments.module`).then(m => m.AssignmentsModule)
      },
      // Lock Student 
      {path: 'lockstudent',component:LockStudentComponent},
      {path: 'shiftingmodule', loadChildren: () => import(`./shiftingmodule/shiftingmodule.module`).then(m => m.ShiftingmoduleModule) },
      { path: 'StudentStrength', component: StudentStrengthComponent },
      { path: 'SubjectSectionStrength', component: StdStrengthSubSecComponent },
      // user detail
      { path: 'UserDetail', component: UserDetailComponent },
      //DB Schema/Routine Details
      { path: 'SchemaDetail', component: DbDetailComponent },
     
      { path: 'reElig', canActivate: [ExaminationGuard], component: ReEligComponent },
      { path: 'newClass', component: NewClassComponent },
      { path: 'overallDump', component: OverallDumpComponent}, 
      
      // {
      //   path: 'LevelWiseStudentStrength', component: LevelWiseStudentsComponent },
        {
          path: 'EnrollmentStatus', component: EnrollmentstatusComponent
        },
        {
          path: 'admittedset', component: AdmittedSetComponent
        },
        {
          path: 'StudentCountEachSubject', component: ClasssubjectenrollcountComponent
        },
        // {
        //   path: 'LevelWiseStudentStrengthYearly', component:SingleyearstrengthComponent
        // },
      {path: 'boardWise', component: CominationWiseReportComponent},
      { path: 'letterGrade', component: LetterGrdClassComponent },
      { path: 'sessionNew', component: SessionNewComponent },
      { path: 'progressReport', component: ProgressReportComponent },
      { path: 'awardList', component: AwardListComponent },
      { path: 'transcript', component: TranscriptComponent },
      { path: 'degree', component: DegreeReportPrintComponent },
      { path: 'degree-recieve-info', component: DegreeRecieveInfoTestComponent },
      { path: 'exam-query', loadChildren: () => import(`./exam-award-list-insert/exam-award-list-insert.module`).then(m => m.ExamAwardListInsertModule) },
      { path: 'studentDetail', canLoad: [StudentDetailManageTimetableGuard], component: CheckStudentDetailComponent },
      // {path:'degree-recieve-info', component:DesgreeRecieveInfoTestComponent },
      { path: 'studentAdmissionDetail', component: StudentAdmissionDetailComponent },
      { path: 'GenerateSingleStdAwardList', canActivate: [ExaminationGuard], component: GenerateSingleStdAwardlistComponent },
      {
        path: 'studentQueries', canActivate: [TeacherGuard], canLoad: [TeacherGuard],
        loadChildren: () => import(`./student-queries/student-queries.module`).then(m => m.StudentQueriesModule)
      },
      // { path:'teacherIssue', component:TeacherAvailableComponent},
      {
        path: 'ManageAwardList', canActivate: [AwardListGuard], canLoad: [AwardListGuard],
        loadChildren: () => import(`./teacher-awardlist/teacher.module`).then(m => m.TeacherAwardListModule)
      },
      {
        path: 'viewStudentProfile',
        component: ViewStudentProfileComponent
      },
      {
        path: 'courseUpload', canActivate: [TeacherGuard], canLoad: [TeacherGuard],
        component: CourseUploadComponent
      },
      { path: 'courseOutline', canActivate: [TeacherGuard], canLoad: [TeacherGuard], component: CourseOutlineComponent },
      { path: 'timeTable', component: TimeTableComponent },
      { path: 'CheckStudentDataComponent', component: CheckStudentDataComponent },
      //manage timetable
      { path: 'ViewTimeTable', canActivate: [ManageTimetableGuard], component: ViewTimetableComponent },
      { path: 'StdTimetableList', component: StdTimetabelListComponent },
      { path: 'InsertTimeTable', canActivate: [ManageTimetableGuard], component: InsertTimeTableComponent },
      { path: 'AttendanceRelaxtion', canActivate: [ManageTimetableGuard], component: AttendanceRelaxationComponent },
      //genral timetable
      { path: 'GenerateTimeTable', canActivate: [GenralTimetableGuard], component: GenerateTimeTableComponent },
      { path: 'singleEnroll', component: SingleEnrollmentComponent },
      { path: 'groupBase', component: GroupBaseWiseCollectionReportComponent },
      //sub offer
      { path: 'subjectOffer', component: SubjectOfferComponent },
      //Add Subject
      { path: 'subjects', component: SubjectsComponent },
      {
        path: 'AddSubject', canActivate: [ExaminationGuard], component: InsertSubjectComponent
      },
      {
        path: 'subjectOfferReport', canActivate: [ExaminationGuard], component: SubjectOfferReportComponent
      },
      {
        path: 'ResultSubmissionCheck', canActivate: [ManageTimetableGuard], component: SubmissionCheckComponent
      },

      { path: 'recoverUser', component: RecoverUserComponent },
      { path: 'Announcements', component: AnnoucementComponent },
      

      //admin
      {
        path: 'register', canActivate: [AccessGuard],
        loadChildren: () => import(`./register/register.module`).then(m => m.RegisterModule)
      },
      {
        path: 'checkAttendence', canActivate: [ManageTimetableGuard], component: CheckTeacherAttendanceComponent
      },
      { path: 'requestedSub', canActivate: [ExaminationGuard], component: RequestedSubComponent },

      { path: 'examModule', canActivate: [ExaminationGuard], canLoad: [ExaminationGuard], loadChildren: () => import(`./exam-module/exam-module.module`).then(m => m.ExamModuleModule) },

      { path: 'lockmanagement', canActivate: [lockingManagementGuard], component: ManageLockingComponent },
      { path: 'subjectReplacement', component: SubjectReplacementComponent },
      { path: 'resultTransfer', component: ResultTransferComponent },
      { path: 'singleSubjectEnroll', component: SingleSubjectEnrollmentComponent },
      {
        path: 'calenderManagement', canActivate: [ExaminationGuard], canLoad: [ExaminationGuard],
        loadChildren: () => import(`./calender-management/calender-management.module`).then(m => m.CalenderManagementModule)
      },

      // ---------------------------Add subject Combination------------------------------------
      {
        path: 'addSubjectCombo', canActivate: [ExaminationGuard], canLoad: [ExaminationGuard],
        component: AddSubjectCombinationsComponent
      },

      {
        path: 'ClassMajorRollno',
        loadChildren: () => import('./insert-new-screen/insert-new-screen.module').then(m => m.InsertNewScreenModule)
      },

      {
        path: 'teacherInformation',
        loadChildren: () => import(`./teacher-information/teacher-information.module`).then(m => m.TeacherInformationModule)
      },
      {
        path: 'incomecash',
        loadChildren: () => import(`./daily-income-cash-rep/income-cash.module`).then(m => m.IncomeCashModule)
      },

      {
        path: 'Eligiblity',
        loadChildren: () => import(`./calculate-eligiblity/calculate-eligiblity.module`).then(m => m.CalculateEligiblityModule)
      },

      {
        path: 'StudentChallanActions',
        loadChildren: () => import(`./student-challan-actions/student-challan-actions.module`).then(m => m.StudentChallanActionsModule)
      },

      {
        path: 'ExternalSchp',
        loadChildren: () => import(`./external-scholarship/external-scholarship.module`).then(m => m.ExternalScholarshipModule)
      },
      // { path: 'studentLeaves', canActivate: [ExaminationGuard], component: StudentLeavesComponent },
      {
        path: 'dutyLeaves', canActivate: [ReadmissionGuard],
        loadChildren: () => import(`./duty-leave/dutyLeave.module`).then(m => m.DutyLeavesModule)
      },

      { path: 'SectionChange', component: SectionChangeComponent },
      { path: 'subjectEnrollment', loadChildren: () => import(`./subject-enrollment/subject-enrollment.module`).then(m => m.SubjectEnrollmentModule) },
      { path: 'SectionAllocate', canActivate: [SectionAllocationGuard], component: SectionAllocateComponent },
      { path: 'ReAdmitted', canActivate: [ReadmissionGuard], component: ReAdmittedComponent },
      { path: 'idcAllocation', canActivate: [IDCGuard], component: IdcAllocationComponent },



      { path: 'progressReport', canActivate: [ExaminationGuard], component: ProgressReportComponent },
      { path: 'awardList', canActivate: [ExaminationGuard], component: AwardListComponent },
      { path: 'singleStdAwardlist', canActivate: [ExaminationGuard], component: SingleStdAwardlistComponent },

      { path: 'DataExport', component: EximComponent },
      { path: 'inter', loadChildren: () => import(`./inter/inter.module`).then(m => m.InterModule) },
      { path: 'inter_seating', loadChildren: () => import(`./student-reporting-seating/student-reporting-seating.module`).then(m => m.StudentReportingSeatingModule) },
      //Fee Module..
      { path: 'feeDescription', component: FeeDescriptionComponent },
      { path: 'challanGeneration', loadChildren: () => import(`./challan-generation/challan-generation.module`).then(m => m.ChallanGenerationModule) },
      { path: 'fineDescription', component: FineDescriptionComponent },
      { path: 'concessionCategory', component: ConcessionCategoryComponent },
      { path: 'subjectFee', component: SubjectFeeComponent },
      { path: 'repeaterImprover', component: RepeaterImproverComponent },
      { path: 'mListReport', component: MeritListComponent },
      { path: 'feeReport', component: FeeReportComponent },
      { path: 'dateExtensionReport', component: DateExtensionReportComponent },
      { path: 'studentGeneralInfo', component: StudentGeneralInfoComponent },
      { path: 'selfSupportFormReport', component: SelfSupportFormReportComponent },
      {
        path: 'SfaoEftRep',
        loadChildren: () => import(`./SFAO/SFAO_EFT/SFAO_EFT.module`).then(m => m.EFTModule)
      },
      {
        path: 'faidname',component: FaidnamesearchComponent
      },
      {
        path: 'collectionReport',
        loadChildren: () => import(`./collection-report/collection-report.module`).then(m => m.CollectionReportModule)
      },
      { path: 'feeRefund', component: FeeRefundComponent },
      { path: 'feeRefundRep', component: FeeRefundReportsComponent },
      { path: 'duesAdjustment', component: DuesAdjustmentsComponent },
      { path: 'onChallanDataInsertion', loadChildren: () => import(`./challan-requirements/challan-requirements.module`).then(m => m.ChallanRequirementsModule) },
      { path: 'schpAward', component: SchpAwardComponent },
      { path: 'feeUtil', canActivate: [CanFeeStructureGaurd], canLoad: [CanFeeStructureGaurd], loadChildren: () => import(`./fee-util/feeUtil.module`).then(m => m.FeeUtilModule) },
      { path: 'majorTitle', component: UpdateMajorNmComponent },
      { path: 'onNewManualChallan', component: ManualChallanFahComponent },
      {path: 'meritList', component: MeritListComponent},
      { path: 'ClassEnrollmentSetNo', component: StudentdetailComponent },
      // { path: 'AddNewClass', component: StudentdetailComponent },
      // { path: 'AddNewMaj', component: StudentdetailComponent },
      {
        path: 'resultCompile',
        loadChildren: () => import(`./result-compilation/result-compilation.module`).then(m => m.ResultCompilationModule)
      },
      {
        path: 'assignRollNumber', component: AssignRollMeritComponent
      },
      {
        path: 'CheckPaidChalans',
        loadChildren: () => import(`./paid-unpaid-chalans/paid-unpaid-routing.module`).then(m => m.PaidUnpaidModule)
      },
      {
        path: 'PrintSingleChallan',
        loadChildren: () => import(`./print-single-std-challan/print-single-challan.module`).then(m => m.PrintSingleChallanModule)
      },
      {
        path: 'ResultAlterStd', component: AlterStudentResultComponent
      },
      {
        path: 'OutstandingDuesEntry', component: OutstandDuesEntryComponent
      },
      {
        path: 'departmentReports',
        loadChildren: () => import(`./department-reports/department-reports.module`).then(m => m.DepartmentReportsModule)
      },
      {
        path: 'StrengthRep',
        loadChildren: () => import(`./strength-report-module/strength-report-module.module`).then(m => m.StrengthReportModuleModule)
      },
      {
        path: 'AdmChangeRecord', canActivate: [StudentDetailGuard], canLoad: [StudentDetailGuard],
        loadChildren: () => import(`./adm-change-record/adm-change-record.module`).then(m => m.AdmChangeRecordModule)
      },
      //Semester Freeze
      { path: 'FreezeSemester', component: FreezeSemesterComponent },

      { path: '', redirectTo: 'timeTable', pathMatch: 'full' },

      { path: 'InterMarksView', canActivate: [SuperAdminGuard], canLoad: [SuperAdminGuard], component: InterMarksViewComponent },

      { path: '', redirectTo: 'timeTable', pathMatch: 'full' },
      {
        path: 'StudentCountEachSubject', component: ClasssubjectenrollcountComponent, canActivate: [SuperAdminGuard], canLoad: [SuperAdminGuard],
      },
      {
        path: 'dashboard',loadChildren: () => import(`../main/dashboard/dashboard.module`).then(m => m.DashboardModule)
      },
      {
        path: 'enrocom',
        loadChildren: () => import(`../main/enrocom/enrocom.module`).then(m => m.EnrocomModule)
      },
      {
        path: 'generalRep',
        loadChildren: () => import(`./fee-general-reports/fee-general-reports.module`).then(m => m.FeeGeneralReportsModule)
      },

      //enrepo
      {
        path: 'enrepo',
        loadChildren: () => import(`../main/enrepo/enrepo.module`).then(m => m.EnrepoModule)
      },

      { path: 'OverallAttendanceReport', component: OverallattendanceComponent },


      //OfferedSubjectsReports Component
      {
        path: 'offeredsubjectsreport', canActivate: [SuperAdminGuard], canLoad: [SuperAdminGuard],
        loadChildren: () => import(`../main/offeredsubjectsreport/offeredsubjectsreport.module`).then(m => m.OfferedsubjectsreportModule)
      },

      //OfferedSubjectsReports Component
      {
        path: 'DetailedStudent', component: DetailedstudentComponent
        , canActivate: [SuperAdminGuard], canLoad: [SuperAdminGuard],
        //loadChildren:()=> import(`../main/offeredsubjectsreport/offeredsubjectsreport.module`).then(m=>m.OfferedsubjectsreportModule)
      },
      //OfferedSubjectsReports Component
      {
        path: 'offeredsubjectsreport', canActivate: [SuperAdminGuard], canLoad: [SuperAdminGuard],
        loadChildren: () => import(`../main/offeredsubjectsreport/offeredsubjectsreport.module`).then(m => m.OfferedsubjectsreportModule)
      },
      //Admission form for migrations
      { path: 'Admission-from', component:AdmissionFormComponent},


    ]
  }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule {
}
