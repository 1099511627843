<div class="card" [@SlideInFromLeft]>
  <div class="card-header"  style="padding-top: 10px;">
    <div class=" col-lg-6 col-md-6 form-group">
      Enrollment Count in Each Subject For a Class</div>
       <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
      <app-details-button [details]="details"></app-details-button>
  </div>
</div>
    <div class="card-header">
      This report will calculate the number of students in each subject offered by a specific class.
    </div>
    <div class="row card-body">
      <div class="col-sm-12 col-lg-12 p-2">
        <form #f="ngForm">
          <div class="row">
  
            <div class="col-lg-4 col-md-6 form-group">
                <label for="c_code">Class</label>
                <select id="c_code" #cource (change)="OnClassChange()" name="c_code" class="form-control" ngModel>
                  <option *ngFor="let c of class" value="{{c.id}}">{{c.nm}}</option>
                </select>
            </div>
  
  
            <div class="col-lg-4 col-md-6 form-group">
                <label for="se_id">Session</label>
                <select (change)="OnSessionChange()"  class="form-control" name="se_id" id="se_id" #session ngModel>
                  <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
                </select>
            </div>

            <div class="col-lg-4 col-md-6 form-group">
                <label for="Session">Semester</label>
                <select #semester class="form-control " #session (change)="OnSemesterChange()" name="t_no"
                  id="Semester" ngModel required>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="20">Complete</option>
                </select>
        
            </div>
            
          </div>  
                          
          <div class="row mt-3">            
            <button  class="btn btn-primary ml-3 mt-2 " (click)="onViewStrengthClick()" type="button" [disabled]="!f.valid">View Strength
            </button>                     
            <button *ngIf="this.student.length>0" class="btn btn-danger ml-2  mt-2 " (click)="OnCreatePDF()" type="button">
              <i class="fa fa-file-pdf-o" for></i> Download PDF
            </button>   
            <button *ngIf="this.student.length>0" class="btn btn-success ml-2  mt-2 " (click)="DownloadExcel()" type="button">
              <i class="fa fa-file-excel-o" for></i> Download Excel
            </button>                                       
          </div>

          <loader *ngIf="loader"></loader>
        </form>
      </div>
            

      <div *ngIf="this.student.length>0" class="col-sm-12 col-lg-12 p-2" style="overflow: hidden; overflow-x: scroll">                 
          <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
            <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
          </div>
            <table  class="table table-bordered table-striped m-0 p-0" id="table">             
              <thead class="thead-dark" class="thead-dark">
                <tr>
                  <th scope="col">Sr #</th>                
                  <th scope="col">Subject Code</th>
                  <th scope="col">Subject Name</th>
                  <th scope="col">Semester</th>
                  <th scope="col">ENROLL COUNT</th>
                  <th scope="col">NOT ENROLL COUNT</th>
                  <th scope="col">TOTAL</th>
                </tr>
            </thead>                      
              <tbody>
                <tr *ngFor="let element of student; let i =index;" id="values">
                  <td>{{i+1}}</td>                  
                  <td>{{element.SUBCODE}}</td>
                  <td>{{element.SUBNAME}}</td>
                  <td>{{element.SEMESTER}}</td>
                  <td>{{element.ENROLL_COUNT}}</td>
                  <td>{{element.NOT_ENROLL_COUNT}}</td>
                  <td>{{element.NOT_ENROLL_COUNT + element.ENROLL_COUNT}}</td>
                  <tr>
                    <td scope="row" colspan="4"><b>Total</b></td>
                    <td>
                        <b>{{ getTotalEnrollCount() }}</b>
                    </td>
                    <td>
                        <b>{{ getTotalNotEnrollCount() }}</b>
                    </td>
                    <td>
                        <b>{{ getTotalTotalCount() }}</b>
                    </td>
                </tr>
            </tbody>
            </table>
          </div>  
    </div>
  </div>