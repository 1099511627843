import { Component,ElementRef, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SelectList } from '../../shared/model/SelectList.model';
import { ClassService } from '../../shared/services/Class.service';
import { reportsFeeService} from '../../shared/services/reportsFee.service';
// import { Subscriber } from 'rxjs';

@Component({
  selector: 'app-class-wise-collection-report',
  templateUrl: './class-wise-collection-report.component.html',
  styleUrls: ['./class-wise-collection-report.component.css'],
  animations: [SlideInFromLeft()]
})
export class ClassWiseCollectionReportComponent implements OnInit {
  classes: Array<SelectList>;
  isAdmin: boolean = false;
  @ViewChild('f') formRef: NgForm;
    constructor(
      private authenticationService: AuthenticationService,
      private toastr: ToastrService,
      private classService: ClassService,
      private reportsFee: reportsFeeService
    ) {
      this.classes= new Array<SelectList>();
      this.isAdmin = this.authenticationService.isAdmin();
     }

     ngOnInit(): void {
      this.classes = [];
      this.classService.getAllClassesFeeServer().subscribe((res: { c_code: number, Des: string }[]) => {
        res?.forEach(entry => {
          this.classes.push({ id: entry.c_code, nm: entry.Des });
        });
      });
    }

    onDownloadReport(){
      this.reportsFee.onCollectionReportClassWise(this.formRef.value).subscribe(res=>{
      });
    }

    OnSubmit(){
    }

    onClear(){
      this.formRef.reset();
    }
    }