import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from 'src/app/Util.service';
import { SlideInFromLeft } from '../../transitions';
import { RequestedSubjectDepartmentModel } from './models/RequestedSubjectDepartment.model';
import { Router } from '@angular/router';
import { SubjectRequestService } from './../shared/services/SubjectRequest.service';

@Component({
  selector: 'app-requested-sub',
  templateUrl: './requested-sub.component.html',
  styleUrls: ['./requested-sub.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class RequestedSubComponent implements OnInit {

  requestedSubs: RequestedSubjectDepartmentModel[];
  showDetails: boolean = false;
  selectedDept: RequestedSubjectDepartmentModel;
  private selectedIndex: number;
  @ViewChild('f') formRef: NgForm;

  @Output() closeViewSubDetail = new EventEmitter<string>();


  constructor(private subReqService: SubjectRequestService,
    private toastr: ToastrService) {

    this.requestedSubs = new Array<RequestedSubjectDepartmentModel>();
  }

  ngOnInit(): void {
    this.updateRequestedSubs();
  }



  OnClose(event) {
    if(event===0){
      this.showDetails = false;
    }else if(event===1){
      this.showDetails = false;
      this.requestedSubs[this.selectedIndex].status = 1;
    }else if(event===-1){
      this.showDetails = false;
      this.requestedSubs[this.selectedIndex].status =-1;
    }
    // this.updateRequestedSubs();
  }


  viewDetails(s: RequestedSubjectDepartmentModel, index: number) {
    this.selectedIndex = index;
    this.selectedDept = s;
    this.showDetails = true;
  }




  updateRequestedSubs() {
    this.requestedSubs = [];
    this.subReqService.getRequestedSubjects().subscribe(res => {
      for (const i in res) {
        this.requestedSubs.push(new RequestedSubjectDepartmentModel(res[i].c_code, res[i].c_nm, res[i].maj_id, res[i].maj_nm, res[i].se_id, res[i].se, res[i].t_no, res[i].status));
      }
    }, err => {
      console.log(err);
    });
  }

}
