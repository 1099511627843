import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SlideInFromLeft } from '../../transitions';
import { AttendanceService } from '../attendance/attendance-services/attendance.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { TimetableModel } from '../view-timetable/model/Timetable.model';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { DepartmentService } from './../shared/services/Department.service';
import { SubjectOfferService } from './../shared/services/SubjectOffer.service';
import { GroupService } from './../shared/services/Groups.service';
import { SelectList } from '../shared/model/SelectList.model';
import { TeacherService } from './../shared/services/Teacher.service';
import { ClassService } from './../shared/services/Class.service';
import { SessionService } from './../shared/services/Sessions.service';
import { jsPDF } from "jspdf";
import autoTable, { UserOptions } from 'jspdf-autotable'
import 'jspdf-autotable';
import { Team, TimetableService } from '../shared/services/Timetable.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-overallattendance',
  templateUrl: './overallattendance.component.html',
  styleUrls: ['./overallattendance.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class OverallattendanceComponent implements OnInit {

  departments: Array<SelectList>;
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  subjects: Array<{ sub_code: string, sub_nm: string, d_id }>;
  sections: Array<string>;
  teachers: Array<SelectList>;
  blocks: Array<SelectList>;
  rooms: Array<SelectList>;
  flag: boolean = true;
  groups: Array<SelectList>;
  manageTeacher: boolean = false;
  checkTeacher: boolean = false;
  checkRoom: boolean = false;
  addTeacher: boolean = false;
  timetableEntries: Map<number, TimetableModel>;
  cData: Map<number, TimetableModel>;

  @ViewChild('f') formRef: NgForm;
  
  isTeacherFree: boolean = true;
  isSectionFree: boolean = true;
  suboffered: boolean = false;
  private usr;
  
  selectedSubject: { sub_code: string, sub_nm: string, d_id };

  isAdmin: boolean = false;
  group: number = 0;
  dept: number = 0;
  sectionScheme:Array<string>;
  showInter:boolean = false;
  interMediateGroup:Array<SelectList>;
  selected_c_code:number;
  add_teacher:boolean=true;
  data:any;
  isPhilosophy:any;
  tm: Team;  
  teams: Team[];
  constructor(  
    
    private datePipe: DatePipe,
    private attendanceService: AttendanceService,
    private authenticationService: AuthenticationService,
    private timetableService: TimetableService,
    private toastr: ToastrService,
    private deptService: DepartmentService,
    private subOfferService: SubjectOfferService,
    private groupService: GroupService,
    private teacherService: TeacherService,
    private classService: ClassService,
    private sessionService: SessionService,
    ) { this.departments = new Array<SelectList>();      
      this.classes = new Array<SelectList>();
      this.majors = new Array<SelectList>();
      this.sessions = new Array<SelectList>();
      this.subjects = new Array<{ sub_code: string, sub_nm: string, d_id }>();
      this.teachers = new Array<SelectList>();
      this.sections = new Array<string>();
      this.blocks = new Array<SelectList>();
      this.rooms = new Array<SelectList>();
      this.interMediateGroup = [];
      this.sectionScheme = new Array<string>();
      this.timetableEntries = new Map<number, TimetableModel>();
      this.cData=new Map<number, TimetableModel>();;
      this.groups = new Array<SelectList>();
      this.isAdmin = this.authenticationService.isAdmin();
      this.group = this.authenticationService.getGroup();
      this.dept = this.authenticationService.getUser().D_ID;
      this.sectionScheme = ['A1', 'A2','A3','A4','A5','A6','A7','E1','E2','E3','E4','E5','E6','E7'];}
      allow:boolean=true;
      loader = false;  
  ngOnInit(): void {
    this.loader=false;
    this.sectionareannoying=[];
    this.isPhilosophy=this.group;        
    this.loadGroups();    
    this.loadDepartments();          
  }
  
  loadDepartments() {
    this.deptService.getDepartments({ admin: true, filter: true }).subscribe((res: { D_ID: number, D_NM: string }[]) => {
      res?.forEach(entry => {
        this.departments.push({ id: entry.D_ID, nm: entry.D_NM });
      });
    });
  }

  OnSectionChange(){    
    // this.loadTeachers();
    
  }


  loadTeachers() {        
    this.teachers = [];
    var {grp,c_code,se_id,t_no,sub_code,section,d_id}=this.formRef.value;
    if(!grp){
      grp=this.group;
    }
    if(!d_id){
      d_id=this.dept;
    }
    this.teacherService.OgetTeachers(d_id,grp,c_code,se_id,t_no,sub_code,section).subscribe((res: { FM_ID: number, FM_NM }[]) => {
      res?.forEach(element => {
        this.teachers.push({ id: element.FM_ID, nm: element.FM_NM });
      });
    });
  }

  loadGroups() {
    this.groupService.getGroups({ grp: this.group, role: this.isAdmin }).subscribe((res: { GRP: number, G_NM: string }[]) => {
      res?.forEach(element => {
        this.groups.push({ id: element.GRP, nm: element.G_NM });
      });
      if(this.groups[0]?.id==1)  
        this.showInter=true;
      if (!this.isAdmin) {
        this.updateClasses();
      }
    }, err => {
      console.log(err);
    });
  }  

  

  OnClassChange(c: HTMLSelectElement) {
    this.sessions=[];
    this.subjects=[];
    this.sectionareannoying=[];
    this.formRef.controls['se_id'].reset();
    this.formRef.controls['t_no'].reset();
    this.formRef.controls['sub_code'].reset();
    this.formRef.controls['section'].reset();    
    if(this.formRef.value.c_code==1){
      this.selected_c_code=this.formRef.value.c_code;
    }       
    this.updateSessions();
  }
  OnGrpChange(grp: HTMLSelectElement) {    
    this.sectionScheme = [];
    this.group = parseInt(grp.value);    
    this.updateClasses();
    this.updateSubjects();
  }

  

  OnDeptChange(d: HTMLSelectElement) {    
  }
  
  OnSessionChange(s: HTMLSelectElement) {    
    this.subjects=[];
    this.sectionareannoying=[];
    this.formRef.controls['t_no'].reset();
    this.formRef.controls['sub_code'].reset();
    this.formRef.controls['section'].reset();
    this.updateSubjects();
    //this.updateRoom();
  }

  OnSemesterChange(sem: HTMLSelectElement) {    
    this.sectionareannoying=[];
    this.subjects=[];
    this.formRef.controls['sub_code'].reset();
    this.formRef.controls['section'].reset();
    this.updateSubjects();    
  }

  onSubChange(s: HTMLSelectElement) {    
    this.sectionareannoying=[];    
    this.formRef.controls['section'].reset();
    this.loadSections();    
    this.allow=true;
  }

  sectionareannoying:any
  loadSections(){
    this.sectionareannoying = [];
    var {grp,c_code,se_id,t_no,sub_code,d_id}=this.formRef.value;
    if(!grp)
    {
      grp=this.group
    }
    if(!d_id)
    {
      d_id=this.dept
    }
    this.teacherService.O_getsection(d_id,grp,c_code,se_id,t_no,sub_code).subscribe(res => {      
        this.sectionareannoying=res;
    });
  }

  getAttendanceData(){   
    var {grp,c_code,se_id,t_no,sub_code,section,d_id}=this.formRef.value;
    if(!grp){
      grp=this.group
    }
    if(!d_id){
      d_id=this.dept
    }
    if (!grp || !d_id || !c_code|| !se_id || !t_no|| !sub_code || !section ) {
      this.toastr.clear()
      this.toastr.warning("Some Fields Missing");
      return;
      }
    this.loader=true;
    this.toastr.info("Downloading pdf...",null, {timeOut:30000});
    this.attendanceService.getAttendanceData(d_id,grp,c_code,se_id,t_no,sub_code,section).subscribe(
      (res: { Attendence: any[], Dates: string[], session: { SESSION: string },teacher:{TEACHER:string} }) => {

        const { SESSION } = res?.session;
        const { TEACHER } = res?.teacher;
        const { Attendence, Dates } = res;
                
        this.printMonthlyAttSheet(Dates, Attendence, SESSION,TEACHER);
      }, err => {
        this.loader=false;
        this.toastr.clear()
        this.toastr.error("Could Not Download")
        console.log(err);        
      });
  }
  printMonthlyAttSheet(dates: string[], att: string[][], session: string,TEACHER:string) {    
    var lastindex=0
    var secondlastindex=0
    var thirdlastindex=0
    lastindex=dates.length;
    lastindex+=5;
    secondlastindex=lastindex-1
    thirdlastindex=lastindex-2          
      lastindex=Number(lastindex)
      secondlastindex=Number(secondlastindex)
      thirdlastindex=Number(thirdlastindex)            
    const header = [];
    var cls='';
    var sub_nm='';
    var dept_nm='';
    var {d_id}=this.formRef.value;
    if(!d_id)
    {
      d_id=this.dept
    }
    this.departments.forEach(element => {
      if(element.id==d_id)
      {
        dept_nm = element.nm;
      }
    });

    this.subjects.forEach(element => {
      if(element.sub_code==this.formRef.value.sub_code)
      {
        sub_nm = element.sub_nm;
      }
    });
    

    this.classes.forEach(element => {
      if(element.id==this.formRef.value.c_code)
      {
        cls = element.nm;
      }
    });

    header.push(
      ["Sr.", "Roll No", "Name", ...dates, "Presents", "Absents","Leaves"]
    );
    
    const body = [];        
    att.forEach((x, index) => {
      body.push([index + 1, ...x]);
    })
    let exportDate = this.datePipe.transform(new Date(), 'MMM d,y')
    let image = new Image();
    let page;    
    let sub_code = this.formRef.value.sub_code;    
    let t_no = this.formRef.value.t_no;
    let sec =this.formRef.value.section;
    let total_lec = dates?.length;    

    image.src = '../../../assets/images/logo3.png';
    const doc = new jsPDF('l', 'pt', "legal");
    doc.setFontSize(10);    
    doc.setDrawColor(256, 256, 256);
    autoTable(doc, {
      margin: { top: 70, bottom: 40},
      styles: {
        fontSize: 7,
        lineWidth: 0.5,        
        
      },
      
      head: header,
      body: body,      
      // headStyles: {
      //   halign: 'center'
      // },
      columnStyles: {
        '-1':{cellWidth:40},
        '-2':{cellWidth:40},
        '-3':{cellWidth:40},
        '0': { cellWidth: 20 },
        '1': { cellWidth: 70 },
        '2': { cellWidth: 70 },        
        // lastindex:{cellWidth:32},
        // secondlastindex:{cellWidth:32},
        // thirdlastindex:{cellWidth:32}
                
      },
      tableLineWidth: 1,
      didDrawPage: function () {
        doc.addImage(image, 160, 1, 55, 55);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 160, 65);
        doc.setFontSize(16);
        doc.setFont('Arial');
        doc.text("Attendance Sheet", 30, 20);
        doc.text("______________", 30,19);
        doc.text("GC University", 30, 40);
        doc.text("  Lahore", 47, 55);
        doc.setFontSize(11);
        doc.text("Session: ( " + session + " )", 230, 20);        
        doc.text("Subject Code: " + sub_code, 230, 40);
        doc.text("Class: " + cls, 470, 20);
        doc.text("Semester: " + t_no, 470, 40);
        doc.text("Subject: " + sub_nm, 230, 60);
        doc.text("Section: " + sec, 470, 60);
        doc.setFontSize(12);
        doc.text(" Total Delivered Lectures : " + total_lec, 821, 30);
        doc.text("_________________________", 820, 35);
        doc.text("_________________________", 820, 10);
        doc.text("|", 819, 35);
        doc.text("|", 819, 21);
        doc.text("|", 819, 26);
        doc.text("|", 969, 21);
        doc.text("|", 969, 35);
        doc.text("|", 969, 26);
        //doc.setFontSize(16);
        doc.text("Teacher's Name: " + TEACHER, 610, 60);
        doc.text("Department: " + dept_nm, 610, 40);
        doc.setFontSize(20);
        doc.setFontSize(10);
        page = doc.getNumberOfPages();
      }
    });
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 480, 600);
      if (page === 1) {
        // doc.text("Printed Date: "+exportDate, 38, 575);
        doc.text("Note:- Errors and Omissions Excepted", 700, 590);
        doc.text("Directorate of Information Technology", 38, 590);
      }
      else {
        doc.text("Note:- Errors and Omissions Excepted", 700, 590);
        doc.text("Directorate of Information Technology", 38, 590);
        if (i === page) {
        }
      }
    }
    this.toastr.clear()
    this.loader=false;
    doc.save();    
  }

  
  updateClasses() {
    if (!this.group)
    return;

    this.classes = [];
    this.majors = [];
    this.sessions = [];
    this.subjects = [];
    this.sections = [];
    this.classService.getClasses({ grp: this.group }).subscribe((res: { C_CODE: number, C_NM }[]) => {
      res?.forEach(element => {
        this.classes.push({ id: element.C_CODE, nm: element.C_NM });
      });
    }, err => {
      console.log(err);
    });
  }


  updateSessions() {
    this.sessions = [];
    this.subjects = [];
    this.sections = [];

    if (this.formRef.value.c_code === "") return;

    this.sessionService.getSessions(this.formRef.value.c_code).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(entry => {
        this.sessions.push({ id: entry.SE_ID, nm: entry.SE_NM });
      });
    });
  }

  updateSubjects() {
    if (!this.group || this.formRef.value.c_code === "" ||
      this.formRef.value.se_id === "" || this.formRef.value.t_no === "") return;

    this.subjects = [];
    this.sections = [];
    this.subOfferService.getOfferedSubByGroup(this.group.toString(), this.formRef.value.c_code, this.formRef.value.se_id, this.formRef.value.t_no).subscribe((res: { SUB_CODE: string, D_ID: string, SUB_TITLE_PRINT: string }[]) => {
      res?.forEach(entry => {
        this.subjects.push({ sub_code: entry.SUB_CODE, sub_nm: entry.SUB_TITLE_PRINT, d_id: entry.D_ID });
      });
      this.suboffered = true;
    }, err => {
      this.toastr.clear();
      this.toastr.error("Unknown Error");
    });
  }

}
