import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';

@Component({
  selector: 'app-modal-pop-up',
  templateUrl: './modal-pop-up.component.html',
  styleUrls: ['./modal-pop-up.component.css'],
  animations:[
    SlideInFromLeft()
  ]
})
export class ModalPopUpComponent {
  closed = false;
  @Input() header = "";
  @Input() component = null;
  @Input() data: { rolno: string; c_code: string; se_id: string; t_no: string, stdt: boolean };

  constructor() { }

  onClose() {
    this.closed = true; 
  }
}
