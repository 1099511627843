import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList, SelectList2 } from '../../shared/model/SelectList.model';
import { DegreeprintService } from '../../shared/services/degreeprint.service';
import { SessionService } from '../../shared/services/Sessions.service';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';
export interface Data {
  rollno: string,
  Name: string,
  F_name: string,
  Reg_no: string,
  Deg_no: number,
  Year: number
}
@Component({
  selector: 'app-degreeholdersforgovernerhouse',
  templateUrl: './degreeholdersforgovernerhouse.component.html',
  styleUrls: ['./degreeholdersforgovernerhouse.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class DegreeholdersforgovernerhouseComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;
  class: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList2>;
  category: string;
  t_no: number;
  Details: Array<Data>;
  isOpen: boolean = true;
  classname: string;
  majname: string;
  constructor(
    private sessionService: SessionService,
    private toaster: ToastrService,
    private degree: DegreeprintService,
    private lmsSharedService: LMSharedDataService) {
    this.class = [];
    this.majors = [];
    this.sessions = [];
    this.Details = []
  }

  ngOnInit(): void {
    this.updateClasses();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.class = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateMajors() {
    this.majors.length = 0;
    this.lmsSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  OnClassChange() {
    this.updateMajors();
    this.updateSessions();
    for (var i = 0; i < this.class.length; i++) {
      if (this.formRef.value.c_code == this.class[i].id) {
        this.classname = this.class[i].nm;
        return;
      };
    }
  }

  OnMajorChange() {
    for (var i = 0; i < this.majors.length; i++) {
      if (this.formRef.value.maj_id == this.majors[i].id) {
        this.majname = this.majors[i].nm;
        return;
      };
    }
  }

  updateSessions() {
    this.lmsSharedService.filterSessionByCode(this.formRef.value.c_code)
      .then(session => {
        this.sessions = session
        if (this.formRef) {
          const initialValue = this.sessions[0]?.id;
          this.formRef?.controls['se_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }

  OnCategoryChange() {

    if (this.formRef.value.category === "Urgent") {
      this.category = "U";
    }
    else if (this.formRef.value.category === "Regular") {
      this.category = "R";
    }
    else if (this.formRef.value.category === "Duplicate") {
      this.category = "D"
    }
  }
  click() {
    this.Details = [];
    this.t_no = -1;
    this.toaster.info("Data is Loading Please Wait");
    this.degree.getEligibleCandidateForDegree(this.formRef.value.c_code, this.t_no, this.formRef.value.se_id, this.formRef.value.maj_id, this.formRef.value.notifDate
      , this.formRef.value.notifDate1, this.category, 3).subscribe((res: any) => {
        if (res != null) {
          this.toaster.success("Data Loaded");
          for (var i = 0; i < res.length; i++) {
            this.Details.push({ rollno: res[i].ROLNO, Name: res[i].nm, F_name: res[i].F_NM, Reg_no: res[i].REG_NO, Deg_no: res[i].DEG_NO, Year: res[i].Year });
          }
        }
        else {
          this.toaster.error("Data Not Found");
        }
      });
  }

  CreatePdf() {
    var classname = this.classname;
    var majname = this.majname;
    var convo = this.formRef.value.convocate;
    this.toaster.success("PDF In Progress");
    const header = [];
    var body = [];
    this.Details.forEach((e, i) => {
      body.push([(i + 1), e.Deg_no, e.Reg_no, e.Name, e.F_name, e.rollno, e.Year])
    });

    const doc = new jsPDF('p', 'mm', [297, 210]);

    header.push(['Sr #', 'Degree No', 'Registration No', 'Name', 'Father Name', 'Roll No', 'Year']);

    autoTable(doc, {
      theme: "grid",
      margin: { top: 40, bottom: 5, left: 7, right: 5 },
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontSize: 7,
        fontStyle: 'bold'
      },
      bodyStyles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        fontSize: 6.5
      },
      head: header,
      body: body,
      didDrawPage: function (d) {
        //   y = d.cursor.y;
        doc.setFontSize(22);
        doc.setFont('Calibri', 'bold');
        doc.text("GC University Lahore", 105, 12, { align: 'center' });
        // doc.text("GC University Lahore", 150, 13, { align: 'center' });
        doc.setFontSize(12);
        doc.text("List of Candidates Eligible to Receive The Degree in Convocation  " + convo, 105, 20, { align: 'center' });
        doc.setFontSize(10);
        doc.setFont('Calibri', 'bold');
        doc.text(classname + " in " + majname, 105, 30, { align: 'center' });
      },
    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }
  DownloadExcel() {
    var Yeaar = this.formRef.value.Passing_year;
    let body = [];
    let header = [];
    let j = 1;

    header.push(['Sr#', 'Degree No', 'Registration No', 'Name', 'Father Name', 'Roll No', 'Year']);
    this.Details.forEach((e, i) => {
      body.push([(i + 1), e.Deg_no, e.Reg_no, e.Name, e.F_name, e.rollno, e.Year])
    });

    new AngularCsv(body, "Degree Holder For Governer House List ", { headers: (header) });
  }

  OnClear(cls: NgForm) {
    cls.resetForm();
    this.isOpen = false;
  }

  Filter()
  {
    filter();
  }
}
