<form (ngSubmit)="onSubmit(f)" #f="ngForm">
  <div class="container-fluid">
    <div class="row card shadow">
      <div class="card-header">Pending Dues</div>
      <div class="row card-body pb-0 px-4">
        <div class="col-lg-4 col-md-6 form-group">
          <label for="C_Code">Department</label>
          <select #d_id="ngModel" class="form-control" name="d_id" id="d_id"
            ngModel required>
            <option *ngFor="let d of dept" [value]="d.D_id">{{d.D_NM}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="year_in">Year</label>
          <select class="form-control" id="year_in" aria-label="Year" ngModel
            required name="year_in">
            <option *ngFor="let s of sessions" [value]="s">{{s}}</option>
          </select>
        </div>
      </div>

      <div class="row card-body">
        <button type="submit" class="btn btn-outline-primary"
          [disabled]="f.invalid">
          Get Report
        </button>
        <button *ngIf="this.studentData.length>0" type="button"
          class="btn ml-3 btn-outline-primary" (click)="CreatePDF()">
          Download
        </button>
      </div>
      <div
        style="display:flex;justify-content:center; align-items: center;"><loader
          *ngIf="loader"></loader></div>

      <div class="px-4" *ngIf="studentData.length > 0">
        <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">
          <input class="form-control col-lg-4" type="text" name="Search"
            placeholder="Search" id="myInput" (keydown)="Filter()">
        </div>
        <table
          class="table table-bordered table-striped mt-4"
          id="table">
          <thead class="thead-dark">
            <th>
              Sr #
            </th>
            <th>
              Roll Number
            </th>
            <th>
              Name
            </th>
            <th>
              Unpaid Challans
            </th>
            <th>
              Pending Amount
            </th>
          </thead>
          <tbody>
            <tr *ngFor="let i of this.studentData; let j=index" id="values">
              <td>
                {{j+1}}
              </td>
              <td>
                {{i.rolno}}
              </td>
              <td>
                {{i.nm}}
              </td>
              <td>
                {{i.Unpaid_Challans}}
              </td>
              <td>
                {{i.pending_amount}}
              </td>
            </tr>
            <tr>
              <th scope="row" colspan="4" class="text-center">Total Amount</th>
              <th scope="row">{{totalAmount}}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>
