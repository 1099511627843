import { Component, OnInit } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';

@Component({
  selector: 'app-adm-change-record',
  templateUrl: './adm-change-record.component.html',
  styleUrls: ['./adm-change-record.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class AdmChangeRecordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
