import { Component, OnInit,ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { GroupService } from './../shared/services/Groups.service';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { MajorService } from './../shared/services/Major.service';
import { FeeService } from '../shared/services/Fee.service';

@Component({
  selector: 'app-subject-fee',
  templateUrl: './subject-fee.component.html',
  styleUrls: ['./subject-fee.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class SubjectFeeComponent implements OnInit {
  groups: Array<SelectList>;
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  subjects: Array<{sub_code:string,sub_nm:string}>;
  subFee: Array<{sub_code:any,sub_nm:any,amount:any}>;
  group: number;
  isAdmin: boolean = false;
  flag:boolean = true;
  @ViewChild('f') formRef: NgForm;

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private groupService: GroupService,
    private classService: ClassService,
    private feeService:FeeService,
    private majorService: MajorService
  ) { 
    this.groups = new Array<SelectList>();
    this.classes = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.subjects = new Array<{sub_code:string,sub_nm:string}>();
    this.subFee = new Array<{sub_code:any,sub_nm:string,amount:number}>();
    this.isAdmin = this.authenticationService.isAdmin();
    this.group = this.authenticationService.getGroup();

  }

  ngOnInit(): void {
    // this.loadGroups();
    this.updateClasses();
  }

  myFunction(){
    this.flag=!this.flag;
       var popUp;
       popUp=document.getElementById("myPopup");
   
   
       if (popUp.style.display === "none") {
         popUp.style.display = "block";
       } else {
         popUp.style.display = "none";
       }
     }
  
  loadGroups() {
    this.classes = [];
    this.majors = [];
    this.groupService.getGroups({ grp: this.group, role: this.isAdmin }).subscribe((res: { GRP: number, G_NM: string }[]) => {
      res?.forEach(element => {
        this.groups.push({ id: element.GRP, nm: element.G_NM });
      });

      if (!this.isAdmin) {
        this.updateClasses();
      }
    }, err => {
      console.log(err);
    });
  }

  updateClasses() {
    this.classes = [];
    this.classService.getAllClasses().subscribe((res: { c_code: number, Des: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.c_code, nm: entry.Des });
      });
    });
  }

  updateMajors() {
    this.majors = [];
    this.majorService.getMajor({ grp: this.group.toString(), c_code: this.formRef.value.c_code }).subscribe((res: { MAJ_ID: number, MAJ_NM: string }[]) => {
      res?.forEach(entry => {
        this.majors.push({ id: entry.MAJ_ID, nm: entry.MAJ_NM });
      });
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    });
  }

  updateSubjects() {
    this.subjects = [];
    if (!this.group || this.formRef.value.c_code === "" ||
    this.formRef.value.maj_id === "" || this.formRef.value.year === "") return;


    this.feeService.getSubjForFee(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.year).subscribe((res: { SUB_CODE: string, SUB_NM: string}[]) => {
      res?.forEach(entry => {
        this.subjects.push({ sub_code: entry.SUB_CODE, sub_nm: entry.SUB_NM});
      });
    });
    this.loadSubjFee();
  }

  addSubjectFee(){
    if(this.formRef.valid){
        this.feeService.InsertSubjFee(this.formRef.value.c_code,this.formRef.value.maj_id,this.formRef.value.year,this.formRef.value.sub_code,this.formRef.value.amnt).subscribe((res:{msg: string ,warning: string}) => {
          if(!res){
            this.toastr.error("Unknown Error");
          }
          else if(res.msg){
            this.toastr.success(res.msg);
            this.loadSubjFee();
          }
          else if(res.warning){
            this.toastr.warning(res.warning);
          }
        }, err => {
          console.log(err);
          this.toastr.error("Some Error Occured during Deletion!");
        });
      }
      else{
        this.toastr.warning("Some Fields are Missing!");
      }
      // this.loadSubjFee();
  }

  OnSubmit() {
    if (!this.formRef.valid)
      return;
  }

  OnGrpChange(grp) {
    this.group = parseInt(grp.value);
    this.updateClasses();
  }

  OnClassChange(c: HTMLSelectElement) {
      this.updateMajors();
  }

  OnMajorChange(m: HTMLSelectElement) {
  }
  
  loadSubjFee(){
    this.subFee=[];
      this.feeService.onGetSubjFee(this.formRef.value.c_code,this.formRef.value.maj_id,this.formRef.value.year).subscribe((res : {SUB_CODE: string,SUB_NM: string, amount: number}[]) => {
        if(!res){
          this.toastr.error("Unknown Error");
        }
        else{
          res?.forEach(element =>{
            this.subFee.push({sub_code:element.SUB_CODE,sub_nm:element.SUB_NM,amount:element.amount});
          })
        }
      }, err => {
        console.log(err);
        this.toastr.error("Some Error Occured!");
      });
  }

  deleteSubjectFee(subcode,index){
    this.feeService.deleteSubjectFee(this.formRef.value.c_code,this.formRef.value.maj_id,this.formRef.value.year,subcode).subscribe((res:{msg:string, warning:string}) => {
      if(!res){
        this.toastr.error("Unknown Error");
      }
      else if(res.msg){
        this.toastr.success(res.msg);
        this.subFee.splice(index,1);
      }
      else{
        this.toastr.warning(res.warning);
      }
    }, err => {
      this.toastr.error("Some Error Occured during Deleting Subject Fee!");
    });
  }

  onClear(f: NgForm){
   f.resetForm();
  }
}