import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { NgForm } from '@angular/forms';
import { reportsFeeService } from '../shared/services/reportsFee.service';
import { FeeService } from '../shared/services/Fee.service';
import jsPDF from 'jspdf';
import { DatePipe } from "@angular/common";
import { ToastrService } from 'ngx-toastr';
import autoTable from "jspdf-autotable";
import "jspdf-autotable";
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fee-refund-reports',
  templateUrl: './fee-refund-reports.component.html',
  styleUrls: ['./fee-refund-reports.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class FeeRefundReportsComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;
  [x: string]: any;
  subscription = new Subscription();
  constructor(
    private feeService: FeeService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private reportsFee: reportsFeeService,
    private feeSharedService: FeeSharedDataService
  ) {
    this.classesArr = [];
    this.sessions = []
    this.feeArr = []
    this.classes = new Map<number, string>()
  }

  ngOnInit(): void {
    this.updateClasses();
    this.updateSessions()
  }

  updateClasses() {
    this.classes = [];
    this.feeSharedService.getFeeClasses()
      .then(classes => {
        this.classesArr = classes;
        this.classesArr.forEach(res=> {
          this.classes.set(parseInt(res.id + ""), res.nm);
        })
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }
  
  updateSessions() {
    this.sessions = []
    this.feeSharedService.getFeeSessions()
      .then(sessions => {
        this.sessions = sessions;
      })
      .catch(error => {
        console.error("Error getting fee session", error);
      });
  }

  UpdateRepType() {
    this.rep = this.formRef.value.rep;
  }

  StartDate(date) {
    if (date == '')
      this.startDate = null
    else {
      this.startDate = date;
    }
  }

  EndDate(date) {
    if (date == '')
      this.endDate = null
    else {
      this.endDate = date;
    }
  }

  getStdName(e) {
    const { c_code, rn, year, actyear } = this.formRef.value;
    if (this.rep != 5)
      this.year_in = year
    else
      this.year_in = actyear;
    if (e.keyCode != 13) {
      this.subscription.add(this.feeService.getStdNameByRn(c_code, this.year_in, rn).subscribe((res: any) => {
        this.name = res[0]?.nm
        this.sessionVar = res[0]?.session
        this.rolno = res[0]?.rolno
        this.rnCheck = rn;
      }))
    }
  }

  onReport(repNm) {
    const { c_code, rn, year, actyear } = this.formRef.value;
    this.feeArr = []
    this.subscription.add(this.reportsFee.FeeRefundReport(c_code, rn, this.startDate, this.endDate, this.rep, year, actyear).subscribe((res: any) => {
      res[0].forEach(e => {
        if (this.feeArr.findIndex(obj => (obj.rn == e.rn) && (obj.se_id == e.se_id) && (obj.c_code == e.c_code) && (obj.maj_id == e.maj_id) && (obj.actyear == e.actyear) && (obj.fine_entry_date == e.fine_entry_date)) == -1) {
          let tuition = 0, exam = 0, lib = 0, host = 0;
          if (e.fine_id == 2)
            tuition = e.amnt;
          else if (e.fine_id == 3)
            exam = e.amnt;
          else if (e.fine_id == 7)
            lib = e.amnt;
          else if (e.fine_id == 30)
            host = e.amnt;

          this.feeArr.push({
            fine_entry_date: e.fine_entry_date, c_code: e.c_code, maj_id: e.maj_id, rolno: e.rolno, class: e.class, session: e.session, actyear: e.actyear, se_id: e.se_id,
            rn: e.rn, nm: e.nm, tuition: tuition, exam: exam, lib: lib, host: host, f_nm: e?.f_nm
          });
        }
        else {
          var index = this.feeArr.findIndex(obj => (obj.rn == e.rn) && (obj.se_id == e.se_id) && (obj.maj_id == e.maj_id) && (obj.actyear == e.actyear) && (obj.c_code == e.c_code) && (obj.fine_entry_date == e.fine_entry_date));
          if (e.fine_id == 2)
            this.feeArr[index].tuition = e.amnt;
          else if (e.fine_id == 3)
            this.feeArr[index].exam = e.amnt;
          else if (e.fine_id == 7)
            this.feeArr[index].lib = e.amnt;
          else if (e.fine_id == 30)
            this.feeArr[index].host = e.amnt;

        }
      })
      // this.feeArr.sort((a: any, b: any) => parseInt(a.rn) - parseInt(b.rn));
      // this.feeArr.sort((a: any, b: any) => parseInt(a.actyear) - parseInt(b.actyear));
      // this.feeArr.sort((a: any, b: any) => parseInt(a.c_code) - parseInt(b.c_code));
      this.CreatePDF(repNm)
    }))
  }

  CreatePDF(repNm) {
    const { year, c_code, maj_id } = this.formRef.value;
    if (this.feeArr?.length == 0) {
      this.toastr.warning("No Record Found");
      return;
    }
    else {
      var body = [];
      const header = [];
      let sr = 1;
      var exportDate = this.datePipe.transform(new Date(), 'MMM d, y')
      var image = new Image();
      var page;
      let y = 50, dateWidth, rolnoWidth, classWidth, sessionsWidth, nmWidth, tuitWidth, nameVar = '', faNmVar = '', rnVar = '', classVar = '',
        examWidth, libWidth, hostWidth, totWidth, colSpanAdj, topVar, bottomVar, leftVar, rightVar, bodyFont, headFont, startDt = this.startDate, endDt = this.endDate;
      image.src = '../../../assets/images/logo3.png';
      if ((this.rep == 1) || (this.rep == 2)) {
        header.push(['DATE', 'ROLL #', 'CLASS', 'SESSION', 'NAME', 'TUITION FEE', 'EXAM FEE', 'LIB SEC', 'HOST SEC', 'TOTAL']);
        this.feeArr.forEach(e => {
          body.push([
            { content: e.fine_entry_date, styles: { fontStyle: "bold" } }, { content: e.rolno, styles: { fontStyle: "bold" } }, { content: e.class, styles: { fontSize: 5.5 } }, e.session, { content: e.nm, styles: { fontSize: 6.5 } },
            e.tuition, e.exam, e.lib, e.host, { content: parseInt(e.tuition) + parseInt(e.exam) + parseInt(e.lib) + parseInt(e.host), styles: { fontStyle: "bold", fontSize: 7 } }
          ])
        })
        dateWidth = 20; rolnoWidth = 25; classWidth = 39; sessionsWidth = 16; nmWidth = 34; tuitWidth = 13; examWidth = 12; libWidth = 12; hostWidth = 12;
        totWidth = 12; colSpanAdj = 5; topVar = 50; bottomVar = 18; leftVar = 7; rightVar = 7, bodyFont = 6.2, headFont = 6.5;
      }
      else if ((this.rep == 3) || (this.rep == 4) || (this.rep == 6)) {
        header.push(['DATE', 'ROLL #', 'SESSION', 'NAME', 'TUITION FEE', 'EXAM FEE', 'LIBRARY SECURITY', 'HOST SECURITY', 'TOTAL']);
        this.feeArr.forEach(e => {
          body.push([
            { content: e.fine_entry_date, styles: { fontStyle: "bold" } }, { content: e.rolno, styles: { fontStyle: "bold" } }, e.session, { content: e.nm, styles: { fontSize: 6.5 } },
            e.tuition, e.exam, e.lib, e.host, { content: parseInt(e.tuition) + parseInt(e.exam) + parseInt(e.lib) + parseInt(e.host), styles: { fontStyle: "bold", fontSize: 7 } }
          ])
        })
        dateWidth = 20; rolnoWidth = 25; classWidth = 20; sessionsWidth = 40; nmWidth = 18; tuitWidth = 18; examWidth = 18; libWidth = 18; hostWidth = 18;
        totWidth = 0; colSpanAdj = 4; topVar = 50; bottomVar = 18; leftVar = 7; rightVar = 7, bodyFont = 6.2, headFont = 6.5;
      }
      else if ((this.rep == 5)) {
        header.push([{ content: 'REFUND HISTORY', colSpan: 6, styles: { fontSize: 12, fontStyle: 'bold', halign: "center" } }]);
        header.push(['DATE', 'TUITION FEE', 'EXAM FEE', 'LIBRARY SECURITY', 'HOST SECURITY', 'TOTAL']);
        this.feeArr.forEach(e => {
          body.push([
            { content: e.fine_entry_date, styles: { fontStyle: "bold" } }, { content: e.tuition }, e.exam, { content: e.lib },
            e.host, { content: parseInt(e.tuition) + parseInt(e.exam) + parseInt(e.lib) + parseInt(e.host), styles: { fontStyle: "bold", fontSize: 7 } }
          ])
          nameVar = e.nm, faNmVar = e.f_nm, rnVar = e.rolno, classVar = e.class
        })
        dateWidth = 32; rolnoWidth = 32; classWidth = 32; sessionsWidth = 32; nmWidth = 32; tuitWidth = 32; examWidth = 0; libWidth = 0; hostWidth = 0;
        totWidth = 0; colSpanAdj = 1; topVar = 50; bottomVar = 18; leftVar = 8; rightVar = 7, y = 80, bodyFont = 6.8, headFont = 7;

      }
      body.push([{ content: "TOTAL", colSpan: colSpanAdj, styles: { fontSize: 7, fontStyle: "bold", halign: "center" } },
      { content: this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.tuition, 0), styles: { fontStyle: "bold", fontSize: 7 } },
      { content: this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.exam, 0), styles: { fontStyle: "bold", fontSize: 7 } },
      { content: this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.lib, 0), styles: { fontStyle: "bold", fontSize: 7 } },
      { content: this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.host, 0), styles: { fontStyle: "bold", fontSize: 7 } },
      {
        content:
          this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.tuition, 0) +
          this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.exam, 0) +
          this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.lib, 0) +
          this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.host, 0),
        styles: { fontStyle: "bold", fontSize: 7 }
      }
      ])
      const doc = new jsPDF('p', 'mm', 'A4');

      autoTable(doc, {
        theme: 'grid',
        startY: y,
        margin: { top: topVar, bottom: bottomVar, left: leftVar, right: rightVar },
        head: header,
        body: body,
        columnStyles: {
          0: { cellWidth: dateWidth },
          1: { cellWidth: rolnoWidth },
          2: { cellWidth: classWidth },
          3: { cellWidth: sessionsWidth },
          4: { cellWidth: nmWidth },
          5: { cellWidth: tuitWidth },
          6: { cellWidth: examWidth },
          7: { cellWidth: libWidth },
          8: { cellWidth: hostWidth },
          9: { cellWidth: totWidth },
        },
        // foot:footer,
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0], // sets the text color of the header to black
          lineColor: [0, 0, 0],
          lineWidth: 0.1, // sets the width of the border to 0.2
          fontSize: headFont,
          fontStyle: 'bold'
        }, // sets the border color of the header to black
        bodyStyles: {
          lineWidth: 0.1, // sets the width of the border to 0.2
          lineColor: [0, 0, 0], // sets the color of the border to black
          fontSize: bodyFont,
        },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 8,
          fontStyle: 'bold'
        },
        showHead: "everyPage",
        showFoot: "lastPage",

        didDrawPage: function () {
          doc.addImage(image, 170, 5, 25, 30);
          doc.setFontSize(10);
          doc.text(`${exportDate}`, 172, 40);
          doc.setFontSize(24);
          doc.setFont("Arial");
          doc.setFont('Arial', 'bold');
          doc.text("GC UNIVERSITY, LAHORE", 100, 22, { align: 'center' });
          doc.setFontSize(18);
          doc.setFont('Arial', 'bold');
          doc.text("Fee Refund Report (" + (repNm == 1 ? "All Classes" : (repNm == 2 ? "All Classes" : (repNm == 3 ? "Class Wise" : (repNm == 4 ? "Class Wise" : (repNm == 5 ? "Student Wise" : "Date Wise"))))) + ")", 100, 30, { align: 'center' });
          doc.text("_____________________________", 100, 30, { align: 'center' });
          doc.setFontSize(16);
          doc.text((repNm == 1 ? "Session: " + year : (repNm == 2 ? "All Sessions" : (repNm == 3 ? classVar + " (Session: " + year + ")" : (repNm == 4 ? classVar + " (All Sessions)" : (repNm == 5 ? "Session: " + year : "All Sessions"))))), 100, 38, { align: 'center' });
          doc.text((repNm != 5 ? startDt + " To " + endDt : ''), 100, 46, { align: 'center' });
          if (repNm == 5) {
            doc.setFontSize(14);
            doc.setFont('Helvetica', 'normal');
            doc.text("Roll No: ", 15, 59);
            doc.setFont('Helvetica', 'bold');
            doc.text(rnVar, 35, 59);
            doc.setFontSize(11);
            doc.setFont('Helvetica', 'normal');
            doc.text("Class: ", 105, 59);
            doc.setFontSize(10);
            doc.setFont('Helvetica', 'bold');
            doc.text(classVar, 119, 59);
            doc.setFontSize(11);
            doc.setFont('Helvetica', 'normal');
            doc.text("Name: ", 15, 69);
            doc.setFontSize(10);
            doc.setFont('Helvetica', 'bold');
            doc.text(nameVar, 29, 69);
            doc.setFontSize(11);
            doc.setFont('Helvetica', 'normal');
            doc.text("S/D of: ", 105, 69);
            doc.setFontSize(10);
            doc.setFont('Helvetica', 'bold');
            doc.text(faNmVar, 119, 69);
            doc.setFont('Helvetica', 'bold');
            doc.setDrawColor("Black");
            doc.roundedRect(8, 50, 192, 25, 2, 2, 'S');
            doc.setDrawColor('Black');
          }
          doc.setFontSize(10);
          doc.setFont('Helvetica', 'bold');
          doc.text('Prepared By:___________________', 8, 286);
          doc.text('Checked By:___________________', 75, 286);
          doc.text('Treasurer:___________________', 140, 286);
          page = '';
          page = doc.getNumberOfPages();
        }

      });
      doc.setFontSize(7);
      for (var i = 1; i <= page; i++) {
        doc.setPage(i);
        doc.text('Page ' + i + " of " + page, 100, 294);
        doc.text("Directorate of Information & Technology",10,295)
        doc.text("Note:- Errors and Omissions Excepted", 150, 295);
        var p = body.length;
      }
      // doc.save('Roll Number('+this.majorNm+')');
      window.open(URL.createObjectURL(doc.output('blob')), '_blank');
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
