import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-ev-f-show-charts',
  templateUrl: './ev-f-show-charts.component.html',
  styleUrls: ['./ev-f-show-charts.component.css']
})
export class EVFShowChartsComponent implements OnInit {
  @Input() arr:[{mean:number,per:number}]=[{mean:0,per:0}];
  @Input() CharDataCustom:[{secondHeading:string,grade:string, lab:string[], meanArr:number[],perArr:number[]}]=[{secondHeading:'',grade:'',lab:[],meanArr:[],perArr:[]}]; 

// charts data
        // pie chart data
        pieChartLabels: Label[] = ['MEAN', '%AGE'];
        pieChartData: number[] = [this.arr[0]?.mean, this.arr[0]?.per];
        pieChartType: ChartType = 'pie';
        pieChartLegend = true;
        pieChartPlugins = [];
        public pieChartOptions: ChartOptions = {
          responsive: true,
        };
// pie chart data finish
  
        // LINE CHART DATA.
  lineChartLabels: Label[] = [];
  lineChartData: ChartDataSets[] = [
    { data: [], label: 'MEAN' },
    { data: [], label: '%AGE' }
  ];
  lineChartOptions = {
    responsive: true,
    suggestedMin: 0,
    suggestedMax: 100
  };
  lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(103, 58, 183, .1)',
      borderColor: 'rgb(103, 58, 183)',
      pointBackgroundColor: 'rgb(103, 58, 183)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(103, 58, 183, .8)',
    },
  ];
  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
// LINE CHART END

  constructor() { }
  
  ngOnInit(): void {
    // pai chart settings and data push
    this.pieChartData = [this.arr[0]?.mean,this.arr[0]?.per];

    // line chart settings and data addition
    this.lineChartLabels = this.CharDataCustom[0]?.lab;
    this.lineChartData = [
      { data: this.CharDataCustom[0]?.meanArr, label: 'Mean' },
      { data: this.CharDataCustom[0]?.perArr, label: '%Age' }
    ];
  }
}
