import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from '../../../../../src/axios.js';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  private fmId: number;

  constructor(private http: HttpClient) {
  }
// Ya Allah Mil jae

  // getWholeAttendance(param) {
  //   return this.http.post(`${baseUrl}/api/attandence/getWholeAttendance`, param);
  // }

  getAttendence(params) {
    return this.http.post(`${baseUrl}/api/attandence/createAttendence`, params);
  }
  getAttendenceEmpty(params) {
    return this.http.post(`${baseUrl}/api/attandence/getAttendanceEmpty`, params);
  }
  getDateOfSubmittedAttandance(params){
    return this.http.post(`${baseUrl}/api/attandence/getDateOfSubmittedAttandance`, params);
  }
  getDateOfExistAttandance(params) {
    return this.http.post(`${baseUrl}/api/attandence/getDateOfExistAttandance`, params);
  }

  updateAttendance(params) {
    return this.http.put(`${baseUrl}/api/attandence/updateAttendance`, params);
  }

  DeleteAttendance(param) {
    return this.http.post(`${baseUrl}/api/attandence/DeleteAttendance`, param);
  }


  getAttendancePercentage(param) {
    return this.http.post(`${baseUrl}/api/attandence/getAttendancePercentage`, param);
  }
  getMonthlyAttendance(param) {
    return this.http.post(`${baseUrl}/api/attandence/getMonthlyAttendance`, param);
  }

  getAttendanceData(d_id:number,grp:number,c_code:number,se_id:number,t_no:number,sub_code:string,section:string) {    
    return this.http.get(`${baseUrl}/api/attandence/getAttendanceData/${d_id}/${grp}/${c_code}/${se_id}/${t_no}/${sub_code}/${section}`);
    
  }

  getStdAttendance(param) {
    return this.http.post(`${baseUrl}/api/attandence/getStdAttendance`, param);
  }

  getStdDetailAttendance(param) {
    return this.http.post(`${baseUrl}/api/attandence/getStdDetailAttendance`, param);
  }
  getCompleteSession(param) {
    return this.http.post(`${baseUrl}/api/attandence/getCompleteSession`,param)
  }

  getSingleStdAndAttendance(param) {
    return this.http.post(`${baseUrl}/api/attandence/getSingleStdAndAttendance`, param);
  }

  getteachersname(){
    return this.http.post(`${baseUrl}/api/attandence/getteachersname`, {});
  }
}
