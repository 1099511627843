<form #f="ngForm">
    <div class=" container-fluid" [@SlideInFromLeft]>
        <div class="card shadow">
            <div class="card-header">
                <div>Check Student Data</div>
            </div>

            <div class="card-body row">
                <div class="form-group col-lg-4">
                    <label for="sub">Classes</label>
                    <select class="form-control" id="sub" aria-label="ClassCode" ngModel required name="c_code"
                        (change)="loadRolno()" (change)="ClearClassBase()">
                        <option *ngFor="let i of classes" value="{{ i.id }}">
                            {{ i.nm }}
                        </option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="Year">Year</label>
                    <select class="form-control" #session name="year_in" id="Year" (change)="loadRolno()" ngModel (change)="ClearYearBase()">
                        <option *ngFor="let s of sessions" value="{{s}}">{{s}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="rolno">Roll Number</label>
                    <select class="form-control" name="rolno" id="rolno" ngModel required>
                        <option *ngFor="let r of rolnos" value="{{r.nm}}">{{r.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="Installment">Installment</label>
                    <select #installment class="form-control " #session name="inst_no" ngModel>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="10">10</option>
                    </select>
                </div>

                <div style="margin-left:12px;margin-top:25px">
                    <button class="btn btn-primary" (click)="FetchStdData()" [disabled]="!f.valid">Check Student Dues
                        Information</button>
                </div>
            </div>
            <div>
                <ng-container *ngIf="loader==true then ifloader;else notLoader"></ng-container>
                <ng-template #ifloader>
                    <div style="display:flex;justify-content:center; align-items: center;margin:5% 0% 0% 0%"><span
                            class="loader"></span></div>
                </ng-template>
                <ng-template #notLoader>


                    <div class="card-body" style="margin-bottom:4%" *ngFor="let i of StudentCompInfo;let p=index">
                        <div class="card" [ngClass]="{over:i.fee_sec_lock==1}"
                            style="box-shadow: 2px 2px 15px #888888;margin-bottom:2%">
                            <div style="display:flex;justify-content:space-between" class="row">
                                <div style="font-size:20px;font-weight:bold;margin:2% 0% 0% 8%">CHALLAN # {{i.ch_no}}
                                </div>
                                <div style="margin:2% 8% 0% 0%">
                                    <ng-container *ngIf="i.paid==1; then ifBlock else elseBlock"></ng-container>
                                    <ng-template #elseBlock>
                                        <span style="font-size:20px;color:red;font-weight:bold" *ngIf="i.sub_inst==0">
                                            UNPAID
                                        </span>
                                    </ng-template>
                                    <ng-template #ifBlock>
                                        <span style="font-size:20px;color:green;font-weight:bold;">
                                            PAID
                                        </span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="card-header" [ngClass]="{over2:i.fee_sec_lock==1}"
                                style="justify-content:space-evenly">
                                <span>
                                    Installment # {{i.inst_no}}
                                </span>
                                <span>
                                    Sub Installments # {{i.sub_inst_no}}
                                </span>

                                <span>
                                    Due Date: {{i.due_date| date : "dd-MM-y"}}
                                </span>
                                <span>
                                    Paid Date: {{i.paidDate}}
                                </span>
                            </div>

                            <div class="card-body row"
                                style="padding-top:2%;padding-bottom:2%; justify-content:space-evenly;font-size:13px">
                                <div>
                                    <ng-container *ngIf="i.fee_sec_lock==1; then locked"></ng-container>
                                    <ng-template #locked>
                                        <h1 class="lock">
                                            <b>E X E M P T E D</b>
                                        </h1>
                                    </ng-template>
                                </div>
                                <div>
                                    <div style="display:flex;">
                                        Name:
                                        <pre> </pre> <b>{{i.nm.toUpperCase()}}</b>
                                    </div>
                                    <div style="display:flex;">
                                        Buyer Code:
                                        <pre> </pre> <b>{{i.num}}</b>
                                    </div>
                                    <div style="display:flex;">
                                        Bank:
                                        <pre> </pre> <b>{{i.bank_nm}}</b>
                                    </div>
                                    <div style="display:flex;">
                                        Scholarship:
                                        <pre> </pre> <b>{{i.schp_dues_des}}</b>
                                    </div>
                                    <div style="display:flex;">
                                        Fee Concession:
                                        <pre> </pre> <b>{{i.fee_conc_des}}</b>
                                    </div>
                                </div>

                                <div>
                                    <div style="display:flex;">
                                        Father Name:
                                        <pre> </pre> <b>{{i.f_nm.toUpperCase()}}</b>
                                    </div>
                                    <div style="display: flex;">
                                        Description/University Dues:
                                        <pre> </pre><b>{{i.Unides}}</b>
                                    </div>
                                    <div style="display:flex;">
                                        Date Extension:
                                        <pre> </pre> <b>{{i.dtExt}}</b>
                                    </div>
                                    <div style="display: flex;">
                                        Scholarship Amount
                                        <pre> </pre><b>{{i.schp_amnt}}</b>
                                    </div>
                                    <div style="display:flex;">
                                        Fee Concession Amount:
                                        <pre> </pre> <b>{{i.fee_amnt}}</b>
                                    </div>

                                </div>

                                <div>
                                    <div style="display:flex;">
                                        Major:
                                        <pre> </pre> <b>{{i.maj}}</b>
                                    </div>

                                    <div style="display: flex;">
                                        Actual Amount:
                                        <pre> </pre><b>{{i.actAmnt}}</b>
                                    </div>

                                    <div style="display: flex;">
                                        Dues Adjustment:
                                        <pre> </pre><b>{{i.dues_adj_des}}</b>
                                    </div>
                                    <div style="display: flex;">
                                        Scholarship Entry Date:
                                        <pre> </pre><b>{{i.schp_dt}}</b>
                                    </div>
                                    <div style="display: flex;">
                                        Fee Conc Entry Date:
                                        <pre> </pre><b>{{i.fee_dt}}</b>
                                    </div>

                                </div>
                            </div>

                            <div *ngIf="subinst&&i.ch_no==ch_no_in" style="padding-left:5%;padding-right:5%;">
                                <div class="shadow bg-white ">
                                    <table class="table table-bordered table-striped"
                                        style="font-size:12px">
                                        <thead class="thead-dark">
                                            <tr style="font-size:11px;text-align:center">
                                                <th scope="col">CHALLAN NO</th>
                                                <th scope="col">SUB INSTALLMENT NO</th>
                                                <th scope="col">AMOUNT</th>
                                                <th scope="col">DUE DATE</th>
                                                <th scope="col">PAID DATE</th>
                                                <th scope="col">STATUS</th>
                                                <th scope="col">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let f of SubInstall;let k = index" style="text-align:center;font-weight:bold">
                                                <td>{{f.ch_no}}</td>
                                                <td>{{f.inst_no}}</td>
                                                <td>{{f.amount}}</td>

                                                <td style="display:flex;text-align:center;">
                                                    <div style="text-align:center;flex-grow:1">
                                                        <ng-container
                                                            *ngIf="D_Date==false&&f.ch_no==sub_chno then elseBlock;else ifBlock">
                                                        </ng-container>

                                                        <ng-template #ifBlock>
                                                            <span>
                                                                {{f.due_date| date : "dd-MM-y"}}
                                                            </span>
                                                        </ng-template>
                                                        <ng-template #elseBlock style="align-items:center">
                                                            <span style="align-items:center">
                                                                <input type="date" value="{{dueDateSubArr[k]}}"
                                                                    style="font-size:12px;" [maxLength]="3"
                                                                    class="form-control col-lg-11" name="exDate" #exDate
                                                                    (change)="DueDateSub(exDate.value,f.ch_no,k)">
                                                            </span>
                                                        </ng-template>
                                                    </div>
                                                    <div style="align-items:right">
                                                        <button *ngIf="f.status==0" class="btn btn-success" style="float:right;
                                           padding: 1% 8% 1% 15%;align-items:center" (click)="extendDateSub(f.ch_no,k)">
                                                            <ng-container *ngIf="D_Date then ifBlock"></ng-container>
                                                            <ng-template #ifBlock><i class="fa fa-edit"
                                                                    style="font-size:1vw;margin-top:-0.2%"></i>
                                                            </ng-template>

                                                            <ng-container
                                                                *ngIf="!D_Date&&sub_chno==f.ch_no then elseBlock">
                                                            </ng-container>
                                                            <ng-template #elseBlock><i class="fa fa-check"
                                                                    style="font-size:1vw;margin-top:-0.2%;"></i>
                                                            </ng-template>

                                                        </button>
                                                    </div>


                                                </td>
                                                <td>{{f.paid_date||0}}</td>
                                                <td>
                                                    <ng-container *ngIf="f.status==1 then paid; else unpaid">
                                                    </ng-container>
                                                    <ng-template #paid>
                                                        <span style="color:green">PAID</span>
                                                    </ng-template>
                                                    <ng-template #unpaid>
                                                        <span style="color:red">UNPAID</span>
                                                    </ng-template>
                                                </td>
                                                <td>
                                                    <div class="row" style="align-items:center;justify-content:center">
                                                        <button class="btn btn-primary"
                                                            style="font-size:10px;padding-top:3%;padding-bottom:3%;margin-right:2%;margin-left:3%"
                                                            (click)="CreatePdfDem(f,1,k)">Download</button>
                                                        <button class="btn btn-warning"
                                                            *ngIf="f.status!=1&&SubInstall[SubInstall.length-1].inst_no==f.inst_no&&SubInstall.length>2&&countUnpaid()>1"
                                                            style="font-size:10px;padding-top:3%;padding-bottom:3%"
                                                            (click)="DelManageSubInstallment(i.ch_no,f.ch_no)">Merge</button>
                                                    </div>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div *ngIf="i.outstandingDues==1" style="padding-left:5%;padding-right:5%;">
                                <div class="shadow bg-white ">
                                    <table class="table table-bordered table-striped"
                                        style="font-size:10px">
                                        <thead class="thead-dark">
                                            <tr style="font-size:10px;text-align:center">
                                                <th scope="col" colspan="6"> OUTSTANDING DUES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style="text-align:center;font-weight:bold"
                                                *ngFor="let f of feeDesc1;let u=index">
                                                <td>
                                                    {{f?.nm}}
                                                </td>
                                                <td>
                                                    <input #val1 (keyup)="AddOutDues(i.ch_no,val1.value,f.id)"
                                                        value="{{getExistingOutDues.get(i.ch_no+'')?.get(f.id+'')}}"
                                                        style="width:100%">
                                                </td>
                                                <td>
                                                    {{feeDesc2[u]?.nm}}
                                                </td>
                                                <td>
                                                    <input #val2
                                                        (keyup)="AddOutDues(i.ch_no,val2.value,feeDesc2[u]?.id)"
                                                        value="{{getExistingOutDues.get(i.ch_no+'')?.get(feeDesc2[u]?.id+'')}}"
                                                        style="width:100%">
                                                </td>
                                                <td>
                                                    {{feeDesc3[u]?.nm}}
                                                </td>
                                                <td *ngIf="feeDesc3[u]?.nm">
                                                    <input #val3
                                                        (keyup)="AddOutDues(i.ch_no,val3.value,feeDesc3[u]?.id)"
                                                        value="{{getExistingOutDues.get(i.ch_no+'')?.get(feeDesc3[u]?.id+'')}}"
                                                        style="width:100%">
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div style="margin-bottom:2%">

                                <span style="color:green;font-size:20px;font-weight:bold;margin-left:6%"
                                    *ngIf="i.sub_inst==0&&i.paid==1">
                                    {{i.paidStatus}}
                                </span>
                                <span style="font-size:20px;font-weight:bold;margin-left:7%"
                                *ngIf="i.sub_inst==0&&i.paid==0">
                                Payable Amount: <b style="color:red;">{{i.curPayable}}</b>
                                </span>
                                <span style="float:right;margin-right:6%"><button class="btn btn-primary"
                                        style="font-size:12px" (click)="DownloadPdf(i,0,p)"
                                        [disabled]="i.fee_sec_lock==1">Download</button></span>

                                <span style="float:right;margin-right:1%;display:flex" *ngIf="i.paid==0&&i.sub_inst==0">

                                    <ng-container *ngIf="PChDate then ifBl;else elseBl"></ng-container>
                                    <ng-template #ifBl>
                                        <span *ngIf="i.outstandingDues==0">
                                            <button class="btn btn-primary" style="font-size:12px"
                                                [disabled]="i.fee_sec_lock==1" (click)="extendDate(i.ch_no,0,p)">Edit Due
                                                Date</button>
                                        </span>
                                    </ng-template>
                                    <ng-template #elseBl>
                                        <span>
                                            <button class="btn btn-primary" style="font-size:12px"
                                                [disabled]="i.fee_sec_lock==1" (click)="extendDate(i.ch_no,1,p)">Change Due
                                                Date</button>
                                        </span>
                                    </ng-template>
                                    <ng-container *ngIf="PChDate==false then elseBlock"></ng-container>
                                    <ng-template #elseBlock>
                                        <span style="display:flex;">
                                            <input type="date" value="{{curdate()}}"
                                                style="font-size:12px;margin-left:2%" [maxLength]="3"
                                                class="form-control" name="exDate" #exDate
                                                (change)="DueDate(exDate.value,i.ch_no,0,p)">
                                            <input style="font-size:12px;margin-left:2%" placeholder="Description"
                                                name="desc" ngModel required class="form-control ">
                                        </span>
                                    </ng-template>


                                </span>

                                <span style="float:right;margin-right:1%" *ngIf="i.sub_inst==1&&subinst==false">
                                    <button [disabled]="i.fee_sec_lock==1" class="btn btn-primary"
                                        style="font-size:12px" (click)="ViewSubinst(i.ch_no,true)">View
                                        SubInstallments</button>
                                </span>
                                <span style="float:right;margin-right:1%" *ngIf="i.sub_inst==1&&subinst==true">
                                    <button class="btn btn-primary" style="font-size:12px"
                                        [disabled]="i.fee_sec_lock==1" (click)="ViewSubinst(i.ch_no,false)">Close
                                        SubInstallments
                                    </button>
                                </span>
                                <span style="float:right;margin-right:1%" *ngIf="i.outstandingDues==0&&i.paid==0&&i.sub_inst==0">
                                    <button class="btn btn-primary" style="font-size:12px"
                                        (click)="OutstandingDues(p,1,i.ch_no)" [disabled]="i.fee_sec_lock==1">
                                        Insert Outstanding Dues
                                    </button>
                                </span>
                                <span style="float:right;margin-right:1%;margin-left:2%;"
                                    *ngIf="i.outstandingDues==1&&i.paid==0&&i.sub_inst==0">
                                    <button class="btn btn-primary" style="font-size:12px;"
                                        (click)="OutstandingDues(p,0,i.ch_no)" [disabled]="i.fee_sec_lock==1">
                                        Close Outstanding Dues
                                    </button>
                                </span>
                                <span style="display:flex;float:right;" *ngIf="i.outstandingDues==1">
                                    <input type="date" value="{{dueDate2[p]}}"
                                        style="font-size:12px;margin-left:2%;width:100%" [maxLength]="3"
                                        class="form-control" name="exDate2" #exDate2
                                        (change)="DueDate(exDate2.value,i.ch_no,1,p)">
                                </span>
                                <span *ngIf="i.sub_inst==0" style="float:right;margin-right:1%">
                                    <button class="btn btn-danger" style="font-size:12px"
                                        (click)="LockChallanByFeeSec(i.ch_no)"
                                        *ngIf="i.fee_sec_lock==0&&i.sub_inst==0&&i.paid==0">Exempt Challan</button>
                                    <button class="btn btn-success" style="font-size:12px"
                                        (click)="UnlockChallanByFeeSec(i.ch_no)"
                                        *ngIf="i.fee_sec_lock==1&&i.sub_inst==0&&i.paid==0">Unexempt Challan</button>
                                </span>

                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</form>
