<form (ngSubmit)="onSubmit()"  #f="ngForm">
  <div [@SlideInFromLeft]>
    <div class="container-fluid mb-2 card">
      <div class="card-header   d-flex justify-content-between" style="margin-bottom: -2px">
        <div class="">Attendance Relaxation</div>
      </div>
      <div class="row card-body mt-3">

        <div class="col-lg-6 form-group" style="margin-top: -15px;">
          <label for="year">Year</label>
          <input #department  type="number" style="margin-top: -8px;" class="form-control"
                  name="year" id="year" required ngModel>
<!--            <option *ngFor="let d of departments" value="{{d.deptId}}">{{d.deptName}}</option>-->
          </div>

        <div class="col-lg-6 form-group" style="margin-top: -15px;">
          <label for="C_Code">Class</label>
          <select #cource  style="margin-top: -8px;" class="form-control " name="c_code" (change)="onClassChange()"
                  id="C_Code" required ngModel>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>
        <div class="col-lg-6 form-group" style="margin-top: -15px;">
          <label for="Session">Session</label>
          <select class="form-control " style="margin-top: -8px;" #session
                  name="se_id" id="Session" ngModel required>
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>
        <div class="col-lg-6 form-group" style="margin-top: -15px;">
          <label for="Semester">Semester</label>
          <select #semester class="form-control " style="margin-top: -8px;" (change)="onSemesterChange()"
                 name="t_no" id="Semester" ngModel required>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
          </select>
        </div>
        <div class="col-lg-6 form-group" style="margin-top: -15px;">
          <label for="Roll no">Roll no</label>
          <select #rolno  class="form-control" style="margin-top: -8px;" name="rn" id="Roll no" (change)="onRollChange()" ngModel required>
            <option *ngFor="let s of students" value="{{s.rn}}">{{s.rollNo}}</option>
          </select>
        </div>
<!--        <div class="col-lg-6 form-group" style="margin-top: -15px;">-->
<!--          <label for="Roll no">Roll no</label>-->
<!--          <input #stname  class="form-control" style="margin-top: -8px;" name="rollNo" id="Roll no" (change)="showName()" ngModel required>-->
<!--        </div>-->
        <div class="col-lg-6 form-group" style="margin-top: -15px;">
          <label for="Name">Name</label>
          <input #stname type="text" class="form-control" style="margin-top: -8px;" [value]="stdname" name="stdname" id="Name" readonly ngModel>
        </div>
        <div class="col-lg-6 form-group" style="margin-top: -15px;">
          <label for="Subject">Subject</label>
          <select #subject class="form-control" style="margin-top: -8px;" name="sub_code" id="Subject" ngModel required>
            <option *ngFor="let sub of subjects" value="{{sub.sub_code}}"><{{sub.sub_code}}>{{sub.sub_nm}}</option>
          </select>
        </div>

        <div class="col-lg-6 form-group" style="margin-top: -15px;">
          <label for="relaxed_by">Relaxed by</label>
<!--          <label for="Teacher" *ngIf="!isTeacherFree" style="color: red;">*</label>-->
          <select #teacher class="form-control" style="margin-top: -8px;"
                  name="relaxed_by" id="relaxed_by" ngModel required>
            <option value="Vice Chancellor">Vice Chancellor</option>
            <option value="Dean">Dean</option>
            <option value="Chairperson">Chairperson</option>
          </select>
        </div>

        <div class="col-lg-6 form-group" style="margin-top: -15px;">
          <label for="relaxed">Attendance Relaxed</label>
          <select  class="form-control " style="margin-top: -8px;"
                  name="per_relax" id="relaxed" ngModel required>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
        </div>

        <button [disabled]="!f.valid" class="btn btn-primary mt-2"
                style="width: 100px; height: 40px; margin-left: 30px; margin-top: -15px; text-align: center;"
                type="submit">save</button>

      </div>
      <div class="card-body mb-3 mt-3" style="min-height: 5rem;">
        <div class="list-group shadow">

        </div>

      </div>
    </div>

  </div>
</form>


<!--<app-manage-teacher *ngIf="manageTeacher" (teacher)="OnNewTeacher($event) "(close)="OnCloseManageTeacher()"></app-manage-teacher>-->
