import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './../../../axios';
@Injectable({
  providedIn: 'root'
})
export class AttendanceRelaxationService {

  constructor(private http: HttpClient) {
  }

  
  EXAM_RESULT_RN_SECTION(params){
    return this.http.post(`${baseUrl}/api/result/EXAM_RESULT_RN_SECTION`,params);
  }

  getSections(isAdmin1,c_code1:number,se_id1:number,t_no1:number,grp1) {
    let params={isAdmin:isAdmin1,c_code:c_code1,se_id:se_id1,t_no:t_no1,grp:grp1}
    return this.http.post(`${baseUrl}/util/getSectionsByTerm`,params);
  }

  getSectionBySub_code(isAdmin1,c_code1:number,se_id1:number,t_no1:number,grp1,sub_code1) {
    let params={isAdmin:isAdmin1,c_code:c_code1,se_id:se_id1,t_no:t_no1,grp:grp1,sub_code:sub_code1}
    return this.http.post(`${baseUrl}/util/getSectionBySub_code`,params);
  }

  // tslint:disable-next-line:variable-name
  getStudents(year: number, c_code: string, se_id: string, t_no: string,sub_code:string) {
    return this.http.get(`${baseUrl}/Util/getStdForAttRelax/${year}/${c_code}/${se_id}/${t_no}/${sub_code}`);
  }
  // tslint:disable-next-line:variable-name
  getStName(year: number, c_code: string, se_id: string, t_no: string, rn: number) {
    return this.http.get(`${baseUrl}/Util/getStName/${year}/${c_code}/${se_id}/${t_no}/${rn}`);
  }
  // tslint:disable-next-line:variable-name
  getSubjectsForRelax(year: number, c_code: string, se_id: string, t_no: string, rn: number) {
    return this.http.get(`${baseUrl}/Util/getSubjectsForRelax/${year}/${c_code}/${se_id}/${t_no}/${rn}`);
  }

  insertAtt_relax(params) {
    return this.http.post(`${baseUrl}/api/attRex/insertAtt_relax`, params);
  }
  getStudentAndSection(param) {
    return this.http.post(`${baseUrl}/api/enrollment/getStudentAndSection`, param);
}

  getClasses(){
    return this.http.get(`${baseUrl}/api/class/getTmClasses/`);
}
 
 getSubject(c_code:number,se_id:number,t_no:number) {
   return this.http.get(`${baseUrl}/api/subjectsOffer/getOfferedSubByWholeClass/${c_code}/${se_id}/${t_no}`);
}  

getSessions(cls) {
 return this.http.get(`${baseUrl}/api/session/getSessionsFromTm/${cls}`);
}
getUSessions(cls) {
  return this.http.get(`${baseUrl}/api/session/getSessions/${cls}`);
 }
}
