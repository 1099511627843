<form (ngSubmit)="OnSubmit()" #f="ngForm">
    <div class=" container-fluid" [@SlideInFromLeft]>
        <div class="row card shadow">
            <div class="row card-body pb-0">
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="C_Code">Class</label>
                    <select #cource class="form-control " name="c_code" id="C_Code"
                        ngModel>
                        <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="b-id">Base</label>
                    <select #base class="form-control " name="b_id" id="b_id"
                        ngModel>
                        <option *ngFor="let b of admBase" value="{{b.id}}">{{b.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="year">Year</label>
                    <input type="number" class="form-control" name="year" min="2000" ngModel
                        required>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="inst_no">Installment</label>
                    <input type="number" class="form-control" name="inst_no" min="0" max="8" ngModel>
                </div>
            </div>

            <div class="row card-body ml-1">
                <button class="btn ml-1 btn-outline-primary" (click)="onDownloadReport()">
                    Download
                </button>
                <button class="btn ml-1 btn-outline-primary" (click)="onClear()">
                    Clear
                </button>
            </div>
        </div>
    </div>
</form>