import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClassBaseWiseCollectionReportComponent} from './class-base-wise-collection-report/class-base-wise-collection-report.component';
import { ClassWiseCollectionReportComponent} from './class-wise-collection-report/class-wise-collection-report.component'
import { CollectionReportComponent } from './collection-report.component';
import { GroupBaseWiseCollectionReportComponent} from './group-base-wise-collection-report/group-base-wise-collection-report.component';
import { StudentWiseCollectionReportComponent} from './student-wise-collection-report/student-wise-collection-report.component';
import { DemandCollectionReportClassWiseComponent } from './demand-collection-report-class-wise/demand-collection-report-class-wise.component';
import { PendingDuesComponent } from './pending-dues/pending-dues.component';
import { LevelWiseReportComponent } from './level-wise-report/level-wise-report.component';

const routes: Routes = [
  {
    path: '', component: CollectionReportComponent, children: [
    {path:'', redirectTo: 'student', pathMatch: 'full'},
    {path: 'student', component: StudentWiseCollectionReportComponent},
    {path: 'groupBase', component: GroupBaseWiseCollectionReportComponent},
    {path: 'classBase', component: ClassBaseWiseCollectionReportComponent},
    {path: 'classWise', component: ClassWiseCollectionReportComponent},
    {path: 'demand', component: DemandCollectionReportClassWiseComponent},
    {path: 'pendingDues', component: PendingDuesComponent},
    {path: 'levelwise' , component: LevelWiseReportComponent},
    ]}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CollectionReportRoutingModule { }
