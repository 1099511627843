<div [@SlideInFromLeft]>
  <div class="container-fluid card shadow" style="min-height: 07vh;">
    <div class="card-header"  style="padding-top: 10px;">
      <div class=" col-lg-6 col-md-6 form-group">
        AUTO SUBJECT OFFER AND REPORT</div>
         <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
        <app-details-button [details]="details"></app-details-button>
    </div>
  </div>>
    <form #f="ngForm">
      <div class="row card-body .space-remover">
        <div class="col-lg-4 col-md-6 form-group">
          <label for="c_code">Class</label>
          <select id="c_code" #cource (change)="OnClassChange()" name="c_code" class="form-control" ngModel>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="se_id">Session</label>
          <select class="form-control" name="se_id" id="se_id" #session ngModel>
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="semester">Semester</label>
          <select #semester class="form-control" name="t_no" id="Semester" (change)="OnSemesterChange()" required ngModel>
            <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]" value="{{i}}">{{i}}</option>
          </select>
        </div>

        <div class="row ml-3 mt-3">
          <button class="btn btn-primary" (click)="viewDetail()" [disabled]="!f.valid">View Detail</button>
          <button class="btn btn-danger ml-2" (click)="allMajListPDF()"
            [disabled]="this.subjects?.size==0"><i class="fa fa-file-pdf-o" for=""></i> Create
            PDF</button>
          <button class="btn btn-outline-primary ml-2" [disabled]="this.subjects?.size==0"
            (click)="SubjectShift()">{{this.suboffer ? 'Cancel' : 'Subject Offer' }}</button>
        </div>
      </div>
      <hr *ngIf="this.suboffer" style="margin-top: -1rem;">
      <div *ngIf="this.suboffer" class="row card-body .space-remover" style="margin-top: -2rem;">
        <!-- <div class="col-lg-4 col-md-6 form-group">
          <label for="c_code">Class</label>
          <select id="c_code" #cource (change)="OnClassChange()" name="c_code" class="form-control" ngModel>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div> -->
        <div class="col-12 ml-3 mr-3 alert alert-secondary alert-dismissible">
          <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
          Select <b>Session</b>, <b>Semester</b>, and <b>Major</b> for which you want to offer subjects which are displayed for above selected
          <strong>Session</strong> and <strong>Semester</strong>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="Nse_id">Session</label>
          <select class="form-control" name="Nse_id" id="Nse_id" #session ngModel>
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="semester">Semester</label>          
          <!-- <select #semester class="form-control" name="Nt_no" id="Semester" required ngModel> -->
            <select #semester class="form-control" name="Nt_no" id="Nt_no" required ngModel>
            <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]" value="{{i}}">{{i}}</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="Nmaj_id">Major</label>
          <select id="Nmaj_id" name="Nmaj_id" class="form-control" #major (change)="onMajorChange()" ngModel>
            <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
          </select>
        </div>
        <div class="row ml-3">
          <!-- <button class="btn btn-outline-primary" (click)="viewDetail()">View Detail</button> -->
          <button class="btn btn-primary" (click)="AutoSubjectOffer()"
            [disabled]="this.subjects?.size==0">Same Subject Offer for new Session and Semester
          </button>
        </div>
      </div>
    </form>

    <div class="card-header d-flex justify-content-between">
      Major List
    </div>
    <div class="row card-body">
      <ul class="maj-list w-100">
        <li class="p-3 col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3" *ngFor="let m of this.majorList; let i=index;">
          <!-- <a id="{{'#'+ m}}" (click)="navigateToSection(m)"><b>({{i}}) </b>{{m}}</a> -->
        <a name="{{m}}" style="width: 100%;">
          <span style="padding-top: 40px; margin-top: -40px; width: 100%;" id="{{'#'+ m}}" (click)="navigateToSection(m)"><b>({{i+1}})
            </b>{{m}}</span>
        </a>
        </li>
      </ul>
    </div>
    <div class="card-header" *ngIf="majorList.length>0">
      Details
    </div>
    <div class="container-fluid card shadow mb-4" *ngFor="let m of this.majorList; let i=index;" id="{{m}}">
      <div class="card-header d-flex justify-content-between">
        <div >
          {{m}}
        </div>
        <div>
          <button class="btn btn-sm btn-danger mr-3" (click)="singleMajPDF(m)"><i class="fa fa-file-pdf-o" for=""></i> Download
          </button>
        </div>
      </div>
      <div class="row card-body">
        <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
          <input class="form-control col-lg-4" type="text" name="Search" placeholder="Individual Search" id="myInput{{i+1}}" (keydown)="Filter(i+1)">
      </div>
        <table class="table table-bordered table-striped" id="table" id="table{{i+1}}">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Sr No</th>
              <th scope="col">Sub Code</th>
              <th scope="col">Name</th>
              <th scope="col">Cr. Hrs</th>
              <th scope="col">Grading</th>
              <th scope="col">Nature</th>
              <th scope="col">Type</th>
              <th scope="col">Set No</th>
              <th scope="col">AB/REL</th>
              <th scope="col">GP-Per</th>
              <!-- <th scope="col"></th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let s of getSubjects(m); let j = index;" id="values{{i+1}}" id="values">
              <td>{{j+1}}</td>
              <td>{{s.SUB_CODE}}</td>
              <td>{{s.SUB_NM}}</td>
              <td>{{s.CR_H}}</td>
              <td>{{(s.GP_PER=='G')?"GPA":"Percentage"}}</td>
              <td>{{s.DES_NM}}</td>
              <td>{{s.C_ELE}}</td>
              <td>{{s.SET_NO}}</td>
              <td>{{(s.AB_REL==1)?"RELATIVE":"ABSOLUTE"}}</td>
              <td>{{s.GP_PER}}</td>
              <!-- <td><button class="btn btn-outline-danger"
                  (click)="deleteSub(i, sub.SUB_CODE,sub.SET_NO )">Delete</button>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
