import { Injectable } from '@angular/core';
import {Router,CanActivate,Route, UrlSegment,
    ActivatedRouteSnapshot,RouterStateSnapshot, CanLoad
} from '@angular/router';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';

@Injectable({ providedIn: 'root' })
export class CanFeeStructureGaurd implements CanActivate, CanLoad {
    constructor(private authenticationService: AuthenticationService,
        private router: Router) {
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.authenticationService.canFeeStructure();
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean {
        return this.authenticationService.canFeeStructure();
    }
}