import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SlideInFromLeft } from 'src/app/transitions';
import { ClassService } from '../shared/services/Class.service';
import { DegreeprintService } from '../shared/services/degreeprint.service';
import { MajorService } from '../shared/services/Major.service';
import { ToastrService } from 'ngx-toastr';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';

interface STD {
  YEAR: number,
  SE_ID: number,
  C_CODE: number,
  ROLNO: string,
  RN: number,
  TRNS_NO: number,
  DEG_NO: number,
  DEG_REC_DATE: string,
  DEG_REC_YEAR: number,
  DEG_CTGRY: string,
  P_YEAR: string,
  D_O_NOTIF: string
}

interface TransData {
  YEAR: number,
  SE_ID: number,
  C_CODE: number,
  RN: number,
  TRNS_NO: number,
  p_year: number;

}
interface DegreeData {
  YEAR: number,
  SE_ID: number,
  C_CODE: number,
  RN: number,
  Degree_no: number,
  p_year: number;

}
interface Degree_Rcv_Date {
  YEAR: number,
  SE_ID: number,
  C_CODE: number,
  RN: number,
  deg_rcv_dte: Date,
  p_year: number;

}

@Component({
  selector: 'app-degree-recieve-info-test',
  templateUrl: './degree-recieve-info-test.component.html',
  styleUrls: ['./degree-recieve-info-test.component.css'],
  animations: [SlideInFromLeft()],
})

export class DegreeRecieveInfoTestComponent implements OnInit {
  @ViewChild("f") formRef: NgForm;
  class: Array<{ id: number, nm: string }>
  majors: Array<{ id: number, nm: string }>
  dataa: Array<STD>
  selectedIndex = -1;
  istrue: boolean = true;
  new_degre_no: number;
  new_date: Date;
  trans_no: number;
  isOpen: boolean = false;
  TransNoArray: Array<TransData>;
  DegreeNoArray: Array<DegreeData>;
  Degree_rcv_date: Array<Degree_Rcv_Date>;
  x: number = 0;
  check: boolean = false;
  constructor(private toaster: ToastrService, 
    private classes: ClassService, 
    private major: MajorService, 
    private degree: DegreeprintService,
    private lmsSharedService: LMSharedDataService) {
    this.class = [];
    this.majors = [];
    this.dataa = [];
    this.TransNoArray = [];
    this.DegreeNoArray = [];
    this.Degree_rcv_date = [];
  }

  ngOnInit(): void {
    this.updateClasses();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.class = classes;
        console.log(this.class)
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateMajors() {
    this.majors.length = 0;
    this.lmsSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  OnClick() {
    this.updateMajors();
  }
 
  save() {
    this.dataa = [];
    const { c_code, st_year, maj_id } = this.formRef.value
    this.degree.getInfo(c_code, maj_id, st_year).subscribe((info: STD[]) => {
      if (info.length != 0) {
        this.toaster.success("Data Fetched Successfully");
        this.dataa = info;
        this.isOpen = true;
        this.check = true;
      }
      else {
        this.toaster.warning("Data not Found");
      }

    })
  }
  Edit(num: number) {
    this.selectedIndex = num;
    this.istrue = false;
    // this.selectedIndex = num;
  }
  OnChange(i: number, det) {
    this.new_degre_no = i;
    this.DegreeNoArray.push({ YEAR: det.YEAR, SE_ID: det.SE_ID, C_CODE: det.C_CODE, RN: det.RN, Degree_no: this.new_degre_no, p_year: det.P_YEAR });
  }

  OnChange2(i: Date, det) {
    this.new_date = i;
    this.Degree_rcv_date.push({ YEAR: det.YEAR, SE_ID: det.SE_ID, C_CODE: det.C_CODE, RN: det.RN, deg_rcv_dte: this.new_date, p_year: det.P_YEAR });
  }
  OnChange3(i: number, det) {
    this.trans_no = i;
    this.TransNoArray.push({ YEAR: det.YEAR, SE_ID: det.SE_ID, C_CODE: det.C_CODE, RN: det.RN, TRNS_NO: this.trans_no, p_year: det.P_YEAR });
  }
  OnChange4(i: string, det) {
    this.degree.UpdateDegCtegory(det.C_CODE, det.YEAR, det.RN, det.SE_ID, i).subscribe((res: any) => {
      if (res != '') {
        this.toaster.success(res.success);
        this.save();
      }
    })
  }

  close(det) {
    if (this.new_date == undefined && this.new_degre_no == undefined && this.trans_no == undefined) {
      this.toaster.warning("empity fields");
      this.selectedIndex = -1;
      this.istrue = true;
    }
    else {
      this.degree.UpdateInfo(det.C_CODE, this.new_degre_no, det.YEAR, det.RN, det.SE_ID, this.new_date, this.trans_no)
        .subscribe((res) => {
          // const {warning, success} = res;
          if (res) {
            this.toaster.success("Successfull Update");
            this.save();
            this.selectedIndex = -1;
            this.istrue = true;
            this.new_date = null;
            this.new_degre_no = null;
            this.trans_no = null;
          }
          else {
            this.toaster.warning("Update Failed!");
          }
          this.new_date = null;
          this.new_degre_no = null;
          this.trans_no = null;
        })
    }


  }
  onClear(f: NgForm) {
    this.dataa = [];
    this.new_date = null;
    this.new_degre_no = null;
    this.trans_no = null;
    this.isOpen = false;
    this.check = false;
    f.resetForm();
  }

  OnClickALL() {
    var count = 0;
    if (this.TransNoArray.length != 0) {
      for (var i = 0; i < this.TransNoArray.length; i++) {
        this.degree.UpdateInfo(this.TransNoArray[i].C_CODE, null, this.TransNoArray[i].YEAR, this.TransNoArray[i].RN, this.TransNoArray[i].SE_ID, null, this.TransNoArray[i].TRNS_NO)
          .subscribe((res) => {
            count += 1;
            if (res) {
              this.selectedIndex = -1;
              this.istrue = true;
              this.new_date = null;
              this.new_degre_no = null;
              this.trans_no = null;
            }
            else {
              this.toaster.warning("Update Failed!");
            }
            this.new_date = null;
            this.new_degre_no = null;
            this.trans_no = null;
          });
        setTimeout(() => {                           // <<<---using ()=> syntax
          if (count != 0) {
            this.TransNoArray = [];
            this.toaster.success(`${count} Transcript no Record Updated!!`);
            this.save();

            count = 0;
          }
        }, 1000);
      }
    }
    else if (this.DegreeNoArray.length != 0) {
      for (var i = 0; i < this.DegreeNoArray.length; i++) {
        this.degree.UpdateInfo(this.DegreeNoArray[i].C_CODE, this.DegreeNoArray[i].Degree_no, this.DegreeNoArray[i].YEAR, this.DegreeNoArray[i].RN, this.DegreeNoArray[i].SE_ID, null, null)
          .subscribe((res) => {
            count += 1;
            if (res) {
              this.selectedIndex = -1;
              this.istrue = true;
              this.new_date = null;
              this.new_degre_no = null;
              this.trans_no = null;
            }
            else {
              this.toaster.warning("Update Failed!");
            }
            this.new_date = null;
            this.new_degre_no = null;
            this.trans_no = null;
          });
        setTimeout(() => {                           // <<<---using ()=> syntax
          if (count != 0) {
            this.DegreeNoArray = [];
            this.toaster.success(`${count} Degree no Record Updated!!`);
            this.save();

            count = 0;
          }
        }, 1000);
      }
    }

    else if (this.Degree_rcv_date.length != 0) {
      for (var i = 0; i < this.Degree_rcv_date.length; i++) {
        this.degree.UpdateInfo(this.Degree_rcv_date[i].C_CODE, null, this.Degree_rcv_date[i].YEAR, this.Degree_rcv_date[i].RN, this.Degree_rcv_date[i].SE_ID, this.Degree_rcv_date[i].deg_rcv_dte, null)
          .subscribe((res) => {
            count += 1;
            // const {warning, success} = res;
            if (res) {
              // this.toaster.success("Successfull Update");
              this.selectedIndex = -1;
              this.istrue = true;
              this.new_date = null;
              this.new_degre_no = null;
              this.trans_no = null;
            }
            else {
              this.toaster.warning("Update Failed!");
            }
            this.new_date = null;
            this.new_degre_no = null;
            this.trans_no = null;
          });
        setTimeout(() => {                           // <<<---using ()=> syntax
          if (count != 0) {
            this.Degree_rcv_date = [];
            this.toaster.success(`${count} Degree no Record Updated!!`);
            this.save();

            count = 0;
          }
        }, 1000);
      }
    }
  };


  OnChange5(i: Date, det) {
    this.degree.UpdateD_O_Notif(det.C_CODE, det.YEAR, det.RN, det.SE_ID, i).subscribe((res: any) => {
      if (res != '') {
        this.toaster.success(res.success);
        this.save();
      }

    })
  };


  OnChange6(i: string, det) {
    this.degree.UpdateP_Year(det.C_CODE, det.YEAR, det.RN, det.SE_ID, i).subscribe((res: any) => {
      if (res != '') {
        this.toaster.success(res.success);
        this.save();
      }
    });
  };
}
