<form (ngSubmit)="OnSubmit()" #f="ngForm">
  <div [@SlideInFromLeft]>
    <div class="container-fluid card shadow">
      <div class="card-header">
        Enrollment and Section Allocation
      </div>

      <div class="row card-body" style="padding-bottom: 0px;">

     

        <div class="col-lg-4 col-md-6 form-group">
          <label for="C_Code">Class</label>
          <select #cource (change)="OnClassChange(cource)" class="form-control " name="c_code" id="C_Code" ngModel required>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="Major">Major</label>
          <select class="form-control" #major name="maj_id" id="Major" (change)="OnMajorChange()" ngModel>
            <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
          </select>
        </div>


        <div class="col-lg-4 col-md-6 form-group">
          <label for="class" class="text-left">Year</label>
          <input class="form-control" name="year_in" id="year_endTime" (keyup)="OnYearChange()" #input ngModel required>
        </div>


        <div class="col-lg-4 col-md-6 form-group">
          <label for="t_no">Semester</label>
          <input type="number" class="form-control" name="t_no" id="t_no" min="0" max="8" ngModel required>
        </div>

      </div>


      
      <ng-container *ngIf="loader then ifBlock;"></ng-container>
      <ng-template #ifBlock>
          <div style="display:flex;justify-content:center; align-items: center;"><span
                  class="loader"></span></div>
      </ng-template>
      
      <div class="card-header">
        Actions
      </div>
      <div class="row card-body">
        <div class="">
          <button (click)="Self_Support_Enrollment()" class="btn  btn-outline-primary">
            Enrollment
          </button>
       

          <button (click)="FA_FSC_Section_Allocation()" class="btn btn-outline-primary">
            FA FSC Section Allocation
          </button>

          <button (click)="FA_FSC_Section_Allocation_Major()" class="btn btn-outline-primary">
            FA FSC Section Allocation (Major)
          </button>

        
          <button (click)="Section_Change()" class="btn  btn-outline-primary">
            Section Change
          </button>

          <button (click)="BA_BSC_Section_Allocation()" class="btn  btn-outline-primary">
            BA BSC Section Allocation
          </button>

          <button (click)="BA_BSC_Section_Allocation_Major()" class="btn  btn-outline-primary">
            BA BSC Section Allocation (Major)
          </button>
          <button (click)="BA_BSC_Repeater_TimeTable()" class="btn  btn-outline-primary">
            BA/ BSC Repeaters Time Table
          </button>

       

          <button type="submit_sec" class="btn  btn-outline-primary">
            Next Semester Shifting
          </button>

          <button class="btn btn-sm btn-outline-primary" type="button" (click)="ClearForm(f)">Clear Form</button>
          
        </div>

        <div class="">

          <button (click)="Delete_Major_Records()" class="btn  btn-outline-danger">
            Delete Major Records
          </button>

          <!-- <button (click)="Delete_All_Records()" class="btn  btn-outline-danger">
            Delete All Records
          </button> -->

          
        </div>

      </div>
    </div>
  </div>
</form>
