import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class  reportsFeeService {

    constructor(private http: HttpClient) {
    }
    onCollectionReportClassWise(params) {
        return this.http.post(`${baseUrl}/api/feeReports/collectionReportClassWise`,params);
    }

    onCollectionReportClassBaseWise(params) {
        return this.http.post(`${baseUrl}/api/feeReports/collectionReportGroupBaseWise`,params);
    }

    onCollectionReportGroupBaseWise(params) {
        return this.http.post(`${baseUrl}/api/feeReports/T_collectionReportGroupBaseWise`,params);
    }

    onCollectionReportLevelWise(params) {
        return this.http.post(`${baseUrl}/api/feeReports/T_collectionReportlevelWise`,params);
    }
    
    
    onCollectionReportGroupBaseMeritWise(params) {
        return this.http.post(`${baseUrl}/api/feeReports/T_collectionReportGroupBaseMeritWise`,params);
    }

    onCollectionReportStudentWise(params) {
        return this.http.post(`${baseUrl}/api/feeReports/collectionReportStudentWise`,params);
    }

    onIntallmentExtension(params) {
        return this.http.post(`${baseUrl}/api/feeReports/installmentExtension`,params);
    }
    
    onDemandCollectionReport(params) {
        return this.http.post(`${baseUrl}/api/feeReports/demandCollectionReport`,params);
    }
    
    onStudentGenInfoReport(c_code,maj_id,se_id,year) {
        return this.http.post(`${baseUrl}/api/feeReports/studentGenInfoReport`,{c_code,maj_id,se_id,year});
    }

    onSelfSupportFormReport(params) {
        return this.http.post(`${baseUrl}/api/feeReports/selfSupportFormReport`,params);
    }
    
    onFinancialAidExpenditure(params) {
        return this.http.post(`${baseUrl}/api/feeReports/financialAidExpenditure`,params);
    }
    getMajors(){
        return this.http.get(`${baseUrl}/api/major/getMajors/`);
    }
    getSessions(){
        return this.http.get(`${baseUrl}/api/session/getSessions/`);
    }
    
    IncomeCashRepDailyAndPeriod(st_date_in,end_date_in,st_year_in,end_year_in,period_in,bnk_id,c_code) {
        return this.http.post(`${baseUrl}/api/feeReports/IncomeCashRepDailyAndPeriod`,
        {st_date_in,end_date_in,st_year_in,end_year_in,period_in,bnk_id,c_code});
    }  
    IncomeCashRepDailyBankClass(st_date_in,st_year_in,period_in,bnk_id_in,c_code_in,end_date_in,reptype) {
        return this.http.post(`${baseUrl}/api/feeReports/IncomeCashRepDailyBankClass`,
        {st_date_in,st_year_in,period_in,bnk_id_in,c_code_in,end_date_in,reptype});
    }
    IncomeCashRepMonth(params) {
        return this.http.post(`${baseUrl}/api/feeReports/IncomeCashRepMonth`,params);
    }
    SetWiseStrengthReport(c_code,year) {
        return this.http.post(`${baseUrl}/api/feeReports/SetWiseStrengthReport`, {c_code,year});
    }
    IncomeCashRepYearly(params) {
        return this.http.post(`${baseUrl}/api/feeReports/IncomeCashRepYearly`,params);
    }
    combinationWiseStrengthReport(params) {
        return this.http.post(`${baseUrl}/api/feeReports/combinationWiseStrengthReport`,params);
    }
    FeeRefundReport(c_code, rn, st_date_in,end_date_in,reptype, year,actyear ) {
        return this.http.post(`${baseUrl}/api/feeReports/FeeRefundReport`,{c_code, rn, st_date_in,end_date_in,reptype, year,actyear});
    }
    StdCollectionHistory(c_code,f_rn,l_rn,inst_no,year)
    {
        return this.http.post(`${baseUrl}/api/feeReports/StdCollectionHistory`, {c_code,f_rn,l_rn,inst_no,year})  
    }
    getProvince() {
        return this.http.post(`${baseUrl}/api/showstudent/getprovince`,{});
      }
      overallDumpReport(lvl_id,year) {
        return this.http.post(`${baseUrl}/api/feeReports/overallDumpReport`,{lvl_id,year});
      }
      getStudyLVL(){
        const url = `${baseUrl}/api/feeReports/getStudyLVL`;
        return this.http.post(url, {});
     }
     genderwiseReport() {
         const url = `${baseUrl}/api/feeReports/genderwiseReport`;
         return this.http.post(url, {});
     }

     getUnpaidChallans(d_id: number, year: number) {
        return this.http.post(`${baseUrl}/api/fee/get_unpaidChallans`, {d_id: d_id, year: year});
    }
}
