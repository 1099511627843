import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { AttendanceService } from '../attendance/attendance-services/attendance.service';

interface EnrollInfo{
 sub_code:string,
 subject:string,
 t_no:number,
 section:string,
 se_id:number,
 session:string
}

interface stdInfo{
  nm:string,
  nic:string,
  year:number,
  c_code:number,
  class:string,
  d_id:number,
  deptt:string,
  set_no:number,
  maj_id:number,
  major:string,
  rn:number,
  se_id:number,
  session:string,
}

interface att{
  DA_DATE:string;
  ATTEND:string;
  MONTH_NM: string;
  MONTH: string;
}

@Component({
  selector: 'app-student-detail',
  templateUrl: './student-detail.component.html',
  styleUrls: ['./student-detail.component.css'],
  animations:[
    SlideInFromLeft()
  ]
})
export class StudentDetailComponent implements OnInit {
   @Output() close:EventEmitter<void> =   new EventEmitter();
   @Input() enrolInfo:EnrollInfo;
   @Input() std:stdInfo;
  hideInfo:boolean=false;

  // teams: Team[];
  // subCode = '';
  attendance :att[];
  attendanceLoading = false;

  presents = 0;
  absents = 0;

// pie chart data
  pieChartLabels: Label[] = ['ABSENTS', 'PRESENTS'];
  pieChartData: number[] = [this.presents, this.absents];
  pieChartType: ChartType = 'pie';
  pieChartLegend = true;
  pieChartPlugins = [];

  
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };



  lineChartLabels: Label[] = [];
  lineChartData: ChartDataSets[] = [
    { data: [], label: 'Absent' },
    { data: [], label: 'Presents' },
  ];

  lineChartOptions = {
    responsive: true,
    suggestedMin: 0,
    suggestedMax: 100
  };
  lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(103, 58, 183, .1)',
      borderColor: 'rgb(103, 58, 183)',
      pointBackgroundColor: 'rgb(103, 58, 183)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(103, 58, 183, .8)',
    },
  ];
  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';

  private months = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  constructor( 
    private attendanceService:AttendanceService,
    private toastrService:ToastrService) {    
     }


  ngOnInit(): void {
    this.getAttendanace(
  {C_CODE:this.std.c_code,GRP:this.std.maj_id,SE_ID:this.enrolInfo.se_id,YEAR:this.std.year,T_NO:this.enrolInfo.t_no,SECTION:this.enrolInfo.section, SUB_CODE:this.enrolInfo.sub_code,RN:this.std.rn});
  }


  
  getAttendanace(team){
    this.attendanceLoading = true;
    this.attendanceService.getStdDetailAttendance(team).subscribe((res:att[])=>{
      this.attendance=res;
      this.attendanceLoading = false;

      this.presents  = (res?.filter(x=> x.ATTEND==='p'))?.length;
      this.absents = res?.length - this.presents;
      this.pieChartData = [this.absents,this.presents]; 
      

      //for line chart
      let months: Set<string>= new Set<string>();
      res?.forEach(entry=>{
        entry.MONTH = this.getMonthName(parseInt(entry.MONTH_NM));
        months.add(entry.MONTH);
      });


      let present: number[] =[];
      let absent: number[] = [];
      let total: number[]=[];
      
      months.forEach(mon=>{
        present.push(res?.filter(x=> x.MONTH === mon && x.ATTEND==='p')?.length);
        absent.push(res?.filter(x=> x.MONTH === mon && x.ATTEND==='a')?.length);

        total.push()
      });

      this.lineChartLabels = Array.from(months);
      this.lineChartData=[
        { data: absent, label: 'Absent' },
        { data: present, label: 'Present' }
      ];

    },err=>{
      console.log(err);
      this.attendanceLoading = false;
      this.toastrService.error("Unknown Error");
    });
  }

  getMonthName(monthId: number){
    return this.months[monthId-1];
  }

  OnClose(){
   this.close.emit();
  }
}
