import { Component, ViewChild } from '@angular/core';
import { ClassService } from '../shared/services/Class.service';
import { SelectList } from '../shared/model/SelectList.model';
import { MajorService } from '../shared/services/Major.service';
import { NgForm } from '@angular/forms';
import { StudentService } from '../shared/services/Student.service';
import { SlideInFromLeft } from "src/app/transitions";
import { ToastrService } from 'ngx-toastr';
import { UtilService } from 'src/app/Util.service';
import { SubjectOfferService } from '../shared/services/SubjectOffer.service';



@Component({
  selector: 'app-admission-form',
  templateUrl: './admission-form.component.html',
  styleUrls: ['./admission-form.component.css'],
  animations: [SlideInFromLeft()],
})
export class AdmissionFormComponent {

  rn = 0;
  rolno = '';
  reg_no = '';
  classes: Array<SelectList>;
  major: Array<SelectList>;
  religion: Array<SelectList>;
  set_no: Array<SelectList>;
  @ViewChild("f") formRef: NgForm;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'> this screen is used to insert entries of migration students  in a session </h6>
  <h6 class="text-right">یہ اسکرین کسی سیشن میں مائیگریشن طلباء کے اندراجات داخل کرنے کے لئے استعمال ہوتی ہے۔</h6>
  `  ;

  constructor(

    private classService: ClassService,
    private majorServices: MajorService,
    private studentService: StudentService,
    private toastr: ToastrService,
    private utilservice: UtilService,
    private subjectOfferService: SubjectOfferService
  ) {

    this.classes = new Array<SelectList>()
    this.major = new Array<SelectList>()
    this.religion = new Array<SelectList>()
    this.set_no = new Array<SelectList>()


  }

  ngOnInit(): void {
    //this.isAdmin = this.authenticationService.isAdmin();
    this.loadClasses();
    this.getReligion();
  }

  // Load All Classes
  loadClasses() {
    this.classService
      .getClasses({})
      .subscribe((res: { C_CODE: number; C_NM: string }[]) => {
        res?.forEach((entry) => {
          this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
        });
      });
  }

//get Religion

getReligion(){
this.utilservice.getReligion().subscribe((res:{idreligion:number,relig_nm:string}[])=>{
  //console.log(res)
res?.forEach((entry)=>{
    this.religion.push({id:entry.idreligion,nm:entry.relig_nm})
  }
  );
  //console.log(this.religion)
});
}

OnClear() {
  this.formRef.reset();
}
  // Get all Majors
  getMajors(c: HTMLSelectElement) {
    this.major = [];
    this.majorServices.getMajor({ c_code: this.formRef.value.c_code }).subscribe((res: { MAJ_ID: number, MAJ_NM: string }[]) => {
      res?.forEach(entry => {
        this.major.push({ id: entry.MAJ_ID, nm: entry.MAJ_NM });
      });
    });
  }

  //Get MaxRoll Number Plus One 
  getmaxrolno() {
    console.log(this.formRef.value)

    this.studentService.GetMaxRollNumberPlusOne({ year: this.formRef.value.year, c_code: this.formRef.value.c_code, maj_id: this.formRef.value.maj_id }).subscribe((res: { rn: number, rolno: string, regno: string }) => {
      if (res) {
        this.rn = res[0][0]?.rn;
        this.rolno = res[0][0]?.rolno;
        this.reg_no = res[0][0]?.regno;
      
      }
    })
  }

  //get Subject Combination
get_set_no(){
  this.subjectOfferService.getSubjectComb( this.formRef.value.maj_id,this.formRef.value.c_code,'1' ).subscribe((res: {SET_NO:number,SUB_COMB:string}[])=>{
    console.log(res)
    res?.forEach(entry => {
      this.set_no.push({ id: entry.SET_NO, nm: entry.SUB_COMB });
    });
  })
}

//Get NIC Status if already inserted in database
get_nic_status(nic: HTMLSelectElement){
  this.studentService.Get_nic_status({nic:this.formRef.value.nic}).subscribe((res: {is_duplicate:number})=>{
    //console.log(res)
    console.log(res) 
    console.log(res.is_duplicate)
if (res.is_duplicate==1) {
  this.toastr.warning('NIC Already Exists')
  const myinput = document.getElementById('nic') as HTMLInputElement;
  myinput.value='' 
  myinput.focus()
  myinput.style.borderColor = 'red';
  myinput.style.boxShadow = '0 0 5px 2px red';
  
}  


     })
}


onsubmit(){
console.log(this.formRef.value)
this.studentService.insertAdmissionStd({year:this.formRef.value.year,c_code:this.formRef.value.c_code,ADM_RN :this.rn,rollno:this.rolno,set_no:this.formRef.value.set_no, maj_id:this.formRef.value.maj_id, nic:this.formRef.value.nic,dob:this.formRef.value.dob,ph1:this.formRef.value.ph1,ph2:this.formRef.value.ph2,add1:this.formRef.value.add1,add2:this.formRef.value.add2,f_no:this.formRef.value.f_no,name:this.formRef.value.name,fname:this.formRef.value.fname,gender:this.formRef.value.gender,reg_no:this.reg_no,rel:this.formRef.value.rel,email:this.formRef.value.email,moreve:this.formRef.value.moreve,syear:this.formRef.value.year}).subscribe((res)=>{
console.log(res)

  if (res[0]?.msg) {
 this.toastr.success(res[0].msg)
} 
else if(res[0]?.warn) {
  this.toastr.warning(res[0].warn)
 } 
})


}  



}
