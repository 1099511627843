import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SelectList } from '../../shared/model/SelectList.model';
import { reportsFeeService } from '../../shared/services/reportsFee.service';
import { EnrollmentService } from '../../shared/services/Enrollment.service';
import { FeeService } from '../../shared/services/Fee.service';
import { jsPDF } from 'jspdf';
import { DatePipe } from "@angular/common";
import autoTable from "jspdf-autotable";
import "jspdf-autotable";
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-student-wise-collection-report',
  templateUrl: './student-wise-collection-report.component.html',
  styleUrls: ['./student-wise-collection-report.component.css'],
  animations: [SlideInFromLeft()]
})
export class StudentWiseCollectionReportComponent implements OnInit {
  [x: string]: any;
  subscription = new Subscription();
  classes: Map<number, string>;
  classesArr: Array<SelectList>
  rolno: Array<SelectList>;
  lgNm: Array<SelectList>;
  allData: Array<string>;
  duesInfoMap: Map<string, Map<string, Map<number, string>>>;
  personalInfo: Array<{ ROLNO, nm, f_nm, ADD1, ph, comb }>;
  isAdmin: boolean = false;
  loader: boolean = false;
  @ViewChild('f') formRef: NgForm;
  feeArr: Array<{
    ch_no: string, rollno: string, inst_no: string, des: string, nm: string,
    adm: number, tuition: number, exam: number, uni: number, verif: number, reg: number, sports: number, lib: number, id: number,
    comp_exam: number, dev_fund: number, thesis: number, student_orient: number, std_fee: number, lang: number, gen_fund: number, board_fund: number,
    medic_fund: number, std_wel_fund: number, park_fund: number, gaz_fund: number, mag_fund: number, uni_sur: number, end_fund: number,
    add_sub: number, sc_breakge: number, fine: number, misc: number, self_fin: number, room_rent: number, host_sec: number, overseas: number, std_fund: number,
    lab_charges: number, green_card: number, other_mis_reg: number, other_misc_self: number, with_tax: number, tot_A: number, tuition_foreign: number,
    uni_surcharge: number, comp: number, std_welf_fund: number, transc_fee: number, tuition_for: number
  }>;
  constructor(
    private feeService: FeeService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private reportsFee: reportsFeeService,
    private enrollmentService: EnrollmentService,
    private feeSharedService: FeeSharedDataService
  ) {
    this.classes = new Map<number, string>()
    this.isAdmin = this.authenticationService.isAdmin();
    this.rolno = new Array<SelectList>();
    this.duesInfo = []
    this.personalInfo = new Array<{ ROLNO, nm, f_nm, ADD1, ph, comb }>();
    this.lgNm = new Array<SelectList>();
    this.allData = new Array<string>();
    this.duesInfoMap = new Map<string, Map<string, Map<number, string>>>();
    this.feeArr = [];
    this.challanInfo = []
    this.manualRn = 0
  }

  ngOnInit(): void {
    this.getLgNm();
    this.updateClasses();
    this.updateSessions();
  }
  
  updateClasses() {
    this.classesArr = [];
    this.feeSharedService.getFeeClasses()
      .then(classes => {
        this.classesArr = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateSessions() {
    this.sessions = []
    this.feeSharedService.getFeeSessions()
      .then(sessions => {
        this.sessions = sessions;
      })
      .catch(error => {
        console.error("Error getting fee session", error);
      });
  }

  onClassChange() {
    if (!this.formRef.value.year_in)
      this.rolno = [];
    else {
      this.getRolno();
    }
  }

  getLgNm() {
    this.subscription.add(this.feeService.onLogNm().subscribe((res: { nm: string, fm_id: number }) => {
      if (!res) {
        this.toastr.error("Unknown Error!");
      }
      else {
        this.lgNm.push({ id: res.fm_id, nm: res.nm });
      }
    }, err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    }));
  }

  getRolno() {
    this.rolno = [];
    this.subscription.add(this.enrollmentService.notEnrollFeeServer(this.formRef.value).subscribe((res: { rn: number, rolno: string }[]) => {
      res?.forEach(entry => {
        this.rolno.push({ id: entry.rn, nm: entry.rolno });
      })
    }));
  }

  onReport(c_code_in) {
    const { c_code, f_rn, l_rn, year_in, inst_no } = this.formRef.value;
    this.personalInfo = [];
    this.duesInfo = []
    this.challanInfo = []
    this.unpaidChallans = []
    this.SchpFeeConcDuesAdj = []
    this.outstandDues = []
    this.feeRefund = []
    this.struckOff = []
    this.loader = true;
    let count = 0;
    this.subscription.add(this.reportsFee.StdCollectionHistory(c_code, f_rn, l_rn, inst_no, year_in).subscribe((res: any) => {
      this.unpaidChallans.push(...res[0])
      this.unpaidChallans.push(...res[1])
      this.SchpFeeConcDuesAdj.push(...res[2])
      this.outstandDues.push(...res[3])
      this.feeRefund.push(...res[4])
      this.subscription.add(this.reportsFee.onCollectionReportStudentWise(this.formRef.value).subscribe(res => {
        // console.log("response from SWCR", res)
        res[0]?.forEach(element => {
          // console.log("Element", element)
          this.duesInfo.push(element);
          this.status = false
        })
        // console.log('duesInfo', this.duesInfo)
        this.onDownloadReport(c_code_in);
      }, err => {
        this.toastr.error("Unknown Error, Maybe Internet Issue")
        this.loader = false;
      }));
    }, err => {
      this.toastr.error("Unknown Error, Maybe Internet Issue")
      this.loader = false;
    }))

    // console.log(this.duesInfo)

  }
  ChangeManualRn(val) {
    this.manualRn = val
  }
  onDownloadReport(c: HTMLSelectElement) {
    this.duesInfoMap.clear();
    let a = 0;
    this.duesInfo.sort((a: any, b: any) => parseInt(a.maj_id) - parseInt(b.maj_id));
    this.duesInfo.sort((a: any, b: any) => parseInt(a.rn) - parseInt(b.rn));
    this.duesInfo.forEach(el => {
      if (this.duesInfoMap.has(el.ROLNO) == false) {
        this.duesInfoMap.set(el.ROLNO, new Map());
      }
      if (this.duesInfoMap.get(el.ROLNO).has(el.ch_no + "") == false) {
        this.duesInfoMap.get(el.ROLNO).set(el.ch_no + "", new Map())
      }
      this.duesInfoMap.get(el.ROLNO).get(el.ch_no + "").set(el.fee_id, el.debit);
    })
    this.CreatePDF()
    // console.log("Map", this.duesInfoMap)
  }
  // async mergePdfs(pages: ArrayBuffer[]) {
  //   const mergedPdf = await PDFDocument.create();
  //   mergedPdf.setTitle('Student Collection Report', { showInWindowTitleBar: true })
  //   const actions = pages.map(async (pdfBuffer) => {
  //     const pdf = await PDFDocument.load(pdfBuffer);
  //     const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  //     copiedPages.forEach((page) => {
  //       mergedPdf.addPage(page);
  //     });
  //   });
  //   await Promise.all(actions);
  //   mergedPdf.setTitle('Student Collection Report');
  //   const pdfBytes = await mergedPdf.save();

  //   let file = new Blob([pdfBytes], { type: 'application/pdf' });
  //   var fileURL = URL.createObjectURL(file);
  //   window.open(fileURL, '_blank');

  // }

  // async downloadAll() {
  //   const promises = [];

  //   for (let i of this.duesInfoMap) {
  //     const promise = this.CreatePDF(i, this.unpaidChallans, this.SchpFeeConcDuesAdj, this.outstandDues, this.feeRefund, this.struckOff)
  //       .then(buf => {
  //         if (buf != null) {
  //           return buf;
  //         }
  //       });

  //     promises.push(promise);
  //   }

  //   const buffers = await Promise.all(promises);
  //   if (buffers.length <= 0) {
  //     this.toastr.warning("No Any Data To Export");
  //     this.loader = false;
  //     return;
  //   } else {
  //     this.toastr.success(
  //       `Data of ${buffers.length} Student/s Exported.`
  //     );
  //     setTimeout(() => {
  //       this.mergePdfs(buffers);
  //       this.loader = false;
  //     }, 500)

  //   }
  // }
  CreatePDF() {
    
    let width = 342,height = 225,rolnoCheck='',lengthCheck=0,MapSize=this.duesInfoMap.size-1;
    var page;
    const doc = new jsPDF('l', 'mm', [width, height]);
    var pageSize = doc.internal.pageSize;
    for(let i of this.duesInfoMap)
    {
      let unpaid,SchpFeeConcDuesAdj,outstandDues,feeRefund,struckOff
      unpaid=this.unpaidChallans
      SchpFeeConcDuesAdj=this.SchpFeeConcDuesAdj
      outstandDues=this.outstandDues
      feeRefund=this.feeRefund
      struckOff=this.struckOff
    let bodyobj = {}, bodyarr = new Array<any>();
    bodyarr = []
    unpaid = unpaid.filter(e => e.ROLNO == i[0])
    unpaid.sort((a: any, b: any) => parseInt(a.inst_sort) - parseInt(b.inst_sort));
    SchpFeeConcDuesAdj = SchpFeeConcDuesAdj.filter(e => e.ROLNO == i[0])
    SchpFeeConcDuesAdj.sort((a: any, b: any) => parseInt(a.inst_sort) - parseInt(b.inst_sort));
    outstandDues = outstandDues.filter(e => e.ROLNO == i[0])
    outstandDues.sort((a: any, b: any) => parseInt(a.inst_sort) - parseInt(b.inst_sort));
    feeRefund = feeRefund.filter(e => e.ROLNO == i[0])
    feeRefund.sort((a: any, b: any) => parseInt(a.inst_sort) - parseInt(b.inst_sort));
    struckOff = struckOff.filter(e => e.ROLNO == i[0])
    struckOff.sort((a: any, b: any) => parseInt(a.inst_sort) - parseInt(b.inst_sort));
    this.feeArr = [];
    let bodyfontStyle = 7;
    let headfontStyle = 7;
    let tabHead, a = 0;
    let nameVar, majVar, classVar, phVar, emailVar, comVar, rollVar, baseVar, fatherVar, addVar;
    for (let m of i[1]) {
      this.feeArr.push({
        ch_no: '', rollno: '', inst_no: '', des: '', nm: "",
        adm: 0, tuition: 0, exam: 0, uni: 0, verif: 0, reg: 0, sports: 0, lib: 0, id: 0,
        comp_exam: 0, dev_fund: 0, thesis: 0, student_orient: 0, std_fee: 0, lang: 0, gen_fund: 0, board_fund: 0,
        medic_fund: 0, std_wel_fund: 0, park_fund: 0, gaz_fund: 0, mag_fund: 0, uni_sur: 0, end_fund: 0,
        add_sub: 0, sc_breakge: 0, fine: 0, misc: 0, self_fin: 0, room_rent: 0, host_sec: 0, overseas: 0, std_fund: 0,
        lab_charges: 0, green_card: 0, other_mis_reg: 0, other_misc_self: 0, with_tax: 0, tot_A: 0, tuition_foreign: 0,
        uni_surcharge: 0, comp: 0, std_welf_fund: 0, transc_fee: 0, tuition_for: 0
      })

      var index = this.duesInfo.findIndex(obj => obj.ch_no == m[0])
      this.feeArr[a].ch_no = this.duesInfo[index].ch_no + "";
      this.feeArr[a].inst_no = this.duesInfo[index].inst_no;
      this.feeArr[a].rollno = this.duesInfo[index].paid_date;
      this.feeArr[a].des = this.duesInfo[index].des;
      nameVar = this.duesInfo[index].nm
      majVar = this.duesInfo[index].maj
      phVar = this.duesInfo[index].ph
      // emailVar=this.duesInfo[index].email
      rollVar = this.duesInfo[index].ROLNO
      comVar = this.duesInfo[index].comb
      // classVar = this.classesArr.get(parseInt(this.formRef.value.c_code));
      baseVar = this.duesInfo[index].adm_bs
      fatherVar = this.duesInfo[index].f_nm
      addVar = this.duesInfo[index].ADD1

      for(let i = 0; i< this.classesArr.length; i ++){
        if(this.classesArr[i].id == parseInt(this.formRef.value.c_code))
            classVar = this.classesArr[i].nm;
      }
        
      for (let j of m[1]) {
        this.class = this.classes.get(parseInt(this.formRef.value.c_code));
  


        if (j[0] == 1) {
          this.feeArr[a].adm = parseInt(j[1]);
        }
        else if (j[0] == 2) {
          this.feeArr[a].tuition = parseInt(j[1]);

        }
        else if (j[0] == 3) {
          this.feeArr[a].exam = parseInt(j[1]);

        }
        else if (j[0] == 4) {
          this.feeArr[a].verif = parseInt(j[1]);
        }
        else if (j[0] == 5) {
          this.feeArr[a].reg = parseInt(j[1]);
        }
        else if (j[0] == 6) {
          this.feeArr[a].sports = parseInt(j[1]);
        }
        else if (j[0] == 7) {
          this.feeArr[a].lib = parseInt(j[1]);
        }
        else if (j[0] == 8) {
          this.feeArr[a].id = parseInt(j[1]);
        }
        else if (j[0] == 9) {
          this.feeArr[a].comp = parseInt(j[1]);
        }
        else if (j[0] == 10) {
          this.feeArr[a].dev_fund = parseInt(j[1]);
        }
        else if (j[0] == 11) {
          this.feeArr[a].thesis = parseInt(j[1]);
        }
        else if (j[0] == 12) {
          this.feeArr[a].student_orient = parseInt(j[1]);
        }
        else if (j[0] == 13) {
          this.feeArr[a].std_fee = parseInt(j[1]);
        }
        else if (j[0] == 14) {
          this.feeArr[a].lang = parseInt(j[1]);
        }
        else if (j[0] == 15) {
          this.feeArr[a].gen_fund = parseInt(j[1]);
        }
        else if (j[0] == 16) {
          this.feeArr[a].board_fund = parseInt(j[1]);
        }
        else if (j[0] == 17) {
          this.feeArr[a].medic_fund = parseInt(j[1]);
        }
        else if (j[0] == 18) {
          this.feeArr[a].std_welf_fund = parseInt(j[1]);
        }
        else if (j[0] == 19) {
          this.feeArr[a].transc_fee = parseInt(j[1]);
        }
        else if (j[0] == 20) {
          this.feeArr[a].gaz_fund = parseInt(j[1]);
        }
        else if (j[0] == 21) {
          this.feeArr[a].mag_fund = parseInt(j[1]);
        }
        else if (j[0] == 22) {
          this.feeArr[a].uni_surcharge = parseInt(j[1]);
        }
        else if (j[0] == 23) {
          this.feeArr[a].end_fund = parseInt(j[1]);
        }
        else if (j[0] == 24) {
          this.feeArr[a].add_sub = parseInt(j[1]);
        }
        else if (j[0] == 25) {
          this.feeArr[a].sc_breakge = parseInt(j[1]);
        }
        else if (j[0] == 26) {
          this.feeArr[a].fine = parseInt(j[1]);
        }
        else if (j[0] == 27) {
          this.feeArr[a].misc = parseInt(j[1]);
        }
        else if (j[0] == 28) {
          this.feeArr[a].self_fin = parseInt(j[1]);
        }
        else if (j[0] == 29) {
          this.feeArr[a].room_rent = parseInt(j[1]);
        }
        else if (j[0] == 30) {
          this.feeArr[a].host_sec = parseInt(j[1]);
        }
        else if (j[0] == 31) {
          this.feeArr[a].overseas = parseInt(j[1]);
        }
        else if (j[0] == 32) {
          this.feeArr[a].std_fund = parseInt(j[1]);
        }
        else if (j[0] == 33) {
          this.feeArr[a].lab_charges = parseInt(j[1]);
        }
        else if (j[0] == 34) {
          this.feeArr[a].green_card = parseInt(j[1]);
        }
        else if (j[0] == 35) {
          this.feeArr[a].other_mis_reg = parseInt(j[1]);
        }
        else if (j[0] == 36) {
          this.feeArr[a].other_misc_self = parseInt(j[1]);
        }
        else if (j[0] == 37) {
          this.feeArr[a].with_tax = parseInt(j[1]);
        }
        else if (j[0] == 98) {
          this.feeArr[a].tot_A = parseInt(j[1]);
        }
        else if (j[0] == 99) {
          this.feeArr[a].tuition_for = parseInt(j[1]);
        }

      }
      ///////////////////////////////////BODY ARRAY///////////////////////////////////////////////////////////

      bodyobj[a] = {
        ch_no: { content: this.feeArr[a].ch_no, styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.3 } },
        inst_no: { content: this.feeArr[a].inst_no, styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.3 } },
        des: { content: this.feeArr[a].des, styles: { fontSize: bodyfontStyle - 0.5, fontStyle: "bold" } },
        rollno: { content: this.feeArr[a].rollno, styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.3 } },
        adm: { content: this.feeArr[a].adm },
        tuition: { content: this.feeArr[a].tuition },
        exam: { content: this.feeArr[a].exam },
        reg: { content: this.feeArr[a].reg },
        thesis: { content: this.feeArr[a].thesis },
        verif: { content: this.feeArr[a].verif },
        sports: { content: this.feeArr[a].sports },
        lang: { content: this.feeArr[a].lang },
        id: { content: this.feeArr[a].id },
        dev_fund: { content: this.feeArr[a].dev_fund },
        medic_fund: { content: this.feeArr[a].medic_fund },
        end_fund: { content: this.feeArr[a].end_fund },
        lab_charges: { content: this.feeArr[a].lab_charges },
        misc: {
          content: this.feeArr[a].other_mis_reg +
            this.feeArr[a].other_misc_self +
            this.feeArr[a].misc
        },
        totA: {
          content:
            this.feeArr[a].adm + this.feeArr[a].tuition + this.feeArr[a].exam + this.feeArr[a].reg + this.feeArr[a].thesis + this.feeArr[a].sc_breakge +
            this.feeArr[a].verif + this.feeArr[a].lang + this.feeArr[a].sports + this.feeArr[a].id + this.feeArr[a].dev_fund +
            this.feeArr[a].medic_fund + this.feeArr[a].end_fund + this.feeArr[a].lab_charges + this.feeArr[a].other_mis_reg + this.feeArr[a].other_misc_self +
            this.feeArr[a].misc,
          styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 }
        },
        std_fund: { content: this.feeArr[a].std_fund },
        board_fund: { content: this.feeArr[a].board_fund },
        with_tax: { content: this.feeArr[a].with_tax },
        room_rent: { content: this.feeArr[a].room_rent },
        host_sec: { content: this.feeArr[a].host_sec },
        lib: { content: this.feeArr[a].lib },
        overseas: { content: this.feeArr[a].overseas },
        uni_surcharge: { content: this.feeArr[a].uni_surcharge },
        student_orient: { content: this.feeArr[a].student_orient },
        std_fee: { content: this.feeArr[a].std_fee },
        fine: { content: this.feeArr[a].fine },
        tuition_for: { content: this.feeArr[a].tuition_for },
        green_card: { content: this.feeArr[a].green_card },
        gen_fund: { content: this.feeArr[a].gen_fund },
        self_fin: { content: this.feeArr[a].self_fin },
        mag_fund: { content: this.feeArr[a].mag_fund },
        gaz_fund: { content: this.feeArr[a].gaz_fund },
        add_sub: { content: this.feeArr[a].add_sub },
        transc_fee: { content: this.feeArr[a].transc_fee },
        comp: { content: this.feeArr[a].comp },
        totB: {
          content: this.feeArr[a].std_fund + this.feeArr[a].board_fund + this.feeArr[a].with_tax + this.feeArr[a].room_rent + this.feeArr[a].host_sec +
            this.feeArr[a].lib + this.feeArr[a].overseas + this.feeArr[a].uni_surcharge + this.feeArr[a].student_orient + this.feeArr[a].std_fee +
            this.feeArr[a].fine + this.feeArr[a].tuition_for + this.feeArr[a].tot_A + this.feeArr[a].green_card + this.feeArr[a].gen_fund +
            this.feeArr[a].self_fin + this.feeArr[a].mag_fund + this.feeArr[a].gaz_fund + this.feeArr[a].add_sub + this.feeArr[a].transc_fee +
            this.feeArr[a].comp,
          styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 }
        },
        grand: {
          content:
            this.feeArr[a].adm + this.feeArr[a].tuition + this.feeArr[a].exam + this.feeArr[a].reg + this.feeArr[a].thesis + this.feeArr[a].sc_breakge +
            this.feeArr[a].verif + this.feeArr[a].sports + this.feeArr[a].lang + this.feeArr[a].id + this.feeArr[a].dev_fund + this.feeArr[a].medic_fund +
            this.feeArr[a].end_fund + this.feeArr[a].lab_charges + this.feeArr[a].other_mis_reg + this.feeArr[a].other_misc_self +
            this.feeArr[a].misc + this.feeArr[a].std_fund + this.feeArr[a].board_fund + this.feeArr[a].with_tax + this.feeArr[a].room_rent +
            this.feeArr[a].host_sec + this.feeArr[a].lib + this.feeArr[a].overseas + this.feeArr[a].uni_surcharge +
            this.feeArr[a].student_orient + this.feeArr[a].std_fee + this.feeArr[a].fine + this.feeArr[a].tuition_for +
            this.feeArr[a].tot_A + this.feeArr[a].green_card + this.feeArr[a].gen_fund + this.feeArr[a].self_fin + this.feeArr[a].mag_fund +
            this.feeArr[a].gaz_fund + this.feeArr[a].add_sub + this.feeArr[a].transc_fee + this.feeArr[a].comp,
          styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.5 }
        },
      }
      a++;
    }
    // }


    this.loader = false;

    let body = [];
    let header = [];

    function innerHead(expD: any) {
      var exportDate = expD
      var image = new Image();
      var page;
      const toPascalCase = str => {
        if (!str) {
          return '';
        }
        const matches = str.match(/([A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+|[,-]+)/g);
        if (!matches) {
          return '';
        }
        let result = '';
        let index = 0;
        for (let i = 0; i < matches.length; i++) {
          const match = matches[i];
          if (match === ',' || match === '-' || match==='#' || match==='.' || match ==='(' ||match===')') {
            result += match;
            index++;
          } else {
            result += ' '+match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
            index += match.length;
          }
        }
        return result;
      };
      image.src = '../../../assets/images/logo3.png';

      doc.addImage(image, 300, 5, 25, 28);
      doc.setFontSize(10);
      doc.text(`${exportDate}`, 302, 37);
      doc.setFontSize(22);
      doc.setFont(undefined, 'bold');
      doc.text("GC UNIVERSITY, LAHORE", 165, 15, { align: 'center' });
      doc.setFont(undefined, 'bold');
      doc.setFontSize(16);
      doc.text("Student Dues Collection Report", 165, 22, { align: 'center' });
      doc.text("___________________________", 165, 22, { align: 'center' });
      doc.setFontSize(12);
      doc.text("Class: " + classVar, 165, 30, { align: 'center' });
      doc.text("Major: " + majVar, 165, 36, { align: 'center' });
      doc.text("Admission Base: " + baseVar, 165, 42, { align: 'center' });
      doc.setFontSize(11);
      doc.setDrawColor("Black");
      doc.roundedRect(7, 47, 328, 30, 2, 2, 'S');
      doc.setDrawColor('Black');
      doc.setFontSize(16);
      doc.setFont(undefined, 'normal');
      doc.text("Roll Number:", 25, 53, { align: 'left' });
      doc.setFont(undefined, 'bold');
      doc.text(rollVar, 59, 53, { align: 'left' });
      doc.setFontSize(12);
      doc.setFont(undefined, 'normal');
      doc.text("Name:", 25, 60,); //15,50
      doc.setFont(undefined, 'bold');
      doc.text(toPascalCase(nameVar), 39, 60, { align: 'left' });
      doc.setFont(undefined, 'normal');
      doc.text("S/D of:", 145, 60);
      doc.setFont(undefined, 'bold');
      doc.text(toPascalCase(fatherVar), 160, 60, { align: 'left' });
      doc.setFont(undefined, 'normal');
      doc.text("Phone No: ", 265, 60);
      doc.setFont(undefined, 'bold');
      doc.text(phVar, 287, 60, { align: 'left' });
      doc.setFont(undefined, 'normal');
      doc.text("Subject Combination:", 25, 67.5, { align: 'left' });
      doc.setFont(undefined, 'bold');
      doc.setFontSize(11);
      doc.text(toPascalCase(comVar), 67, 67.5, { align: 'left' });
      doc.setFontSize(12);
      doc.setFont(undefined, 'normal');
      doc.text("Address:", 25, 75, { align: 'left' });
      doc.setFont(undefined, 'bold');
      doc.setFontSize(10);
      doc.text(toPascalCase(addVar), 43, 75, { align: 'left' });

      doc.setFontSize(7);

    }
    doc.setFontSize(6);

    let y = 82;
    let store = '2';
    a = 0;

    let FeeIDTotal = {}
    FeeIDTotal['adm'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.adm, 0)
    FeeIDTotal['tuition'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.tuition, 0)
    FeeIDTotal['exam'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.exam, 0)
    FeeIDTotal['reg'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.reg, 0)
    FeeIDTotal['thesis'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.thesis, 0)
    FeeIDTotal['sc_breakge'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.sc_breakge, 0)
    FeeIDTotal['verif'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.verif, 0)
    FeeIDTotal['sports'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.sports, 0)
    FeeIDTotal['lang'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.lang, 0)
    FeeIDTotal['id'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.id, 0)
    FeeIDTotal['dev_fund'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.dev_fund, 0)
    FeeIDTotal['medic_fund'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.medic_fund, 0)
    FeeIDTotal['end_fund'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.end_fund, 0)
    FeeIDTotal['lab_charges'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.lab_charges, 0)
    FeeIDTotal['other_mis_reg'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.other_mis_reg, 0)
    FeeIDTotal['other_misc_self'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.other_misc_self, 0)
    FeeIDTotal['misc'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.misc, 0)
    FeeIDTotal['std_fund'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.std_fund, 0)
    FeeIDTotal['board_fund'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.board_fund, 0)
    FeeIDTotal['with_tax'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.with_tax, 0)
    FeeIDTotal['room_rent'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.room_rent, 0)
    FeeIDTotal['host_sec'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.host_sec, 0)
    FeeIDTotal['lib'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.lib, 0)
    FeeIDTotal['overseas'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.overseas, 0)
    FeeIDTotal['uni_surcharge'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.uni_surcharge, 0)
    FeeIDTotal['student_orient'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.student_orient, 0)
    FeeIDTotal['std_fee'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.std_fee, 0)
    FeeIDTotal['fine'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.fine, 0)
    FeeIDTotal['tuition_for'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.tuition_for, 0)
    FeeIDTotal['tot_A'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.tot_A, 0)
    FeeIDTotal['green_card'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.green_card, 0)
    FeeIDTotal['gen_fund'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.gen_fund, 0)
    FeeIDTotal['self_fin'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.self_fin, 0)
    FeeIDTotal['mag_fund'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.mag_fund, 0)
    FeeIDTotal['gaz_fund'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.gaz_fund, 0)
    FeeIDTotal['add_sub'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.add_sub, 0)
    FeeIDTotal['transc_fee'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.transc_fee, 0)
    FeeIDTotal['comp'] = this.feeArr.reduce((previousValue, currentValue) => previousValue + currentValue.comp, 0)

    let totalAmnt: number = FeeIDTotal['adm'] + FeeIDTotal['tuition'] + FeeIDTotal['exam'] + FeeIDTotal['reg'] + FeeIDTotal['thesis'] + FeeIDTotal['sc_breakge'] +
      FeeIDTotal['verif'] + FeeIDTotal['sports'] + FeeIDTotal['lang'] + FeeIDTotal['id'] + FeeIDTotal['dev_fund'] + FeeIDTotal['medic_fund'] +
      FeeIDTotal['end_fund'] + FeeIDTotal['lab_charges'] + FeeIDTotal['other_mis_reg'] + FeeIDTotal['other_misc_self'] + FeeIDTotal['misc'] +
      FeeIDTotal['std_fund'] + FeeIDTotal['board_fund'] + FeeIDTotal['with_tax'] + FeeIDTotal['room_rent'] + FeeIDTotal['host_sec'] +
      FeeIDTotal['lib'] + FeeIDTotal['overseas'] + FeeIDTotal['uni_surcharge'] + FeeIDTotal['student_orient'] + FeeIDTotal['std_fee'] +
      FeeIDTotal['fine'] + FeeIDTotal['tuition_for'] + FeeIDTotal['tot_A'] + FeeIDTotal['green_card'] + FeeIDTotal['gen_fund'] + FeeIDTotal['self_fin'] +
      FeeIDTotal['mag_fund'] + FeeIDTotal['gaz_fund'] + FeeIDTotal['add_sub'] + FeeIDTotal['transc_fee'] + FeeIDTotal['comp']

    // Convert variable to string
    var numStr = totalAmnt.toString();
    var count = (numStr.match(/[0-9]/g) || []).length;
    if (count == 8) {
      bodyfontStyle = 6.5
      headfontStyle = 6.5
      width = 345
    }
    else if (count > 8) {
      bodyfontStyle = 6
      headfontStyle = 6
      width = 345
    }
    let tempArrA = {}
    let tempArr = {}


    /// Specifying fields that We want to neglect if they are 0 because of report visibility
    //tempArr is for funds
    //tempArrA is for Fee
    tempArrA['id'] = FeeIDTotal['id']
    tempArrA['lang'] = FeeIDTotal['lang']
    tempArrA['verif'] = FeeIDTotal['verif']
    tempArrA['lab_charges'] = FeeIDTotal['lab_charges']
    tempArrA['end_fund'] = FeeIDTotal['end_fund']
    tempArrA['medic_fund'] = FeeIDTotal['medic_fund']
    tempArrA['thesis'] = FeeIDTotal['thesis']
    tempArrA['dev_fund'] = FeeIDTotal['dev_fund']
    tempArrA['reg'] = FeeIDTotal['reg']
    tempArrA['adm'] = FeeIDTotal['adm']



    tempArr['green_card'] = FeeIDTotal['green_card']
    tempArr['self_fin'] = FeeIDTotal['self_fin']
    tempArr['mag_fund'] = FeeIDTotal['mag_fund']
    tempArr['gaz_fund'] = FeeIDTotal['gaz_fund']
    tempArr['add_sub'] = FeeIDTotal['add_sub']
    tempArr['transc_fee'] = FeeIDTotal['transc_fee']
    tempArr['comp'] = FeeIDTotal['comp']
    tempArr['board_fund'] = FeeIDTotal['board_fund']
    tempArr['std_fund'] = FeeIDTotal['std_fund']
    tempArr['with_tax'] = FeeIDTotal['with_tax']
    tempArr['overseas'] = FeeIDTotal['overseas']
    tempArr['uni_surcharge'] = FeeIDTotal['uni_surcharge']
    tempArr['student_orient'] = FeeIDTotal['student_orient']
    tempArr['std_fee'] = FeeIDTotal['std_fee']
    tempArr['fine'] = FeeIDTotal['fine']
    tempArr['tuition_for'] = FeeIDTotal['tuition_for']
    tempArr['gen_fund'] = FeeIDTotal['gen_fund']
    tempArr['lib'] = FeeIDTotal['lib']
    tempArr['room_rent'] = FeeIDTotal['room_rent']



    let headobj = {
      ch_no: { content: "Challan#" },
      inst_no: { content: "Inst-No" },
      des: { content: "Description" },
      rollno: { content: "Paid Date" },
      adm: { content: "Adm Fee" },
      tuition: { content: "Tutition Fee" },
      exam: { content: "Exam Fee" },
      reg: { content: "Reg Fee" },
      thesis: { content: "Thesis Fee" },
      verif: { content: "Verif Fee" },
      sports: { content: "Sports Fee" },
      lang: { content: "Lang Fee" },
      id: { content: "ID Card" },
      dev_fund: { content: "Develop Fee" },
      medic_fund: { content: "Med Fund" },
      end_fund: { content: "Endow Fund" },
      lab_charges: { content: "Lab Charges" },
      misc: { content: "Misc" },
      totA: { content: "Total-A" },
      std_fund: { content: "Std Funds" },
      board_fund: { content: "Board Fund" },
      with_tax: { content: "Withhold Tax" },
      room_rent: { content: "Room Rent" },
      host_sec: { content: "Host Sec" },
      lib: { content: "Lib Sec" },
      overseas: { content: "Overseas" },
      uni_surcharge: { content: "Univ Sur" },
      student_orient: { content: "Std Orient" },
      std_fee: { content: "Std Fee" },
      fine: { content: "Fine" },
      tuition_for: { content: "Tution (Foreign)" },
      green_card: { content: "Green Card" },
      gen_fund: { content: "Gen Fund" },
      self_fin: { content: "Self Fin" },
      mag_fund: { content: "Mag Fund" },
      gaz_fund: { content: "Gaz Fund" },
      add_sub: { content: "Add Sub" },
      transc_fee: { content: "Transc Fee" },
      comp: { content: "Comp Fee" },
      totB: { content: "Total-B" },
      grand: { content: "Grand Total" }
    };


    let object2 = headobj;
    let head;
    let headFunds = 22;
    let headFee = 15;
    let newhead = new Array<any>();
    let newheadkey = new Array<any>();
    newhead = []

    //Filtering fields that are 0 for Fee

    Object.keys(tempArrA).forEach((key2: string) => {
      if (tempArrA[key2] == 0) {
        head = Object.keys(object2)
          .filter((key: string) => !key.includes(key2))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: object2[key]
            });
          }, {});
        headFee = headFee - 1;
        object2 = head

      }
    });
    //Filtering fields that are 0 for Funds
    Object.keys(tempArr).forEach(key2 => {
      if (tempArr[key2] == 0) {
        head = Object.keys(object2)
          .filter((key) => !key.includes(key2))
          .reduce((obj, key) => {
            return Object.assign(obj, {
              [key]: object2[key]
            });
          }, {});
        headFunds = headFunds - 1;
        object2 = head
      }
    });
    Object.keys(head).forEach(key => {
      newhead.push(head[key])
      newheadkey.push(key)
    });


    header.push([{ content: "DETAILS", colSpan: 4, styles: { fontStyle: "bold", fontSize: 14, width: 100, halign: "center" } },
    { content: "FEE", colSpan: headFee, styles: { fontStyle: "bold", fontSize: 14, width: 100, halign: "center" } },
    { content: "FUNDS", colSpan: headFunds, styles: { fontStyle: "bold", fontSize: 14, width: 100, halign: "center" } }]);

    header.push(newhead)


    ///////////////////////////////////BODY///////////////////////////////////////////////////////////


    Object.keys(bodyobj).forEach(ok => {
      let tempbodyArr = new Array<any>()
      newheadkey.forEach(el => {
        tempbodyArr.push(bodyobj[ok][el])
      })
      body.push(tempbodyArr)
    })

    let bodyobj2 = {

      ch_no: { content: "TOTAL", colSpan: 4, styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.8, width: 100, halign: "center" } },
      adm: { content: FeeIDTotal['adm'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      tuition: { content: FeeIDTotal['tuition'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      exam: { content: FeeIDTotal['exam'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      reg: { content: FeeIDTotal['reg'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      thesis: { content: FeeIDTotal['thesis'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      // { content: FeeIDTotal['sc_breakge'], 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle+0.8 } },
      verif: { content: FeeIDTotal['verif'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      sports: { content: FeeIDTotal['sports'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      lang: { content: FeeIDTotal['lang'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      id: { content: FeeIDTotal['id'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      dev_fund: { content: FeeIDTotal['dev_fund'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      medic_fund: { content: FeeIDTotal['medic_fund'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      end_fund: { content: FeeIDTotal['end_fund'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      lab_charges: { content: FeeIDTotal['lab_charges'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      misc: {
        content: FeeIDTotal['other_mis_reg'] + FeeIDTotal['other_misc_self'] + FeeIDTotal['misc'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 }
      },
      totA: {
        content:
          FeeIDTotal['adm'] + FeeIDTotal['tuition'] + FeeIDTotal['exam'] + FeeIDTotal['reg'] + FeeIDTotal['thesis'] + FeeIDTotal['sc_breakge'] +
          FeeIDTotal['verif'] + FeeIDTotal['lang'] + FeeIDTotal['sports'] + FeeIDTotal['id'] + FeeIDTotal['dev_fund'] + FeeIDTotal['medic_fund'] +
          FeeIDTotal['end_fund'] + FeeIDTotal['lab_charges'] + FeeIDTotal['other_mis_reg'] + FeeIDTotal['other_misc_self'] + FeeIDTotal['misc'],
        styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 }
      },
      std_fund: { content: FeeIDTotal['std_fund'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      board_fund: { content: FeeIDTotal['board_fund'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      with_tax: { content: FeeIDTotal['with_tax'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      room_rent: { content: FeeIDTotal['room_rent'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      host_sec: { content: FeeIDTotal['host_sec'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      lib: { content: FeeIDTotal['lib'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      overseas: { content: FeeIDTotal['overseas'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      uni_surcharge: { content: FeeIDTotal['uni_surcharge'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      student_orient: { content: FeeIDTotal['student_orient'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      std_fee: { content: FeeIDTotal['std_fee'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      fine: { content: FeeIDTotal['fine'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      tuition_for: { content: FeeIDTotal['tuition_for'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      green_card: { content: FeeIDTotal['green_card'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      gen_fund: { content: FeeIDTotal['gen_fund'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      self_fin: { content: FeeIDTotal['self_fin'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      mag_fund: { content: FeeIDTotal['mag_fund'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      gaz_fund: { content: FeeIDTotal['gaz_fund'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      add_sub: { content: FeeIDTotal['add_sub'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      transc_fee: { content: FeeIDTotal['transc_fee'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      comp: { content: FeeIDTotal['comp'], styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
      totB: {
        content: FeeIDTotal['std_fund'] + FeeIDTotal['board_fund'] + FeeIDTotal['with_tax'] + FeeIDTotal['room_rent'] + FeeIDTotal['host_sec'] +
          FeeIDTotal['lib'] + FeeIDTotal['overseas'] + FeeIDTotal['uni_surcharge']
        , styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 }
      },
      grand: {
        content: totalAmnt,
        styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.8 }
      },

    }
    let tempbody2 = new Array<any>();
    newheadkey.forEach(el => {
      if (bodyobj2[el] != undefined)
        tempbody2.push(bodyobj2[el])
    })
    body.push(tempbody2)

    var marginBottom = 30;
    // Define the height of the footer
    var footerHeight = 10; // in mm
    let footer = [];
    footer.push([
      { content: "Prepared By: ______________________", colSpan: 10, styles: { fontStyle: "bold", fontSize: 9 } },
      { content: "Assistant Treasurer: ______________________", colSpan: 26, styles: { fontStyle: "bold", fontSize: 9 } }]);
    let ClassPass, MajPass, expDate, obj;
    ClassPass = this.class;
    MajPass = this.maj;
    expDate = this.datePipe.transform(new Date(), 'MMM d, y')
    obj = this.personalInfo

    let cursor1, cursor2, cursor3, cursor4, cursor5;
    let unpaidStruckHead = [], outRefundHead = [], schpAdjHead = [];
    let unpaidStruckBody = [], outRefundBody = [], schpAdjBody = [];
    var columnWidth = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
    let unpaidStruckLength = 0, outRefundLength = 0, schpAdjLength = 0;

    ////////////////////////////////////////////////////Unpaid Struck Off///////////////////////////////////////////////////
    ////////////////////////////////////////////////////////Header/////////////////////////////////////////////////////////
    unpaidStruckHead.push([{ content: "UNPAID CHALLANS", colSpan: 5, styles: { fontStyle: "bold", fontSize: 12, width: 100, halign: "center" } },
    { content: "STRUCK OFF", colSpan: 5, styles: { fontStyle: "bold", fontSize: 12, width: 100, halign: "center" } }]);
    unpaidStruckHead.push([{ content: "Challan#" }, { content: "Inst-No" }, { content: "Description" }, { content: "Due Date" }, { content: "Total Amount" },
    { content: "Challan#" }, { content: "Inst-No" }, { content: "Description" }, { content: "Due Date" }, { content: "Total Amount" }]);
    ///////////////////////////////////////////////////////Body/////////////////////////////////////////////////////////
    if (unpaid?.length > struckOff?.length)
      unpaidStruckLength = unpaid?.length
    else
      unpaidStruckLength = struckOff?.length
    for (let r = 0; r < unpaidStruckLength; r++) {
      unpaidStruckBody.push([
        { content: (unpaid?.length > 0 ? unpaid[r]?.ch_no : "----"), styles: { fontStyle: "bold" } },
        { content: (unpaid?.length > 0 ? unpaid[r]?.inst_no : "----"), styles: { fontStyle: "bold" } },
        { content: (unpaid?.length > 0 ? unpaid[r]?.des : "----"), styles: { fontStyle: "bold" } },
        { content: (unpaid?.length > 0 ? unpaid[r]?.due_dt : "----"), styles: { fontStyle: "bold" } },
        { content: (unpaid?.length > 0 ? unpaid[r]?.debit : "----"), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.5 } },
        { content: (struckOff?.length > 0 ? struckOff[r]?.ch_no : "----"), styles: { fontStyle: "bold" } },
        { content: (struckOff?.length > 0 ? struckOff[r]?.inst_no : "----"), styles: { fontStyle: "bold" } },
        { content: (struckOff?.length > 0 ? struckOff[r]?.des : "----"), styles: { fontStyle: "bold" } },
        { content: (struckOff?.length > 0 ? struckOff[r]?.due_dt : "----"), styles: { fontStyle: "bold" } },
        { content: (struckOff?.length > 0 ? struckOff[r]?.debit : "----"), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.5 } }])
    }
    unpaidStruckBody.push([{ content: "TOTAL", colSpan: 4, styles: { fontStyle: "bold", fontSize: 8, halign: "center" } },
    { content: unpaid.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.debit), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.8 } },
    { content: "TOTAL", colSpan: 4, styles: { fontStyle: "bold", fontSize: 8, halign: "center" } }, { content: 0, styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.8 } }])
    //////////////////////////////////////////////////////End//////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////Outstanding Dues////////////////////////////////////////////
    //////////////////////////////////////////////////////Header/////////////////////////////////////////////////////
    outRefundHead.push([{ content: "OUTSTANDING DUES", colSpan: 7, styles: { fontStyle: "bold", fontSize: 12, width: 100, halign: "center" } },
    { content: "FEE REFUND", colSpan: 7, styles: { fontStyle: "bold", fontSize: 12, width: 100, halign: "center" } }]);
    outRefundHead.push([{ content: "Challan#" }, { content: "Entry Date" }, { content: "Description" },
    { content: "Tuition Fee" }, { content: "Misc (REG)" }, { content: "Misc (SSP)" }, { content: "Total Amount" },
    { content: "Entry Date" }, { content: "Description" }, { content: "Tuition Fee" }, { content: "Library Sec" },
    { content: "Exam Fee" },
    { content: "Hostel Sec" }, { content: "Total Amount" }]);

    //////////////////////////////////////////////////////Body////////////////////////////////////////////////////////////
    let outfineEntryDateCheck = [], reffineEntryDateCheck = [], tempOutStd = [], tempRefStd = [], tempArrBody = [];
    if (outstandDues?.length > feeRefund?.length)
      outRefundLength = outstandDues?.length
    else
      outRefundLength = feeRefund?.length

    for (let r = 0; r < outRefundLength; r++) {

      let outDuesTemp = outstandDues, refundTemp = feeRefund, outtuition = 0, misc_reg = 0, misc_ssp = 0, refundtuition = 0, exam = 0, lib = 0, host = 0, outcheck = false, refundcheck = false;

      if ((outfineEntryDateCheck.findIndex(e => e.outstand_fine_entry_date == outstandDues[r]?.outstand_fine_entry_date) == -1) && outstandDues[r]) {
        outtuition = 0, misc_reg = 0, misc_ssp = 0
        outDuesTemp = outstandDues.filter(e => e.outstand_fine_entry_date == outstandDues[r]?.outstand_fine_entry_date)
        outDuesTemp.forEach(e => {
          if (e.fine_id == 2)
            outtuition = e.amnt;
          else if (e.fine_id == 35)
            misc_reg = e.amnt;
          else if (e.fine_id == 36)
            misc_ssp = e.amnt;
        })
        outcheck = true;
        outfineEntryDateCheck.push({ outstand_fine_entry_date: outstandDues[r]?.outstand_fine_entry_date })
      }
      if ((reffineEntryDateCheck.findIndex(e => e?.refund_fine_entry_date == feeRefund[r]?.refund_fine_entry_date) == -1) && feeRefund[r]) {
        refundtuition = 0, exam = 0, lib = 0, host = 0
        refundTemp = feeRefund.filter(e => e?.refund_fine_entry_date == feeRefund[r]?.refund_fine_entry_date)
        refundTemp.forEach(e => {
          if (e.fine_id == 2)
            refundtuition = e.amnt;
          else if (e.fine_id == 3)
            exam = e.amnt;
          else if (e.fine_id == 7)
            lib = e.amnt;
          else if (e.fine_id == 30)
            host = e.amnt;
        })
        refundcheck = true;
        reffineEntryDateCheck.push({ refund_fine_entry_date: feeRefund[r]?.refund_fine_entry_date })
      }
      if (outcheck == true) {
        tempOutStd.push({
          outstd_ch_no: outstandDues[r]?.ch_no, outstd_fine_entry_date: outstandDues[r]?.outstand_fine_entry_date, outstd_des: outstandDues[r]?.des,
          outtuition: outtuition, misc_reg: misc_reg, misc_ssp: misc_ssp,
          out_tot: outtuition + misc_reg + misc_ssp
        })
      }
      if (refundcheck == true) {
        tempRefStd.push({
          refund_fine_entry_date: feeRefund[r]?.refund_fine_entry_date,
          refund_des: feeRefund[r]?.des,
          refund_tuition: refundtuition, lib: lib, exam: exam,
          host: host, refund_tot: refundtuition + lib + exam + host
        })
      }
    }
    if (tempOutStd?.length > tempRefStd?.length)
      outRefundLength = tempOutStd?.length
    else
      outRefundLength = tempRefStd?.length
    for (let i = 0; i < outRefundLength; i++) {
      outRefundBody.push([
        { content: tempOutStd[i] ? tempOutStd[i]?.outstd_ch_no : "----", styles: { fontStyle: "bold" } },
        { content: tempOutStd[i] ? tempOutStd[i]?.outstd_fine_entry_date : "----", styles: { fontStyle: "bold" } },
        { content: tempOutStd[i] ? tempOutStd[i]?.outstd_des : "----", styles: { fontStyle: "bold" } },
        { content: tempOutStd[i] ? tempOutStd[i]?.outtuition : 0 },
        { content: tempOutStd[i] ? tempOutStd[i]?.misc_reg : 0 },
        { content: tempOutStd[i] ? tempOutStd[i]?.misc_ssp : 0 },
        { content: tempOutStd[i] ? tempOutStd[i]?.out_tot : 0, styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.5 } },
        { content: tempRefStd[i] ? tempRefStd[i]?.refund_fine_entry_date : "----", styles: { fontStyle: "bold" } },
        { content: tempRefStd[i] ? tempRefStd[i]?.refund_des : "----", styles: { fontStyle: "bold" } },
        { content: tempRefStd[i] ? tempRefStd[i]?.refund_tuition : 0 },
        { content: tempRefStd[i] ? tempRefStd[i]?.lib : 0 },
        { content: tempRefStd[i] ? tempRefStd[i]?.exam : 0 },
        { content: tempRefStd[i] ? tempRefStd[i]?.host : 0 },
        { content: tempRefStd[i] ? tempRefStd[i]?.refund_tot : 0, styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.5 } }])
    }
    outRefundBody.push([{ content: "TOTAL", colSpan: 3, styles: { fontStyle: "bold", fontSize: 8, halign: "center" } },
    { content: tempOutStd.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.outtuition), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
    { content: tempOutStd.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.misc_reg), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
    { content: tempOutStd.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.misc_ssp), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
    { content: tempOutStd.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.out_tot), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.8 } },
    { content: "TOTAL", colSpan: 2, styles: { fontStyle: "bold", fontSize: 8, halign: "center" } },
    { content: tempRefStd.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.refund_tuition), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
    { content: tempRefStd.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.lib), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
    { content: tempRefStd.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.exam), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
    { content: tempRefStd.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.host), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
    { content: tempRefStd.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.refund_tot), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.8 } },
    ])
    //////////////////////////////////////////////////////End/////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////Scholarship//////////////////////////////////////////////////
    ///////////////////////////////////////////////////Header///////////////////////////////////////////////////////
    schpAdjHead.push([{ content: "DUES ADJUSTMENT/ FEE CONCESSION/ SCHOLARSHIP", colSpan: 13, styles: { fontStyle: "bold", fontSize: 12, width: 100, halign: "center" } }]);
    schpAdjHead.push([{ content: "Challan#" }, { content: "Inst-No" }, { content: "Dues Adjustment Description" },
    { content: "Fee Concession Description" }, { content: "Fee Conc Entry Date" }, { content: "Scholarship Description" }, { content: "Schp Entry Date" },
    { content: "Dues Adj" }, { content: "Fee Conc" }, { content: "Schp" }, { content: "Concession Amount" }, { content: "Actual Amount" }, { content: "Payable Amount" }]);

    SchpFeeConcDuesAdj.forEach(e => {
      schpAdjBody.push([
        { content: e.ch_no, styles: { fontStyle: "bold" } }, { content: e.inst_no, styles: { fontStyle: "bold" } }, { content: e.dues_adj_des, styles: { fontStyle: "bold", fontSize: bodyfontStyle - 0.3 } },
        { content: e.fee_conc_des, styles: { fontStyle: "bold", fontSize: bodyfontStyle - 0.8 } }, { content: e.fee_conc_dt, styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
        { content: e.schp_des, styles: { fontStyle: "bold", fontSize: bodyfontStyle - 0.8 } }, { content: e.schp_dt, styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } }, { content: e.dues_adj_amnt },
        { content: e.fee_conc_amnt }, { content: e.schp_dues_amnt }, {
          content: parseInt(e.dues_adj_amnt) +
            parseInt(e.fee_conc_amnt) + parseInt(e.schp_dues_amnt), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.5 }
        }, { content: e.ActAmnt, styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.5 } }, { content: e.payableAmnt, styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.5 } }
      ])
    })
    schpAdjBody.push([{ content: "TOTAL", colSpan: 7, styles: { fontSize: 8, halign: "center", fontStyle: "bold" } },
    { content: SchpFeeConcDuesAdj.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.dues_adj_amnt), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
    { content: SchpFeeConcDuesAdj.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.fee_conc_amnt), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
    { content: SchpFeeConcDuesAdj.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.schp_dues_amnt), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 0.8 } },
    {
      content: SchpFeeConcDuesAdj.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.dues_adj_amnt), 0) +
        SchpFeeConcDuesAdj.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.fee_conc_amnt), 0) +
        SchpFeeConcDuesAdj.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.schp_dues_amnt), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.8 }
    },
    { content: SchpFeeConcDuesAdj.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.ActAmnt), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.8 } },
    { content: SchpFeeConcDuesAdj.reduce((previousValue, currentValue) => parseInt(previousValue) + parseInt(currentValue.payableAmnt), 0), styles: { fontStyle: "bold", fontSize: bodyfontStyle + 1.8 } }]);
    autoTable(doc, {
      theme: 'grid',
      startY: y,
      margin: { top: 82, bottom: 20, left: 7, right: 7 },
      styles: {
        fontSize: bodyfontStyle,
      },
      head: header,
      body: body,
      // foot:footer,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0], // sets the text color of the header to black
        lineColor: [0, 0, 0],
        lineWidth: 0.1, // sets the width of the border to 0.2
        fontSize: headfontStyle,
        fontStyle: 'bold'
      }, // sets the border color of the header to black
      bodyStyles: {
        lineWidth: 0.1, // sets the width of the border to 0.2
        lineColor: [0, 0, 0], // sets the color of the border to black
      },
      columnStyles: {
        2: { cellWidth: 41 },
      },
      footStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
        fontStyle: 'bold'
      },
      showHead: "everyPage",
      showFoot: "lastPage",

      didDrawPage: function (data) {

        if(lengthCheck==MapSize)
        {
          page = '';
          page = doc.getNumberOfPages();
        }
        cursor1 = data.cursor.y;
        cursor2 = data.cursor.y;
        cursor3 = data.cursor.y;
        cursor4 = data.cursor.y;
        cursor5 = data.cursor.y;

        innerHead(expDate)


        doc.setFontSize(9);
        doc.text('Prepared By: ____________________', 9, 215, { align: 'left' });
        doc.text('Assistant Treasurer: ____________________', 100, 215, { align: 'center' });
        doc.text('Deputy Treasurer: ____________________', 170, 215, { align: 'center' });
        doc.text('Additional Treasurer: ____________________', 240, 215, { align: 'center' });
        doc.text('Treasurer: ______________________', 308, 215, { align: 'center' });

      },
    });
    autoTable(doc, {
      theme: 'grid',
      startY: cursor1 + 5,
      margin: { left: 7, right: 7, top: 82, bottom: 20 },
      styles: {
        fontSize: bodyfontStyle,
      },
      head: unpaidStruckHead,
      body: unpaidStruckBody,
      columnStyles: {
        0: { cellWidth: 31.9 },
        1: { cellWidth: 30.9 },
        2: { cellWidth: 39.9 },
        3: { cellWidth: 30.9 },
        4: { cellWidth: 30.9 },
        5: { cellWidth: 32.9 },
        6: { cellWidth: 32.9 },
        7: { cellWidth: 32.9 },
        8: { cellWidth: 32.9 },
        9: { cellWidth: 31.9 },
        10: { cellWidth: 32.9 },
      },
      // foot:footer,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0], // sets the text color of the header to black
        lineColor: [0, 0, 0],
        lineWidth: 0.1, // sets the width of the border to 0.2
        fontSize: headfontStyle,
        fontStyle: 'bold'
      }, // sets the border color of the header to black
      bodyStyles: {
        lineWidth: 0.1, // sets the width of the border to 0.2
        lineColor: [0, 0, 0], // sets the color of the border to black
      },
      footStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
        fontStyle: 'bold'
      },
      showHead: "everyPage",
      showFoot: "lastPage",
      didDrawPage: function (data) {
        cursor2 = data.cursor.y;
        cursor3 = data.cursor.y;
        cursor4 = data.cursor.y;
        cursor5 = data.cursor.y;
      }
    });
    autoTable(doc, {
      theme: 'grid',
      startY: cursor2 + 5,
      margin: { left: 7, right: 7, top: 82, bottom: 20 },
      styles: {
        fontSize: bodyfontStyle,
      },
      head: outRefundHead,
      body: outRefundBody,
      columnStyles: {
        0: { cellWidth: 20.5 },
        1: { cellWidth: 20 },
        2: { cellWidth: 44 },
        3: { cellWidth: 19.5 },
        4: { cellWidth: 19.5 },
        5: { cellWidth: 19.5 },
        6: { cellWidth: 21.5 },
        7: { cellWidth: 20.5 },
        8: { cellWidth: 45 },
        9: { cellWidth: 19.5 },
        10: { cellWidth: 19 },
        11: { cellWidth: 18.5 },
        12: { cellWidth: 19.5 },
        13: { cellWidth: 21.5 },
        14: { cellWidth: 23.5 },
      },
      // foot:footer,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0], // sets the text color of the header to black
        lineColor: [0, 0, 0],
        lineWidth: 0.1, // sets the width of the border to 0.2
        fontSize: headfontStyle,
        fontStyle: 'bold'
      }, // sets the border color of the header to black
      bodyStyles: {
        lineWidth: 0.1, // sets the width of the border to 0.2
        lineColor: [0, 0, 0], // sets the color of the border to black
      },
      footStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
        fontStyle: 'bold'
      },
      showHead: "everyPage",
      showFoot: "lastPage",
      didDrawPage: function (data) {
        cursor3 = data.cursor.y;
        cursor4 = data.cursor.y;
        cursor5 = data.cursor.y;
      }
    });
    autoTable(doc, {
      theme: 'grid',
      startY: cursor3 + 5,
      margin: { bottom: 20, left: 7, right: 7, top: 82 },
      styles: {
        fontSize: bodyfontStyle,
      },
      head: schpAdjHead,
      body: schpAdjBody,
      columnStyles: {
        0: { cellWidth: 13.9 },
        1: { cellWidth: 11.9 },
        2: { cellWidth: 39.9 },
        3: { cellWidth: 47 },
        4: { cellWidth: 28.9 },
        5: { cellWidth: 47 },
        6: { cellWidth: 22.9 },
        7: { cellWidth: 15.9 },
        8: { cellWidth: 15.9 },
        9: { cellWidth: 12.9 },
        10: { cellWidth: 28 },
        11: { cellWidth: 20.9 },
        12: { cellWidth: 22.9 },
      },
      // foot:footer,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0], // sets the text color of the header to black
        lineColor: [0, 0, 0],
        lineWidth: 0.1, // sets the width of the border to 0.2
        fontSize: headfontStyle,
        fontStyle: 'bold'
      }, // sets the border color of the header to black
      bodyStyles: {
        lineWidth: 0.1, // sets the width of the border to 0.2
        lineColor: [0, 0, 0], // sets the color of the border to black
      },
      footStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        fontSize: 10,
        fontStyle: 'bold'
      },
      showHead: "everyPage",
      showFoot: "lastPage",
      didDrawPage: function (data) {
        cursor4 = data.cursor.y;
        cursor5 = data.cursor.y;
        innerHead(expDate)


        doc.setFontSize(9);
        doc.text('Prepared By: ____________________', 9, 215, { align: 'left' });
        doc.text('Assistant Treasurer: ____________________', 100, 215, { align: 'center' });
        doc.text('Deputy Treasurer: ____________________', 170, 215, { align: 'center' });
        doc.text('Additional Treasurer: ____________________', 240, 215, { align: 'center' });
        doc.text('Treasurer: ______________________', 308, 215, { align: 'center' });
        // doc.text("Directorate of Information & Technology",10,220)
        // doc.text("Note:- Errors and Omissions Excepted", 280, 220);

        if(rolnoCheck!==i[0])
        {
          for (var l = 1; l <= page; l++) {
            doc.setPage(l);
            doc.setFontSize(8)
            doc.text('Page ' + l + " of " + page, 167, 220);
            doc.text("Directorate of Information & Technology",10,220)
            doc.text("Note:- Errors and Omissions Excepted", 280, 220);
          }
          rolnoCheck=i[0]
          doc.addPage();
          lengthCheck++;
        }
      }
    })
  }
  window.open(URL.createObjectURL(doc.output('blob')), '_blank');

  }
  OnSubmit() {
  }

  onClear() {
    this.formRef.reset();
  }


  ClearClassBase() {
    this.formRef?.controls['year_in'].reset();
    this.formRef?.controls['f_rn'].reset();
    this.formRef?.controls['l_rn'].reset();
  }

  ClearYearBase() {
    this.formRef?.controls['f_rn'].reset();
    this.formRef?.controls['l_rn'].reset();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}