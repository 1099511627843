import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { UtilService } from 'src/app/Util.service';
import { TimetableModel } from './../view-timetable/model/Timetable.model';
import { NgForm } from '@angular/forms';
import { TimetableService } from '../insert-time-table/Timetable.service';
import { ToastrService } from 'ngx-toastr';
import { TeacherService } from './../shared/services/Teacher.service';
import { SelectList } from '../shared/model/SelectList.model';
import { BlockService } from './../shared/services/Block.service';
import { RoomService } from './../shared/services/Room.service';

@Component({
  selector: 'app-timetable-entry',
  templateUrl: './timetable-entry.component.html',
  styleUrls: ['./timetable-entry.component.css']
})
export class TimetableEntryComponent implements OnInit {
  durations: Array<{ dur: string, des: string }>;
  timings: Array<{ start_time: string, desc: string }>;
  @Input('inData') data: TimetableModel;
  @Input('editable') isEditable: boolean;
  @Output('delete') del = new EventEmitter<string>();
  @ViewChild('f') formRef: NgForm;
  @ViewChild('room') roomRef: HTMLSelectElement;

  editView: boolean;

  manageTeacher: boolean;
  teachers: Array<SelectList>;
  blocks: SelectList[];
  rooms: SelectList[];
  constructor(private utilService: UtilService,
    private teacherService:TeacherService,
    private timetableService: TimetableService,
    private toastr: ToastrService,
    private blockService:BlockService,
    private roomService:RoomService) {
    this.teachers = new Array<SelectList>();
    this.blocks = [];
    this.rooms = [];
    this.durations = new Array<{ dur: string, des: string }>();
    this.timings = new Array<{ start_time: string, desc: string }>();
    this.editView = false;
    this.manageTeacher = false;
  }

  ngOnInit(): void {
    this.getTimeDuration();
  }

  toggleEditMode() {
    if(this.isEditable){
      if (!this.editView && this.teachers.length < 1) {
        this.loadTeachers();
      }
      if (!this.editView && this.blocks.length < 1) {
        this.loadBuildings();
      }
      this.editView = !this.editView;
    }   
  }

  OnBuildingChange(blk: HTMLSelectElement) {
    this.loadRooms();
  }

  getTimeDuration() {
    this.timetableService.getTimeDuration().subscribe((res) => {
      if (res) {
        res[0]?.forEach(entry => {
          this.timings.push({ start_time: entry.start_time, desc: entry.des });
        });
        res[1]?.forEach(entry => {
          this.durations.push({ dur: entry.duration, des: entry.d_des });
        });
      }
    });
  }

  OnManageTeacher() {
    this.manageTeacher = true;
  }

  OnCloseManageTeacher() {
    this.manageTeacher = false;
  }



  OnNewTeacher(teacher) {
    this.teachers.push(teacher);
  }

  OnDeleteClick() {
    if (this.data.status === 0 || this.data.status === 1) {
      this.timetableService.deleteEntry(this.data.tmId).subscribe((res: { msg: string }) => {
        if (res) {
          this.toastr.success("Deleted Successfully");
          this.del.emit(this.data.tmId);
        }
        else {
          this.toastr.warning("Some error Occured");
        }
      }, err => {
        console.log(err);
        this.toastr.error("Some error Occured");
      });
    } else {
      this.toastr.warning("You cannot Delete this entry");
    }
  }

  updateTeacher() {
    if (this.data.status === 0 || this.data.status === 1) {
      if (this.formRef.value.fm_id !== "" && this.formRef.value.fm_id != this.data.fmId) {
        this.timetableService.validateTeacher(this.formRef.value.fm_id, this.formRef.value.day || this.data.day, this.data.sTime, this.data.eTime, this.data.status.toString()).subscribe((res: { res: number }) => {
          if (res.res === 1) {
            this.updateEntry(this.data.tmId, this.formRef.value.fm_id, this.data.day, this.data.sTime, "-1", this.data.blkId, this.data.rmId);
            this.toastr.success("Teacher is Updated Successfully");
          } else {
            this.toastr.warning("Teacher is not Available");
          }
        }, err => {
          console.log(err);
          this.toastr.error("Some error Occured");
        });
      }
    }else {
      this.toastr.warning("You cannot update this Teacher");
    }
  }

  OnSubmit() {
    if (this.data.status === 0 || this.data.status === 1) {
      if (this.formRef.value.day || (this.formRef.value.s_time && this.formRef.value.duration)) {
        this.timetableService.validateUpdateEntry(this.data.tmId, this.data.fmId, (this.formRef.value.day || this.data.day), this.formRef.value.s_time || this.data.sTime, (this.formRef.value.duration || -1), this.data.blkId, this.data.rmId, (this.data.status)).subscribe(res => {
          if (res[0].res === 1) {
            this.updateEntry(this.data.tmId, this.data.fmId, (this.formRef.value.day || this.data.day), (this.formRef.value.s_time || this.data.sTime), (this.formRef.value.duration || -1), (this.formRef.value.rm_id !== "" ? this.formRef.value.blk_id : this.data.blkId), (this.formRef.value.rm_id || this.data.rmId));
            this.toastr.success("Time Updated Successfully");
          } else if (res[0].res === -2) {
            this.toastr.warning("Teacher Is not Available");
          } else if (res[0].res === -1) {
            this.toastr.warning("Section Is not Available");
          }
        }, err => {
          console.log(err);
          this.toastr.error("Error Occuresd");
        });
      }
    }else {
      this.toastr.warning("You cannot update this entry");
    }
  }


  updateRoom() {
    if (this.data.status === 0 || this.data.status === 1) {
      if (this.formRef.value.blk_id !== "" && this.formRef.value.rm_id) {
        this.updateEntry(this.data.tmId, this.data.fmId, this.data.day, this.data.sTime, "-1", this.formRef.value.blk_id, this.formRef.value.rm_id);
        this.toastr.success("Room Updated Successfully");
      }
    }else {
      this.toastr.warning("You cannot update this entry");
    }
  }


  updateEntry(tmId: string, fmId: string, day: string, sTime: string, dur: string, blkId: string, rmId: string) {
    if (this.data.status === 0 || this.data.status === 1) {
      this.timetableService.updateEntry(tmId, fmId, day, sTime, dur, blkId, rmId)
        .subscribe(res => {
          if (res) {
            var d: TimetableModel;
            for (const i in res) {
              d = new TimetableModel(res[i].sub_code, res[i].grp, res[i].tm_id, res[i].d_id, res[i].d_nm, res[i].c_nm, res[i].se, res[i].t_no, res[i].section, res[i].subject, res[i].fm_id, res[i].teacher, res[i].day, res[i].start_time, res[i].end_time, res[i].blk_id, res[i].blk_nm, res[i].rm_id, res[i].rm_nm, res[i].status);
            }
            this.data = d;
          }
        }, err => {
          console.log(err);
          this.toastr.error("Somer error Occured");
        });
    }
    else {
      this.toastr.warning("You cannot update this entry");
    }
  }

  loadBuildings() {
    this.rooms = [];
    this.blockService.getBlocks().subscribe((res:{BLK_ID:number,BLK_NM:string}[]) => {
      res?.forEach(entry=>{
        this.blocks.push({id:entry.BLK_ID, nm:entry.BLK_NM});
      })
    });
  }

  loadRooms() {
    if (this.formRef.value.blk_id !== "") {
      this.rooms = [];
      this.roomService.getRooms(this.formRef.value.blk_id, this.data.day, this.data.sTime, this.data.eTime, "-1", this.data.status).subscribe((res:{RM_ID:number,RM_NM:string}[]) => {
        res?.forEach(entry=>{
          this.rooms.push({id:entry.RM_ID,nm:entry.RM_NM});
        })
      });
    }
  }

  loadTeachers() {
    this.teachers = [];
    this.teacherService.getTeachers(this.data.dId).subscribe((res:{FM_ID:number,FM_NM:string}[]) => {
      res?.forEach(entry=>{
        this.teachers.push({id: entry.FM_ID, nm: entry.FM_NM});
      })      
    });
  }
}
