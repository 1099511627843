<div [@SlideInFromLeft]>
  <div class="card shadow">

    <div class="card-header"  style="padding-top: 10px;">
      <div class=" col-lg-6 col-md-6 form-group">
        Result Submission Check</div>
         <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
        <app-details-button [details]="details"></app-details-button>
    </div>
  </div>
    <div class="card-body">
      <div class="col-sm-12 col-lg-12 p-2">
        <form #f="ngForm">
          <div class="row">
            <div class="col-lg-6 col-md-6 form-group">
              <label for="d_id">Department</label>
              <select #d_id class="form-control" name="d_id" id="d_id"
                [(ngModel)]="this.gr" (change)="loadClasses()"
                required>
                <option *ngFor="let d of departments"
                  value="{{d.id}}">{{d.nm}}</option>
              </select>
            </div>
            <div class="col-lg-6 col-md-6 form-group">
              <label for="C_Code">Class</label>
              <select #C_Code class="form-control " name="C_Code" id="C_Code"
                (change)="onClassChange(C_Code)"
                [(ngModel)]="this.selected">
                <!-- <option value="" disabled selected>Select the C_Code</option> -->
                <option *ngFor="let item of this.classes"
                  value="{{item.id}}">{{item.nm}}</option>
              </select>
            </div>
            <div class="col-lg-6 col-md-6 form-group">
              <label for="Session">Session</label>
              <select class="form-control " #session name="se_id" id="se_id"
                (change)="onSemesterChange()"
                [(ngModel)]="this.se_select" ngModel required>
                <option *ngFor="let item of this.sessions"
                  value="{{item.id}}">{{item.nm}}</option>
              </select>
            </div>
            <div class="col-lg-6 col-md-6 form-group">
              <label for="t_no">Semester</label>
              <select #semester class="form-control"
                (change)="onSemesterChange()" #session name="t_no" id="t_no"
                [(ngModel)]="this.sem" ngModel required>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="0">0</option>
              </select>
            </div>
          </div>
          <div class="mt-2">
            <button class="btn btn-md btn-outline-primary  mt-2 "
              (click)="showList()" type="button" [disabled]="!f.valid">Show List of
              Teachers Who Have Not Submitted Result</button>
            <button class="btn btn-md btn-outline-primary ml-2  mt-2 "
              (click)="showRList()" type="button" [disabled]="!f.valid">Show List of
              Teachers Who Have Submitted Result</button>
            <button *ngIf="this.info.length>0"
              class="btn btn-md btn-danger ml-2  mt-2 "
              (click)="OnCreatePDF()" type="button"><i class="fa fa-file-pdf-o" for=""></i> Download Pdf</button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="this.info.length>0"
      class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-0">
      <div class="card text-left">
        <div class="card-header"><i>List of Teachers </i></div>
        <div class="card-body">
          <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
            <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
        </div>
          <table id="html"
            class="table table-bordered table-striped m-0 p-0" id="table">
            <thead class="thead-dark">
              <tr>
                <th>Sr No</th>
                <th>Name</th>
                <!-- <th>CNIC</th> -->
                <th>Subject</th>
                <!-- <th>Department</th> -->
                <th>Section</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.info; let j=index;" id="values">
                <td>{{j+1}}</td>
                <td>{{item.Name}}</td>
                <!-- <td>{{item.CNIC}}</td> -->
                <td>{{item.Subject}}</td>
                <!-- <td>{{item.Department}}</td> -->
                <td>{{item.Section}}</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      <br>
    </div>
  </div>
</div>