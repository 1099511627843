<div [@SlideInFromLeft] class="container-fluid">
    <div class="row card shadow">
        <div class="card-header"  style="padding-top: 10px;">
            <div class=" col-lg-6 col-md-6 form-group">
                Exam Result Correction</div>
               <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
              <app-details-button [details]="details"></app-details-button>
          </div>
        </div>
        <form [formGroup]="lockForm" class="px-4 mb-0 pb-0">
            <div class="row card-body px-0 mb-0">
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="C_Code">Class</label>
                    <select #cource (change)="OnClassChange()" class="form-control " name="c_code" id="C_Code"
                        formControlName="c_code">
                        <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="Session">Year</label>
                    <input class="form-control " name="year" id="Session" formControlName="year"
                        (change)="OnYearChange()">
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="semester">Semester</label>
                    <select #t_no (change)="OnSemesterChange()" class="form-control " name="t_no" id="Semester"
                        formControlName="t_no" ngModel>
                        <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]" value="{{i}}">{{i}}</option>
                        <option value="-1">Complete</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="rolno">Roll no</label>
                    <select #rolno class="form-control" name="rolno" id="rolno" formControlName="rolno"
                        (change)=" this.getName(rolno)" ngModel>
                        <option *ngFor="let item of this.rolnumbers" value="{{item.nm}}">{{item.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="st_name">Student Name</label>
                    <input type="text" class="form-control" placeholder="Student Name" name="st_name" id="st_name"
                        readonly value="{{this.NM}}">
                </div>
                <div class="row card-body mt-3 pt-0 mb-0 pb-0">
                    <div *ngIf="this.rolnumbers?.length>0" class="row">
                        <div>
                            <button type="button" class="btn btn-outline-primary ml-2" (click)="getSubjects()">Display
                                Subject
                                List</button>
                        </div>
                    </div>
                    <div *ngIf="this.rolnumbers?.length>0" class="row ml-4">
                        <div>
                            <button class="ml-2" type="button" class="btn btn-outline-primary"
                                (click)="getExempted()">Display
                                Exempted Subjects</button>
                        </div>
                    </div>
                    <div *ngIf="this.rolnumbers?.length>0" class="row ml-4">
                        <div>
                            <button class="ml-2" type="button" class="btn btn-outline-primary"
                                (click)="getReplaced()">Display
                                Replaced Subjects</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div *ngIf="this.subjectslist?.length>0" class="mt-0 pt-0 px-4">
            <div class="card-header px-0">
                Exam Result Subjects
            </div>
            <div class="card-body mt-0 px-0">
                <table class="table table-bordered table-striped">
                    <thead class="thead-dark">
                        <tr style="align-items:center; font-size:14px">
                            <th scope="col">No.</th>
                            <th scope="col">Subject Code</th>
                            <th scope="col">Subject Title</th>
                            <th scope="col">Grade</th>
                            <th scope="col">Session</th>
                            <th scope="col">Attempt No</th>
                            <th scope="col">Orignal Term</th>
                            <th scope="col" colspan="2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let c of subjectslist; let i = index" style="font-weight: bold;">
                            <td>{{i+1}}</td>
                            <td name="sub_code">{{c.SUB_CODE}}</td>
                            <td>{{c.SUB_NM}}</td>
                            <td>{{c.GRD}}</td>
                            <td>{{c.SE_NM}} ({{c.SE_ID}})</td>
                            <td>
                                <div style="align-items: center; justify-content: center; display:flex">
                                    <div *ngIf="isExam; then thenChangeBlock else elseAdminBlock"
                                        class="row card-body pb-0"></div>
                                    <ng-template #thenChangeBlock>
                                        <input #newATMP_NO class="inputCell" maxlength=1 name="newATMP_NO"
                                            (change)="updateAttpNo(c.SUB_CODE,c.SE_ID,c.T_NO,newATMP_NO)"
                                            id="newATMP_NO" ngModel="{{c.ATMP_NO}}">
                                    </ng-template>
                                    <ng-template #elseAdminBlock>
                                        <input class="call" #newATMP_NO name="newATMP_NO" id="newATMP_NO"
                                            style="border: none;" ngModel="{{c.ATMP_NO}}" readonly>
                                    </ng-template>
                                </div>
                            </td>

                            <td>
                                <div style="align-items: center; justify-content: center; display:flex">
                                    <div *ngIf="isExam; then thenChangeORIGBlock else elseAdminORIGBlock"
                                        class="row card-body pb-0"></div>
                                    <ng-template #thenChangeORIGBlock>
                                        <input #newORIG_TNO class="inputCell" maxlength=1
                                            (change)="updateOrigTno(c.SUB_CODE,c.SE_ID,c.T_NO,newORIG_TNO)"
                                            id="newORIG_TNO" ngModel="{{c.ORIG_TNO}}">
                                    </ng-template>
                                    <ng-template #elseAdminORIGBlock>
                                        <input class="call" #newORIG_TNO ngModel="{{c.ORIG_TNO}}" id="newORIG_TNO"
                                            readonly style="border: none;">
                                    </ng-template>
                                </div>
                            </td>

                            <td *ngIf='i===index && edit' class="input-group input-group-sm" id="pppp">
                                <input for="exempt" #exempt [checked]="c.STATUS==='EXEMPTED'" class="form-control"
                                    aria-label="Small" aria-describedby="inputGroup-sizing-sm" type="checkbox"
                                    value="EXEMPTED" name="exempt" id="exempt" (change)="OnExempt(i,exempt)">
                                <span id="span" for="exempt" name="exempt" class="input-group-text"
                                    id="exempt">EXEMPT</span>
                            </td>
                            <td *ngIf='i===index && edit && this.exempt' class="input-group input-group-sm" id="pppp">
                                <input #replace class="form-control" aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm" type="checkbox" value="1" name="replace"
                                    id="replace" (change)="ONREPLACE(i)">
                                <span id="span" class="input-group-text" id="replace">REPLACE</span>
                            </td>
                            <td *ngIf='i===index && edit && this.replace' class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-sm">New Session</span>
                                </div>
                                <select #repse_id class="form-control " name="repse_id" id="repse_id"
                                    (change)="OnReplacmentSessionChange(repse_id)" ngModel>
                                    <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
                                </select>
                            </td>
                            <td *ngIf='i===index && edit && this.replace' class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-sm">Term</span>
                                </div>
                                <select #rept_no class="form-control " name="rept_no" id="rept_no"
                                    (change)="this.OnReplacmentT_noChange(rept_no)" ngModel>
                                    <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]" value="{{i}}">{{i}}</option>
                                </select>
                            </td>
                            <td *ngIf='i===index && edit && this.replace' class="input-group input-group-sm">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="inputGroup-sizing-sm">Subjects</span>
                                </div>
                                <select #id class="form-control " name="sub_code" id="sub_code"
                                    (change)="this.OnReplacmentSubChange(i,id)" ngModel>
                                    <option *ngFor="let i of this.subjects" value="{{i.id}}">{{i.id}}-{{(i.nm)}}
                                    </option>
                                </select>
                            </td>

                            <td *ngIf='i===index && edit && this.replace' class="input-group input-group-sm">
                                <button class="btn btn-outline-success mr-3" (click)="SubjectReplace(i)">
                                    Insert <i class="fas fa-user"></i>
                                </button>
                            </td>

                            <td>
                                <button class="btn btn-outline-warning" (click)="toggleRecord(i)">
                                    <i class="fas fa-edit"></i>
                                </button>
                            </td>
                            <td id="del">
                                <button *ngIf="isExam" #delete class="form-control btn btn-danger fa fa-trash"
                                    type="button" name="delete" id="delete"
                                    (click)="onDeleteExRslt(c.SUB_CODE,c.SE_ID,c.T_NO)"></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div *ngIf="this.ExemptedList?.length>0" class="mt-0 pt-0 px-4">
            <div class="card-header px-0">
                Exempted Subjects
            </div>
            <div class="card-body mt-0 px-0">
                <table class="ttable table-bordered table-striped">
                    <thead class="thead-dark">
                        <tr style="align-items:center; font-size:14px">
                            <th scope="col">No.</th>
                            <th scope="col">Subject Code</th>
                            <th scope="col">Subject Title</th>
                            <th scope="col">Grade</th>
                            <th scope="col">Session</th>
                            <th scope="col">Orignal Term</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let c of ExemptedList; let i = index">
                            <td>{{i+1}}</td>
                            <td>{{c.SUB_CODE}}</td>
                            <td>{{c.SUB_NM}}</td>
                            <td>{{c.GRD}}</td>
                            <td>{{c.SE_NM}}</td>
                            <td>{{c.ORIG_TNO}}</td>
                            <td>{{c.STATUS}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="this.ReplacedList?.length>0" class="mt-0 pt-0 px-4">
            <div class="card-header px-0">
                Replaced Subjects
            </div>
            <div class="card-body mt-0 px-0">
                <table class="table table-bordered table-striped">
                    <thead class="thead-dark">
                        <tr style="align-items:center; font-size:14px">
                            <th scope="col">No.</th>
                            <!-- <th scope="col">Pre-SE_ID</th> -->
                            <th scope="col">Pre-TNO</th>
                            <th scope="col">Pre-Sub Code</th>
                            <th scope="col">Pre-Subject Title</th>
                            <!-- <th scope="col">Rep-SE_ID</th> -->
                            <th scope="col">Rep-TNO</th>
                            <th scope="col">Rep-Sub Code</th>
                            <th scope="col">Rep-Subject Title</th>
                            <th scope="col">ATMP</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let c of ReplacedList; let i = index">
                            <td>{{i+1}}</td>
                            <!-- <td>{{c.Pre_SE_ID}}</td> -->
                            <td>{{c.Pre_TNO}}</td>
                            <td>{{c.Pre_SUB_CODE}}</td>
                            <td>{{c.Pre_SUB_NM}}</td>
                            <!-- <td>{{c.Rep_SE_ID}}</td> -->
                            <td>{{c.Rep_TNO}}</td>
                            <td>{{c.Rep_SUB_CODE}}</td>
                            <td>{{c.Rep_SUB_NM}}</td>
                            <td>{{c.ATMP_NO}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>