<div class="card" [@SlideInFromLeft]>
    <div class="card-header">Old Result Transfer in New Session</div>
    <form id="form1" #f="ngForm" class="px-4">
        <div class="row card-body px-0">
            <div class="form-group col-lg-4">
                <label for="C_Code">Class</label>
                <select class="form-control" id="C_Code" ngModel name="c_code">
                    <option *ngFor="let i of classes " value="{{i.id}}">{{i.nm}}</option>
                </select>
            </div>
            <div class="form-group col-lg-4">
                <label for="oldSession">Old Session</label>
                <input type="number" #oldSession id="oldSession" min="2000" ngModel name="year_in" class="form-control"
                    (change)="getRolno()">
            </div>
            <div class="form-group  col-lg-4">
                <label for="tno">Term No</label>
                <select class="form-control" id="tno" aria-label="tno" ngModel name="tno" #tno>
                    <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]" value="{{i}}">{{i}}</option>
                </select>
            </div>
            <div class="form-group col-lg-4">
                <label for="newSession">New Session</label>
                <input type="number" #newSession id="newSession" ngModel min="2000" name="newSession"
                    class="form-control">
            </div>
            <div class="form-group col-lg-4">
                <label for="rolno">Roll No</label>
                <select class="form-control" (change)="getStdtName()" name="rolno" id="rolno" ngModel required>
                    <option *ngFor="let r of rolno" value="{{r}}">{{r}}</option>
                </select>
            </div>
            <div class="form-group col-lg-4">
                <label for="NM">Name</label>
                <input type="text" ngModel value={{name}} name="NM" readonly class="form-control">
            </div>
            <div>
                <button (click)="getstdtSubData()" class="btn btn-primary mt-3 ml-3" [disabled]="!f.valid">Display Subjects</button>
            </div>
        </div>
        <div class="card-header px-0">Subject Information</div>
        <div class="row card-body px-3">
            <ng-container *ngIf="valid; then IfBlock"></ng-container>
            <ng-template #IfBlock>
                <table id="table" class="table table-bordered table-striped">
                    <thead class="thead-dark">
                        <tr style="font-size:12px;text-align:center;font-weight:bold">
                            <th>SUBJECT CODE</th>
                            <th>SUBJECT TITLE</th>
                            <th>GRADE</th>
                            <th>ENROLL</th>
                            <th>GRADE</th>
                            <th>EXEMPTED</th>
                            <th>UPDATE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of stdtSubData;let j=index" style="text-align:center;font-weight:bold;">
                            <td style="padding-top:25px;font-size:14px">{{i?.sub_code}}</td>
                            <td style="padding-top:25px;font-size:14px">{{i?.sub_nm}}</td>
                            <td style="padding-top:25px;font-size:14px">{{i?.grd}}</td>
                            <!-- <td style="align-items:center;">
                                <span class="round">
                                    <input type="checkbox" id="checkbox" name="subject" id="subject" #subject
                                        [checked]="i.subjectValue == 1 || stdtSubDataReplica[j].subjectValue==1" value="{{i.subjectValue}}">
                                    <label for="checkbox" style="margin-top:10px;margin-left:-10px"
                                        (click)="RadioBox($event,i,j,1)"></label>
                                </span>
                            </td> -->
                            <td style="align-items:center">
                                <span class="round" style="margin-top:1%">
                                    <input type="checkbox" id="checkbox" name="enroll" id="enroll" #enroll
                                        [checked]="i.enrollValue==1 || stdtSubDataReplica[j].enrollValue==1" value="i.enrollValue"
                                        >
                                    <label for="checkbox" (click)="RadioBox($event,i,j,2)"
                                        style="margin-top:10px;margin-left:-10px"></label>
                                </span>
                            </td>
                            <td style="align-items:center">
                                <span class="round" style="margin-top:1%">
                                    <input type="checkbox" id="checkbox" id="grade"
                                        value="i.gradeValue" name="grade" #grade
                                        [checked]="i.gradeValue==1|| stdtSubDataReplica[j].gradeValue==1">
                                    <label for="checkbox" style="margin-top:10px;margin-left:-10px"
                                        (click)="RadioBox($event,i,j,3)"></label>
                                </span>
                            </td>
                            <td style="align-items:center">
                                <span class="round" style="margin-top:1%">
                                    <input type="checkbox" id="checkbox" name="excempted" id="excempted"
                                        [checked]="i.excemptedValue==1|| stdtSubDataReplica[j].excemptedValue==1" value="{{i.excemptedValue}}" #excempted>
                                    <label (click)="RadioBox($event,i,j,4)" for="checkbox"
                                        style="margin-top:10px;margin-left:-10px"></label>
                                </span>
                            </td>
                            <td style="padding:10px 0px">
                                <button class="button button-primary hov" (click)="updateStdtData(i,j)"
                                    style="font-size: 18px; padding: 10px 24px; border: none; background-color: none; background: none; border-radius: 4px;"
                                    [disabled]="stdtSubDataReplica[j].enrollValue==1&&
                                    stdtSubDataReplica[j].gradeValue==1&&stdtSubDataReplica[j].excemptedValue==1">
                                    <h4 [ngClass]="{shiftedCl:stdtSubDataReplica[j].gradeValue==1&&stdtSubDataReplica[j].excemptedValue==1}" style="font-weight:bold">&#10003;</h4>
                                </button></td>
                        </tr>
                        <tr style="text-align:center;font-weight:bold;font-size:14px;" >
                            <td colspan="6" style="padding-top:15px">
                                Shift Data with Single Click
                            </td>
                            <td style="padding:10px 2px">
                                <button class="button button-primary hov" (click)="ShiftAll()" [disabled]="shifted==1"
                                    style="padding: 10px 24px; border: none; background-color: none; background: none; border-radius: 4px;font-size:12px">
                                    <b style="color:green">Shift All Subjects</b>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-template>
        </div>

    </form>
</div>