<form (ngSubmit)="OnSubmit()" #f="ngForm">
  <div [@SlideInFromLeft] class="container-fluid">

    <div class=" row card-body card shadow">

      <div class="card-header">
        Section Change
      </div>
      <div class="row card-body mt-3">

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Day">Group</label>
          <input *ngIf="!isAdmin" type="text" class="form-control"
            value='{{ ((this.groups.length>0) ? groups[0].nm:" ")}}' name="grp" id="grp" readonly>
          <select *ngIf="isAdmin" #grp class="form-control" name="grp" id="grp" (change)="OnGrpChange(grp)" ngModel
            required>
            <option *ngFor="let grp of groups" value="{{grp.id}}">{{grp.nm}}</option>
          </select>
        </div>
        <!-- <div class="col-lg-4 col-md-6 form-group" >
          <label for="Department">Department</label>
          <select #department (change)="OnDeptChange(department)" class="form-control"
            name="d_id" id="Department" ngModel>
            <option *ngFor="let d of departments" value="{{d.id}}">{{d.nm}}</option>
          </select>
        </div> -->

        <div class="col-lg-4 col-md-6 form-group">
          <label for="C_Code">Class</label>
          <select #cource (change)="OnClassChange(cource)"  class="form-control " name="c_code"
            id="C_Code" ngModel>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="Major">Major</label>
          <select class="form-control"  #major (change)="OnMajorChange(major)" name="maj_id"
            id="Major" ngModel>
            <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="Session">Session</label>
          <select class="form-control "  #session (change)="OnSessionChange(session)"
            name="se_id" id="Session" ngModel>
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="class" class="text-left">Year</label>
          <input class="form-control" name="year_in" id="year_endTime" #input ngModel
            required>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Session">Semester</label>
          <select #semester class="form-control "  #session
            (change)="OnSemesterChange(semester)" name="t_no" id="Semester" ngModel>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="rn">Roll No</label>
          <select class="form-control " #StudentRollNumber (change)="OnRollNoChange(f)"
            name="rn" id="rn" ngModel>
            <option *ngFor="let r of RollNo" value="{{r.studentRN}}">{{r.studentRN}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="name">Name</label>
          <input class="form-control" name="name" id="name" value={{Sname[0]?.StudentName}} readonly>
        </div>


        

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Subject">Subject</label>
          <select #subject class="form-control" (change)="OnSubjectChange(subject)"
            name="sub_code" id="Subject" ngModel>
            <option *ngFor="let sub of subjects" value="{{sub.subCode}}">{{sub.subCode}} {{sub.subName}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="class" class="text-left">New Section</label>
          <input class="form-control"  name="in" id="endTime" style="text-transform: uppercase;" #input ngModel required>
        </div>



        <div>
          <button [disabled]="!f.valid" (click)="inserting(f)" type="button" class="btn btn-sm btn-primary mt-4"
            >Save</button>
        </div>

      </div>
    </div>

  </div>
</form>