import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models';
import { baseUrl } from './../../../axios';
import { RolesService } from './Roles.service';
import { TimetableService } from './../../main/shared/services/Timetable.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient,
    private _user: RolesService,
    private timetableService: TimetableService) {
    this.currentUserSubject = new BehaviorSubject<User>(this._user.getUser());

    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getGroup() {
    return this._user.credentials.roles.group;
  }

  resetPwDefault(param) {
    return this.http.post(`${baseUrl}/api/auth/resetPwDefault`, param);
  }


  changePassword(params) {
    return this.http.post(`${baseUrl}/api/auth/updatePassword`, params);
  }

  forgetPassword(params) {
    return this.http.post(`${baseUrl}/api/auth/forgetPassword`, params);
  }

  login(username: string, password: string) {
    return this.http.post(`${baseUrl}/api/auth/login`, { usnm: username, pwd: password }).pipe(map( user => {
      if (user != null && user != undefined) {
        localStorage.setItem('gcuid', JSON.stringify(user[1]));
        this._user.setRole(user[0].ROLE);
        if (user[0].ROLE === 'FMR') {
          this._user.loadRole(user[2], user[3]);
          this._user.setDepartment(user[0].D_ID);
        }
        else if (user[0].ROLE === 'STDR') {
          this.timetableService.loadTimetable(user[2]);
        }
        this.currentUserSubject.next(user[0]);
      }
      return user;
    }));
  }

  getUser() {
    return this._user.getUser();
  }

  logout() {
    const sessionToken = localStorage.getItem('gcuid');
    if (sessionToken) {
      localStorage.removeItem('gcuid');
      localStorage.removeItem('navAction');
      this._user.destroy();
      this.currentUserSubject.next(null);
      location.reload();
    } 
    else {
      // console.log('No active session, skipping logout.');
    }
  }

  loggedIn(): boolean {
    return (this._user.credentials.role === "FMR");
  }

  isStudent() {
    return this._user.credentials.role === "STDR";
  }


  isAdmin(): boolean {
    return this._user.credentials.roles.isAdmin;
  }

  superAdmin(): boolean {
    return this._user.credentials.roles.superAdmin;
  }

  seeStudentDetail():boolean{
    return this._user.credentials.roles.stdDetail;
  }

  isTeacher(): boolean {
    return this._user.credentials.roles.isTeacher;
  }

  canAccessStudentProfile(): boolean {
    return this._user.credentials.roles.canViewStudentProfile;
  }

  canAllocateSection(): boolean {
    return this._user.credentials.roles.canAllocateSection;
  }

  canManageTimetable(): boolean {
    return this._user.credentials.roles.canManageTimetable;
  }

  canEnrollRepeater(): boolean {
    return this._user.credentials.roles.canEnrollRepeater;
  }

  isExamCell(): boolean {
    return this._user.credentials.roles.isExamCell;
  }

  canAddSubject(): boolean {
    return this._user.credentials.roles.canAddSubject;
  }

  canEnroll(): boolean {
    return this._user.credentials.roles.canEnroll;
  }

  canManageAwardList(): boolean{
    // return (this.timetableService.getDraftClasses()?.length > 0);

    // for 2nd sem only to fullfill requirment of letter
    return true;
  }

  // Adding
  canReadmission(): boolean{
    return this._user.credentials.roles.canReadmission;
  }

  canIDC(): boolean{
    return this._user.credentials.roles.canIDC;
  }

  canFeeStructure(): boolean{
    return this._user.credentials.roles.canFeeStructure;
  }

  isFinancialAidOffice(): boolean{
    return this._user.credentials.roles.financialOffice;
  }

  isEndownmentFund(): boolean{
    return this._user.credentials.roles.endownmentFund;
  }

  isQEC(): boolean{
    return this._user.credentials.roles.isQEC;
  }

  isHod(): boolean{
    return this._user.credentials.roles.isHod;
  }

  isDashboard(): boolean{
    return this._user.credentials.roles.isDashboard;
  }
}
