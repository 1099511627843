import { Component, OnInit, ViewChild } from '@angular/core';
import { NgControl, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { UtilService } from 'src/app/Util.service';
import { SubjectModel } from '../insert-time-table/models/Subject.model';
import { SubjectRequestService } from './../shared/services/SubjectRequest.service';
import { SubjectOfferService } from './../shared/services/SubjectOffer.service';
import { GroupService } from './../shared/services/Groups.service';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { SessionService } from './../shared/services/Sessions.service';
import { DepartmentService } from './../shared/services/Department.service';
import { MajorService } from './../shared/services/Major.service';
import { DatePipe } from '@angular/common';
import { EnrollmentService } from '../shared/services/Enrollment.service';
import 'jspdf-autotable';
import { StudentdetailService } from '../shared/services/studentdetail.service';

@Component({
  selector: 'app-inter-marks-view',
  templateUrl: './inter-marks-view.component.html',
  styleUrls: ['./inter-marks-view.component.css']
})
export class InterMarksViewComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;
  SharedInfo: Array<{rolno:string}>
  class: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  subject: Array<SelectList>;
  c_code: string;
  maj_id:string;
  se_id: string;
  c_nm: string;
  heading: string;
  sub_comb: any[];
  updateMajors: any;
  classes: any;
  student_table:Array<any>;
  stdDet:boolean=false;
  rolno:any
  c_code_in:any
  t_no: Number;

  constructor(private authenticationService: AuthenticationService,
    private utilService: UtilService,
    private toastr: ToastrService,
    private subOffer: SubjectOfferService,    
    private datePipe: DatePipe,
    // private subReqService: SubjectRequestService,
    private groupService:GroupService,
    private classService:ClassService,
    private sessionService:SessionService,
    // private StudentdetailService:StudentdetailService,
    private EnrollmentService:EnrollmentService,
    private departmentService:DepartmentService,
    private majorService:MajorService,
    private studentdetailservice:StudentdetailService) {
      this.class = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.student_table=[];
    this.subject = new Array<SelectList>();
     }

  ngOnInit(): void {

    this.classService.getAllClasses().subscribe((data: any) => {
      for (var i = 0; i < data.length; i++) {
        this.class.push({ id: data[i].c_code, nm: data[i].Des })
      }
    });  
  }

  getMajors(){
    this.majors = [];
    this.majorService.getMajor({c_code: this.formRef.value.c_code}).subscribe((res:{MAJ_ID:number,MAJ_NM:string}[]) => {
      res?.forEach(entry=>{
        this.majors.push({id: entry.MAJ_ID, nm: entry.MAJ_NM});
      });
    });

    this.getSessions();
    
    
  }
  getSessions()
  {
    this.sessions = [];
    this.sessionService.getSessions(this.formRef.value.c_code).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(entry => {
        this.sessions.push({ id: entry.SE_ID, nm: entry.SE_NM });
      });
    });
    this.getSubject();
  }

  getSubject()
  {
    this.subject = [];
    this.studentdetailservice.getsubject(this.formRef.value.c_code,this.formRef.value.se_id,this.formRef.value.t_no).subscribe((res: { sub_code:number ,sub_nm:string }[]) => {
      res?.forEach(entry => {
        this.subject.push({ id: entry.sub_code, nm: entry.sub_nm });
      });
    });
  }

}
