<form (ngSubmit)="OnSubmit()" #f="ngForm">
  <div class=" container-fluid" [@SlideInFromLeft]>
    <div class="row card shadow">
      <div class="card-header">
        <div>IDC Section Allocation</div>
      </div>
      <div class="row card-body pb-0">

        <div class="col-lg-4 col-md-6 form-group">
          <label for="C_Code">Class</label>
          <select #cource (change)="OnClassChange(cource)" class="form-control "
            name="c_code" id="C_Code" required ngModel>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Day">Group</label>
          <select #grp class="form-control" name="grp" id="grp"
            (change)="OnGrpChange(grp)" ngModel
            required>
            <option *ngFor="let grp of groups"
              value="{{grp.id}}">{{grp.nm}}</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="Session">Session</label>
          <select class="form-control " #session
            (change)="OnSessionChange(session)" name="se_id" id="Session"
            required ngModel>
            <option *ngFor="let s of sessions"
              value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="semester">Semester</label>
          <select #semester class="form-control" name="t_no" id="Semester"
            required ngModel>
            <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]"
              value="{{i}}">{{i}}</option>
          </select>
        </div>
        <div class="row card-body" style="margin-top: -10px;">
          <button (click)="fetchRecord()"
            class="btn btn-primary">
            Fetch Data
          </button>
          <!-- <button (click)="create_csv(major)" [disabled]="!(this.students?.length>0)"
              class="btn ml-2  btn-outline-primary">
              Create Excel File
            </button> -->
          <button (click)="Clear(f)" class="btn  btn-warning ml-2">
            Clear
          </button>
        </div>
      </div>
      <loader *ngIf="loader"></loader>
      <div class="card-body mb-3 list-group" style="min-height: 10rem;"
        *ngIf="students.length > 0">
        <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">
          <input class="form-control col-lg-4" type="text" name="Search"
            placeholder="Search" id="myInput" (keydown)="Filter()">
        </div>
        <div class="d-flex justify-content-between mb-1">
          <table class="mb-0 table table-bordered table-striped" id="table">
            <thead class="thead-dark">
              <tr>
                <th style="width: 7vw;">Sr No</th>
                <th style="width: 20vw;">Roll No</th>
                <th style="width: 20vw;">Name</th>
                <th style="width: 20vw;">Current Section</th>
                <th style="width: 25vw;" *ngIf="section?.length>0">Update Section</th>
              </tr>
            </thead>
            <tbody>
              <!-- (click)="UpdateClassSection(section)" -->
              <!-- <td *ngFor="let sec of section">
                  <div #section class="section" style="background-color: yellow;"  title="Allocate Section to Whole Class!">
                    <b>{{sec.SUB_CODE}}</b>-[{{sec.SECTION}}]
                  </div>
                </td> -->
              <tr *ngFor="let s of students; let i=index;" id="values">
                <td>{{i+1}}</td>
                <td>{{s.ROLNO}}</td>
                <td>{{s.NM}}</td>
                <td #curr class="curr" id="new-section"
                  style="background-color:#e3e3e5;"><b>{{s.SUB_CODE}}</b>-[{{s.SECTION}}]</td>
                <td *ngFor="let sec of section" style="padding: 2px;">
                  <div #section class="section"
                    [ngClass]="{'clicked' : s.SECTION===sec.SECTION }" style="background-color: yellow;"
                    (click)="UpdateStudentSection(i, sec.SECTION, sec.SUB_CODE, s.RN, s.YEAR, s.MAJ_ID)">
                    {{sec.SUB_CODE}}-({{sec.SECTION}})</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</form>