import { Component, OnInit } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
@Component({
  selector: 'app-department-reports',
  templateUrl: './department-reports.component.html',
  styleUrls: ['./department-reports.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class DepartmentReportsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
