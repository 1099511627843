<div [@SlideInFromLeft] class="tabber backdrop"
  style="overflow:scroll; overflow-y: scroll; margin-left: 5%; margin-top: 1%;">
  <div
    class="container-fluid mb-1 card shadow alert-box  col-lg-8 ml-2 col-md-12 offset-sm-3 ml-2 mr-2 ml-sm-auto mr-sm-auto">
    <div class="card-header d-flex justify-content-between">
      <div>Student Assessment Details </div>
      <button (click)="OnClose()" class="btn btn-outline-primary btn-sm">Close</button>
    </div>
    <div class="row m-4">
      <span *ngIf="noAssessment">
        <h4 style="color:blue"> No Any Assessment Created. </h4>
      </span>
    </div>
    <div class="card-body pl-4">
      <div *ngIf="assignments?.length>0">
        <b>Assignments</b>
        <div class="mt-1" style="margin-bottom: 20px;">
          <div class="card card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Total Marks</th>
                    <th>Obtain Marks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let gradeType of assignments;">
                    <td>{{gradeType.ASSIGNMENT_TITLE}}</td>
                    <td>{{gradeType.ASS_TOT_MRKS}}</td>
                    <td>{{gradeType.ASS_OBT_MRKS}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="quiz?.length>0">
        <b>Quizes</b>
        <div style="margin-bottom: 20px;">
          <div class="card card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Total Marks</th>
                    <th>Obtain Marks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let gradeType of quiz;">
                    <td>{{gradeType.ASSIGNMENT_TITLE}}</td>
                    <td>{{gradeType.ASS_TOT_MRKS}}</td>
                    <td>{{gradeType.ASS_OBT_MRKS}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="presentation?.length>0">
        <b>Presentations</b>
        <div style="margin-bottom: 20px;">
          <div class="card card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Total Marks</th>
                    <th>Obtain Marks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let gradeType of presentation;">
                    <td>{{gradeType.ASSIGNMENT_TITLE}}</td>
                    <td>{{gradeType.ASS_TOT_MRKS}}</td>
                    <td>{{gradeType.ASS_OBT_MRKS}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="projects?.length>0">
        <b>Project</b>
        <div style="margin-bottom: 20px;">
          <div class="card card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Total Marks</th>
                    <th>Obtain Marks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let gradeType of projects;">
                    <td>{{gradeType.ASSIGNMENT_TITLE}}</td>
                    <td>{{gradeType.ASS_TOT_MRKS}}</td>
                    <td>{{gradeType.ASS_OBT_MRKS}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="mid?.length>0">
        <b> Mid Term</b>
        <div style="margin-bottom: 20px;">
          <div class="card card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Total Marks</th>
                    <th>Obtain Marks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let gradeType of mid;">
                    <td>{{gradeType.ASSIGNMENT_TITLE}}</td>
                    <td>{{gradeType.ASS_TOT_MRKS}}</td>
                    <td>{{gradeType.ASS_OBT_MRKS}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="lab?.length>0">
        <b>Labs</b>
        <div style="margin-bottom: 20px;">
          <div class="card card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Total Marks</th>
                    <th>Obtain Marks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  <tr *ngFor="let gradeType of lab;">
                    <td>{{gradeType.ASSIGNMENT_TITLE}}</td>
                    <td>{{gradeType.ASS_TOT_MRKS}}</td>
                    <td>{{gradeType.ASS_OBT_MRKS}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="final?.length>0">
        <b>Final-Term</b>
        <div style="margin-bottom: 20px;">
          <div class="card card-body">
            <div class="table-responsive">
              <table class="table table-bordered table-hover table-striped">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Total Marks</th>
                    <th>Obtain Marks</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let gradeType of final;">
                    <td>{{gradeType.ASSIGNMENT_TITLE}}</td>
                    <td>{{gradeType.ASS_TOT_MRKS}}</td>
                    <td>{{gradeType.ASS_OBT_MRKS}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>