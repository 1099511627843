import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { StudentService } from '../shared/services/Student.service';
import { SessionService } from './../shared/services/Sessions.service';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { FeeService } from '../shared/services/Fee.service'; // added by mehtab on dated 25-03-2024
import { IntermediateService } from '../shared/services/intermediate.service';

interface stdInfo {
  nm: string,
  nic: string,
  year: number,
  c_code: number,
  class: string,
  d_id: number,
  deptt: string,
  set_no: number,
  maj_id: number,
  major: string,
  rn: number,
  se_id: number,
  session: string,
  f_nm: string,
  email: string,
  add1: string,
  ph1: string,
  ph2: string,
  gender: string,
  relig: string,
  DOB: string,
  add2: string,
  BRD_RN: string,
  reg_no: string,
  BRD_RN_2: string
}

@Component({
  selector: 'app-detailedstudent',
  templateUrl: './detailedstudent.component.html',
  styleUrls: ['./detailedstudent.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class DetailedstudentComponent implements OnInit {
  canManageTT: boolean = false;
  isExamCell: boolean = false;
  canStudentDetail: boolean = false;
  onDepartmentAccess: boolean = false;
  canEnrollRepeater: boolean = false;
  isAdmin: boolean = false;
  dept: number = 0;
  group: number = 0;
  remarks: string = '';
  sessions: Array<SelectList>;
  classes: Array<SelectList>;
  @ViewChild('f') formRef: NgForm;
  @ViewChild('fn') stddet: NgForm;
  @Input() data: { rolno: string; c_code: string; se_id: string; t_no: string, stdt: boolean };
  rolnoArr: Array<SelectList>;
  @Input() c_code_in: string;
  @Input() rolno: string;
  @Input() se_id_in: string;
  @Input() t_no_in: string;
  @Input() stdt: boolean;
  @Input() type = "";
  paidChallan: any; // added by mehtab on dated 25-03-2024
  unpaidChallans: any; // added by mehtab on dated 25-03-2024
  schpFeeConcDuesAdj: any; // added by mehtab on dated 25-03-2024
  outStndDues: any; // added by mehtab on dated 25-03-2024
  feeRefund: any; // added by mehtab on dated 25-03-2024
  oneTime: any; // added by mehtab on dated 25-03-2024
  f_no = -1; // added by mehtab on dated 25-03-2024
  ch_no = -1; // added by mehtab on dated 25-03-2024
  details =
    `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>in this screen you can check all the  details including fee,personal ,academic and the examination detail of any student of 
  any major in its  session and class </h6>
  <h6 class="text-right"> اس اسکرین میں آپ کسی بھی میجر کے کسی بھی طالب علم کی تمام تفصیلات، جن میں فیس، ذاتی معلومات، تعلیمی اور امتحانی تفصیلات شامل ہیں، ان کے سیشن اور کلاس کے مطابق چیک کر سکتے ہیں۔</h6>
  `  ;
  loaded: boolean;
  valid: boolean;
  constructor(
    private authenticationService: AuthenticationService,
    private sessionService: SessionService,
    private studentService: StudentService,
    private toastr: ToastrService,
    private classService: ClassService,
    private feeService: FeeService, // added by mehtab on dated 25-03-2024
    private interservice: IntermediateService,
  ) {
    this.dept = this.authenticationService.getUser().D_ID;
    this.canManageTT = this.authenticationService.canManageTimetable();
    this.isAdmin = this.authenticationService.isAdmin();
    this.isExamCell = this.authenticationService.isExamCell();
    this.group = this.authenticationService.getGroup();
    this.canStudentDetail = this.authenticationService.seeStudentDetail();
    this.canEnrollRepeater = authenticationService.canEnrollRepeater();
    this.sessions = new Array<SelectList>();
    this.classes = new Array<SelectList>();
    this.rolnoArr = new Array<SelectList>();

    this.paidChallan = []; // added by mehtab on dated 25-03-2024
    this.unpaidChallans = []; // added by mehtab on dated 25-03-2024
    this.schpFeeConcDuesAdj = []; // added by mehtab on dated 25-03-2024
    this.outStndDues = []; // added by mehtab on dated 25-03-2024
    this.feeRefund = []; // added by mehtab on dated 25-03-2024
    this.oneTime = []; // added by mehtab on dated 25-03-2024

  }

  showpersonaldetails: boolean
  showsubjectoffer: boolean
  showenrollment: boolean
  showattendance: boolean
  showawardlist: boolean
  showexamresult: boolean
  showexamsummary: boolean

  Rshowenrollment: boolean
  Rshowattendance: boolean
  Rshowawardlist: boolean
  Rshowexamresult: boolean
  Rshowexamsummary: boolean
  showbuttonspallete: boolean
  showfeedetails: boolean //added by mehtab on dated 25-03-2024..
  loader = false;

  ngOnInit(): void {
    this.stdt = true;
    this.loader = false
    this.showpersonaldetails = false
    this.showsubjectoffer = false
    this.showenrollment = false
    this.showattendance = false
    this.showawardlist = false
    this.showexamresult = false
    this.showexamsummary = false

    this.Rshowenrollment = false
    this.Rshowattendance = false
    this.Rshowawardlist = false
    this.Rshowexamresult = false
    this.Rshowexamsummary = false
    this.showbuttonspallete = false
    this.showfeedetails = false; //added by mehtab on dated 25-03-2024..

    this.onDepartmentAccess = this.canManageTT && !this.isAdmin && !this.canStudentDetail;
    if (this.onDepartmentAccess) {
      this.loadClassess(this.group.toString());
    } else if (this.isExamCell) {
      this.loadClassess('-1');
      this.group = -1;
    }
    else if (this.canStudentDetail) {
      this.loadClassess('-1');
      this.group = -1;
    }

    if(this.type == "popup") this.valid = true;
    else this.valid = false;
  }

  Onshowpersonaldetailsclick() {
    this.showpersonaldetails = true
    this.showsubjectoffer = false
    this.showenrollment = false
    this.showattendance = false
    this.showawardlist = false
    this.showexamresult = false
    this.showexamsummary = false
    this.Rshowenrollment = false
    this.Rshowattendance = false
    this.Rshowawardlist = false
    this.Rshowexamresult = false
    this.Rshowexamsummary = false
    this.showfeedetails = false // added by mehtab on dated 25-03-2024
  }

  Onshowsubjectofferclick() {
    this.showpersonaldetails = false
    this.showsubjectoffer = true
    this.showenrollment = false
    this.showattendance = false
    this.showawardlist = false
    this.showexamresult = false
    this.showexamsummary = false
    this.Rshowenrollment = false
    this.Rshowattendance = false
    this.Rshowawardlist = false
    this.Rshowexamresult = false
    this.Rshowexamsummary = false
    this.showfeedetails = false // added by mehtab on dated 25-03-2024
  }

  Onshowenrollmentclick() {
    this.showpersonaldetails = false
    this.showsubjectoffer = false
    this.showenrollment = true
    this.showattendance = false
    this.showawardlist = false
    this.showexamresult = false
    this.showexamsummary = false
    this.Rshowenrollment = false
    this.Rshowattendance = false
    this.Rshowawardlist = false
    this.Rshowexamresult = false
    this.Rshowexamsummary = false
    this.showfeedetails = false // added by mehtab on dated 25-03-2024
  }

  Onshowattendanceclick() {
    this.showpersonaldetails = false
    this.showsubjectoffer = false
    this.showenrollment = false
    this.showattendance = true
    this.showawardlist = false
    this.showexamresult = false
    this.showexamsummary = false
    this.Rshowenrollment = false
    this.Rshowattendance = false
    this.Rshowawardlist = false
    this.Rshowexamresult = false
    this.Rshowexamsummary = false
    this.showfeedetails = false // added by mehtab on dated 25-03-2024
  }

  Onshowawardlistclick() {
    this.showpersonaldetails = false
    this.showsubjectoffer = false
    this.showenrollment = false
    this.showattendance = false
    this.showawardlist = true
    this.showexamresult = false
    this.showexamsummary = false
    this.Rshowenrollment = false
    this.Rshowattendance = false
    this.Rshowawardlist = false
    this.Rshowexamresult = false
    this.Rshowexamsummary = false
    this.showfeedetails = false // added by mehtab on dated 25-03-2024
  }

  Onshowexamresultclick() {
    this.showpersonaldetails = false
    this.showsubjectoffer = false
    this.showenrollment = false
    this.showattendance = false
    this.showawardlist = false
    this.showexamresult = true
    this.showexamsummary = false
    this.Rshowenrollment = false
    this.Rshowattendance = false
    this.Rshowawardlist = false
    this.Rshowexamresult = false
    this.Rshowexamsummary = false
    this.showfeedetails = false // added by mehtab on dated 25-03-2024
  }

  Onshowexamsummaryclick() {
    this.showpersonaldetails = false
    this.showsubjectoffer = false
    this.showenrollment = false
    this.showattendance = false
    this.showawardlist = false
    this.showexamresult = false
    this.showexamsummary = true

    this.Rshowenrollment = false
    this.Rshowattendance = false
    this.Rshowawardlist = false
    this.Rshowexamresult = false
    this.Rshowexamsummary = false
    this.showfeedetails = false // added by mehtab on dated 25-03-2024
  }

  Onshowrepeaterdataclick() {
    this.showpersonaldetails = false
    this.showsubjectoffer = false
    this.showenrollment = false
    this.showattendance = false
    this.showawardlist = false
    this.showexamresult = false
    this.showexamsummary = false
    this.Rshowenrollment = true
    this.Rshowattendance = true
    this.Rshowawardlist = true
    this.Rshowexamresult = true
    this.Rshowexamsummary = true
    this.showfeedetails = false // added by mehtab on dated 25-03-2024
  }

  OnshowFeeDetailsclick() {
    this.showpersonaldetails = false
    this.showsubjectoffer = false
    this.showenrollment = false
    this.showattendance = false
    this.showawardlist = false
    this.showexamresult = false
    this.showexamsummary = false
    this.Rshowenrollment = false
    this.Rshowattendance = false
    this.Rshowawardlist = false
    this.Rshowexamresult = false
    this.Rshowexamsummary = false
    this.showfeedetails = true // added by mehtab on dated 25-03-2024
  }

  loadClassess(grp: string) {
    let param = {};
    param['grp'] = grp;

    this.classService.getClasses(param).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      });
      if(this.type == "popup") {
        this.formRef.controls["c_code"].setValue(this.data.c_code);
        this.OnClassChange();
      }
    });
  }

  OnClassChange() {
    this.rolnoArr = [];
    this.updateSessions();
  }

  updateSessions() {
    this.sessions = [];
    this.rolnoArr = [];
    this.sessionService.getSessions(this.formRef.value.c_code).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(entry => {
        this.sessions.push({ id: entry.SE_ID, nm: entry.SE_NM });
      });
      if(this.type == "popup") {
        this.formRef.controls["se_id"].setValue(this.data.se_id);
        this.formRef.controls["t_no"].setValue(this.data.t_no);
        this.loadRollno();
      }
    });
  }

  loadRollno() {
    this.rolnoArr = [];
    let param = {};
    if (!this.formRef.value?.se_id && this.type != "popup") {
      return;
    }
    if(this.type == "popup") param = { c_code: this.data.c_code, se_id: this.data.se_id }
    else param = { c_code: this.formRef.value?.c_code, se_id: this.formRef.value?.se_id }
    this.studentService.getStudentsRollNumbersForDetail(param).subscribe((
      res: { RN: number, ROLNO: string }[]) => {
      res?.forEach(element => {
        this.rolnoArr.push({ id: element.RN, nm: element.ROLNO });
      });
      if(this.type == "popup") {
        this.formRef.controls["rolno"].setValue(this.data.rolno);
        this.valid = false;
      }
    });
  }
  studentadm_stdt: Array<stdInfo>
  sub_offered: any
  enrollment: any
  attendance: any
  award_lst: any
  ex_rslt: any
  ex_sum: any
  status: any

  //REPEATER DATA
  Renrollment: any
  Rattendance: any
  Raward_lst: any
  Rex_rslt: any
  Rex_sum: any

  getDetailedStudentData() {
    this.loader = true;
    const { c_code, se_id, rolno, t_no } = (this.type == "popup" ? this.data : this.formRef.value);
    this.studentService.getDetailedStudent(c_code, se_id, rolno, t_no).subscribe(res => {
      this.remarks = res[0][0].remarks;
      this.sub_offered = res[1]
      this.enrollment = res[2]
      this.attendance = res[3]
      this.award_lst = res[4]
      this.ex_rslt = res[5]
      this.ex_sum = res[6]
      this.Renrollment = res[7]
      this.Rattendance = res[8]
      this.Raward_lst = res[9]
      this.Rex_rslt = res[10]
      this.Rex_sum = res[11]
      this.status = res[12];
      this.loader = false;
      this.showpersonaldetails = true;
      this.showbuttonspallete = true;
      this.showsubjectoffer = false
      this.showenrollment = false
      this.showattendance = false
      this.showawardlist = false
      this.showexamresult = false
      this.showexamsummary = false
      this.Rshowenrollment = false
      this.Rshowattendance = false
      this.Rshowawardlist = false
      this.Rshowexamresult = false
      this.Rshowexamsummary = false
      this.showfeedetails = false
    },
      err => {
        this.loader = false;
        this.toastr.error("Unknown Error")
      }
    )
  }

  getUser() {
    this.studentadm_stdt = [];
    if (this.formRef.value.rolno.includes('-')) {
      this.studentService.getStudentInfoDetail(this.type == "popup" ? this.data.rolno : this.formRef.value.rolno).subscribe((res: stdInfo) => {
        if (!res) {
          this.toastr.info('Student Details not found.');
          return;
        }

        this.studentadm_stdt.push(res);
      });
    }
    else {
      this.toastr.warning('Roll Number Should be Correct.');
    }
  }

  // added by mehtab on dated 25-03-2024
  getFeeDetails(rolno: HTMLSelectElement, f_no: HTMLSelectElement, ch_no: HTMLSelectElement) {
    // this.admissionDetail = [];
    this.loader = true;
    if ((!rolno.value) && (!f_no.value) && (!ch_no.value)) {
      this.toastr.warning("Enter at least one option");
      return;
    }

    this.showpersonaldetails = false
    this.showsubjectoffer = false
    this.showenrollment = false
    this.showattendance = false
    this.showawardlist = false
    this.showexamresult = false
    this.showexamsummary = false
    this.Rshowenrollment = false
    this.Rshowattendance = false
    this.Rshowawardlist = false
    this.Rshowexamresult = false
    this.Rshowexamsummary = false
    this.showfeedetails = true

    this.paidChallan = []
    this.unpaidChallans = [];
    this.schpFeeConcDuesAdj = [];
    this.outStndDues = [];
    this.feeRefund = [];
    this.oneTime = [1]
    this.feeService?.getFeeDetails(rolno.value, -1, -1).subscribe(
      res => {
        res[0]?.forEach(element => {
          this.paidChallan.push(element)
        })
        res[1]?.forEach(element => {
          this.paidChallan.push(element)
        })
        res[2]?.forEach(e => {
          this.unpaidChallans.push(e)
        })
        res[3]?.forEach(e => {
          this.unpaidChallans.push(e)
        })
        res[4]?.forEach(e => {
          this.schpFeeConcDuesAdj.push(e)
        })
        res[5]?.forEach(e => {
          this.outStndDues.push(e)
        })
        res[6]?.forEach(e => {
          this.feeRefund.push(e)
        })
        this.loader = false;
      },
      (error) => {
        console.error('Error occurred:', error);
        // Handle errors in a more detailed way if needed
      }
    );
    // added by mehtab on dated 25-03-2024
  }

  onSubmit(name, fName, cnic, email, add1, ph1, ph2, gender, religion, dob, add2) {
    let obj = this.formRef.value;

    this.studentService.updateStudentDetailADM({
      c_code: obj?.c_code, se_id: obj?.se_id, rollNo: obj?.rolno, name: name.value,
      fatherName: fName.value, cnic: cnic.value, email: email.value, add1: add1.value, ph1: ph1.value, ph2: ph2.value,
      gender: gender.value, religion: religion.value, dob: dob.value, add2: add2.value
    }).subscribe((res: { affectedRows: number }) => {
      if (res?.affectedRows != 0) {
        this.toastr.success('Updated Information Successfullly.');
      }
      else {
        this.toastr.info('No Change in any Field.');
      }
    });
    this.studentService.updateStudentDetailADMSFC({
      c_code: obj?.c_code, se_id: obj?.se_id, rollNo: obj?.rolno, name: name.value, fatherName: fName.value, cnic: cnic.value,
      email: email.value, add1: add1.value, ph1: ph1.value, ph2: ph2.value, gender: gender.value, religion: religion.value,
      add2: add2.value
    }).subscribe((res: any) => {
      this.toastr.success(res.msg)
    });
  }
  
  onSubmitFee(name, fName, cnic, email, add1, ph1, ph2, gender, religion, dob, add2) {
    let obj = this.formRef.value;

    this.studentService.updateStudentDetailADMFee({
      c_code: obj?.c_code, se_id: obj?.se_id, rollNo: obj?.rolno, name: name.value,
      fatherName: fName.value, cnic: cnic.value, email: email.value, add1: add1.value, ph1: ph1.value, ph2: ph2.value,
      gender: gender.value, religion: religion.value, dob: dob.value, add2: add2.value
    }).subscribe((res: { affectedRows: number }) => {
      if (res?.affectedRows != 0) {
        this.toastr.success('Updated Information Successfullly.');
      }
      else {
        this.toastr.info('No Change in any Field.');
      }
    });
    this.studentService.updateStudentDetailADMSFC({
      c_code: obj?.c_code, se_id: obj?.se_id, rollNo: obj?.rolno, name: name.value, fatherName: fName.value, cnic: cnic.value,
      email: email.value, add1: add1.value, ph1: ph1.value, ph2: ph2.value, gender: gender.value, religion: religion.value,
      add2: add2.value
    }).subscribe((res: any) => {
      this.toastr.success(res.msg)
    });
  }

  OnBoardUpdate(marks: any) {
    this.studentadm_stdt[0].BRD_RN = marks;
  }

  OnBoard2Update(marks: any) {
    this.studentadm_stdt[0].BRD_RN_2 = marks;
  }

  OnRegUpdate(marks: any) {
    this.studentadm_stdt[0].reg_no = marks;
  }

  UpdateStudentDetail() {
    for (const item of this.studentadm_stdt) {
      this.interservice.UpdateStudentDetail(this.studentadm_stdt[0]?.year, this.studentadm_stdt[0]?.rn, item.BRD_RN, item.reg_no, this.studentadm_stdt[0]?.BRD_RN_2).subscribe((res: any) => {
        // this.toastr.success("Detail Upgraded");
      })
    }
  }
}