<form (ngSubmit)="OnSubmit()" #f="ngForm">
  <div class=" container-fluid" [@SlideInFromLeft]>
    <div class="row card shadow">
      <div class="card-header">Date Extension Report</div>
      <div class="row card-body pb-0 px-4">
        <div class="col-lg-4 col-md-6 form-group">
          <label for="c_code">Class</label>
          <select #c_code class="form-control " name="c_code" id="c_code" ngModel (change)="ClearClassBase()">
            <option *ngFor="let c of classDT" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="year">Year</label>
          <select class="form-control" id="year" aria-label="Year" ngModel
            required name="year"
            (change)="ClearYearBase()">
            <option *ngFor="let s of sessions" value="{{s}}">{{s}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="inst_no">Installment</label>
          <!-- <input type="number" class="form-control" name="inst_no" min="0" max="8" ngModel> -->
          <select #installment class="form-control "name="inst_no"
             ngModel="selectedInstNo">
             <option *ngFor="let entry of instNo" [value]="entry">{{ entry }}</option>
             </select>
        </div>
      </div>

      <div class="row card-body">
        <button class="btn btn-primary ml-1" (click)="getData()" [disabled]="!f.valid">
          Get Report
        </button>
        <button *ngIf="this.allData.length>0" class="btn ml-3 btn-outline-danger" (click)="onPdfDownload(c_code)">
          <i class="fa fa-file-pdf-o" for=""></i>
          Download Report Pdf
        </button>
        <button class="btn ml-3 btn-warning" (click)="onClear()">
          Clear
        </button>
      </div>
      <div class="px-4">
      <table class="table table-bordered table-striped mt-4" id="tableID" *ngIf="allData.length>0">
        <thead class="thead-dark">
          <th>Sr.#</th>
          <th>Challan #</th>
          <th>Roll #/ Form #</th>
          <th>Name</th>
          <th>Date Extension</th>
          <th>Description</th>
        </thead>
        <tbody>
        <tr *ngFor="let i of this.allData; let j=index">
          <td>{{j+1}}</td>
          <td>{{i.rolno}}</td>
          <td>{{i.ch_no}}</td>
          <td>{{i.name}}</td>
          <td>{{i.dt_extension}}</td>
          <td>{{i.ext_des}}</td>
        </tr>
      </tbody>
      </table>
    </div>
    </div>
  </div>
</form>