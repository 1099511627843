import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../shared/model/SelectList.model';
import { RoomService } from '../shared/services/Room.service';
import { BlockService } from './../shared/services/Block.service';
import { ToastrService } from 'ngx-toastr';
import { TimetableRoomModel2 } from './timeTableRoomModel';

@Component({
  selector: 'app-room-available',
  templateUrl: './room-available.component.html',
  styleUrls: ['./room-available.component.css'],
  animations:[
    SlideInFromLeft()
  ]
})
export class RoomAvailableComponent implements OnInit {
   @Output() close:EventEmitter<void> =   new EventEmitter();
  blocks: Array<SelectList>;
  rooms: Array<SelectList>;
  @ViewChild('f') formRef: NgForm;
  timetableEntries: Map<number, TimetableRoomModel2>;
  temp: TimetableRoomModel2[];
  array;
  hideInfo:boolean=false;

  constructor(private blockService:BlockService,private roomService:RoomService,private toaster: ToastrService,) {
    this.blocks = new Array<SelectList>();
    this.rooms = new Array<SelectList>();
    this.timetableEntries=new Map<number, TimetableRoomModel2>();
  }

  ngOnInit(): void {
    this.blockService.getBlocks().subscribe((r: { BLK_ID: number, BLK_NM: string }[]) => {
      r?.forEach(e => {
        this.blocks.push({ id: e.BLK_ID, nm: e.BLK_NM });
      });
    });
  }
  OnClose(){
   this.close.emit();
  }
  OnBuildingChange(blk: HTMLSelectElement) {
    this.updateRoom();
  }
  updateRoom() {
    this.rooms = [];
    this.roomService.getRoomsByBlock(this.formRef.value.blk_id).subscribe((r:{RM_ID:number, RM_NM:string}[]) => {
      r?.forEach(e=>{
        this.rooms.push({id: e.RM_ID, nm: e.RM_NM});
      });
    });

  }
  onSubmit(){
    if(this.formRef.value.blk_id==''){
      this.toaster.warning('Block Must be Select');
      return;
    }
    if(this.formRef.value.rm_id=='' && this.formRef.value.day !='')
    {
      this.array=[];
      this.toaster.warning('Room Should be Select.');
      this.hideInfo=false;
      return;
    }
    this.blockService.getBlockRoomDetail(this.formRef.value.blk_id,this.formRef.value.rm_id||0,this.formRef.value.day||'null').subscribe((r: {
      sub_code:string, grp:number, tm_id: number, d_id: number, d_nm: string, c_nm: string, se: number, t_no: number, section: string, subject: string, teacher: string, day: string, start_time: string, end_time: string, blk_id: string, blk_nm: string, rm_id: string, rm_nm: string,status:number
    }[])=>{
      if(r==null || r==undefined||r.length==0){
        this.array=[];
        this.hideInfo=false;
        this.toaster.warning('No Records Found.');
        return;
      }
      this.temp = [];
      this.array=[];
      this.hideInfo=true;
      this.timetableEntries.clear();
        for (const i in r) {
          this.timetableEntries.set(r[i].tm_id, new TimetableRoomModel2(r[i].sub_code, r[i].grp, r[i].tm_id, r[i].d_id, r[i].d_nm, r[i].c_nm, r[i].se, r[i].t_no, r[i].section, r[i].subject, r[i].teacher, r[i].day, r[i].start_time, r[i].end_time, r[i].blk_id, r[i].blk_nm, r[i].rm_id, r[i].rm_nm, r[i].status));
        }
        this.array = Array.from(this.timetableEntries, ([name, value]) => ({ name, value }));
        this.temp = Array.from(this.timetableEntries.values());        
        let x = [];
        x = [...x, ...this.temp.filter(a => a.day == "Monday")];
        x = [...x, ...this.temp.filter(a => a.day == "Tuesday")];
        x = [...x, ...this.temp.filter(a => a.day == "Wednesday")];
        x = [...x, ...this.temp.filter(a => a.day == "Thursday")];
        x = [...x, ...this.temp.filter(a => a.day == "Friday")];
        this.array = x;
      }, err => {
        console.log(err);
      });
  }
}
