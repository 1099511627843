import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FeeService } from '../shared/services/Fee.service';
import * as moment from 'moment';
import { ChalanService } from '../shared/services/chalan.service';
import { ToastrService } from 'ngx-toastr';
import { MajorService } from '../shared/services/Major.service';
import { SlideInFromLeft } from "src/app/transitions";
import { ClassService } from '../shared/services/Class.service';
import { DatePipe } from '@angular/common';
import { EnrollmentService } from '../shared/services/Enrollment.service';
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';
declare let $: any;

@Component({
  selector: 'app-outstand-dues-entry',
  templateUrl: './outstand-dues-entry.component.html',
  styleUrls: ['./outstand-dues-entry.component.css'],
  animations: [SlideInFromLeft()],
})
export class OutstandDuesEntryComponent implements OnInit {
  @ViewChild("f") formRef: NgForm;
  [x: string]: any;
  existedOutStdEntriesArr: Array<any>
  existedOutStdEntriesMap: Map<number, number>
  constructor(
    private toastr: ToastrService,
    private feeService: FeeService,
    private feeSharedService: FeeSharedDataService
  ) {
    this.classes = [];
    this.sessions = []
    this.majors = [];
    this.fineAcc = [];
    this.feeidsNm = [];
    this.rnCheck = 0;
    this.fine_entry_date = null;
    this.manualDes = 0
    this.existedOutStdEntriesArr = []
    this.existedOutStdEntriesMap = new Map<number, number>();
  }

  ngOnInit(): void {
    this.updateClasses();
    this.updateSessions();
    this.loadFineMisc();
    this.getOutstandingEntriesFeeIds();
  }

    updateClasses() {
      this.feeSharedService.getFeeClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
    }

  updateSessions() {
    this.sessions = []
    this.feeSharedService.getFeeSessions()
    .then(sessions => {
      this.sessions = sessions;
    })
    .catch(error => {
      console.error("Error getting fee session", error);
    });
  }

  curdate(): any {
    return moment(new Date()).format('YYYY-MM-DD');
  }

  FineEntryDate(date) {
    if (date == '')
      this.fine_entry_date = null
    else {
      this.fine_entry_date = date;
      this.getOutStdEntries();
    }
  }

  getOutstandingEntriesFeeIds() {
    this.feeService.getOutstandingEntriesFeeIds().subscribe((res: any) => {
      res.forEach(e => {
        this.feeidsNm.push(e)
      })
    })
  }
  
  getStdName(e: any) {
    const { c_code, year, rn, fine_id, amnt, fine_entry_dt, des } = this.formRef.value;
    e.preventDefault();
    if (e.keyCode == 13) {
      if (this.formRef.valid) {
        if (this.fine_entry_date != null) {
          if (this.name != null) {
            if (this.existedOutStdEntriesArr.findIndex(obj => (obj.rn == rn) && (obj.fine_id == fine_id) && (obj.c_code == c_code) && (obj.actyear == year)) != -1) {
              let msg =
                "Do you really want to update Amount & Description for Roll# " + rn + "\n\n Press OK to proceed";
              if (confirm(msg)) {
                this.feeService.insertOutStdEntry(c_code, year, rn, fine_id, amnt, this.fine_entry_date, des).subscribe((res: any) => {
                  this.toastr.success(res[0].msg);
                  this.existedOutStdEntriesArr = this.existedOutStdEntriesArr.filter(obj => !((obj.rn == rn) && (obj.fine_id == fine_id) && (obj.c_code == c_code) && (obj.actyear == year)))
                  this.existedOutStdEntriesArr.push({
                    ch_no: "Not Generated", rn: rn, rolno: this.rolno, nm: this.name,
                    fee: this.feeidsNm[this.feeidsNm.findIndex(obj => obj.fee_id == fine_id)].fee_nm, fine_id: fine_id, amnt: amnt, Des: des, c_code: c_code,
                    actyear: year, session: this.sessionVar, class: this.classes[this.classes.findIndex(obj => obj.id == c_code)].nm
                  })
                  this.existedOutStdEntriesArr.sort((a: any, b: any) => parseInt(a.c_code) - parseInt(b.c_code));
                  this.existedOutStdEntriesArr.sort((a: any, b: any) => parseInt(a.rn) - parseInt(b.rn));
                  this.existedOutStdEntriesArr.sort((a: any, b: any) => parseInt(a.actyear) - parseInt(b.actyear))
                })
              }
            }
            else {
              this.feeService.insertOutStdEntry(c_code, year, rn, fine_id, amnt, this.fine_entry_date, des).subscribe((res: any) => {
                this.toastr.success(res[0].msg);
                this.existedOutStdEntriesArr.push({
                  ch_no: "Not Generated", rn: rn, rolno: this.rolno, nm: this.name,
                  fee: this.feeidsNm[this.feeidsNm.findIndex(obj => obj.fee_id == fine_id)].fee_nm, fine_id: fine_id, amnt: amnt, Des: des, c_code: c_code,
                  actyear: year, session: this.sessionVar, class: this.classes[this.classes.findIndex(obj => obj.id == c_code)].nm
                })

              })
            }

          }
          else
            this.toastr.error("Roll No Does Not Exist")

        }
        else
          this.toastr.warning("Please select an entry date")
      }
      else
        this.toastr.warning("Some Fields are missing")
    }
    else if (e.keyCode != 13) {
      if (this.rnCheck != rn) {
        this.feeService.getStdNameByRn(c_code, year, rn).subscribe((res: any) => {
          this.name = res[0]?.nm
          this.sessionVar = res[0]?.session
          this.rolno = res[0]?.rolno
          this.rnCheck = rn;
        })
      }
    }
  }
  ChangeManualDes(val) {
    this.manualDes = val
  }
  getOutStdEntries() {
    const { c_code, year, fine_entry_date } = this.formRef.value;
    this.existedOutStdEntriesArr = []
    this.feeService.getOutStdEntries(c_code, year, this.fine_entry_date).subscribe((res: any) => {
      res.forEach(e => {
        this.existedOutStdEntriesArr.push(e);
      })
    }, err => {
      console.log("Error");
    })
  }
  SearchTable() {
    $(document).ready(function () {
      $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $("#myTable tr").filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
      });
    });
  }
  DeleteStdEntry(obj, val) {

    const { year } = this.formRef.value;
    let msg =
      "Do you really want to Delete Entry for Roll# " + obj.rolno + "\n\n Press OK to proceed";
    if (confirm(msg)) {
      this.feeService.deleteOutStdEntry(obj.c_code, obj.rn, obj.fine_id, obj.actyear, this.fine_entry_date).subscribe((res: any) => {
        this.toastr.info(res[0].msg);
        if (res[0].status == 1)
          this.existedOutStdEntriesArr = this.existedOutStdEntriesArr.filter(val => !((val.rn == obj.rn) && (val.fine_id == obj.fine_id) && (val.c_code == obj.c_code) && (val.actyear == obj.actyear)))
      })
    }
  }
  loadFineMisc() {
    this.feeAcc = [];
    this.feeService.getFineDescription().subscribe((res: { fine_id: string, fine_nm: string }[]) => {
      if (!res) {
        this.toastr.error("Unknown Error");
      }
      else if (res) {
        res?.forEach(element => {

          this.fineAcc.push({ id: parseInt(element.fine_id), nm: element.fine_nm });
        });

      }
    }
      , err => {
        console.log(err);
        this.toastr.error("Some Error Occured!");
      }
    );
  }
  ClearFields() {
    this.name = null;
    this.getOutStdEntries();
  }
}
