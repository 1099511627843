import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FeeService } from '../shared/services/Fee.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from "src/app/transitions";
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';
declare let $: any;

@Component({
  selector: 'app-fee-refund',
  templateUrl: './fee-refund.component.html',
  styleUrls: ['./fee-refund.component.css'],
  animations: [SlideInFromLeft()]
})

export class FeeRefundComponent implements OnInit {
  @ViewChild("f") formRef: NgForm;
  [x: string]: any;
  existedFeeRefundArr: Array<any>
  existedOutStdEntriesMap: Map<number, number>
  session:string
  constructor(
    private toastr: ToastrService,
    private feeService: FeeService,
    private feeSharedService: FeeSharedDataService) {
    this.classes = [];
    this.sessions = []
    this.majors = [];
    this.feeRefundDes = [];
    this.feeidsNm = [];
    this.rnCheck = 0;
    this.fine_entry_date = null;
    this.manualDes = 0
    this.existedFeeRefundArr = []
  }

  ngOnInit(): void {
    this.updateClasses();
    this.updateSessions();
    this.getFeeRefundDes();
    this.getFeeRefundFeeIds();
  }

  updateClasses() {
    this.feeSharedService.getFeeClasses()
    .then(classes => {
      this.classes = classes;
    })
    .catch(error => {
      console.error("Error getting fee classes", error);
    });
  }

  updateSessions() {
    this.sessions = []
    this.feeSharedService.getFeeSessions()
    .then(sessions => {
      this.sessions = sessions;
    })
    .catch(error => {
      console.error("Error getting fee session", error);
    });
  }

  curdate(): any {
    return moment(new Date()).format('YYYY-MM-DD');
  }

  FineEntryDate(date) {
    if (date == '')
      this.fine_entry_date = null
    else {
      this.fine_entry_date = date;
      this.getFeeRefundEntries();
    }
  }

  getFeeRefundFeeIds() {
    this.feeService.getFeeRefundFeeIds().subscribe((res: any) => {
      res.forEach(e => {
        this.feeidsNm.push(e)
      })
    },err=>{
      console.log("Error")
    })
  }
  getStdName(e: any) {
    const { c_code, year, rn, fine_id, amnt, fine_entry_dt, des } = this.formRef.value;
    e.preventDefault();
    if (e.keyCode == 13) {
      if (this.formRef.valid) {
        if (this.fine_entry_date != null) {
          if (this.name != null) {
            if (this.existedFeeRefundArr.findIndex(obj => (obj.rn == rn) && (obj.fine_id == fine_id)&&(obj.c_code==c_code)&&(obj.actyear==year)) != -1) {
              let msg =
                "Do you really want to update Amount & Description for Roll# " + rn + "\n\n Press OK to proceed";
              if (confirm(msg)) {
                this.feeService.insertFeeRefundEntries(c_code, year, rn, fine_id, amnt, this.fine_entry_date, des).subscribe((res: any) => {
                  this.toastr.success(res[0].msg);
                  this.existedFeeRefundArr = this.existedFeeRefundArr.filter(obj => !((obj.rn == rn) && (obj.fine_id == fine_id)&&(obj.c_code==c_code)&&(obj.actyear==year)))
                  this.existedFeeRefundArr.push({
                    ch_no: "Not Generated", rn: rn, rolno: this.rolno, nm: this.name,
                    fee: this.feeidsNm[this.feeidsNm.findIndex(obj => obj.fee_id == fine_id)].fee_nm, fine_id: fine_id, amnt: amnt, Des: des, c_code: c_code,
                    actyear:year,session:this.sessionVar,class:this.classes[this.classes.findIndex(obj=>obj.id==c_code)].nm
                  })
                  this.existedFeeRefundArr.sort((a: any, b: any) => parseInt(a.c_code) - parseInt(b.c_code));
                  this.existedFeeRefundArr.sort((a: any, b: any) => parseInt(a.rn) - parseInt(b.rn));
                  this.existedFeeRefundArr.sort((a: any, b: any) => parseInt(a.actyear) - parseInt(b.actyear));
                })
              }
            }
            else {
              this.feeService.insertFeeRefundEntries(c_code, year, rn, fine_id, amnt, this.fine_entry_date, des).subscribe((res: any) => {
                this.toastr.success(res[0].msg);
                this.existedFeeRefundArr.push({
                  ch_no: "Not Generated", rn: rn, rolno: this.rolno, nm: this.name,
                  fee: this.feeidsNm[this.feeidsNm.findIndex(obj => obj.fee_id == fine_id)].fee_nm, fine_id: fine_id, amnt: amnt, Des: des, c_code: c_code,
                  actyear:year,session:this.sessionVar,class:this.classes[this.classes.findIndex(obj=>obj.id==c_code)].nm
                })
                this.existedFeeRefundArr.sort((a: any, b: any) => parseInt(a.c_code) - parseInt(b.c_code));
                this.existedFeeRefundArr.sort((a: any, b: any) => parseInt(a.rn) - parseInt(b.rn));
                this.existedFeeRefundArr.sort((a: any, b: any) => parseInt(a.actyear) - parseInt(b.actyear));
              })
            }

          }
          else
            this.toastr.error("Roll No Does Not Exist")

        }
        else
          this.toastr.warning("Please select an entry date")
      }
      else
        this.toastr.warning("Some Fields are missing")
    }
    else if (e.keyCode != 13) {
      if (this.rnCheck != rn) {
        this.feeService.getStdNameByRn(c_code, year, rn).subscribe((res: any) => {
          this.name = res[0]?.nm
          this.sessionVar=res[0]?.session
          this.rolno = res[0]?.rolno
          this.rnCheck = rn;
        })
      }
    }
  }
  ChangeManualDes(val) {
    this.manualDes = val
  }
  getFeeRefundEntries() {
    const { c_code, year, fine_entry_date } = this.formRef.value;
    this.existedFeeRefundArr = []
    this.feeService.getFeeRefundEntries(c_code, year, this.fine_entry_date).subscribe((res: any) => {
      res.forEach(e => {
        this.existedFeeRefundArr.push(e);
      })
    })
  }
  SearchTable() {
    $(document).ready(function () {
      $("#myInput").on("keyup", function () {
        var value = $(this).val().toLowerCase();
        $("#myTable tr").filter(function () {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
      });
    });
  }
  DeleteStdEntry(obj, val) {

    const { year } = this.formRef.value;
    let msg =
      "Do you really want to Delete Entry for Roll# " + obj.rolno + "\n\n Press OK to proceed";
    if (confirm(msg)) {
      this.feeService.deleteFeeRefundStdEntry(obj.c_code, obj.rn, obj.fine_id, obj.actyear, this.fine_entry_date).subscribe((res: any) => {
        this.toastr.info(res[0].msg);
        if (res[0].status == 1)
          this.existedFeeRefundArr = this.existedFeeRefundArr.filter(val => !((val.rn == obj.rn) && (val.fine_id == obj.fine_id)&&(val.c_code==obj.c_code)&&(val.actyear==obj.actyear)))
      })
    }
  }
 getFeeRefundDes()
 {
  this.feeRefundDes=[]
  this.feeService.getFeeRefundDes().subscribe((res:any)=>{
    res.forEach(e=>{
      this.feeRefundDes.push(e)
    })
  })
 }
 UpdateDes(){
  const{fine_id}=this.formRef.value;
  
this.feeRefundDes.forEach(e=>{
  if(e.fee_id==fine_id)
  this.selectedValue=e.des
})
 }
 ClearFields(){
  this.name=null;
  this.getFeeRefundEntries();
}
}
