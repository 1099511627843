import { Component, OnInit, ViewChild } from "@angular/core";
import { SlideInFromLeft } from "src/app/transitions";
import { ToastrService } from "ngx-toastr";
import { NgForm } from "@angular/forms";
import { AuthenticationService } from "src/app/auth/_services/authentication.service";
import { SelectList } from "../../shared/model/SelectList.model";
import { reportsFeeService } from "../../shared/services/reportsFee.service";
import { jsPDF } from "jspdf";
import { FeeService } from "../../shared/services/Fee.service";
import { DatePipe } from "@angular/common";
import autoTable from "jspdf-autotable";
import * as _ from "lodash";
import { FeeSharedDataService } from "src/app/shared/FeeSharedService";
import { ClassService } from "../../shared/services/Class.service";
import { Subscription } from "rxjs";

let lgNm: Array<SelectList>;

@Component({
  selector: "app-group-base-wise-collection-report",
  templateUrl: "./group-base-wise-collection-report.component.html",
  styleUrls: ["./group-base-wise-collection-report.component.css"],
  animations: [SlideInFromLeft()],
})

export class GroupBaseWiseCollectionReportComponent implements OnInit {

  subscription = new Subscription();
  allClasses: Array<SelectList>;
  sessions: any;
  allDataMap: Map<number, Map<number, number[]>>;
  allData: Array<{ maj_id; maj_nm; b_id; b_nm; countStdt }>;
  isAdmin: boolean = false;
  loader: boolean = false;
  instNo: Array<number>;
  admissionBases: any;
  majors: any;
  cloneAdmissionBasesArr: any;
  storeAdmissionBases: any;

  isHod: boolean;
  private usr = this.authenticationService.getUser();

  @ViewChild("f") formRef: NgForm;
  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private feeService: FeeService,
    private reportsFee: reportsFeeService,
    private feeSharedService: FeeSharedDataService,
    private classService: ClassService
  ) {
    this.allClasses = new Array<SelectList>();
    this.admissionBases = [];
    this.allDataMap = new Map<number, Map<number, number[]>>();
    lgNm = new Array<SelectList>();
    this.allData = new Array<{ maj_id; maj_nm; b_id; b_nm; countStdt }>();
    this.isAdmin = this.authenticationService.isAdmin();
    this.isHod = this.authenticationService.isHod();
    this.instNo = new Array<number>();
  }

  ngOnInit(): void {
    this.getLgNm();
    this.updateClasses();
    this.updateSessions();
  }

  updateClasses() {
    this.allClasses = [];

    if (this.isHod) {
      this.classService.getAllClassesWithDept(this.usr.D_ID).subscribe((res: any) => {
        this.allClasses = res;
        console.log("Response", res)
      });
    }else{

      this.feeSharedService.getFeeClasses()
      .then(classes => {
        this.allClasses = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
    }

    
  }

  updateSessions() {
    this.sessions = []
    this.feeSharedService.getFeeSessions()
      .then(sessions => {
        this.sessions = sessions;
      })
      .catch(error => {
        console.error("Error getting fee session", error);
      });
  }

  onData(c_code) {
    this.loader = true;
    this.allDataMap.clear();
    this.subscription.add(this.reportsFee.onCollectionReportGroupBaseWise(this.formRef.value).subscribe((res: any) => {
      this.updateAdmissionBases(res[0]);
      this.updateMajors(res[0]);
      this.updateAllDataMap(res[0]);
      this.onPdfDownload(c_code);
    }
    ));
  }

  onMeritWiseData(c_code) {
    console.log('here')
    this.loader = true;
    this.allDataMap.clear();
    this.subscription.add(this.reportsFee.onCollectionReportGroupBaseMeritWise(this.formRef.value).subscribe((res: any) => {
      this.updateAdmissionBases(res[0]);
      this.updateMajors(res[0]);
      this.updateAllDataMap(res[0]);
      this.onPdfDownload(c_code);
    }
    ));
  }

  updateAdmissionBases(res: any) {
    this.admissionBases = [];
    res.map(({ b_id, base }: any) => ({ b_id, base })).forEach(val => {
      if (this.admissionBases.findIndex(inVal => inVal.b_id == val.b_id) == -1) {
        this.admissionBases.push(val);
      }
    });
    this.admissionBases = this.admissionBases.map(obj => ({ ...obj, count: 0 }))
    this.cloneAdmissionBases();
  }

  updateMajors(res) {
    this.majors = []
    res.map(({ maj_id, s_nm, major }: any) => ({ maj_id, s_nm, major })).forEach(val => {
      if (this.majors.findIndex(inVal => inVal.maj_id == val.maj_id) == -1) {
        this.majors.push(val)
      }
    });
  }

  updateAllDataMap(res) {

    // console.log("Deal Win: ", res)
    if (this.isHod) {
      res.forEach(val => {
        if (val.d_id == this.usr.D_ID) {
          if (this.allDataMap.has(val.major)) {
            // outer map
            if (this.allDataMap.get(val.major).has(val.base)) {
              this.allDataMap
                .get(val.major)
                .get(val.base)
                .push(val.count);
            } else {
              this.allDataMap
                .get(val.major)
                .set(val.base, val.count);
            }
          } // end outer map
          else {
            this.allDataMap.set(val.major, new Map());
            this.allDataMap.get(val.major).set(val.base, val.count);
          }
        }
      })
      
    }else{
      res.forEach(val => {
        if (this.allDataMap.has(val.major)) {
          // outer map
          if (this.allDataMap.get(val.major).has(val.base)) {
            this.allDataMap
              .get(val.major)
              .get(val.base)
              .push(val.count);
          } else {
            this.allDataMap
              .get(val.major)
              .set(val.base, val.count);
          }
        } // end outer map
        else {
          this.allDataMap.set(val.major, new Map());
          this.allDataMap.get(val.major).set(val.base, val.count);
        }
      })
    }
    
  }

  cloneAdmissionBases() {
    this.cloneAdmissionBasesArr = [];
    this.cloneAdmissionBasesArr = _.cloneDeep(this.admissionBases)
  }

  onClear() {
    this.formRef.reset();
  }

  getLgNm() {
    this.feeService.onLogNm().subscribe(
      (res: { nm: string; fm_id: number }) => {
        if (!res) {
          this.toastr.error("Unknown Error!");
        } else {
          lgNm.push({ id: res.fm_id, nm: res.nm });
        }
      },
      (err) => {
        console.log(err);
        this.toastr.error("Unknown Error!");
      }
    );
  }

  updateClonedAdmissionBases(val: any) {
    let index = this.cloneAdmissionBasesArr.findIndex(obj => obj.base == val[0]);
    if (index != -1) {
      this.cloneAdmissionBasesArr[index].count = val[1];
    }
  }

  updateStoredAdmissionBases(array: any) {
    array.forEach(val => {
      let index = this.storeAdmissionBases.findIndex(res => res.b_id == val.b_id);
      if (index != -1) {
        this.storeAdmissionBases[index].count += parseInt(val.count);
      }
      else {
        this.storeAdmissionBases.push(val);
        this.storeAdmissionBases[this.storeAdmissionBases.length - 1].count = parseInt(val.count);
      }
    })
  }


  onPdfDownload(c: HTMLSelectElement) {

    function toPascalCase(str) {
      if (!str) {
        return "";
      }
      return str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
        .join(" ");
    }

    let pageCount = 1;
    if (this.allDataMap?.size == 0) {
      this.loader = false;
      this.toastr.warning("No Record Found");
      return;
    } else {
      this.loader = false;
      let body = [];
      let header = [];
      this.storeAdmissionBases = [];
      header.push([{ content: "MAJORS", styles: { fontStyle: "bold", fontSize: 9 } }, ...this.admissionBases.map(obj => obj.base), "TOTAL"]);
      for (let major of this.allDataMap) {
        this.cloneAdmissionBases();
        // Converting a map into array
        [...this.allDataMap.get(major[0])].forEach(res => {
          this.updateClonedAdmissionBases(res);
        })
        this.updateStoredAdmissionBases(this.cloneAdmissionBasesArr)
        body.push([{ content: major[0], styles: { fontStyle: "bold", fontSize: 7.5 } }, ...this.cloneAdmissionBasesArr.map(obj => obj.count),
        { content: this.cloneAdmissionBasesArr.reduce((a, b) => parseInt(a) + parseInt(b.count), 0), styles: { fontStyle: "bold", fontSize: 9 } }
        ])
      }

      body.push([
        { content: `TOTAL`, styles: { fontStyle: "bold", fontSize: 9 } },
        ...this.storeAdmissionBases.map(obj => obj.count),
        { content: this.storeAdmissionBases.reduce((a, b) => parseInt(a) + parseInt(b.count), 0), styles: { fontStyle: "bold", fontSize: 9 } },
      ]);

      const doc = new jsPDF("l", "mm", "legal") as any;
      var exportDate = this.datePipe.transform(new Date(), "MMM d, y");
      var image = new Image();
      var year = this.formRef.value.year;
      var groupClass = this.allClasses[c.selectedIndex].nm;
      image.src = "../../../assets/images/logo3.png";
      let y = 40;

      autoTable(doc, {
        theme: "grid",
        startY: y,
        margin: { top: 40, bottom: 25, left: 5, right: 5 },
        styles: { fontSize: 8 },
        head: header,
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineWidth: 0.1,
          fontSize: 7,
        },
        columnStyles: {
          0: { cellWidth: 50 },
        },
        body: body,
        didDrawPage: function () {
          const pageX = doc.internal.pageSize.getWidth() / 2;
          const pageY = doc.internal.pageSize.getHeight() - 4;
          doc.setFont('Helvetica', 'bold');
          doc.addImage(image, 300, 4, 25, 27);
          doc.setFontSize(10);
          doc.text(`${exportDate}`, 302, 35);
          doc.setFontSize(22);
          doc.setFont("Helvetica", "bold");
          doc.text("GC UNIVERSITY, LAHORE", 165, 15, { align: "center" });
          doc.setFontSize(18);
          doc.setFont("Helvetica", "bold");
          doc.setFontSize(14);
          doc.text("Group And Base Wise List Of Fee Deposited Students", 165, 26, {
            align: "center",
          });
          doc.text("________________________________________________", 165, 26, {
            align: "center",
          });
          doc.setFontSize(12);
          doc.text("CLASS: " + groupClass + " (" + year + ")", 165, 34, { align: "center" });
          ///////////////////////////////////////FOOTER///////////////////////////////////
          doc.setFontSize(10);
          doc.text("Compiled By: " + toPascalCase(lgNm[0].nm), 40, doc.internal.pageSize.getHeight() - 13, { align: "left" });
          doc.text("Checked By: ", 160, doc.internal.pageSize.getHeight() - 13, { align: "center" });
          doc.setDrawColor(0, 0, 0);
          doc.text("___________________", 190, doc.internal.pageSize.getHeight() - 14.5, { align: "center" });
          doc.text("Registrar: ", 275, doc.internal.pageSize.getHeight() - 13, { align: "right" });
          doc.setDrawColor(0, 0, 0);
          doc.text("___________________", 295, doc.internal.pageSize.getHeight() - 14.5, { align: "center" });
          doc.setFontSize(10);
          doc.text("Page " + pageCount, pageX, pageY - 1, { align: "center" });
          doc.setFont("Helvetica", "Normal");
          doc.setFontSize(10);
          doc.text("Note:- Errors and Omissions are Excepted ", 7, pageY - 1, { align: "left" });
          doc.text("Directorate of Information Technology ", pageX + 117, pageY - 1, { align: "left" });
          pageCount++;
        },
      });
      window.open(URL.createObjectURL(doc.output("blob")), "_blank");
    }
  }

  onMeritPdfDownload(c: HTMLSelectElement) {
    function toPascalCase(str) {
      if (!str) {
        return "";
      }
      return str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
        .join(" ");
    }

    let pageCount = 1;
    if (this.allDataMap?.size == 0) {
      this.loader = false;
      this.toastr.warning("No Record Found");
      return;
    } else {
      this.loader = false;
      let body = [];
      let header = [];
      this.storeAdmissionBases = [];
      header.push([{ content: "MAJORS", styles: { fontStyle: "bold", fontSize: 9 } }, 'Merit List#', ...this.admissionBases.map(obj => obj.base), "TOTAL"]);
      for (let major of this.allDataMap) {
        this.cloneAdmissionBases();
        // Converting a map into array
        [...this.allDataMap.get(major[0])].forEach(res => {
          this.updateClonedAdmissionBases(res);
        })
        this.updateStoredAdmissionBases(this.cloneAdmissionBasesArr)
        body.push([{ content: major[0], styles: { fontStyle: "bold", fontSize: 7.5 } } , ...this.cloneAdmissionBasesArr.map(obj => obj.count),
        { content: this.cloneAdmissionBasesArr.reduce((a, b) => parseInt(a) + parseInt(b.count), 0), styles: { fontStyle: "bold", fontSize: 9 } }
        ])
      }

      body.push([
        { content: `TOTAL`, styles: { fontStyle: "bold", fontSize: 9 } },
        ...this.storeAdmissionBases.map(obj => obj.count),
        { content: this.storeAdmissionBases.reduce((a, b) => parseInt(a) + parseInt(b.count), 0), styles: { fontStyle: "bold", fontSize: 9 } },
      ]);

      const doc = new jsPDF("l", "mm", "legal") as any;
      var exportDate = this.datePipe.transform(new Date(), "MMM d, y");
      var image = new Image();
      var year = this.formRef.value.year;
      var groupClass = this.allClasses[c.selectedIndex].nm;
      image.src = "../../../assets/images/logo3.png";
      let y = 40;

      autoTable(doc, {
        theme: "grid",
        startY: y,
        margin: { top: 40, bottom: 25, left: 5, right: 5 },
        styles: { fontSize: 8 },
        head: header,
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineWidth: 0.1,
          fontSize: 7,
        },
        columnStyles: {
          0: { cellWidth: 50 },
        },
        body: body,
        didDrawPage: function () {
          const pageX = doc.internal.pageSize.getWidth() / 2;
          const pageY = doc.internal.pageSize.getHeight() - 4;
          doc.setFont('Helvetica', 'bold');
          doc.addImage(image, 300, 4, 25, 27);
          doc.setFontSize(10);
          doc.text(`${exportDate}`, 302, 35);
          doc.setFontSize(22);
          doc.setFont("Helvetica", "bold");
          doc.text("GC UNIVERSITY, LAHORE", 165, 15, { align: "center" });
          doc.setFontSize(18);
          doc.setFont("Helvetica", "bold");
          doc.setFontSize(14);
          doc.text("Group And Base Wise List Of Fee Deposited Students", 165, 26, {
            align: "center",
          });
          doc.text("________________________________________________", 165, 26, {
            align: "center",
          });
          doc.setFontSize(12);
          doc.text("CLASS: " + groupClass + " (" + year + ")", 165, 34, { align: "center" });
          ///////////////////////////////////////FOOTER///////////////////////////////////
          doc.setFontSize(10);
          doc.text("Compiled By: " + toPascalCase(lgNm[0].nm), 40, doc.internal.pageSize.getHeight() - 13, { align: "left" });
          doc.text("Checked By: ", 160, doc.internal.pageSize.getHeight() - 13, { align: "center" });
          doc.setDrawColor(0, 0, 0);
          doc.text("___________________", 190, doc.internal.pageSize.getHeight() - 14.5, { align: "center" });
          doc.text("Registrar: ", 275, doc.internal.pageSize.getHeight() - 13, { align: "right" });
          doc.setDrawColor(0, 0, 0);
          doc.text("___________________", 295, doc.internal.pageSize.getHeight() - 14.5, { align: "center" });
          doc.setFontSize(10);
          doc.text("Page " + pageCount, pageX, pageY - 1, { align: "center" });
          doc.setFont("Helvetica", "Normal");
          doc.setFontSize(10);
          doc.text("Note:- Errors and Omissions are Excepted ", 7, pageY - 1, { align: "left" });
          doc.text("Directorate of Information Technology ", pageX + 117, pageY - 1, { align: "left" });
          pageCount++;
        },
      });
      window.open(URL.createObjectURL(doc.output("blob")), "_blank");
    }
  }

  ClearClassBase() {
    this.formRef?.controls["year"].reset();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
