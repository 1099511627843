<div class="container-fluid" [@SlideInFromLeft]>
    <div class="card shadow">
        <div class="card-header">Letter Grade For A Class</div>
        <form (ngSubmit)="OnSubmit()" #f="ngForm" class="row">
            <div class="row card-body">
                <div class="col-lg-4 col-md-6 col-sm-10 form-group">
                    <label for="c_code">Class</label>
                    <select class="form-control" name="c_code" id="c_code" (change)="onChangeClass()" required ngModel>
                        <option *ngFor="let c of classes" value="{{ c.id }}">{{c.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-10 form-group">
                    <label for="se_st_year">Session Start Year</label>
                    <input class="form-control" [(ngModel)]="se_st_year" name="se_st_year" min="0" id="se_st_year" (change)="onChangeYear()"
                        required ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-10 form-group">
                    <label for="se_id">Session ID</label>
                    <select class="form-control" name="se_id" id="se_id" (change)="changeSessionID()" required ngModel>
                        <option *ngFor="let s of session" value="{{s}}">{{s}} </option>
                    </select>
                </div>
                <div class="row ml-3">
                    <button class="btn btn-primary" style="height: 40px;" [disabled]="!f.valid" type="submit"
                        id="save">Save Record</button>
                </div>
            </div>
        </form>

        <div class="card-header">
            Update Letter Grade Information
        </div>
        <div class="card-body row">
            <div *ngIf="showTable" >
                <table class="table table-bordered table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">Sr #</th>
                            <th scope="col">ST_GPER</th>
                            <th scope="col">END_GPER</th>
                            <th scope="col">L_GRD</th>
                            <th scope="col">G_POINT</th>
                            <th scope="col">DESCR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let g of grade1; let i = index;">
                            <td>{{i+1}}</td>
                            <td>
                                <input inputmode="numeric" #st_gper name="st_gper" id="st_gper"
                                    (change)="onChangeRow(g.ST_GPER,i,g,st_gper.value,$event,true,1)" value="{{g.ST_GPER}}"
                                    class="marks weightage">
                            </td>
                            <td>
                                <input inputmode="numeric" #end_gper name="end_gper" id="end_gper"
                                    (change)="onChangeRow(g.END_GPER,i,g,end_gper.value,$event,true,2)" value="{{g.END_GPER}}"
                                    class="marks weightage">
                            </td>
                            <td>
                                <input type="text" #l_grd name="l_grd" id="l_grd"
                                    (change)="onChangeRow(g.L_GRD,i,g,l_grd.value,$event,false,3)" value="{{g.L_GRD}}"
                                    class="marks weightage">
                            </td>
                            <td>
                                <input type="text" #g_point name="g_point" id="g_point"
                                    (change)="onChangeRow(g.G_POINT,i,g,g_point.value,$event,false,4)" value="{{g.G_POINT}}"
                                    class="marks weightage">
                            </td>
                            <td>
                                <!-- {{g.DESCR}} -->
                                <input type="text" #g_descr name="g_descr" id="g_descr"
                                    (change)="onChangeRow(g.DESCR,i,g,g_descr.value,$event,false,5)" value="{{g.DESCR}}"
                                    class="marks weightage">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</div>