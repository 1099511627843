import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';

@Injectable({
  providedIn: 'root'
})
export class StudentdetailService {

  constructor(private http: HttpClient) { }

  getstudent(c_code, maj_id, se_id) {
    return this.http.post(`${baseUrl}/api/showstudent/getstudent`, { c_code, maj_id, se_id });
  }
  getbasestudent(c_code, maj_id, year, b_id) {
    return this.http.post(`${baseUrl}/api/showstudent/basestudent`, { c_code, maj_id, year, b_id })
  }
  getrollno(c_code, maj_id) {
    return this.http.post(`${baseUrl}/api/showstudent/rollnostring`, { c_code, maj_id })
  }
  updaterollno(c_code, g_code, reg_format, rn_format) {
    return this.http.post(`${baseUrl}/api/showstudent/updateformat`, { c_code, g_code, reg_format, rn_format })
  }
  updaterollnoFee(c_code, g_code, reg_format, rn_format) {
    return this.http.post(`${baseUrl}/api/showstudent/updateformatFee`, { c_code, g_code, reg_format, rn_format })
  }
  deleterollno(c_code, g_code) {
    return this.http.post(`${baseUrl}/api/showstudent/deleteRollnoFormat`, { c_code, g_code })
  }
  deleterollnoFee(c_code, g_code) {
    return this.http.post(`${baseUrl}/api/showstudent/deleteRollnoFormatFee`, { c_code, g_code })
  }
  addrollno(c_code, g_code, reg_format, rn_format) {
    return this.http.post(`${baseUrl}/api/showstudent/Addnewrollno`, { c_code, g_code, reg_format, rn_format })
  }

  addrollnoFee(c_code, g_code, reg_format, rn_format) {
    return this.http.post(`${baseUrl}/api/showstudent/AddRollnoFee`, { c_code, g_code, reg_format, rn_format })
  }

  addmessage(message, start_date, end_date, user_role, d_id) {
    return this.http.post(`${baseUrl}/api/showstudent/Addmessage`, { message, start_date, end_date, user_role, d_id })
  }
  getMessage() {
    return this.http.get(`${baseUrl}/api/showstudent/getMessage`);
  }
  getmessage(msg_id) {
    return this.http.post(`${baseUrl}/api/showstudent/getMessageDetail`, { msg_id });
  }
  getNewId() {
    return this.http.get(`${baseUrl}/api/showstudent/getNewId`);
  }
  FetchFormatForMajor(maj_id) {
    return this.http.post(`${baseUrl}/api/showstudent/FetchFormatForMajor`, { maj_id });
  }
  updateRegRolNumForSession(c_code, maj_id, year) {
    return this.http.post(`${baseUrl}/api/showstudent/updateRegRolNumForSession`, { c_code, maj_id, year });
  }
  updateRegRolNumForSessionFee(c_code, maj_id, year) {
    return this.http.post(`${baseUrl}/api/showstudent/updateRegRolNumForSessionFee`, { c_code, maj_id, year });
  }
  getsubject(c_code, se_id, t_no) {
    return this.http.post(`${baseUrl}/api/showstudent/getsubject`, { c_code, se_id, t_no });
  }

  insertroom(blk_id, rm_id, rm_nm, cap, row_no, col_no) {
    return this.http.post(`${baseUrl}/api/showstudent/insertstudentstrength`, { blk_id, rm_id, rm_nm, cap, row_no, col_no });

  }
  room_table() {
    return this.http.get(`${baseUrl}/api/showstudent/getAllRoomStrength`)
  }
  updateroom(rm_id, blk_id, rm_nm, cap, row_no, col_no) {
    return this.http.post(`${baseUrl}/api/showstudent//updateRoom`, { rm_id, blk_id, rm_nm, cap, row_no, col_no });
  }
  getroomstrength(rm_id) {
    return this.http.post(`${baseUrl}/api/showstudent/getSingleRoomStrength`, { rm_id });
  }
  getnewrm_id() {
    return this.http.get(`${baseUrl}/api/showstudent/getnewRM_id`);
  }
  getblock() {
    return this.http.get(`${baseUrl}/api/showstudent/getBlockDetail`);
  }
  getRoom(rm_id) {
    return this.http.post(`${baseUrl}/api/showstudent/getroom`, { rm_id });
  }
  deleteroom(rm_id) {
    return this.http.post(`${baseUrl}/api/showstudent/deleteroom`, { rm_id });
  }
  getsubexamtype(c_code: number) {
    return this.http.get(`${baseUrl}/api/showstudent/getSubExamType/${c_code}`);
  }
  getsubjectoffer(params) {
    return this.http.post(`${baseUrl}/api/showstudent/getSubjectOffer`, params);
  }
  getroomname(blk: any) {
    return this.http.post(`${baseUrl}/api/showstudent/getRoomName`, {blk});
  }
  getSubjectsByS(c_code: number, se_st_year: any, t_no: number) {
    return this.http.get(`${baseUrl}/api/showstudent/getSubjectsByYear/${c_code}/${se_st_year}/${t_no}`);
  }
  getstudentnmrn(c_code: number, year: any, maj_id: any, t_no: number, sub_code: any) {
    return this.http.get(`${baseUrl}/api/showstudent/getstudentnameroll/${c_code}/${year}/${maj_id}/${t_no}/${sub_code}`);

  }
  getstudentrollno(c_code: number, year: any, maj_id:any, t_no: number, sub_code: any, section:any, start_rollno: any, end_rollno: any) {
    return this.http.get(`${baseUrl}/api/showstudent/getstudentrolnowise/${c_code}/${year}/${maj_id}/${t_no}/${sub_code}/${section}/${start_rollno}/${end_rollno}`)
  }
  getstudentsubjectrollno(c_code: number, year: any, t_no: number, status: number) {
    return this.http.get(`${baseUrl}/api/showstudent/getstudentsubjectrollno/${c_code}/${year}/${t_no}/${status}`);

  }
}