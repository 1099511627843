<form (ngSubmit)="OnSubmit()" #f="ngForm">
    <div class=" container-fluid" [@SlideInFromLeft]>
        <div class="row card shadow">
            <div class="card-header">
                <div>Sessions</div>
            </div>
            <div class="row card-body pb-0">
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="c_code">Class Code</label> 
                    <input #c_code type="text" class="form-control" name="c_code" id="c_code" ngModel>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="st_dt">Session Start Date</label>
                    <input #st_dt type="date" [maxLength]="3" (change)="onEndYearChange()" class="form-control"
                        name="st_dt" id="st_dt" [(ngModel)]="st_dt_s">
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="end_dt">Session End Date</label>
                    <input #end_dt type="date" [maxLength]="3" (change)="onEndYearChange()" class="form-control"
                        name="end_dt" id="end_dt" [(ngModel)]="end_dt_e">
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="se_id">Session Id</label>
                    <input type="number" class="form-control" name="se_id" readonly min="1" [(ngModel)]="newSe_id"
                        required>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="gp">GPA/Percentage</label>
                    <select class="form-control" name="gp" id="gp" required ngModel>
                        <option value="G">GPA</option>
                        <option value="P">Percentage</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="att">Attendance %age</label>
                    <input type="number" class="form-control" name="att" min="1" ngModel required>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="pc">Pass Criteria</label>
                    <input type="number" class="form-control" name="pc" min="1" ngModel required>
                </div>
                <div class="col-lg-4 col-md-6">
                    <button class="btn  btn-outline-primary ml-3 mt-2" (click)="insertSession()">
                        Add New Session
                    </button>
                </div>
            </div>
            <div *ngIf="this.sessions?.length" class="card-body" style="overflow-x:scroll; margin-top: -2rem;">
                <table class="table table-bordered table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th>Sr #</th>
                            <th>Se_id</th>
                            <th>Session</th>
                            <th>Session Start Date</th>
                            <th>Session End Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let x of sessions;let i=index" [ngClass]="{'text-danger':x.attend=='a'}">
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                {{x.se_id}}
                            </td>
                            <td>
                                {{x.session_year}}
                            </td>
                            <td>
                                {{x.se_st_dt|date:'MMM d, y, h:mm:ss a'}}
                            </td>
                            <td>
                                {{x.se_e_dt|date:'MMM d, y, h:mm:ss a'}}
                            </td>
                            <td>
                                <button class="btn btn-outline-danger"
                                    (click)="deleteSession(i, x.se_id)">Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>
</form>