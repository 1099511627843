import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';

import { baseUrl } from 'src/axios.js';

@Injectable({
  providedIn: 'root'
})
export class SRMService {

  constructor(
    public http: HttpClient,
      
  ) { }


srmlogin(params)
{
  return this.http.post(`${baseUrl}/api/SRM/srm/login`, params);
}

fmdetails(fm_id:number)
{
  return this.http.get(`${baseUrl}/api/srm/getFMToken`);
}


stdetails(rn:string)
{
  return this.http.get(`${baseUrl}/api/srm/getSTToken`);
}

}