<form #f="ngForm">
  <div [@SlideInFromLeft] class="container-fluid ">
    <div class="card shadow">

      <div class="card-header">
        <div>Shift Single Student Award List</div>
      </div>
      <div class="row card-body">

        <div class="col-lg-4 col-md-6 form-group">
          <label for="c_code">Class</label>
          <select #c_code (change)="OnClassChange()" class="form-control "
            name="c_code" id="c_code" ngModel>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="se_id">Session</label>
          <select class="form-control " #se_id name="se_id" id="se_id" ngModel
            (change)="onSessionChange()">
            <option *ngFor="let s of sessions"
              value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="t_no">Semester</label>
          <select class="form-control " #t_no (change)="OnSemesterChange()"
            name="t_no" id="t_no" ngModel>
            <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]"
              value="{{i}}">{{i}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6  form-group">
          <label for="rolno" class="text-left">Roll No</label>
          <select class="form-control " #rolno (change)="OnRollNoChange(rolno)"
            name="rolno" id="rolno" ngModel>
            <option *ngFor="let i of students"
              value="{{i.ROLNO}}">{{i.ROLNO}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="name">Name</label>
          <input class="form-control" name="name" id="name"
            value="{{this.stdObj?.NM}}" readonly ngModel>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="name">Major</label>
          <input class="form-control" name="name" id="name"
            value={{this.stdObj?.MAJ_NM}} readonly ngModel>
        </div>

        <div>
          <button class="btn btn-primary ml-3 mt-2"
            (click)="getStudentSubject()">
            Get Subjects</button>
          <button class="btn btn-warning ml-2 mt-2" (click)="clearBtn(f)">
            Clear</button>
        </div>
      </div>

      <div *ngIf="this.subjects?.length>0" class="card-header">
        Subjects
      </div>

      <div class="row card-body px-5">
        <table *ngIf="this.subjects?.length>0"
          class="mb-0 table table-striped table-bordered" id="table">
          <thead class="thead-dark">
            <th>Sr No</th>
            <th>Subjects</th>
            <th>Section</th>
            <th style="width:10vw">Action</th>
          </thead>
          <tbody>
            <tr *ngFor="let s of subjects; let i=index;"
              [ngClass]="s.sub_code===selectedSubject ? 'table-active':''"
              id="values">
              <td>{{i+1}}</td>
              <td>{{s.sub_nm}} - ({{s.sub_code}})</td>
              <td> {{s.section}} </td>
              <td>
                <button type="button" class="btn btn-primary"
                  style="font-size:12px" (click)="createAssessment(s)">
                  Create Assessment
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="sortedTitle.length>0" class="card-header">
        Enter Marks
      </div>

      <div *ngIf="sortedTitle.length>0" class="row card-body"
        style="overflow: hidden; overflow-x: scroll">

        <div>
          <h4
            [ngClass]="sortedTitle[0]?.ELIGIBLE==null?'text-success':'text-danger'"
            class="text-right">
            Eligibility Status:
            {{sortedTitle[0]?.ELIGIBLE==null?'Eligible':'In-Eligible'}}
          </h4>
        </div>

        <table class="table table-striped table-bordered mt-4"
          style="overflow: hidden; overflow-x: scroll" id="table">
          <thead class="thead-dark">
            <tr>
              <th style="width: 1vw;">Sr No</th>
              <th style="width: 12vw;">Roll No</th>
              <th style="width: 15vw;">Name</th>
              <th *ngFor="let t of sortedTitle" style="width: 3vw;"
                style="font-size: small;">
                {{t.ASSIGNMENT_TITLE}} <br>
                <b style="font-size: smaller;">
                  /
                  <input #w type="text"
                    value="{{t.ORIGINAL_WEIGHTAGE}}"
                    class="marks weightage" readonly>
                </b>
              </th>
              <th>Total: {{calculateTotal_Weightage_Marks()}}</th>
            </tr>
          </thead>
          <tbody>
            <tr id="values">
              <td>
                <div>{{1}}</div>
              </td>
              <td>
                {{stdObj?.ROLNO.toUpperCase()}}
              </td>
              <td>
                {{stdObj?.NM.toUpperCase()}}
              </td>
              <td *ngFor="let ts of sortedTitle">
                <input class="marks" #enteredMarks
                  style="text-align: center; font-weight: bold;"
                  (change)="onChangeMark(ts.ASS_OBT_MRKS,ts.SUB_ASS_ID,ts,enteredMarks.value,$event)"
                  value="{{ts.ASS_OBT_MRKS}}"
                  type="number"
                  [disabled]="(ts.ASSIGNMENT_TITLE=='Final Exam' && ts.ELIGIBLE!=null)"
                  min="0">
              </td>
              <!-- 
                   [ngClass]="(ts.ASSIGNMENT_TITLE=='Final Exam' && ts.ELIGIBLE!=null)?'disabled':''"
                 -->
              <td>
                {{calculateTotal_Obtained_Marks()}}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row ml-1">
          <button class="btn btn-outline-primary"
            (click)="submitToExam()">Submit to Examination</button>
          <button *ngIf="hideDeleteBtn" class="btn btn-outline-danger ml-1"
            (click)="deleteSingleSubjStdAwardList()">Delete Existing
            Record</button>
        </div>
      </div>
    </div>
  </div>
</form>