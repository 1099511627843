import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { EnrollmentService } from '../shared/services/Enrollment.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DatePipe } from '@angular/common';
declare let $:any;
@Component({
  selector: 'app-level-wise-students',
  templateUrl: './level-wise-students.component.html',
  styleUrls: ['./level-wise-students.component.css'],
  animations: [SlideInFromLeft()]
})
export class LevelWiseStudentsComponent implements OnInit {
  @ViewChild('f') formRef: NgForm;
  constructor(private EnrollmentService: EnrollmentService, private toastr: ToastrService, private datePipe: DatePipe,) { }

  loader = false
  ngOnInit(): void {
    this.loader = false
    this.sl = []
    this.getstudylevelandyear()
  }

  studylevel: any
  years: any
  students: any
  overalltotals: any
  showgrandtotal: boolean = false;
  OnLevelChange() {
    this.overalltotals = []
    this.sl = []
    this.students = []
    this.showgrandtotal = false;
    this.formRef.controls['year_in'].reset();
  }
  OnYearChange() {
    this.overalltotals = []
    this.sl = []
    this.students = []
    this.showgrandtotal = false;
  }

  getstudylevelandyear() {
    this.EnrollmentService.getstudylevelalongwithyear().subscribe(res => {
      this.studylevel = res[0]
      this.years = res[1]
    })
  }

  studentlist: any
  sl: any
  ot: any
  onViewStrengthClick() {
    this.overalltotals = []
    this.loader = true
    this.sl = []
    this.ot = []
    this.students = []
    const { lvl_id, year_in } = this.formRef.value;
    this.EnrollmentService.getlevelwisestudentcount(lvl_id, year_in).subscribe(res => {
      this.students = res[0];
      this.studentlist = []
      var dataarray = []
      var descarray = []
      var summedupaarray = []

      for (var outer = 0; outer < res[1].length; outer++) {
        dataarray = []
        descarray = []
        summedupaarray = []
        var c = res[1][outer].c_code
        for (var inner = 0; inner < res[0].length; inner++) {
          if (res[0][inner].c_code == c) {
            var data = res[0][inner]
            dataarray.push(data)

          }
        }
        var desc = res[1][outer]
        descarray.push(desc)
        summedupaarray[0] = dataarray
        summedupaarray[1] = desc
        this.studentlist.push(summedupaarray)

      }
      this.ot = res[2][0]
      this.overalltotals = this.ot;

      this.showgrandtotal = true;
      this.loader = false
      this.sl = this.studentlist;
      console.log(this.sl,'sl')
    },
      err => {
        this.loader = false;
        this.toastr.error('Error Occured');
      }
    )


  }
  OnCreatePDF() {




    this.toastr.info('Generating pdf...', null, { timeOut: 120000 });
    var header = [];
    var level = ''
    var year = this.formRef.value.year_in
    this.studylevel.forEach(element => {
      if (element.LVLID == this.formRef.value.lvl_id) {
        level = element.DES
      }
    });


    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y,h:mm a')
    var image = new Image();
    var page;
    let to = this.formRef.value.t_no;
    image.src = '../../../assets/images/logo3.png';
    var body = [];
    console.log(this.sl, "sl")
    const doc = new jsPDF();
    let k = 0;
    // header.push(['Sr #', 'Major', 'Male P1', 'Female P1','Total P1', 'Male P2', 'Female P2','Total P2', 'Male P3', 'Female P3','Total P3', 'Male P4', 'Female P4','Total P4', 'Grand Total']);

//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    if (this.formRef.value.lvl_id == 1) {      
      for (let i = 0; i < this.sl.length; i++) {        
        header = []
        header.push(['Sr #', 'Major', 'Male P1', 'Female P1', 'Total P1', 'Male P2', 'Female P2', 'Total P2', 'Male P3', 'Female P3', 'Total P3', 'Male P4', 'Female P4', 'Total P4', 'Grand Total']);
        body = []
        for (let j = 0; j < this.sl[i][0].length; j++) {          
          k++;
          body.push([k,
            this.sl[i][0][j].majname,
            this.sl[i][0][j].malepart1,
            this.sl[i][0][j].femalepart1,
            this.sl[i][0][j].totalpart1,
            this.sl[i][0][j].malepart2,
            this.sl[i][0][j].femalepart2,
            this.sl[i][0][j].totalpart2,
            this.sl[i][0][j].malepart3,
            this.sl[i][0][j].femalepart3,
            this.sl[i][0][j].totalpart3,
            this.sl[i][0][j].malepart4,
            this.sl[i][0][j].femalepart4,
            this.sl[i][0][j].totalpart4,
            this.sl[i][0][j].total,
          ]);
        }        
        k = null
        body.push([k,
          this.sl[i][1].des + ' CLASS TOTAL',
          this.sl[i][1].malepart1,
          this.sl[i][1].femalepart1,
          this.sl[i][1].totalpart1,
          this.sl[i][1].malepart2,
          this.sl[i][1].femalepart2,
          this.sl[i][1].totalpart2,
          this.sl[i][1].malepart3,
          this.sl[i][1].femalepart3,
          this.sl[i][1].totalpart3,
          this.sl[i][1].malepart4,
          this.sl[i][1].femalepart4,
          this.sl[i][1].totalpart4,
          this.sl[i][1].total,
        ]);
        autoTable(doc, {
          margin: { top: 35, bottom: 20 },
          styles: { fontSize: 8 },
          head: header,
          body: body,
          columnStyles: {
            1: { cellWidth: 30 },
          },
          didDrawPage: function () {
            doc.addImage(image, 177, 2, 25, 25);
            doc.setFontSize(10);
            doc.text('${exportDate}', 172, 31);
            doc.setFontSize(20);
            doc.setFont('Arial');
            doc.text("GC UNIVERSITY LAHORE", 100, 10, { align: 'center' });
            doc.setFontSize(12);
            doc.text("LEVEL WISE STRENGTH", 100, 17, { align: 'center' });
            doc.text("STUDY LEVEL : " + level.toUpperCase(), 100, 24, { align: 'center' });
            doc.text("YEAR: " + year, 100, 31, { align: 'center' });
            doc.setFontSize(10);
            page = doc.getNumberOfPages();
            doc.text("Note:- Errors and Omissions Excepted", 145, 288);
            doc.text("Directorate of Information Technology", 8, 288);
          }
        });
      }
      header = []
      header.push(['', '', 'Male P1', 'Female P1', 'Total P1', 'Male P2', 'Female P2', 'Total P2', 'Male P3', 'Female P3', 'Total P3', 'Male P4', 'Female P4', 'Total P4', 'Grand Total']);
      k = null
      body = []
      body.push([k,
        'GRAND TOTAL',
        this.overalltotals.gmalepart1,
        this.overalltotals.gfemalepart1,
        this.overalltotals.gtotalpart1,
        this.overalltotals.gmalepart2,
        this.overalltotals.gfemalepart2,
        this.overalltotals.gtotalpart2,
        this.overalltotals.gmalepart3,
        this.overalltotals.gfemalepart3,
        this.overalltotals.gtotalpart3,
        this.overalltotals.gmalepart4,
        this.overalltotals.gfemalepart4,
        this.overalltotals.gtotalpart4,
        this.overalltotals.gclasstotal,
      ]);
      k = 0
      autoTable(doc, {
        margin: { top: 35, bottom: 20 },
        styles: { fontSize: 7 },
        head: header,
        body: body,
        columnStyles: {
          1: { cellWidth: 30 },
        },
        didDrawPage: function () {
          doc.addImage(image, 177, 2, 25, 25);
          doc.setFontSize(10);
          doc.text('${exportDate}', 172, 31);
          doc.setFontSize(17);
          doc.setFont('Arial');
          doc.text("GC UNIVERSITY LAHORE", 100, 10, { align: 'center' });
          doc.setFontSize(12);
          doc.text("LEVEL WISE STRENGTH", 100, 17, { align: 'center' });
          doc.text("STUDY LEVEL : " + level.toUpperCase(), 100, 24, { align: 'center' });
          doc.text("YEAR: " + year, 100, 31, { align: 'center' });
          doc.setFontSize(10);
          page = doc.getNumberOfPages();
          doc.text("Note:- Errors and Omissions Excepted", 145, 288);
          doc.text("Directorate of Information Technology", 8, 288);
        }
      });
      var l = (body.length + 65) % 26;
      doc.setFontSize(10);
      for (var i = 1; i <= page; i++) {
        doc.setPage(i);
        doc.text('Page ' + i + " of " + page, 90, 288);
      }
      this.toastr.clear();
      doc.save("Student Strength");
      //stop here for intermediate
      return;
    }
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    for (let i = 0; i < this.sl.length; i++) {      
      header = []
      header.push(['Sr #', 'Major', 'Male P1', 'Female P1', 'Total P1', 'Male P2', 'Female P2', 'Total P2', 'Male P3', 'Female P3', 'Total P3', 'Male P4', 'Female P4', 'Total P4', 'Grand Total']);
      body = []
      for (let j = 0; j < this.sl[i][0].length; j++) {        
        k++;
        body.push([k,
          this.sl[i][0][j].majname,
          this.sl[i][0][j].malepart1,
          this.sl[i][0][j].femalepart1,
          this.sl[i][0][j].totalpart1,
          this.sl[i][0][j].malepart2,
          this.sl[i][0][j].femalepart2,
          this.sl[i][0][j].totalpart2,
          this.sl[i][0][j].malepart3,
          this.sl[i][0][j].femalepart3,
          this.sl[i][0][j].totalpart3,
          this.sl[i][0][j].malepart4,
          this.sl[i][0][j].femalepart4,
          this.sl[i][0][j].totalpart4,
          this.sl[i][0][j].total,
        ]);               
      }          
      k = null
      body.push([k,
        this.sl[i][1].des + ' CLASS TOTAL',
        this.sl[i][1].malepart1,
        this.sl[i][1].femalepart1,
        this.sl[i][1].totalpart1,
        this.sl[i][1].malepart2,
        this.sl[i][1].femalepart2,
        this.sl[i][1].totalpart2,
        this.sl[i][1].malepart3,
        this.sl[i][1].femalepart3,
        this.sl[i][1].totalpart3,
        this.sl[i][1].malepart4,
        this.sl[i][1].femalepart4,
        this.sl[i][1].totalpart4,
        this.sl[i][1].total,
      ]);    
      if (i == (this.sl.length-1) ) {        
        header = []
        header.push(['', '', 'Male P1', 'Female P1', 'Total P1', 'Male P2', 'Female P2', 'Total P2', 'Male P3', 'Female P3', 'Total P3', 'Male P4', 'Female P4', 'Total P4', 'Grand Total']);
        k = null
        // body = []
        body.push([k,
          'GRAND TOTAL',
          this.overalltotals.gmalepart1,
          this.overalltotals.gfemalepart1,
          this.overalltotals.gtotalpart1,
          this.overalltotals.gmalepart2,
          this.overalltotals.gfemalepart2,
          this.overalltotals.gtotalpart2,
          this.overalltotals.gmalepart3,
          this.overalltotals.gfemalepart3,
          this.overalltotals.gtotalpart3,
          this.overalltotals.gmalepart4,
          this.overalltotals.gfemalepart4,
          this.overalltotals.gtotalpart4,
          this.overalltotals.gclasstotal,
        ]);
      }
      k = 0

      autoTable(doc, {
        margin: { top: 35, bottom: 15 },
        styles: { fontSize: 8 },
        head: header,
        body: body,
        columnStyles: {
          1: { cellWidth: 30 },
        },
        didDrawPage: function () {
          doc.addImage(image, 177, 2, 25, 25);
          doc.setFontSize(10);
          doc.text('${exportDate}', 172, 31);
          doc.setFontSize(20);
          doc.setFont('Arial');
          doc.text("GC UNIVERSITY LAHORE", 100, 10, { align: 'center' });
          doc.setFontSize(12);
          doc.text("LEVEL WISE STRENGTH", 100, 17, { align: 'center' });
          doc.text("STUDY LEVEL : " + level.toUpperCase(), 100, 24, { align: 'center' });
          doc.text("YEAR: " + year, 100, 31, { align: 'center' });
          doc.setFontSize(10);
          page = doc.getNumberOfPages();
          doc.text("Note:- Errors and Omissions Excepted", 145, 288);
          doc.text("Directorate of Information Technology", 8, 288);
        }
      });
    }
    var l = (body.length + 65) % 26;
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 90, 288);
    }
    this.toastr.clear();
    console.log(body,"lastbody")
    doc.save("Student Strength");
  }

  Filter(index)
  {
    $(document).ready(function(){
      $(`#myInput${index}`).on("keyup", function() {
        var value = $(this).val().toLowerCase();
        $(`#table${index} #values${index}`).filter(function() {
          $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
      });
    });
  }
}