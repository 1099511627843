<div class="card" [@SlideInFromLeft]>
  <div class="card-header"  style="padding-top: 10px;">
    <div class=" col-lg-6 col-md-6 form-group">
      Enrollment Status Report</div>
       <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
      <app-details-button [details]="details"></app-details-button>
  </div>
</div>
  <div class="row card-body">
    <div class="col-sm-12 col-lg-12 p-2">
      <form #f="ngForm">
        <div class="row">

          <div class="col-lg-4 col-md-6 form-group"
            *ngIf='this.isAdmin || this.isExamCell'>
            <label for="Department">Department</label>
            <select #department (change)="OnDeptChange(department)" id="d_id"
              name="d_id" class="form-control" ngModel>
              <option *ngFor="let d of departments"
                value="{{d.id}}">{{d.nm}}</option>
            </select>
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="C_Code">Class</label>
            <select #cource (change)="OnClassChange(cource)"
              class="form-control " name="c_code" id="C_Code" required
              ngModel>
              <option *ngFor="let c of classes"
                value="{{c.id}}">{{c.nm}}</option>
            </select>
          </div>
          <div class="col-lg-4 col-md-6 form-group">
            <label for="Session">Session</label>
            <select class="form-control " #session
              (change)="OnSessionChange(session)" name="se_id" id="Session"
              ngModel
              required>
              <option *ngFor="let s of sessions"
                value="{{s.id}}">{{s.nm}}</option>
            </select>
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="Session">Semester</label>
            <select #semester class="form-control " #session
              (change)="OnSemesterChange(semester)" name="t_no"
              id="Semester" ngModel required>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">1-8</option>
            </select>
          </div>

          <!-- <div class="col-lg-4 col-md-6 form-group">
              <label for="Subject">Subject</label>
              <select #subject class="form-control" name="sub_code" (change)="onSubjectChange(subject)" id="Subject"
                ngModel required>
                <option *ngFor="let sub of subjects" value="{{sub.SUB_CODE}}">{{sub.SUB_CODE}}-({{sub.SUB_NM}})</option>
  
              </select>
            </div> -->
          <!--added-->
          <!-- <div class="col-lg-4 col-md-6 form-group" *ngIf="filteredStudents?.length">
              <label for="type">Type</label>
              <select #type class="form-control" (change)="onTypeChange()" name="mode" id="type" ngModel>
                <option value="0">Eligible</option>
                <option value="1">Ineligible</option>
                <option value="2">Both</option>
              </select>
            </div> -->
        </div>

        <div class="row mt-3">
          <button class="btn btn-primary ml-3" (click)="getData()"
            type="button" [disabled]="!f.valid">Check Status
          </button>
          <button [disabled]="!data?.length" class="btn ml-2 btn-danger"
            (click)="OnCreatePDF()"
            type="button">
            <i class="fa fa-file-pdf-o" for></i>
            Download PDF
          </button>
          <button [disabled]="!data?.length" class="btn btn-success ml-2"
            (click)="DownloadExcel()" type="button">
            <i class="fa fa-file-excel-o" for></i>
            Download Excel
          </button>
        </div>
        <div class="center"><loader *ngIf="loader"></loader></div>
      </form>
    </div>
    <div class="col-sm-12 col-lg-12 p-2 mt-sm-5 mt-lg-2"
      *ngIf="data?.length>0">
        <div>
          <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
            <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
        </div>
          <table class="table table-bordered table-striped m-0 p-0" id="table">
            <thead class="thead-dark">
              <tr>
                <th>Sr #</th>
                <th>Major</th>
                <th>Semester</th>
                <th>Subject Code</th>
                <th>Subject Name</th>
                <th>Timetable</th>
                <th>Enrollment</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let each of data; let i = index">
                <tr
                  [ngStyle]="{'color': each.tmbool? 'black':'red'}"
                  [ngStyle]="{'color': each.enrollbool? 'black':'red'}" id="values">
                  <td>
                    <div>{{i+1}}</div>
                  </td>
                  <td>
                    <div>{{each.major}}</div>
                  </td>
                  <td>
                    <div>{{each.t_no}}</div>
                  </td>
                  <td>
                    <div>{{(each.sub_code)}} </div>
                  </td>
                  <td>
                    <div>{{each.subject}}</div>
                  </td>
                  <td>
                    <div>{{each.tm}}</div>
                    <td>
                      <div>{{each.enroll}}</div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        <br>
      </div>
    </div>
  </div>