<!-- 
<div class="row">
  <div class="col-sm-4">
    <div class="card text-center shadow" style="border-radius: 2rem;">
      <div class="card-body" style="padding:0.75rem 0 0.75rem 0;">
        <h5 class="card-title">Programmes</h5>
        <p class="card-text" style="font-size:medium">{{totalprogcount}}</p>
      </div>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="card text-center shadow" style="border-radius: 2rem;">
      <div class="card-body" style="padding:0.75rem 0 0.75rem 0;">
        <h5 class="card-title">Departments</h5>
        <p class="card-text" style="font-size:medium">{{dptcount}}</p>
      </div>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="card text-center shadow" style="border-radius: 2rem;">
      <div class="card-body" style="padding:0.75rem 0 0.75rem 0;">
        <h5 class="card-title">Employees</h5>
        <p class="card-text" style="font-size:medium">10</p>
      </div>
    </div>
  </div>
</div> -->

<h3 style="color: #003970;">Welcome to the Department of: <strong>{{deptName}}</strong></h3>

<div class="card-header" style="justify-content: flex-start; height: 80px; background-color: rgb(235, 235, 235);">
  <button class="routing-button ml-1" role="button" (click)="showDetail(1)"><span class="text"> <img
        src="../../../assets/graduated.png" style="width: 30px;height: 30px;" class="ml-2"> Student Details </span>
    <span>Student
      Details</span></button>
  <button class="routing-button ml-3" role="button" (click)="showDetail(2)"><span class="text"> <img
        src="../../../assets/teacher.png" style="width: 30px;height: 30px;" class="ml-2"> Faculty Details</span>
    <span>Faculty
      Details</span></button>

  <button class="routing-button ml-3" role="button" (click)="showDetail(3)"><span class="text"> <img
        src="../../../assets/std-reptr.png" style="width: 30px;height: 30px;" class="ml-2">Subject Repeater</span>
    <span>Subject Repeater</span></button>
  
    <button class="routing-button ml-3" role="button" (click)="showDetail(4)"><span class="text"> <img
      src="../../../assets/dues_collection.png" style="width: 30px;height: 30px;" class="ml-2">Dues Collection</span>
  <span>Dues Collection</span></button>

</div>


<div id="container">



  <div class="card shadow scaling" style="width: 22rem; height: 21rem; border-radius: 0.5rem;">
    <div class="card-header text-center"
      style="border-top-left-radius: 2rem;border-top-right-radius: 2rem; font-size: large;">
      Year Wise Enrollment
    </div>
    <div class="card-body" style="padding:1rem 0 0 0; ">
      <canvas id="genderchart">
      </canvas>
    </div>
    <div class="card-footer" style="border-bottom-left-radius: 2rem;border-bottom-right-radius: 2rem;">
      <div class="row" style="flex-wrap: nowrap; justify-content: center;">
        <button *ngIf="certificateButton" routerLink="certificate"
          style="margin-right: 0.5rem; max-width: 75px; min-width: 75px; font-size: smaller;"
          class="btn btn-outline-primary">Certificate</button>
        <button *ngIf="interButton" routerLink="inter"
          style="margin-right: 0.5rem; max-width: 75px; min-width: 75px; font-size: smaller;"
          class="btn btn-outline-primary">Inter</button>
        <button *ngIf="gradButton" routerLink="graduate"
          style="margin-right: 0.5rem; max-width: 75px; min-width: 75px; font-size: smaller;"
          class="btn btn-outline-primary">Graduate</button>
        <button *ngIf="masterButton" routerLink="postgrad"
          style=" max-width: 75px; min-width: 75px; font-size: smaller;"
          class="btn btn-outline-primary">MS/Mphil</button>
      </div>
    </div>
  </div>



  <div class="card shadow scaling" style="width: 22rem; height: 21rem;border-radius: 0.5rem;">
    <!-- <div class="card-header" style="border-top-left-radius: 2rem; border-top-right-radius: 2rem;">
      Employees : {{total_faculty}}
    </div> -->
    <div class="card-body" style="padding:0 0 0 0; -ms-text-combine-horizontal: 5px;">
      <!-- <canvas id="employeechart">
      </canvas> -->
      <div class="card-header" style=" font-size: large; border-top-left-radius: 2rem;border-top-right-radius: 2rem;">
        Employees : {{total_faculty}}
      </div>

      <div class="table-responsive" style="height: 230px; ">
        <table class="table table-bordered table-striped">
          <thead style="color:white; background-color: #003970; ">
            <tr>
              <th scope="col" style="width: 15vw; text-align: center;">Sr #</th>
              <th scope="col" style="width: 45vw; text-align: center;">Designation</th>
              <th scope="col" style="width: 30vw; text-align: center;">Count</th>
              <th scope="col" style="width: 10vw; text-align: center;">PDF</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let rslt of EmployeeGroup; let i = index" class="table-sm"
              style="text-align:center;font-weight:bold; font-size: 10px; line-height: 10px;">

              <td>{{i+1}}</td>
              <td style="text-align: left;">{{rslt.DES}}</td>
              <td style="font-size: 11.5px;">{{rslt.count}}</td>
              <td ><button (click)="getEmployee(rslt.DES)" style="border: none; color: #003970;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download"
                  viewBox="0 0 16 16">
                  <path
                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                  <path
                    d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                </svg>
              </button></td>


            </tr>

          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer" style=" display: flex; justify-content: right; align-items: center; padding-top: 0.50rem;">
      <button routerLink="employees" class="btn btn-outline-primary"
        style="max-width: 75px; min-width: 75px; font-size: smaller;">Details</button>

      <!-- <form #fn="ngForm" style="margin-left: 1rem; margin-right: 2rem;">
        <div>
          <select style="max-height: 65px; font-size: unset; height: unset; color: #57A8FF; border-color: #3395FF;"
            #faculty_type type="faculty_type" class="form-control shadow-sm" name="faculty_type" id="faculty_type"
            (change)="getEmployee()" ngModel>
            <option value="" disabled>Select Designation...</option>
            <option *ngFor="let i of EmployeeGroup" value="{{i.DES}}">{{i.DES}}</option>
          </select>
        </div>
      </form>

      <div *ngIf="showEmployeeData">
        <button (click)="PDF_Employee()" class="btn btn-outline-primary">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download"
            viewBox="0 0 16 16">
            <path
              d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
            <path
              d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
          </svg>
        </button>
      </div> -->
    </div>

  </div>

  <div class="card shadow scaling" style="width: 22rem; height: 21rem;border-radius: 0.5rem;">
    <div class="card-header text-center"
      style="border-top-left-radius: 2rem;border-top-right-radius: 2rem; font-size: large;">
      Pass/Fail Data
    </div>
    <div class="card-body" (mouseenter)="pauseCycle()" (mouseleave)="resumeCycle()" style="padding:1rem 0 1rem 0; background: -webkit-linear-gradient( #c7c7c7); 
        background: linear-gradient( #c7c7c7);">

      <div style="text-align: center; margin-top: -1rem;">
        <strong>{{ passFailData[currentIndex]?.PRINTTITLE }} ({{ passFailData[currentIndex]?.year }})</strong><br>
        <h6>Semester: {{ passFailData[currentIndex]?.t_no }}</h6>
      </div>

      <canvas id="passFailchart">
      </canvas>


      <!-- <div class="datacontainer">
        <div class="data-item">
          <label for="">Total Students: </label>
          <br>
          <h1 class="data_count">{{ passFailData[currentIndex]?.total_count }}</h1>
        </div>
        <div class="data-item">
          <label for="">Pass Students: </label>
          <br>
          <h1 class="data_count">{{ passFailData[currentIndex]?.pass_count }}</h1>
          
        </div>
        <div class="data-item">
          <label for="">Fail Students: </label>
          <br>
          <h1 class="data_count">{{ passFailData[currentIndex]?.fail_count }}</h1>
  
        </div>
      </div> -->

      <!-- <div class="datacontainer">
        <div class="data-item">
          <div class="circle maroon">{{ passFailData[currentIndex]?.total_count }}</div>
          <p class="heading-data">Total Students</p>
        </div>
        <div class="data-item">
          <div class="circle green">{{ passFailData[currentIndex]?.pass_count }}</div>
          <p class="heading-data">Pass Students</p>
        </div>
        <div class="data-item">
          <div class="circle red">{{ passFailData[currentIndex]?.fail_count }}</div>
          <p class="heading-data">Fail Students</p>
        </div>
      </div> -->



      <button (click)="prevRecord()" class="btn-left">
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="#005F60" class="bi bi-arrow-left-circle"
          viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
        </svg>
      </button>

      <button (click)="nextRecord()" class="btn-right">
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="#005F60" class="bi bi-arrow-right-circle"
          viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
        </svg>

      </button>



    </div>
    <div class="card-footer"
      style="border-bottom-left-radius: 2rem;border-bottom-right-radius: 2rem; padding-top: unset;">
      <div class="row ml-4" style="justify-content: left; padding-left: 5%;">

        <h3 style="width: 50px; color: #003970; text-align: center;">{{ passFailData[currentIndex]?.total_count }}</h3>
        <h3 class="ml-4 " style="width: 50px; color: #1579D1; text-align: center;">{{
          passFailData[currentIndex]?.pass_count }}</h3>
        <h3 class="ml-5 " style="width: 50px; color: #78C2FF; text-align: center;">{{
          passFailData[currentIndex]?.fail_count }}</h3>
        <!-- <button routerLink="departments" (click)="getDepartment()"
          style="margin-right: 2rem; max-width: 75px; min-width: 75px; font-size: smaller;"
          class="btn btn-danger">Details</button>
        <div *ngIf="showDepartmentData">
          <button (click)="PDF_Departments()" style="margin-right: 2rem;" class="btn btn-danger">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download"
              viewBox="0 0 16 16">
              <path
                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
              <path
                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
            </svg></button>
        </div> -->

      </div>
    </div>
  </div>


  <div *ngIf="isAdmin" class="card shadow scaling" style="width: 22rem; height: 21rem;border-radius: 0.5rem;">
    <div class="card-header" style="border-top-left-radius: 2rem;border-top-right-radius: 2rem;">
      Programmes : {{totalprogcount}}
    </div>
    <div class="card-body" style="padding:1rem 0 0 0;">
      <canvas id="programmechart">
      </canvas>
    </div>
    <div class="card-footer" style="border-bottom-left-radius: 2rem;border-bottom-right-radius: 2rem;">
      <div class="row" style="justify-content: left; padding-left: 5%;">
        <div>
          <button routerLink="programmes" (click)="getProgramsData()"
            style="margin-right: 2rem; font-size: smaller; max-width: 75px; min-width: 75px;"
            class="btn btn-outline-primary">Details</button>
        </div>

        <div *ngIf="showProgrammeData">
          <button (click)="PDF_Programme()" style="margin-right: 2rem;" class="btn btn-danger">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download"
              viewBox="0 0 16 16">
              <path
                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
              <path
                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
            </svg>
          </button>

        </div>
      </div>
    </div>
  </div>


  <div *ngIf="isAdmin" class="card shadow scaling" style="width: 22rem; height: 21rem;border-radius: 0.5rem;">
    <div class="card-header text-center"
      style="border-top-left-radius: 2rem;border-top-right-radius: 2rem; font-size: large;">
      Departments : {{dptcount}}
    </div>
    <div class="card-body" style="padding:1rem 0 0 0;">
      <canvas id="departmentchart">
      </canvas>
    </div>
    <div class="card-footer" style="border-bottom-left-radius: 2rem;border-bottom-right-radius: 2rem;">
      <div class="row" style="justify-content: left; padding-left: 5%;">
        <button routerLink="departments" (click)="getDepartment()"
          style="margin-right: 2rem; max-width: 75px; min-width: 75px; font-size: smaller;"
          class="btn btn-outline-primary">Details</button>
        <div *ngIf="showDepartmentData">
          <button (click)="PDF_Departments()" style="margin-right: 2rem;" class="btn btn-danger">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download"
              viewBox="0 0 16 16">
              <path
                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
              <path
                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
            </svg></button>
        </div>
      </div>
    </div>
  </div>


  <!-- <div *ngIf="isAdmin" class="card shadow" style="width: 23rem; height: 21rem;border-radius: 0.5  rem; ">
    <div class="card-header" style="border-top-left-radius: 2rem;border-top-right-radius: 2rem;">
      Accomodation
    </div>
    <div class="card-body" style="padding:1rem 0 0 0;">
      <canvas id="accomodationchart">
      </canvas>
    </div>
    <div class="card-footer" style="border-bottom-left-radius: 2rem;border-bottom-right-radius: 2rem;">
      <div class="row" style="justify-content: left; padding-left: 5%;">
        <button routerLink="accomodation" (click)="getBlocks()" style="margin-right: 2rem;"
          class="btn btn-danger">Details</button>
        <div *ngIf="showAccomodationData">
          <button (click)="PDF_Accomodation()" style="margin-right: 2rem;" class="btn btn-danger">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download"
              viewBox="0 0 16 16">
              <path
                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
              <path
                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
            </svg></button>
        </div>
      </div>
    </div>
  </div> -->



  <!-- <div class="">
    <table class="table table-bordered table-striped mt-4" id="tableID" *ngIf="studentData.length > 0">
      <thead class="thead-dark">
        <th>Sr.#</th>
        <th>Roll Number </th>
        <th> Name </th>
        <th>
          Unpaid Challans
        </th>
        <th>
          Pending Amount
        </th>
      </thead>
      <tbody>
        <tr *ngFor="let i of this.studentData; let j=index">
          <td>
            {{j+1}}
          </td>
          <td>
            {{i.rolno}}
          </td>
          <td>
            {{i.nm}}
          </td>
          <td>
            {{i.Unpaid_Challans}}
          </td>
          <td>
            {{i.pending_amount}}
          </td>
        </tr>
        <tr>
          <th scope="row" colspan="4" class="text-center">Total Amount</th>
          <th scope="row">{{totalAmount}}</th>
        </tr>
      </tbody>
    </table>
  </div> -->

</div>

<div *ngIf="!isAdmin">
  <loader *ngIf="loader"></loader>

  <div class="mt-5 d-flex justify-content-between" style="background-color: rgb(233, 233, 233); color: #003970;">
    <h2>Unpaid Student Intuition</h2>

    <select #year class="form-control shadow-sm" style="width: 25vw;" name="year" id="year" (change)="getFeeDetail()">
      <option *ngFor="let i of sessions" [value]="i.year">{{i.year}}</option>
    </select>

    <button (click)="CreatePDF()" style="margin-right: 1.5rem;" class="btn">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#003970" class="bi bi-download"
        viewBox="0 0 16 16">
        <defs>
          <filter id="bold-shadow" x="-50%" y="-50%" width="200%" height="200%">
            <feDropShadow dx="0" dy="0" stdDeviation="1" flood-color="black" />
          </filter>
        </defs>
        <path
          d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
        <path
          d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
      </svg>
    </button>
  </div>

  <div class="mt-3">
    <table class="table table-striped" id="tableID" *ngIf="studentData.length > 0" style="background-color: white;">
      <thead style="color:#003970;">
        <tr>
          <!-- style="width: 15vw; text-align: center;" -->
          <th scope="col" style="width: 10vw; ">Sr.#</th>
          <th scope="col" style="width: 25vw; ">Roll Number</th>
          <th scope="col" style="width: 55vw; ">Name</th>
          <th scope="col" style="width: 25vw; ">Unpaid Challans</th>
          <th scope="col" style="width: 30vw; ">Pending Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let i of this.studentData; let j=index" style="border-top: unset;">

          <td style="border-top: unset;">{{j+1}}</td>
          <td style="border-top: unset;">{{i.rolno}}</td>
          <td style="border-top: unset;">{{i.nm}}</td>
          <td style="border-top: unset;">{{i.Unpaid_Challans}}</td>
          <td style="border-top: unset;">{{i.pending_amount}}</td>

        </tr>

        <tr>
          <th scope="row" colspan="4" class="text-center">Total Amount</th>
          <th scope="row">{{totalAmount}}</th>
        </tr>

      </tbody>
    </table>
  </div>
</div>


<!-- <div class="card" style="width: 35rem;">
          <div class="card-header">          
            Programme Wise Enrollment
          </div>
          <div class="card-body"style="padding:1rem 0 0 0;">                   
        <canvas id="progenrollchart">
        </canvas>          
      </div>      
    </div> -->







<!-- ++++++++++++++++++ -->



<div class="modal fade" tabindex="-1" role="dialog" [ngClass]="{'show': isModalVisible}"
  [ngStyle]="{'display': isModalVisible ? 'block' : 'none'}">
  <div class="modal-dialog modal-dialog-centered custom-modal" role="document">
    <div class="modal-content">
      <div *ngIf="show_SubjFailed" class="modal-header">
          <h5 class="modal-title">Subject Failed Student</h5>
          <button type="button" class="close" (click)="hideModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      <div class="modal-body">
        <div>
          <!-- <h3 style="font-size:1.5em; text-align: center;margin-top:0.5rem">Graduation Students</h3> -->

          <div class="card shadow bg-white border-0 mb-5 custom-modal-card" id="tablecard">
            <ng-container *ngIf="resetComponent">
              <app-detailedstudent *ngIf="show_StdDetail"></app-detailedstudent>
              <app-check-teacher-attendance *ngIf="show_FacultyDeatil"></app-check-teacher-attendance>

              <app-group-base-wise-collection-report *ngIf="show_DuesCollection"></app-group-base-wise-collection-report>


              <h2 *ngIf="subjFailData.length == 0 && show_SubjFailed" class="text-center">No Data to Display</h2>
              <div *ngIf="show_SubjFailed" class="mt-3" style="height: 300px;">
                <table class="table table-striped" id="tableID" *ngIf="subjFailData.length > 0" style="background-color: white;">
                  <thead style="background-color: #003970; color: white;">
                    <tr>
                      <!-- style="width: 15vw; text-align: center;" -->
                      <th scope="col" style="width: 5vw; ">Sr.#</th>
                      <th scope="col" style="width: 12vw; ">Class Name</th>
                      <th scope="col" style="width: 17vw; ">Major Name</th>
                      <th scope="col" style="width: 13vw; ">Roll #</th>
                      <th scope="col" style="width: 14vw; ">Name</th>
                      <th scope="col" style="width: 13vw; ">Father Name</th>
                      <th scope="col" style="width: 9vw; ">Subject Code</th>
                      <th scope="col" style="width: 15vw; ">Subject Name</th>
                      <th scope="col" style="width: 2vw; ">Term</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let i of this.subjFailData; let j=index" style="border-top: unset;">
            
                      <td style="border-top: unset;">{{j+1}}</td>
                      <td style="border-top: unset;">{{i.DES}}</td>
                      <td style="border-top: unset;">{{i.PRINTTITLE}}</td>
                      <td style="border-top: unset;">{{i.Roll_NO}}</td>
                      <td style="border-top: unset;">{{i.Name}}</td>
                      <td style="border-top: unset;">{{i.Father_Name}}</td>
                      <td style="border-top: unset;">{{i.SUB_CODE}}</td>
                      <td style="border-top: unset;">{{i.Subject_Name}}</td>
                      <td style="border-top: unset;">{{i.T_NO}}</td>
            
                    </tr>
            
                  </tbody>
                </table>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="hideModal()">Close</button>
      </div>
    </div>
  </div>
</div>


<router-outlet></router-outlet>