import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { Timetable } from '../../main/shared/services/Timetable.service';
import { StudentService } from './../shared/services/Student.service';
import { DatePipe } from '@angular/common';
import { jsPDF } from "jspdf";
import autoTable, { UserOptions } from 'jspdf-autotable';
import * as moment from 'moment';
import 'jspdf-autotable';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NgForm } from '@angular/forms';
import { SelectList } from './../shared/model/SelectList.model';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';
import { filter } from 'src/app/shared/functions/tableSearch';
interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

export interface Student {
  ROLNO: string,
  NM: string,
  C_CODE: number,
  SE_ID: number,
  RN: number,
  YEAR: number
}

@Component({
  selector: 'app-std-timetabel-list',
  templateUrl: './std-timetabel-list.component.html',
  styleUrls: ['./std-timetabel-list.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})


export class StdTimetabelListComponent implements OnInit {
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  grp: string = '';

  @ViewChild('f') formRef: NgForm;
  students: Student[];
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  this screen is used to download the student timetable and student list of any major in its class </h6>
  <h6 class="text-right">یہ اسکرین کسی بھی میجر کے کلاس میں طالب علم کا ٹائم ٹیبل اور طالب علموں کی فہرست ڈاؤن لوڈ کرنے کے لئے استعمال ہوتی ہے۔
</h6>
  `  ;


  constructor(private studentService: StudentService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private lmsSharedService: LMSharedDataService,
    private feeSharedService: FeeSharedDataService) {
    this.classes = [];
    this.majors = [];
    this.students = [];
    this.sessions = new Array<SelectList>();
  }

  ngOnInit(): void {
  this.updateClasses();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting lms classes", error);
      });
  }

  OnGrpChange(grp) {
    this.students = [];
    this.grp = (this.majors.find(x => x.id == parseInt(grp.value)))?.nm;
    this.getStudents();
  }

  downloadExcel() {
    let body = [];
    let i = 1;
    this.students.forEach(entry => {
      body.push({ sr: i, Rolno: entry.ROLNO, name: entry.NM.toUpperCase() });
      i++;
    });
    new AngularCsv(body, this.grp, { headers: ['Sr.', 'Roll Nu.', 'Name'] });
  }
  private getStudents() {

    if (this.formRef.value.c_code === '' || this.formRef.value.se_id === '') return;

    this.studentService.getStdList({ MAJ_ID: this.formRef.value.maj_id, SE_ID: this.formRef.value.se_id, C_CODE: this.formRef.value.c_code }).subscribe((res: Student[]) => {
      this.students = res;
    }, err => {
      console.log(err);
      alert('Unknown Error');
    });
  }
  OnClassChange(c: HTMLSelectElement) {
    this.majors = [];
    this.students = [];
    this.updateMajors();
    this.updateSessions();
    this.getStudents();
  }

  updateMajors() {
    this.majors.length = 0;
    this.feeSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
          this.formRef?.controls['maj_id'].reset();
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  updateSessions() {
    this.lmsSharedService.filterSessionByCode(this.formRef.value.c_code)
      .then(session => {
        this.sessions = session
        if (this.formRef) {
          const initialValue = this.sessions[0]?.id;
          this.formRef?.controls['se_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }

  OnSessionChange(s: HTMLSelectElement) {
    this.getStudents();
  }
  downloadTimetable(s) {
    this.studentService.getTimeTableForPDF(s).subscribe(
      (res: [[{ f_nm, SE_NM, M_ID, T_NO, SUB_COMB }], Timetable[]]) => {
        const liveTimetable: Timetable[] = res[1]?.filter(x => x.STATUS === 1);


        const monday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('monday'));
        const tuesday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('tuesday'));
        const wednesday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('wednesday'));
        const thursday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('thursday'));
        const friday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('friday'));
        let timetable: [Timetable[], Timetable[], Timetable[], Timetable[], Timetable[]];

        timetable = [monday, tuesday, wednesday, thursday, friday];

        const { f_nm: F_NM, SE_NM, M_ID, T_NO, SUB_COMB } = res[0][0]
        this.CreatePdf({ F_NM, SE_NM, M_ID, T_NO, SUB_COMB, NM: s.NM, ROLNO: s.ROLNO }, this.getDaysFromTM(timetable), this.sortByStartTimeTable(timetable));
      });
  }
  private sortByStartTimeTable(tm: [Timetable[], Timetable[], Timetable[], Timetable[], Timetable[]]) {
    for (var i = 0; i < tm?.length; i++) {
      tm[i]?.sort((a, b) =>
        a.START_TIME < b.START_TIME ? -1 :
          a.START_TIME > b.START_TIME ? 1 : 0
      )
    }
    return tm;
  }
  private getDaysFromTM(tm: [Timetable[], Timetable[], Timetable[], Timetable[], Timetable[]]) {
    let dayList: string[] = [];
    for (var i = 0; i < tm?.length; i++) {
      if (tm[i].length > 0)
        dayList.push(tm[i][0].DAY);
    }
    return dayList;
  }


  CreatePdf(std: { F_NM, SE_NM, M_ID, T_NO, SUB_COMB, ROLNO, NM }, days, tm: [Timetable[], Timetable[], Timetable[], Timetable[], Timetable[]]) {
    var body = [];
    var header = [];
    if (days.length == 0) {
      this.toastr.warning('Time Table Does Not Exist')
      return;
    }
    this.toastr.info("Downloading TimeTable");
    var image = new Image();
    image.src = '../../../assets/images/logo3.png';
    const doc = new jsPDF('p', 'mm', [297, 210]);
    doc.addImage(image, 15, 1, 20, 20);
    var exportDate = this.datePipe.transform(new Date(), 'MMM d, y')
    doc.setFontSize(7);
    doc.text(`${exportDate}`, 18, 24);
    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.text("GC UNIVERSITY LAHORE", 40, 8);
    doc.text("Time Table", 40, 14);
    doc.setFontSize(11);

    let clas = std.M_ID;
    let sess = std.SE_NM || '';
    let SUB_COMB = std.SUB_COMB || '';
    let nam = std.NM.toUpperCase();
    let fnam = std.F_NM.toUpperCase();
    let rol = std.ROLNO.toUpperCase();
    let t_no = std.T_NO || '';
    doc.text(clas, 40, 19);
    doc.setFont('Arial', 'normal');
    doc.text('Session: ' + sess, 40, 24);
    if (this.formRef.value.c_code == '1') {
      doc.text("Year : 1", 80, 24);
    } else {
      doc.text("Semester: " + t_no, 80, 24);
    }
    doc.text("Subject Combination: " + SUB_COMB, 40, 29);
    doc.setFont('Arial', 'bold');
    doc.setFontSize(10);
    doc.text("Roll No: " + rol, 135, 8);
    doc.text("Name: " + nam, 135, 13);
    doc.text("Father Name: " + fnam, 135, 18);
    var y_val = 0;
    for (var i = 0; i < days.length; i++) {//DAYS
      header.push(
        [
          { content: days[i], colSpan: 7, styles: { halign: 'left', textColor: [0, 0, 0], fontSize: 7, lineColor: [0, 0, 0], fillColor: [256, 256, 256] } }
        ],
        [
          'Time', 'Block', 'Room', 'SubCode', 'Subject Name', 'Teacher Name', 'Sec'

        ]


      );
      y_val = tm[i].length + 2;

      for (var j = 0; j < tm.length; j++) {//MAJOR ARRAY
        for (var k = 0; k < tm[j].length; k++) {//INNER ARRAY

          if (days[i] == tm[j][k]?.DAY) {
            var obj = tm[j][k];
            var tt = moment(obj?.START_TIME, 'h:mmA').format('hh:mmA') + '-' + moment(obj?.END_TIME, 'h:mmA').format('hh:mmA');

            body.push([tt, obj?.BLK_NM, obj?.ROOM_NM, obj?.SUB_CODE,
              obj?.SUB_NM, obj?.FM_NAME, obj?.SECTION]);
          }
        }
      }
      autoTable(doc, {
        styles: {
          textColor: [0, 0, 0],
          fontSize: 6,
          minCellHeight: 4,
          cellPadding: 1
        },
        theme: 'grid',
        margin: { top: 30, bottom: 0 },
        head: header,
        body: body,
      });
      body = [];
      header = [];
    }

    var y_Axis = 265;
    var x_axis = 14;
    doc.text("Note :", x_axis, y_Axis);
    doc.setFontSize(8).setFont('Times-Roman', 'Bold');
    doc.setTextColor(0, 0, 0);
    doc.text("1.Dars-e-Quran/Tutorial Group Meeting on Friday from 10:30 am to 11:00 am. There will be no Theory/Practical Class on Friday from 10:30 am to 11:10 am", x_axis, y_Axis + 4);
    doc.setTextColor(0, 0, 0);
    doc.text("2.The Duration of each class can be different for different subject depending upon their credit hours.", x_axis, y_Axis + 8);
    doc.text("3.Consult Concerned Chairperson if Teacher is not in class room ", x_axis, y_Axis + 12);
    doc.text("4.In case any query, contact Prof. Inam ul Haq(Incharge General Time Table) in Statistics Department PGB-313 from 09:00 am to 10:30 am.", x_axis, y_Axis + 16);
    doc.text("5.Always keep a photocopy of time table in your possession during academic year, as no duplicate time table will be issued.", x_axis, y_Axis + 20);
    doc.text("* University reserves the right to amend Time Table at any time according to availability of faculty,space and combination.", x_axis, y_Axis + 24);


    doc.setFontSize(7).setFont('Times-Roman');
    doc.setFont('Arial', 'normal');
    doc.text("By:Directorate of Information Technology", 15, 295);
    doc.text('Note: Errors and Omissions are Excepted', 155, 295);


    var a = this.toastr;
    setTimeout(function () {
      a.success("Downloaded");
    }, 1000);


    doc.setProperties({
      title: `Timetable-${std.ROLNO}`,

    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }


  async CreatePdfAll(std: { F_NM, SE_NM, M_ID, T_NO, SUB_COMB, ROLNO, NM }, days, tm: [Timetable[], Timetable[], Timetable[], Timetable[], Timetable[]]) {
    var body = [];
    var header = [];

    if (days.length == 0) {
      this.toastr.warning('Time Table Not Exist.')
      return;
    }
    // this.toastr.info("Downloading TimeTable");
    var image = new Image();
    image.src = '../../../assets/images/logo3.png';
    const doc = new jsPDF('p', 'mm', [297, 210]);
    doc.addImage(image, 15, 1, 20, 20);
    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y')
    doc.setFontSize(7);
    doc.text(`${exportDate}`, 18, 24);
    doc.setFontSize(13);
    doc.setFont('Arial', 'bold');
    doc.text("GC UNIVERSITY LAHORE", 40, 8);
    doc.text("Time Table", 40, 14);
    doc.setFontSize(11);

    let clas = std.M_ID;
    let sess = std.SE_NM || '';
    let SUB_COMB = std.SUB_COMB || '';
    let nam = std.NM.toUpperCase();
    let fnam = std.F_NM.toUpperCase();
    let rol = std.ROLNO.toUpperCase();
    let t_no = std.T_NO || '';
    doc.text(clas, 40, 19);
    doc.setFont('Arial', 'normal');
    doc.text('Session: ' + sess, 40, 24);
    if (this.formRef.value.c_code === '1') {
      doc.text("Year : " + t_no, 80, 24);
    } else {
      doc.text("Semester: " + t_no || "", 80, 24);
    }
    doc.text("Subject Combination: " + SUB_COMB, 40, 29);
    doc.setFont('Arial', 'bold');
    doc.setFontSize(10);
    doc.text("Roll No: " + rol, 135, 8);
    doc.text("Name: " + nam.toUpperCase(), 135, 13);
    doc.text("Father Name: " + fnam.toUpperCase(), 135, 18);
    var y_val = 0;
    for (var i = 0; i < days.length; i++) {//DAYS
      header.push(
        [
          { content: days[i], colSpan: 7, styles: { halign: 'left', textColor: [0, 0, 0], fontSize: 7, lineColor: [0, 0, 0], fillColor: [256, 256, 256] } }
        ],
        [
          'Time', 'Block', 'Room', 'SubCode', 'Subject Name', 'Teacher Name', 'Sec'
        ]
      );
      y_val = tm[i].length + 2;

      for (var j = 0; j < tm.length; j++) {//MAJOR ARRAY
        for (var k = 0; k < tm[j].length; k++) {//INNER ARRAY

          if (days[i] == tm[j][k]?.DAY) {
            var obj = tm[j][k];
            var tt = moment(obj?.START_TIME, 'h:mmA').format('hh:mmA') + '-' + moment(obj?.END_TIME, 'h:mmA').format('hh:mmA');

            body.push([tt, obj?.BLK_NM, obj?.ROOM_NM, obj?.SUB_CODE,
              obj?.SUB_NM, obj?.FM_NAME, obj?.SECTION]);
          }
        }
      }
      autoTable(doc, {
        styles: {
          textColor: [0, 0, 0],
          fontSize: 6,
          minCellHeight: 4,
          cellPadding: 1
        },
        theme: 'grid',
        margin: { top: 30, bottom: 0 },
        head: header,
        body: body,
      });
      body = [];
      header = [];
    }

    var y_Axis = 265;
    var x_axis = 14;
    doc.text("Note :", x_axis, y_Axis);
    doc.setFontSize(8).setFont('Times-Roman', 'Bold');
    doc.setTextColor(0, 0, 0);
    doc.text("1.Dars-e-Quran/Tutorial Group Meeting on Friday from 10:30 am to 11:00 am. There will be no Theory/Practical Class on Friday from 10:30 am to 11:10 am", x_axis, y_Axis + 4);
    doc.setTextColor(0, 0, 0);
    doc.text("2.The Duration of each class can be different for different subject depending upon their credit hours.", x_axis, y_Axis + 8);
    doc.text("3.Consult Concerned Chairperson if Teacher is not in class room ", x_axis, y_Axis + 12);
    doc.text("4.In case any query, contact Prof. Inam ul Haq(Incharge General Time Table) in Statistics Department PGB-313 from 09:00 am to 10:30 am.", x_axis, y_Axis + 16);
    doc.text("5.Always keep a photocopy of time table in your possession during academic year, as no duplicate time table will be issued.", x_axis, y_Axis + 20);
    doc.text("* University reserves the right to amend Time Table at any time according to availability of faculty,space and combination.", x_axis, y_Axis + 24);


    doc.setFontSize(7).setFont('Times-Roman');
    doc.setFont('Arial', 'normal');
    doc.text("By:Directorate of Information Technology", 15, 295);
    doc.text('Note: Errors and Omissions are Excepted', 155, 295);

    const page = await doc.output('arraybuffer');
    return page;
  }


  async downloadAll() {

    const buffers = [];
    const actions = this.students.map(async student => {
      await this.getSTdTM(student)
        .then(async ({ std, day, tm }) => {
          // if (day?.lengtn > 0)
          {
            const buf = await this.CreatePdfAll(std, day, tm)
              .catch(err => {
                console.log(err);
              })
            if (buf) {
              buffers.push(buf)
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
    });

    await Promise.all(actions);
    await this.mergePdfs(buffers);
  }



  async mergePdfs(pages: ArrayBuffer[]) {
    const mergedPdf = await PDFDocument.create();
    const actions = pages.map(async pdfBuffer => {
      const pdf = await PDFDocument.load(pdfBuffer);
      const copiedPages = await mergedPdf.copyPages(pdf, [0]);
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    });
    await Promise.all(actions);

    const pdfBytes = await mergedPdf.save();

    saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), this.grp);
    // return pdfBytes;
  }

  getSTdTM(s) {
    return new Promise((resolve, reject) => {
      this.studentService.getTimeTableForPDF(s).subscribe(
        (res: [[{ f_nm, SE_NM, M_ID, T_NO, SUB_COMB }], Timetable[]]) => {
          const liveTimetable = res[1];
          const monday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('monday'));
          const tuesday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('tuesday'));
          const wednesday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('wednesday'));
          const thursday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('thursday'));
          const friday = liveTimetable?.filter(value1 => !!value1.DAY?.toLowerCase().match('friday'));
          let timetable: [Timetable[], Timetable[], Timetable[], Timetable[], Timetable[]];

          timetable = [monday, tuesday, wednesday, thursday, friday];

          const { f_nm: F_NM, SE_NM, M_ID, T_NO, SUB_COMB } = res[0][0]
          resolve({
            std: { F_NM, SE_NM, M_ID, T_NO, SUB_COMB, NM: s.NM.toUpperCase(), ROLNO: s.ROLNO.toUpperCase() },
            day: this.getDaysFromTM(timetable),
            tm: this.sortByStartTimeTable(timetable)
          })
          // this.CreatePdf({F_NM, SE_NM, M_ID, NM: s.NM, ROLNO: s.ROLNO }, this.getDaysFromTM(timetable),this.sortByStartTimeTable(timetable));
        }, err => {
          resolve({ std: null, day: null, tm: null });
        });
    })
  }

  Filter()
  {
    filter();
  }
}
