export class TimetableRoomModel {
    constructor(public sub_code:string,public grp: number,public tmId: number,
    public dId: number, public deptNm: string, public cNm: string,
    public se: number, public tNo: number, public section: string,
     public subject: string,  public fm: string,
      public day: string, public sTime: string, public eTime: string,  
      public blkId: string,public blkNm:string, public rmId:string, public rmNm: string
       ) {
    }
    // public fmId: string,
    // public status: number
}

// need status in room availabiltiy 
export class TimetableRoomModel2 {
    constructor(public sub_code:string,public grp: number,public tmId: number,
    public dId: number, public deptNm: string, public cNm: string,
    public se: number, public tNo: number, public section: string,
     public subject: string,  public fm: string,
      public day: string, public sTime: string, public eTime: string,  
      public blkId: string,public blkNm:string, public rmId:string, public rmNm: string,
      public status: number
      ) {
    }
    // public fmId: string
}