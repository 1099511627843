import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from '../../../../node_modules/chart.js/dist/Chart.js'
import { DashboardService } from './dashboard.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { jsPDF } from "jspdf";
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services';
import { reportsFeeService } from '../shared/services/reportsFee.service';
import { SessionService } from 'src/app/main/shared/services/Sessions.service';
import { ClassService } from '../shared/services/Class.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  animations: [
    SlideInFromLeft(),
  ]
})
export class DashboardComponent implements OnInit {

  constructor(private dbs: DashboardService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private authenticationService: AuthenticationService,
    private sessionService: SessionService,
    private feeReportService: reportsFeeService,
    private classService: ClassService,
  private location: Location) {
  }

  isAdmin: boolean;
  levelIDs: any[] = [];
  certificateButton: boolean = false;
  interButton: boolean = false;
  gradButton: boolean = false;
  masterButton: boolean = false;

  show_StdDetail: boolean = false;
  show_DuesCollection: boolean = false;
  show_SubjFailed: boolean = false;
  show_FacultyDeatil: boolean = false;
  isModalVisible: boolean = false;
  resetComponent: boolean = false;

  total_faculty: number = 0;
  currentIndex = 0;
  chart: any;
  intervalId: any;
  passFailData: any[] = [];
  subjFailData: any[] = [];
  sessions: any[] = [];
  session = 0;
  studentData: any = [];
  dept: any[] = [];
  totalAmount = 0;
  deptName  = "";
  loader: boolean = false;

  private usr = this.authenticationService.getUser();
  

  // +++++++++++++++++++++++++++++
  dptData: any = 0
  dptcount: any = 0
  dptcategorycount: any = 0
  blockscount: any = 0
  roomscount: any = 0
  Sdptcount: any = 0
  Adptcount: any = 0
  Ndptcount: any = 0
  Progcount: any = 0
  totalprogcount: any = 0
  ProgrammesData: any[] = [];
  showProgrammeData: boolean = false;
  showDepartmentData: boolean = false;
  showEmployeeData: boolean = false;
  showAccomodationData: boolean = false;
  AccomodationData: any[] = [];
  EmployeeData: any[] = [];
  EmployeeGroup: any[] = [];
  @ViewChild('fn') formRef: NgForm;

  // +++++++++++++++++++++++++++++  


  ngOnInit(): void {
    this.isAdmin = this.authenticationService.isAdmin() || this.authenticationService.isDashboard();
    this.getDept();
    this.getSessions()
    
    this.S_getLevelID();
    this.S_getPassFailRatio()
    this.getDepartmentCategoryCount()
    this.getRoomsandBlocksCount()
    this.getroomsblocksdptscount()
    this.gettstugcount()
    this.getprogrammecount()
    this.getprogcount()

    this.S_getSubjFailed()

    // this.generateempchart()
    // this.generateprogrammeenrollchart() 
    this.getEmployeeGroup()
  }

  async S_getPassFailRatio(){
    var user_dept = 0

    if (this.isAdmin) {
      user_dept = -1
    }else{
      user_dept = this.usr.D_ID;
    }
    var labels = [];
    var pass = [];
    var fail = [];
    var total = [];

    // this.dbs.S_getPassFailRatio(user_dept).subscribe((res :any) => {
    //   this.passFailData = res[0];
    //   res[0].forEach(element => {
    //     labels.push(String(element.PRINTTITLE))
    //     total.push(element.total_count)
    //     pass.push(element.pass_count)
    //     fail.push(element.fail_count)
    //   });
    //   console.log(res);
    // });

    const res = await this.dbs.S_getPassFailRatio(user_dept).toPromise();
    this.passFailData = res[0];
    this.displayChart(this.currentIndex);
    this.startAutoCycle();
  }

  S_getSubjFailed(){
    this.subjFailData = []

    this.dbs.S_getSubjFailed(this.usr.D_ID).subscribe((res: any) => {
      // console.log(res)
      this.subjFailData = res[0];
    })
    
  }

  getFeeDetail(){

    this.loader = true;
    this.studentData = [];
    const yearSelect = document.getElementById('year') as HTMLSelectElement;
    this.session = Number(yearSelect.value);
    this.feeReportService.getUnpaidChallans(this.usr.D_ID, this.session).subscribe(
      (data:any)=>{
        if (!data) {
          this.toastr.error("Unknown Error!");
        }
        else {
          this.studentData = data;
          this.loader = false;
          this.calculateTotalAmount();
          this.getDeptName(this.usr.D_ID)
        }
      }, err => {
        console.log(err);
        this.toastr.error("Unknown Error!");
      });
  }

  calculateTotalAmount() {
    this.totalAmount = 0;
    this.studentData.forEach((student) => {
      this.totalAmount += Number(student.pending_amount);
    });
  }

  getDept(){
    this.dept = []
    this.classService.getDept().subscribe((result: any) =>{
      this.dept = result;
      this.getDeptName(this.usr.D_ID);
    },
    err => {
      console.log(err);
      this.toastr.error("Unknown Error!");
    }
  )

  }

  getDeptName (d_id: number){
    this.dept.forEach((dept)=>{
      if(dept.D_id == d_id){
        this.deptName = dept.D_NM;
      }
    })
  }

  getSessions(){
    this.sessionService.get_Sessions().subscribe((res: any) => {
      this.sessions = res[0];
    });
  }

  closeIt() {
    this.show_StdDetail = false;
    this.show_FacultyDeatil = false;
  }

  showDetail(mode: number) {
    
    if (mode == 1) {
      this.show_StdDetail = true;
      this.show_FacultyDeatil = false;
      this.show_SubjFailed = false;
      this.show_DuesCollection = false;

    }else if (mode == 2) {

      this.show_FacultyDeatil = true;
      this.show_SubjFailed = false;
      this.show_StdDetail = false;
      this.show_DuesCollection = false;
    }else if (mode == 3) {

      this.show_SubjFailed = true;
      this.show_StdDetail = false;
      this.show_FacultyDeatil = false;
      this.show_DuesCollection = false;

    }else if (mode == 4) {

        this.show_DuesCollection = true;
        this.show_SubjFailed = false;
        this.show_StdDetail = false;
        this.show_FacultyDeatil = false;
      }

    this.showModal();
  }

  showModal() {
    this.resetComponent = false;  // Ensure the component is removed first
    setTimeout(() => {
      this.resetComponent = true; // Add the component back
      this.isModalVisible = true;
    }, 0);  // A small delay to ensure the DOM update occurs
  }

  hideModal(): void {
    this.isModalVisible = false;
    // this.location.back();
  }

  async S_getLevelID(){
    this.levelIDs = [];

    if (this.isAdmin) {
      const res = await this.dbs.S_getLevelID(-1).toPromise();
        // console.log(res, "Response");
        res[0]?.forEach(entry => {
          this.levelIDs.push(entry.LVLID);
        });
      // this.levelIDs = res[0];

      // this.dbs.S_getLevelID(-1).subscribe((res: any) => {
      //   console.log(res[0]);
      //   this.levelIDs = res[0];
      // });
    }else{
      const res = await this.dbs.S_getLevelID(this.usr.D_ID).toPromise();
      res[0]?.forEach(entry => {
        this.levelIDs.push(entry.LVLID);
      });
      // this.levelIDs = res[0];
      // this.dbs.S_getLevelID(this.usr.D_ID).subscribe((res: any) => {
      //   // console.log(res)
      //   this.levelIDs = res[0];
      // });
    }

    // console.log(this.levelIDs, "Data");
    this.showButtons();
  }

  showButtons(){
    this.certificateButton = this.levelIDs.includes(0);
    this.interButton = this.levelIDs.includes(1);
    this.gradButton = this.levelIDs.includes(2);
    this.masterButton = this.levelIDs.some(id => id >= 3 && id <= 6);

    // console.log(this.levelIDs,"HOoooo");

    // console.log(this.certificateButton, this.interButton, this.gradButton, this.masterButton);
  }

  displayChart(index: number) {
    const element = this.passFailData[index];
    const labels = ["Total", "Pass", "Fail"];
    const data = [element.total_count, element.pass_count, element.fail_count];

    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart('passFailchart', {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [{
          label: 'Pass/Fail Ratio',
          data: data,
          backgroundColor: ['#003970', '#1579D1', '#78C2FF'],
          borderColor: ['#003970', '#1579D1', '#78C2FF'],
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        animation: {
          animateScale: true,
          animateRotate: true
        },
        legend: {
          position: 'bottom',
          labels: {
            fontColor: 'black'
          }
        }
      }
    });
  }

  startAutoCycle() {
    this.intervalId = setInterval(() => {
      this.nextRecord();
    }, 2000);
  }

  pauseCycle() {
    clearInterval(this.intervalId);
  }

  resumeCycle() {
    this.startAutoCycle();
  }

  nextRecord() {
    this.currentIndex = (this.currentIndex + 1) % this.passFailData.length;
    this.displayChart(this.currentIndex);
  }

  prevRecord() {
    this.currentIndex = (this.currentIndex - 1 + this.passFailData.length) % this.passFailData.length;
    this.displayChart(this.currentIndex);
  }


  generateprogrammechart(p: any, c: any) {
    // const colors = p.map(label => this.getColorByLabelLength(label));

    var enrollmentchart = new Chart('programmechart', {
      type: 'doughnut',
      data: {
        labels: p,

        datasets: [{
          label: 'Categories of Programmes',
          data: c,
          backgroundColor: ['#003970', '#1579D1', '#78C2FF','#0A2440', '#293F50', '#4682B4', '#B2D7FF', '#E0F2FF'],
          borderColor: ['#003970', '#1579D1', '#78C2FF','#0A2440', '#293F50', '#4682B4', '#B2D7FF', '#E0F2FF'],
          borderWidth: 1,
        }]
      },
      options: {
        legend: {
          position: 'right',
          labels: {
            fontColor: 'black',
          }
        },
      }
    });
  }

  getColorByLabelLength(label) {
    const colorPalette = [
      'Maroon', '#DAA520', '#4169e1', '#FF9933', '#0099CC', '#CC0000', '#66B3FF', 
      '#99FF99', '#FFCC99', '#C2C2F0', '#F0C2C2', '#C2F0F0','#F0D9B5', '#E74C3C',
      '#8E44AD', '#2CA02C', '#D298FB', '#949FB1', '#4D697A', '#9AECDB', '#F59E0B',
      '#E74C3C', '#1BBC9B', '#9EBEC0', '#79D2CA', '#C2F0F0',
    ];
    const labelLength = label.length;
    const colorIndex = labelLength % colorPalette.length;
  
    if (colorIndex >= colorPalette.length) {
      console.warn('Label length exceeds color palette size. Using default color.');
      return 'gray';
    }
  
    return colorPalette[colorIndex];
  }

  generateempchart(labels: any, count:any) {
    const colors = labels.map(label => this.getColorByLabelLength(label));
    var employeechart = new Chart('employeechart', {
      type: 'doughnut',
      data: {
        labels: this.isAdmin ? [] : labels,
        datasets: [{
          label: 'Categories of Employees',
          data: count,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          position: 'right',
          labels: {
            fontColor: 'black',
          }
        },
      }
    });
  }

  generatedptchart(S: number, A: number, N: number) {
    var departmentchart = new Chart('departmentchart', {
      type: 'doughnut',
      data: {
        labels: ['Academic', 'Non-Academic', 'Self Supporting'],
        datasets: [{
          label: 'Categories of Departments',
          data: [A, N, S],
          backgroundColor: [
            '#003970', '#1579D1', '#78C2FF'
          ],
          borderColor: [
            '#003970', '#1579D1', '#78C2FF'
          ],
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          position: 'right',
          labels: {

            fontColor: 'black',
          }

        },
      }
    });
  }
  generateaccchart(B: number, R: number) {
    var departmentchart = new Chart('accomodationchart', {
      type: 'doughnut',
      data: {
        labels: ['Buildings', 'Rooms'],
        datasets: [{
          label: 'Categories of Accomodation',
          data: [B, R],
          backgroundColor: [
            'Maroon',
            '#DAA520'
          ],
          borderColor: [
            'Maroon',
            '#DAA520'
          ],
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          position: 'right',
          labels: {
            fontColor: 'black',
          }
        },
      }
    });
  }
  generategenderchart(l: any, t: any, m: any, f: any) {
    var genderchart = new Chart('genderchart', {
      type: 'bar',
      data: {
        labels: l,
        datasets: [
          {
            label: 'Total',
            data: t,
            borderWidth: 1,
            backgroundColor: '#003970'
          }, 
          {
            label: 'Male',
            data: m,
            borderWidth: 1,
            backgroundColor: '#1579D1'
          },
          {
            label: 'Female',
            data: f,
            borderWidth: 1,
            backgroundColor: '#78C2FF'
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
        }
      }
    });
  }

  generateprogrammeenrollchart() {
    var progenrollchart = new Chart('progenrollchart', {
      type: 'bar',
      data: {
        labels: [
          '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'
        ],
        datasets: [
          {
            label: 'Phd',
            data: [12, 2, 13, 34, 54, 23, 34, 54],
            borderWidth: 1,
            backgroundColor: 'Maroon'
          },
          {
            label: 'Masters',
            data: [14, 18, 22, 12, 32, 43, 34, 54],
            borderWidth: 1,
            backgroundColor: '#DAA520'
          },
          {
            label: 'Bachelors',
            data: [12, 2, 13, 43, 23, 54, 76, 43],
            borderWidth: 1,
            backgroundColor: '#4169e1'
          },
          {
            label: 'Intermediate',
            data: [12, 2, 13, 43, 23, 54, 76, 43],
            borderWidth: 1,
            backgroundColor: '#381345'
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }],
        }
      }
    });
  }


  getDepartment() {
    this.dbs.getdepartment().subscribe(res => {
      this.dptData = res;
      // Replace category values
      this.dptData.forEach(item => {
        if (item.Category === 'S') {
          item.Category = 'Self Supporting';
        } else if (item.Category === 'R') {
          item.Category = 'Regular';
        } else if (item.Category === 'N') {
          item.Category = 'Non-Academic';
        }
        // Add more conditions if needed
      });
    });
    this.showDepartmentDataFunction();
  }

  showDepartmentDataFunction() {
    this.showDepartmentData = true;
    this.showProgrammeData = false;
    this.showEmployeeData = false;
    this.showAccomodationData = false;
  }

  getDepartmentCategoryCount() {
    this.dbs.getdepartmentcategorycount().subscribe(res => {
      this.dptcategorycount = res
      this.Sdptcount = this.dptcategorycount[0].count
      this.Adptcount = this.dptcategorycount[1].count
      this.Ndptcount = this.dptcategorycount[2].count

      //S A N       
      this.generatedptchart(this.Sdptcount, this.Adptcount, this.Ndptcount)

    });
  }
  getRoomsandBlocksCount() {
    this.dbs.getroomsandblockscount().subscribe(res => {
      this.roomscount = res[0][0].Rooms
      this.blockscount = res[1][0].Blocks
      this.generateaccchart(this.blockscount, this.roomscount)
    });

  }

  getroomsblocksdptscount() {
    this.dbs.getroomsblocksdptscount().subscribe(res => {
      this.dptcount = res[2][0].Departments
    });
  }
  getprogrammecount() {

    var user_dept = 0

    if (this.isAdmin) {
      user_dept = -1
    }else{
      user_dept = this.usr.D_ID;
    }

      this.dbs.getprogrammecount(user_dept).subscribe(res => {
        // console.log(res, "Response")
        this.Progcount = res
        let labels = []
        let progcount = []
        res[0].forEach(element => {
          labels.push(String(element.Programme))
          progcount.push(element.Count)
        })
        this.generateprogrammechart(labels, progcount)
      });
    
  }


  getprogcount() {
    this.dbs.getprogcount().subscribe(res => {
      this.totalprogcount = res[0].Count
    });
  }
  gettstugcount() {

    var user_dept = 0;

    if (this.isAdmin) {
      user_dept = -1;
    }else{
      user_dept = this.usr.D_ID;
    }

    this.dbs.gettstugcount(user_dept).subscribe(res => {
      var labels = []
      var Stotal = []
      var MStotal = []
      var FStotal = []
      res[0].forEach(element => {
        labels.push(String(element.Year))
        Stotal.push(element.Total)
      });
      res[1].forEach(element => {
        MStotal.push((element.Male))
      });
      res[2].forEach(element => {
        FStotal.push((element.Female))
      });
      this.generategenderchart(labels, Stotal, MStotal, FStotal)
    });
  }


  getProgramsData() {
    this.dbs.getprogrammes().subscribe(res => {
      this.ProgrammesData = res[0];
    });
    this.showProgrammeDataFunction();
  }

  showProgrammeDataFunction() {
    this.showProgrammeData = true;
    this.showDepartmentData = false;
    this.showEmployeeData = false;
    this.showAccomodationData = false;
  }

  CreatePDF() {
    if (this.studentData?.length == 0) {
      this.toastr.warning("No Record Found");
      // this.loader = false
      return;
    }
    else {
      this.toastr.success("Generating PDF")
      const body = [];
      const header = [];
      for (let i = 0; i < this.studentData.length; i++) {
        body.push([
          { content: i + 1, styles: { fontStyle: "bold", halign: 'center' } },
          { content: this.studentData[i].nm, styles: { fontStyle: "bold" } },
          { content: this.studentData[i].rolno, styles: { fontStyle: "bold", halign: 'center' } },
          this.studentData[i].major,
          this.studentData[i].Unpaid_Challans,
          this.studentData[i].pending_amount
        ]);
      }

      body.push([
        { content: 'Total Pending Amount', colSpan: 5, styles: { halign: 'center', fontStyle: 'bold' } },
        { content: this.totalAmount, styles: { halign: 'center', fontStyle: 'bold' } }
      ]);
      var exportDate = this.datePipe.transform(new Date(), 'MMM d, y')
      var image = new Image();
      var page;
      var dept = this.deptName;
      var sessionVar = this.session;
      image.src = '../../../assets/images/logo3.png';
      // if (this.subOrNot == 1)
        header.push(['Sr#', 'Name', 'Roll#', 'Major', 'Unpaid Challans', 'Pending Amount']);
      // else
      //   header.push(['Sr#', 'Challan#', 'Form/Roll#', 'Major', 'Inst#', 'Name', 'Father Name', 'Due Date']);
      const doc = new jsPDF('p', 'mm', 'A4');
      let y = 60;
      autoTable(doc, {
        theme: 'grid',
        startY: y,
        margin: { top: 50, bottom: 10, left: 7, right: 7 },
        head: header,
        body: body,
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0], // sets the text color of the header to black
          lineColor: [0, 0, 0],
          lineWidth: 0.1, // sets the width of the border to 0.2
          fontSize: 8,
          fontStyle: 'bold'
        }, // sets the border color of the header to black
        bodyStyles: {
          lineWidth: 0.1, // sets the width of the border to 0.2
          lineColor: [0, 0, 0], // sets the color of the border to black
          fontSize: 7,
        },
        columnStyles: {
          0: { cellWidth: 10 },
          // 1: { cellWidth: 16 }
        },
        didDrawPage: function () {
          let height = 25;
          doc.addImage(image, 175, 10, 25, 30);
          doc.setFontSize(10);
          doc.text(`${exportDate}`, 177, 45);
          doc.setFontSize(20);
          doc.setFont('Helvetica', 'bold');
          doc.text("GC UNIVERSITY, LAHORE", 105, height, { align: 'center' });
          doc.setFontSize(14);
          doc.setFont('Helvetica', 'bold');
          doc.text("PENDING DUES REPORT", 105, height + 9, { align: 'center' });
          doc.text("______________________", 105, height + 9, { align: 'center' });
          
          doc.setFontSize(12);
          // doc.text("Merit list Summary", 150, 26, { align: 'center' });
          doc.text("DEPARTMENT: " + dept, 105, height + 17, { align: 'center' });
          // doc.text("MAJOR: "+majVar, 105, height+12, { align: 'center' });
          doc.text("SESSION: " + sessionVar, 105, height + 24.5, { align: 'center' });
          page = '';
          page = doc.getNumberOfPages();
        }
      });
      var l = (body.length + 65) % 26;
      doc.setFontSize(7);
      for (var i = 1; i <= page; i++) {
        doc.setPage(i);
        doc.text('Page ' + i + " of " + page, 99, 294);
        doc.text("Directorate of Information & Technology", 10, 295)
        doc.text("Note:- Errors and Omissions Excepted", 150, 295);
        var p = body.length;
      }
      window.open(URL.createObjectURL(doc.output('blob')), '_blank');
    }
  }

  PDF_Programme() {
    this.toastr.info('Generating pdf...', null, { timeOut: 120000 });

    const body = [];
    const header = [];

    let k = 0;
    for (let i = 0; i < this.ProgrammesData.length; i++) {
      k++;
      body.push([k,
        this.ProgrammesData[i].Category,
        this.ProgrammesData[i].Degree,
      ]);
    }
    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y')
    var image = new Image();
    var page;


    image.src = '../../../assets/images/logo3.png';
    header.push(['Sr #', 'Category', 'Degree Title']);
    const doc = new jsPDF();
    autoTable(doc, {
      margin: { top: 48, bottom: 10 },
      styles: { fontSize: 10 },
      head: header,
      body: body,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontSize: 9.5,
        fontStyle: 'bold',
        halign: 'left'
      },
      bodyStyles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        fontSize: 9.2,

      },
      columnStyles: {
        0: { halign: "center" },
        1: { halign: "left" },
        2: { halign: "left" },
      },
      didDrawPage: function () {
        doc.addImage(image, 177, 10, 25, 25);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 179, 40);
        doc.setFontSize(22);
        doc.setFont('Lobster');
        doc.text("GC UNIVERSITY LAHORE", 100, 25, { align: 'center' });
        doc.setFontSize(18);
        doc.text("Office of the Controller of Examinations", 100, 35, { align: 'center' });
        doc.setFontSize(18);
        doc.text("Programmes", 100, 44, { align: 'center' });
        doc.setFontSize(10);
        page = doc.getNumberOfPages();
        doc.setFontSize(12);
        doc.text("Note:- Errors and Omissions Excepted", 135, 288);
        doc.text("Directorate of Information Technology", 8, 288);
      }
    });
    var l = (body.length + 65) % 26;
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 90, 288);
    }
    this.toastr.clear();
    //doc.save(`StudentCount `);
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');

  }

  PDF_Departments() {
    this.toastr.info('Generating pdf...', null, { timeOut: 120000 });

    const body = [];
    const header = [];

    let k = 0;
    for (let i = 0; i < this.dptData.length; i++) {
      k++;
      body.push([k,
        this.dptData[i].ID,
        this.dptData[i].Name,
        this.dptData[i].CategoryLabel,
      ]);
    }
    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y')
    var image = new Image();
    var page;


    image.src = '../../../assets/images/logo3.png';
    header.push(['Sr #', 'Department ID', 'Department Name', 'Category']);
    const doc = new jsPDF();
    autoTable(doc, {
      margin: { top: 48, bottom: 10 },
      styles: { fontSize: 10 },
      head: header,
      body: body,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontSize: 9.5,
        fontStyle: 'bold',
        halign: 'left'
      },
      bodyStyles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        fontSize: 9.2,

      },
      columnStyles: {
        0: { halign: "center" },
        1: { halign: "left" },
        2: { halign: "left" },
        3: { halign: "left" },
      },
      didDrawPage: function () {
        doc.addImage(image, 177, 10, 25, 25);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 179, 40);
        doc.setFontSize(22);
        doc.setFont('Lobster');
        doc.text("GC UNIVERSITY LAHORE", 100, 25, { align: 'center' });
        doc.setFontSize(18);
        doc.text("Office of the Controller of Examinations", 100, 35, { align: 'center' });
        doc.setFontSize(18);
        doc.text("Departments", 100, 44, { align: 'center' });
        doc.setFontSize(10);
        page = doc.getNumberOfPages();
        doc.setFontSize(12);
        doc.text("Note:- Errors and Omissions Excepted", 135, 288);
        doc.text("Directorate of Information Technology", 8, 288);
      }
    });
    var l = (body.length + 65) % 26;
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 90, 288);
    }
    this.toastr.clear();
    //doc.save(`StudentCount `);
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');

  }

  showAccomodationDataFunction() {
    this.showAccomodationData = true;
    this.showDepartmentData = false;
    this.showProgrammeData = false;
    this.showEmployeeData = false;
  }

  getBlocks() {

    this.dbs.getroomsinblockscount().subscribe((res: any) => {
      this.AccomodationData = res;
    });
    this.showAccomodationDataFunction();
  }

  PDF_Accomodation() {
    this.toastr.info('Generating pdf...', null, { timeOut: 120000 });

    const body = [];
    const header = [];

    let k = 0;
    for (let i = 0; i < this.AccomodationData.length; i++) {
      k++;
      body.push([k,
        this.AccomodationData[i].ID,
        this.AccomodationData[i].Building,
        this.AccomodationData[i].Count,
      ]);
    }
    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y')
    var image = new Image();
    var page;


    image.src = '../../../assets/images/logo3.png';
    header.push(['Sr #', 'Block ID', 'Block Name', 'Rooms']);
    const doc = new jsPDF();
    autoTable(doc, {
      margin: { top: 48, bottom: 10 },
      styles: { fontSize: 10 },
      head: header,
      body: body,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontSize: 9.5,
        fontStyle: 'bold',
        halign: 'left'
      },
      bodyStyles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        fontSize: 9.2,

      },
      columnStyles: {
        0: { halign: "center" },
        1: { halign: "left" },
        2: { halign: "left" },
        3: { halign: "left" },
      },
      didDrawPage: function () {
        doc.addImage(image, 177, 10, 25, 25);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 179, 40);
        doc.setFontSize(22);
        doc.setFont('Lobster');
        doc.text("GC UNIVERSITY LAHORE", 100, 25, { align: 'center' });
        doc.setFontSize(18);
        doc.text("Office of the Controller of Examinations", 100, 35, { align: 'center' });
        doc.setFontSize(18);
        doc.text("Accomodation", 100, 44, { align: 'center' });
        doc.setFontSize(10);
        page = doc.getNumberOfPages();
        doc.setFontSize(12);
        doc.text("Note:- Errors and Omissions Excepted", 135, 288);
        doc.text("Directorate of Information Technology", 8, 288);
      }
    });
    var l = (body.length + 65) % 26;
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 90, 288);
    }
    this.toastr.clear();
    //doc.save(`StudentCount `);
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
    // this.AccomodationData = [];
  }

  getEmployeeGroup() {
    // console.log(this.isAdmin, "Role")

    var labels = []
    var count = []
    this.total_faculty = 0;

    if (this.isAdmin) {
      this.dbs.getEmployeeForGroup(-1).subscribe((res: any) => {
        this.EmployeeGroup = res;

        res.forEach(element => {
          labels.push(String(element.DES))
          count.push(element.count)
          this.total_faculty += element.count;
        });

    // console.log(labels, count)


        this.generateempchart(labels, count);

      });
    } else {

      this.dbs.getEmployeeForGroup(this.usr.D_ID).subscribe((res: any) => {
        this.EmployeeGroup = res;
        res.forEach(element => {
          labels.push(String(element.DES))
          count.push(element.count)
          this.total_faculty += element.count;
        });

        this.generateempchart(labels, count);

      });
    }

  }

  showEmployeeDataFunction() {
    this.showEmployeeData = true;
    this.showDepartmentData = false;
    this.showProgrammeData = false;
    this.showAccomodationData = false;
  }

  async getEmployee(faculty_type: any) {
    console.log(faculty_type)
    // const { faculty_type } = this.formRef.value;
    this.EmployeeData = []
    var user_dept = 0;
    if (this.isAdmin) {
      user_dept = -1;
    }else{
      user_dept = this.usr.D_ID;
    }

    try {
      this.EmployeeData = await this.dbs.getEmployeeWithNameDashboard(faculty_type, user_dept).toPromise() as any[];
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }

    // this.dbs.getEmployeeWithNameDashboard(faculty_type, user_dept).subscribe((res: any) => {
    //   this.EmployeeData = res;
    // });
    this.showEmployeeDataFunction();

    this.PDF_Employee();
  }

  PDF_Employee() {
    this.toastr.info('Generating pdf...', null, { timeOut: 120000 });

    const body = [];
    const header = [];

    let deptname = this.EmployeeData[0].Department;


    let k = 0;
    for (let i = 0; i < this.EmployeeData.length; i++) {
      k++;
      body.push([k,
        this.EmployeeData[i].FM_id,
        this.EmployeeData[i].NM,
        this.EmployeeData[i].DES,
        this.EmployeeData[i].Department,
        this.EmployeeData[i].NIC,
        this.EmployeeData[i].PH1,
        this.EmployeeData[i].EMAIL,
      ]);
    }
    var exportDate = this.datePipe.transform(new Date(), 'MMM d,y')
    var image = new Image();
    var page;
    // const { maj_id } = this.formRef.value;


    image.src = '../../../assets/images/logo3.png';
    header.push(['Sr #', 'FM_id', 'Name', 'Designation', 'Department', 'NIC', 'Phone Number', 'Email']);
    const doc = new jsPDF();
    autoTable(doc, {
      margin: { top: 48, bottom: 13 },
      styles: { fontSize: 9 },
      head: header,
      body: body,
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontSize: 9.5,
        fontStyle: 'bold',
        halign: 'left'
      },
      bodyStyles: {
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
        fontSize: 8,

      },
      columnStyles: {
        0: { halign: "center" },
        1: { halign: "left" },
        2: { halign: "left" },
        3: { halign: "left" },
      },
      didDrawPage: function () {
        doc.addImage(image, 177, 10, 25, 25);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 179, 40);
        doc.setFontSize(24);
        doc.setFont('Lobster');
        doc.text("GC UNIVERSITY LAHORE", 100, 25, { align: 'center' });
        doc.setFontSize(14);
        doc.text("DEPARTMENT OF " + deptname, 100, 35, { align: 'center' });
        doc.setFontSize(18);
        // doc.text(`${this.deptName}`, 100, 44, { align: 'center' });
        doc.setFontSize(10);
        page = doc.getNumberOfPages();
        doc.setFontSize(12);
        doc.text("Note:- Errors and Omissions Excepted", 135, 288);
        doc.text("Directorate of Information Technology", 8, 288);
      }
    });
    var l = (body.length + 65) % 26;
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.text('Page ' + i + " of " + page, 90, 288);
    }
    this.toastr.clear();
    //doc.save(`StudentCount `);
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }



}