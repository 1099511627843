import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import { CollectionReportRoutingModule } from './collection-report-routing.module';
import { ClassWiseCollectionReportComponent } from './class-wise-collection-report/class-wise-collection-report.component';
import { GroupBaseWiseCollectionReportComponent } from './group-base-wise-collection-report/group-base-wise-collection-report.component';
import { StudentWiseCollectionReportComponent } from './student-wise-collection-report/student-wise-collection-report.component';
import { ClassBaseWiseCollectionReportComponent } from './class-base-wise-collection-report/class-base-wise-collection-report.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DemandCollectionReportClassWiseComponent } from './demand-collection-report-class-wise/demand-collection-report-class-wise.component';
import { PendingDuesComponent } from './pending-dues/pending-dues.component';
import { LevelWiseReportComponent } from './level-wise-report/level-wise-report.component';
import { LoaderModule } from '../loader/loader.module';

@NgModule({
  declarations: [ClassWiseCollectionReportComponent, 
    GroupBaseWiseCollectionReportComponent, 
    StudentWiseCollectionReportComponent, 
    ClassBaseWiseCollectionReportComponent,
    DemandCollectionReportClassWiseComponent,
    PendingDuesComponent,
    LevelWiseReportComponent],
  imports: [
    CommonModule,
    CollectionReportRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    LoaderModule
  ],  
  exports: [
    GroupBaseWiseCollectionReportComponent 
  ],
})
export class CollectionReportModule { }
