import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { SelectList } from '../../shared/model/SelectList.model';
import { reportsFeeService } from '../../shared/services/reportsFee.service';
import { jsPDF } from 'jspdf';
import { DatePipe } from "@angular/common";
import autoTable from "jspdf-autotable";
import "jspdf-autotable";
import * as moment from 'moment';
import { FeeService } from '../../shared/services/Fee.service';
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';

@Component({
  selector: 'app-date-extension-report',
  templateUrl: './date-extension-report.component.html',
  styleUrls: ['./date-extension-report.component.css'],
  animations: [SlideInFromLeft()]
})

export class DateExtensionReportComponent implements OnInit {
  isAdmin: boolean = false;
  sessions: any;
  allData: Array<{ ch_no, rolno, name, dt_extension, ext_des }>;
  @ViewChild('f') formRef: NgForm;
  classDT: Array<SelectList>;
  instNo: Array<number>;
  constructor(
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private reportsFee: reportsFeeService,
    private feeService: FeeService,
    private feeSharedService: FeeSharedDataService
  ) {
    this.classDT = Array<SelectList>();
    this.allData = Array<{ ch_no, rolno, name, dt_extension, ext_des }>();
    this.instNo = new Array<number>();
  }

  ngOnInit(): void {
   this.updateClasses();
   this.updateInstNO();
   this.updateSessions()
  }

  updateClasses() {
    this.classDT = [];
    this.feeSharedService.getFeeClasses()
      .then(classes => {
        this.classDT = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  OnSubmit() {}

  getData() {
    this.allData = [];
    this.reportsFee.onIntallmentExtension(this.formRef.value).subscribe((res: { ch_no: any, rolno: string, nm: string, dt_ext: string, ext_des }[]) => {
      res?.forEach(element => {
        this.allData.push({ ch_no: element.ch_no, rolno: element.rolno, name: element.nm, dt_extension: moment(element.dt_ext).format('DD-MMM-YYYY'), ext_des: element.ext_des });
      });
    });
  }

  updateSessions() {
    this.sessions = []
    this.feeSharedService.getFeeSessions()
      .then(sessions => {
        this.sessions = sessions;
      })
      .catch(error => {
        console.error("Error getting fee session", error);
      });
  }

  updateInstNO() {
    this.instNo = []
    this.feeSharedService.getFeeInst()
    .then(inst => {
      this.instNo = inst;
    })
    .catch(error => {
      console.error("Error getting inst", error);
    });
  }

  onPdfDownload(c: HTMLSelectElement) {
    if (this.allData?.length == 0) {
      this.toastr.warning("No Record Found");
      return;
    }
    else {
      const body = [];
      const header = [];
      var exportDate = this.datePipe.transform(new Date(), 'MMM d, y')
      var image = new Image();
      var page;
      image.src = '../../../assets/images/logo3.png';
      var sessionYear = this.formRef.value.year;
      var inst = this.formRef.value.inst_no;
      var groupClass = this.classDT[c.selectedIndex].nm;
      header.push([
        { content: 'Sr #', styles: { fontStyle: "bold", halign: 'center' } },
        { content: 'Roll/Form #', styles: { fontStyle: "bold", halign: 'center' } },
        { content: 'Name', styles: { fontStyle: "bold" } },
        { content: 'Date Extension', styles: { fontStyle: "bold", halign: 'center' } },
        { content: 'Description', styles: { fontStyle: "bold" } }]);

      const doc = new jsPDF('p', 'mm', 'A4');//

      for (let i = 0; i < this.allData.length; i++) {
        body.push([
          { content: i + 1, styles: { fontStyle: 'bold', halign: 'center' } },
          { content: this.allData[i].rolno, styles: { fontStyle: 'bold', halign: 'center' } },
          { content: this.allData[i].name.toUpperCase() },
          { content: this.allData[i].dt_extension, styles: { halign: 'center' } },
          { content: this.allData[i].ext_des }
        ]);
      }//
      autoTable(doc, {
        theme: 'grid',
        startY: 57,
        margin: { top: 57, bottom: 10, left: 7, right: 7 },
        styles: { fontSize: 11 },
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
          fontSize: 8,
          fontStyle: 'bold'
        },
        bodyStyles: {
          lineWidth: 0.1,
          lineColor: [0, 0, 0],
          fontSize: 7.5
        },
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 23 },
          3: { cellWidth: 23 }
        },
        head: header,
        body: body,
        didDrawPage: function () {
          doc.addImage(image, 175, 10, 25, 30);
          doc.setFontSize(10);
          doc.text(`${exportDate}`, 176, 45);
          doc.setFontSize(20);
          doc.setFont('Helvetica', 'bold');
          doc.text("GC UNIVERSITY, LAHORE", 105, 20, { align: 'center' });
          doc.setFontSize(14);
          doc.setFont('Helvetica', 'bold');
          doc.text("DATE EXTENSION REPORT", 105, 29, { align: 'center' });
          doc.text("_________________________", 105, 30, { align: 'center' });
          doc.setFontSize(12);
          doc.text("CLASS: " + groupClass, 105, 38, { align: 'center' });
          doc.setFontSize(12);
          doc.text("INSTALLMENT: " + inst, 105, 45, { align: 'center' });
          doc.setFontSize(12);
          doc.text("SESSION: " + sessionYear, 105, 52, { align: 'center' });
          page = '';
          page = doc.getNumberOfPages();
        }
      });
      var l = (body.length + 65) % 26;
      doc.setFontSize(8);
      for (var i = 1; i <= page; i++) {
        doc.setPage(i);
        doc.text('Page ' + i + " of " + page, 100, 295);
        doc.text("Directorate of Information & Technology", 10, 295)
        doc.text("Note:- Errors and Omissions Excepted", 150, 295);
        var p = body.length;
      }
      //  doc.save('Installment_Date_Extension('+sessionYear+')');
      window.open(URL.createObjectURL(doc.output('blob')), 'Date_Extension_Report(' + sessionYear + ')');
    }
  }

  onClear() {
    this.formRef.reset();
    this.allData = [];
  }

  ClearClassBase() {
    this.formRef?.controls['year'].reset();
    this.formRef?.controls['inst_no'].reset();
  }

  ClearYearBase() {
    this.formRef?.controls['inst_no'].reset();
  }
}
