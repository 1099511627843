import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from './../shared/model/SelectList.model';
import { GroupService } from './../shared/services/Groups.service';
import { ClassService } from './../shared/services/Class.service';
import { MajorService } from './../shared/services/Major.service';
import { SessionService } from './../shared/services/Sessions.service';
import { EnrollmentService } from './../shared/services/Enrollment.service';



interface Student{
  c_code:number,
  c_nm:string,
  maj_id:number,
  maj_nm:string,
  session:string,
  rolno:string,
  nm:string,
  rn:number,
  f_nm:string,
  year:number,
  remarks:string
}

@Component({
  selector: 'app-re-admitted',
  templateUrl: './re-admitted.component.html',
  styleUrls: ['./re-admitted.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class ReAdmittedComponent implements OnInit {

  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  Std: Student;
  groups: Array<SelectList>;
  isAdmin: boolean = false;
  group: number;

  @ViewChild('f') formRef: NgForm;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  this screen is used for student Re-Admission/ReJoin in forthcoming or Current semester in any session  </h6>
  <h6 class="text-right">یہ اسکرین کسی بھی سیشن میں آنے والے یا موجودہ سمسٹر میں طلباء کے دوبارہ داخلے/دوبارہ شامل ہونے کے لیے استعمال ہوتی ہے۔۔
</h6>
  `  ;

  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private enrollmentService:EnrollmentService,
    private groupService: GroupService,
    private classService: ClassService,
    private majorService: MajorService,
    private sessionService: SessionService,
    
  ) {
    this.classes = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.groups = new Array<SelectList>();

    this.isAdmin = this.authenticationService.isAdmin();
    this.group = this.authenticationService.getGroup();
   }


  //  ngOnInit(): void {
  //   this.loadGroups();
  // }


  ngOnInit(): void {
    this.classService.getClasses({}).subscribe((res:{C_CODE: number, C_NM: string}[]) => {
      res?.forEach(entry=>{
        this.classes.push({id: entry.C_CODE, nm: entry.C_NM});
      });
    });
  }
  // loadGroups() {
  //   this.classes = [];
  //   this.majors = [];
  //   this.sessions = [];
  //   this.groupService.getGroups({ grp: this.group, role: this.isAdmin }).subscribe((res: { GRP: number, G_NM: string }[]) => {
  //     res?.forEach(element => {
  //       this.groups.push({ id: element.GRP, nm: element.G_NM });
  //     });

  //     if (!this.isAdmin) {
  //       this.updateClasses();
  //     }
  //   }, err => {
  //     console.log(err);
  //   });
  // }



  // updateClasses() {
  //   this.classes = [];
  //   this.majors = [];
  //   this.sessions = [];
  //   this.classService.getClasses({ grp: this.group }).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
  //     res?.forEach(entry => {
  //       this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
  //     });
  //   });
  // }


  updateMajors() {
    this.majors = [];
    this.sessions = [];
    this.majorService.getMajor({d_id: this.formRef.value.d_id, c_code: this.formRef.value.c_code}).subscribe((res:{MAJ_ID: number, MAJ_NM:string}[]) => {
      res?.forEach(entry=>{
        this.majors.push({id: entry.MAJ_ID, nm:entry.MAJ_NM});
      })
    });

  }

  updateSessions() {
    this.sessions = [];
    this.sessionService.getSessions(this.formRef.value.c_code).subscribe((res:{SE_ID: number, SE_NM:string}[]) => {
      res?.forEach(element => {
        this.sessions.push({id: element.SE_ID, nm : element.SE_NM});
      });
    });
  }


  upadteStudentName() {
    this.enrollmentService.getAdmStudent(this.formRef.value.c_code,this.formRef.value.se_id,this.formRef.value.year_in,this.formRef.value.rn).subscribe((res:{c_code:number,c_nm:string,maj_id:number,maj_nm:string,session:string,rolno:string,nm:string,rn:number,f_nm:string,year:number,remarks:string})=>{
        this.Std={c_code:res.c_code,c_nm:res.c_nm,maj_id:res.maj_id,maj_nm:res.maj_nm,session:res.session,rolno:res.rolno,nm:res.nm,rn:res.rn,f_nm:res.f_nm,year:res.year,remarks:res.remarks};
    },err=>{
      console.log(err);
      this.toastr.warning("Some Error Occured!");
    });
  }


  AddRecord(){
    let stdRolno=this.Std.rolno;
    this.enrollmentService.insertAdmRecord(this.Std.rolno,this.formRef.value.cat,this.formRef.value.new_se_id||this.formRef.value.se_id,this.formRef.value.t_no).subscribe((res:{warning:string,rolno:string,session:string})=>{
      if(!res){
        this.toastr.error("Some Error Occured!");
      }else if(res[0][0].warning){
        this.toastr.warning(res[0][0].warning);
      }else{
        this.Std.rolno=res[1][0].rolno;
        this.Std.session=res[1][0].session;
        this.toastr.success("Inserted Successfully at LMS Server!");
      }
    },err=>{
      console.log(err);
      this.toastr.error("Some Error Occured!");
    })

    this.enrollmentService.insertAdmRecordFeeServer(stdRolno,this.formRef.value.cat,this.formRef.value.new_se_id||this.formRef.value.se_id,this.formRef.value.t_no).subscribe((res:{warning:string,rolno:string,session:string})=>{
      
        if(!res){
          this.toastr.error("Some Error Occured!");
        }else if(res[0][0].warning){
          this.toastr.warning(res[0][0].warning);
        }else{
          // this.Std.rolno=res[1][0].rolno;
          // this.Std.session=res[1][0].session;
          this.toastr.success("Inserted Successfully at Fee Server!");
        }
      },err=>{
        console.log(err);
        this.toastr.error("Some Error Occured at Fee Server!");
      })
  }


  deleteStudent(rolno){
    this.enrollmentService.delAdmRecord(rolno,this.formRef.value.t_no).subscribe((res:{msg:string,warning:string})=>{
      if(!res){
        this.toastr.error("Some Error Occured!");
      }else if(res.msg){
        this.toastr.success(res.msg);
        this.Std.rolno='';
      }else{
        this.toastr.warning(res.warning);
      }
    },err=>{
      console.log(err);
      this.toastr.error("Some Error Occured!");
    });

    this.enrollmentService.delAdmRecordFee(rolno,this.formRef.value.t_no).subscribe((res:{msg:string,warning:string})=>{
      if(!res){
        this.toastr.error("Some Error Occured!");
      }else if(res.msg){
        this.toastr.success(res.msg);
        this.Std.rolno='';
      }else{
        this.toastr.warning(res.warning);
      }
    },err=>{
      console.log(err);
      this.toastr.error("Some Error Occured!");
    })
  }


  ClearForm(fn: NgForm) {
    fn.resetForm();
    this.classes = [];
    this.Std.rolno='';
    this.majors = [];
  }


  OnSubmit() {
    if (!this.formRef.valid)
      return;
  }

  OnGrpChange(grp) {
    this.group = parseInt(grp.value);
    // this.updateClasses();
  }

  OnSemesterChange() {
    // this.Std.rolno='';
  }

  OnRollNoChange(fn: NgForm) {
  }

  OnSessionChange(s: HTMLSelectElement) {
  }


  OnClassChange(c: HTMLSelectElement) {
    this.updateSessions();
  }

}
