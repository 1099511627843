<div class="details-button-container">
    <button (click)="toggleDetails()" class="icon-button fa fa-info-circle"></button>
    <div *ngIf="isOpen" class="details-content">
      <button (click)="toggleDetails()" class="close-button">✖</button>
      <div [innerHTML]="details"></div>
    </div>
  </div>
  
   <!-- <div class="details-button-container">
    <button (click)="toggleDetails()" class="icon-button">
        <i class="far fa-info-circle"></i>
    </button>
    <div *ngIf="isOpen" class="details-content">
        <button (click)="toggleDetails()" class="close-button">✖</button>
        <div [innerHTML]="details"></div>
    </div>
</div> -->
