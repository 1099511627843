<div class="card" [@SlideInFromLeft]>
  <div class="card-header">Overall Attendance Report</div>
  <div class="row card-body px-5">
    <div class="col-sm-12 col-lg-12 px-0">
      <form #f="ngForm" class="px-0">
        <div class="row px-0">
          <div class="col-lg-4 col-md-6 form-group">
            <label for="Day">Group</label>
            <input *ngIf="!isAdmin && !showInter && this.isPhilosophy!=410"
              type="text" class="form-control"
              value='{{ ((this.groups.length>0) ? groups[0].nm:" ")}}'
              name="grp" id="grp" readonly>
            <select *ngIf="isAdmin && this.isPhilosophy!=410" #grp
              class="form-control" name="grp" id="grp"
              (change)="OnGrpChange(grp)" ngModel>
              <option *ngFor="let grp of groups"
                value="{{grp.id}}">{{grp.nm}}</option>
            </select>
            <select *ngIf="this.isPhilosophy==410" #grp class="form-control"
              name="grp" id="grp" (change)="OnGrpChange(grp)" ngModel>
              <option value="410">PHILOSOPHY</option>
              <option value="643">IDC (Evening)</option>
              <option value="600">Chemistry & Life Sciences (Morning)</option>
              <option value="106">HUMANITIES (Morning)</option>
            </select>
            <select *ngIf="showInter&&this.isPhilosophy!=410" #grp
              class="form-control" name="grp" id="grp"
              (change)="OnGrpChange(grp)" ngModel>
              <option *ngFor="let grp of interMediateGroup" value="{{grp.id}}">
                {{grp.nm}} </option>
            </select>
          </div>

          <div *ngIf="isAdmin" class="col-lg-4 col-md-6 form-group">
            <label for="Department">Department</label>
            <select #department (change)="OnDeptChange(department)"
              class="form-control" name="d_id"
              id="Department"
              required ngModel>
              <option *ngFor="let d of departments"
                value="{{d.id}}">{{d.nm}}</option>
            </select>
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="C_Code">Class</label>
            <select #cource (change)="OnClassChange(cource)"
              class="form-control " name="c_code" id="C_Code" ngModel>
              <option *ngFor="let c of classes"
                value="{{c.id}}">{{c.nm}}</option>
            </select>
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="Session">Session</label>
            <select class="form-control " #session
              (change)="OnSessionChange(session)" name="se_id" id="Session"
              ngModel>
              <option *ngFor="let s of sessions"
                value="{{s.id}}">{{s.nm}}</option>
            </select>
          </div>
          <div class="col-lg-4 col-md-6 form-group">
            <label for="semester">Semester</label>
            <select #semester (change)="OnSemesterChange(semester)"
              class="form-control " name="t_no" id="Semester" required
              ngModel>
              <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]"
                value="{{i}}">{{i}}</option>
            </select>
          </div>
          <div class="col-lg-4 col-md-6 form-group">
            <label for="Subject">Subject</label>
            <select #subject class="form-control" name="sub_code" id="Subject"
              (change)="onSubChange(subject)" ngModel required>
              <option *ngFor="let sub of subjects; let i=index;"
                value="{{sub.sub_code}}">{{sub.sub_code}} ({{sub.sub_nm}}
                )</option>
            </select>
          </div>

          <div class="col-lg-4 col-md-6 form-group">
            <label for="Section">Section</label>
            <select class="form-control " #section name="section" id="Section"
              (change)="OnSectionChange()" ngModel>
              <option *ngFor="let sec of sectionareannoying"
                value="{{sec.section}}">{{sec.section}}</option>
            </select>
          </div>

        </div>

        <ng-container *ngIf="loader then ifBlock"></ng-container>
        <ng-template #ifBlock>
          <div
            style="display:flex;justify-content:center; align-items: center;"><span
              class="loader"></span></div>
        </ng-template>

        <div class="col-lg-4 col-md-6 form-group px-0" style="margin-left: -28px">
          <button class="btn btn-outline-info mt-1"
            (click)="getAttendanceData()" [disabled]="!f.valid">Download PDF
          </button>
          <button class="btn btn-warning mt-1 ml-2"
            (click)="f.resetForm()" type="button">Clear</button>
        </div>
      </form>
    </div>
  </div>
</div>