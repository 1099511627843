import { Component,  OnInit } from '@angular/core';
import { SlideInFromLeft } from '../../transitions';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from '../../../../src/axios.js';
import { ToastrService } from 'ngx-toastr';
import { Team, TimetableService } from '../shared/services/Timetable.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CourseMaterialService } from './../shared/services/CourseMaterial.service';
import { FileService } from './../shared/services/File.service';
import { saveAs } from 'file-saver';


export interface materail{
  CM_ID:number,
  CM_TITLE:string,
  FILE_NM:string,
  FILE_PATH:string
}

@Component({
  selector: 'app-course-upload',
  templateUrl: './course-upload.component.html',
  styleUrls: ['./course-upload.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class CourseUploadComponent implements OnInit {
  show: boolean;
  classes: { c_code: string, c_nm: string }[];
  teams: Team[];
  tm: Team;
  courseMaterailForm: UntypedFormGroup;
  submitted: boolean = false;
  MAX_FILE_SIZE = 6000000;
  // materail: Array<materail>;
  materail : Map<number, materail>;



  fileToUpload: File | null;

  constructor(private timetableService: TimetableService,
    private httpService: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private courseMatService: CourseMaterialService,
    private toastr: ToastrService,
    private fileService:FileService
  ) {

    this.materail = new Map<number, materail>();
    this.classes = new Array<{ c_code: string, c_nm: string }>();
    this.teams = new Array<Team>();
    this.show = false;
  }

  ngOnInit(): void {
    this.classes = this.timetableService.getClasses();

    if (this.classes?.length > 0) {
      this.loadTeams(parseInt(this.classes[0].c_code));
    }

    this.courseMaterailForm = this.formBuilder.group({
      file: ['', Validators.required],
      title: ['Lecture ', Validators.required]
    });
  }

  get f() {
    return this.courseMaterailForm.controls;
  }

  OnClassChange(c: HTMLSelectElement) {
    this.loadTeams(parseInt(c.value));
  }

  loadTeams(c_code: number) {
    this.teams = this.timetableService.getTeam(c_code);
    if (this.teams?.length) {
      this.tm = this.teams[0];
    }
    else {
      this.tm = null;
    }
  }

  onSubjectChange(index: HTMLSelectElement) {
    this.tm = this.teams[parseInt(index.value)];
  }

  handleFile(e) {

    if (!e.target.files?.length) return;

    this.fileToUpload = e.target.files[0];
  }

  loadMaterial(){
    this.courseMatService.getCourseMaterial(this.tm).subscribe((res:materail[])=>{
      this.materail.clear()
      res?.forEach(entry=>{
        this.materail.set(entry.CM_ID, entry);
      });
    }, err=>{
      console.log(err);
      this.toastr.error("Unknown Error");
    });
  }


  uploadFiles() {
    this.submitted=true;

    if(this.courseMaterailForm.invalid || !this.tm || !(this.fileToUpload?.size < this.MAX_FILE_SIZE)) return;

    this.courseMatService.insertCourseMaterial({ ...this.tm, title: this.courseMaterailForm.value.title, file_nm: this.fileToUpload?.name }).subscribe((res: { CM_ID:number})=>{
      if(res){
        var d=new FormData();
        d.append('file',this.fileToUpload);
        this.fileService.uploadFile(this.tm, 'CourseMaterial', res.CM_ID, d).subscribe((res2: materail)=>{
          if(res2){
            this.materail.set(res.CM_ID, res2);
            this.toastr.success("File Uploaded Successfully");
          }else{
            this.toastr.warning("Failed to Upload file");
          }
        },err=>{
          console.log(err);
        })
      }else{
        this.toastr.warning("Already Exist");
      }
    },err=>{
      console.log(err);
      this.toastr.error("Unknown Error");
    });
  }


  onDelCLick(id: number, path: string) {
    this.fileService.deleteFile({ id, path, type: 'CourseMaterial'}).subscribe(res => {
      if (res) {
        this.materail.delete(id);
        this.toastr.success("Deleted Successfully");
      }
      else {
        this.toastr.warning("Occured");
      }
    }, err => {
      this.toastr.error("Some Error Occured");
    });
  }


  downloadFile(name: string, path:string){
    this.fileService.downloadFile(path).subscribe(res=>{
      if(res){
        saveAs(res, name);
      }else{
        this.toastr.warning("Failed To Download");
      }
    }, err=>{
      console.log(err);
      this.toastr.error("File Not Found");
    });
  }


}
