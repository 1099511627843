import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { UtilService } from 'src/app/Util.service';
import { EvaluationFormServices } from '../evaluation-form/evaluationForm.services';
import { SelectList } from '../shared/model/SelectList.model';
import { ClassService } from '../shared/services/Class.service';
import { GroupService } from '../shared/services/Groups.service';
import { SessionService } from '../shared/services/Sessions.service';
import * as moment from 'moment';
import { SubjectOfferService } from '../shared/services/SubjectOffer.service';
import { EvaluationFormReportsServices } from '../evaluation-form-reports/EvaluationFormsReports.services';
import jsPDF from 'jspdf';
import { DatePipe } from '@angular/common';
import { oldeng } from 'src/font';
import autoTable from 'jspdf-autotable';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { MajorService } from '../shared/services/Major.service';
import { IsDetail } from '../degree-report-print/degree-report-print.component';
import { Chart, plugins } from 'chart.js';
export interface IPublishedInfo{
  CLASS_NM: string,
  C_CODE: number,
  GRP: number,
  G_NAME: string,
  SECTION: string,
  SE_NM: string,
  SE_ID: number,
  T_NO: number,
  FRM_ID:number,
  FRM_NM:string
}
export interface IStdDetail{
  ROLNO:string,
  NM:string,
  STATUS:number
}
@Component({
  selector: 'app-evaluation-response-detail',
  templateUrl: './evaluation-response-detail.component.html',
  styleUrls: ['./evaluation-response-detail.component.css'],
  animations:[SlideInFromLeft()]
})
export class EvaluationResponseDetailComponent implements OnInit {
    groups: Array<SelectList>;
    classes:Array<SelectList>;
    sessions:Array<SelectList>;
    formName:Array<SelectList>;
    majors: Array<SelectList>;
    section:Array<string>;
    subs: Array<{ sub_code: string, sub_nm: string, Total:number,Submit:number,NotSubmit:number }>;
    infoReport:Array<IPublishedInfo>;
    stdDetail:Array<IStdDetail>;
    usr=null;
    group:number;
    @ViewChild('f') formRef: NgForm;
    constructor(private _formSer:EvaluationFormServices, private _toaster:ToastrService,
      private _grpService:GroupService , private _classSer:ClassService, private _sessionSer:SessionService,
      private util:UtilService, private datePipe:DatePipe, private _subOfferSer:SubjectOfferService,private formRptService:EvaluationFormReportsServices,
    private authenticationService: AuthenticationService,private majorService: MajorService){
      this.section=new Array<string>();
      this.classes=[];
      this.sessions=[];
      this.formName=[];
      this.groups=[];
      this.infoReport = [];
      this.subs = [];
      this.stdDetail=[];
      this.majors=[];
      this.usr=this.authenticationService.getUser();
    }
    ngOnInit(): void {
      this.getFormNames();
    }
    getFormNames(){
      this._formSer.getPublishedForms().subscribe((res:{FRM_ID:number,FRM_NM:string}[])=>{
        if(res?.length==0){
          this._toaster.warning('No Any Forms Published');
          return;
        }
        res?.forEach(e =>{
            this.formName.push({nm:e.FRM_NM, id:e.FRM_ID});
        });
          this.updateClasses();
      })
    }
    /*
    loadGroups() {
      this._grpService.getGroups({role:true}).subscribe((res: { GRP: number, G_NM: string }[]) => {
        res?.forEach(element => {
          this.groups.push({ id: element.GRP, nm: element.G_NM });
        });
      }, err => {
        console.log(err);
      });
    }
    OnGrpChange(grp: HTMLSelectElement) {
      this.group = parseInt(grp.value);
      this.updateClasses();
    }

    */
    
    updateClasses(){
      this.classes=[];
      this._classSer.getClasses({}).subscribe((res:{C_CODE:number,C_NM:string}[]) =>{
        res?.forEach(e =>{
          this.classes.push({nm:e.C_NM,id:e.C_CODE});
        })
      })
    }
    onClassChange(){
      this.updateMajors();
      this.updateSession();
    }
    updateMajors() {
      this.majors = [];
      this.subs=[];
      this.majorService.getMajor({c_code: this.formRef.value.c_code}).subscribe((res:{MAJ_ID:number,MAJ_NM:string}[]) => {
        res?.forEach(entry=>{
          this.majors.push({id: entry.MAJ_ID, nm: entry.MAJ_NM});
        });
      });
    }
    updateSession(){
      this.sessions=[];
      this._sessionSer.getSessions(this.formRef.value.c_code).subscribe( (res:{SE_ID:number,SE_NM:string}[])=>{
        res?.forEach(e =>{
          this.sessions.push({nm:e.SE_NM,id:e.SE_ID});
        })
      })
    }
    OnSemesterChange() {
      this.section=[];
      this.updateSections()
      // this.updateSubjects();
    }
    OnSectionChange(){
      this.updateSubjects()
    }
    updateSubjects(){
      const {frm_id,c_code,se_id,t_no,maj_id,section} = this.formRef.value;      
      this.subs=[];
      this._subOfferSer.getSubjects_Class_Sess_Tno_count(frm_id,c_code,se_id,t_no,maj_id,section).subscribe( (res:{sub_code:string,sub_nm:string,Total:number,Submit:number,NotSubmit:number}[]) =>{
        res?.forEach(e =>{          
          this.subs.push({sub_code:e.sub_code,sub_nm:e.sub_nm,Total:e.Total,Submit:e.Submit,NotSubmit:e.NotSubmit});
          
        });                
        
        setTimeout(() => {
          this.subs.forEach(element=>{            
            this.generatechart(element.sub_code,[element.Total,element.Submit,element.NotSubmit])              
          })
          
        }, 200);            
               
      })
      
    }    
    
    GetFormsData(sub_code:string){
      this.stdDetail=[];      
      if(!this.formRef.value.frm_id){
        this._toaster.warning('Select All Fields Properly.');
        return;
      }
      const {frm_id, c_code, se_id, t_no, section} = this.formRef.value;
      this.formRptService.EV_F_getSdtFrmSubDetail(frm_id,c_code,se_id,t_no,sub_code,section).subscribe((res:[{FM_ID,NAME}[],[IStdDetail]]) =>{
      if(res[0]?.length>0){
          this.teacherName=res[0][0]?.NAME;
        }
        if(res[1]?.length>0){
          this.stdDetail=res[1];
        }
        else{
          this._toaster.warning('No Students Found.');
          return;
        }
      });

    }



    generatechart(name:string,dataset:any) {
      
          var chart = new Chart(name, {
        
        type: 'doughnut',
        data: {        
          labels: [`Total : ${dataset[0]}`, `Submitted : ${dataset[1]}`, `Non Submitted : ${dataset[2]}`],
          datasets: [{          
            label: 'Forms Response',
            data: dataset,
            backgroundColor: [
              'Maroon',
              '#DAA520',
              '#4169e1'
            ],
            borderColor: [
              'Maroon',
              '#DAA520',
              '#4169e1'
            ],
            borderWidth: 1
          }]
        },
        options: {
          legend: {
            position: 'right',
            labels: {
              fontColor: 'black',
            }
          },
        }
      });
    }
    updateSections(){
      this.section=[];
      const {c_code,se_id,t_no,maj_id} = this.formRef.value;
      this.util.getSect(c_code,se_id,t_no,'null', maj_id).subscribe((res:{section:string}[]) =>{
        if(res?.length==0){
          this._toaster.warning('Sections not found.');
          return;
        }
        res?.forEach(e =>{
          
          this.section.push(e.section);

        }
        )        
      })
    }
    
    submitType:number=0;
    teacherName:string='';
    onSubmit(submitType:number){
      this.stdDetail=[];
      this.submitType=submitType;
      if(!this.formRef.value.frm_id){
        this._toaster.warning('Select All Fields Properly.');
        return;
      }
      const {frm_id, c_code, se_id, t_no, sub_code, section} = this.formRef.value;      
      this.formRptService.EV_F_getSdtFrmSubDetail(frm_id,c_code,se_id,t_no,sub_code,section).subscribe((res:[{FM_ID,NAME}[],[IStdDetail]]) =>{
      // this.formRptService.EV_F_getSdtFrmSubDetail(25,11,489,8,'CS-4215','C',2).subscribe((res:[{FM_ID,NAME}[],[IStdDetail]]) =>{
      if(res[0]?.length>0){
          this.teacherName=res[0][0]?.NAME;
        }
        if(res[1]?.length>0){
          this.stdDetail=res[1];
        }
        else{
          this._toaster.warning('No Students Found.');
          return;
        }
      });
    }

    downloadStdDetail(){
      if (this.stdDetail?.length == 0)  return;
      let body = [];
      let header = [];
      const {c_code, se_id, t_no, sub_code, section} = this.formRef.value;
      let clas = this.classes.find(e => e.id==c_code)?.nm;
      let session = this.sessions.find(s =>s.id==se_id)?.nm;
      let sub_nm = this.subs.find(s =>s.sub_code==sub_code)?.sub_nm;
      let nm=this.usr?.NM;
      this._toaster.info("Downloading");
      let image = new Image();
      image.src = "../../../assets/images/logo3.png";
      const doc = new jsPDF("p", "mm", [297, 210]);
      let exportDate = this.datePipe.transform(new Date(), "MMM d, y, h:mm:ss a");
      let tchr = this.teacherName;
      let subType=this.submitType;
       
      header = [['Sr. No.', "Roll Number","Name"]];
  
        this.stdDetail.forEach((e, i)=>{
          body.push([
            (i+1),
            e.ROLNO,
            e.NM
            ]);
        })
          autoTable(doc, {
            theme: "grid",
            margin: {top:45,bottom: 20, left: 10, right: 10  },
            head: header,
            body: body,
            styles: {
              fontSize: 9
            },
            didDrawPage: function (d) {
              doc.addImage(image, 180, 2, 26, 26);
              doc.setFontSize(20);
              doc.addFileToVFS('OLDENGL.ttf', oldeng);
              doc.addFont('OLDENGL.ttf', 'OLDENGL', 'bold');
              doc.setFont('OLDENGL', "bold");
              doc.text("GC University Lahore", 100, 10, { align: 'center' });
              doc.setFont("Arial", "bold");
              doc.setFontSize(12);
              doc.text(`${clas}`, 100, 16, { align: 'center' });
              doc.text(`Session : (${session})  Semester : (${t_no})`, 100, 22, { align: 'center' });
              doc.text(`Subject : ${sub_nm}-(${sub_code})`, 10, 28);
              doc.text(`Teacher Name : ${tchr}`,10,34);
              doc.text(`Section : ${section}`,140,34);


              if(subType==1){
              doc.setFontSize(14);
                doc.text(`List of Students who has Submitted the Teacher Evaluation Form`, 95, 42, { align: 'center' });
              }
              else{
              doc.setFontSize(14);
                doc.text(`List of Students who has not Submitted the Teacher Evaluation Form`, 95, 42, { align: 'center' });
              }
              doc.setFontSize(10);
              doc.setFont("Arial", "normal");

              doc.text(`PRINTED BY: ${nm}`, 10, 286);
              doc.text("Note:- Errors and Omissions are Excepted", 10, 290);
              doc.text(`${exportDate}`, 159, 286);
              doc.text("Directorate of Information Technology", 143, 290);
            },
          });
      let a = this._toaster;
      setTimeout(function () {
        a.success("Downloaded");
      }, 1000);
      this._toaster.success("PDF Download Successfully");
      doc.setProperties({
        title: `List of Students`      
      });
      window.open(URL.createObjectURL(doc.output('blob')), '_blank');
    }
}