import { Injectable } from '@angular/core';
import { ClassService } from '../main/shared/services/Class.service';
import { FeeService } from '../main/shared/services/Fee.service';
import { MajorService } from '../main/shared/services/Major.service';
import { SessionService } from '../main/shared/services/Sessions.service';
import { DepartmentService } from '../main/shared/services/Department.service';

@Injectable({
    providedIn: 'root',
})
export class LMSharedDataService {
    private classes: any[] = [];
    private instNo: any[] = [];
    private majors: any[] = [];
    private sessions: any[] = [];
    private adminDept: any[] = [];
    private adminExamDept: any[] = [];
    private updateClassPromise: Promise<any> | null = null;
    private updateInstPromise: Promise<any> | null = null;
    private updateMajorPromise: Promise<any> | null = null;
    private updateSessionPromise: Promise<any> | null = null;
    private adminDeptPromise: Promise<any> | null = null;
    private adminExamDeptPromise: Promise<any> | null = null;

    constructor(
        private classService: ClassService,
        private feeService: FeeService,
        private majorService: MajorService,
        private sessionService: SessionService,
        private departmentService: DepartmentService
    ) { this.initialize(); }

    initialize() {
        this.updateLMSMajor();
        this.updateLMSInst();
        this.getLMSSessions();
        this.updateAdminDept();
    }

    updateLMSClasses(): Promise<any[]> {
        if (!this.updateClassPromise) {
            this.updateClassPromise = new Promise((resolve, reject) => {
                this.classService.getAllClasses().subscribe(
                    (res: { c_code: number, Des: string, lvlId: string }[]) => {
                        this.classes = res?.map(entry => ({ id: entry.c_code, nm: entry.Des, alt_id: entry.lvlId })) || [];
                        this.updateClassPromise = null; // Reset the promise
                        resolve(this.classes);
                    },
                    error => {
                        console.log("Error fetching classes", error);
                        this.updateClassPromise = null; // Reset the promise on error
                        reject(error);
                    }
                );
            });
        }
        return this.updateClassPromise;
    }

    updateLMSMajor(): Promise<any[]> {
        if (!this.updateMajorPromise) {
            this.updateMajorPromise = new Promise((resolve, reject) => {
                this.majorService.getMajors().subscribe((res: { class: number, id: number, nm: string }[]) => {
                    this.majors = res;
                    resolve(this.majors);
                }, error => {
                    console.log("Error fetching majors", error);
                    this.updateMajorPromise = null; // Reset the promise on error
                    reject(error);
                });
            });
        }
        return this.updateMajorPromise;
    }



    getLMSClasses(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.classes.length === 0) {
                this.updateLMSClasses()
                    .then((updatedClasses) => {
                        resolve(updatedClasses);
                    })
                    .catch((error) => {
                        console.log("Error updating fee classes", error);
                        resolve([]);
                    });
            } else {
                resolve(this.classes);
            }
        });
    }

    updateLMSInst(): Promise<any[]> {
        if (!this.updateInstPromise) {
            this.updateInstPromise = new Promise((resolve, reject) => {
                this.feeService.getInstallmentNo().subscribe((res: { inst_no }[]) => {
                    if (res) {
                        for (let i = 0; i < res.length; i++) {
                            this.instNo.push(res[i].inst_no)
                        }
                        this.updateInstPromise = null; // Reset the promise
                        resolve(this.instNo);
                    }
                }, error => {
                    console.log("Error fetching installments", error);
                    this.updateInstPromise = null; // Reset the promise on error
                    reject(error);
                });
            });
        }

        return this.updateInstPromise;
    }

    getLMSInst(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.instNo.length === 0) {
                this.updateLMSInst()
                    .then((updateInstallment) => {
                        resolve(updateInstallment);
                    })
                    .catch((error) => {
                        console.log("Error updating fee inst_no", error);
                        resolve([]);
                    });
            } else {
                resolve(this.instNo);
            }
        });
    }


    getLMSMajor(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.majors.length === 0) {
                this.updateLMSMajor()
                    .then((updateMajors) => {
                        resolve(updateMajors);
                    })
                    .catch((error) => {
                        console.log("Error updating fee majors", error);
                        resolve([]);
                    });
            } else {
                resolve(this.majors);
            }
        });
    }

    filterMajorByCode(c_code: any): Promise<any[]> {
        return new Promise((resolve) => {
            this.getLMSMajor()
                .then(majors => {
                    const majVal = majors
                        .filter(major => major.class == c_code)
                        .map(filteredMajor => ({ id: filteredMajor.id, nm: filteredMajor.nm, c_code: filteredMajor.class }));
                    resolve(majVal);
                })
                .catch(error => {
                    console.log("Error getting majors", error);
                    resolve([])
                });
        }
        )
    }


    filterSessionByCode(c_code: any): Promise<any[]> {
        return new Promise((resolve) => {
            this.getLMSSessions()
                .then(sessions => {
                    const sessVal = sessions
                        .filter(session => session.c_code == c_code)
                        .map(filteredsession => ({ id: filteredsession.se_id, nm: filteredsession.sess, c_code: filteredsession.c_code }));
                    resolve(sessVal);
                })
                .catch(error => {
                    console.log("Error getting sessions", error);
                    resolve([])
                });
        }
        )
    }

    updateLMSSessions(): Promise<any[]> {
        if (!this.updateSessionPromise) {
            this.updateSessionPromise = new Promise((resolve, reject) => {
                this.sessionService.getAllSessions().subscribe(
                    (res: { sess: string, se_id: number, c_code: number }[]) => {
                        this.sessions = res;
                        resolve(this.sessions);
                    },
                    error => {
                        console.log("Error fetching sessions", error);
                        this.updateSessionPromise = null; // Reset the promise on error
                        reject(error);
                    }
                );
            });
        }
        return this.updateSessionPromise;
    }


    getLMSSessions(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.sessions.length == 0) {
                this.updateLMSSessions()
                    .then((updateSessions) => {
                        resolve(updateSessions);
                    })
                    .catch((error) => {
                        console.log("Error updating fee sessions", error);
                        resolve([]);
                    });
            } else {
                resolve(this.sessions);
            }
        });
    }

    updateAdminDept(): Promise<any[]> {
        if (!this.adminDeptPromise) {
            this.adminDeptPromise = new Promise((resolve, reject) => {
                this.departmentService.getDepartments({admin: true}).subscribe((res:{D_ID:number,D_NM:string}[]) => {
                    if (res) {
                        for (let i = 0; i < res.length; i++) {
                            this.adminDept.push({id: res[i].D_ID, nm: res[i].D_NM});
                        }
                        this.adminDeptPromise = null; // Reset the promise
                        resolve(this.adminDept);
                    }
                }, error => {
                    console.log("Error fetching Admin Department", error);
                    this.adminDeptPromise = null; // Reset the promise on error
                    reject(error);
                });
            });
        }
        return this.adminDeptPromise;
    }

    getAdminDept(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.adminDept.length === 0) {
                this.updateAdminDept()
                    .then((updateAdminDept) => {
                        resolve(updateAdminDept);
                    })
                    .catch((error) => {
                        console.log("Error updating Admin Department", error);
                        resolve([]);
                    });
            } else {
                resolve(this.adminDept);
            }
        });
    }

    updateAdminExamDept(): Promise<any[]> {
        if (!this.adminExamDeptPromise) {
            this.adminExamDeptPromise = new Promise((resolve, reject) => {
                this.departmentService.getDepartments({admin: true, isExam: true}).subscribe((res:{D_ID:number,D_NM:string}[]) => {
                    if (res) {
                        for (let i = 0; i < res.length; i++) {
                            this.adminExamDept.push({id: res[i].D_ID, nm: res[i].D_NM});
                        }
                        this.adminExamDeptPromise = null; // Reset the promise
                        resolve(this.adminExamDept);
                    }
                }, error => {
                    console.log("Error fetching Admin Department", error);
                    this.adminExamDeptPromise = null; // Reset the promise on error
                    reject(error);
                });
            });
        }
        return this.adminExamDeptPromise;
    }

    getAdminExamDept(): Promise<any[]> {
        return new Promise((resolve) => {
            if (this.adminExamDept.length === 0) {
                this.updateAdminExamDept()
                    .then((updateAdminExamDept) => {
                        resolve(updateAdminExamDept);
                    })
                    .catch((error) => {
                        console.log("Error updating Admin Department", error);
                        resolve([]);
                    });
            } else {
                resolve(this.adminExamDept);
            }
        });
    }
}
