<div [@SlideInFromLeft] class="tabber">
    <div class="container-fluid card shadow">
        <div class="card-header">
            <div *ngIf="updating" class="over">
                <div class="loader "></div>
                Loading...
            </div>
            <div>Transcript</div>
        </div>
        <form #f="ngForm" class="row">
            <div class="row card-body space-remover">
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="c_code">Class</label>
                    <select #c_code class="form-control" (change)="OnClassChange(c_code)" name="c_code" id="c_code"
                        required [(ngModel)]="this.selected">
                        <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
                    </select>
                </div>

                <!-- <div class="col-lg-4 form-group">
                    <label for="se_st">Session Start</label>
                    <input type="number" #se_st class="form-control" min="0" name="se_st" id="se_st" ngModel>
                </div> -->
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="se_st_year">Year</label>
                    <input type="number" #se_st_year class="form-control" min="2000" name="se_st_year" id="se_st_year"
                        (change)="onChangeMajor()" placeholder="Admission Year" ngModel>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="se_id">Session</label>

                    <select #se_id class="form-control" (change)="OnSessionIDChange()" name="se_id" id="se_id" required
                        [(ngModel)]="this.se_select">
                        <option *ngFor="let c of session" value="{{c.id}}">{{c.nm}}</option>
                    </select>

                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="maj_id" class="text-left">Major
                    </label>
                    <select #maj_id id="maj_id" name="maj_id" (change)="onChangeMajor()" class="form-control"
                        [(ngModel)]="this.maj">
                        <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="t_no">Semester</label>
                    <select #t_no class="form-control" name="t_no" id="t_no" (change)="onChangeMajor()" required
                        [(ngModel)]="this.sem">
                        <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]" value="{{i}}">{{i}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="rn">Roll No</label>
                    <select #rn class="form-control" name="rn" id="rn" (change)="OnRollNoChange()" required
                        [(ngModel)]="this.rol">
                        <option *ngFor="let i of this.rolnumbers" value="{{i.id}}">{{i.nm}}</option>
                    </select>

                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 form-group mt-2">
                    <label></label>
                    <input type="text" class="form-control" name="" id="" placeholder="Name" value="{{this.st_nm}}"
                        readonly>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 card-header" *ngIf="sem == 8 || sem == 4 || sem == 3">
                In case of final <strong style="color: red;">Transcript/Degree</strong>, please insert into below fields
            </div>
            <div class="row card-body space-remover" style="padding-bottom: 0px;">

                <div class="col-lg-4 col-md-4 col-sm-6 form-group">
                    <label for="passYear">Passing Year</label>
                    <input type="number" #passYear class="form-control" min="2000" name="passYear" id="passYear" ngModel>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 form-group">
                    <label for="notifDate">Date Of Notification</label>
                    <input type="date" #notifDate class="form-control" name="notifDate" id="notifDate" ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="transcript">Transcript No.</label>
                    <input type="number" #transcript class="form-control" min="0" name="transcript" id="transcript" ngModel>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 card-header">Actions</div>
            <div class="row card-body">
                <div class="test ml-2 d-flex flex-column">
                    <button [disabled]="!f.valid" class="btn btn-outline-primary ml-2" style="font-size: 15px;"
                        (click)="OnSubmit(true)" type="button">New Generate Trans
                    </button>
                    <span class="ml-2"><b>For Legal Printing</b></span>
                </div>
                <div class="test ml-3 d-flex flex-column">
                    <button [disabled]="!f.valid" class="btn btn-outline-primary" style="font-size: 15px;"
                        (click)="OnSubmit(false)" type="button">New Generate Trans
                    </button>
                    <span><b>For Letter Printing</b></span>
                </div>
                <div class="test ml-3 d-flex flex-column">
                    <button [disabled]="!f.valid" class="btn btn-outline-primary" style="font-size: 15px;"
                        (click)="OnSubmit(true)" type="button">Mphil/MS Trans
                    </button>
                    <span><b>For Letter Printing</b></span>
                </div>
                <div class="test ml-3 d-flex flex-column">
                    <button [disabled]="!f.valid" class="btn btn-outline-primary" style="font-size: 15px;"
                        (click)="OnSubmit(true)" type="button">MBA/EMBA Trans
                    </button>
                    <span><b>For Letter Printing</b></span>
                </div>
                <div class="test ml-3">
                    <button class="btn btn-warning" style="font-size: 15px;" type="button" (click)="onClear()">Clear
                        Form
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>