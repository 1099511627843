<form (ngSubmit)="OnSubmit(f)" #f="ngForm" class="mt-4">
  <div class="container-fluid" [@SlideInFromLeft]>
    <div class="row ">
      <div class="col-sm-12 p-2">
        <div class="card shadow-sm">
          <div class="card-header">
            Add New Teacher
          </div>
          <div class="row card-body pb-3">
            <div class="col-lg-6 col-md-6 form-group">
              <label for="NM" class="text-left">Name</label>
              <input class="form-control" name="NM" id="NM" required ngModel>
            </div>
            <div class="col-lg-6 col-md-6 form-group">
              <label for="email">Email ID</label>
              <input class="form-control" name="email" id="email" type="email"
                ngModel>
            </div>
            <div class="col-lg-6 col-md-6 form-group">
              <label for="Department">Department</label>
              <input *ngIf="!isAdmin" type="text" class="form-control"
                value='{{ ((this.departments.length>0) ? departments[0].nm:" ")}}'
                readonly>
              <select *ngIf="isAdmin" #department class="form-control"
                (change)="OnDeptChange(department)" name="d_id"
                id="Department" ngModel>
                <option *ngFor="let d of departments"
                  value="{{d.id}}">{{d.nm}}</option>
              </select>
            </div>

            <div class="col-lg-6 col-md-6 form-group">
              <label for="NIC">CNIC</label>
              <input type="number" class="form-control" name="NIC" ID="NIC"
                required ngModel>
            </div>

            <div class="col-lg-6 col-md-6 form-group">
              <label for="DOB">Date of Birth</label><span class="required"
                style="color: red;">*</span>
              <input type="date" class="form-control" name="DOB" id="DOB"
                max="2010-01-02" required ngModel>
            </div>
            <div class="col-lg-6 col-md-6 form-group">
              <label for="PH1">Mobile Number</label><span class="required"
                style="color: red;">*</span>
              <input type="number" class="form-control" name="PH1" id="PH1"
                ngModel>
            </div>

            <div class="col-lg-6 col-md-6 form-group">
              <label for="religion">Religion</label><span class="required"
                style="color: red;">*</span>
              <select class="form-control" id="religion" name="religion"
                required ngModel>
                <option *ngFor="let d of relig"
                  value="{{d.nm}}">{{d.nm}}</option>
              </select>
            </div>

            <div class="col-lg-6 col-md-6 form-group">
              <label for="DES">Designation</label><span class="required"
                style="color: red;">*</span>
              <select class="form-control" name="DES" id="DES" required ngModel>
                <option *ngFor="let d of design"
                  value="{{d.nm}}">{{d.nm}}</option>
              </select>
            </div>

            <div class="col-lg-6 col-md-6 form-group">
              <label for="CTGRY">Category</label><span class="required"
                style="color: red;">*</span>
              <select class="form-control" id="CTGRY" name="CTGRY" required
                ngModel>
                <option *ngFor="let j of jobCtgry"
                  value="{{j.sub_nm}}">{{j.ctgry}}</option>
              </select>
            </div>
            <div class="col-lg-6 col-md-6 form-group">
              <label for="ADD1">Address</label><span class="required"
                style="color: red;">*</span>
              <input class="form-control" name="ADD1" id="ADD1" required
                ngModel>
            </div>

            <div class="radios col-lg-3">
              <div class>
                <label for>Gender</label>
                <div class>
                  <input type="radio" name="gender" id="male" value="M" required
                    ngModel>
                  <label for="male">Male</label>
                  <input type="radio" name="gender" id="female" value="F"
                    ngModel>
                  <label for="female">Female</label>
                  <input type="radio" name="gender" id="other" value="O"
                    ngModel>
                  <label for="other">Other</label>
                </div>
              </div>
            </div>
            <div class="radios col-lg-3">
              <label for="status">Status</label>
              <div class>
                <input type="radio" name="status" id="single" value="S" required
                  ngModel>
                <label for="single">Single</label>
                <input type="radio" name="status" id="zaleel" value="M" ngModel>
                <label for="zaleel">Married</label>
              </div>
            </div>

            <div class="col-lg-3" style="margin-top: 4px;">
              <button [disabled]="!f.valid" type="submit"
                class="btn btn-primary btn-md"
                style="margin-left: 15px; width: 100px">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>