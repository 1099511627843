import { Component,ElementRef, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';

@Component({
  selector: 'app-collection-report',
  templateUrl: './collection-report.component.html',
  styleUrls: ['./collection-report.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class CollectionReportComponent implements OnInit {
  isAdmin: boolean;
  canEnroll: boolean;
  canEnrolleRepeater: boolean;
  canReadmission:boolean;
  canFeeStructure: boolean;
  isFinancialAidOffice:boolean;
  isEndownmentFund:boolean;

@ViewChild('f') formRef: NgForm;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.isAdmin = this.authenticationService.isAdmin();
    this.canReadmission= authenticationService.canReadmission();
    this.canFeeStructure = authenticationService.canFeeStructure();
    this.canEnroll = this.authenticationService.canEnroll();
    this.canEnrolleRepeater = this.authenticationService.canEnrollRepeater();
    this.canReadmission= authenticationService.canReadmission();
    this.isEndownmentFund = authenticationService.isEndownmentFund();
   }

  ngOnInit(): void {
    this.onStudentReport();
  }
  
  onClassReport(){
    this.router.navigate(['classWise'], {relativeTo: this.route});
  }

  onClassBaseReport(){
    this.router.navigate(['classBase'], {relativeTo: this.route});
  }

  onGroupBaseReport(){
    this.router.navigate(['groupBase'], {relativeTo: this.route});
  }
  
  onStudentReport(){
    this.router.navigate(['student'], {relativeTo: this.route});
  }
}