<div class="modal-header">
    <h5 class="modal-title">Add New FAQ</h5>
    <button type="button" class="close" (click)="onCancel()">
      <span>&times;</span>
    </button>
  </div>
  
  <div class="modal-body">
    <form [formGroup]="faqForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="question">Question</label>
        <input id="question" type="text" formControlName="question" class="form-control" name="question"/>
        <div *ngIf="faqForm.get('question').invalid && faqForm.get('question').touched" class="text-danger">
          Question is required
        </div>
      </div>
  
      <div class="form-group">
        <label for="answer">Answer</label>
        <textarea id="answer" formControlName="answer" name="answer" class="form-control"></textarea>
        <div *ngIf="faqForm.get('answer').invalid && faqForm.get('answer').touched" class="text-danger">
          Answer is required
        </div>
      </div>
  
      <div class="form-group">
        <label for="role_index">Role Index</label>
        <input id="role_index" type="number" name="role_index" formControlName="role_index" class="form-control" />
        <div *ngIf="faqForm.get('role_index').invalid && faqForm.get('role_index').touched" class="text-danger">
          Role Index is required
        </div>
      </div>
  
      <button type="submit" class="btn btn-primary" [disabled]="faqForm.invalid">Add FAQ</button>
    </form>
  </div>
  