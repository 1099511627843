import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-details-button',
  templateUrl: './details-button.component.html',
  styleUrls: ['./details-button.component.css'],
  template: `<button>{{ details }}</button>`
})
export class DetailsButtonComponent {
  @Input() details: string | null = null;
  isOpen = false;

  toggleDetails() {
    this.isOpen = !this.isOpen;
  }

  // get icon() {
  //   // return this.isOpen ? '✖' : 'F';
  // }
}
