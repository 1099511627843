import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
    providedIn: 'root'
})

export class BlockService {

    constructor(private http: HttpClient) {
    }

    getBlocks() {
        return this.http.get(`${baseUrl}/api/block/getBlocks`);
    }
    getBlockRoomDetail(blk_id:string, rm_id:string,day:string) {
        return this.http.get(`${baseUrl}/api/block/getBlockRoomDetail/${blk_id}/${rm_id}/${day}`);
    }
}