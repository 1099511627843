<form (ngSubmit)="OnSubmit()" #f="ngForm">
  <div class=" container-fluid" [@SlideInFromLeft]>
    <div class="row card shadow">
      <div class="card-header">
        <div>Dues Submission Report</div>
      </div>
      <div class="row card-body pb-0 px-4">
        <div class="col-lg-4 col-md-6 form-group">
          <label for="C_Code">Class</label>
          <select #cource (change)="OnClassChange(cource)" class="form-control "
            name="c_code" id="C_Code" ngModel (change)="ClearClassBase()">
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>
        <!-- <div class="col-lg-4 col-md-6 form-group">
          <label for="Major">Major</label>
          <select class="form-control" #major (change)="OnMajorChange(major)" name="maj_id" id="Major" ngModel>
            <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
          </select>
        </div> -->

        <div class="col-lg-4 col-md-6 form-group">
          <label for="year">Year</label>
          <select class="form-control" id="year" aria-label="Year" ngModel
            required name="year" (change)="NullField()"
            (change)="ClearYearBase()">
            <option *ngFor="let s of sessions" value="{{s}}">{{s}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Installment">Installment</label>
          <select #installment class="form-control " #inst_no name="inst_no"
            (change)='onInstallmentChange()' ngModel (change)="NullField()">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="100">All Installments</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group" *ngIf="installment.value==1">
          <label for="m_list">Merit List</label>
            <select class="form-control " #merit name="m_list"
               ngModel id="m_list" required (change)="NullField()">
               <option *ngFor="let entry of meritList" [value]="entry">{{ entry }}</option>
          </select>
        </div>

        <div *ngIf="installment.value==1" class="col-lg-4 col-md-6 form-group">
          <label for="b_id">Base</label>
          <select class="form-control" #base (change)="onBaseChange(base)"
            name="b_id" id="b_id" ngModel (change)="NullField()">
            <option *ngFor="let a of admBase" value="{{a.id}}">{{a.nm}}</option>
          </select>
        </div>
      </div>
      <div class="row card-body">
        <button class="btn ml-1  btn-success" (click)='onSubDuesReport(1)' [disabled]="!f.valid">
          Submission of Dues Report
        </button>

        <button class="btn ml-2 btn-danger" (click)='onNonSubDuesReport(2)' [disabled]="!f.valid">
          Non Submission of Dues Report
        </button>

        <button class="btn ml-2 btn-warning" (click)='ClearForm(f)'>
          Clear
        </button>

        <button *ngIf="hideBtn" class="btn ml-2 btn-info "
          (click)='CreatePDF()'>
          Create-Pdf
        </button>
      </div>

      <loader *ngIf="loader"></loader>
      <div class="px-4">
        <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1" *ngIf="StudentInfo.length > 0">  
          <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
      </div>
        <table class="table table-bordered table-striped"
          id="tableID" *ngIf="StudentInfo.length>0" id="table">
          <thead class="thead-dark">
            <th>Challan #</th>
            <th>Form /Roll #</th>
            <th>Major</th>
            <th>Inst #</th>
            <th>Name</th>
            <th>Father's Name</th>
            <th *ngIf="subOrNot==1">Paid Date</th>
            <th *ngIf="subOrNot==2">Due Date</th>
            <th>Status</th>

          </thead>
          <tbody>
            <tr *ngFor="let i of this.StudentInfo" style="font-size:10px" id="values">
              <td>{{i.ch_no}}</td>
              <td>{{i.num}}</td>
              <td>{{i.maj}}</td>
              <td>{{i.inst_no}}</td>
              <td>{{i.nm}}</td>
              <td>{{i.f_nm}}</td>
              <td>{{i.dat| date : "dd-MM-y"}}</td>
              <td>{{i.status}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>