import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from 'src/app/transitions';
import { SelectList } from '../../shared/model/SelectList.model';
import { ClassService } from '../../shared/services/Class.service';
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
export interface Record{
  Combination: string,
  Grp: string,
  Set_no: number,
  Students:number
}
@Component({
  selector: 'app-student-strength',
  templateUrl: './student-strength.component.html',
  styleUrls: ['./student-strength.component.css'],
  animations:[
    SlideInFromLeft()
  ]
})
export class StudentStrengthComponent implements OnInit {

  @ViewChild('f') formRef: NgForm;
  classes: Array<SelectList>;
  sessions: Array<SelectList>;
  isAdmin: boolean = false;
  group: number;
  record:Array<Record>;
  totalStd:number=0;
  loader: boolean;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  You can view and download the combination wise strength of students in a class and session  </h6>
  <h6 class="text-right">آپ کسی کلاس اور سیشن میں کمبینیشن وائز طلباء کی تعداد دیکھ اور ڈاؤن لوڈ کر سکتے ہیں۔۔</h6>
  `  ;

  constructor(
    private toastr: ToastrService,
    private classService: ClassService,
    private datePipe: DatePipe,
    private lmsSharedService: LMSharedDataService
  ) {
    this.classes = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.record = new Array<Record>();
  }
  
  ngOnInit(): void {
    this.updateClasses();
    this.loader = false;
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  OnClassChange() {
    this.updateSessions();
  }

  updateSessions() {
    this.lmsSharedService.filterSessionByCode(this.formRef.value.c_code)
      .then(session => {
        this.sessions = session
        if (this.formRef) {
          const initialValue = this.sessions[0]?.id;
          this.formRef?.controls['se_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }
  
  OnSubmit(){
    if(!this.formRef.value){
      return;
    }
    const {c_code,se_id} = this.formRef.value;
    this.loader = true;
    this.record=[];
    this.totalStd=0;
    this.classService.getDepartmentCountStudents(c_code,se_id).subscribe((res:Record[])=>{
      if(res.length != 0){
        this.record = res;
        console.log(this.record)
        this.totalStd = this.record.reduce( (a, b) => (a + b.Students), 0 );
        this.loader = false;
      }
    })
  }

  ClearForm(f){
    this.record = []
    this.sessions=[];
    this.loader = false;
    f.resetForm();
  }

  OnCreatePDF() {
    if (this.record.length==0) {
      this.toastr.warning("No Data To Export");
      return;
    }
    const header = [];
    let body = [];
    let index = this.sessions.findIndex((se) => se.id == this.formRef.value.se_id);
    let se_nm = this.sessions[index]?.nm;
    let index2 = this.classes.findIndex(ind => ind.id==this.formRef.value.c_code);
    let c_nm = this.classes[index2]?.nm;
    let tot = this.totalStd;
    let exportDate = this.datePipe.transform(new Date(), "MMM d,y, h:mm a");
    let image = new Image();
    let page;
    let k=1;

    image.src = "../../../assets/images/logo3.png";
    header.push(["Sr #","Group","Combination","Set","Students"]);
    const doc = new jsPDF("p", "mm", "A4");
    this.record.forEach(e =>{
      body.push([k,e.Grp, e.Combination,e.Set_no,e.Students]);
      k++;
    })
    autoTable(doc, {
      theme: "grid",
      margin: { top: 46, bottom: 8 },
      head: header,
      body: body,
      didDrawPage: function () {
        doc.addImage(image, 180, 2, 25, 25);
        doc.setFontSize(10);
        doc.text(`${exportDate}`, 170, 30);
        doc.setFontSize(20);
        doc.setFont("Arial", "bold");
        doc.text("GC UNIVERSITY LAHORE", 100, 20, { align: "center" });
        doc.setFontSize(14);
        doc.setFont("Arial", "normal");
        doc.text(`Set wise strength`, 90, 27, {align: "center"});
        doc.text(`Class: ${c_nm} `, 90, 33, {align: "center"});
        doc.setFontSize(12);
        doc.text(`Session: ${se_nm}`, 90, 38, {align: "center"});
        page = doc.getNumberOfPages();
      },
    });
    doc.setFontSize(10);
    for (var i = 1; i <= page; i++) {
      doc.setPage(i);
      doc.setFont("Arial", "bold");
      doc.setFontSize(14);
      doc.text(`Total Students: ${tot}`,40,290);
      doc.setFont("Arial", "normal");
      doc.setFontSize(8);
      doc.text("Page " + i + " of " + page, 100, 295);
      doc.text("Directorate of Information & Technology",10,295)
      doc.text("Note:- Errors and Omissions Excepted", 160, 295);
    }
    doc.setProperties({
      title: `Subject Offer-${c_nm||''}-${se_nm}`,
      
    });
    window.open(URL.createObjectURL(doc.output('blob')), '_blank');
  }

}
