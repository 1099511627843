<div [@SlideInFromLeft]>
  <div class="card shadow">

    <div class="card-header"  style="padding-top: 10px;">
      <div class=" col-lg-6 col-md-6 form-group">
         Time Table</div>
         <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
        <app-details-button [details]="details"></app-details-button>
    </div>
  </div>
    <form (ngSubmit)="OnSubmit()" #f="ngForm">
      <div class="card-body row">
        <div class="col-lg-4 col-md-6 form-group">
          <label for="Day">Group</label>
          <input *ngIf="!isAdmin&&!isExam  && this.isPhilosophy!=410  && this.isPhilosophy!=320" type="text" class="form-control"
            value='{{ ((this.groups.length>0) ? groups[0].nm:" ")}}' name="grp" id="grp" readonly>
          <select *ngIf="isAdmin && this.isPhilosophy!=410" #grp class="form-control" name="grp" id="grp"
            (change)="OnGrpChange(grp)" ngModel>
            <option *ngFor="let grp of groups" value="{{grp.id}}">{{grp.nm}}</option>
          </select>
          <select *ngIf="this.isPhilosophy==410 || this.isPhilosophy==320" #grp class="form-control" name="grp" id="grp"
            (change)="OnGrpChange(grp)" ngModel>
            <option *ngFor="let grp of groups" value="{{grp.id}}">{{grp.nm}}</option>
          </select>
          <select *ngIf="showInter&&this.isPhilosophy!=410" #grp class="form-control" name="grp" id="grp"
            (change)="OnGrpChange(grp)" ngModel>
            <option *ngFor="let grp of interMediateGroup" value="{{grp.id}}"> {{grp.nm}} </option>
          </select>
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="C_Code">Class</label>
          <select #cource (change)="OnClassChange(cource)" class="form-control " name="c_code" id="C_Code" ngModel required>
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Session">Session</label>
          <select class="form-control " #session (change)="OnSessionChange(session)" name="se_id" id="Session" ngModel>
            <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="semester">Semester</label>
          <input type="number" class="form-control" name="t_no" id="Semester" min="0" max="8" #semester
            (change)="OnSemesterChange(semester)" ngModel required>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="Section">Section</label>
          <select #sec class="form-control " name="section" id="Section" (change)="onSecChange(sec)" ngModel>
            <option *ngFor="let s of sections" value="{{s}}">{{s}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="sub_code">Subject</label>
          <select #sec class="form-control " name="sub_code" id="sub_code" ngModel>
            <option value="null">Complete</option>
            <option *ngFor="let s of subs" ng-selected="false" value="{{s.sub_code}}">{{s.sub_code}}-({{s.sub_nm}})
            </option>
          </select>
        </div>

        <button  class="btn btn-primary mt-3 ml-3" type="submit" [disabled]="!f.valid">Fetch Timetable</button>
        <button *ngIf="pdfvalid" class="btn btn-danger mt-3" type="button" (click)="OnCreatePDF()"><i class="fa fa-file-pdf-o" for=""></i> Download PDF</button>
        <button class="btn btn-warning mt-3" type="button" (click)="ClearForm(f)">Clear</button>
      </div>
    </form>

    <div class="card-header" style="height: 0rem;"></div>
    <div class="card-body mb-3" style="background-color: transparent; min-height: 15rem;">

      <div class="list-group shadow" *ngFor="let entry of timetableEntries | keyvalue">
        <div class="d-flex">
          <app-timetable-entry [inData]='entry.value'
            [editable]='isAdmin || (this.group==entry.value.grp && entry.value.status <2)'
            (delete)="OnDeleteClick($event)" style="flex: auto;">
          </app-timetable-entry>
        </div>
      </div>
    </div>
  </div>
</div>