import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SlideInFromLeft } from '../../transitions';

import { TimetableService } from './Timetable.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { TimetableModel } from '../view-timetable/model/Timetable.model';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { DepartmentService } from './../shared/services/Department.service';
import { SubjectOfferService } from './../shared/services/SubjectOffer.service';
import { GroupService } from './../shared/services/Groups.service';
import { SelectList } from '../shared/model/SelectList.model';
import { TeacherService } from './../shared/services/Teacher.service';
import { ClassService } from './../shared/services/Class.service';
import { SessionService } from './../shared/services/Sessions.service';
import { BlockService } from './../shared/services/Block.service';
import { RoomService } from './../shared/services/Room.service';

@Component({
  selector: 'app-insert-time-table',
  templateUrl: './insert-time-table.component.html',
  styleUrls: ['./insert-time-table.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class InsertTimeTableComponent implements OnInit {
  departments: Array<SelectList>;
  classes: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<SelectList>;
  durations: Array<{ dur: string, des: string }>;
  timings: Array<{ start_time: string, desc: string }>;
  subjects: Array<{ sub_code: string, sub_nm: string, d_id }>;
  sections: Array<string>;
  teachers: Array<SelectList>;
  blocks: Array<SelectList>;
  rooms: Array<SelectList>;
  flag: boolean = true;
  groups: Array<SelectList>;
  manageTeacher: boolean = false;
  checkTeacher: boolean = false;
  checkRoom: boolean = false;
  addTeacher: boolean = false;
  timetableEntries: Map<number, TimetableModel>;
  cData: Map<number, TimetableModel>;
  isExam: boolean = false;

  @ViewChild('f') formRef: NgForm;

  isTeacherFree: boolean = true;
  isSectionFree: boolean = true;
  suboffered: boolean = false;

  selectedSubject: { sub_code: string, sub_nm: string, d_id };

  isAdmin: boolean = false;
  group: number = 0;
  dept: number = 0;
  sectionScheme: Array<string>;
  showInter: boolean = false;
  interMediateGroup: Array<SelectList>;
  selected_c_code: number;
  add_teacher: boolean = true;
  data: any;
  isPhilosophy: any;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  this screen is used to insert the timetable of all the offered subjects of any semester of any session of
  a major in its class.If you mark on "Publish timetable " then it will visible to teacher other wise it will be in draft mode.You can also choose other
  department's teachers in "Manage Teacher" tab and can also check the teacher and room availibility by clicking on their 
  respective tabs </h6>
  <h6 class="text-right">یہ اسکرین کسی بھی میجر کی کلاس کے کسی بھی سیشن کے کسی بھی سمسٹر کے تمام آفر کردہ سبجیکٹس کا ٹائم ٹیبل داخل کرنے کے لیے استعمال ہوتی ہے۔ اگر آپ "پبلش ٹائم ٹیبل" پر نشان لگائیں گے تو یہ استاد کو نظر آئے گا، ورنہ یہ ڈرافٹ موڈ میں رہے گا۔ آپ "مینج ٹیچر" ٹیب میں دوسرے ڈیپارٹمنٹ کے اساتذہ کو بھی منتخب کر سکتے ہیں
   اور ان کے متعلقہ ٹیبز پر کلک کر کے استاد اور کمرے کی دستیابی بھی چیک کر سکتے ہیں۔
</h6>
  `
  constructor(
    private authenticationService: AuthenticationService,
    private timetableService: TimetableService,
    private toastr: ToastrService,
    private deptService: DepartmentService,
    private subOfferService: SubjectOfferService,
    private groupService: GroupService,
    private teacherService: TeacherService,
    private classService: ClassService,
    private sessionService: SessionService,
    private blockService: BlockService,
    private roomService: RoomService
  ) {
    this.departments = new Array<SelectList>();
    this.classes = new Array<SelectList>();
    this.majors = new Array<SelectList>();
    this.sessions = new Array<SelectList>();
    this.durations = new Array<{ dur: string, des: string }>();
    this.timings = new Array<{ start_time: string, desc: string }>();
    this.subjects = new Array<{ sub_code: string, sub_nm: string, d_id }>();
    this.teachers = new Array<SelectList>();
    this.sections = new Array<string>();
    this.blocks = new Array<SelectList>();
    this.rooms = new Array<SelectList>();
    // this.interMediateGroup = [];
    this.sectionScheme = new Array<string>();
    this.timetableEntries = new Map<number, TimetableModel>();
    this.cData = new Map<number, TimetableModel>();;
    this.groups = new Array<SelectList>();
    this.isAdmin = this.authenticationService.isAdmin();
    this.group = this.authenticationService.getGroup();
    this.dept = this.authenticationService.getUser().D_ID;
    this.sectionScheme = ['A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6', 'E7'];
  }
  allow: boolean = true;
  loader = false;
  ngOnInit(): void {
    this.getTimeDuration();
    this.isPhilosophy = this.group;
    if (this.isPhilosophy == 410) {
      this.sectionScheme = [];
      this.sectionScheme = ['ID1', 'ID2', 'ID3', 'ID4', 'ID5', 'ID6', 'ID7', 'ID8', 'ID9', 'ID10', 'ID11', 'ID12', 'ID13', 'ID14', 'ID15'];
      this.loadTeachers();
      this.loadGroups();
    } else {
      this.loadGroups();
      if (this.isAdmin) {
        this.loadDepartments();
      } else {
        this.loadTeachers();
        if (this.group == 1 || this.group == 5 || this.group == 10 || this.group == 15 || this.group == 20 || this.group == 25 || this.group == 30 || this.group == 35 || this.group == 40)
          this.getIntermediateSectionScheme();
        else
          this.getSectionScheme();
      }
      // this.updateIntermediateGroups();
    }
  }

  getTimeDuration() {
    this.timetableService.getTimeDuration().subscribe((res) => {
      if (res) {
        res[0]?.forEach(entry => {
          this.timings.push({ start_time: entry.start_time, desc: entry.des });
        });
        res[1]?.forEach(entry => {
          this.durations.push({ dur: entry.duration, des: entry.d_des });
        });
      }
    });
  }

  // updateIntermediateGroups() {
  //   this.interMediateGroup = [
  //     { id: 1, nm: 'INTERMEDIATE' },
  //     { id: 5, nm: 'INTERMEDIATE (ARTS)' },
  //     { id: 10, nm: 'INTERMEDIATE (ICOM)' },
  //     { id: 15, nm: 'INTERMEDIATE (Pre-Eng)' },
  //     { id: 20, nm: 'INTERMEDIATE (Pre-Med)' },
  //     { id: 25, nm: 'INTERMEDIATE (General Sc)' },
  //     { id: 30, nm: 'INTERMEDIATE (Computer Sc)' },
  //     { id: 35, nm: 'INTERMEDIATE PE O-Level' },
  //     { id: 40, nm: 'INTERMEDIATE PM O-Level' }
  //   ]
  // }
  loadDepartments() {
    this.deptService.getDepartments({ admin: true, filter: true }).subscribe((res: { D_ID: number, D_NM: string }[]) => {
      res?.forEach(entry => {
        this.departments.push({ id: entry.D_ID, nm: entry.D_NM });
      });
    });
  }


  loadTeachers() {
    this.teachers = [];
    this.teacherService.getTeachers(this.dept).subscribe((res: { FM_ID: number, FM_NM }[]) => {
      res?.forEach(element => {
        this.teachers.push({ id: element.FM_ID, nm: element.FM_NM });
      });
    });
  }

  loadGroups() {
    this.groupService.getGroups({ grp: this.group, role: this.isAdmin }).subscribe((res: { GRP: number, G_NM: string }[]) => {
      res?.forEach(element => {
        this.groups.push({ id: element.GRP, nm: element.G_NM });
      });
      if (this.groups[0]?.id == 1)
        this.showInter = true;
      if (!this.isAdmin) {
        this.updateClasses();
      }
    }, err => {
      console.log(err);
    });
  }


  getSectionScheme() {
    console.log(this.group)
    this.timetableService.getSectionScheme({ grp: this.group, d_id: this.dept }).subscribe(res => {
      var s = this.sectionScheme
      this.sectionScheme = []
      res[0].forEach(element => {
        for (let i = 0; i < 14; i++) {
          this.sectionScheme.push(element.sec + s[i]);
        }
      })
      
    })
  }

  OnSubmit() {
    this.allow = false;
    if (!this.formRef.valid)
      return;
    this.loader = true;
    var param = this.formRef.value;
    param.grp = this.group.toString();
    param.d_id = this.selectedSubject.d_id;
    param.sub_code = this.selectedSubject.sub_code;

    this.timetableService.validateTime(param).subscribe(res => {
      if (res === 1) {
        this.timetableService.insertTimetable(param).subscribe((r: {
          sub_code: string, tm_id: number, d_id: number, d_nm: string, c_nm: string, se: number, t_no: number, section: string, subject: string, fm_id: string, teacher: string, day: string, start_time: string, end_time: string, blk_id: string, blk_nm: string, rm_id: string, rm_nm: string, status: number

        }) => {
          if (!r) {
            this.loader = false;
            this.toastr.clear();
            this.toastr.warning("Failed To Insert");
          } else {
            this.loader = false;
            this.toastr.clear();
            this.toastr.success("Inserted successfully");
            this.timetableEntries.set(r.tm_id, new TimetableModel(r.sub_code, 0, r.tm_id.toString(), r.d_id, r.d_nm, r.c_nm, r.se, r.t_no, r.section, r.subject, r.fm_id, r.teacher, r.day, r.start_time, r.end_time, r.blk_id, r.blk_nm, r.rm_id, r.rm_nm, r.status));
            
            this.formRef.form.patchValue({ fm_id: '' });
            this.formRef.form.patchValue({ section: '' });
            this.formRef.form.patchValue({ day: '' });
            this.formRef.form.patchValue({ s_time: '' });
            this.formRef.form.patchValue({ duration: '' });
            this.formRef.form.patchValue({ blk_id: '' });
            this.formRef.form.patchValue({ rm_id: '' });
            
            if (this.selected_c_code == 1)
              this.add_teacher = false;
            this.data = this.formRef.value;

          }
        },
          err => {
            this.loader = false;
            console.log(err.message);
            this.toastr.clear();
            this.toastr.error(err.message);
          });

      } else if (res === -1) {
        this.loader = false;
        this.toastr.clear();
        this.toastr.warning("Section is not Available");
        this.isSectionFree = false;
      } else if (res === -2) {
        this.loader = false;
        this.toastr.clear();
        this.toastr.warning("Teacher is not Available");
        this.isTeacherFree = false;
      } else {
        this.loader = false;
        this.toastr.clear();
        this.toastr.error("Some error Occured");
      }

    }, err => {
      this.loader = false;
      console.log(err);
      this.toastr.clear();
      this.toastr.error("Some error Occured");
    });
  }

  OnBuildingChange(blk: HTMLSelectElement) {
    this.allow = true;
    this.updateRoom();
  }

  OnClassChange(c: HTMLSelectElement) {
    if (this.formRef.value.c_code == 1) {
      this.selected_c_code = this.formRef.value.c_code;
    }


    this.updateSessions();
  }
  OnGrpChange(grp: HTMLSelectElement) {
    // this.sectionScheme = [];
    console.log(grp.value)
    this.group = parseInt(grp.value);
    // this.getSectionScheme();
    console.log(this.group)
    if (this.group != 410 && this.isPhilosophy == 410) {
      console.log(1)
      this.sectionScheme = [];
      this.sectionScheme = ['ID1', 'ID2', 'ID3', 'ID4', 'ID5', 'ID6', 'ID7', 'ID8', 'ID9', 'ID10', 'ID11', 'ID12', 'ID13', 'ID14', 'ID15'];
    } else if (this.group == 410) {
      console.log(2)
      this.sectionScheme = [];
      this.sectionScheme = ['PLA1', 'PLA2', 'PLA3', 'PLA4', 'PLA5', 'PLA6', 'PLA7', 'PLE1', 'PLE2', 'PLE3', 'PLE4', 'PLE5', 'PLE6', 'PLE7'];

    }
    else if (this.group == 657 || this.group == 656 || this.group == 658 || this.group == 659) {
      console.log(5)
      this.sectionScheme = [];
      this.sectionScheme = ['ETA1', 'ETA2', 'ETA3', 'ETA4', 'ETA5'];
    }
    else if(this.group != 320){      
      console.log(3)
      this.getIntermediateSectionScheme();
    }      console.log(4)

    this.updateClasses();
    this.updateSubjects();
  }

  getIntermediateSectionScheme() {
    this.sectionScheme = [];
    if (this.group == 1) {
      this.sectionScheme = ['EM1'];
    }
    else if (this.group == 5) {
      this.sectionScheme = ['A6', 'B6', 'C6', 'D6', 'E6', 'F6', 'G6', 'H6', 'I6', 'J6'];
    }
    else if (this.group == 10) {
      this.sectionScheme = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5', 'I5', 'J5'];
    }

    else if (this.group == 15) {
      this.sectionScheme = ['A2', 'B2', 'C2', 'D2', 'E2', 'F2', 'F2', 'G2', 'H2', 'I2', 'J2'];
    }

    else if (this.group == 20) {
      this.sectionScheme = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1'];
    }
    else if (this.group == 25) {
      this.sectionScheme = ['A4', 'B4', 'C4', 'D4', 'E4', 'F4', 'F4', 'G4', 'H4', 'I4', 'J4'];
    }
    else if (this.group == 30) {
      this.sectionScheme = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'F3', 'G3', 'H3', 'I3', 'J3'];
    }
    else if (this.group == 35) {
      this.sectionScheme = ['OL2'];
    }
    else if (this.group == 40) {
      this.sectionScheme = ['OL1'];
    }
  }

  OnDeptChange(d: HTMLSelectElement) {
    this.dept = parseInt(d.value);
    if (this.isAdmin) {
      this.loadTeachers();
    }
  }


  checkSubject() {
    if (!this.group || this.formRef.value.c_code === "" ||
      this.formRef.value.se_id === "" || this.formRef.value.t_no === "") return;


    if (this.subjects.length < 1 && this.suboffered) {
      this.suboffered = false;
      alert("\nSubject offer is missing. \nFirst Offer Subjects.");
    }
  }

  OnSectionChange() {
    this.allow = true;
    this.updateRoom();
    this.validate();
  }

  OnDurChange(dur) {
    this.allow = true;
    this.updateRoom();
    this.validate();
  }

  OnDayChange(day) {
    this.allow = true;
    this.validate();
    this.updateRoom();
  }

  OnSessionChange(s: HTMLSelectElement) {
    this.updateSubjects();
    this.updateRoom();
  }

  OnSemesterChange(sem: HTMLSelectElement) {
    this.updateSubjects();
    this.updateRoom();
  }

  onSubChange(s: HTMLSelectElement) {
    // this.allow = true;
    const index = parseInt(s.value);
    this.selectedSubject = this.subjects[index];
    // this.validate();
  }

  OnTeacherChange(teacher: HTMLSelectElement) {
    this.allow = true;
    if (!this.isTeacherFree) {
      this.validate();
    }
  }


  OnTimeChange(time) {
    this.allow = true;
    this.validate();
    this.updateRoom();
  }

  onRoomChange() {
    this.allow = true;
  }
  OnManageTeacher() {
    this.manageTeacher = true;
  }

  OnCloseManageTeacher() {
    this.manageTeacher = false;
  }
  OnCloseAvailableTeacher() {
    this.checkTeacher = false;
  }

  OnNewTeacher(teacher) {
    this.teachers.push(teacher);
  }

  OnDeleteClick(tmId) {
    this.timetableEntries.delete(parseInt(tmId));
  }



  validate() {
    var param = this.formRef.value;
    param.grp = this.group.toString();
    param.d_id = this.selectedSubject?.d_id;
    param.sub_code = this.selectedSubject?.sub_code;

    if (this.formRef.value.c_code === "" || this.formRef.value.se_id === "" ||
      this.formRef.value.t_no === "" || this.formRef.value.section === "") return;

    if (this.formRef.value.duration && this.formRef.value.s_time && this.formRef.value.day) {
      this.timetableService.validateTime(param).subscribe(res => {
        if (res === -1) {
          this.isSectionFree = false;
        } else if (res === -2) {
          this.isSectionFree = true;
          this.isTeacherFree = false;
        } else {
          this.isTeacherFree = true;
          this.isSectionFree = true;
          this.updateBuilding();
        }
      }, err => {
        console.log(err.message);
      });
    }
  }


  updateClasses() {
    if (!this.group)
      return;

    this.classes = [];
    this.majors = [];
    this.sessions = [];
    this.subjects = [];
    this.sections = [];
    this.classService.getClasses({ grp: this.group }).subscribe((res: { C_CODE: number, C_NM }[]) => {
      res?.forEach(element => {
        this.classes.push({ id: element.C_CODE, nm: element.C_NM });
      });
    }, err => {
      console.log(err);
    });
  }


  updateSessions() {
    this.sessions = [];
    this.subjects = [];
    this.sections = [];

    if (this.formRef.value.c_code === "") return;

    this.sessionService.getSessions(this.formRef.value.c_code).subscribe((res: { SE_ID: number, SE_NM: string }[]) => {
      res?.forEach(entry => {
        this.sessions.push({ id: entry.SE_ID, nm: entry.SE_NM });
      });
    });
  }

  updateSubjects() {
    if (!this.group || this.formRef.value.c_code === "" ||
      this.formRef.value.se_id === "" || this.formRef.value.t_no === "") return;

    this.subjects = [];
    this.sections = [];
    this.subOfferService.getOfferedSubByGroup(this.group.toString(), this.formRef.value.c_code, this.formRef.value.se_id, this.formRef.value.t_no).subscribe((res: { SUB_CODE: string, D_ID: string, SUB_TITLE_PRINT: string }[]) => {
      res?.forEach(entry => {
        this.subjects.push({ sub_code: entry.SUB_CODE, sub_nm: entry.SUB_TITLE_PRINT, d_id: entry.D_ID });
      });
      this.suboffered = true;
    }, err => {
      this.toastr.clear();
      this.toastr.error("Unknown Error");
    });
  }


  updateBuilding() {
    this.rooms = [];
    this.blocks = [];
    this.blockService.getBlocks().subscribe((res: { BLK_ID: number, BLK_NM: string }[]) => {
      res?.forEach(entry => {
        this.blocks.push({ id: entry.BLK_ID, nm: entry.BLK_NM });
      });
    });
  }
  updateRoom() {

    if (this.formRef.value.blk_id === "" || this.formRef.value.day === "" ||
      this.formRef.value.s_time === "" || this.formRef.value.duration === "") return;


    this.rooms = [];
    this.roomService.getRooms(this.formRef.value.blk_id, this.formRef.value.day, this.formRef.value.s_time, '00:00', this.formRef.value.duration, this.formRef.value.status ? 1 : 0).subscribe((res: { RM_ID: number, RM_NM: string }[]) => {
      res?.forEach(entry => {
        this.rooms.push({ id: entry.RM_ID, nm: entry.RM_NM });
      });

    });
  }
  checkTeacherAvailable() {
    this.checkTeacher = true;
  }
  checkRoomAvailable() {
    this.checkRoom = true;
  }
  onCloseRoomAvailable() {
    this.checkRoom = false;
  }

  AddTeacher() {

    this.addTeacher = true;
  }

  onCloseAddTeacher() {
    this.addTeacher = false;
  }

  ch_data(data: any) {
    this.cData = data;
  }
}
