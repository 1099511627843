<div [@SlideInFromLeft] class="container-fluid">
    <div class="row card shadow">

        <div class="card-header d-flex justify-content-between">
            <div>Student Responses Detail</div>
        </div>
        <form #f="ngForm">
            <div class="card-body">
                <div class="row" style="margin-left: -18px;">

                    <div class="col-lg-3 col-md-6 form-group">
                        <label for="frm_id">Form Name</label>
                        <select #frm_id class="form-control" name="frm_id" id="frm_id" ngModel required>
                            <option *ngFor="let k of formName" value="{{k.id}}">{{k.nm}}</option>
                        </select>
                    </div>

                    <!-- <div class="col-lg-3 col-md-6 form-group">
                        <label for="grp">Group</label>
                        <select #grp class="form-control" name="grp" id="grp" (change)="OnGrpChange(grp)" ngModel
                            required>
                            <option *ngFor="let grp of groups" value="{{grp.id}}">{{grp.nm}}</option>
                        </select>
                    </div> -->

                    <div class="col-lg-3 col-md-6 form-group">
                        <label for="c_code">Class</label>
                        <select #c_code class="form-control" name="c_code" id="c_code" (change)="onClassChange()"
                            ngModel required>
                            <option *ngFor="let d of classes" value="{{d.id}}">{{d.nm}}</option>
                        </select>
                    </div>

                    <div class="col-lg-3 col-md-6 form-group">
                        <label for="maj_id">Major</label>
                        <select class="form-control" #maj_id name="maj_id" id="maj_id" ngModel required>
                          <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
                        </select>
                      </div>

                    <div class="col-lg-3 col-md-6 form-group">
                        <label for="se_id">Session</label>
                        <select #se_id class="form-control" name="se_id" id="se_id" ngModel required>
                            <option *ngFor="let d of sessions" value="{{d.id}}">{{d.nm}}</option>
                        </select>
                    </div>

                    <div class="col-lg-3 col-md-6 form-group">
                        <label for="t_no">Semester</label>

                        <select #semester class="form-control" name="t_no" id="t_no" (change)="OnSemesterChange()"
                            ngModel required>
                            <option *ngFor="let tNo of [0,1,2,3,4,5,6,7,8]" value="{{tNo}}">{{tNo}}</option>
                        </select>
                    </div>
                    <div class="col-lg-3 col-md-6 form-group">
                        <label for="section">Section</label>
                        <select #sec class="form-control" name="section" id="section" (change)="OnSectionChange()" ngModel required>
                            <option *ngFor="let d of section" value="{{d}}">{{d}}</option>
                        </select>
                    </div>

                    <!-- <div class="col-lg-3 col-md-6 form-group">
                        <label for="sub_code">Subjects</label>
                        <select #sub_code class="form-control" name="sub_code"
                            id="sub_code" ngModel required>
                            <option *ngFor="let d of subs" value="{{d.sub_code}}">{{d.sub_nm}}-({{d.sub_code}})</option>
                        </select>
                    </div> -->

                    
                    
                    <div id="container">
                        <div *ngFor="let d of subs" class="card shadow" style="width: 23rem; height: 21rem;border-radius: 2rem;margin-top: 1rem;">
                          <div class="card-header" style="border-top-left-radius: 2rem;border-top-right-radius: 2rem; text-align: center;">
                            {{d.sub_code}}-{{d.sub_nm}}
                          </div>
                          <div class="card-body" style="padding:1rem 0 0 0;">
                            
                            <canvas id={{d.sub_code}}>
                                
                            </canvas>
                          </div>
                          <div class="card-footer" style="border-bottom-left-radius: 2rem;border-bottom-right-radius: 2rem;">
                            <div class="row" style="justify-content: right;">
                              <button (click)="GetFormsData(d.sub_code)" style="margin-right: 2rem;"class="btn btn-danger">Details</button>
                            </div>
                          </div>
                        </div>
                        </div>
                        




                </div>                

                <div class="row d-flex justify-content-between">
                    <!-- <div>
                        
                        <button type="button" class="btn btn-outline-success ml-3" (click)="onSubmit(1)"
                            style="height: 36px;">Student Who Submit Form</button>
                        <button type="button" class="btn btn-outline-info ml-1" (click)="onSubmit(2)"
                            style="height: 36px;">Student Who Not Submit Form</button>                    
                    </div> -->

                    

                    <div>
                        <div class="row mr-3" (click)="downloadStdDetail()" *ngIf="stdDetail.length>0">
                            <i class='fas fa-file-download fa-3x'></i> <!--fa-spin-->
                            <button type="button" class="btn btn-outline-secondary ml-2" >Create PDF</button>
                        </div>
                    </div>

                </div>
                <hr>
                <div *ngIf="stdDetail.length>0" class="card-header d-flex justify-content-between">
                    <div>Student Details</div>
                </div>

                <div class="table-responsive" *ngIf="stdDetail.length>0">
                    <table class="table table-hover table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Sr.No</th>
                                <th scope="col">Roll #</th>
                                <th scope="col">Name</th>                                
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let d of stdDetail; let i=index;">
                                <th  scope="row">{{i+1}}</th>
                                <td>{{d.ROLNO}}</td>
                                <td>{{d.NM}}</td>                                
                                <td>
                                    <!-- <button class="btn btn-outline-primary">Action</button> -->
                                    <input type="checkbox"
                                    [checked]="d.STATUS==1?1:0">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- 
                    <div class="table-responsive">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Sr.No</th>
                                <th scope="col">Form Name</th>
                                <th scope="col">Group</th>
                                <th scope="col">Class</th>
                                <th scope="col">Session</th>
                                <th scope="col">Semester</th>
                                <th scope="col">Section</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let in of infoReport; let i=index;">
                                <th scope="row">{{i+1}}</th>
                                <td>{{in.FRM_NM}}</td>
                                <td>{{in.G_NAME}}</td>
                                <td>{{in.CLASS_NM}}</td>
                                <td>{{in.SE_NM}}</td>
                                <td>{{in.T_NO}}</td>
                                <td>{{in.SECTION}}</td>
                                <td>
                                    <button class="btn btn-outline-primary">Un-Publish</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> 
            -->
            </div>
        </form>
    </div>
</div>