import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
@Injectable({
  providedIn: 'root'
})

export class ExamSummaryService {

  constructor(private http: HttpClient) {
  }

  report(params) {
    return this.http.post(`${baseUrl}/api/examSummary/U_report`, params);
  }
  notEnroll(params) {
    return this.http.post(`${baseUrl}/api/enrollment/notEnroll`, params);
  }
  // chnages by suffyan
  notEnroll_ExamSum(params) {
    return this.http.post(`${baseUrl}/api/enrollment/notEnroll_ExamSum`, params);
  }
  singleEnroll(params) {
    return this.http.post(`${baseUrl}/api/enrollment/single_enrollment`, params);
  }
  onDelete(c_code, maj_id, year_in, t_no) {
    return this.http.get(`${baseUrl}/api/examSummary/deleteButtonTerm/${c_code}/${maj_id}/${year_in}/${t_no}`);
  }
  onLock(c_code, year_in, t_no) {
    return this.http.get(`${baseUrl}/api/examSummary/lockButtonTerm/${c_code}/${year_in}/${t_no}`);
  }
  onResultCompile(c_code, year_in, maj_id, t_no) {
    return this.http.get(`${baseUrl}/api/examSummary/resultCompile/${c_code}/${year_in}/${maj_id}/${t_no}`);
  }

  getStdExamSum(c_code, year_in, maj_id, t_no) {
    return this.http.get(`${baseUrl}/api/examSummary/getStdExamSum/${c_code}/${year_in}/${maj_id}/${t_no}`);
  }
  gettranscriptrecievings(c_code, year_in, maj_id, t_no) {
    return this.http.get(`${baseUrl}/api/examSummary/gettranscriptrecievings/${c_code}/${year_in}/${maj_id}/${t_no}`);
  }

  onDeleteSingle(c_code, maj_id, year_in, t_no, rolno) {
    return this.http.get(`${baseUrl}/api/examSummary/deleteButtonSingle/${c_code}/${maj_id}/${year_in}/${t_no}/${rolno}`);
  }

  onResultCompileSingle(c_code, year_in, t_no, maj_id, rolno) {
    return this.http.get(`${baseUrl}/api/examSummary/singleResultCompile/${c_code}/${year_in}/${t_no}/${maj_id}/${rolno}`);
  }

  //Exam Result Status update Exempted

  OnExamStatusExempted(params) {
    return this.http.post(`${baseUrl}/api/examSummary/OnExamStatusExempted`, params);
  }


  OnSubjectReplacementInsert(params) {
    return this.http.post(`${baseUrl}/api/examSummary/OnSubjectReplacementInsert`, params);
  }

  OnExemptedSubjects(params) {
    return this.http.post(`${baseUrl}/api/examSummary/OnExemptedSubjects`, params);
  }
  OnSubReplaced(params) {
    return this.http.post(`${baseUrl}/api/examSummary/OnSubReplaced`, params);
  }
  deleteExamRslt(c_code, year, se_id, t_no, rolno, sub_code) {
    return this.http.get(`${baseUrl}/api/examSummary/OnDeleteRslt/${c_code}/${year}/${se_id}/${t_no}/${rolno}/${sub_code}`);
  }
  updateOrigTno(params) {
    return this.http.post(`${baseUrl}/api/examSummary/updateOrigTno`, params);
  }
  updateAttpNo(params) {
    return this.http.post(`${baseUrl}/api/examSummary/updateAttpNo`, params);
  }  
  resultTransfer(params) {
    return this.http.post(`${baseUrl}/api/examSummary/resultTransfer`, params);
  }
}