<div class=" container-fluid" [@SlideInFromLeft]>
  <form #f="ngForm">
    <div class="container-fluid">
      <div class="row card shadow px-2">
        <div class="card-header" style="padding-top: 10px;">
          <div class=" col-lg-6 col-md-6 form-group">
            Search Student By Name or Roll Number or With Both</div>
          <div class="col-lg-6 col-md-6 form-group"
            style="text-align: right; text-transform: capitalize;">
            <app-details-button [details]="details"></app-details-button>
          </div>
        </div>

        <div class="row card-body pb-0 col-sm-12 px-4">
          <div class="col-lg-6 col-md-6 col-sm-12 form-group">
            <label for="studentName">Student Name</label>
            <input
              type="text"
              id="nm"
              name="nm"
              class="form-control"
              ngModel />
          </div>

          <div class="col-lg-6 col-md-6 col-sm-12 form-group">
            <label for="rollNumber">Roll Number</label>
            <input
              type="text"
              id="rolno"
              name="rolno"
              class="form-control"
              ngModel />
          </div>
        </div>

        <div class="row card-body px-4 ml-1">
          <button class="btn btn-primary" (click)="getData()">
            Search
          </button>
          <button class="btn ml-2 btn-warning" (click)="onClear()">
            Clear
          </button>
        </div>
        <div
          style="display: flex; align-items: center; justify-content: center;"
          class="col-12 mt-5"> <loader *ngIf="loader"></loader></div>

        <div *ngIf="data.length>0" class="px-4">
          <div class="px-2">
            <table class="table table-striped table-bordered"
              id="myTable">
              <thead class="thead-dark">
                <tr>
                  <th>Year</th>
                  <th>Roll Number</th>
                  <th>Name</th>
                  <th>Father Name</th>
                  <th>Major</th>
                  <th>Remarks</th>
                  <th>Action</th>
                  <!-- <th>Detail</th> -->
                </tr>
                <tr>
                  <th class="form-group pl-2 pr-2" style="width:10%">
                    <input class="form-control" id="myInput1" type="text" placeholder="Search" style="font-size:12px"
                      (keydown)="CategoryFilter($event)">
                  </th>
                  <th class="form-group pl-0 pr-2" style="width:9%">
                    <input class="form-control" id="myInput2" type="text" placeholder="Search" style="font-size:12px"
                    (keydown.enter)="$event.preventDefault()" (keyup)="Filter($event, '2')">
                  </th>
                  <th class="form-group pl-0 pr-2" style="width:12%">
                    <input class="form-control" id="myInput3" type="text" placeholder="Search" style="font-size:12px"
                    (keydown.enter)="$event.preventDefault()" (keyup)="Filter($event, '3')">
                  </th>
                  <th class="form-group pl-0 pr-2" style="width:12%">
                    <input class="form-control" id="myInput4" type="text" placeholder="Search" style="font-size:12px"
                    (keydown.enter)="$event.preventDefault()" (keyup)="Filter($event, '4')">
                </th>
                <th class="form-group pl-0 pr-2" style="width:7%">
                    <input class="form-control" id="myInput5" type="text" placeholder="Search" style="font-size:12px"
                    (keydown.enter)="$event.preventDefault()" (keyup)="Filter($event, '5')">
                </th>
                <th class="form-group  pl-0 pr-2" style="width:13%">
                    <input class="form-control" id="myInput6" type="text" placeholder="Search" style="font-size:12px"
                    (keydown.enter)="$event.preventDefault()" (keyup)="Filter($event, '6')">
                </th>
                  <th class="form-group  pl-0 pr-1" style="width:13%">
                    <input class="form-control" id="myInput7" type="text" placeholder="Search" style="font-size:12px"
                      (keydown)="CategoryFilter($event)">
                  </th>
                </tr>
              </thead>
              <tbody id="myBody">
                <tr *ngFor="let s of data;let j=index"
                  id="values">
                  <td>
                    {{s.year}}
                  </td>
                  <td>
                    {{s.rolno}}
                  </td>
                  <td>
                    {{s.nm}}
                  </td>
                  <td>
                    {{s.f_nm}}
                  </td>
                  <td>
                    {{s.major}}
                  </td>
                  <td>
                    {{s.remarks}}
                  </td>
                  <td>
                    <button class="btn btn-primary"
                      style="font-size:13px;padding-top:3%;padding-bottom:3%;margin-right:2%;margin-left:3%"
                      (click)="getDetails(s.rolno,s.c_code,s.se_id,s.t_no)">View
                      Student Details</button>
                    <app-modal-pop-up *ngIf="stddata && rolno == s.rolno"
                      [data]="{rolno: s.rolno, c_code: s.c_code, se_id: s.se_id, t_no: 9, stdt: false}"
                      id="s.rolno"
                      [header]="'Detailed Student'"
                      [component]="'detailedstudent'"></app-modal-pop-up>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
