<div class="backdrop">
  <div class="row">
    <div class="col-sm-6 offset-sm-3 ml-2 mr-2 ml-sm-auto mr-sm-auto">
      <div class="alert-box">
        <form #form="ngForm" action="submit" class="clearfix" (ngSubmit)="OnChangeClicked(form)">
          <div class="form-group mb-4">
            <label for="usr">User Name<span class="required">*</span></label>
            <input type="text" class="form-control" ngModel name="usr" id="usr" required>
          </div>
          <div class="form-group mb-4">
            <label for="email">Registered E-mail<span class="required">*</span></label>
            <input type="email" class="form-control" ngModel name="email" id="email" required>
          </div>
          <div class="alert-box-actions">
            <button class="btn btn-danger float-right" type="submit" [disabled]="form.invalid">Recover</button>
            <button class="btn btn-outline-secondary float-right mr-2" type="button" (click)="onClose()">Close</button>
          </div>
          <div class="note">NOTE: Fill in the required fields</div>
        </form>
      </div>
    </div>
  </div>
</div>