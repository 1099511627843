<div [@SlideInFromLeft]>
    <div class="card shadow">
        <div class="card-header"  style="padding-top: 10px;">
            <div class=" col-lg-6 col-md-6 form-group">
              Set Wise Strength for Class</div>
               <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
              <app-details-button [details]="details"></app-details-button>
          </div>
        </div>        <form (ngSubmit)="OnSubmit()" #f="ngForm" class="row">
            <div class="card-body row">
                <div class="col-lg-4 col-md-6 form-group">
                    <label for="c_code">Class</label>
                    <select #cource (change)="OnClassChange()"
                        class="form-control " name="c_code" id="c_code" ngModel
                        required>
                        <option *ngFor="let c of classes"
                            value="{{c.id}}">{{c.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 form-group">
                    <label for="se_id">Session</label>
                    <select class="form-control " #session name="se_id"
                        id="se_id" ngModel required>
                        <option *ngFor="let s of sessions"
                            value="{{s.id}}">{{s.nm}}</option>
                    </select>
                </div>
            </div>
        </form>
        <div class="mb-4 px-3" style="margin-top:-20px">
            <button [disabled]="!f.valid" class="btn btn-primary ml-2 smallBtn"
                (click)="OnSubmit()">Get Strength</button>
            <button class="btn btn-danger ml-2 smallBtn" type="button"
                (click)="OnCreatePDF()">
                <i class="fa fa-file-pdf-o" for></i> Download PDF</button>
            <button class="btn btn-warning ml-2 smallBtn"
                (click)="ClearForm(f)">Clear</button>
        </div>
        <div class="col-12 center"> <loader *ngIf="loader"></loader></div>

        <div class="card-header" style="height: 0rem;"></div>

        <div *ngIf="this.record?.length>0" class="card-body mb-3 px-4"
            style="background-color: transparent; min-height: 15rem;">

            <h4 class="mb-2" *ngIf="totalStd!=0">
                Total Students {{totalStd}}
            </h4>

            <table class="table table-bordered table-striped m-0 p-0 ">
                <thead class="thead-dark">
                    <tr class="table-active">
                        <th>Sr #</th>
                        <th>Group</th>
                        <th>Combination</th>
                        <th>Set #</th>
                        <th>Students</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let roll of record; let i=index"
                        class="text-center; font-weight: bold">
                        <td>
                            {{i+1}}
                        </td>
                        <td>
                            {{roll.Grp}}
                        </td>
                        <td>
                            {{roll.Combination}}
                        </td>
                        <td>
                            {{roll.Set_no}}
                        </td>
                        <td>
                            {{roll.Students}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>