import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  constructor(private http: HttpClient) {
  }

  getFaqs() {
    return this.http.get(`${baseUrl}/api/faq/getfaqs`);
  }

  addFaq(params) {
    return this.http.post(`${baseUrl}/api/faq/addfaqs`,params);
  }
}
