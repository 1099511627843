<form (ngSubmit)="OnSubmit()" #f="ngForm">
  <div class=" container-fluid" [@SlideInFromLeft]>
    <div class="row card shadow">
      <div class="card-header"  style="padding-top: 10px;">
        <div class=" col-lg-6 col-md-6 form-group">
          Merit List Report</div>
           <div class="col-lg-6 col-md-6 form-group" style="text-align: right; text-transform: capitalize;">
          <app-details-button [details]="details"></app-details-button>
      </div>
    </div>
      <div class="row card-body pb-0 px-4">

        <!-- <div class="col-lg-4 col-md-6 form-group">
            <label for="Day">Group</label>
            <input *ngIf="!isAdmin" type="text" class="form-control"
              value='{{ ((this.groups.length>0) ? groups[0].nm:" ")}}' name="grp" id="grp" readonly>
            <select *ngIf="isAdmin" #grp class="form-control" name="grp" id="grp" (change)="OnGrpChange(grp)" ngModel
              required>
              <option *ngFor="let grp of groups" value="{{grp.id}}">{{grp.nm}}</option>
            </select>
          </div> -->

        <div class="col-lg-4 col-md-6 form-group">
          <label for="C_Code">Class</label>
          <select #cource (change)="OnClassChange(cource)" class="form-control "
            name="c_code" id="C_Code" ngModel (change)="ClearClassBase()">
            <option *ngFor="let c of classes" value="{{c.id}}">{{c.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="major">Major</label>
          <select class="form-control" #major (change)="OnMajorChange(major)"
            name="maj_id" id="major" ngModel (change)="ClearMajorBase()">
            <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="year">Year</label>
          <input type="number" class="form-control" name="year" min="2000"
            ngModel required (change)="ClearYearBase()">
        </div>
        <div class="col-lg-4 col-md-6 form-group">
          <label for="base">Base</label>
          <select class="form-control" #base (change)="onBaseChange(base)"
            name="b_id" id="base" ngModel>
            <option value="0">All</option>
            <option *ngFor="let a of admBase" value="{{a.id}}">{{a.nm}}</option>
          </select>
        </div>

        <div class="col-lg-4 col-md-6 form-group">
          <label for="merit">Merit List</label>
          <span class="login-box" style="margin-top: -47px;float:right">
            <form>
              <a style="font-size:9px;font-weight:bold">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                IF MERIT LIST REQUIRED
              </a>
            </form>
          </span>

          <input type="number" class="form-control" name="m_list" min="1"
            ngModel required>
        </div>

      </div>

      <div class="card-header">
        Actions
      </div>

      <div class="row card-body">
        <button class="btn btn-primary" (click)='onMeritReport()' [disabled]="!f.valid">
          Show Results
        </button>

        <button *ngIf="hide" class="btn ml-2 btn-danger"
          (click)="CreatePdf(base)" name="All" id="0">
          <i class="fa fa-file-pdf-o" for></i>
          Download Report Pdf
        </button>
        <button *ngIf="hide" class="btn btn-success ml-2"
          (click)='DownloadExcel()'>
          <i class="fa fa-file-excel-o" for></i>
          Download Report Excel
        </button>

        <button class="btn ml-2  btn-warning" (click)='onClearForm(f)'>
          Clear Form
        </button>
      </div>
      <loader *ngIf="loader"></loader>
      <div class="px-4" *ngIf="StudentInfo.length>0">
        <div class="col-lg-12 col-md-6 form-group px-0 mt-0 pt-1">  
          <input class="form-control col-lg-4" type="text" name="Search" placeholder="Search" id="myInput" (keydown)="Filter()">
      </div>
        <table class="table table-bordered table-striped mt-4" id="tableID" id="table" style="font-size: 10px;">
          <thead class="thead-dark">
            <th>
              Sr #
            </th>
            <th>
              Admission Base
            </th>
            <th>
              Merit List
            </th>
            <th>
              Form #
            </th>
            <th>
              Name
            </th>

            <th>
              Father Name
            </th>
            <th>
              Gender
            </th>
            <th>
              Board Name
            </th>
            <th>
              Board Rn
            </th>
            <th>
              Marks
            </th>
            <th>
              Subject Combination
            </th>
          </thead>
          <tbody>
            <tr *ngFor="let i of this.StudentInfo" id="values">
              <td>
                {{i.sr_no}}
              </td>
              <td>
                {{i.base}}
              </td>
              <td>
                {{i.m_list}}
              </td>
              <td>
                {{i.f_no}}
              </td>
              <td>
                {{i.nm}}
              </td>
              <td>
                {{i.f_nm}}
              </td>
              <td>
                {{i.gender}}
              </td>
              <td>
                {{i.brd}}
              </td>
              <td>
                {{i.brd_rn}}
              </td>
              <td>
                {{i.i_marks}}
              </td>
              <td>
                {{i.sub_comb}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</form>