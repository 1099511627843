<div [@SlideInFromLeft] class="tabber">
    <div class="container-fluid card shadow px-0">
        <div class="card-header px-4">
            <div class="over">
                <div class="loader"></div>
                <!-- Loading... -->
            </div>
            <div class="px-0">Degree Receive Info</div>
        </div>
        <form #f="ngForm" class="row px-3">
            <div class="row card-body space-remover px-4">
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="c_code">Class</label>
                    <select #c_code class="form-control" ngModel name="c_code" id="c_code" required
                        (change)="OnClick()">
                        <option *ngFor="let i of class" value="{{i.id}}">{{i.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="maj_id" class="text-left">Major
                    </label>
                    <select #maj_id id="maj_id" name="maj_id" ngModel class="form-control">
                        <option *ngFor="let i of majors" value="{{i.id}}">{{i.nm}}</option>
                    </select>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                    <label for="notifDate">Session Start Year</label>
                    <input #notifDate class="form-control" ngModel name="st_year" id="st-year" required>
                </div>

                <div class="test ml-3 d-flex flex-column">

                    <button [disabled]="!f.valid" class="btn btn-outline-primary" type="button"
                        (click)="save()">Details
                    </button>
                </div>
                <div class="test ml-3">
                    <button class="btn btn-warning ml-1" type="button" (click)="onClear(f)">Clear
                    </button>
                </div>
            </div>
        </form>
        <div class="px-4">
        <table class="table table-bordered table-striped mt-4" *ngIf="isOpen">
            <thead class="thead-dark">
                <tr>
                    <th scope="col" style="width: 4vw">Sr #</th>
                    <th scope="col" style="width: 22vw; font-size: 11.5px;">Roll No</th>
                    <th scope="col" style="width: 10vw">Transcript No</th>
                    <th scope="col" style="width: 10vw">Degree No</th>
                    <th scope="col" style="width: 10vw">Degree Receive Date</th>
                    <!-- <th scope="col" *ngIf="istrue">Action.</th> -->
                    <th scope="col" style="width: 10vw">Degree Receive Year</th>
                    <th scope="col" style="width: 10vw">Degree Category</th>
                    <th scope="col" style="width: 10vw">Passing Year</th>
                    <th scope="col" style="width: 10vw">Date of Notification</th>
                    <!-- <th scope="col ">Action.</th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let detail of dataa let i=index ">
                    <td>{{i+1}}</td>
                    <td>{{detail.ROLNO}}</td>
                    <td>
                        {{detail.TRNS_NO}}
                        <div>
                            <input type="number" #trans_no class="text-center form-control p-1" ngModel name="trans_no "
                                (change)="OnChange3(trans_no.value, detail)" id="rec_date " required>
                        </div>
                    </td>
                    <td>{{detail.DEG_NO}}
                        <input #degno class="text-center form-control p-1" ngModel name="deg_no " id="deg_no "
                            (change)="OnChange(degno.value, detail)" required placeholder="Edit Deg no ">
                    </td>
                    <td>{{detail.DEG_REC_DATE | date:'MMM d, y' }}
                        <input type="date" #rec_date class="text-center form-control p-1" ngModel name="rec_date "
                            (change)="OnChange2(rec_date.value, detail)" id="rec_date " required>
                    </td>
                    <!-- <td *ngIf="i===selectedIndex ">
                            <button class="btn btn-outline-primary btn-sm " #num1 type="button " value="{{detail.TRNS_NO}} " (click)=" close() ">
                                <i class="fa fa-edit "></i>Update.
                            </button>
                        </td> -->
                    <td>{{detail.DEG_REC_YEAR }}</td>
                    <td>{{detail.DEG_CTGRY}}
                        <input type="text" #cat class="text-center form-control p-1" ngModel name="cat "
                            (change)="OnChange4(cat.value, detail)" id="cat " required>
                    </td>
                    <td>{{detail.P_YEAR}}
                        <input type="text" #p_year class="text-center form-control p-1" ngModel name="p_year "
                            (change)="OnChange6(p_year.value, detail)" id="p_year " required>
                    </td>
                    <td>{{detail.D_O_NOTIF |date:'MMM d, y' }}
                        <input type="date" #D_O_NOTIF class="text-center form-control p-1" ngModel name="D_O_NOTIF "
                            (change)="OnChange5(D_O_NOTIF.value, detail)" id="D_O_NOTIF " required>
                    </td>
                    <!-- <td>
                            <button class="btn btn-outline-primary btn-sm "  #num1 type="button " value="deg_no" (click)=" close(detail)">
                                <i class="fa fa-edit "></i>Update.
                            </button>
                        </td> -->
                </tr>
            </tbody>
        </table>
    </div>
        <div class="test text-center mb-4" *ngIf="check">
            <button class="btn btn-primary btn-lg ml-1" type="button" (click)="OnClickALL()">Update All
            </button>
        </div>
    </div>
</div>