import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from '../../transitions';
import { NgForm } from '@angular/forms';
import { SubjectModel } from './Subject.model';
import { AttendanceRelaxationService } from './attendance-relaxation.service';
import { StNameModel } from '../../shared/StName.Model';
import { ToastrService } from 'ngx-toastr';
import { SelectList } from './../shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { SessionService } from './../shared/services/Sessions.service';


@Component({
  selector: 'app-attendance-relaxation',
  templateUrl: './attendance-relaxation.component.html',
  styleUrls: ['./attendance-relaxation.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class AttendanceRelaxationComponent implements OnInit {

  @ViewChild('f') formRef: NgForm;
  stdname: string;

  classes: Array<SelectList>;
  sessions: Array<SelectList>;
  subjects: Array<SubjectModel>;
  // tslint:disable-next-line:ban-types
  students: Array<StNameModel>;
  constructor(private attRelax: AttendanceRelaxationService, 
    private toastr: ToastrService,
    private classService: ClassService,
    private sessionService: SessionService) {
    this.classes = [];
    this.sessions = [];
    this.subjects = [];
    this.students = [];
    this.stdname = '';
  }

  ngOnInit(): void {
    this.classes = [];
    this.classService.getClasses({}).subscribe((res: { C_CODE: number, C_NM: string }[]) => {
      res?.forEach(entry => {
        this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
      })
    });
  }

  onClassChange() {
    this.sessions = [];
    this.sessionService.getSessions(this.formRef.value.c_code).subscribe((res:{SE_ID: number, SE_NM:string}[]) => {
      res?.forEach(entry=>{
        this.sessions.push({id: entry.SE_ID, nm: entry.SE_NM});
      })
    });
  }

  onSemesterChange() {
    this.students = [];
    // tslint:disable-next-line:max-line-length
    this.attRelax.getStudents(this.formRef.value.year, this.formRef.value.c_code, this.formRef.value.se_id, this.formRef.value.t_no,'-75').subscribe(res => {

      // tslint:disable-next-line:forin
      for (const i in res) {
        this.students.push(new StNameModel(res[i].rolno, res[i].rn));
      }
    });
  }

  onRollChange() {
    this.showName();
    this.showSubjects();
  }

  showName() {
    // tslint:disable-next-line:max-line-length
    this.attRelax.getStName(this.formRef.value.year, this.formRef.value.c_code, this.formRef.value.se_id, this.formRef.value.t_no, this.formRef.value.rn).subscribe(res => {
      this.stdname = res[0].st_name;
    });
  }

  showSubjects() {
    // tslint:disable-next-line:max-line-length
    this.attRelax.getSubjectsForRelax(this.formRef.value.year, this.formRef.value.c_code, this.formRef.value.se_id, this.formRef.value.t_no, this.formRef.value.rn).subscribe(res => {
      for (const i in res) {
        this.subjects.push(new SubjectModel(res[i].sub_code, res[i].sub_nm));
      }
      });
  }


  onSubmit() {
    this.attRelax.insertAtt_relax(this.formRef.value).subscribe(res => {
      if (!res) {
        this.toastr.warning('Failed To Relax Attendance');
      } else {
        this.toastr.success('Inserted successfully');
      }
    });
  }

}
