<div [@SlideInFromLeft] class="tabber">
    <div class="container-fluid card shadow px-4">
        <div class="card-header px-0">
            <div class="over">
                <div class="loader">

                </div>
                <!-- Loading... -->
            </div>
            <div>Degree Print Report</div>
        </div>
        <form #f="ngForm" class="px-0">
            <div class="row card-body space-remover px-0">

                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="c_code">Class</label>
                    <select #c_code class="form-control" ngModel name="c_code" id="c_code" required
                        (change)="OnClassChange()">
                        <option *ngFor="let i of class" value="{{i.id}}">{{i.nm}} </option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="maj_id" class="text-left">Major
                    </label>
                    <select #maj_id id="maj_id" name="maj_id" ngModel class="form-control">
                        <option *ngFor="let i of majors" value="{{i.id}}">{{i.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="t_no">Semester</label>
                    <select #t_no class="form-control" ngModel name="t_no" id="t_no" required>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                    </select>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                    <label for="notifDate">Start Date Notification</label>
                    <input type="date" #notifDate class="form-control" ngModel name="notifDate" id="notifDate" required
                        ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                    <label for="notifDate1">End Date Notification</label>
                    <input type="date" #notifDate class="form-control" ngModel name="notifDate1" id="notifDate1"
                        required ngModel>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 form-group">
                    <label for="category">Degree Category</label>
                    <select #category class="form-control" name="category" ngModel id="category" required>
                        <option>Regular</option>
                        <option>Urgent</option>
                        <option>Duplicate</option>
                    </select>
                </div>
                <div class="test ml-3 d-flex flex-column mt-1">

                    <button [disabled]="!f.valid" class="btn btn-outline-primary" type="button" (click)="click()"
                        style="font-size: 15px;">Generate List
                    </button>
                </div>

                <div class="test ml-3 d-flex flex-column mt-1">

                    <button [disabled]="!f.valid" class="btn btn-outline-danger" type="button" (click)="downloadAll()"
                        style="font-size: 15px;">
                        <i class="fa fa-file-pdf-o" for=""></i>
                        Generate Pdf
                    </button>
                </div>


                <div class="test ml-3 mt-1">
                    <!-- <button [disabled]="!f.valid" class="btn btn-outline-primary btn-sm" type="submit">Generate Trans.
                    </button> -->
                    <button class="btn btn-warning ml-1" type="button" style="font-size: 15px;">Clear Form
                    </button>
                </div>
                <div class="test ml-3 mt-1">
                    <!-- <button [disabled]="!f.valid" class="btn btn-outline-primary btn-sm" type="submit">Generate Trans.
                    </button> -->
                    <button class="btn btn-danger ml-1" type="button" style="font-size: 15px;">Exit
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="card-header mt-5" *ngIf="degreeinfo.length>0">
        Details
    </div>
    <div class="card card-body px-4" *ngIf="degreeinfo.length>0">
        <table class="table table-bordered table-striped">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">STUDENT NAME</th>
                    <th scope="col">FATHER NAME</th>
                    <th scope="col">GENDER</th>
                    <th scope="col">ROLNO</th>
                    <th scope="col">MAJOR</th>
                    <th scope="col">CGPA</th>
                    <th scope="col">REG NO</th>
                    <th scope="col">Transcript NO</th>
                    <!-- <th scope="col">Action.</th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let info of degreeinfo; let i =index;">
                    <td style="font-size: 11px;">{{info.NM?.toUpperCase()}}</td>
                    <td style="font-size: 11px;">{{info.F_NM?.toUpperCase()}}</td>
                    <td>{{info.gender}}</td>
                    <td>{{info.ROLNO }}</td>
                    <td style="font-size: 11px;">{{info.maj }}</td>
                    <td>{{info.CGPA}}</td>
                    <td>{{info.REG_NO}}</td>
                    <td>{{info.trns_no}}</td>
                    <!-- <td>
                        <button class="btn btn-outline-primary btn-sm" (click)="generate(info.ROLNO)" type="button">Generate Degree.
                        </button>
                    </td> -->
                </tr>

            </tbody>
        </table>
    </div>
</div>