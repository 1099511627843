<div [@SlideInFromLeft] class="tabber">
    <div class="container-fluid card shadow px-4">
        <div class="card-header px-0">
            <div>Progress Report</div>
        </div>
        <form (ngSubmit)=" OnSubmit()" #f="ngForm" class="px-0">
            <div class="row card-body space-remover px-0">
                <div class="col-lg-4 form-group">
                    <label for="c_code">Classes</label>
                    <select #c_code class="form-control" (change)="OnClassChange()" name="c_code" id="c_code" required
                        ngModel>
                        <option *ngFor="let c of classes" value="{{ c.id }}">{{c.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="maj_id" class="text-left">Major
                    </label>
                    <select #maj_id id="maj_id" name="maj_id" class="form-control" ngModel>
                        <option *ngFor="let m of majors" value="{{m.id}}">{{m.nm}}</option>
                    </select>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="se_id">Year</label>
                    <input select class="form-control" #se_id name="se_id" id="se_id" ngModel>
                    <!-- <select class="form-control" #se_id name="se_id" id="se_id" ngModel>
                        <option *ngFor="let s of sessions" value="{{s.id}}">{{s.nm}}</option>
                    </select> -->
                </div>
                <div class="col-lg-4 form-group">
                    <label for="t_no">Semester</label>
                    <select #t_no class="form-control " name="t_no" id="t_no" required ngModel>
                        <option *ngFor="let i of [0,1,2,3,4,5,6,7,8]" value="{{i}}">{{i}}</option>
                    </select>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="start_rn">Start Roll Number</label>
                    <input type="number" #start_rn class="form-control" (change)="changeStartRn()" name="start_rn"
                        id="start_rn" required ngModel>
                </div>
                <div class="col-lg-4 form-group">
                    <label for="end_rn">End Roll Number</label>
                    <input type="number" #endRollNumber class="form-control" name="end_rn" id="end_rn" required ngModel>
                </div>
                <div id="test" class="row ml-3">
                    <button [disabled]="!f.valid" class="btn btn-outline-danger mr-2" type="submit">
                        <i class="fa fa-file-pdf-o" for=""></i>
                        Create Pdf
                    </button>
                    <!-- <button [disabled]="!f.valid" class="btn btn-outline-primary btn-sm" type="Button" (click)="func()">
                        Download Zip
                    </button> -->
                </div>
            </div>
        </form>
    </div>
</div>