import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) {

  }
  //  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //COUNTS SHOWN ON DASHBOARD
    getroomsblocksdptscount() {
      return this.http.get(`${baseUrl}/api/dashboard/getroomsblocksdpts`);
    }
       
   getdepartmentcategorycount() {
    return this.http.get(`${baseUrl}/api/dashboard/getdptdatacategorycount`);
   }
   getroomsandblockscount() {
    return this.http.get(`${baseUrl}/api/dashboard/getroomsandblockscount`);
   } 
   //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
   getroomsinblockscount() {
     return this.http.get(`${baseUrl}/api/dashboard/getblockroomsdata`);
   }
   gettstugcount(user_dept: any) {
    return this.http.post(`${baseUrl}/api/dashboard/gettstug`, {user_dept});
  }

  S_getLevelID(user_dept: any){
    return this.http.post(`${baseUrl}/api/dashboard/S_getLevelID`, {user_dept});
  }

  getprogrammecount(user_class:any) {
    return this.http.post(`${baseUrl}/api/dashboard/getprogrammecount`, {user_class});
  }
  getprogcount() {
    return this.http.get(`${baseUrl}/api/dashboard/getprogcount`);
  }
    getdepartment() {
      return this.http.get(`${baseUrl}/api/dashboard/getdptdata`);
     }

     getprogrammes() {
      return this.http.get(`${baseUrl}/api/dashboard/getprogrammedata`);
     }
//++++++++++++++++++++
     getmphilmscount(Year :any, user_dept: any) {
      return this.http.post(`${baseUrl}/api/dashboard/getmphilmscount`,{Year, user_dept});
     }
     getintercount(Year :any) {
      return this.http.post(`${baseUrl}/api/dashboard/getintercount`,{Year});
     }     
     getgradcount(Year :any, user_dept: any) {
      return this.http.post(`${baseUrl}/api/dashboard/getgraduatecount`, {Year, user_dept});
     }          
     getcertcount(Year :any, user_dept: any) {
      return this.http.post(`${baseUrl}/api/dashboard/getcertcount`, {Year, user_dept});
     }    
     
     getEmployeeForDashboard(user_dept: any) {
      return this.http.post(`${baseUrl}/api/dashboard/getEmployeeDashboard`, {user_dept});
     } 

     S_getPassFailRatio(user_dept: any) {
      return this.http.post(`${baseUrl}/api/dashboard/S_getPassFailRatio`, {user_dept});
     } 

     S_getSubjFailed(user_dept: any) {
      return this.http.post(`${baseUrl}/api/dashboard/S_getSubjFailed`, {user_dept});
     } 

     getEmployeeForGroup(user_dept: any) {
      return this.http.post(`${baseUrl}/api/dashboard/getEmployeeGroup`, {user_dept});
     } 

     getEmployeeWithNameDashboard(faculty_type :any, user_dept: number) {
      return this.http.post(`${baseUrl}/api/dashboard/getEmployeeByNameDashboard`, {faculty_type, user_dept});
     } 



     fetchhrmdata(){
      return this.http.get(`http://www.hrm.gcu.edu.pk:10087/api/get_teaching_emp`,{headers:{token:'ask?asf#fkas#jaslfsa%fdslkf&fdlgkm#gdslg'}});
      // return this.http.get(`http://www.hrm.gcu.edu.pk:10087/api/get_emp_unique_desig`,{headers:{token:'ask?asf#fkas#jaslfsa%fdslkf&fdlgkm#gdslg'}});
     }
}
