import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { baseUrl } from 'src/axios.js';
import { Team } from './Timetable.service';
@Injectable({
    providedIn: 'root'
})

export class FileService {

    constructor(private http: HttpClient) {
    }

   uploadFile(tm: Team,type: string, id: number,  file){
       const {C_CODE, SE_ID, T_NO, SECTION, SUB_CODE} = tm;
       return this.http.post(`${baseUrl}/api/file/uploadFile/${C_CODE}/${SE_ID}/${T_NO}/${SECTION}/${SUB_CODE}/${type}/${id}`, file);

   }


    downloadFile(path) {
        return this.http.post(`${baseUrl}/api/file/downloadFile`, { path: path }, {
            responseType: 'blob',
            headers: new HttpHeaders().append('Content-Type', 'application/json')
        });
    }


    deleteFile(params){
        return this.http.post(`${baseUrl}/api/file/deleteFile`,params);
    }
    
}