import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FaqService } from '../../main/shared/services/faq.service';

@Component({
  selector: 'app-faq-add',
  templateUrl: './faq-add.component.html',
  styleUrls: ['./faq-add.component.css']
})
export class FaqAddComponent {
  faqForm: FormGroup;
  @Output() closeModal = new EventEmitter<void>();

  constructor(private fb: FormBuilder, private faqService: FaqService) {
    this.faqForm = this.fb.group({
      question: ['', Validators.required],
      answer: ['', Validators.required],
      role_index: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.faqForm.valid) {
      this.faqService.addFaq(this.faqForm.value).subscribe(response => {
        this.faqForm.reset();
        this.closeModal.emit(); // Close modal after submission
      });
    }
  }

  onCancel(): void {
    this.faqForm.reset();
    this.closeModal.emit(); // Close modal without submission
  }
}
