import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FeeService } from '../shared/services/Fee.service';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import { ToWords } from 'to-words';
import { ChalanService } from '../shared/services/chalan.service';
import { SelectList } from '../shared/model/SelectList.model';
import { ToastrService } from 'ngx-toastr';
import { SlideInFromLeft } from "src/app/transitions";
import { ClassService } from '../shared/services/Class.service';
import { DatePipe } from '@angular/common';
import { EnrollmentService } from '../shared/services/Enrollment.service';
import { FeeSharedDataService } from 'src/app/shared/FeeSharedService';
declare let $: any;

@Component({
  selector: 'app-check-student-data',
  templateUrl: './check-student-data.component.html',
  styleUrls: ['./check-student-data.component.css'],
  animations: [SlideInFromLeft()],
})

export class CheckStudentDataComponent implements OnInit {

  @ViewChild("f") formRef: NgForm;
  [x: string]: any;
  letter: string;
  StudentInfo: Array<{ ch_no: number, dueDate: string, num: number, sem: number, nm: string, f_nm: string, sub_inst: number, paid: number, rn: any }>;
  StudentAmount: Array<{ fee_id: string, acc: string, debit: number, des: string }>
  concession: Array<{ id: number, desc: string, rem: string }>;
  concid: Array<SelectList>;
  showBorder: boolean = false;
  var: string;
  challanInfo: Map<string, any>;
  totalA: Map<string, any>;
  totalB: Map<string, any>;
  subTotalA: number;
  subTotalB: number;
  usr: any;
  count: any;
  feeDesc = new Array<SelectList>();
  feeDef = new Array<{ inst: number, fee_id: number, fee_nm: string, amnt: number }>();
  classes: Array<SelectList>;
  fineAcc: Array<SelectList>;
  fineAcc2: Array<SelectList>;
  fineAcc3: Array<SelectList>;
  majors: Array<SelectList>;
  sessions: Array<any>
  StudentCompInfo: Array<any>;
  SubInstall: Array<{ ch_no: string, inst_no: number, amount: number, paid_date: any, due_date: any, status: any }>;
  D_Date: any;
  PChDate: any;
  loader: boolean = false;
  flag: boolean = true;
  unpaid: any;
  OutStandDues: Array<{ ch_no: string, fine_id: number, amnt: number }>
  feeDesLength1: number
  feeDesLength2: number
  getExistingOutDues: Map<string, Map<string, string>>;
  ChalanCompInfo: Array<{
    bankMCAName: string, mca: string, bankNM: string,
    major: string, class: string, rolno: string, dueDate: string, f_nm: string, m_list: string, sr_no: string,
    base: string, f_no: string, sem: string, year: string, nm: string, paid: string, sub_inst: string, ch_no: string, sub_inst_no: any,
    schp: string, schpAmnt: number, schp_nm: string
  }>;
  dueDate: Array<any>
  dueDate2: Array<any>
  dueDateSubArr: Array<any>

  constructor(
    private classService: ClassService,
    private toastr: ToastrService,
    private feeService: FeeService,
    private chalanService: ChalanService,
    private enrollmentService: EnrollmentService,
    private datepipe: DatePipe,
    private feeSharedService: FeeSharedDataService
  ) {
    this.StudentInfo = new Array<{ ch_no: number, dueDate: string, num: number, sem: number, nm: string, f_nm: string, sub_inst: number, paid: number, rn: any }>();
    this.StudentAmount = new Array<{ fee_id: string, acc: string, debit: number, des: string }>();
    this.concession = new Array<{ id: number, desc: string, rem: string }>();
    this.concid = new Array<SelectList>();
    this.count = 0;
    this.feeDesc = new Array<SelectList>();
    this.feeDef = new Array<{ inst: number, fee_id: number, fee_nm: string, amnt: number }>();
    this.classes = [];
    this.sessions = []
    this.majors = [];
    this.StudentCompInfo = [];
    this.SubInstall = [];
    this.D_Date = true;
    this.PChDate = true;
    this.loader = false
    this.unpaid = 0;
    this.fineAcc = new Array<SelectList>();
    this.fineAcc2 = new Array<SelectList>();
    this.fineAcc3 = new Array<SelectList>();
    this.OutStandDues = []
    this.getExistingOutDues = new Map<string, Map<string, string>>();

  }

  ngOnInit(): void {
    this.updateClasses();
    this.feeService.getSessionYear().subscribe((res: any) => {
      res.forEach(element => {
        this.sessions.push(element.SE_ST_YEAR)
      });

    });
    this.subinst = false;
    this.sub_chno = null;
    this.FreshChallan()
    this.loadFineMisc()
  }

  updateClasses() {
    this.feeSharedService.getFeeClasses()
      .then(classes => {
        this.classes = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateInstNO() {
    this.instNo = []
    this.feeSharedService.getFeeInst()
      .then(inst => {
        this.instNo = inst;
      })
      .catch(error => {
        console.error("Error getting inst", error);
      });
  }

  loadRolno() {
    this.rolnos = [];
    setTimeout(() => {
      this.rolnos = [];
      this.enrollmentService.notEnrollFeeServer(this.formRef.value).subscribe((res: { rn: number, rolno: string }[]) => {
        res?.forEach(entry => {
          this.rolnos.push({ id: entry.rn, nm: entry.rolno });
        });
      });
    }, 2500)
  }

  OutstandingDues(index, val, ch_no_in) {
    if (val == 1) {
      this.GetOutDues(ch_no_in)
    }
    this.StudentCompInfo[index].outstandingDues = val
  }

  DueDateSub(date: any, ch_no, index) {
    this.dueDateSubArr[index] = moment(date).format('YYYY-MM-DD');
  }

  dateExtend(ch_no) {
    const { desc } = this.formRef.value;
    this.StudentCompInfo.forEach(element => {
      if (element.ch_no == ch_no)
        this.EXDATE = element.due_date
    })
    if (this.formRef.valid) {
      this.feeService.DateExtend(ch_no, this.EXDATE, desc).subscribe((res: { msg: string }) => {
        if (!res) {
          this.toastr.error("Unknown Error");
        }
        else if (res.msg) {
          this.toastr.success(res.msg);
        }
      }, err => {
        this.toastr.error("Some Error Occured during Adding Fee Description!");
      });
    }
    else {
      this.toastr.warning("Some Fields are Missing!");
    }
  }

  LoadSubInst(ch_no) {
    this.SubInstall = []
    this.dueDateSubArr = []
    this.feeService.LoadSubInst(ch_no, null, null, null, null).subscribe((res: any) => {
      res.forEach(element => {
        if(element) {
        this.SubInstall.push({
          ch_no: element.sub_ch_no, inst_no: element.inst_no, amount: element.debit, paid_date: element.paid_date,
          due_date: element.due_date, status: element.paid
        })
        if(element.due_date)
        {
          const parts = this.datepipe.transform(element.due_date,'yyyy-MM-dd').split('-');
          parts[2] = parts[2].split('T')[0]
          // Note: months are 0-based in JavaScript Date object, so subtract 1 from the month
          const dateObject = new Date(+parts[0], +parts[1] - 1, +parts[2]);
          this.dueDateSubArr.push(moment(dateObject).format('YYYY-MM-DD'))
        }
        else {
          this.dueDateSubArr.push(this.curdate());
        }
      }
      })
    })
  }

  ViewSubinst(ch_no, status) {
    this.subinst = status;
    this.ch_no_in = ch_no;
    this.unpaid = 0;
    this.LoadSubInst(ch_no);
  }

  countUnpaid() {
    let unpaid = 0;
    this.SubInstall.forEach(element => {
      if (element.status == 0)
        unpaid = unpaid + 1;
    })
    return unpaid;
  }

  curdate(): any {
    return moment(new Date()).format('YYYY-MM-DD');
  }

  FetchStdData() {
    const { c_code, year_in, inst_no, rolno } = this.formRef.value;
    this.StudentCompInfo = []
    this.dueDate = []
    this.dueDate2 = []
    this.subinst = false;
    this.loader = true;
    this.rol = []
    this.rol = rolno.split('-');
      this.feeService.CheckStdInformation(c_code, year_in, inst_no, this.rol[0]).subscribe((res: any) => {
        res.forEach(element => {
          this.StudentCompInfo.push(element);
          this.dueDate.push(this.curdate());
          if(element.due_date)
          {
            const parts = this.datepipe.transform(element.due_date,'yyyy-MM-dd').split('-');
            parts[2] = parts[2].split('T')[0]
            // Note: months are 0-based in JavaScript Date object, so subtract 1 from the month
            const dateObject = new Date(+parts[0], +parts[1] - 1, +parts[2]);
            this.dueDate2.push(moment(dateObject).format('YYYY-MM-DD'))
          }
        })
        this.loader = false;
      })
  }

  Handle(e) {
    return e.keyCode != 13;
  }

  DueDate(date: any, ch_no, option,index) {
    if(option == 0) {
      this.dueDate[index] = date;
      this.option = option
    }
    else if (option == 1) {
      this.dueDate2[index] = date;
      this.option = option
    } 
  }

  DelManageSubInstallment(ch_no, sub_ch) {
    if (confirm("Do you really want to merge SubInstallment " + sub_ch)) {
      this.feeService.DelManageSubInstallment(ch_no).subscribe((res: any) => {
        this.toastr.success(res[0].msg)
        this.LoadSubInst(ch_no);
      })
    }
  }

  LockChallanByFeeSec(ch_no_in) {
    this.feeService.LockChallanByFeeSec(ch_no_in).subscribe((res: any) => {
      this.toastr.success(res[0][0].msg)
    })
    this.StudentCompInfo.forEach(el => {
      if (el.ch_no == ch_no_in) {
        el.fee_sec_lock = 1;
      }
    })
  }

  loadFineMisc() {
    this.feeAcc = [];
    this.feeDesc1 = [];
    this.feeDesc2 = []
    this.feeDesc3 = []
    this.feeDesLength1 = 0
    this.feeDesLength2 = 0
    this.feeService.getFineDescription().subscribe((res: { fine_id: string, fine_nm: string }[]) => {
      if (!res) {
        this.toastr.error("Unknown Error");
      }
      else if (res) {
        res?.forEach(element => {

          this.fineAcc.push({ id: parseInt(element.fine_id), nm: element.fine_nm });
        });
        this.feeDesLength = Math.round((this.fineAcc.length / 3));
        this.feeDesLength1 = (parseInt(this.feeDesLength) * 2);
        for (let i = 0; i < parseInt(this.feeDesLength); i++) {
          this.feeDesc1.push(this.fineAcc[i]);
        }
        for (let i = parseInt(this.feeDesLength); i < this.feeDesLength1; i++) {
          this.feeDesc2.push(this.fineAcc[i]);
        }
        for (let i = this.feeDesLength1; i < this.fineAcc.length; i++) {
          this.feeDesc3.push(this.fineAcc[i]);
        }
      }
    }, err => {
        console.log(err);
        this.toastr.error("Some Error Occured!");
      }
    );
  }

  UnlockChallanByFeeSec(ch_no_in) {
    this.feeService.UnlockChallanByFeeSec(ch_no_in).subscribe((res: any) => {
      this.toastr.success(res[0][0].msg)
    })
    this.StudentCompInfo.forEach(el => {
      if (el.ch_no == ch_no_in) {
        el.fee_sec_lock = 0;
      }
    })
  }

  FreshChallan() {
    this.totalA = new Map([["OVERSEAS", 0], ["ADMISSION FEE", 0], ["REGISTRATION FEE", 0], ["TUITION (FORIEGN)", 0], ["TUITION FEE", 0], ["EXAMINATION", 0]
      , ["UNIVERSITY EXAM FEE", 0], ["THESIS FEE", 0], ["ID CARD", 0], ["OTHER MISCELLANEOUS (REG)", 0], ["LAB CHARGES", 0]
      , ["MISCELLANEOUS", 0], ["DEVELOPMENT FUND", 0], ["SPORTS FEE", 0], ["VERIFICATION FEE", 0], ["OTHER MISCELLANEOUS (SSP)", 0]]);
    this.totalB = new Map([["ROOM RENT", 0], ["HOSTEL SECURITY", 0], ["LIBRARY SECURITY", 0], ["WITHHOLDING TAX", 0], ["STUDENT FUNDS", 0], ["BOARD FUND", 0]]);
  }

  UpdateTotal(AFee: any) {
    this.subTotalA = 0;
    this.subTotalB = 0;
    for (let i = 0; i < AFee.length; i++) {
      this.totalA.forEach((values, element) => {
        if (element == AFee[i].acc) {
          this.totalA.set(AFee[i].acc, parseInt(AFee[i].debit));
          this.subTotalA += parseInt(AFee[i].debit);
        }
      })
      this.totalB.forEach((values, element) => {
        if (element == AFee[i].acc) {
          this.totalB.set(AFee[i].acc, parseInt(AFee[i].debit));
          this.subTotalB += parseInt(AFee[i].debit);
        }
      })
      if (!this.totalA.has(AFee[i].acc) && !this.totalB.has(AFee[i].acc)) {
        this.totalB.set(AFee[i].acc, parseInt(AFee[i].debit))
        this.subTotalB += parseInt(AFee[i].debit);
      }
    }
  }

  AddOutDues(ch_no_in, value, fine_id_in) {
    let found = false
    this.OutStandDues.forEach((e, index) => {
      if (e.ch_no == ch_no_in && e.fine_id == fine_id_in) {
        this.OutStandDues[index].amnt = value;
        found = true;
      }
    })
    if (found == false)
      this.OutStandDues.push({ ch_no: ch_no_in, fine_id: fine_id_in, amnt: value })
  }

  GetOutDues(ch_no_in) {
    this.feeService.GetOutStandDues(ch_no_in).subscribe((res: any) => {
      res.forEach(e => {
        if (this.getExistingOutDues.has(e.ch_no + '') == false) {
          this.getExistingOutDues.set(e.ch_no + '', new Map());
        }
        this.getExistingOutDues.get(e.ch_no + '').set(e.fine_id + "", e.amnt);
      })
    })
  }

  extendDateSub(sub_ch,index) {
    this.sub_chno = sub_ch
    this.D_Date = !this.D_Date;
    if(this.D_Date == true) {
    this.feeService.UpdateDueDateForSubInstall(this.dueDateSubArr[index], sub_ch).subscribe((res: any) => {
      if(res[0][0]?.msg) {
        this.toastr.success(res[0][0].msg);
        this.SubInstall[index].due_date = this.dueDateSubArr[index]
      }
    })
  }
  }

  extendDateOutDues(obj,subinstexists,index) {
    this.feeService.DateExtend(obj.ch_no, this.dueDate2[index], "Misc Fine").subscribe((res: { msg: string }) => {
      if (!res.msg) {
        this.toastr.error("Unknown Error");
      }
      else if (res.msg) {
        this.toastr.success(res.msg);
        this.StudentCompInfo[index].due_date=this.dueDate2[index];
        this.CreatePdfDem(obj, subinstexists,index)
      }
    });
  }

  extendDate(ch_no, allow,index) {
    this.PchNo = ch_no
    this.PChDate = !this.PChDate;
    if (allow == 1) {
      if (this.formRef.valid) {
      this.feeService.DateExtend(ch_no, this.option == 0 ? this.dueDate[index]: this.dueDate2[index], this.formRef.value.desc).subscribe((res: { msg: string }) => {
        if (!res.msg) {
          this.toastr.error("Unknown Error");
        }
        else if (res.msg) {
          this.toastr.success(res.msg);
          if(this.option == 0)
          {
            this.dueDate2[index] = this.dueDate[index];
          }
        }
      });
    
    }
    else {
      this.toastr.warning("Description is missing");
    }
    }
    else if(allow == 0) {
    this.option = 0;
    }
  }

  DownloadPdf(obj, subinstexists,index) {
    let ch_no = obj.ch_no
    if (obj.outstandingDues == 1) {
    if (this.OutStandDues.length > 0) {
      this.OutStandDues.forEach(e => {
        if (e.ch_no == ch_no) {
          this.feeService.AddOutstandDues(e.ch_no, e.fine_id, e.amnt).subscribe();
        }
      })
      this.extendDateOutDues(obj,subinstexists,index);
      }
      else {
        this.extendDateOutDues(obj,subinstexists,index);
      }
    }
    else {
      this.CreatePdfDem(obj, subinstexists,index)
    }
  }

  CreatePdfDem(obj, subinstexists,index) {
    let ch_no = obj.ch_no
    this.ChalanCompInfo = [];
    this.FreshChallan();
    setTimeout(() => {
      this.toastr.success("Generating Challan")
    }, 200)
    for (let i = 0; i < this.SubInstall.length; i++) {
      if (this.SubInstall[i].status == 0) {
        this.subChallan = this.SubInstall[i].ch_no;
        break;
      }
    }
    const towords = new ToWords();
    var body = [];

    this.chalanService.T_CheckPaidUnpaidShowChallan(null, null, null, null, ch_no, null).subscribe((data: any) => {
      this.ChalanCompInfo.push({
        bankMCAName: data[0][0].bank_snm, mca: data[0][0].mcs, bankNM: data[0][0].bank_nm,
        major: data[3][0].maj, class: data[3][0].class, rolno: data[1][0].num, dueDate: data[1][0].dueDate, f_nm: data[1][0].f_nm, m_list: data[1][0].m_list,
        sr_no: data[1][0].sr_no, base: data[1][0].base, f_no: data[1][0].f_no, sem: data[1][0].sem, year: data[1][0].year, nm: data[1][0].nm, paid: data[1][0].paid, sub_inst: data[1][0].sub_inst,
        ch_no: data[1][0].ch_no, sub_inst_no: data[1][0].sub_inst_no, schp: data[1][0].schp, schpAmnt: data[1][0].schpAmount, schp_nm: data[1][0].schp_nm
      });
      this.UpdateTotal(data[2]);

      const doc = new jsPDF('l', 'mm', 'A4'); //[297, 210]
      {
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();

        doc.setDrawColor(192, 192, 192);
        doc.line(102, 0, 102, 220);
        doc.line(192, 0, 192, 220);
        doc.setDrawColor(0, 0, 0);

        let h = 20;
        //FIRST PAGE
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("GC University Lahore", 1 / 6 * width + 10, h - 2, { align: 'center' });
        doc.setFontSize(8);
        doc.text(this.ChalanCompInfo[0].class, 1 / 6 * width + 10, h + 3, { align: 'center' });
        doc.text("MAJOR: " + this.ChalanCompInfo[0].major, 1 / 6 * width + 10, h + 8, { align: 'center' });
        //For Stamp on Challan
        if (parseInt(this.ChalanCompInfo[0].paid) == 1) {
          doc.saveGraphicsState();
          doc.setFontSize(30);
          doc.setGState(doc.GState({ opacity: 0.5 }));
          doc.text("Challan Already Paid", 23, 160, null, 45)
          // doc.text("Paid On: ", 35, 160, null,45)
          doc.text("Challan Already Paid", 113, 160, null, 45)
          // doc.text("Paid On: ", 125, 160, null,45)
          doc.text("Challan Already Paid", 203, 160, null, 45)
          // doc.text("Paid On: ", 215, 160, null,45)
          doc.restoreGraphicsState();
        }

        if (parseInt(this.ChalanCompInfo[0].sub_inst) == 1) {
          doc.saveGraphicsState();
          doc.setFontSize(20);
          doc.setGState(doc.GState({ opacity: 0.5 }));
          doc.text("Challan has Sub-Installments", 23, 160, null, 45)
          doc.text("Challan has Sub-Installments", 113, 160, null, 45)
          doc.text("Challan has Sub-Installments", 203, 160, null, 45)
          doc.restoreGraphicsState();
        }

        // SECOND PAGE
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("GC University Lahore", 1 / 2 * width, h - 2, { align: 'center' });
        doc.setFontSize(8);
        doc.text(this.ChalanCompInfo[0].class.toUpperCase(), 1 / 2 * width, h + 3, { align: 'center' });
        doc.text("MAJOR: " + this.ChalanCompInfo[0].major.toUpperCase(), 1 / 2 * width, h + 8, { align: 'center' });

        // // THIRD PAGE
        doc.setFontSize(12);
        doc.setFont("Helvetica", "bold");
        doc.text("GC University Lahore", 5 / 6 * width - 10, h - 2, { align: 'center' });
        doc.setFontSize(8);
        doc.text(this.ChalanCompInfo[0].class.toUpperCase(), 5 / 6 * width - 10, h + 3, { align: 'center' });
        doc.text("MAJOR: " + this.ChalanCompInfo[0].major.toUpperCase(), 5 / 6 * width - 10, h + 8, { align: 'center' });

        if (parseInt(this.ChalanCompInfo[0].sem) == 1) {
          // "this.admBase.find(e => e.id == this.formRef.value.b_id)?.nm;"
          let nm = "BASE"
          doc.text("BASE: " + this.ChalanCompInfo[0].base, 1 / 6 * width + 10, h + 12, { align: 'center' });
          doc.text("BASE: " + this.ChalanCompInfo[0].base, 1 / 2 * width, h + 12, { align: 'center' });
          doc.text("BASE: " + this.ChalanCompInfo[0].base, 5 / 6 * width - 10, h + 12, { align: 'center' });
          h = 24;
        }

        // FIRST PAGE
        doc.setFontSize(8);
        doc.setFont("Helvetica", "bold");
        doc.text("Bank Copy", 1 / 6 * width + 10, h + 11, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('' + this.ChalanCompInfo[0].bankNM.toUpperCase(), 1 / 6 * width + 10, h + 15, { align: 'center' });
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(7);
        doc.text("Acceptable at All " + (this.ChalanCompInfo[0].bankMCAName.toUpperCase()) + " Branches (within Pakistan)", 1 / 6 * width + 10, h + 19.5, { align: 'center' });
        doc.text((this.ChalanCompInfo[0].bankNM) + " MCA # : " + this.ChalanCompInfo[0].mca, 1 / 6 * width + 10, h + 23.5, { align: 'center' });
        doc.setFont("Helvetica", "normal")
        doc.text('_______________________________________________________', 1 / 6 * width + 10, h + 25, { align: 'center' });


        // // SECOND PAGE
        doc.setFontSize(8);
        doc.setFont("Helvetica", "bold");
        doc.text("University Copy", 1 / 2 * width, h + 11, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('' + this.ChalanCompInfo[0].bankNM.toUpperCase(), 1 / 2 * width, h + 15, { align: 'center' });
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(7);
        doc.text("Acceptable at All " + (this.ChalanCompInfo[0].bankMCAName.toUpperCase()) + " Branches (within Pakistan)", 1 / 2 * width, h + 19.5, { align: 'center' });
        doc.text((this.ChalanCompInfo[0].bankNM.toUpperCase()) + " MCA # : " + this.ChalanCompInfo[0].mca, 1 / 2 * width, h + 23.5, { align: 'center' });
        doc.setFont("Helvetica", "normal")
        doc.text('_______________________________________________________', 1 / 2 * width, h + 25, { align: 'center' });


        // // THIRD PAGE
        doc.setFontSize(8);
        doc.setFont("Helvetica", "bold");
        doc.text("Student Copy", 5 / 6 * width - 10, h + 11, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(10);
        doc.text('' + this.ChalanCompInfo[0].bankNM.toUpperCase(), 5 / 6 * width - 10, h + 15, { align: 'center' });
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(7);
        doc.text("Acceptable at All " + (this.ChalanCompInfo[0].bankMCAName.toUpperCase()) + " Branches (within Pakistan)", 5 / 6 * width - 10, h + 19.5, { align: 'center' });
        doc.text((this.ChalanCompInfo[0].bankNM.toUpperCase()) + " MCA # : " + this.ChalanCompInfo[0].mca, 5 / 6 * width - 10, h + 23.5, { align: 'center' });
        doc.setFont("Helvetica", "normal")
        doc.text('________________________________________________________', 5 / 6 * width - 10, h + 25, { align: 'center' });



        doc.setFont("Helvetica", "bold");
        doc.setFontSize(7);

        let b = 50,
          d = ['Challan #/PV #', 'Due Date', 'Roll #/Buyer Code', 'University Dues for', 'Name', 'S/D Of', 'Sr.#'];
        if (parseInt(this.ChalanCompInfo[0].sem) == 1) {
          b = 55;
        }

        doc.setFontSize(7);
        let sr = 0;
        let count = 0;
        for (let index = 0; index < d.length; index++) {
          // for (const itr of Object.entries(std.info)) {
          if (index == 1) {
            if (parseInt(this.ChalanCompInfo[0].sem) == 1) {

              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.datepipe.transform(this.ChalanCompInfo[0].dueDate, 'dd-MM-yyyy'), 1 / 6 * width + 21, b - 7);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 1 / 6 * width + 10, b - 6, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.datepipe.transform(this.ChalanCompInfo[0].dueDate, 'dd-MM-yyyy'), 1 / 2 * width + 11, b - 7);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 1 / 2 * width, b - 6, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.datepipe.transform(this.ChalanCompInfo[0].dueDate, 'dd-MM-yyyy'), 5 / 6 * width + 1, b - 7);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 5 / 6 * width - 10, b - 6, { align: 'center' });
              // e += a - 10;
            }
            else {
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.datepipe.transform(this.ChalanCompInfo[0].dueDate, 'dd-MM-yyyy'), 1 / 6 * width + 21, b - 6);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 1 / 6 * width + 10, b - 5, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.datepipe.transform(this.ChalanCompInfo[0].dueDate, 'dd-MM-yyyy'), 1 / 2 * width + 11, b - 6);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 1 / 2 * width, b - 5, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.datepipe.transform(this.ChalanCompInfo[0].dueDate, 'dd-MM-yyyy'), 5 / 6 * width + 1, b - 6);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 5 / 6 * width - 10, b - 5, { align: 'center' });
              // e += a - 10;
            }

          }
          else if (index == 2) {
            if (parseInt(this.ChalanCompInfo[0].sem) > 1) {
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.ChalanCompInfo[0].rolno, 20, b - 1);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 59, b, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.ChalanCompInfo[0].rolno, 2 / 6 * width + 10, b - 1);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 2 / 6 * width + 49, b, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": " + this.ChalanCompInfo[0].rolno, 2 / 3 * width, b - 1);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 2 / 3 * width + 39, b, { align: 'center' });
              b = b + 4;
            }
            else {
              if (parseInt(this.ChalanCompInfo[0].sem) == 1) { /**/
                doc.setFont("Helvetica", "bold")
                doc.text("Form #: " + this.ChalanCompInfo[0].f_no, 20, b - 2);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 59, b - 1, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text("Form #: " + this.ChalanCompInfo[0].f_no, 2 / 6 * width + 10, b - 2);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 6 * width + 49, b - 1, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text("Form #: " + this.ChalanCompInfo[0].f_no, 2 / 3 * width, b - 2);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 3 * width + 39, b - 1, { align: 'center' });
                doc.setFont("Helvetica", "bold")


                doc.text("Merit List: " + this.ChalanCompInfo[0].m_list, 20, b + 3);
                doc.text("Merit List: " + this.ChalanCompInfo[0].m_list, 2 / 6 * width + 10, b + 3);
                doc.text("Merit List: " + this.ChalanCompInfo[0].m_list, 2 / 3 * width, b + 3);


                doc.text("Session: " + this.ChalanCompInfo[0].year, 1 / 6 * width + 30, b + 3);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 59, b + 4, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text("Session: " + this.ChalanCompInfo[0].year, 1 / 2 * width + 20, b + 3);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 4, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text("Session: " + this.ChalanCompInfo[0].year, 5 / 6 * width + 10, b + 3);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 4, { align: 'center' });
                doc.setFont("Helvetica", "bold")

                sr = b;
                b = b + 8;
              }
            }
          }
          else if (index == 3) {
            if (parseInt(this.ChalanCompInfo[0].sub_inst) > -1) {
              if (parseInt(this.ChalanCompInfo[0].schp) == 0) {
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": Semester # " + parseInt(this.ChalanCompInfo[0].sem), 20, b);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 59, b + 1, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": Semester # " + parseInt(this.ChalanCompInfo[0].sem), 2 / 6 * width + 10, b);
                doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 1, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": Semester # " + parseInt(this.ChalanCompInfo[0].sem), 2 / 3 * width, b);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 1, { align: 'center' });
              }
              else {
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": Semester #" + parseInt(this.ChalanCompInfo[0].sem) + "      " + this.ChalanCompInfo[0].schp_nm + " " + this.ChalanCompInfo[0].schpAmnt, 20, b);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 59, b + 1, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": Semester #" + parseInt(this.ChalanCompInfo[0].sem) + "      " + this.ChalanCompInfo[0].schp_nm + " " + this.ChalanCompInfo[0].schpAmnt, 2 / 6 * width + 10, b);
                doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 1, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": Semester #" + parseInt(this.ChalanCompInfo[0].sem) + "      " + this.ChalanCompInfo[0].schp_nm + " " + this.ChalanCompInfo[0].schpAmnt, 2 / 3 * width, b);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 1, { align: 'center' });
              }
            }
            else if (parseInt(this.ChalanCompInfo[0].sub_inst) == -1) {
              if (parseInt(this.ChalanCompInfo[0].schp) == 0) {
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": Semester # " + parseInt(this.ChalanCompInfo[0].sem) + " (Inst-" + this.ChalanCompInfo[0].sub_inst_no + ")", 20, b);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 59, b + 1, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": Semester # " + parseInt(this.ChalanCompInfo[0].sem) + " (Inst-" + this.ChalanCompInfo[0].sub_inst_no + ")", 2 / 6 * width + 10, b);
              doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 1, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": Semester # " + parseInt(this.ChalanCompInfo[0].sem) + " (Inst-" + this.ChalanCompInfo[0].sub_inst_no + ")", 2 / 3 * width, b);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 1, { align: 'center' });
              }
              else {
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": Sem # " + parseInt(this.ChalanCompInfo[0].sem) + " (Inst-" + this.ChalanCompInfo[0].sub_inst_no + ")"+ "    " + this.ChalanCompInfo[0].schp_nm + " " + this.ChalanCompInfo[0].schpAmnt, 20, b);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 59, b + 1, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": Sem # " + parseInt(this.ChalanCompInfo[0].sem) + " (Inst-" + this.ChalanCompInfo[0].sub_inst_no + ")"+ "    " + this.ChalanCompInfo[0].schp_nm + " " + this.ChalanCompInfo[0].schpAmnt, 2 / 6 * width + 10, b);
              doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 1, { align: 'center' });
              doc.setFont("Helvetica", "bold")
              doc.text(d[index] + ": Sem # " + parseInt(this.ChalanCompInfo[0].sem) + " (Inst-" + this.ChalanCompInfo[0].sub_inst_no + ")"+ "    " + this.ChalanCompInfo[0].schp_nm + " " + this.ChalanCompInfo[0].schpAmnt, 2 / 3 * width, b);
              doc.setFont("Helvetica", "normal")
              doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 1, { align: 'center' });
              }
            }

            b = b + 4;
          }
          else if (index < 6) {

            if (index == 0) {
              if (parseInt(this.ChalanCompInfo[0].sem) == 1) {
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].ch_no, 20, b - 2);
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].ch_no, 2 / 6 * width + 10, b - 2);
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].ch_no, 2 / 3 * width, b - 2);
              }
              else {
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].ch_no, 20, b - 1);
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].ch_no, 2 / 6 * width + 10, b - 1);
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].ch_no, 2 / 3 * width, b - 1);
              }
              count++;
            }

            else {
              if (count == 1) {

                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].nm, 20, b + 1);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 59, b + 2, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].nm, 2 / 6 * width + 10, b + 1);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 2, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].nm, 2 / 3 * width, b + 1);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 2, { align: 'center' });
                count++;
              }
              else {
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].f_nm, 20, b + 1);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 59, b + 2, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].f_nm, 2 / 6 * width + 10, b + 1);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 2, { align: 'center' });
                doc.setFont("Helvetica", "bold")
                doc.text(d[index] + ": " + this.ChalanCompInfo[0].f_nm, 2 / 3 * width, b + 1);
                doc.setFont("Helvetica", "normal")
                doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 2, { align: 'center' });
              }
            }
            b = b + 5;
            // b += a - 10;
          }

          if (index == 6 && parseInt(this.ChalanCompInfo[0].sem) == 1) {
            doc.setFont("Helvetica", "bold")
            doc.text(d[index] + ": " + this.ChalanCompInfo[0].sr_no, 58, sr + 3);
            doc.text(d[index] + ": " + this.ChalanCompInfo[0].sr_no, 2 / 6 * width + 48, sr + 3);
            doc.text(d[index] + ": " + this.ChalanCompInfo[0].sr_no, 2 / 3 * width + 38, sr + 3);
          }
          // index++;

        }

        // FIRST PAGE
        doc.setFontSize(7);
        doc.setFont("Helvetica", "bold");
        doc.text('Head Of Account', 24, b);
        doc.setFontSize(8);
        doc.text('A-FEE', 24, b + 4.5);
        // doc.text('__', 14, b+23);
        doc.setFontSize(7);
        doc.setFont("Helvetica", "normal");
        doc.text('_______________________________________________________', 59, b + 5, { align: 'center' });
        doc.setFont("Helvetica", "bold");
        doc.setFontSize(6);
        doc.text('A/C No to be credited', 1 / 6 * width + 15, b, { align: 'center' })
        doc.setFontSize(7);
        doc.text('Amount', 87, b);
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 59, b + 1, { align: 'center' });
        doc.setFont('Helvetica', 'normal');


        // // SECOND PAGE
        doc.setFontSize(7);
        doc.setFont("Helvetica", "bold");
        doc.text('Head Of Account', 2 / 6 * width + 14, b);
        doc.setFontSize(8);
        doc.text('A-FEE', 2 / 6 * width + 14, b + 4.5);
        doc.text('________________________________________________', 2 / 6 * width + 49, b + 5, { align: 'center' });
        doc.setFontSize(6);
        // doc.text('__', 14, b+23);
        doc.text('A/C No.to be credited', 1 / 2 * width + 5, b, { align: 'center' })
        doc.setFontSize(7);
        doc.text('Amount', 2 / 6 * width + 77, b);
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 2 / 6 * width + 49, b + 1, { align: 'center' });
        doc.setFont('Helvetica', 'normal');

        // // THIRD PAGE
        doc.setFontSize(7);
        doc.setFont("Helvetica", "bold");
        doc.text('Head Of Account', 2 / 3 * width + 4, b);
        doc.setFontSize(8);
        doc.text('A-FEE', 2 / 3 * width + 4, b + 4.5);
        doc.setFontSize(7);
        doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 5, { align: 'center' });

        // doc.text('__', 14, b+23);
        doc.setFontSize(6);
        doc.text('A/C No.to be credited', 5 / 6 * width - 5, b, { align: 'center' })
        doc.setFontSize(7);
        doc.text('Amount', 2 / 3 * width + 67, b);
        doc.text('_______________________________________________________', 2 / 3 * width + 39, b + 1, { align: 'center' });
        doc.setFont('Helvetica', 'normal');


        doc.setFontSize(6);
        let index = 1, m = b + 7;
        for (const itr of this.totalA) {
          if (this.totalA.get("OVERSEAS") == 0 && itr[0] == "OVERSEAS")
            continue

          if (this.totalA.get("TUITION (FORIEGN)") == 0 && itr[0] == "TUITION (FORIEGN)")
            continue

          if (this.totalA.get("TUITION (FORIEGN)") != 0 && itr[0] == "TUITION FEE")
            continue

          doc.text(index + ".", 20, m + 1);
          doc.text(index + ".", 2 / 6 * width + 10, m + 1);
          doc.text(index + ".", 2 / 3 * width, m + 1);

          doc.text(itr[0]+'', 24, m + 1);
          doc.text(itr[0]+'', 2 / 6 * width - 1 + 15, m + 1);
          doc.text(itr[0]+'', 2 / 3 * width - 1 + 5, m + 1);

          doc.text(JSON.stringify(itr[1]), 93, m + 1, { align: 'center' });
          doc.text('________________________________________________________________', 59, m + 2, { align: 'center' });
          doc.text(JSON.stringify(itr[1]), 2 / 6 * width + 83, m + 1, { align: 'center' });
          doc.text('________________________________________________________________', 2 / 6 * width + 49, m + 2, { align: 'center' });
          doc.text(JSON.stringify(itr[1]), 2 / 3 * width + 73, m + 1, { align: 'center' });
          doc.text('________________________________________________________________', 2 / 3 * width + 39, m + 2, { align: 'center' });
          index++;
          m += 4;
        }

        doc.setFontSize(7);

        doc.setFont('Helvetica', 'bold');
        doc.text('Total A', 24, m + 1);
        doc.text('Total A', 2 / 6 * width + 14, m + 1);
        doc.text('Total A', 2 / 3 * width + 4, m + 1);
        doc.text(JSON.stringify(this.subTotalA), 93, m + 1, { align: 'center' });
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 59, m + 2, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.text(JSON.stringify(this.subTotalA), 2 / 6 * width + 83, m + 1, { align: 'center' });
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 2 / 6 * width + 49, m + 2, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.text(JSON.stringify(this.subTotalA), 2 / 3 * width + 73, m + 1, { align: 'center' });
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 2 / 3 * width + 39, m + 2, { align: 'center' });

        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(8);
        doc.text('B-FEE', 24, m + 5.5);
        doc.setFont('Helvetica', 'normal');
        doc.text('________________________________________________', 59, m + 6, { align: 'center' });
        // doc.text('____', 10, m + 3);

        doc.setFont('Helvetica', 'bold');
        doc.text('B-FEE', 2 / 6 * width + 14, m + 5.5);
        doc.setFont('Helvetica', 'normal');
        doc.text('________________________________________________', 2 / 6 * width + 49, m + 6, { align: 'center' });
        // doc.text('____', 130, m + 2);

        doc.setFont('Helvetica', 'bold');
        doc.text('B-FEE', 2 / 3 * width + 4, m + 5.5);
        doc.setFont('Helvetica', 'normal');
        doc.text('________________________________________________', 2 / 3 * width + 39, m + 6, { align: 'center' });

        // doc.text('____', 250, m + 2);


        doc.setFont('Helvetica', 'normal');
        doc.setFontSize(6);

        index = 1;
        let n = m + 6;
        for (const itr of this.totalB) {
          doc.text(index + ".", 21, n + 3);
          doc.text(index + ".", 2 / 6 * width + 11, n + 3);
          doc.text(index + ".", 2 / 3 * width + 1, n + 3);

          doc.text(itr[0]+'', 24, n + 3);
          doc.text(itr[0]+'', 2 / 6 * width + 14, n + 3);
          doc.text(itr[0]+'', 2 / 3 * width + 4, n + 3);

          doc.text(JSON.stringify(itr[1]), 93, n + 3, { align: 'center' });
          doc.text('________________________________________________________________', 59, n + 4, { align: 'center' });
          doc.text(JSON.stringify(itr[1]), 2 / 6 * width + 83, n + 3, { align: 'center' });
          doc.text('________________________________________________________________', 2 / 6 * width + 49, n + 4, { align: 'center' });
          doc.text(JSON.stringify(itr[1]), 2 / 3 * width + 73, n + 3, { align: 'center' });
          doc.text('________________________________________________________________', 2 / 3 * width + 39, n + 4, { align: 'center' });

          index++;
          n += 4;
        }

        doc.setFontSize(7);

        doc.setFont('Helvetica', 'bold');
        doc.text('Total B', 24, n + 3.5);
        // console.log(n,"M")
        doc.text('Total B', 2 / 6 * width + 14, n + 3.5);
        doc.text('Total B', 2 / 3 * width + 4, n + 3.5);

        doc.text(JSON.stringify(this.subTotalB), 93, n + 3.5, { align: 'center' });
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 59, n + 4.5, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.text(JSON.stringify(this.subTotalB), 2 / 6 * width + 83, n + 3.5, { align: 'center' });
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 2 / 6 * width + 49, n + 4.5, { align: 'center' });
        doc.setFont('Helvetica', 'bold');
        doc.text(JSON.stringify(this.subTotalB), 2 / 3 * width + 73, n + 3.5, { align: 'center' });
        doc.setFont('Helvetica', 'normal');
        doc.text('_______________________________________________________', 2 / 3 * width + 39, n + 4.5, { align: 'center' });


        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(8)
        doc.text('Grand Total', 24, n + 8);
        doc.text('Grand Total', 2 / 6 * width + 14, n + 8);
        doc.text('Grand Total', 2 / 3 * width + 4, n + 8);

        doc.text(JSON.stringify(this.subTotalA + this.subTotalB), 89, n + 8);
        doc.setFont("Helvetica", "normal")
        doc.text('________________________________________________', 59, n + 9, { align: 'center' });
        doc.setFont("Helvetica", "bold")
        doc.text(JSON.stringify(this.subTotalA + this.subTotalB), 2 / 6 * width + 79, n + 8);
        doc.setFont("Helvetica", "normal")
        doc.text('________________________________________________', 2 / 6 * width + 49, n + 9, { align: 'center' });
        doc.setFont("Helvetica", "bold")
        doc.text(JSON.stringify(this.subTotalA + this.subTotalB), 2 / 3 * width + 69, n + 8);
        doc.setFont("Helvetica", "normal")
        doc.text('________________________________________________', 2 / 3 * width + 39, n + 9, { align: 'center' });

        doc.setFont("Helvetica", "bold")
        doc.text('Rupees: ', 24, n + 12.5);//1
        doc.setFontSize(6)
        doc.text('(' + towords.convert(this.subTotalA + this.subTotalB) + ' Only)', 36, n + 12.5);
        doc.setFontSize(8)
        doc.setFont("Helvetica", "normal")
        doc.text('________________________________________________', 59, n + 13, { align: 'center' });
        doc.setFont("Helvetica", "bold")
        doc.text('Rupees: ', 2 / 6 * width + 14, n + 12.5);//1
        doc.setFontSize(6)
        doc.text('(' + towords.convert(this.subTotalA + this.subTotalB) + ' Only)', 2 / 6 * width + 26, n + 12.5);//1
        doc.setFontSize(8)
        doc.text('________________________________________________', 2 / 6 * width + 49, n + 13, { align: 'center' });
        doc.setFont("Helvetica", "bold")
        doc.setFontSize(8)
        doc.text('Rupees: ', 2 / 3 * width + 4, n + 12.5);//1
        doc.setFontSize(6)
        doc.text('(' + towords.convert(this.subTotalA + this.subTotalB) + ' Only)', 2 / 3 * width + 16, n + 12.5);//1
        doc.setFont("Helvetica", "normal")
        doc.setFontSize(8)
        doc.text('________________________________________________', 2 / 3 * width + 39, n + 13, { align: 'center' });
        doc.setFont("Helvetica", "normal")
        doc.setFontSize(8)

        doc.text('For ' + this.ChalanCompInfo[0].bankNM + ' Manager', 50, n + 17);
        doc.text('For ' + this.ChalanCompInfo[0].bankNM + ' Manager', 2 / 6 * width + 40, n + 17);
        doc.text('For ' + this.ChalanCompInfo[0].bankNM + ' Manager', 2 / 3 * width + 30, n + 17);

        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(7)
        doc.setFillColor(192, 192, 192);
        doc.rect(20, n + 20, 80, 12, 'FD');
        doc.rect(2 / 6 * width + 10, n + 20, 1 / 3 * width - 20, 12, 'FD');
        doc.rect(2 / 3 * width, n + 20, 2 / 6 * width - 20, 12, 'FD');

        doc.text("Issued By: Treasurer's Office", 23, n + 24);
        doc.text("This is Computerized Challan and No need to Signature", 23, n + 27);
        doc.text("Errors and Omissions may occur", 23, n + 30);
        doc.text("Issued By: Treasurer's Office", 2 / 6 * width + 13, n + 24);
        doc.text("This is Computerized Challan and No need to Signature", 2 / 6 * width + 13, n + 27);
        doc.text("Errors and Omissions may occur", 2 / 6 * width + 13, n + 30);
        doc.text("Issued By: Treasurer's Office", 2 / 3 * width + 3, n + 24);
        doc.text("This is Computerized Challan and No need to Signature", 2 / 3 * width + 3, n + 27);
        doc.text("Errors and Omissions may occur", 2 / 3 * width + 3, n + 30);
      }
      window.open(URL.createObjectURL(doc.output('blob')), '_blank');
    })

  }

  ClearClassBase() {
    this.formRef?.controls['year_in'].reset();
    this.formRef?.controls['rolno'].reset();
    this.formRef?.controls['inst_no'].reset();
  }

  ClearYearBase() {
    this.formRef?.controls['rolno'].reset();
    this.formRef?.controls['inst_no'].reset();
  }
}
