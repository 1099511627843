import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { NgForm } from '@angular/forms';
import { UtilService } from 'src/app/Util.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/auth/_services/authentication.service';
import { DepartmentService } from '../../shared/services/Department.service';
import { SelectList } from './../../shared/model/SelectList.model';
import { TeacherService } from './../../shared/services/Teacher.service';

@Component({
  selector: 'app-addteacher',
  templateUrl: './addteacher.component.html',
  styleUrls: ['./addteacher.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})
export class AddteacherComponent implements OnInit {
  @Output() newTeacher = new EventEmitter<SelectList>();
  design: Array<SelectList>;
  relig: Array<SelectList>;
  date: Date;
  departments: Array<SelectList>;
  isAdmin: boolean = false;
  dept: number = 0;
  jobCtgry: Array<{ctgry:string,sub_nm:string}>;



  constructor(
    private authenticationService: AuthenticationService,
    private utilService: UtilService,
    private deptService: DepartmentService,
    private toastr: ToastrService,
    private teacherService: TeacherService
  ) {
    this.departments = new Array<SelectList>();
    this.design = new Array<SelectList>();
    this.relig = new Array<SelectList>();
    this.jobCtgry = [];
    this.dept = this.authenticationService.getUser().D_ID;
    this.isAdmin = this.authenticationService.isAdmin();
  }

  ngOnInit(): void {
    this.loadDepartments();
    this.date = new Date();
    this.OnDesig();
    this.OnReligion();
  }

  loadDepartments() {
    this.deptService.getDepartments({ d_id: this.dept, admin: this.isAdmin }).subscribe((res: { D_ID: number, D_NM: string }[]) => {
      res.forEach(entry => {
        this.departments.push({ id: entry.D_ID, nm: entry.D_NM });
      })
    });
  }

  OnSubmit(f: NgForm) {
    f.value.d_id = this.dept.toString();
    this.teacherService.insertTeacher(f.value).subscribe(
      (res: { FM_ID: number, FM_NM: string }) => {
        if (!res) {
          this.toastr.warning("Already Exist");
        }
        else {
          this.toastr.success("Registered Successfully");
          this.newTeacher.emit({ id: res.FM_ID, nm: res.FM_NM });
        }
      }, err => {
        this.toastr.error("Some error Occured",err);
      });
  }

  OnDeptChange(d: HTMLSelectElement) {
    this.dept = parseInt(d.value);
  }

  OnDesig() {
    this.utilService.getDesignation().subscribe((res: any) => {
      res[0].forEach(entry => {
        this.design.push({ id: entry.desig_id, nm: entry.des });
      })
      res[1].forEach(entry => {
        this.jobCtgry.push({ctgry: entry.ctgry, sub_nm: entry.sub_nm})
      })
    }, err => {
      this.toastr.error("Some error Occured", err);
    })
  }

  OnReligion() {
    this.utilService.getReligion().subscribe((res: { idreligion: number, relig_nm: string }[]) => {
      res.forEach(entry => {
        this.relig.push({ id: entry.idreligion, nm: entry.relig_nm });
      })
    }, err => {
      this.toastr.error("Some error Occured", err);
    })
  }
}
