<div class="card-header"><b>Outline of Weekly Lectures</b></div>
<div class="card-body mb-3" style="height: 30rem;overflow: hidden;overflow-y: scroll">
  <div class="list-group shadow">   
    <div  *ngFor="let o of outline">
    <div class="d-flex justify-content-between mb-1">
      <div class="p-1 bg-danger"></div>
      <div href="#" class="list-group-item list-group-item-action"> 
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1"> Week {{o.week_no}}</h5>
          <!-- <small>just now</small> -->
          <button class="btn btn-sm btn-danger" (click)="deleteWeek()"  type="button">Delete
          </button>
        </div>
        <p class="mb-1">{{o.content}}</p>
    </div>
  </div>
    </div>
    </div>
  </div>  