<div [@SlideInFromLeft] class="backdrop" style="overflow:hidden; overflow-y: scroll">
    <div class="row">
      <div class="col-lg-6 col-md-12 offset-sm-3 ml-2 mr-2 ml-sm-auto mr-sm-auto">
        <div class="alert-box">
          <form (ngSubmit)="OnSubmit()" #f="ngForm">
            <div class="container-fluid mb-2 card" style="margin-bottom: 5px;">
              <div class="card-header  d-flex justify-content-between" style="margin-bottom: -20px">
                Add Teacher
               
                  <button (click)="OnClose()" class="btn btn-outline-primary btn-sm">Close</button>
              </div>
  
              <div class="row card-body" style="margin-bottom: -30px">
  
                <div class="col-lg-4 col-md-4 form-group">
                  <label for="Department">Department</label>
                  <select #department class="form-control" name="d_id" id="Department" (change)="OnDeptChange()" required
                    ngModel>
                    <option *ngFor="let d of departments" value="{{d.id}}">{{d.nm}}</option>
                  </select>
                </div>
  
                <div class="col-lg-4 col-md-4 form-group">
                  <label for="Teacher">Teacher</label>
                  <select #teacher class="form-control" name="fm_id" id="Teacher" (change)="onTeacherChange(teacher.value)" required ngModel>
                    <option *ngFor="let fm of teachers |keyvalue"  value="{{fm.key}}">{{fm.value.nm}}</option>
                  </select>
                </div>
                
                
                  <div  class="col-lg-4 col-md-4 form-group">
                    <button style="height: 35px;" class="btn btn-sm btn-outline-primary mt-4" type="submit">Insert Entry
                      </button>
                  </div>
                


                </div>
                </div>
            </form>
            </div>
        </div>
    </div>
</div>
            

  